import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
  root: {
    height: 'calc(100vh - 120px)'
  },
  title: {
    marginBottom: 7
  },
  gridRoot: {
    height: 240,
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    textAlign: 'left'
  },
  defaultAvatar: {
    fontSize: 155,
    color: theme.palette.orange[400]
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 500,
    margin: 'auto',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  optionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  legendContainer: {
    display: 'flex'
  },
  avatarContainer: {
    marginRight: theme.spacing(3),
    position: 'relative'
  },
  legend: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  iconCamera: {
    fontSize: 14,
    color: theme.palette.orange[400]
  },
  addAvatarButton: {
    position: 'absolute',
    bottom: 0,
    right: 0
  },
  input: {
    display: 'none'
  },
  avatar: {
    width: 125,
    height: 125,
    margin: 15
  },
  saveChangesContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

class ProfileSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      me: props.me,
      preview: props.me && props.me.avatar ? props.me.avatar : null,
      edit: false,
      editOptions: false,
      options: {
        darkTheme: JSON.parse(localStorage.getItem('darkTheme')) ? true : false
      }
    };
  }

  getBase64(file, cb) {
    if (file && file.size > 350000) {
      // 350kb
      alert('too big, needs to be under 350Kb');
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
  }

  handleChange(event, id = null) {
    if (id) {
      this.setState(
        {
          editOptions: true,
          options: {
            ...this.state.options,
            [id]:
              event.currentTarget.type === 'checkbox'
                ? event.target.checked
                : event.target.value
          }
        },
        () => {
          this.props.setOptions(this.state.options);
        }
      );
    } else {
      this.getBase64(event.target.files[0], result => {
        this.setState({
          preview: result,
          edit: true
        });
      });
    }
  }

  saveChanges(status) {
    if (status === 'reload') {
      window.location.reload();
    }

    this.setState(
      {
        edit: false
      },
      () => {
        this.props.saveChanges(this.state.preview);
      }
    );
  }

  render() {
    const { classes, me } = this.props;
    const { preview, editOptions, edit, options } = this.state;

    return (
      <div className={classes.root}>
        <Typography className={classes.title} variant="h5">
          Profile
        </Typography>
        <Card className={classes.profileContainer}>
          <div className={classes.legendContainer}>
            <div className={classes.avatarContainer}>
              {!preview ? (
                <AccountCircle className={classes.defaultAvatar} />
              ) : (
                <Avatar alt="avatar" src={preview} className={classes.avatar} />
              )}
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                type="file"
                onChange={this.handleChange.bind(this)}
              />
              <label htmlFor="contained-button-file">
                <IconButton
                  component="span"
                  className={classes.addAvatarButton}
                >
                  <Icon
                    className={classNames(classes.iconCamera, 'fas fa-camera')}
                  />
                </IconButton>
              </label>
            </div>
            <div className={classes.legend}>
              <Typography variant="h6">{me.username}</Typography>
              <Typography variant="body2">{me.roleIds.join(',')}</Typography>
            </div>
          </div>
          {edit ? (
            <div className={classes.saveChangesContainer}>
              <Button
                color="primary"
                className={classes.buttonSave}
                onClick={() => this.saveChanges()}
              >
                Save Changes
              </Button>
            </div>
          ) : (
            ''
          )}
        </Card>

        <Card className={classes.profileContainer}>
          <div className={classes.optionsContainer}>
            <Typography variant="body2">Dark theme</Typography>
            <Switch
              checked={options['darkTheme']}
              onChange={e => this.handleChange(e, 'darkTheme')}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
          {editOptions ? (
            <div className={classes.saveChangesContainer}>
              <Button
                color="primary"
                className={classes.buttonSave}
                onClick={() => this.saveChanges('reload')}
              >
                Save Changes
              </Button>
            </div>
          ) : (
            ''
          )}
        </Card>
      </div>
    );
  }
}

ProfileSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
  saveChanges: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(ProfileSettings);
