import { setDateFormat } from './date';

export const setBMM = bmm => {
  const item = {
    assetName: bmm.asset_name ? bmm.asset_name : '',
    assetId: bmm.asset_id ? bmm.asset_id : '',
    assetUsername: bmm.asset_username ? bmm.asset_username : '',
    assetIGBAUsername: bmm.asset_igba_username ? bmm.asset_igba_username : '',
    assetIGBAId: bmm.asset_igba_id ? bmm.asset_igba_id : '',
    assetPlatform: bmm.asset_platform ? bmm.asset_platform : '',
    assetType: bmm.asset_type ? bmm.asset_type : '',
    assetBusinessName: bmm.asset_business_name ? bmm.asset_business_name : '',
    assetBusinessId: bmm.asset_business_id ? bmm.asset_business_id : '',
    ownerBusinessName: bmm.owner_business_name ? bmm.owner_business_name : '',
    ownerBusinessId: bmm.owner_business_id ? bmm.owner_business_id : '',
    status: bmm.status ? bmm.status : '',
    assetTasks: bmm.asset_tasks ? bmm.asset_tasks : [],
    assetUsers: bmm.asset_users ? bmm.asset_users : [],
    lastCheckTime: bmm.last_check_time
      ? setDateFormat(bmm.last_check_time)
      : '',
    inBMSince: bmm.created_time ? setDateFormat(bmm.created_time) : ''
  };

  return item;
};
