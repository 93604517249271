import { assetsConstants } from '../../constants/configuration/assetsConstants';
import { extractAssetList } from '../../extractors/assetsExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  assetList: [],
  error: null
};

const Assets = (state = initialState, action) => {
  switch (action.type) {
    // FETCH ASSET LIST
    case `${assetsConstants.FETCH_ASSET_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${assetsConstants.FETCH_ASSET_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${assetsConstants.FETCH_ASSET_LIST}_FULFILLED`:
      let assetList = extractAssetList(action.payload.data || []);
      return {
        ...state,
        fetching: false,
        fetched: true,
        assetList: assetList
      };
    // ADD ASSET
    case `${assetsConstants.ADD_ASSET}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${assetsConstants.ADD_ASSET}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${assetsConstants.ADD_ASSET}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        assetList: [
          ...state.assetList,
          {
            id: action.payload.config.assetInfo.id,
            name: action.payload.config.assetInfo.name,
            platform_ids: action.payload.config.assetInfo.platform_ids,
            crawling_allowed: true
          }
        ]
      };
    // EDIT ASSET
    case `${assetsConstants.EDIT_ASSET}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${assetsConstants.EDIT_ASSET}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${assetsConstants.EDIT_ASSET}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        assetList: Object.assign([], {
          ...state.assetList,
          [action.payload.config.index]: {
            ...state.assetList[action.payload.config.index],
            id: action.payload.config.assetInfo.id,
            name: action.payload.config.assetInfo.name,
            platform_ids: action.payload.config.assetInfo.platform_ids
          }
        })
      };
    default:
      return state;
  }
};

export default Assets;
