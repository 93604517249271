import { setAction } from '../setters/action';

export const extractActionList = data => {
  const actions = [];
  data.map((action, key) => {
    actions.push(setAction(action, key));
    return true;
  });

  return actions;
};
