import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as pako from 'pako';

import { getUTC } from '../../setters/date';
import CustomizedSnackbar from '../commons/snackBar/snackBarComponent';

const UNAUTHORIZED = 401;
const SOMETHINGWRONG = 400;

class Interceptor extends Component {
  constructor(props) {
    super(props);
    this.interceptors();

    this.state = {
      openSnackBar: false,
      error: ''
    };
  }

  logOut() {
    this.props.signOut();
  }

  onCloseSnackBar() {
    this.setState({ openSnackBar: false });
  }

  saveRequestToLocalStorage(response) {
    const data = localStorage.getItem('requests');

    if (data && data.startsWith('[{')) {
      localStorage.removeItem('requests');
    }

    const requests =
      data && data.length
        ? JSON.parse(pako.inflate(data, { to: 'string' }))
        : [];

    requests.push({
      timestamp: getUTC(),
      body: response && response.config ? response.config.data : '',
      response: response && response.data ? response.data : '',
      status: response && response.status ? response.status : '',
      url: response && response.config ? response.config.url : ''
    });

    localStorage.setItem(
      'requests',
      pako.deflate(
        JSON.stringify(requests.length > 5 ? requests.slice(1, 6) : requests),
        { to: 'string' }
      )
    );
  }

  interceptors() {
    axios.interceptors.response.use(
      response => {
        // store the request into localstorage
        this.saveRequestToLocalStorage(response);

        // if the comment moderation is stopped and still request are running
        // cancel all the requests
        if (
          this.props.moderation.fetchingList &&
          this.props.stream &&
          response.data &&
          response.data.items &&
          response.data.items[0] &&
          response.data.items[0]['streamIds'] &&
          this.props.stream.streamSelected !==
            response.data.items[0]['streamIds'][0]
        ) {
          throw new axios.Cancel('Operation canceled by the user.');
        }
        return response;
      },
      error => {
        const { status } = error.response || SOMETHINGWRONG;
        if (status === UNAUTHORIZED) {
          this.logOut();
        }

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.setState({
            error: `${error.response.data.message}. Please contact the administrator`,
            openSnackBar: true
          });
        }

        return Promise.reject(error);
      }
    );
  }

  render() {
    const { error, openSnackBar } = this.state;
    return (
      <CustomizedSnackbar
        variant="error"
        onClose={this.onCloseSnackBar.bind(this)}
        message={error}
        open={openSnackBar}
      />
    );
  }
}

Interceptor.propTypes = {
  moderation: PropTypes.object.isRequired,
  stream: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired
};

export default Interceptor;
