export const setFilterModerationEvent = (comments, time = null) => {
  let moderationsEvents = [];

  comments.map(comment => {
    moderationsEvents.push({
      id: comment ? comment.id : '',
      started_time: comment && comment.startTime ? comment.startTime : '',
      finished_time: time ? time : 0,
      tags:
        comment && comment.tags && comment.tags.length
          ? comment.tags
              .filter(tag => {
                return tag.checked === true;
              })
              .map(tag => {
                return tag.id;
              })
          : []
    });
    return true;
  });

  return {
    items: moderationsEvents
  };
};

export const setFilterAlertEvent = (commentId, tags) => {
  const AlertEvents = [
    {
      id: commentId,
      tagIds: tags
        ? tags
            .filter(tag => {
              return tag.checked === true;
            })
            .map(function(tag) {
              return tag.id;
            })
        : []
    }
  ];

  return {
    items: AlertEvents
  };
};
