import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Panel from '../../commons/panel/PanelComponent';
import AlertDialog from '../../commons/dialog/alertDialogComponent';
import DialogContent from '../../commons/dialog/contentDialogComponent';
import PermissionsForm from './permissionsForm';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100vh - 120px)',
    paddingTop: theme.spacing(1)
  },
  title: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonAddRole: {
    marginLeft: theme.spacing(2)
  },
  roleContainer: {
    width: '50%',
    marginTop: theme.spacing(2)
  },
  panelContainer: {
    margin: theme.spacing(2)
  },
  panelContent: {
    width: '100%'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

class RolePermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleList: props.list,
      roleId: '',
      roleName: '',
      mode: 'add',
      error: '',
      permissionGroups: [],
      openDialogDeleteRole: false,
      openDialogRolesPermissions: false
    };

    this.deleteRole.bind(this);
  }

  onChangeTextField(data, value) {
    this.setState({
      [data]: value
    });
  }

  actionRolesPermissions(e, roleId = '', roleName = '') {
    this.setState(
      {
        mode: roleId ? 'edit' : 'add',
        permissionGroups: [],
        roleId: roleId,
        roleName: roleName
      },
      () => {
        this.openDialogRolesPermissions(e, roleId);
      }
    );
  }

  openDialogRolesPermissions(e, roleId = '') {
    this.setState({
      openDialogRolesPermissions: true
    });

    this.props.fetchPermissionGroups(roleId);
  }

  closeDialogRolesPermissions() {
    this.setState({
      openDialogRolesPermissions: false
    });
  }

  deleteRole(e, roleId) {
    this.setState({
      openDialogDeleteRole: true,
      roleId: roleId
    });
  }

  actionDialogDeleteRole(state) {
    if (state) {
      this.props.deleteRole(this.state.roleId);
      this.setState({
        openDialogDeleteRole: false
      });
    } else {
      this.setState({
        openDialogDeleteRole: false
      });
    }
  }

  updateRolesPermissions() {
    const { mode, roleId, roleName, permissionGroups } = this.state;
    let str = '';

    if (roleId === '') {
      str = 'Role Id Empty';
    } else if (!roleId.startsWith('ROLE_')) {
      str = 'Role Id need to start with ROLE_';
    } else if (roleName === '') {
      str = 'Role Name Empty';
    } else if (
      !permissionGroups.filter(group => {
        return group.permissions.filter(permission => {
          return permission.checked === true;
        }).length;
      }).length
    ) {
      str = 'Needs minimum one permission';
    }

    if (str) {
      this.setState({
        error: str,
        errorKey: Math.random()
      });
    } else {
      this.props.updateRolesPermissions(
        mode,
        roleId,
        roleName,
        permissionGroups
      );
      this.closeDialogRolesPermissions();
    }
  }

  onChangePermission(value, keyGroup, keyPermission) {
    this.props.onChangePermission(value, keyGroup, keyPermission);
  }

  displayButtonsRole(roleId, roleName) {
    const { classes } = this.props;

    return (
      <div className={classes.buttonContainer}>
        <Button
          size="small"
          color="primary"
          onClick={e => {
            return this.actionRolesPermissions(e, roleId, roleName);
          }}
        >
          Edit
        </Button>
        <Button
          onClick={e => {
            return this.deleteRole(e, roleId);
          }}
          size="small"
          color="secondary"
        >
          Delete
        </Button>
      </div>
    );
  }

  displayButtonsPermission() {
    const { classes } = this.props;
    const { mode } = this.state;
    return (
      <div className={classes.buttonContainer}>
        <Button
          size="small"
          color="primary"
          onClick={e => {
            return this.updateRolesPermissions();
          }}
        >
          {mode === 'add' ? 'Add role' : 'Edit role'}
        </Button>
        <Button
          onClick={e => {
            return this.closeDialogRolesPermissions(e);
          }}
          size="small"
          color="secondary"
        >
          Cancel
        </Button>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.list !== this.props.list) {
      this.setState({
        list: this.props.list
      });
    }

    if (prevProps.permissionGroups !== this.props.permissionGroups) {
      this.setState({
        permissionGroups: this.props.permissionGroups
      });
    }
  }

  render() {
    const { classes, list } = this.props;
    const {
      openDialogDeleteRole,
      openDialogRolesPermissions,
      permissionGroups,
      roleName,
      mode,
      roleId,
      error,
      errorKey
    } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h5">Roles and permissions</Typography>
          <Button
            variant="contained"
            className={classes.buttonAddRole}
            size="small"
            color="primary"
            onClick={e => {
              return this.actionRolesPermissions(e);
            }}
          >
            Add Role
          </Button>
        </div>
        <div className={classes.roleContainer}>
          {list
            ? list.map((role, key) => {
                return (
                  <div key={key} className={classes.panelContainer}>
                    <Panel expanded={false} title={role.name}>
                      <div className={classes.panelContent}>
                        <PermissionsForm
                          permissionGroups={role.permission_groups}
                        />
                        {!role.readonly
                          ? this.displayButtonsRole(role.id, role.name)
                          : ''}
                      </div>
                    </Panel>
                  </div>
                );
              })
            : ''}
        </div>
        <AlertDialog
          title="Are you sure?"
          message="It will delete the role permanently."
          buttonCancelText="Cancel"
          buttonAcceptText="Delete"
          open={openDialogDeleteRole}
          action={this.actionDialogDeleteRole.bind(this)}
        />
        <DialogContent
          title={'Permissions'}
          open={openDialogRolesPermissions}
          close={this.closeDialogRolesPermissions.bind(this)}
        >
          <div>
            <PermissionsForm
              mode={mode}
              onChangePermission={this.onChangePermission.bind(this)}
              onChangeTextField={this.onChangeTextField.bind(this)}
              permissionGroups={permissionGroups}
              roleName={roleName}
              roleId={roleId}
              error={error}
              errorKey={errorKey}
            />
            {this.displayButtonsPermission()}
          </div>
        </DialogContent>
      </div>
    );
  }
}

RolePermission.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  list: PropTypes.array,
  permissionGroups: PropTypes.array,
  fetchPermissionGroups: PropTypes.func,
  onChangePermission: PropTypes.func,
  updateRolesPermissions: PropTypes.func,
  deleteRole: PropTypes.func
};

export default withStyles(styles, { withTheme: true })(RolePermission);
