import { setDateFormat } from './date';

export const setTaskHistory = th => {
  const hasParams = th && th.params && Object.keys(th.params).length;
  const hasStats = th && th.stats && Object.keys(th.stats).length;

  const item = {
    id: th ? th.id : '',
    is_favorite: th ? th.is_favorite : 0,
    params: hasParams ? th.params : {},
    status: th ? th.status : '',
    stats: hasStats ? th.stats : {},
    last_run: th && th.last_run ? setDateFormat(th.last_run) : '',
    last_successful_run:
      th && th.last_successful_run ? setDateFormat(th.last_successful_run) : ''
  };

  if (hasParams) {
    if (item.params.publishedBefore) {
      item.params.publishedBefore = th.params.publishedBefore;
    }
    if (item.params.publishedAfter) {
      item.params.publishedAfter = th.params.publishedAfter;
    }
  }

  return item;
};
