import axios from 'axios';

import { taskHistoryConstants } from '../../constants/configuration/taskHistoryConstants';
import { setHeaders } from '../../setters/auth';

export const fetchTaskHistoryList = (
  clientShortName,
  isFilterFavorite = false
) => dispatch => {
  let url = `${
    process.env.URL_API_ENDPOINT
  }/copyright/admin/${clientShortName}/${
    isFilterFavorite ? 'task-favorites' : 'task-history'
  }`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: taskHistoryConstants.FETCH_TASK_HISTORY_LIST,
    payload: promise
  });
};

export const addTask = (clientShortName, data) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/copyright/admin/${clientShortName}/create-task`;

  const promise = axios.post(url, data, {
    headers: setHeaders(),
    addData: data
  });

  return dispatch({
    type: taskHistoryConstants.ADD_TASK,
    payload: promise
  });
};

export const updateFavorite = (
  clientShortName,
  index,
  taskId,
  is_favorite
) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/copyright/admin/${clientShortName}/task/${taskId}/update`;

  const data = {
    is_favorite
  };

  const promise = axios.put(url, data, {
    headers: setHeaders(),
    index,
    is_favorite
  });

  return dispatch({
    type: taskHistoryConstants.UPDATE_TASK_FAVORITE,
    payload: promise
  });
};
