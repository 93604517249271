export const setStream = (stream, key) => {
  const item = {
    id: stream ? stream.id : null,
    key: key,
    label: stream ? stream.label : 'unknown',
    notification_count: stream ? stream.notificationCount : 0,
    groupsTag:
      stream && stream.tagGroups && stream.tagGroups.length
        ? stream.tagGroups.sort((a, b) => (a.group > b.group ? 1 : -1))
        : [],
    own: stream ? stream.own : [],
    favourite: stream ? stream.favorite : false,
    streamGroup: stream.stream_group ? stream.stream_group : '{none}'
  };

  return item;
};

export const setStreamConfiguration = (stream, key) => {
  const item = {
    id: stream ? stream.id : null,
    key: key,
    label: stream ? stream.label : 'unknown',
    tags: stream ? stream.tags : [],
    filters: stream && stream.filters ? stream.filters : [],
    exclusion_filters:
      stream && stream.exclusion_filters ? stream.exclusion_filters : [],
    users:
      stream &&
      stream.access_filters &&
      stream.access_filters.length &&
      stream.access_filters.find(filter => filter.field === 'username')
        ? stream.access_filters.find(filter => filter.field === 'username')[
            'values'
          ]
        : [],
    roles:
      stream &&
      stream.access_filters &&
      stream.access_filters.length &&
      stream.access_filters.find(filter => filter.field === 'roles')
        ? stream.access_filters.find(filter => filter.field === 'roles')[
            'values'
          ]
        : []
  };

  return item;
};
