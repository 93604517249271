import { tagsConstants } from "../../constants/configuration/tagsConstants";

import { extractTags } from "../../extractors/tagsExtractor";

const initialState = {
  fetching: false,
  fetched: false,
  tagList: [],
  error: null,
};

const getTagCategoryNameById = (tag_category) => {
  switch (tag_category) {
    case "sensitive":
      return "Sensitive";
    case "universally_harmful":
      return "Universally Harmful";
    case "non_harmful":
      return "Non Harmful";
    case "customer_engagement":
      return "Customer Engagement";
    case "alerts":
      return "Alerts";
    default:
      return "-";
  }
};

const Tags = (state = initialState, action) => {
  switch (action.type) {
    // FETCH TAG LIST
    case `${tagsConstants.FETCH_TAG_LIST}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${tagsConstants.FETCH_TAG_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${tagsConstants.FETCH_TAG_LIST}_FULFILLED`:
      let tags = extractTags(action.payload.data);
      return {
        ...state,
        fetching: false,
        fetched: true,
        tagList: tags,
      };
    // ADD TAG
    case `${tagsConstants.ADD_TAG}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${tagsConstants.ADD_TAG}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${tagsConstants.ADD_TAG}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        tagList: [
          ...state.tagList,
          {
            id: action.payload.config.tagInfo.id,
            label: action.payload.config.tagInfo.label,
            description: action.payload.config.tagInfo.description,
            group: action.payload.config.tagInfo.group,
            example: action.payload.config.tagInfo.example,
            excluded_example: action.payload.config.tagInfo.excluded_example,
            volume_based_alert:
              action.payload.config.tagInfo.volume_based_alert,
            tag_category: action.payload.config.tagInfo.tag_category,
            tag_category_name: getTagCategoryNameById(
              action.payload.config.tagInfo.tag_category
            ),
            pim_tone: action.payload.config.tagInfo.pim_tone,
            pim_weight: action.payload.config.tagInfo.pim_weight,
          },
        ],
      };
    // EDIT TAG
    case `${tagsConstants.EDIT_TAG}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${tagsConstants.EDIT_TAG}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${tagsConstants.EDIT_TAG}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        tagList: Object.assign([], {
          ...state.tagList,
          [action.payload.config.index]: {
            id: action.payload.config.tagInfo.id,
            label: action.payload.config.tagInfo.label,
            description: action.payload.config.tagInfo.description,
            group: action.payload.config.tagInfo.group,
            example: action.payload.config.tagInfo.example,
            excluded_example: action.payload.config.tagInfo.excluded_example,
            volume_based_alert:
              action.payload.config.tagInfo.volume_based_alert,
            tag_category: action.payload.config.tagInfo.tag_category,
            tag_category_name: getTagCategoryNameById(
              action.payload.config.tagInfo.tag_category
            ),
            pim_tone: action.payload.config.tagInfo.pim_tone,
            pim_weight: action.payload.config.tagInfo.pim_weight,
          },
        }),
      };
    default:
      return state;
  }
};

export default Tags;
