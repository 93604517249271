import axios from 'axios';

import { configurationConstants } from '../../constants/configuration/configurationConstants';
import { setHeaders } from '../../setters/auth';

export const fetchRolesAndPermissionList = () => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/roles/`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: configurationConstants.FETCH_ROLES_AND_PERMISSION_LIST,
    payload: promise
  });
};

export const fetchRolePermissions = roleId => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/roles/${roleId}`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: configurationConstants.FETCH_ROLE_PERMISSIONS,
    payload: promise
  });
};

export const fetchPermissionGroups = () => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/permission-groups/`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: configurationConstants.FETCH_PERMISSION_GROUPS,
    payload: promise
  });
};

export const onChangePermission = (
  value,
  keyGroup,
  keyPermission
) => dispatch => {
  const promise = new Promise((resolve, reject) => {
    resolve({
      value: value,
      keyGroup: keyGroup,
      keyPermission: keyPermission
    }); // fulfilled
    reject('failure decrase notification count');
  });

  return dispatch({
    type: configurationConstants.ON_CHANGE_PERMISSION,
    payload: promise
  });
};

export const createRole = (roleId, roleName, permissions) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/roles/`;

  const promise = axios.post(
    url,
    {
      id: roleId,
      name: roleName,
      permissions: permissions
    },
    {
      headers: setHeaders()
    }
  );

  return dispatch({
    type: configurationConstants.CREATE_ROLE,
    payload: promise
  });
};

export const editRole = (roleId, roleName, permissions) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/roles/${roleId}`;

  const promise = axios.put(
    url,
    {
      name: roleName,
      permissions: permissions
    },
    {
      headers: setHeaders(),
      roleId: roleId
    }
  );

  return dispatch({
    type: configurationConstants.EDIT_ROLE,
    payload: promise
  });
};

export const deleteRole = roleId => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/roles/${roleId}`;

  const promise = axios.delete(url, {
    headers: setHeaders(),
    roleId: roleId
  });

  return dispatch({
    type: configurationConstants.DELETE_ROLE,
    payload: promise
  });
};
