import { withOAuth } from 'aws-amplify-react';
import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    marginBottom: theme.spacing(2)
  }
});

class OAuthButton extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h5" className={classes.title}>
          BrandBastion CAMP
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={this.props.OAuthSignIn}
        >
          Sign in
        </Button>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withOAuth(OAuthButton));
