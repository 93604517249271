import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const MAX_TRUSTPILOT_STARS = 5;

const useStyles = makeStyles(() => ({
  bbStars: {
    display: "flex",
    gap: "2px",
    height: "20px",
    "& img": {
      width: "16px",
      height: "16px",
      backgroundColor: "#DCDCE6",
    },
  },
  bbStar5: {
    "& img": {
      backgroundColor: "#00b67a",
    },
  },
  bbStar4: {
    "& img": {
      backgroundColor: "#73cf11",
    },
  },
  bbStar3: {
    "& img": {
      backgroundColor: "#ffce00",
    },
  },
  bbStar2: {
    "& img": {
      backgroundColor: "#ff8622",
    },
  },
  bbStar1: {
    "& img": {
      backgroundColor: "#ff3722",
    },
  },
}));

export const TrustpilotStars = ({ numberOfStars }) => {
  const classes = useStyles();
  return (
    <div className={classes.bbStars}>
      {[...Array(MAX_TRUSTPILOT_STARS)].map((_, index) => {
        const starClass = `bbStar${
          numberOfStars && index < numberOfStars ? numberOfStars : ""
        }`;
        return (
          <div className={classes[starClass]} key={index}>
            <img
              src={`${process.env.PUBLIC_URL}/images/trustpilotStar.svg`}
              alt="trustpilotstar"
            />
          </div>
        );
      })}
    </div>
  );
};
