import { setAsset } from '../setters/asset';

export const extractAssetList = data => {
  const assets = [];
  data.map((asset, key) => {
    assets.push(setAsset(asset, key));
    return true;
  });

  return assets;
};
