import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";

import SettingsPage from "../../pages/settings/settingsPage";
import HomePage from "../../pages/home/homePage";
import ModerationPage from "../../pages/moderation/moderationPage";
import AlertsPage from "../../pages/alerts/alertsPage";
import ExplorerPage from "../../pages/explorer/explorerPage";
import ReplyPage from "../../pages/reply/replyPage";
import AdminPage from "../../pages/admin/adminPage";
import TaskKPIsPage from "../../pages/admin/taskKPIsPage";
import SalesPage from "../../pages/sales/salesPage";
import AttemptsPage from "../../pages/sales/attemptsPage";
import CopyrightPage from "../../pages/copyright/copyrightPage";
import ClientConfigurationPage from "../../pages/admin/clientConfigurationPage";

import InterceptorComponent from "../interceptor/interceptorComponent";

import { wsConnect, wsDisconnect } from "../../actions/websocketAction";

const mapStateToProps = (state) => ({
  moderation: state.moderationReducer,
  streams: state.streamReducer,
});

const mapDispatchToProps = (dispatch) => ({
  wsConnect: (host) => dispatch(wsConnect(host)),
  wsDisconnect: (host) => dispatch(wsDisconnect(host)),
});

class RouteComponent extends Component {
  signOut() {
    this.props.signOut();
  }

  componentDidMount() {
    if (process.env.SOCKET_URL) {
      this.props.wsConnect(process.env.SOCKET_URL);
    }
  }

  componentWillUnmount() {
    if (process.env.SOCKET_URL) {
      this.props.wsDisconnect(process.env.SOCKET_URL);
    }
  }

  render() {
    return (
      <div className="Route">
        <Router>
          <div>
            <InterceptorComponent
              moderation={this.props.moderation}
              stream={this.props.streams}
              signOut={this.signOut.bind(this)}
            />
            <Route
              exact
              path="/"
              render={(props) => (
                <HomePage {...props} signOut={this.signOut.bind(this)} />
              )}
            />
            <Route
              exact
              path="/home"
              render={(props) => (
                <HomePage {...props} signOut={this.signOut.bind(this)} />
              )}
            />
            <Route
              exact
              path="/moderation"
              render={(props) => (
                <ModerationPage {...props} signOut={this.signOut.bind(this)} />
              )}
            />
            <Route
              exact
              path="/alerts"
              render={(props) => (
                <AlertsPage {...props} signOut={this.signOut.bind(this)} />
              )}
            />
            <Route
              exact
              path="/explorer"
              render={(props) => (
                <ExplorerPage {...props} signOut={this.signOut.bind(this)} />
              )}
            />
            <Route
              exact
              path="/reply"
              render={(props) => (
                <ReplyPage {...props} signOut={this.signOut.bind(this)} />
              )}
            />
            <Route
              exact
              path="/copyright"
              render={(props) => (
                <CopyrightPage {...props} signOut={this.signOut.bind(this)} />
              )}
            />
            <Route
              exact
              path="/admin"
              render={(props) => (
                <AdminPage {...props} signOut={this.signOut.bind(this)} />
              )}
            />
            <Route
              exact
              path="/task-kpis/:assetId"
              render={(props) => (
                <TaskKPIsPage {...props} signOut={this.signOut.bind(this)} />
              )}
            />
            <Route
              exact
              path="/sales"
              render={(props) => (
                <SalesPage {...props} signOut={this.signOut.bind(this)} />
              )}
            />
            <Route
              exact
              path={`/sales/:code/attempts`}
              render={(props) => (
                <AttemptsPage {...props} signOut={this.signOut.bind(this)} />
              )}
            />
            <Route
              exact
              path={`/admin/:clientShortName`}
              render={(props) => (
                <ClientConfigurationPage
                  {...props}
                  signOut={this.signOut.bind(this)}
                />
              )}
            />
            <Route
              exact
              path={`/settings`}
              render={(props) => (
                <SettingsPage {...props} signOut={this.signOut.bind(this)} />
              )}
            />
          </div>
        </Router>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteComponent);
