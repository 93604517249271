import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Link from "@material-ui/core/Link";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";

import { getUTC } from "../../../setters/date";
import Panel from "../panel/PanelComponent";
import SelectComponent from "../select/selectComponent";
import InputComponent from "../input/inputComponent";
import AlertDialog from "../dialog/alertDialogComponent";
import DialogContent from "../../commons/dialog/contentDialogComponent";
import Tooltip from "../../commons/tooltip/TooltipComponent";
import JSONEditor from "../../commons/jsonEditor/jsonEditorComponent";
import DatePicker from "../../commons/picker/dateTimePickerComponent";
import Legend from "./legend";

import WarningIcon from "@material-ui/icons/Warning";

const styles = (theme) => ({
  root: {
    width: "90%",
    overflow: "hidden",
    margin: "auto",
  },
  rootFull: {
    width: "100%",
  },
  listNoScroll: {},
  listScroll: {
    overflow: "auto",
    height: "calc(100vh - 300px)",
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  categoryContainer: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
  },
  panel: {
    boxShadow: "none",
    width: "100%",
  },
  categoryContent: {
    width: "100%",
  },
  list: {
    width: "100%",
  },
  link: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },
  linkLang: {
    padding: 4,
    borderRadius: 4,
    minWidth: 50,
    fontWeight: "bold",
  },
  filtersContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  categoryName: {
    marginRight: theme.spacing(3),
  },
  buttonMessage: {
    marginRight: theme.spacing(2),
  },
  buttonSuccess: {
    color: theme.palette.white,
    background: theme.palette.success[500],
    "&:hover, &:focus, &:active": {
      color: theme.palette.white,
      background: theme.palette.success[800],
    },
  },
  buttonAutoSelect: {
    width: 95,
    paddingTop: 17,
  },
  listItemRoot: {
    display: "flex",
    alignItems: "center",
  },
  secondaryAction: {
    position: "static",
    alignItems: "center",
    transform: "initial",
    display: "flex",
    marginLeft: theme.spacing(3),
  },
  itemAvatar: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    cursor: "pointer",
    marginRight: theme.spacing(1),
  },
  categoryActions: {
    display: "flex",
    paddingTop: theme.spacing(1),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  inputEditMessage: {
    marginTop: theme.spacing(2),
  },
  infoOld: {
    marginRight: theme.spacing(3),
  },
  infoNotActive: {
    marginRight: theme.spacing(3),
  },
  infoActive: {
    marginRight: theme.spacing(3),
    color: theme.palette.success[500],
  },
  infoCounts: {
    marginRight: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  infoTotalCounts: {
    marginRight: theme.spacing(3),
  },
  formGroupEditor: {
    marginTop: theme.spacing(1),
  },
  labelEditor: {
    marginBottom: theme.spacing(1),
  },
  infoIcon: {
    color: theme.palette.error[500],
    marginRight: theme.spacing(3),
  },
  usageWarning: {
    color: theme.palette.warning[800],
    backgroundColor: "rgba(255,235,59, .3)",
    boxShadow: theme.palette.shadow.shadowDefault,
  },
  usageDanger: {
    color: theme.palette.secondary[500],
    backgroundColor: "rgba(224, 102, 102, .3)",
    boxShadow: theme.palette.shadow.shadowDefault,
  },
  usageOk: {
    color: theme.palette.success[600],
    backgroundColor: "rgba(147,196,125, .3)",
    boxShadow: theme.palette.shadow.shadowDefault,
  },
  alertMessage: {
    height: 62,
    width: "100%",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    backgroundColor: "#E36B00",
    paddingLeft: 28,
  },
  alertMessageIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
});

class NestedList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: props.item || {},
      oldCategories:
        props.data && props.data.categories
          ? JSON.parse(JSON.stringify(props.data.categories))
          : [],
      categories:
        props.data && props.data.categories ? props.data.categories : [],
      messages: props.data && props.data.messages ? props.data.messages : [],
      defaultLanguage: props.defaultLanguage ? props.defaultLanguage : [],
      selected: {},
      default_filter_languages: props.availableLanguages
        ? props.availableLanguages
        : [],
      filter_languages: props.availableLanguages
        ? props.availableLanguages
        : [],
      filter_categories:
        props.data && props.data.categories
          ? props.data.categories.map((item) => item.category_name)
          : [],
      default_filter_categories:
        props.data && props.data.categories
          ? props.data.categories
              .filter((item) => item.auto_check === true)
              .map((item) => item.category_name)
          : [],
      search: "",
      openDialogDeleteMessage: false,
      openDialogCategory: false,
      openDialogDeleteCategory: false,
      openDialogMessage: false,
      editMode: false,
      categorySelected: "",
      messageEdit: {
        languageSelected: "",
        type: null,
        data: {},
        message: "",
        activation_date: null,
        deactivation_date: null,
      },
      categoryEdit: {
        category_id: 0,
        category_name: "",
        auto_check_filters: [],
        filters: [],
        messages: [],
        categories: [],
      },
    };
  }

  ///////////////////////////
  // CHANGE DATA DEEP OBJECT
  checkAndChangeMessage(messages, selected, newValue) {
    let newMessage = {
      ...messages[selected.key],
      activation_date: newValue.activation_date,
      deactivation_date: newValue.deactivation_date,
      text: {
        ...messages[selected.key]["text"],
        [newValue.languageSelected]: newValue.message,
      },
    };

    messages[selected.key] = newMessage;
  }

  checkAndAddMessage(messages) {
    const { defaultLanguage } = this.state;
    let obj = {};
    obj[defaultLanguage] = "";

    messages.push({
      text: obj,
    });
  }

  checkAndAddCategory(categories, newCategory) {
    categories.push(newCategory);
  }

  recursion(obj, selected, newValue, options) {
    let o = obj;

    //recursion to change message
    if (o.category_name && options.type === "change") {
      if (o.messages && selected.category_name === o.category_name) {
        this.checkAndChangeMessage(o.messages, selected, newValue);
      }
    }

    //recursion to add message
    if (options.type === "addMessage") {
      if (o.messages && o.category_name === options.data.category_name) {
        if (
          o.category_id === options.data.category_id ||
          (o.category_id === undefined &&
            options.data.category_id === undefined)
        ) {
          this.checkAndAddMessage(o.messages);
        }
      }
    }

    //recursion to edit category
    if (options.type === "editCategory") {
      if (o.category_name === options.category_name) {
        o.filters = newValue.filters;
        o.auto_check_filters = newValue.auto_check_filters;
        o.category_name = newValue.category_name;
        o.messages = newValue.messages;
        o.categories = newValue.categories;
      }
    }

    //recursion to add categorie
    if (options.type === "addCategory") {
      if (!options.category_name && o.categories && o.defaultLanguage) {
        this.checkAndAddCategory(o.categories || [], newValue);
      } else if (o.category_name === options.category_name) {
        this.checkAndAddCategory(o.categories || [], newValue);
      }
    }

    //recursion to delete category
    if (options.type === "deleteCategory") {
      if (
        o.categories &&
        o.categories.find((category) => selected === category.category_name)
      ) {
        let newCategories = o.categories.filter(
          (category) => selected !== category.category_name
        );
        o.categories = newCategories;
      }
    }

    //recursion to delete message
    if (options.type === "deleteMessage") {
      if (o.messages && selected.category_name === o.category_name) {
        o.messages.splice(selected.key, 1);
      }
    }

    //recursion to add sub category
    if ((o.categories && o.categories.length > 0) || !o.categories) {
      if (!o.categories) {
        o["categories"] = [];
      }
      o.categories.forEach((v) => {
        this.recursion(v, selected, newValue, options);
      });
    }
    return o;
  }
  /////////////////////////////////////////////
  /// EVENTS EDIT MODE ////////////////////////

  handleChangeCategory(e, id) {
    this.setState({
      categoryEdit: {
        ...this.state.categoryEdit,
        [id]: e.target.value,
      },
    });
  }

  onChangeEditor(e, id) {
    this.setState({
      categoryEdit: {
        ...this.state.categoryEdit,
        [id]: e !== undefined ? e : [],
      },
    });
  }

  setSelectedDate(name, date) {
    this.setState({
      messageEdit: {
        ...this.state.messageEdit,
        [name]: date,
      },
    });
  }

  handleChangeMessage(e, id) {
    this.setState({
      messageEdit: {
        ...this.state.messageEdit,
        [id]: e.target.value,
      },
    });
  }

  changeMessage(selected) {
    this.setState(
      {
        categories: this.recursion(
          JSON.parse(JSON.stringify(this.state)),
          selected,
          this.state.messageEdit,
          {
            type: "change",
          }
        )["categories"],
      },
      () => {
        this.setState({
          openDialogMessage: false,
        });
      }
    );
  }

  addMessage(callback) {
    this.setState(
      {
        categories: this.recursion(
          JSON.parse(JSON.stringify(this.state)),
          this.state.selected,
          {},
          {
            type: "addMessage",
            data: this.state.messageEdit.data,
          }
        )["categories"],
      },
      () => {
        if (typeof callback === "function") {
          callback();
        }
      }
    );
  }

  updateMessage() {
    const { messageEdit } = this.state;

    if (messageEdit.type === "change") {
      this.changeMessage(this.state.selected);
    } else {
      this.addMessage(() => {
        this.setState(
          {
            selected: {
              key: messageEdit.data.messages.length,
              text: messageEdit.data.messages[
                messageEdit.data.messages.length - 1
              ],
              category_name: messageEdit.data.category_name,
              count: 0,
              pColor: "green",
            },
          },
          () => {
            this.changeMessage(this.state.selected);
          }
        );
      });
    }
  }

  addCategory() {
    this.setState(
      {
        categories: this.recursion(
          JSON.parse(JSON.stringify(this.state)),
          null,
          this.state.categoryEdit,
          {
            type: this.state.editMode ? "editCategory" : "addCategory",
            category_name: this.state.categorySelected,
          }
        )["categories"],
      },
      () => {
        this.setState({
          old_filter_categories: this.state.filter_categories,
          filter_categories: this.state.categories.map(
            (item) => item.category_name
          ),
          categoryEdit: {
            ...this.state.categoryEdit,
            category_id: this.state.category_id + 1,
          },
        });
        this.closeDialogCategory();
      }
    );
  }

  deleteMessage() {
    this.setState({
      openDialogDeleteMessage: false,
      categories: this.recursion(
        JSON.parse(JSON.stringify(this.state)),
        this.state.selected,
        null,
        {
          type: "deleteMessage",
        }
      )["categories"],
    });
  }

  deleteCategory() {
    this.setState({
      openDialogDeleteCategory: false,
      categorySelected: "",
      categories: this.recursion(
        JSON.parse(JSON.stringify(this.state)),
        this.state.categorySelected,
        null,
        {
          type: "deleteCategory",
        }
      )["categories"],
    });
  }

  actionDialogDeleteMessage(state) {
    if (state) {
      this.deleteMessage();
    } else {
      this.setState({
        openDialogDeleteMessage: false,
      });
    }
  }

  actionDialogDeleteCategory(state) {
    if (state) {
      this.deleteCategory();
    } else {
      this.setState({
        openDialogDeleteCategory: false,
      });
    }
  }

  openDialogDeleteMessage() {
    this.setState({
      openDialogDeleteMessage: true,
    });
  }

  openDialogDeleteCategory(e, category_name) {
    this.setState({
      categorySelected: category_name,
      openDialogDeleteCategory: true,
    });
  }

  openDialogCategory(e, data, type) {
    this.setState({
      categoryEdit: type === "edit" ? data : this.state.categoryEdit,
      categorySelected: data && data.category_name ? data.category_name : "",
      openDialogCategory: true,
      editMode: type === "edit" ? true : false,
    });
  }

  openDialogMessage(e, text, data, language, type) {
    this.setState({
      messageEdit: {
        ...this.state.messageEdit,
        languageSelected: language,
        message: text,
        type: type,
        data: data,
        activation_date: data.activation_date,
        deactivation_date: data.deactivation_date,
      },
      openDialogMessage: true,
    });
  }

  closeDialogCategory() {
    this.setState({
      openDialogCategory: false,
      categorySelected: "",
      editMode: false,
      categoryEdit: {
        ...this.state.categoryEdit,
        category_name: "",
        filters: [],
        auto_check_filters: [],
        messages: [],
        categories: [],
      },
    });
  }

  closeDialogMessage() {
    this.setState({
      messageEdit: {
        languageSelected: "",
        message: "",
        activation_date: null,
        deactivation_date: null,
      },
      openDialogMessage: false,
    });
  }

  onErrorEditor(e) {}
  ///////////////////////////////////////
  //// EVENTS READ ONLY /////////////////

  autoSelectReply(messages) {
    const { defaultLanguage, item } = this.state;

    let langToken = "en";

    if (
      item.languageDetected &&
      item.replyConfig &&
      item.replyConfig.available_languages &&
      item.replyConfig.available_languages.indexOf(item.languageDetected) !== -1
    ) {
      langToken = item.languageDetected;
    }

    let availableMessages = messages.filter((mes) => {
      return Object.keys(mes.text).indexOf(langToken) !== -1;
    });

    if (availableMessages.length === 0) {
      availableMessages = messages;
      langToken = "en";
    }

    let messageObj =
      availableMessages[Math.floor(Math.random() * availableMessages.length)];

    let message = messageObj["text"][langToken];

    this.props.selectedItem(
      message,
      defaultLanguage,
      true,
      messageObj.category_path,
      messageObj.counts[langToken],
      messageObj.percentiles[langToken]
    );
  }

  selectLanguage(key, category_name, item, path, count, pColor) {
    const { edit } = this.props;
    this.setState(
      {
        [`${category_name}-${key}`]: item[edit ? 1 : 0],
      },
      () => {
        this.selectMessage(
          item[edit ? 0 : 1],
          key,
          category_name,
          path,
          count,
          pColor
        );
      }
    );
  }

  selectMessage(text, key, category_name, path, count, pColor) {
    this.setState(
      {
        selected: {
          key: key,
          text: text,
          category_name: category_name,
          path: path,
          count: count,
          pColor: pColor,
        },
      },
      () => {
        this.selectedItem();
      }
    );
  }

  selectedItem() {
    const { selected, defaultLanguage } = this.state;
    const lang = this.state[`${selected.category_name}-${selected.key}`]
      ? this.state[`${selected.category_name}-${selected.key}`]
      : defaultLanguage;

    const text =
      typeof selected.text === "string" ? selected.text : selected.text[lang];

    this.props.selectedItem(
      text,
      lang,
      true,
      selected.path,
      selected.count,
      selected.pColor
    );
  }

  handleChange(e, id) {
    this.setState({
      [id]: e.target.value,
    });
  }

  /////////////////////////////////////////////////////////////////
  /////////////   SUB COMPONENTS : TODO IMPORT ///////////////////

  createItem(data, keyList, category_name, lang = null) {
    const { classes, edit, status, isAdmin } = this.props;
    const { selected, defaultLanguage, default_filter_languages } = this.state;
    let language = this.state[`${category_name}-${keyList}`]
      ? this.state[`${category_name}-${keyList}`]
      : lang
      ? lang
      : defaultLanguage;
    let text = data.text[language] === undefined ? "" : data.text[language];
    let post_counts =
      data.counts && data.counts[language] !== undefined
        ? data.counts[language]
        : 0;
    let user_counts =
      data.user_counts && data.user_counts[language] !== undefined
        ? data.user_counts[language]
        : 0;
    let utcTime = Math.round(getUTC() / 1000);

    let percentiles = data.percentiles;

    return (
      <ListItem
        dense
        button
        key={keyList}
        selected={
          edit
            ? false
            : selected.key === keyList &&
              selected.category_name === category_name
        }
        onClick={(e) => {
          this.selectMessage(
            data.text,
            keyList,
            category_name,
            data.category_path,
            isAdmin ? 0 : data.counts[language],
            isAdmin ? "green" : percentiles[language]
          );
        }}
        classes={{
          container: classes.listItemRoot,
        }}
      >
        {edit ? (
          <ListItemAvatar>
            <div className={classes.itemAvatar}>
              <div
                className={classes.icon}
                onClick={(e) =>
                  this.openDialogMessage(e, text, data, language, "change")
                }
              >
                <Tooltip title={<p>Edit message</p>}>
                  <Icon>edit</Icon>
                </Tooltip>
              </div>
              <div
                className={classes.icon}
                onClick={(e) => this.openDialogDeleteMessage(e)}
              >
                <Tooltip title={<p>Delete message</p>}>
                  <Icon>delete</Icon>
                </Tooltip>
              </div>
            </div>
          </ListItemAvatar>
        ) : (
          ""
        )}

        {lang && !status ? (
          <ListItemAvatar className={classes.categoryName}>
            <Chip label={category_name} size="small" />
          </ListItemAvatar>
        ) : (
          ""
        )}
        <ListItemText>{text}</ListItemText>
        <ListItemSecondaryAction
          classes={{
            root: classes.secondaryAction,
          }}
        >
          {status && utcTime > data.deactivation_date ? (
            <Chip
              className={classes.infoOld}
              label={"old"}
              size="small"
              variant="outlined"
              color="secondary"
            />
          ) : (
            ""
          )}

          {status &&
          (utcTime <= data.activation_date || !data.activation_date) ? (
            <Chip
              className={classes.infoNotActive}
              label={"Not activated"}
              size="small"
              variant="outlined"
              color="secondary"
            />
          ) : (
            ""
          )}

          {status &&
          utcTime < data.deactivation_date &&
          utcTime >= data.activation_date ? (
            <Chip
              className={classes.infoActive}
              label={"Activated"}
              size="small"
              variant="outlined"
            />
          ) : (
            ""
          )}

          {!status && post_counts !== "" ? (
            <Chip
              className={classes.infoCounts}
              label={`${post_counts} time${post_counts > 1 ? "s" : ""} in post`}
              size="small"
              variant="outlined"
            />
          ) : (
            ""
          )}

          {!status && user_counts > 0 ? (
            <Tooltip
              title={
                <p>{`This message was already used for this user ${user_counts} time${
                  user_counts > 1 ? "s" : ""
                } in the past ${
                  this.props.item.replyConfig.reply_user_window
                } hours.`}</p>
              }
            >
              <Icon className={classes.infoIcon}>info</Icon>
            </Tooltip>
          ) : (
            ""
          )}

          {Object.entries(edit ? default_filter_languages : data.text).map(
            (item, key) => {
              if (item[1] === " ") {
                return true;
              }

              let colorClass = "";
              let className = classes.link + " " + classes.linkLang;

              if (!isAdmin) {
                colorClass =
                  percentiles[item[0]] === "green"
                    ? classes.usageOk
                    : percentiles[item[0]] === "yellow"
                    ? classes.usageWarning
                    : percentiles[item[0]] === "red"
                    ? classes.usageDanger
                    : "";

                className += " " + colorClass;
              }

              return (
                <Link
                  key={key}
                  className={className}
                  component="button"
                  color={
                    this.state[`${category_name}-${keyList}`] ===
                      item[edit ? 1 : 0] ||
                    ((lang
                      ? lang === item[edit ? 1 : 0]
                      : defaultLanguage === item[edit ? 1 : 0]) &&
                      this.state[`${category_name}-${keyList}`] === undefined)
                      ? "primary"
                      : "inherit"
                  }
                  onClick={(e) => {
                    this.selectLanguage(
                      keyList,
                      category_name,
                      item,
                      data.category_path,
                      isAdmin ? 0 : data.counts[item[0]],
                      isAdmin ? 0 : percentiles[item[0]]
                    );
                  }}
                >
                  {`${item[edit ? 1 : 0].toUpperCase()}${
                    !isAdmin ? ": " + data.counts[item[0]] : ""
                  }`}
                </Link>
              );
            }
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  createMessageList(data, category_name) {
    const { classes } = this.props;
    const { search, default_filter_languages, filter_languages } = this.state;

    return (
      <List className={classes.list}>
        {data &&
          data.map((item, key) => {
            let searchActivated = search && search.length > 1;
            let filterLanguagesActivated =
              default_filter_languages.length !== filter_languages.length;
            if (
              searchActivated ||
              filterLanguagesActivated ||
              (filterLanguagesActivated && searchActivated)
            ) {
              let result = Object.entries(item.text).find((message) => {
                return searchActivated
                  ? message[1].toLowerCase().includes(search.toLowerCase())
                  : searchActivated && filterLanguagesActivated
                  ? message[1].toLowerCase().includes(search.toLowerCase()) &&
                    filter_languages.includes(message[0])
                  : filter_languages.includes(message[0]);
              });

              if (result) {
                return this.createItem(item, key, category_name, result[0]);
              }
              return true;
            } else {
              return this.createItem(item, key, category_name);
            }
          })}
      </List>
    );
  }

  createCategoryContent(data, keyCategory) {
    const { classes } = this.props;
    return (
      <div key={keyCategory} className={classes.categoryContent}>
        {data.categories && data.categories.length > 0
          ? data.categories.map((item, key) => {
              return this.createCategory(item, key);
            })
          : this.createMessageList(data.messages, data.category_name)}
      </div>
    );
  }

  createCategory(data, keyCategory) {
    const { classes, edit, status } = this.props;
    const { search, filter_languages } = this.state;

    if (search && search.length > 1) {
      return this.createCategoryContent(data, keyCategory);
    }

    let found = false;
    if (data.messages && data.messages.length > 0) {
      for (const message of data.messages) {
        for (const langKey of Object.keys(message.text)) {
          if (filter_languages.indexOf(langKey) !== -1) {
            found = true;
          }
        }
      }
      if (!found) {
        return false;
      }
    }

    return (
      <div key={keyCategory} className={classes.categoryContainer}>
        {!status && data.messages && data.messages.length > 0 ? (
          <Link
            component="button"
            className={classes.buttonAutoSelect}
            onClick={(e) => {
              return this.autoSelectReply(data.messages);
            }}
          >
            Auto select
          </Link>
        ) : (
          ""
        )}
        {edit ? (
          <div className={classes.categoryActions}>
            <div
              className={classes.icon}
              onClick={(e) => this.openDialogCategory(e, data, "edit")}
            >
              <Tooltip title={<p>Edit category</p>}>
                <Icon>edit</Icon>
              </Tooltip>
            </div>
            <div
              className={classes.icon}
              onClick={(e) =>
                this.openDialogDeleteCategory(e, data.category_name)
              }
            >
              <Tooltip title={<p>Delete category</p>}>
                <Icon>delete</Icon>
              </Tooltip>
            </div>
            {data.categories && data.categories.length > 0 ? (
              ""
            ) : (
              <div
                className={classes.icon}
                onClick={(e) =>
                  this.openDialogMessage(
                    e,
                    "",
                    data,
                    filter_languages[0],
                    "add"
                  )
                }
              >
                <Tooltip title={<p>Add message</p>}>
                  <Icon>add</Icon>
                </Tooltip>
              </div>
            )}
            {data.messages && data.messages.length > 0 ? (
              ""
            ) : (
              <div
                className={classes.icon}
                onClick={(e) => this.openDialogCategory(e, data)}
              >
                <Tooltip title={<p>Add sub category</p>}>
                  <Icon>add_box</Icon>
                </Tooltip>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        <Panel
          expanded={this.props.status ? true : false}
          noShadow
          className={classes.panel}
          title={data.category_name}
          seconHeader={this.renderSecondHeaderCategory(data.count)}
        >
          {this.createCategoryContent(data, keyCategory)}
        </Panel>
      </div>
    );
  }

  renderSecondHeaderCategory(count) {
    const { classes, status } = this.props;
    let total_post_count = count !== undefined ? count : 0;

    if (!status) {
      return (
        <Chip
          className={classes.infoTotalCounts}
          label={`Total used in this Post: ${total_post_count}`}
          size="small"
          variant="outlined"
          color="primary"
        />
      );
    }

    return <div />;
  }

  //////////////////////////////////////////////////////////////////////////////////

  /////// REACT LIFE CYCLE ///////////////////////////////////////////////////////

  componentDidUpdate(prevProps) {
    const {
      selected,
      oldCategories,
      categories,
      defaultLanguage,
      filter_categories,
      old_filter_categories,
    } = this.state;

    const { data } = this.props;

    // item change
    if (prevProps.item !== this.props.item) {
      this.setState({
        item: this.props.item,
      });
    }

    // data change
    if (prevProps.data !== data) {
      this.setState({
        oldCategories:
          data && data.categories
            ? JSON.parse(JSON.stringify(data.categories))
            : [],
        categories: data && data.categories ? data.categories : [],
        messages: data && data.messages ? data.messages : [],
        filter_categories:
          data && data.categories
            ? data.categories.map((item) => item.category_name)
            : [],
      });
    }

    // languages change
    if (prevProps.availableLanguages !== this.props.availableLanguages) {
      this.setState({
        filter_languages: this.props.availableLanguages,
        default_filter_languages: this.props.availableLanguages,
      });
    }

    if (prevProps.defaultLanguage !== this.props.defaultLanguage) {
      this.setState({
        defaultLanguage: this.props.defaultLanguage,
      });
    }

    // cancel event
    if (
      prevProps.status !== this.props.status &&
      this.props.status === "cancel"
    ) {
      this.setState({
        filter_categories: old_filter_categories
          ? old_filter_categories
          : filter_categories,
        categories: oldCategories,
        [`${selected.category_name}-${selected.key}`]: defaultLanguage,
      });
    }

    // save event
    if (
      prevProps.status !== this.props.status &&
      this.props.status === "save"
    ) {
      this.setState({
        old_filter_categories: filter_categories,
        oldCategories: categories,
      });
      if (typeof this.props.saveEvent === "function") {
        this.props.saveEvent(categories);
      }
    }
  }

  render() {
    const {
      classes,
      width,
      hideFilterLanguages,
      hideSearch,
      displayItem,
      edit,
      status,
      scroll,
    } = this.props;
    const {
      messages,
      categories,
      filter_categories,
      default_filter_categories,
      default_filter_languages,
      filter_languages,
      search,
      messageEdit,
      categoryEdit,
      openDialogDeleteMessage,
      openDialogCategory,
      openDialogDeleteCategory,
      openDialogMessage,
      item,
    } = this.state;

    return (
      <div
        className={classNames(classes.root, {
          [classes.rootFull]: width,
        })}
      >
        <div className={classes.filtersContainer}>
          <SelectComponent
            id={"filter_categories"}
            label={"Filter Categories"}
            multiple={true}
            defaultValue={
              !status ? default_filter_categories : filter_categories
            }
            value={filter_categories}
            display={3}
            items={categories.map((category) => category.category_name)}
            onChange={this.handleChange.bind(this)}
          />
          {!hideFilterLanguages ? (
            <SelectComponent
              id={"filter_languages"}
              label={"Filter Languages"}
              multiple={true}
              value={filter_languages}
              display={3}
              items={default_filter_languages}
              onChange={this.handleChange.bind(this)}
            />
          ) : (
            ""
          )}
          {!hideSearch ? (
            <InputComponent
              id={"search"}
              label={"Search Message"}
              value={search}
              onChange={this.handleChange.bind(this)}
            />
          ) : (
            ""
          )}
          {edit ? (
            <Button
              variant="contained"
              size="small"
              className={classes.buttonSuccess}
              onClick={this.openDialogCategory.bind(this)}
            >
              Add Parent Category
            </Button>
          ) : (
            ""
          )}
        </div>
        {displayItem && item.message ? <Legend item={item} /> : ""}
        {categories &&
          categories.length &&
          item.replyConfig &&
          item.replyConfig.auto_select_disabled && (
            <div className={classes.alertMessage}>
              <WarningIcon className={classes.alertMessageIcon} />
              Auto select is not allowed for this client. Please choose the
              reply manually.
            </div>
          )}
        <div
          className={classNames(classes.listNoScroll, {
            [classes.listScroll]: scroll,
          })}
        >
          <div className={classes.listContainer}>
            {messages.length
              ? messages.map((item, key) => {
                  return this.createMessageList(item, key);
                })
              : ""}
            {categories.length
              ? categories
                  .filter((category) =>
                    filter_categories.includes(category.category_name)
                  )
                  .map((item, key) => {
                    return item.category_name
                      ? this.createCategory(item, key)
                      : this.createMessageList(item, key);
                  })
              : "no categories"}
          </div>
        </div>

        <AlertDialog
          title="Delete this message?"
          message="Delete the message from the category"
          buttonCancelText="Cancel"
          buttonAcceptText="Accept"
          open={openDialogDeleteMessage}
          action={this.actionDialogDeleteMessage.bind(this)}
        />
        <AlertDialog
          title="Delete this category?"
          message="Delete this category will remove all the nested items"
          buttonCancelText="Cancel"
          buttonAcceptText="Accept"
          open={openDialogDeleteCategory}
          action={this.actionDialogDeleteCategory.bind(this)}
        />
        <DialogContent
          title={"Category"}
          fullWidth={true}
          open={openDialogCategory}
          close={(e, reason) => {
            this.closeDialogCategory(e, reason);
          }}
        >
          <div>
            <div>
              <InputComponent
                id={"category_name"}
                label={"Category Name"}
                value={categoryEdit["category_name"]}
                onChange={this.handleChangeCategory.bind(this)}
              />
              <FormGroup className={classes.formGroupEditor}>
                <InputLabel className={classes.labelEditor}>
                  {"Filters"}
                </InputLabel>
                <JSONEditor
                  checkFilter={false}
                  placeholder={categoryEdit["filters"] || []}
                  height={"240px"}
                  onChange={(e) => {
                    return this.onChangeEditor(e, "filters");
                  }}
                  onError={(e) => {
                    return this.onErrorEditor(e);
                  }}
                />
              </FormGroup>
              <FormGroup className={classes.formGroupEditor}>
                <InputLabel className={classes.labelEditor}>
                  {"Auto check filters"}
                </InputLabel>
                <JSONEditor
                  checkFilter={false}
                  placeholder={categoryEdit["auto_check_filters"] || []}
                  height={"240px"}
                  onChange={(e) => {
                    return this.onChangeEditor(e, "auto_check_filters");
                  }}
                  onError={(e) => {
                    return this.onErrorEditor(e);
                  }}
                />
              </FormGroup>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                size="small"
                color="secondary"
                onClick={this.closeDialogCategory.bind(this)}
              >
                cancel
              </Button>
              <Button
                size="small"
                color="primary"
                onClick={this.addCategory.bind(this)}
              >
                save
              </Button>
            </div>
          </div>
        </DialogContent>

        <DialogContent
          title={"Edit Message"}
          fullWidth={true}
          open={openDialogMessage}
          close={(e, reason) => {
            this.closeDialogMessage(e, reason);
          }}
        >
          <div>
            <div>
              <Typography>Generic informations</Typography>
              <div>
                <DatePicker
                  settedValue={false}
                  formatDate={"MMM dd yyyy"}
                  name={"activation_date"}
                  label={"Activation"}
                  value={messageEdit["activation_date"]}
                  setSelectedDate={this.setSelectedDate.bind(this)}
                />
              </div>
              <div>
                <DatePicker
                  settedValue={false}
                  formatDate={"MMM dd yyyy"}
                  name={"deactivation_date"}
                  label={"Deactivation"}
                  value={messageEdit["deactivation_date"]}
                  setSelectedDate={this.setSelectedDate.bind(this)}
                />
              </div>
            </div>
            <div className={classes.inputEditMessage}>
              <Typography>{`Edit message for ${messageEdit[
                "languageSelected"
              ].toUpperCase()} language`}</Typography>
              <InputComponent
                id={"message"}
                label={`Edit message`}
                fullWidth={true}
                multiline={true}
                value={messageEdit["message"]}
                onChange={this.handleChangeMessage.bind(this)}
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                size="small"
                color="secondary"
                onClick={this.closeDialogMessage.bind(this)}
              >
                cancel
              </Button>
              <Button
                size="small"
                color="primary"
                onClick={() => this.updateMessage()}
              >
                save
              </Button>
            </div>
          </div>
        </DialogContent>
      </div>
    );
  }

  componentWillUnmount() {
    this.setState({
      oldCategories: [],
      categories: [],
    });
  }
}

NestedList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  selectedItem: PropTypes.func.isRequired,
  item: PropTypes.object,
  scroll: PropTypes.bool,
  displayItem: PropTypes.bool,
  status: PropTypes.string,
  width: PropTypes.string,
  edit: PropTypes.bool,
  hideFilterLanguages: PropTypes.bool,
  hideSearch: PropTypes.bool,
  saveEvent: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(NestedList);
