import { setStickFilter } from '../setters/stickyFilter';

export const extractStickyFilterList = data => {
  const list = [];

  if (data && data.length) {
    data.map((sf, key) => {
      list.push(setStickFilter(sf, key));
      return true;
    });
  }

  return list;
};
