import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';

import InputComponent from '../../commons/input/inputComponent';
import JSONEditor from '../../commons/jsonEditor/jsonEditorComponent';
import SearchSelectMulti from '../../commons/select/searchSelectMultiComponent';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  formControlContainer: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  formGroup: {
    width: '80%'
  },
  formGroupEditor: {
    marginTop: theme.spacing(6),
    width: '80%'
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  item: {
    margin: 2
  }
});

class StreamsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userList: props.userList,
      roleList: props.roleList,
      checkFilter: props.checkFilter,
      typeList: props.typeList,
      tagList: props.tagList || [],
      data: props.defaultValue
        ? props.defaultValue
        : {
            id: '',
            label: '',
            filters: [],
            users: [],
            exclusion_filters: [],
            tags: [],
            clientTags: []
          },
      error: props.error
        ? props.error
        : {
            id: '',
            label: '',
            tags: '',
            filters: ''
          }
    };
  }

  onErrorEditor(e) {
    this.props.onErrorStreamForm(e);
  }

  onChangeEditor(e, value) {
    this.setState(
      {
        data: {
          ...this.state.data,
          [value]: e
        }
      },
      () => {
        this.props.onChangeStreamForm(this.state.data);
      }
    );
  }

  setValue(e, value) {
    this.setState(
      {
        data: {
          ...this.state.data,
          [value]:
            value === 'tags'
              ? e.target.value.filter(i => i !== 'search')
              : e.target && e.target.value
              ? e.target.value
              : e
        }
      },
      () => {
        this.props.onChangeStreamForm(this.state.data);
      }
    );
  }
  resetOptionalValues(e, value) {
    this.setState(
      {
        data: {
          ...this.state.data,
          time_interval: '',
          timeout: '',
          tags: []
        }
      },
      () => {
        this.setValue(e, value);
      }
    );
  }

  onChange(e, value) {
    if (value === 'type' && e.target.value !== 'check_alert') {
      this.resetOptionalValues(e, value);
    } else {
      this.setValue(e, value);
    }
  }

  displayTextField(value, label, required = false, isDisabled = false) {
    const { classes, defaultValue, mode } = this.props;
    const { data, error } = this.state;
    return (
      <FormGroup className={classes.formGroup}>
        <InputComponent
          required={required}
          disabled={isDisabled && mode === 'edit' ? true : false}
          defaultValue={
            Object.values(defaultValue).length
              ? defaultValue[value]
              : data[value]
          }
          onChange={e => {
            return this.onChange(e, value);
          }}
          error={error[value] ? true : false}
          helperText={error[value]}
          label={label}
          margin="dense"
          fullWidth={true}
        />
      </FormGroup>
    );
  }

  displayFilters(value, label, mandatory) {
    const { classes } = this.props;
    const { data, error, checkFilter } = this.state;
    return (
      <FormGroup className={classes.formGroupEditor}>
        <InputLabel>
          {label} {mandatory ? '*' : ''}
        </InputLabel>
        <JSONEditor
          checkFilter={checkFilter}
          placeholder={data[value] || []}
          height={'240px'}
          onChange={e => {
            return this.onChangeEditor(e, value);
          }}
          onError={e => {
            return this.onErrorEditor(e);
          }}
        />
        {mandatory && error[value] ? (
          <FormHelperText>{error[value]}</FormHelperText>
        ) : (
          ''
        )}
      </FormGroup>
    );
  }

  displayMultipleSelect(
    id,
    label,
    isRequired = false,
    items,
    attributeId,
    attributeName
  ) {
    const { classes } = this.props;
    const { data, error } = this.state;
    return (
      <FormGroup className={classes.formGroup}>
        <InputLabel>
          {label} {isRequired ? '*' : ''}
        </InputLabel>
        <Select
          multiple
          error={error[id] ? true : false}
          value={data[id]}
          onChange={e => {
            return this.onChange(e, id);
          }}
          input={<Input id={id} />}
          renderValue={selected => (
            <div className={classes.tags}>
              {selected.map((value, key) => (
                <Chip
                  key={key}
                  label={
                    items.find(i => value === i[attributeId])
                      ? items.find(i => value === i[attributeId])[attributeName]
                      : value
                  }
                  className={classes.tag}
                />
              ))}
            </div>
          )}
        >
          {items.map((i, key) => (
            <MenuItem key={key} value={i[attributeId]}>
              {i[attributeName]}
            </MenuItem>
          ))}
        </Select>
        {error[id] ? <FormHelperText>{error[id]}</FormHelperText> : ''}
      </FormGroup>
    );
  }

  renderChip(list, value, id, item, key) {
    const { classes } = this.props;
    return (
      <Chip
        key={key}
        label={
          list.find(i => value === i[id])
            ? item !== null
              ? list.find(i => value === i[id])[item]
              : value
            : value
        }
        className={classes.item}
      />
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.typeList !== this.props.typeList) {
      this.setState({
        typeList: this.props.typeList
      });
    }

    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({
        data: this.props.defaultValue
      });
    }

    if (prevProps.checkFilter !== this.props.checkFilter) {
      this.setState({
        checkFilter: this.props.checkFilter
      });
    }

    if (prevProps.userList !== this.props.userList) {
      this.setState({
        userList: this.props.userList
      });
    }

    if (prevProps.error !== this.props.error) {
      this.setState({
        error: this.props.error
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { loading, tagList, userList, roleList, data } = this.state;
    return (
      <div className={classes.root}>
        {loading ? (
          <CircularProgress />
        ) : (
          <div className={classes.formControlContainer}>
            <FormControl className={classes.formControl}>
              {this.displayTextField('id', 'ID', true, true)}
            </FormControl>
            <FormControl className={classes.formControl}>
              {this.displayTextField('label', 'Label', true, false)}
            </FormControl>
            <FormControl className={classes.formControl}>
              <SearchSelectMulti
                name={'tags'}
                label={'Tags'}
                list={tagList}
                id={'id'}
                attribute={'label'}
                data={data['tags']}
                onChange={(e, name) => this.onChange(e, name)}
                renderItem={(i, id, attribute, name, key) =>
                  this.renderChip(i, id, attribute, name, key)
                }
                enableCopyPaste={true}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              {this.displayMultipleSelect(
                'users',
                'Access Users',
                false,
                userList,
                'id',
                'username'
              )}
            </FormControl>
            <FormControl className={classes.formControl}>
              {this.displayMultipleSelect(
                'roles',
                'Access Roles',
                false,
                roleList,
                'id',
                'id'
              )}
            </FormControl>
            <FormControl className={classes.formControl}>
              {this.displayFilters('filters', 'Filters', true)}
            </FormControl>
            <FormControl className={classes.formControl}>
              {this.displayFilters(
                'exclusion_filters',
                'Exclusion filters',
                false
              )}
            </FormControl>
          </div>
        )}
      </div>
    );
  }
}

StreamsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onChangeStreamForm: PropTypes.func.isRequired,
  onErrorStreamForm: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  checkFilter: PropTypes.bool,
  error: PropTypes.object,
  typeList: PropTypes.array,
  roleList: PropTypes.array,
  userList: PropTypes.array,
  tagList: PropTypes.array,
  mode: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(StreamsForm);
