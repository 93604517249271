import axios from "axios";
import { adbastionReportsConstants } from "../../constants/configuration/adbastionReportsConstants";
import { setHeaders } from "../../setters/auth";

export const fetchAdbastionReportsList = (clientShortName) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/cfa/reports/${clientShortName}`;

  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: adbastionReportsConstants.FETCH_ADBASTION_REPORTS_LIST,
    payload: promise,
  });
};

export const deleteAdbastionReport = (id) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/cfa/reports/${id}`;

  const promise = axios.delete(url, {
    headers: setHeaders(),
    id: id,
  });

  return dispatch({
    type: adbastionReportsConstants.DELETE_ADBASTION_REPORT,
    payload: promise,
  });
};

export const downloadAdbastionReportUrl = (reportId) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/cfa/reports/${reportId}/url/download`;

  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: adbastionReportsConstants.GET_ADBASTION_REPORT_DOWNLOAD_URL,
    payload: promise,
  });
};

export const getAdbastionReportUploadUrl = () => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/cfa/reports/url/upload`;

  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: adbastionReportsConstants.GET_ADBASTION_REPORT_UPLOAD_URL,
    payload: promise,
  });
};

export const uploadAdbastionReport = (data) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/cfa/reports`;

  const promise = axios.post(
    url,
    {
      id: data.id,
      client_short_name: data.client_short_name,
      description: data.description,
      name: data.name,
    },
    {
      headers: setHeaders(),
      addData: {
        ...data,
        status: true,
      },
    }
  );

  return dispatch({
    type: adbastionReportsConstants.UPLOAD_ADBASTION_REPORT,
    payload: promise,
  });
};

export const uploadAdbastionReportAws = (url, file) => (dispatch) => {
  const promise = axios.put(url, file, {
    headers: {
      "Content-Type": "",
    },
  });

  return dispatch({
    type: adbastionReportsConstants.UPLOAD_ADBASTION_REPORT_AWS,
    payload: promise,
  });
};
