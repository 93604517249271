import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import TableComponent from "../../commons/table/tableComponent";
import DialogContent from "../../commons/dialog/contentDialogComponent";

import StreamsForm from "./streamsForm";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 7,
  },
  buttonAddStream: {
    marginLeft: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

class StreamsConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagSelected: 0,
      openDialogStreams: false,
      checkFilter: false,
      mode: "add",
      defaultValue: {},
      error: {},
      userList: props.userList,
      roleList: props.roleList,
      editable: props.editable,
      creatable: props.creatable,
      tagList: props.tagList,
      onErrorFilter: {
        filters: false,
        exclusion_filters: false,
      },
      columns: [
        {
          title: "ID",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          field: "id",
        },
        {
          title: "Label",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          field: "label",
        },
        {
          title: "Tags",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          render: (rowData) => this.displayFilters(rowData.tags),
          field: "tags",
        },
        {
          title: "Filters",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          render: (rowData) => this.displayFilters(rowData.filters),
          field: "filters",
        },
        {
          title: "Exclusion filters",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          render: (rowData) => this.displayFilters(rowData.exclusion_filters),
          field: "exclusion_filters",
        },
        {
          title: "Access Uers",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          render: (rowData) => this.displayFilters(rowData.users),
          field: "users",
        },
        {
          title: "Access Roles",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          render: (rowData) => this.displayFilters(rowData.roles),
          field: "roles",
        },
      ],
      data: props.streamList || [],
      actions: this.onActionStreams(props.editable),
      stream: {},
    };
  }

  displayFilters(filters) {
    return <div>{filters ? filters.length : "0"}</div>;
  }

  onActionStreams(editable) {
    return editable
      ? [
          {
            icon: "edit",
            tooltip: "Edit stream",
            onClick: (event, rowData) => this.streamEditStream(event, rowData),
          },
        ]
      : [];
  }

  onChangeStreamForm(data) {
    this.setState({
      onErrorFilter: {
        filters: false,
        exclusion_filters: false,
      },
      checkFilter: false,
      stream: {
        id: data.id,
        label: data.label,
        filters: data.filters,
        exclusion_filters: data.exclusion_filters,
        users: data.users,
        roles: data.roles,
        tags: data.tags,
      },
    });
  }

  onErrorStreamForm(e, value) {
    this.setState({
      onErrorFilter: {
        ...this.state.onErrorFilter,
        [value]: true,
      },
    });
  }

  checkForErrors() {
    const { stream, onErrorFilter } = this.state;
    return new Promise((resolve, reject) => {
      this.setState(
        {
          error: {
            id: stream.id ? "" : "ID is mandatory",
            label: stream.label ? "" : "Label is mandatory",
            tags: stream.tags.length ? "" : "Tag(s) is mandatory",
            filters: !stream.filters.length
              ? "Filters is madatory"
              : onErrorFilter.filter
              ? "JSON not valid"
              : "",
          },
        },
        () => {
          resolve();
        }
      );
    });
  }

  updateStream = () => {
    this.setState(
      {
        checkFilter: true,
      },
      () => {
        setTimeout(() => {
          this.checkForErrors().then(() => {
            if (
              !Object.values(this.state.error).filter((value) => value !== "")
                .length
            ) {
              if (this.state.mode === "add") {
                this.props.addStream(this.state.stream);
              } else {
                this.props.editStream(
                  this.state.tagSelected,
                  this.state.stream
                );
              }
              this.closeDialogStreams();
            }
          });
        }, 100);
      }
    );
  };

  openDialogStreams() {
    this.setState({
      openDialogStreams: true,
    });
  }

  closeDialogStreams = () => {
    this.setState({
      openDialogStreams: false,
    });
  };

  streamAddStream(e) {
    const defaultValue = {
      id: "",
      label: "",
      filters: [],
      exclusion_filters: [],
      users: [],
      roles: [],
      tags: [],
    };

    this.setState(
      {
        mode: "add",
        defaultValue: defaultValue,
        stream: defaultValue,
      },
      () => {
        this.openDialogStreams();
      }
    );
  }

  streamEditStream(e, data) {
    const defaultValue = {
      id: data.id,
      label: data.label,
      tags: data.tags,
      filters: data.filters,
      exclusion_filters: data.exclusion_filters,
      users: data.users,
      roles: data.roles,
    };

    this.setState(
      {
        mode: "edit",
        tagSelected: data.tableData.id,
        defaultValue: defaultValue,
        stream: defaultValue,
      },
      () => {
        this.openDialogStreams();
      }
    );
  }

  displayButtonsStream() {
    const { classes } = this.props;
    const { mode } = this.state;
    return (
      <div className={classes.buttonContainer}>
        <Button size="small" color="primary" onClick={this.updateStream}>
          {mode === "add" ? "Add stream" : "Edit stream"}
        </Button>
        <Button
          onClick={this.closeDialogStreams}
          size="small"
          color="secondary"
        >
          Cancel
        </Button>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.streamList !== this.props.streamList) {
      this.setState({
        data: this.props.streamList,
      });
    }

    if (prevProps.tagList !== this.props.tagList) {
      this.setState({
        tagList: this.props.tagList,
      });
    }

    if (prevProps.userList !== this.props.userList) {
      this.setState({
        userList: this.props.userList,
      });
    }

    if (prevProps.roleList !== this.props.roleList) {
      this.setState({
        roleList: this.props.roleList,
      });
    }

    if (prevProps.creatable !== this.props.creatable) {
      this.setState({
        creatable: this.props.creatable,
      });
    }

    if (prevProps.editable !== this.props.editable) {
      this.setState({
        editable: this.props.editable,
        actions: this.onCreateStreams(this.props.editable),
      });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      columns,
      data,
      actions,
      openDialogStreams,
      defaultValue,
      error,
      roleList,
      userList,
      creatable,
      tagList,
      checkFilter,
      mode,
    } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h5">stream list</Typography>
          {creatable ? (
            <Button
              variant="contained"
              className={classes.buttonAddStream}
              size="small"
              color="primary"
              onClick={(e) => {
                return this.streamAddStream(e);
              }}
            >
              Add stream
            </Button>
          ) : (
            ""
          )}
        </div>
        <TableComponent
          editable={false}
          columns={columns}
          data={data}
          actions={actions}
          title=""
        />
        <DialogContent
          title={"stream"}
          open={openDialogStreams}
          fullWidth={true}
          close={this.closeDialogStreams.bind(this)}
        >
          <div>
            <StreamsForm
              mode={mode}
              defaultValue={defaultValue}
              onChangeStreamForm={this.onChangeStreamForm.bind(this)}
              onErrorStreamForm={this.onErrorStreamForm.bind(this)}
              error={error}
              roleList={roleList}
              userList={userList}
              checkFilter={checkFilter}
              tagList={tagList}
            />
            {this.displayButtonsStream()}
          </div>
        </DialogContent>
      </div>
    );
  }
}

StreamsConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  addStream: PropTypes.func.isRequired,
  editStream: PropTypes.func.isRequired,
  streamList: PropTypes.array,
  editable: PropTypes.bool,
  creatable: PropTypes.bool,
  tagList: PropTypes.array,
  roleList: PropTypes.array,
  userList: PropTypes.array,
};

export default withStyles(styles, { withTheme: true })(StreamsConfiguration);
