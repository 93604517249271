import { setGuidelineGroup } from '../setters/guidelineGroup';

export const extractGuidelineGroupList = data => {
  const list = [];

  if (data && data.length) {
    data.map((sf, key) => {
      list.push(setGuidelineGroup(sf, key));
      return true;
    });
  }

  return list;
};
