import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import UserConfiguration from './usersConfiguration';

import {
  fetchUserList,
  updateUserRoles
} from '../../../actions/configuration/usersConfigurationAction';

const styles = theme => ({
  root: {
    width: '100%'
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 367px)',
    marginTop: 'calc(50vh - 96px)'
  }
});

const mapStateToProps = state => ({
  user: state.usersConfigurationReducer,
  rolesAndPermissionsReducer: state.rolesAndPermissionsReducer
});

const mapDispatchToProps = dispatch => ({
  fetchUserList: () => dispatch(fetchUserList()),
  updateUserRoles: (index, userId, roles) =>
    dispatch(updateUserRoles(index, userId, roles))
});

class UsersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
      roleList: [],
      permissionGroups: [],
      loading: true,
      userList: [],
      editable: false,
      notAllowed: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.user.userList !== prevState.userList) {
      update.userList = nextProps.user.userList;
    }

    if (nextProps.rolesAndPermissionsReducer.roleList !== prevState.roleList) {
      update.roleList = nextProps.rolesAndPermissionsReducer.roleList;
    }

    return Object.keys(update).length ? update : null;
  }

  updateUserRoles(index, userId, roles) {
    this.props
      .updateUserRoles(index, userId, roles)
      .then(() => {})
      .catch(err => {
        this.props.fetchUserList();
      });
  }

  initialize() {
    if (
      this.props.permissions.includes('admin_list_users') &&
      this.props.permissions.includes('admin_list_roles')
    ) {
      this.props.fetchUserList().then(() => {
        this.setState({
          loading: false
        });
      });
      if (this.props.permissions.includes('admin_update_user_roles')) {
        this.setState({
          editable: true
        });
      }
    } else {
      this.setState({
        notAllowed: true,
        loading: false
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tabIndex === 1 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true
        },
        this.initialize
      );
    }
  }

  componentDidMount() {
    if (this.props.tabIndex === 1 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true
        },
        this.initialize
      );
    }
  }

  render() {
    const { classes } = this.props;
    const { roleList, loading, notAllowed, userList, editable } = this.state;

    return (
      <div className={classes.root}>
        {!notAllowed && !loading ? (
          <UserConfiguration
            roleList={roleList}
            userList={userList}
            editable={editable}
            updateUserRoles={this.updateUserRoles.bind(this)}
          />
        ) : !notAllowed ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <Typography className={classes.notAllowed} variant="body1">
            Not Allowed
          </Typography>
        )}
      </div>
    );
  }
}

UsersContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  tabIndex: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(UsersContainer));
