import { groupsConstants } from '../../constants/configuration/groupsConstants';

const initialState = {
  fetching: false,
  fetched: false,
  groupList: [],
  error: null
};

const Groups = (state = initialState, action) => {
  switch (action.type) {
    // FETCH GROUP LIST
    case `${groupsConstants.FETCH_GROUP_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${groupsConstants.FETCH_GROUP_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${groupsConstants.FETCH_GROUP_LIST}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        groupList: action.payload.data
      };
    // ADD GROUP
    case `${groupsConstants.ADD_GROUP}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${groupsConstants.ADD_GROUP}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${groupsConstants.ADD_GROUP}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        groupList: [
          ...state.groupList,
          {
            id: action.payload.config.groupInfo.id,
            label: action.payload.config.groupInfo.label,
            description: action.payload.config.groupInfo.description,
            filters: action.payload.config.groupInfo.filters
          }
        ]
      };
    // EDIT GROUP
    case `${groupsConstants.EDIT_GROUP}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${groupsConstants.EDIT_GROUP}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${groupsConstants.EDIT_GROUP}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        groupList: Object.assign([], {
          ...state.groupList,
          [action.payload.config.index]: {
            id: action.payload.config.groupInfo.id,
            label: action.payload.config.groupInfo.label,
            description: action.payload.config.groupInfo.description,
            filters: action.payload.config.groupInfo.filters
          }
        })
      };
    default:
      return state;
  }
};

export default Groups;
