import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { makeStyles, useTheme } from '@material-ui/core/styles';

import TableComponent from '../../commons/table/tableComponent';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  }
}));

const PostKPIsConfiguration = props => {
  const classes = useStyles();
  const theme = useTheme();

  // Init
  const { postKPIsList } = props;

  // Table schema
  const tableColumns = [
    {
      title: 'Task ID',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'task_id'
    },
    {
      title: 'Task Type',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'task_type_pretty'
    },
    {
      title: 'Task Duration',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'task_duration'
    },
    {
      title: 'Task End Time',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'task_end_time'
    },
    {
      title: 'Refreshed Items',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'refreshed_items'
    },
    {
      title: 'Crawled Items',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'crawled_items'
    },
    {
      title: 'Updated Items',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'updated_items'
    },
    {
      title: 'Inserted Items',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'inserted_items'
    }
  ];

  return (
    <div className={classes.root}>
      <TableComponent
        editable={false}
        columns={tableColumns}
        data={postKPIsList}
        title=""
      />
    </div>
  );
};

PostKPIsConfiguration.propTypes = {
  postKPIsList: PropTypes.array.isRequired
};

export default PostKPIsConfiguration;
