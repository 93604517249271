import axios from 'axios';

import { platformsConstants } from '../../constants/configuration/platformsConstants';
import { setHeaders } from '../../setters/auth';

export const fetchPlatformList = () => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/platforms`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: platformsConstants.FETCH_PLATFORM_LIST,
    payload: promise
  });
};
