import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Redirect } from "react-router-dom";

import AppBar from "../../components/commons/appBar/AppBarComponent";
import LeftMenu from "../../components/commons/leftMenu/leftMenuComponent";
import TabsComponent from "../../components/commons/tabs/tabsComponent";

import ActionsContainer from "../../components/configuration/actions/actionsContainer";
import ClaraContainer from "../../components/configuration/clara/claraContainer";
import AssetsContainer from "../../components/configuration/assets/assetsContainer";
import GroupsContainer from "../../components/configuration/groups/groupsContainer";
import RepliesContainer from "../../components/configuration/replies/repliesContainer";
import TagsExampleContainer from "../../components/configuration/tagsExample/tagsExampleContainer";
import StickyFiltersContainer from "../../components/configuration/stickyFilters/stickyFiltersContainer";
import GuidelineGroupsContainer from "../../components/configuration/guidelineGroups/guidelineGroupsContainer";
import ModerationGuidelinesContainer from "../../components/configuration/moderationGuidelines/moderationGuidelinesContainer";
import AdbastionReportsContainer from "../../components/configuration/adbastionReports/adbastionReportsContainer";

import TaskHistoryContainer from "../../components/configuration/taskHistory/taskHistoryContainer";
import ChannelWhitelistContainer from "../../components/configuration/channelWhitelist/channelWhitelistContainer";

import { fetchMe } from "../../actions/meAction";
import { fetchClient } from "../../actions/clientSingleAction";

const mapDispatchToProps = (dispatch) => ({
  fetchMe: () => dispatch(fetchMe()),
  fetchClient: (clientShortName) => dispatch(fetchClient(clientShortName)),
});

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  content: {
    width: "100%",
    paddingTop: theme.spacing(5),
  },
  breadcrumbsContainer: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(8),
  },
  tabsContainer: {
    padding: theme.spacing(8),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  link: {
    cursor: "pointer",
    color: theme.palette.black,
  },
});

class ClientConfigurationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      redirectConfiguration: false,
      hasDefaultValue: props.location.state
        ? props.location.state.hasDefaultValue
        : 0,
      clientTags: props.location.state ? props.location.state.clientTags : [],
      clientShortName:
        props.location.state && props.location.state.clientShortName
          ? props.location.state.clientShortName
          : window.location.pathname.replace("/admin/", ""),
      open: false,
      loading: true,
      client: null,
      permissions: [],
      taskHistoryViewable: false,
      channelWhitelistViewable: false,
      tabIndex: props.location.state ? props.location.state.hasDefaultValue : 0,
    };
  }

  logout() {
    this.props.signOut();
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  redirectConfiguration() {
    this.setState({
      redirectConfiguration: true,
    });
  }

  breadcrumbs(clientShortName) {
    const { classes } = this.props;
    return (
      <Breadcrumbs aria-label="Breadcrumb">
        <Link
          className={classes.link}
          onClick={this.redirectConfiguration.bind(this)}
        >
          admin
        </Link>
        <Typography>{clientShortName}</Typography>
      </Breadcrumbs>
    );
  }

  onChangeTabIndex = (tabIndex) => {
    this.setState({
      tabIndex,
    });
  };

  componentDidMount() {
    this.props.fetchMe().then((response) => {
      this.props
        .fetchClient(this.state.clientShortName)
        .then((responseClient) => {
          this.setState({
            loading: false,
            client: responseClient.data,
            clientTags: responseClient.data.tags,
          });
        });
      if (
        response.value.data.permissions.includes("copyright_admin_get_history")
      ) {
        this.setState({
          taskHistoryViewable: true,
        });
      }
      if (
        response.value.data.permissions.includes(
          "copyright_admin_channel_whitelist_get"
        )
      ) {
        this.setState({
          channelWhitelistViewable: true,
        });
      }
      this.setState({
        permissions: response.value.data.permissions,
      });
    });
  }

  render() {
    const { classes } = this.props;
    const {
      open,
      loading,
      clientShortName,
      redirectConfiguration,
      hasDefaultValue,
      clientTags,
      permissions,
      taskHistoryViewable,
      channelWhitelistViewable,
      tabIndex,
    } = this.state;

    if (redirectConfiguration) {
      return (
        <Redirect
          to={{
            pathname: `/admin`,
            state: {
              hasDefaultValue: 4,
            },
          }}
        />
      );
    }

    const tabs = [
      "Tags example",
      "Actions",
      "Assets",
      "Groups",
      "Replies",
      "Clara",
      "AB Sticky Filters",
      "AB Guideline Groups",
      "AB Moderation Guidelines",
      "AB Reports",
    ];

    if (
      taskHistoryViewable &&
      this.state.client &&
      this.state.client.copyright_client
    ) {
      tabs.push("Task History");
    }

    if (
      channelWhitelistViewable &&
      this.state.client &&
      this.state.client.copyright_client
    ) {
      tabs.push("Channel Whitelist");
    }

    return (
      <div className={classes.root}>
        <AppBar
          action={this.handleDrawerOpen}
          status={open}
          selected={"admin"}
          logout={this.logout.bind(this)}
        />
        <LeftMenu open={open} onClose={this.handleDrawerClose.bind(this)} />
        <main className={classes.content}>
          <div className={classes.breadcrumbsContainer}>
            {this.breadcrumbs(clientShortName)}
          </div>
          <div className={classes.tabsContainer}>
            {!loading && (
              <TabsComponent
                hasDefaultValue={hasDefaultValue}
                tabs={tabs}
                variant="scrollable"
                onChangeIndex={this.onChangeTabIndex.bind(this)}
              >
                <TagsExampleContainer
                  clientShortName={clientShortName}
                  permissions={permissions}
                  tabIndex={tabIndex}
                />
                <ActionsContainer
                  clientShortName={clientShortName}
                  clientTags={clientTags}
                  permissions={permissions}
                  tabIndex={tabIndex}
                />
                <AssetsContainer
                  clientShortName={clientShortName}
                  permissions={permissions}
                  tabIndex={tabIndex}
                />
                <GroupsContainer
                  clientShortName={clientShortName}
                  permissions={permissions}
                  tabIndex={tabIndex}
                />
                <RepliesContainer
                  clientShortName={clientShortName}
                  clientTags={clientTags}
                  permissions={permissions}
                  tabIndex={tabIndex}
                />
                <ClaraContainer
                  clientShortName={clientShortName}
                  clientTags={clientTags}
                  permissions={permissions}
                  tabIndex={tabIndex}
                />
                <StickyFiltersContainer
                  clientShortName={clientShortName}
                  clientTags={clientTags}
                  permissions={permissions}
                  tabIndex={tabIndex}
                />
                <GuidelineGroupsContainer
                  clientShortName={clientShortName}
                  clientTags={clientTags}
                  permissions={permissions}
                  tabIndex={tabIndex}
                />
                <ModerationGuidelinesContainer
                  clientShortName={clientShortName}
                  clientTags={clientTags}
                  permissions={permissions}
                  tabIndex={tabIndex}
                />
                <AdbastionReportsContainer
                  clientShortName={clientShortName}
                  clientTags={clientTags}
                  permissions={permissions}
                  tabIndex={tabIndex}
                />
                {taskHistoryViewable && (
                  <TaskHistoryContainer
                    clientShortName={clientShortName}
                    permissions={permissions}
                    tabIndex={tabIndex}
                  />
                )}
                {channelWhitelistViewable && (
                  <ChannelWhitelistContainer
                    clientShortName={clientShortName}
                    permissions={permissions}
                    tabIndex={tabIndex}
                  />
                )}
              </TabsComponent>
            )}
          </div>
        </main>
      </div>
    );
  }
}

ClientConfigurationPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ClientConfigurationPage));
