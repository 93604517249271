import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'flex-start',
    minHeight: 'calc(100vh - 302px)'
  },
  container: {
    height: 'auto',
    width: '100%',
    border: `3px dashed ${theme.palette.container.light}`,
    marginTop: theme.spacing(1),
    minWidth: 300,
    position: 'relative'
  },
  rulesContainer: {
    height: 'calc(100vh - 465px)',
    overflow: 'auto'
  },
  versionsContainer: {
    marginBottom: theme.spacing(1)
  },
  ruleContent: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    textAlign: 'left'
  },
  containerAutoProcessed: {
    boxShadow: theme.palette.shadow.shadowPositiveSmall
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 8
  },
  tagItem: {
    marginRight: theme.spacing(1)
  }
});

class RulesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      editable: props.editable,
      editVersion: false
    };
  }

  onChangeVersion() {
    this.setState({
      editVersion: !this.state.editVersion
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editable !== this.props.editable) {
      this.setState({
        editable: this.props.editable
      });
    }
  }

  createRuleConainer(rule, tagList) {
    const { classes } = this.props;
    return (
      <Card
        className={classNames(classes.ruleContent, {
          [classes.containerAutoProcessed]: rule.autoprocess
        })}
      >
        <div className={classes.ruleTags}>
          <div>
            Tags:{' '}
            {rule.tags.map((tag, keyTag) => {
              return (
                <Chip
                  key={keyTag}
                  size="small"
                  label={
                    tagList.length && tagList.find(i => tag === i.id)
                      ? tagList.find(i => tag === i.id).label
                      : tag
                  }
                  className={classes.tagItem}
                />
              );
            })}
          </div>
        </div>
        <div className={classes.ruleAutoProcessed}>
          <div>Auto process: {rule.autoprocess ? 'ON' : 'OFF'}</div>
        </div>
        <div className={classes.ruleFilters}>
          <div>Filters: {rule.filters.length}</div>
        </div>
      </Card>
    );
  }

  render() {
    const { classes, rules, tagList, version_selected } = this.props;
    const { editable } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Typography variant="overline">{'Version'}</Typography>
          <div className={classes.versionsContainer}>
            <Chip
              size="small"
              label={
                version_selected ? version_selected : 'no version selected'
              }
            />
          </div>
        </div>
        <div className={classes.container}>
          <Typography variant="overline">{'Rules'}</Typography>
          <div className={classes.rulesContainer}>
            {rules.map((rule, key) => {
              return (
                <div key={key}>{this.createRuleConainer(rule, tagList)}</div>
              );
            })}
          </div>
        </div>
        <div className={classes.buttonContainer}>
          {editable ? (
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={e => {
                return this.props.onEdit();
              }}
            >
              {'Edit Classifier'}
            </Button>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

RulesList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  rules: PropTypes.array.isRequired,
  version_selected: PropTypes.string,
  editable: PropTypes.bool.isRequired,
  tagList: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(RulesList);
