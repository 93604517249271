// Packages
import React, { useState } from "react";
import PropTypes from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Components
import DialogContent from "../../commons/dialog/contentDialogComponent";
import AlertDialog from "../../commons/dialog/alertDialogComponent";
import TableComponent from "../../commons/table/tableComponent";
import AdbastionReportsForm from "./adbastionReportsForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
    "& .MuiInput-root": {
      marginTop: "0 !important",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 7,
  },
  buttonAdd: {
    marginLeft: theme.spacing(2),
  },
  detailContainer: {
    display: "flex",
  },
  detailItem: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.container.light,
  },
  detailLegend: {
    padding: theme.spacing(1),
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
  },
  detailContent: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    overflow: "auto",
  },
  detailCell: {
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const AdbastionReportsConfiguration = (props) => {
  const classes = useStyles();

  // Init
  const {
    adbastionReportsList,
    isCreatable,
    isDeletable,
    deleteAdbastionReport,
    downloadAdbastionReportUrl,
    uploadAdbastionReport,
  } = props;

  const initialFormData = {
    name: "",
    description: "",
    file: {},
  };

  // Hooks
  const [isFormOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [selectedReport, setSelectedReport] = useState({});
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  // Table schema
  const tableColumns = [
    {
      title: "Id",
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14,
      },
      field: "id",
      editComponent: (props) => <p>{props.value}</p>,
    },
    {
      title: "Name",
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14,
      },
      field: "name",
      editComponent: (props) => <p>{props.value}</p>,
    },
    {
      title: "Description",
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14,
      },
      field: "description",
      editComponent: (props) => <p>{props.value}</p>,
    },
  ];

  const onChangeFormData = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onAddReport = () => {
    setFormOpen(true);
  };

  const onCloseDialogForm = () => {
    setFormOpen(false);
  };

  const addReport = () => {
    checkForErrors().then((formErrors) => {
      if (Object.keys(formErrors).length === 0) {
        uploadAdbastionReport(formData);
        onCloseDialogForm();
        setFormData(initialFormData);
      } else {
        setFormErrors(formErrors);
      }
    });
  };

  const checkForErrors = () => {
    return new Promise((resolve) => {
      const formErrors = {};

      for (let field in formData) {
        let errorMessage = null;

        switch (field) {
          case "name":
            if (formData[field] === "") {
              errorMessage = "Name is mandatory";
            }
            break;
          case "description":
            if (formData[field] === "") {
              errorMessage = "Description is mandatory";
            }
            break;
          case "file":
            if (
              formData[field] &&
              Object.keys(formData[field]).length === 0 &&
              formData[field].constructor === Object
            ) {
              errorMessage = "File is mandatory";
            }
            break;
          default:
            break;
        }
        if (errorMessage) {
          formErrors[field] = errorMessage;
        }
      }

      resolve(formErrors);
    });
  };

  const actions = [];

  if (isDeletable) {
    actions.push({
      icon: "delete",
      tooltip: "Delete report",
      onClick: (e, rowData) => {
        setSelectedReport(rowData);
        setDeleteDialogOpen(true);
      },
    });
  }

  actions.push({
    icon: "download",
    tooltip: "Download report",
    onClick: (e, rowData) => {
      downloadAdbastionReportUrl(rowData.id);
    },
  });

  const actionDeleteReport = (confirm) => {
    if (confirm) deleteAdbastionReport(selectedReport.id);

    setDeleteDialogOpen(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h5">Adbastion Reports</Typography>
        {isCreatable && (
          <Button
            variant="contained"
            className={classes.buttonAdd}
            size="small"
            color="primary"
            onClick={(e) => {
              return onAddReport(e);
            }}
          >
            Add Report
          </Button>
        )}
      </div>
      <TableComponent
        columns={tableColumns}
        data={Object.assign([], adbastionReportsList)}
        title=""
        actions={actions}
        options={{
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
      <DialogContent
        title={"Add Report"}
        open={isFormOpen}
        fullWidth={true}
        close={onCloseDialogForm}
      >
        <div>
          <AdbastionReportsForm
            formData={formData}
            errors={formErrors}
            onChangeFormData={onChangeFormData}
          />
          <div className={classes.buttonContainer}>
            <Button
              size="small"
              color="primary"
              onClick={(e) => {
                return addReport(e);
              }}
            >
              Add Report
            </Button>
            <Button
              onClick={(e) => {
                return onCloseDialogForm(e);
              }}
              size="small"
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
      <AlertDialog
        title="Delete report?"
        message={`Are you sure you want to delete report '${
          selectedReport ? selectedReport.name : ""
        }' ?`}
        buttonCancelText="Cancel"
        buttonAcceptText="Accept"
        open={isDeleteDialogOpen}
        action={actionDeleteReport}
      />
    </div>
  );
};

AdbastionReportsConfiguration.propTypes = {
  adbastionReportsList: PropTypes.array.isRequired,
  isCreatable: PropTypes.bool.isRequired,
  isDeletable: PropTypes.bool.isRequired,
  uploadAdbastionReport: PropTypes.func,
  deleteAdbastionReport: PropTypes.func,
  downloadAdbastionReportUrl: PropTypes.func,
};

export default AdbastionReportsConfiguration;
