import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";

import Tooltip from "../tooltip/TooltipComponent";
import CustomizedSnackbar from "../snackBar/snackBarComponent";

const mapStateToProps = (state) => ({
  me: state.meReducer,
  alerts: state.alertsReducer,
  reply: state.replyReducer,
});

const mapDispatchToProps = (dispatch) => ({});

const CustomBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    fontSize: 12,
    height: 16,
    border: `2px solid ${theme.palette.white}`,
    padding: "0 4px",
  },
}))(Badge);

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  centerText: {
    alignItems: "center",
  },
  toolbar: {
    marginRight: 10,
  },
  appBar: {
    background: theme.palette.gradient.blue.main,
    zIndex: theme.zIndex.drawer + 1,
    justifyContent: "space-between",
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  toolbarRoot: {
    justifyContent: "space-between",
    width: "100%",
  },
  leftSideBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rightSideBar: {
    minWidth: "202px",
    display: "flex",
    justifyContent: "flex-end",
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  list: {
    paddingBottom: 0,
  },
  avatar: {
    width: 25,
    height: 25,
  },
  infoIcon: {
    color: theme.palette.warning[500],
    fontSize: 18,
  },
  new: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },
  pending: {
    backgroundColor: theme.palette.warning[500],
    color:
      theme.type === "dark" ? theme.palette.primary.main : theme.palette.black,
  },
  logoText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    letterSpacing: 2,
  },
  centuryGothic: {
    fontFamily: theme.palette.typography.logo.fontFamily,
    fontSize: 25,
  },
  centuryGothicBold: {
    fontFamily: theme.palette.typography.logo.fontFamily,
    fontWeight: "bold",
    fontSize: 25,
  },
  shortcutContainer: {
    height: 48,
    display: "flex",
  },
  shortcut: {
    height: 48,
    display: "flex",
    flexDirection: "column",
    paddingTop: 14,
    marginRight: theme.spacing(1),
    position: "relative",
    "&:hover": {
      textDecoration: "none",
    },
  },
  icon: {
    fontSize: 20,
    width: 28,
    opacity: 0.7,
    overflow: "inherit",
    color: theme.palette.white,
  },
  iconSelected: {
    opacity: 1,
  },
  badgeNew: {
    position: "absolute",
    top: 15,
    right: 2,
  },
  badgePending: {
    position: "absolute",
    bottom: 16,
    right: 2,
  },
  shortcutLink: {
    position: "absolute",
    bottom: 0,
    visibility: "hidden",
    backgroundColor: theme.palette.white,
    width: "100%",
    height: 4,
  },
  shortcutSelected: {
    visibility: "visible",
  },
  logo: {
    width: 180,
  },
});

class AppBarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      mobileMoreAnchorEl: null,
      me: {},
      CustomizedSnackbar: "",
      openSnackBar: false,
      totalReplyNotifications: [],
      alert: {},
      notification: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.me.me !== prevState.me) {
      update.me = nextProps.me.me;
    }

    if (nextProps.alerts.alert !== prevState.alert) {
      update.alert = nextProps.alerts.alert;
    }

    if (nextProps.alerts.notification !== prevState.notification) {
      update.notification = nextProps.alerts.notification;
      update.openSnackBar =
        nextProps.alerts.notification && nextProps.alerts.notification.message
          ? true
          : false;
      update.messageSnackBar = nextProps.alerts.notification.message;
    }

    if (
      nextProps.reply.totalReplyNotifications !==
      prevState.totalReplyNotifications
    ) {
      update.totalReplyNotifications = nextProps.reply.totalReplyNotifications;
    }

    return Object.keys(update).length ? update : null;
  }

  onCloseSnackBar() {
    this.setState({
      openSnackBar: false,
    });
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  renderReply() {
    return <Typography variant="body2">Go to Replies</Typography>;
  }

  renderAlert(alert) {
    return (
      <div>
        <Typography variant="h6">{alert.title}</Typography>
        <Typography variant="body2">{alert.message}</Typography>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.alerts !== this.props.alert && this.props.checkFilter) {
      this.editorRef.current.children[0].children[0].children[1].children[0].click();
    }
  }

  render() {
    const { classes, action, selected, hideMenu } = this.props;
    const {
      anchorEl,
      me,
      alert,
      totalReplyNotifications,
      openSnackBar,
      messageSnackBar,
    } = this.state;
    const isMenuOpen = Boolean(anchorEl);

    return (
      <div className={classes.root}>
        <CustomizedSnackbar
          variant="success"
          onClose={this.onCloseSnackBar.bind(this)}
          message={messageSnackBar}
          open={openSnackBar}
        />
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.centerText]: hideMenu,
          })}
        >
          <Toolbar
            classes={{
              root: classes.toolbarRoot,
            }}
            variant="dense"
            disableGutters={true}
          >
            <div className={classes.leftSideBar}>
              {hideMenu !== true ? (
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={action}
                  className={classes.toolbar}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                ""
              )}

              <div className={classes.shortcutContainer}>
                <Link
                  rel={"noopener noreferrer"}
                  color="inherit"
                  href={"/moderation"}
                  classes={{
                    root: classes.shortcut,
                  }}
                >
                  <Icon
                    className={classNames("fas fa-shield-alt", classes.icon, {
                      [classes.iconSelected]: selected === "moderation",
                    })}
                  />
                  <div
                    className={classNames(classes.shortcutLink, {
                      [classes.shortcutSelected]: selected === "moderation",
                    })}
                  ></div>
                </Link>
                <Link
                  rel={"noopener noreferrer"}
                  color="inherit"
                  href={"/copyright"}
                  classes={{
                    root: classes.shortcut,
                  }}
                >
                  <Icon
                    className={classNames("fas fa-globe", classes.icon, {
                      [classes.iconSelected]: selected === "copyright",
                    })}
                  />
                  <div
                    className={classNames(classes.shortcutLink, {
                      [classes.shortcutSelected]: selected === "copyright",
                    })}
                  ></div>
                </Link>
                <Link
                  rel={"noopener noreferrer"}
                  color="inherit"
                  href={"/explorer"}
                  classes={{
                    root: classes.shortcut,
                  }}
                >
                  <Icon
                    className={classNames("fas fa-search", classes.icon, {
                      [classes.iconSelected]: selected === "explorer",
                    })}
                  />
                  <div
                    className={classNames(classes.shortcutLink, {
                      [classes.shortcutSelected]: selected === "explorer",
                    })}
                  ></div>
                </Link>
                <Link
                  rel={"noopener noreferrer"}
                  color="inherit"
                  href={"/reply"}
                  classes={{
                    root: classes.shortcut,
                  }}
                >
                  <Icon
                    className={classNames("far fa-comments", classes.icon, {
                      [classes.iconSelected]: selected === "reply",
                    })}
                  />
                  <CustomBadge
                    color="secondary"
                    max={99}
                    badgeContent={
                      totalReplyNotifications &&
                      totalReplyNotifications[0] &&
                      totalReplyNotifications[0]["count"]
                        ? totalReplyNotifications[0]["count"]
                        : 0
                    }
                    className={classes.badgeNew}
                    classes={{
                      badge: classes.new,
                    }}
                  ></CustomBadge>
                  <CustomBadge
                    color="secondary"
                    max={99}
                    badgeContent={
                      totalReplyNotifications &&
                      totalReplyNotifications[1] &&
                      totalReplyNotifications[1]["count"]
                        ? totalReplyNotifications[1]["count"]
                        : 0
                    }
                    className={classes.badgePending}
                    classes={{
                      badge: classes.pending,
                    }}
                  ></CustomBadge>
                  <div
                    className={classNames(classes.shortcutLink, {
                      [classes.shortcutSelected]: selected === "reply",
                    })}
                  ></div>
                </Link>
                <Link
                  rel="noopener noreferrer"
                  color="inherit"
                  href="/alerts"
                  classes={{
                    root: classes.shortcut,
                  }}
                >
                  <Icon
                    className={classNames(
                      "fas fa-exclamation-triangle",
                      classes.icon,
                      {
                        [classes.iconSelected]: selected === "alerts",
                      }
                    )}
                  />
                  <div
                    className={classNames(classes.shortcutLink, {
                      [classes.shortcutSelected]: selected === "alerts",
                    })}
                  />
                </Link>
              </div>
              {alert && alert.status === "display" ? (
                <Tooltip title={this.renderAlert(alert)}>
                  <IconButton color="inherit">
                    <Icon
                      className={classNames(
                        classes.infoIcon,
                        "fa fa-info-circle"
                      )}
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
            <div className={classes.logoText}>
              <img
                src={`${process.env.PUBLIC_URL}/images/bb_logo_white.png`}
                className={classes.logo}
                alt="logo-brandbastion"
              />
            </div>
            <div className={classes.rightSideBar}>
              <IconButton
                aria-owns={"material-appbar"}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                {me.avatar ? (
                  <Avatar
                    alt="avatar"
                    src={me.avatar}
                    className={classes.avatar}
                  />
                ) : (
                  <AccountCircle />
                )}
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Menu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={this.handleMenuClose}
          classes={{
            list: classes.list,
          }}
        >
          <MenuItem onClick={this.props.logout.bind(this)}>
            <Link className={classes.link} color="inherit" href="/settings">
              <Typography>Settings</Typography>
            </Link>
          </MenuItem>
          <Divider variant="middle" />
          <MenuItem onClick={this.props.logout.bind(this)}>
            <Typography>Log out</Typography>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

AppBarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  selected: PropTypes.string,
  logout: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AppBarComponent));
