import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import CommentKPIsConfiguration from './commentKPIsConfiguration';

// Redux
import { fetchCommentKPIsList } from '../../../actions/taskKPIs/commentKPIsAction';

const mapStateToProps = state => ({
  commentKPIsState: state.commentKPIsReducer
});

const mapDispatchToProps = dispatch => ({
  fetchCommentKPIsList: (assetId, fromDate, toDate) =>
    dispatch(fetchCommentKPIsList(assetId, fromDate, toDate))
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 196px)',
    paddingTop: theme.spacing(1)
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 196px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 367px)',
    marginTop: 'calc(50vh - 96px)'
  },
  filters: {
    display: 'flex',
    width: '90%',
    margin: '0 auto 24px auto'
  },
  formControl: {
    minWidth: 700,
    height: 42,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  title: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const CommentKPIsContainer = props => {
  const classes = useStyles();
  const [isInitialized, setInitialized] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isNotAllowed, setNotAllowed] = useState(false);
  const [commentKPIsList, setCommentKPIsList] = useState([]);

  const [fromDateTs, setFromDateTs] = useState(
    moment()
      .subtract(1, 'days')
      .unix()
  );
  const [toDateTs, setToDateTs] = useState(moment().unix());

  const [fromDate, setFromDate] = useState(moment(fromDateTs * 1000));
  const [toDate, setToDate] = useState(moment(toDateTs * 1000));

  useEffect(() => {
    if (props.permissions.includes('admin_get_post_task_kpis')) {
      if (props.tabIndex === 1 && !isInitialized) {
        setLoading(true);
        setInitialized(true);
        props.fetchCommentKPIsList(props.assetId, fromDateTs, toDateTs);
      }
    } else {
      setNotAllowed(true);
    }
  }, [props.tabIndex]);

  useEffect(() => {
    if (!props.commentKPIsState.fetching) {
      setCommentKPIsList(props.commentKPIsState.commentKPIsList);
      setLoading(false);
    }
  }, [props.commentKPIsState.fetching]);

  const fetchCommentKPIsList = () => {
    setLoading(true);
    props.fetchCommentKPIsList(props.assetId, fromDateTs, toDateTs);
  };

  const setSelectedFromDate = date => {
    const timestamp = moment(date).unix();
    setFromDateTs(timestamp);
    setFromDate(date);
  };

  const setSelectedToDate = date => {
    const timestamp = moment(date).unix();
    setToDateTs(timestamp);
    setToDate(date);
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h5">Comment KPIs</Typography>
      </div>
      <div className={classes.filters}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl className={classes.formControl}>
            <KeyboardDatePicker
              margin="dense"
              label={'From'}
              value={fromDate}
              format={'MMM dd yyyy'}
              onChange={e => setSelectedFromDate(e)}
              minDate={new Date('1900-01-01')}
              maxDate={new Date('2100-01-01')}
            />
            <KeyboardDatePicker
              margin="dense"
              label={'To'}
              value={toDate}
              format={'MMM dd yyyy'}
              onChange={e => setSelectedToDate(e)}
              minDate={new Date('1900-01-01')}
              maxDate={new Date('2100-01-01')}
            />
            <Button
              variant="contained"
              className={classes.buttonAdd}
              size="small"
              color="primary"
              onClick={e => {
                return fetchCommentKPIsList(e);
              }}
            >
              Filter by Dates
            </Button>
          </FormControl>
        </MuiPickersUtilsProvider>
      </div>
      {!isNotAllowed && !isLoading ? (
        <CommentKPIsConfiguration commentKPIsList={commentKPIsList} />
      ) : !isNotAllowed ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <Typography className={classes.notAllowed} variant="body1">
          Not Allowed
        </Typography>
      )}
    </div>
  );
};

CommentKPIsContainer.propTypes = {
  assetId: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentKPIsContainer);
