import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import PrintItem from './printItem';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '@media print': {
      width: 'auto',
      height: 'auto',
      overflow: 'visible'
    }
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 91,
    '@media print': {
      overflow: 'visible',
      position: 'relative',
      background: '#f9f9f9',
      printColorAdjust: 'exact',
      display: 'inline-block',
      width: '1024px'
    }
  },
  listDisplayed: {
    overflow: 'hidden auto'
  },
  filters: {
    width: '100%',
    position: 'fixed',
    backgroundColor: theme.palette.white,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    marginTop: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: 3
  },
  printOpened: {
    display: 'none'
  },
  loading: {
    color: theme.palette.orange[300],
    marginTop: 70,
    margin: 'auto'
  }
});

class PrintComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showUserName: false,
      collapse: false,
      showReply: false,
      limit: 80
    };
    this.onChangeLimit.bind(this);
    this.handleChange.bind(this);
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.checked
    });
  };

  onChangeLimit(e) {
    this.setState({
      limit: e.target.value
    });
  }

  render() {
    const { classes, data } = this.props;
    const { showUserName, collapse, limit, showReply } = this.state;
    return (
      <div className={classes.root}>
        <Box displayPrint="none">
          <div className={classes.filters}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showUserName}
                    onChange={this.handleChange('showUserName')}
                    value="showUserName"
                  />
                }
                label="Show user name"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={collapse}
                    onChange={this.handleChange('collapse')}
                    value="collapse"
                  />
                }
                label="Collapse Item"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showReply}
                    onChange={this.handleChange('showReply')}
                    value="showReply"
                  />
                }
                label="Show Reply"
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <TextField
                    type="number"
                    defaultValue={limit}
                    style={{ width: 50, marginLeft: 11 }}
                    inputProps={{ min: '0', max: '200', step: '1' }}
                    onChange={e => {
                      return this.onChangeLimit(e);
                    }}
                  />
                }
                label="Limit (max 200)"
              />
            </FormGroup>
          </div>
        </Box>
        <div
          className={classNames(classes.list, {
            [classes.listDisplayed]: data && data.length
          })}
        >
          {data && data.length === 0 ? (
            <CircularProgress className={classes.loading} />
          ) : data && data.length ? (
            data.slice(0, limit).map((item, key) => {
              return (
                <PrintItem
                  key={key}
                  item={item}
                  collapse={collapse}
                  showUserName={showUserName}
                  showReply={showReply}
                />
              );
            })
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

PrintComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired
};

export default withStyles(styles, { withTheme: true })(PrintComponent);
