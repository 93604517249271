import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList } from 'react-window';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import SalesItem from './salesListItem';

const useStylesList = makeStyles(theme => ({
  root: {
    borderRadius: 5,
    flexGrow: 1,
    marginTop: theme.spacing(5),
    overflow: 'hidden auto'
  },
  container: {
    background: 'white'
  },
  content: {
    height: 'calc(100vh - 180px)'
  },
  loading: {
    margin: 'auto',
    color: theme.palette.orange[300],
    marginTop: 'calc(50vh - 196px)',
    marginBottom: 'calc(50vh - 196px)'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 65,
    borderBottom: `1px solid ${theme.palette.backgroundLight}`,
    paddingRight: theme.spacing(2)
  },
  headerTitle: {}
}));

const useClientRect = () => {
  const [rect, setRect] = useState(null);
  const ref = useCallback(node => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect, ref];
};

const SalesList = ({ list, actionItemHeader, onClickSalesItem }) => {
  const classes = useStylesList();
  const [loading, setLoading] = useState(true);
  const [rect, ref] = useClientRect();

  useEffect(() => {
    return setLoading(list ? false : true);
  }, [list]);

  return (
    <div className={classes.root}>
      {list && list.length ? (
        <div className={classes.container}>
          <div className={classes.header}>
            <div className={classes.headerTitle}>Analysis Code</div>
            <div className={classes.headerTitle}>Owner</div>
            <div className={classes.headerTitle}>Used Attempts</div>
          </div>
          <div className={classes.content} ref={ref}>
            {rect !== null && (
              <FixedSizeList
                itemData={{
                  list: list,
                  action: actionItemHeader,
                  clickItem: onClickSalesItem
                }}
                height={rect.height}
                itemSize={46}
                itemCount={list.length}
              >
                {SalesItem}
              </FixedSizeList>
            )}
          </div>
        </div>
      ) : loading ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <Typography>{'No Items found'}</Typography>
      )}
    </div>
  );
};

SalesList.propTypes = {
  list: PropTypes.array
};

export default SalesList;
