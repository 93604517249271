const getTagCategoryNameById = (tag_category) => {
  switch (tag_category) {
    case "sensitive":
      return "Sensitive";
    case "universally_harmful":
      return "Universally Harmful";
    case "non_harmful":
      return "Non Harmful";
    case "customer_engagement":
      return "Customer Engagement";
    case "alerts":
      return "Alerts";
    default:
      return "-";
  }
};

export const setTag = (tag, key = null) => {
  const item = {
    id: tag && tag.id ? tag.id : "",
    label: tag && tag.mod_ui && tag.mod_ui.label ? tag.mod_ui.label : "",
    description:
      tag && tag.mod_ui && tag.mod_ui.description
        ? tag.mod_ui.description
        : "-",
    group:
      tag && tag && tag.mod_ui && tag.mod_ui.group ? tag.mod_ui.group : "-",
    example:
      tag && tag && tag.mod_ui && tag.mod_ui.example ? tag.mod_ui.example : "-",
    excluded_example:
      tag && tag && tag.mod_ui && tag.mod_ui.excluded_example
        ? tag.mod_ui.excluded_example
        : "-",
    tag_category: tag.tag_category,
    tag_category_name: getTagCategoryNameById(tag.tag_category),
    volume_based_alert: tag.volume_based_alert,
    pim_tone: tag.mod_ui.pim_tone || "",
    pim_weight: tag.mod_ui.pim_weight,
  };
  return item;
};

export const setTagsListFromComment = (tagList) => {
  return tagList;
};

export const setSentimentFromTags = (tags) => {
  const sentiment = Object.assign([], tags)
    .map((tag) => {
      if (
        (tag.id === "negative_sentiment" && tag.checked) ||
        tag === "negative_sentiment"
      ) {
        return "negative";
      }

      if (
        (tag.id === "positive_sentiment" && tag.checked) ||
        tag === "positive_sentiment"
      ) {
        return "positive";
      }
      return true;
    })
    .filter((item) => {
      return item === "positive" || item === "negative";
    });

  return sentiment && sentiment[0] ? sentiment[0] : "neutral";
};
