//middleware/middleware.js
import * as pako from 'pako';

import * as actions from '../actions/websocketAction';
import { updatesStreamList } from '../actions/streamAction';
import { updatesReplyStats } from '../actions/replyAction';
import { updatesCopyrightStats } from '../actions/copyrightAction';
import { alert, notification } from '../actions/alertsAction';

const socketMiddleware = () => {
  let socket = null;

  const onOpen = store => event => {
    store.dispatch(actions.wsConnected(event.target.url));
  };

  const onClose = store => event => {
    console.log(event);
    store.dispatch(actions.wsDisconnected());

    //if weboscket is not close normally, restart websocket
    if (event.code !== 1000) {
      store.dispatch(actions.wsConnect(process.env.SOCKET_URL));
    }
  };

  const onMessage = store => event => {
    const payload = JSON.parse(event.data);
    const data = JSON.parse(pako.inflate(payload.data, { to: 'string' }));

    switch (payload.event) {
      case 'updates_stream_list':
        store.dispatch(updatesStreamList(data));
        break;
      case 'updates_reply_stats':
        store.dispatch(updatesReplyStats(data));
        break;
      case 'updates_copyright_stats':
        store.dispatch(updatesCopyrightStats(data));
        break;
      case 'alert':
        store.dispatch(alert(data));
        break;
      case 'notification':
        store.dispatch(notification(data));
        break;
      default:
        break;
    }
  };

  // the middleware part of this function
  return store => next => action => {
    switch (action.type) {
      case 'WS_CONNECT':
        if (socket !== null) {
          socket.close();
        }
        // connect to the remote host
        socket = new WebSocket(
          `${action.host}?token=${localStorage.getItem('token')}`
        );

        // websocket handlers
        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);
        break;
      case 'WS_DISCONNECT':
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
