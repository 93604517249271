import { setComment } from '../setters/comment';
import { setCopyrightReport } from '../setters/copyright';

export const extractCopyrightList = data => {
  const list = [];

  const guidelines = {
    tags: data.tags
  };

  if (data && data.items && data.items.length) {
    data.items.map((comment, key) => {
      list.push(setComment(comment, guidelines, key));
      return true;
    });
  }

  return {
    list: list
  };
};

export const extractCopyrightReportList = data => {
  const reports = [];
  data.map((report, key) => {
    reports.push(setCopyrightReport(report, key));
    return true;
  });

  return reports;
};
