export const setAsset = asset => {
  const item = {
    name: asset.name ? asset.name : '',
    id: asset.id ? asset.id : '',
    platform_ids: asset.platform_ids ? asset.platform_ids : [],
    crawling_allowed:
      asset.crawling_disabled && asset.crawling_disabled ? false : true
  };

  return item;
};
