import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from '../reducers/rootReducer';
import socketMiddleware from '../middleware/websocketMiddleware';

export default function configureStore(initialState = {}) {
  let middlewares = applyMiddleware(promise, thunk, socketMiddleware);

  middlewares =
    process.env.NODE_ENV === `development`
      ? composeWithDevTools(middlewares)
      : middlewares;

  return createStore(rootReducer, initialState, middlewares);
}
