export const operationalTags = [
  {
    action: "hide",
    href: null,
    data: {
      label: "isHidden",
      value: false,
      prefix: true,
    },
    label: "Hide",
  },
  {
    action: "unhide",
    href: null,
    data: {
      label: "isHidden",
      value: true,
      prefix: true,
    },
    label: "Unhide",
  },
  {
    action: "unlike",
    href: null,
    data: {
      label: "isLiked",
      value: true,
      prefix: false,
    },
    label: "Remove Like",
  },
  {
    action: "unsure",
    href: null,
    data: {
      label: "isUnsure",
      value: false,
      prefix: true,
    },
    label: "Unsure",
  },
  {
    action: "history",
    href: null,
    data: {
      label: "history",
      value: false,
      prefix: false,
    },
    label: "History",
  },
  {
    action: "favorite",
    href: null,
    data: {
      label: "isFavorite",
      value: false,
      prefix: false,
    },
    label: "Favorite",
  },
];

export const keyboardEvents = [
  {
    name: "positive_sentiment",
    label: "Positive Sentiment",
    handleKeys: ["p"],
  },
  {
    name: "negative_sentiment",
    label: "Negative Sentiment",
    handleKeys: ["n"],
  },
  {
    name: "spam",
    label: "Spam",
    handleKeys: ["s"],
  },
  {
    name: "discrimination",
    label: "Discrimination",
    handleKeys: ["d"],
  },
  {
    name: "intoxicants_criminal",
    label: "Intoxicants Criminal",
    handleKeys: ["i"],
  },
  {
    name: "disturbing_violent",
    label: "Disturbing Violent",
    handleKeys: ["v"],
  },
  {
    name: "sexual_toilet",
    label: "Sexual Toilet",
    handleKeys: ["t"],
  },
  {
    name: "extreme_profanity",
    label: "Extreme Profanity",
    handleKeys: ["e"],
  },
  {
    name: "competitor_promotion",
    label: "Competitor Promotion",
    handleKeys: ["c"],
  },
  {
    name: "hacks_and_cheats",
    label: "Hacks And Cheats",
    handleKeys: ["h"],
  },
  {
    name: "account_misuse",
    label: "Account Misuse",
    handleKeys: ["a"],
  },
  {
    name: "protest_boycott",
    label: "Protest Boycott",
    handleKeys: ["b"],
  },
  {
    name: "brand_attacks",
    label: "Brand Attacks",
    handleKeys: ["x"],
  },
  {
    name: "fan_community",
    label: "Fan Community",
    handleKeys: ["f"],
  },
  {
    name: "faq",
    label: "FAQ",
    handleKeys: ["q"],
  },
  {
    name: "customer_complaints",
    label: "Customer Complaints",
    handleKeys: ["w"],
  },
  {
    name: "constructive_feedback",
    label: "Constructive Feedback",
    handleKeys: ["r"],
  },
  {
    name: "personal_attacks_bullying",
    label: "Personal Attacks/Bullying",
    handleKeys: ["u"],
  },
  {
    name: "competitor_mention",
    label: "Competitor Mention",
    handleKeys: ["m"],
  },
  {
    name: "piracy",
    label: "Piracy",
    handleKeys: ["y"],
  },
  {
    name: "favorite",
    label: "Favorite",
    handleKeys: ["1"],
  },
  {
    name: "brand_critique",
    label: "Brand Critique",
    handleKeys: ["z"],
  },
  {
    name: "product_interest",
    label: "Product Interest",
    handleKeys: ["o"],
  },
  {
    name: "against_person_featured",
    label: "Against Person Featured",
    handleKeys: ["g"],
  },
  {
    name: "customer_complaints_escalated",
    label: "Customer Complaints Escalated",
    handleKeys: ["l"],
  },
  {
    name: "mild_profanity",
    label: "Mild profanity",
    handleKeys: ["j"],
  },
  {
    name: "repeated_non_harmful",
    label: "Repeated Non Harmful",
    handleKeys: ["k"],
  },
  {
    name: "race_religion",
    label: "Race/Religion",
    handleKeys: ["2"],
  },
  {
    name: "socio_political",
    label: "Socio-Political",
    handleKeys: ["3"],
  },
  {
    name: "repeated_socio_political",
    label: "Repeated Socio-Political",
    handleKeys: ["4"],
  },
  {
    name: "response_eligible",
    label: "Response Eligible",
    handleKeys: ["5"],
  },
  {
    name: "response_ineligible_client_rule",
    label: "Response Ineligible Client Specific",
    handleKeys: ["6"],
  },
  {
    name: "response_ineligible",
    label: "Response Ineligible Universal",
    handleKeys: ["7"],
  },
  {
    name: "response_correct",
    label: "Response Correct",
    handleKeys: ["8"],
  },
];

export const infringementItems = [
  {
    value: "game_hacks",
    label: "Game hacks, free coins and cheats",
  },
  {
    value: "misleading_thumbnail",
    label: "Misleading thumbnails",
  },
  {
    value: "misleading_click",
    label: "Misleading click bait titles/description",
  },
  {
    value: "against_fan_policy",
    label: "Against Supercell's fan video policy",
  },
  {
    value: "against_youtube_policy",
    label: "Against YouTube's policy",
  },
  {
    value: "free_account",
    label: "Free, selling or trading accounts",
  },
];

export const categoryItems = [
  {
    value: "cash_for_app",
    label: "Cash for apps",
  },
  {
    value: "unsure",
    label: "Unsure",
  },
  {
    value: "unrelated",
    label: "Unrelated",
  },
  {
    value: "false_positive",
    label: "False positive",
  },
  {
    value: "free_sell_account",
    label: "Free, selling or trading accounts",
  },
];

export const locations_label = [
  "Description",
  "Entire Video",
  "Thumbnail",
  "Time Stamp",
];

export const categories_label = [
  "Cash for apps",
  "Unsure",
  "Unrelated",
  "False positive",
  "Free, selling or trading accounts",
];

export const default_locations = {
  description: false,
  entire_video: false,
  thumbnail: false,
  timestamp: false,
};

export const default_categories = {
  cash_for_app: false,
  unsure: false,
  unrelated: false,
  false_positive: false,
  free_sell_account: false,
};
