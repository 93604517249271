import { setTag } from '../setters/tags';

export const extractTags = data => {
  const tags = [];
  data.map((tag, key) => {
    tags.push(setTag(tag, key));
    return true;
  });

  return Object.assign([], tags);
};
