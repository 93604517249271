import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import TableComponent from '../../commons/table/tableComponent';
import DialogContent from '../../commons/dialog/contentDialogComponent';
import GuidelineGroupsForm from './guidelineGroupsForm';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 7
  },
  detailContainer: {
    display: 'flex'
  },
  detailItem: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.container.light
  },
  detailLegend: {
    padding: theme.spacing(1),
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center'
  },
  detailContent: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    overflow: 'auto'
  },
  detailCell: {
    marginBottom: theme.spacing(1)
  },
  buttonAdd: {
    marginLeft: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actionsContainer: {
    display: 'flex'
  },
  customAction: {
    borderRadius: '50%',
    padding: '0',
    cursor: 'pointer',
    width: 48,
    height: 48,
    minWidth: 'unset',
    '& .MuiButton-label': {
      width: 'auto'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const GuidelineGroupsConfiguration = props => {
  const classes = useStyles();
  const theme = useTheme();
  const [isFormOpen, setFormOpen] = useState(false);
  const [mode, setMode] = useState('add');

  const initialFormData = {
    name: '',
    asset_ids: []
  };
  const [formData, setFormData] = useState(initialFormData);

  const [formErrors, setFormErrors] = useState({});

  const onAddGuidelineGroup = e => {
    setFormErrors({});
    setFormData(initialFormData);
    setMode('add');
    setFormOpen(true);
  };

  const onEditGuidelineGroup = (e, rowData) => {
    setFormErrors({});
    setFormData({
      name: rowData.name,
      asset_ids: rowData.asset_ids,
      index: rowData.tableData.id,
      id: rowData.id
    });
    setMode('edit');
    setFormOpen(true);
  };

  const onCloseDialogForm = () => {
    setFormOpen(false);
  };

  const onChangeFormData = (e, name) => {
    setFormData({
      ...formData,
      [name]: e.target.value
    });
  };

  const checkForErrors = () => {
    return new Promise((resolve, reject) => {
      const formErrors = {};
      for (let field in formData) {
        let errorMessage = null;
        switch (field) {
          case 'name':
            if (formData[field] === '') {
              errorMessage = 'Name is mandatory';
            }
            break;
          case 'asset_ids':
            if (formData[field].length === 0) {
              errorMessage = 'Assets are mandatory';
            }
            break;
          default:
            break;
        }
        if (errorMessage) {
          formErrors[field] = errorMessage;
        }
      }

      resolve(formErrors);
    });
  };

  const addGuidelineGroup = () => {
    checkForErrors().then(formErrors => {
      if (Object.keys(formErrors).length === 0) {
        props.addGuidelineGroup(formData);
        onCloseDialogForm();
        setFormData(initialFormData);
      } else {
        setFormErrors(formErrors);
      }
    });
  };

  const updateGuidelineGroup = () => {
    checkForErrors().then(formErrors => {
      if (Object.keys(formErrors).length === 0) {
        props.updateGuidelineGroup(formData.index, formData);
        onCloseDialogForm();
      } else {
        setFormErrors(formErrors);
      }
    });
  };

  const { isCreatable, isEditable, guidelineGroupsList } = props;

  // Table schema
  const tableColumns = [
    {
      title: 'Name',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'name'
    },
    {
      title: 'ID',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'id'
    },
    {
      title: 'Asset Count',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'asset_ids',
      render: rowData => {
        return rowData.asset_ids.length > 0 ? rowData.asset_ids.length : 0;
      }
    }
  ];

  const tableActions = isEditable
    ? [
        {
          icon: 'edit',
          tooltip: 'Edit Guideline Group',
          onClick: (event, rowData) => onEditGuidelineGroup(event, rowData)
        }
      ]
    : [];

  const tableDetailPanel = [
    {
      tooltip: 'Show Guideline Group Details',
      render: rowData => {
        if (rowData.asset_ids.length > 0) {
          return (
            <div className={classes.detailContainer}>
              <div className={classes.detailItem}>
                <div className={classes.detailLegend}>{'Asset Names'}</div>
                {rowData.asset_ids.map(ass_id => {
                  const name = props.assetList.find(ass => ass.id === ass_id)
                    .name;
                  return (
                    <div className={classes.detailContent}>
                      {name ? name : 'NO DATA'}
                    </div>
                  );
                })}
              </div>
              <div className={classes.detailItem}>
                <div className={classes.detailLegend}>{'Asset IDs'}</div>
                {rowData.asset_ids.map(ass_id => {
                  return <div className={classes.detailContent}>{ass_id}</div>;
                })}
              </div>
            </div>
          );
        } else {
          return (
            <div className={classes.detailContainer}>
              <div className={classes.detailItem}>
                <div className={classes.detailLegend}>{'Asset Names'}</div>
                <div className={classes.detailContent}>NONE</div>
              </div>
              <div className={classes.detailItem}>
                <div className={classes.detailLegend}>{'Asset IDs'}</div>
                <div className={classes.detailContent}>NONE</div>
              </div>
            </div>
          );
        }
      }
    }
  ];

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h5">Adbastion Guideline Groups</Typography>
        {isCreatable && (
          <Button
            variant="contained"
            className={classes.buttonAdd}
            size="small"
            color="primary"
            onClick={e => {
              return onAddGuidelineGroup(e);
            }}
          >
            Add Guideline Group
          </Button>
        )}
      </div>
      <TableComponent
        editable={false}
        columns={tableColumns}
        detailPanel={tableDetailPanel}
        data={guidelineGroupsList}
        actions={tableActions}
        title=""
      />
      <DialogContent
        title={
          mode === 'edit'
            ? 'Edit Adbastion Guideline Group'
            : 'Add Adbastion Guideline Group'
        }
        open={isFormOpen}
        fullWidth={true}
        close={onCloseDialogForm}
      >
        <div>
          <GuidelineGroupsForm
            formData={formData}
            assetList={props.assetList}
            errors={formErrors}
            onChangeFormData={onChangeFormData}
          />
          <div className={classes.buttonContainer}>
            {mode === 'edit' ? (
              <Button
                size="small"
                color="primary"
                onClick={e => {
                  return updateGuidelineGroup();
                }}
              >
                Edit Guideline Group
              </Button>
            ) : (
              <Button
                size="small"
                color="primary"
                onClick={e => {
                  return addGuidelineGroup();
                }}
              >
                Add Guideline Group
              </Button>
            )}
            <Button
              onClick={e => {
                return onCloseDialogForm(e);
              }}
              size="small"
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </div>
  );
};

GuidelineGroupsConfiguration.propTypes = {
  isCreatable: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  guidelineGroupsList: PropTypes.array.isRequired,
  assetList: PropTypes.array.isRequired,
  addGuidelineGroup: PropTypes.func.isRequired,
  updateGuidelineGroup: PropTypes.func.isRequired
};

export default GuidelineGroupsConfiguration;
