import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '../../components/commons/appBar/AppBarComponent';
import LeftMenu from '../../components/commons/leftMenu/leftMenuComponent';
import TabsComponent from '../../components/commons/tabs/tabsComponent';

import RolesPermissionsContainer from '../../components/configuration/rolesAndPermissions/rolesAndPermissionsContainer';
import UsersContainer from '../../components/configuration/users/usersContainer';
import TagsContainer from '../../components/configuration/tags/tagsContainer';
import ClassifiersContainer from '../../components/configuration/classifiers/classifiersContainer';
import ClientsContainer from '../../components/configuration/clients/clientsContainer';
import StreamsContainer from '../../components/configuration/streams/streamsContainer';
/* import BMMContainer from '../../components/configuration/businnessManagementMonitor/bmmContainer'; */
import AssetManagementContainer from '../../components/configuration/assetManagement/assetManagementContainer';
import UCMContainer from '../../components/configuration/userClientManagement/ucmContainer';
import TrendsContainer from '../../components/configuration/trends/trendsContainer';

import { fetchMe } from '../../actions/meAction';

const mapDispatchToProps = dispatch => ({
  fetchMe: () => dispatch(fetchMe())
});

const styles = theme => ({
  root: {
    display: 'flex'
  },
  content: {
    width: '100%',
    paddingTop: theme.spacing(5)
  }
});

class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      permissions: [],
      loading: true,
      hasDefaultValue:
        props.location.state && props.location.state.hasDefaultValue
          ? props.location.state.hasDefaultValue
          : 0,
      open: false,
      tabIndex:
        props.location.state && props.location.state.hasDefaultValue
          ? props.location.state.hasDefaultValue
          : 0
    };
  }

  logout() {
    this.props.signOut();
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  onChangeTabIndex = tabIndex => {
    this.setState({
      tabIndex
    });
  };

  componentDidMount() {
    this.props.fetchMe().then(response => {
      this.setState({
        permissions: response.value.data.permissions,
        loading: false
      });
    });
  }

  render() {
    const { classes } = this.props;
    const {
      open,
      hasDefaultValue,
      permissions,
      loading,
      tabIndex
    } = this.state;

    return (
      <div className={classes.root}>
        <AppBar
          action={this.handleDrawerOpen}
          status={open}
          selected={'admin'}
          logout={this.logout.bind(this)}
        />
        <LeftMenu open={open} onClose={this.handleDrawerClose.bind(this)} />
        <main className={classes.content}>
          {!loading && (
            <TabsComponent
              variant="scrollable"
              smallTab={true}
              hasDefaultValue={hasDefaultValue}
              onChangeIndex={this.onChangeTabIndex.bind(this)}
              tabs={[
                'Roles & Permissions',
                'Users',
                'Tags',
                'Classifiers',
                'Clients',
                'Streams',
                /*                 'BM Monitor', */
                'Asset Management',
                'UC Management',
                'Global Trends'
              ]}
            >
              <RolesPermissionsContainer
                permissions={permissions}
                tabIndex={tabIndex}
              />
              <UsersContainer permissions={permissions} tabIndex={tabIndex} />
              <TagsContainer permissions={permissions} tabIndex={tabIndex} />
              <ClassifiersContainer
                permissions={permissions}
                tabIndex={tabIndex}
              />
              <ClientsContainer permissions={permissions} tabIndex={tabIndex} />
              <StreamsContainer permissions={permissions} tabIndex={tabIndex} />
              {/*               <BMMContainer permissions={permissions} tabIndex={tabIndex} /> */}
              <AssetManagementContainer
                permissions={permissions}
                tabIndex={tabIndex}
              />
              <UCMContainer permissions={permissions} tabIndex={tabIndex} />
              <TrendsContainer permissions={permissions} tabIndex={tabIndex} />
            </TabsComponent>
          )}
        </main>
      </div>
    );
  }
}

AdminPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AdminPage));
