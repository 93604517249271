import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';

import { setDateFormat, setDateWithSec } from '../../../setters/date';

import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(theme => ({
  tag: {
    margin: 5,
    marginTop: 0,
    height: 20,
    fontSize: 10
  },
  title: {},
  errorsContainer: {
    marginLeft: theme.spacing(2)
  },
  typo: {
    marginLeft: theme.spacing(1),
    fontSize: 13,
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: theme.palette.primary.main,
    minWidth: 30,
    marginRight: theme.spacing(1)
  }
}));

const HistoryComment = ({ type, step }) => {
  const classes = useStyles();
  return (
    <Step active={true}>
      <StepLabel className={classes.title}>{'Status Initial'}</StepLabel>
      <StepContent>
        <Typography className={classes.typo}>
          <Icon
            className={classNames(classes.icon, 'fas fa-exclamation-circle')}
          />
          {step['status'] ? step['status'] : 'status unknown'}
        </Typography>
        <Typography className={classes.typo}>
          <Icon className={classes.icon}>watch_later</Icon>
          {step['sla'] ? `SLA: ${setDateWithSec(step['sla'])}` : 'sla unknown'}
        </Typography>
        <Typography className={classes.typo}>
          <Icon className={classes.icon}>calendar_today</Icon>
          {` ${setDateFormat(step.timestamp_pretty)}`}
        </Typography>
      </StepContent>
    </Step>
  );
};

HistoryComment.propTypes = {
  step: PropTypes.object
};

export default HistoryComment;
