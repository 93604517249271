import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

const useStylesItem = makeStyles(theme => ({
  subHeader: {
    display: 'flex',
    backgroundColor: theme.palette.backgroundDark,
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  subHeaderTextContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginLeft: theme.spacing(2),
    cursor: 'pointer'
  },
  icon: {
    fontSize: 14
  },
  legendContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  clientContainer: {
    display: 'flex'
  },
  assetsContainer: {
    width: '100%',
    maxHeight: 220,
    minHeight: 220,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    overflow: 'auto'
  },
  noAssetText: {
    marginTop: 100,
    textAlign: 'center',
    color: theme.palette.grey[800]
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(1),
    height: '100%'
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    marginTop: theme.spacing(1)
  },
  attemptLink: {
    textAlign: 'center'
  },
  attemptNumber: {
    fontSize: '2rem',
    marginRight: '2rem'
  },
  listItemPrimary: {
    color: theme.palette.primary.main
  },
  timelineContainer: {
    background: 'transparent !important',
    padding: '16px 0 24px 0'
  },
  stepActive: {
    color: '#79d70f !important'
  }
}));

const ActionButton = ({ item, name, action = null, variant, href = null }) => {
  const classes = useStylesItem();
  let props = {};

  if (action) {
    props = {
      onClick: e => action(e, item)
    };
  } else if (href) {
    props = {
      href: href
    };
  }

  return (
    <Button
      size="small"
      variant={variant ? variant : 'contained'}
      color="primary"
      disabled={!item.client.sale_analysis_crawlers_finished}
      className={classes.button}
      {...props}
    >
      {name}
    </Button>
  );
};

const AttemptsItem = ({ data, index, style }) => {
  const classes = useStylesItem();

  const item = data.list[index];
  const clientConverted = data.clientConverted;

  return (
    <ListItem
      style={style}
      key={index}
      classes={{
        root: classes.subHeader
      }}
    >
      <Grid container>
        <Grid item xs={8}>
          <div className={classes.legendContainer}>
            <div className={classes.clientContainer}>
              <Typography className={classes.attemptNumber}>
                {index + 1}
              </Typography>
              <div className={classes.logoContainer}>
                <Avatar
                  className={classes.logo}
                  src={item.brand_logo ? item.brand_logo : ''}
                />
              </div>
              <div className={classes.clientInfo}>
                <Typography>
                  {item.brand_name ? item.brand_name : ''} (
                  {item.client && item.client.client_short_name
                    ? item.client.client_short_name
                    : ''}
                  )
                </Typography>
                <Typography>{item.industry ? item.industry : ''}</Typography>
              </div>
            </div>
            <div className={classes.contactContainer}>
              <Typography>
                {item.first_name ? item.first_name : ''}{' '}
                {item.last_name ? item.last_name : ''}
              </Typography>
              <Typography>{item.work_email ? item.work_email : ''}</Typography>
            </div>
          </div>
          <div className={classes.assetsContainer}>
            {item.assets && item.assets.length ? (
              <List>
                {item.assets.map((asset, key) => {
                  return (
                    <div key={key}>
                      <ListItem>
                        <ListItemText
                          primary={asset.name}
                          secondary={asset.id}
                          classes={{ primary: classes.listItemPrimary }}
                        />
                        <ListItemSecondaryAction>
                          <Chip key={key} label={asset.type} component="div" />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider variant="middle" component="li" />
                    </div>
                  );
                })}
              </List>
            ) : (
              <Typography className={classes.noAssetText}>
                {'No assets'}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.buttonContainer}>
            <div className={classes.buttonContent}>
              {item.client && item.client.client_short_name ? (
                <ActionButton
                  item={item}
                  variant="text"
                  name={'Go to explorer'}
                  href={`${window.location.origin}/explorer?client_short_name=${item.client.client_short_name}`}
                />
              ) : (
                ''
              )}
              {item.client && item.client.sale_analysis_link ? (
                <ActionButton
                  item={item}
                  variant="text"
                  name={'Link to analysis'}
                  href={item.client.sale_analysis_link}
                />
              ) : (
                ''
              )}
            </div>
            <div className={classes.buttonContent}>
              {item.is_owner ? (
                <ActionButton
                  item={item}
                  name={'Restart analysis'}
                  action={(e, item) => data.action(e, item, 'restart_analysis')}
                />
              ) : (
                ''
              )}
              {clientConverted || item.converted ? (
                ''
              ) : !item.converted_client ? (
                <ActionButton
                  item={item}
                  name={'Convert to client'}
                  action={(e, item) =>
                    data.action(e, item, 'convert_to_client')
                  }
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Stepper className={classes.timelineContainer}>
            {item.steps.map(step => {
              let tooltipTitle = '';
              switch (step.status) {
                case 'failed':
                  tooltipTitle = 'Step Failed';
                  break;
                case 'finished':
                  tooltipTitle = 'Step Finished';
                  break;
                case 'started':
                  tooltipTitle = 'Step Started';
                  break;
                case 'not_started':
                  tooltipTitle = 'Step Not Started';
                  break;
                default:
                  tooltipTitle = step.status;
                  break;
              }
              return (
                <Tooltip
                  title={tooltipTitle}
                  key={`stepper-${item.id}-${step.id}`}
                >
                  <Step>
                    <StepLabel
                      error={step.status === 'failed'}
                      completed={step.status === 'finished'}
                      active={step.status === 'started'}
                      StepIconProps={{
                        classes: { active: classes.stepActive }
                      }}
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                </Tooltip>
              );
            })}
          </Stepper>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
      </Grid>
    </ListItem>
  );
};

AttemptsItem.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  style: PropTypes.object
};

export default AttemptsItem;
