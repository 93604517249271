import { ucmConstants } from '../../constants/configuration/ucmConstants';

import { setDateFormatTimeStamp } from '../../setters/date';
import { extractUCMList } from '../../extractors/ucmExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  fetchingRoles: false,
  fetchedRoles: false,
  fetchingResetPassword: false,
  fetchedResetPassword: false,
  UCMList: [],
  UCMRoles: [],
  resetPasswordResult: null,
  error: null
};

const ucmReducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_UCM_ROLES
    case `${ucmConstants.FETCH_UCM_ROLES}_PENDING`:
      return {
        ...state,
        fetchingRoles: true
      };
    case `${ucmConstants.FETCH_UCM_ROLES}_ERROR`:
      return {
        ...state,
        fetchingRoles: false,
        error: action.payload.data
      };
    case `${ucmConstants.FETCH_UCM_ROLES}_FULFILLED`:
      return {
        ...state,
        fetchingRoles: false,
        fetchedRoles: true,
        UCMRoles: action.payload.data.items
      };
    // FETCH_UCM_LIST
    case `${ucmConstants.FETCH_UCM_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${ucmConstants.FETCH_UCM_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${ucmConstants.FETCH_UCM_LIST}_FULFILLED`:
      const UCMList = extractUCMList(action.payload.data.items);
      return {
        ...state,
        fetching: false,
        fetched: true,
        UCMList
      };
    // ADD_UCM_LIST
    case `${ucmConstants.ADD_UCM_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${ucmConstants.ADD_UCM_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${ucmConstants.ADD_UCM_LIST}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        UCMList: [...state.UCMList, action.payload.config.addData]
      };
    // UPDATE_UCM_LIST
    case `${ucmConstants.UPDATE_UCM_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${ucmConstants.UPDATE_UCM_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${ucmConstants.UPDATE_UCM_LIST}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        UCMList: Object.assign([], {
          ...state.UCMList,
          [action.payload.config.index]: action.payload.config.updateData
        })
      };
    // RESET_PASSWORD_UCM_USER
    case `${ucmConstants.RESET_PASSWORD_UCM_USER}_PENDING`:
      return {
        ...state,
        fetchingResetPassword: true,
        error: null
      };
    case `${ucmConstants.RESET_PASSWORD_UCM_USER}_ERROR`:
      return {
        ...state,
        fetchingResetPassword: false,
        error: action.payload.data
      };
    case `${ucmConstants.RESET_PASSWORD_UCM_USER}_FULFILLED`:
      const listCopy = [...state.UCMList];
      listCopy[action.payload.config.index] = {
        ...listCopy[action.payload.config.index],
        reset_info:
          'by ' +
          action.payload.config.username +
          ' ' +
          setDateFormatTimeStamp(new Date().getTime() / 1000)
      };
      return {
        ...state,
        fetchingResetPassword: false,
        fetchedResetPassword: true,
        error: null,
        UCMList: listCopy
      };
    default:
      return state;
  }
};

export default ucmReducer;
