import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";

import { setDateFormat } from "../../../setters/date";

const styles = (theme) => ({
  root: {},
  rowTitle: {
    fontSize: 10,
    color: theme.palette.common.black,
  },
  rowContent: {
    fontSize: 8,
    fontWeight: "bold",
    color: theme.palette.common.black,
  },
  status: {
    width: 10,
    height: 10,
    display: "inline-block",
    verticalAlign: "middle",
    backgroundColor: theme.palette.error[500],
    borderRadius: "50%",
  },
  active: {
    backgroundColor: theme.palette.success[500],
  },
});

class CustomTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
    };
  }

  componentDidMount() {
    this.createColumn(this.props.columns);
  }

  createColumn(data) {
    data.forEach((element) => {
      element.render = (rowData) => this.renderRow(rowData, element);
    });

    this.setState({
      columns: data,
    });
  }

  renderSimpleRow(rowData, element, subField = false) {
    const { classes } = this.props;
    let data = "";

    if (subField) {
      data =
        rowData[element.field] && rowData[element.field][element.subField]
          ? rowData[element.field][element.subField]
          : "";
    }

    if (subField && element.subFieldType === "array") {
      data =
        rowData[element.field] && rowData[element.field][element.subField]
          ? rowData[element.field][element.subField].join(",")
          : "";
    }

    return (
      <div>
        {element.content1 ? (
          <div className={classes.rowTitle}>{rowData[element.content1]}</div>
        ) : (
          ""
        )}
        {element.content2 ? (
          <div className={classes.rowContent}>{rowData[element.content2]}</div>
        ) : (
          ""
        )}
        {/* {element.status ? (
          <div className={classes.rowTitle}>
            {`Status: `}
            <span
              className={classNames(classes.status, {
                [classes.active]: rowData[element.status]
                  .toString()
                  .charAt(element.statusValue)
                  ? true
                  : false
              })}
            ></span>
          </div>
        ) : (
          ''
        )} */}
        {subField ? <div className={classes.rowTitle}>{data}</div> : ""}
        {element.time ? (
          <div className={classes.rowTitle}>
            {setDateFormat(rowData[element.timePretty])}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  renderObjectRow(rowData, element) {
    Object.values(rowData[element.field]).map((data) => {
      this.renderSimpleRow(rowData, element, data);
      return true;
    });
  }

  renderRow(rowData, element) {
    if (element.subField) {
      return <div>{this.renderSimpleRow(rowData, element, true)}</div>;
    }

    return <div>{this.renderSimpleRow(rowData, element)}</div>;
  }

  render() {
    const {
      data,
      search,
      showTitle,
      title,
      showToolbar,
      showExport,
    } = this.props;
    const { columns } = this.state;
    return (
      <MaterialTable
        title={showTitle ? title : ""}
        columns={columns}
        data={data}
        options={{
          exportButton: showExport,
          showTitle: showTitle,
          toolbar: showToolbar,
          search: search,
          rowStyle: (rowData) => ({
            backgroundColor:
              this.state.selectedRow &&
              this.state.selectedRow.tableData.id === rowData.tableData.id
                ? "#EEE"
                : "#FFF",
          }),
        }}
        onRowClick={(evt, selectedRow) => this.setState({ selectedRow })}
      />
    );
  }
}

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  search: PropTypes.bool,
  showTitle: PropTypes.bool,
  showToolbar: PropTypes.bool,
  showExport: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
};

export default withStyles(styles, { withTheme: true })(CustomTable);
