import axios from 'axios';

import { claraConstants } from '../../constants/configuration/claraConstants';
import { setHeaders } from '../../setters/auth';

export const fetchClassifiers = clientShortName => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/clara_config`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: claraConstants.FETCH_CLASSIFIERS,
    payload: promise
  });
};

export const updateRules = (
  clientShortName,
  index,
  classifier_name,
  rules,
  version
) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/clara_config/${classifier_name}`;
  let classifier = {
    rules: rules,
    version: version
  };

  const promise = axios.put(url, classifier, {
    headers: setHeaders(),
    index: index
  });

  return dispatch({
    type: claraConstants.UPDATE_CLASSIFIER_RULES,
    payload: promise
  });
};

export const updateStatusClassifier = (
  clientShortName,
  index,
  classifier_name,
  status
) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/clara_config/${classifier_name}`;

  const promise = axios.patch(
    url,
    {
      clara_only: !status
    },
    {
      headers: setHeaders(),
      index: index
    }
  );

  return dispatch({
    type: claraConstants.UPDATE_STATUS_CLASSIFIER,
    payload: promise
  });
};

export const addClassifier = (
  clientShortName,
  classifier_name,
  rules,
  version
) => dispatch => {
  const url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/clara_config/${classifier_name}`;

  rules = rules.map(rule => {
    delete rule.isDefault;
    return rule;
  });

  const promise = axios.post(
    url,
    {
      rules,
      version,
      clara_only: true
    },
    {
      headers: setHeaders()
    }
  );

  return dispatch({
    type: claraConstants.ADD_CLASSIFIER_LIST_ITEM,
    payload: promise
  });
};

export const deleteClassifier = (
  clientShortName,
  id,
  classifier_name
) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/clara_config/${classifier_name}`;
  const promise = axios.delete(url, {
    headers: setHeaders(),
    id
  });
  return dispatch({
    type: claraConstants.DELETE_CLASSIFIER_LIST_ITEM,
    payload: promise
  });
};

export const resetClassifiers = () => dispatch => {
  const promise = new Promise((resolve, reject) => {
    resolve(); // fulfilled
    reject('failure decrase notification count');
  });

  return dispatch({
    type: claraConstants.RESET_CLASSIFIERS,
    payload: promise
  });
};
