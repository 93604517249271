import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ChipInput from "material-ui-chip-input";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import Textarea from "../../commons/textarea/textareaComponent";
import InputComponent from "../../commons/input/inputComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  formControlContainer: {
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
  formGroup: {
    display: "block",
  },
  guidelineTitle: {
    backgroundColor: theme.palette.container.light,
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  guidelineContent: {
    padding: theme.spacing(2),
  },
}));

const BuildGuideline = ({
  guideline,
  action,
  order,
  hasEmail,
  onChangeFormData,
  tagList,
  clientTags,
}) => {
  const classes = useStyles();

  let label = action.replace("_action", "");
  label = label.charAt(0).toUpperCase() + label.slice(1);

  return (
    <div key={order} className={classes.guidelineContainer}>
      <div className={classes.guidelineContent}>
        <FormControlLabel
          control={
            <CheckBox
              checked={
                guideline && guideline[action]
                  ? guideline[action].enabled
                  : false
              }
              onChange={(e) =>
                onChangeFormData("guidelines", order, action, "enabled", e)
              }
            />
          }
          label={label}
        />
        {guideline && guideline[action] && guideline[action].enabled ? (
          <FormControlLabel
            control={
              <MultipleSelect
                order={order}
                values={
                  guideline && guideline[action]
                    ? guideline[action].exceptions
                    : []
                }
                action={action}
                tagList={tagList}
                clientTags={clientTags}
                onChangeFormData={onChangeFormData}
              />
            }
            label={"Exceptions"}
            labelPlacement="start"
          />
        ) : null}
        {hasEmail &&
        guideline &&
        guideline[action] &&
        guideline[action].enabled ? (
          <div>
            <BuildChipInput
              guideline={guideline}
              action={action}
              attribute={"emails"}
              order={order}
              label={"Emails"}
              onChangeFormData={onChangeFormData}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const MultipleSelect = ({
  order,
  values,
  action,
  onChangeFormData,
  tagList,
  clientTags,
}) => {
  const classes = useStyles();
  const [vals, setVals] = useState(values);

  const onChange = (e) => {
    setVals(e.target.value);
  };

  return (
    <Select
      multiple
      value={vals ? vals : []}
      onChange={(e) => onChange(e)}
      onClose={() => {
        onChangeFormData("guidelines", order, action, "exceptions", {
          target: {
            value: vals,
          },
        });
      }}
      renderValue={(selected) => (
        <div className={classes.tags}>
          {selected.map((value) => (
            <Chip
              key={value}
              label={
                tagList.find((tag) => value === tag.id)
                  ? tagList.find((tag) => value === tag.id)["label"]
                  : value
              }
              className={classes.tag}
            />
          ))}
        </div>
      )}
    >
      {clientTags.map((tag) => {
        const item = tagList.find((i) => tag === i.id) || tag;
        return (
          <MenuItem
            key={item.id ? item.id : item}
            value={item.id ? item.id : item}
          >
            {item.label ? item.label : item}
          </MenuItem>
        );
      })}
    </Select>
  );
};

const BuildDescription = ({ guideline, order, onChangeFormData }) => {
  const [val, setVal] = useState(guideline.competitor_description);

  const onChange = (e) => {
    setVal(e.target.value);
  };

  return (
    <FormControlLabel
      control={
        <Textarea
          value={val}
          margin={"dense"}
          onChange={(e) => onChange(e)}
          handleBlur={() =>
            onChangeFormData(
              "guidelines",
              order,
              "competitor_description",
              null,
              {
                target: {
                  value: val,
                },
              }
            )
          }
        />
      }
      label={"Competitor Description"}
      labelPlacement="start"
    />
  );
};

const BuildChipInput = ({
  guideline,
  action,
  order,
  attribute,
  label,
  onChangeFormData,
}) => {
  return (
    <ChipInput
      required
      label={label}
      defaultValue={
        guideline && guideline[action] && guideline[action][attribute]
          ? guideline[action][attribute]
          : []
      }
      blurBehavior="add"
      onChange={(e) =>
        onChangeFormData("guidelines", order, action, attribute, {
          target: {
            value: e,
          },
        })
      }
      error={
        guideline &&
        guideline[action] &&
        guideline[action][attribute] &&
        guideline[action][attribute].length === 0
          ? true
          : false
      }
      helperText={`Must have at least 1 email`}
    />
  );
};

const BuildInputNumber = ({
  guideline,
  action,
  order,
  id,
  minValue,
  label,
  onChangeFormData,
}) => {
  const [val, setVal] = useState(guideline[action][id]);

  const onChange = (e) => {
    setVal(e.target.value);
  };

  return (
    <InputComponent
      label={label}
      type="number"
      value={val}
      InputProps={{ inputProps: { min: minValue } }}
      helperText={`Minimum value will be set to ${minValue}`}
      onBlur={() =>
        onChangeFormData(
          "guidelines",
          order,
          action,
          id,
          {
            target: {
              value: val,
            },
          },
          "number"
        )
      }
      onChange={(e) => onChange(e)}
    />
  );
};

const BuildVolumeBaseAlert = ({
  guideline,
  action,
  order,
  onChangeFormData,
}) => {
  const classes = useStyles();

  let label = action.replace("_action", "");
  label = label.charAt(0).toUpperCase() + label.slice(1);

  return (
    <div key={order} className={classes.guidelineContainer}>
      <div className={classes.guidelineContent}>
        <FormControlLabel
          control={
            <CheckBox
              checked={
                guideline && guideline[action]
                  ? guideline[action].enabled
                  : false
              }
              onChange={(e) =>
                onChangeFormData("guidelines", order, action, "enabled", e)
              }
            />
          }
          label={label}
        />
        {guideline[action].enabled ? (
          <BuildInputNumber
            guideline={guideline}
            action={action}
            order={order}
            id={"threshold"}
            minValue={1}
            label={"Threshold"}
            onChangeFormData={onChangeFormData}
          />
        ) : (
          ""
        )}
        {guideline[action].enabled ? (
          <BuildInputNumber
            guideline={guideline}
            action={action}
            order={order}
            id={"time_interval"}
            minValue={3600}
            label={"Time Interval"}
            onChangeFormData={onChangeFormData}
          />
        ) : (
          ""
        )}
        {guideline[action].enabled ? (
          <BuildInputNumber
            guideline={guideline}
            action={action}
            order={order}
            id={"timeout"}
            minValue={300}
            label={"Timeout"}
            onChangeFormData={onChangeFormData}
          />
        ) : (
          ""
        )}
        {guideline[action].enabled ? (
          <BuildChipInput
            guideline={guideline}
            action={action}
            order={order}
            attribute={"emails"}
            label={"Emails"}
            onChangeFormData={onChangeFormData}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const ModerationGuidelinesForm = (props) => {
  const classes = useStyles();
  const [form, setForm] = useState(props.formData);
  const [tagList, setTagList] = useState(props.tagList);
  const [clientTags, setClientTags] = useState(props.clientTags);

  useEffect(() => {
    if (props.tagList !== tagList) {
      setTagList(props.tagList);
    }
  }, [props.tagList]);

  useEffect(() => {
    if (props.clientTags !== clientTags) {
      setClientTags(props.clientTags);
    }
  }, [props.clientTags]);

  useEffect(() => {
    if (props.formData !== form) {
      setForm(props.formData);
    }
  }, [props.formData]);

  return (
    <div className={classes.formControlContainer}>
      {form &&
        form.guidelines &&
        form.guidelines.map((guideline, key) => {
          return (
            <div key={key}>
              <div className={classes.guidelineTitle}>{guideline.name}</div>
              <BuildGuideline
                guideline={guideline}
                action="hide_action"
                order={key}
                tagList={tagList}
                clientTags={clientTags}
                onChangeFormData={props.onChangeFormData}
              />
              <BuildGuideline
                guideline={guideline}
                action="alert_action"
                order={key}
                hasEmail
                tagList={tagList}
                clientTags={clientTags}
                onChangeFormData={props.onChangeFormData}
              />
              {guideline.volume_action ? (
                <BuildVolumeBaseAlert
                  guideline={guideline}
                  action="volume_action"
                  order={key}
                  onChangeFormData={props.onChangeFormData}
                />
              ) : null}
              <BuildDescription
                guideline={guideline}
                order={key}
                onChangeFormData={props.onChangeFormData}
              />
            </div>
          );
        })}
    </div>
  );
};

ModerationGuidelinesForm.propTypes = {
  formData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChangeFormData: PropTypes.func.isRequired,
};

export default ModerationGuidelinesForm;
