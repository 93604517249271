import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Tooltip from "../tooltip/TooltipComponent";

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  item: {
    margin: "5px 5px 0px 5px",
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconReplied: {
    fontSize: 12,
    width: 20,
    marginRight: 2,
    marginTop: 2,
    color: theme.palette.success[500],
  },
  iconPending: {
    fontSize: 12,
    width: 20,
    marginRight: 2,
    color: theme.palette.warning[500],
  },
  selected: {
    color: theme.palette.white,
  },
  bounceIn: {
    animationDelay: "0ms",
    animationDuration: "1000ms",
    pointerEvents: "all",
    animationName: "bounceIn",
    animationFillMode: "both",
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fontSize: 12,
    width: 20,
    marginRight: 2,
  },
});

class ListItemAlerts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: {},
      hide: {},
      checkforalert: {},
      reply: {},
      animate: false,
      deleteAlert: {},
    };

    this.generateStatusTitle.bind(this);
    this.generateActionTitle.bind(this);
  }

  setAllAlerts() {
    const alerts = this.props.item[1].alerts;

    this.setState({
      animate: false,
      alert: {},
      hide: {},
      deleteAlert: {},
      checkforalert: {},
      reply: {},
    });

    alerts.map((alert) => {
      let alertName = alert.objectType.replace(/\s/g, "");
      this.setState({
        [alertName]: {
          name: alertName,
          status: alert.status,
        },
      });
      return true;
    });

    if (this.props.item[1].isHidden) {
      this.setState({
        hide: {
          name: "hide",
          status: "finished",
        },
      });
    }

    if (this.props.item[1].isDeleted) {
      this.setState({
        deleteAlert: {
          name: "delete",
          status: "finished",
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    const alerts = this.props.item[1].alerts;

    if (alerts !== prevProps.item[1].alerts) {
      this.setAllAlerts();
    }
  }

  componentDidMount() {
    if (this.props.item[1].alerts) {
      this.setAllAlerts();
    }
  }

  generateTagNames(inputNames, tags) {
    const tagName = inputNames.map((input) => {
      return tags.find((tag) => {
        return input === tag.id;
      });
    });

    return tagName
      .map((tag) => {
        return tag && tag.label ? tag.label : "";
      })
      .join(", ");
  }

  generateStatusTitle(status = "") {
    return <p>{status}</p>;
  }

  generateActionTitle(type) {
    const { classes, item } = this.props;
    const alerts = item && item.length ? item[1].alerts : [];

    return (
      <React.Fragment>
        {alerts.map((alert, key) => {
          if (alert.objectType === type) {
            return (
              <div key={key} className={classes.list}>
                {alert.objectType} - [
                {this.generateTagNames(alert.inputNames, item[1].tags)}]
              </div>
            );
          }
          return true;
        })}
      </React.Fragment>
    );
  }

  renderAction(type, label, status) {
    const { classes, index, item, isParent } = this.props;
    const { animate } = this.state;

    let replied =
      type === "reply" &&
      item[1]["reply"] &&
      item[1]["reply"]["status"] === "successful";

    return (
      <div
        className={classNames(classes.item, {
          [classes.bounceIn]: animate,
          [classes.selected]: index === item[0] || isParent,
        })}
      >
        <Icon
          className={classNames(classes.icon, "fa fa-exclamation-triangle")}
        />
        <Tooltip title={this.generateActionTitle(type)}>
          <span>{label}</span>
        </Tooltip>
        <Tooltip
          title={this.generateStatusTitle(
            replied
              ? "Replied"
              : status && type !== "reply"
              ? `Action ${status}`
              : ""
          )}
          hide={!status}
        >
          <div className={classes.statusContainer}>
            {(status === "finished" && type !== "reply") || replied ? (
              <Icon
                className={classNames(classes.iconReplied, "fa fa-check", {
                  [classes.bounceIn]: animate,
                  [classes.selected]: index === item[0] || isParent,
                })}
              />
            ) : (
              ""
            )}
            {status === "pending" ? (
              <Icon
                className={classNames(
                  classes.iconPending,
                  "fa fa-hourglass-half",
                  {
                    [classes.bounceIn]: animate,
                    [classes.selected]: index === item[0] || isParent,
                  }
                )}
              />
            ) : (
              ""
            )}
          </div>
        </Tooltip>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { alert, hide, checkforalert, reply, deleteAlert } = this.state;

    return (
      <div className={classes.root}>
        {hide.name ? this.renderAction(hide.name, "Hidden", hide.status) : ""}
        {deleteAlert.name
          ? this.renderAction(deleteAlert.name, "Deleted", deleteAlert.status)
          : ""}
        {alert.name ? this.renderAction(alert.name, "Alert", alert.status) : ""}
        {checkforalert.name
          ? this.renderAction(
              checkforalert.name,
              "Check for alert",
              checkforalert.status
            )
          : ""}
        {reply.name ? this.renderAction(reply.name, "Reply", reply.status) : ""}
      </div>
    );
  }
}

ListItemAlerts.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  item: PropTypes.array.isRequired,
  isParent: PropTypes.bool,
  index: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(ListItemAlerts);
