import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import TableComponent from '../../commons/table/tableComponent';
import DialogContent from '../../commons/dialog/contentDialogComponent';
import AlertDialog from '../../commons/dialog/alertDialogComponent';
import ChannelWhitelistForm from './channelWhitelistForm';

import { setDateFormatTimeStamp } from '../../../setters/date';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 7
  },
  buttonAdd: {
    marginLeft: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const ChannelWhiteListConfiguration = props => {
  const classes = useStyles();
  const theme = useTheme();
  const [isFormOpen, setFormOpen] = useState(false);

  const initialDeleteData = {
    index: null,
    channel_id: ''
  };
  const [channelToDeleteData, setChannelToDeleteData] = useState(
    initialDeleteData
  );
  const [isDeleteAlertOpen, setDeleteAlertOpen] = useState(false);

  const initialFormData = {
    channel_id: ''
  };
  const [formData, setFormData] = useState(initialFormData);

  const [formErrors, setFormErrors] = useState({});

  const onAddChannel = e => {
    setFormOpen(true);
  };

  const onCloseDialogForm = () => {
    setFormOpen(false);
  };

  const onChangeFormData = (e, name) => {
    setFormData({
      ...formData,
      [name]: e.target.value
    });
  };

  const checkForErrors = () => {
    return new Promise((resolve, reject) => {
      const formErrors = {};
      for (let field in formData) {
        let errorMessage = null;
        switch (field) {
          case 'channel_id':
            if (formData[field] === '') {
              errorMessage = 'Channel ID is mandatory';
            }
            break;
          default:
            break;
        }
        if (errorMessage) {
          formErrors[field] = errorMessage;
        }
      }

      resolve(formErrors);
    });
  };

  const addChannelWhitelist = () => {
    checkForErrors().then(formErrors => {
      if (Object.keys(formErrors).length === 0) {
        props.addChannelWhitelist(formData);
        onCloseDialogForm();
        setFormData(initialFormData);
      } else {
        setFormErrors(formErrors);
      }
    });
  };

  const onDeleteChannel = (e, data) => {
    setChannelToDeleteData({
      index: data.tableData.id,
      channel_id: data.channel_id
    });
    setDeleteAlertOpen(true);
  };

  const onDialogDeleteAction = isConfirmed => {
    if (isConfirmed) {
      const { index, channel_id } = channelToDeleteData;
      props.deleteChannelWhitelist(index, channel_id);
    }
    setDeleteAlertOpen(false);
    setTimeout(() => {
      setChannelToDeleteData(initialDeleteData);
    }, 200);
  };

  const { isCreatable, isDeletable, channelWhitelistList } = props;

  // Table schema
  const tableColumns = [
    {
      title: 'Channel ID',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'channel_id'
    },
    {
      title: 'Created At',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'created_at',
      render: rowData => setDateFormatTimeStamp(rowData.created_at)
    }
  ];

  const tableActions = isDeletable
    ? [
        {
          icon: 'delete',
          tooltip: 'Delete channel',
          onClick: (event, rowData) => onDeleteChannel(event, rowData)
        }
      ]
    : [];

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h5">Channel Whitelist</Typography>
        {isCreatable && (
          <Button
            variant="contained"
            className={classes.buttonAdd}
            size="small"
            color="primary"
            onClick={e => {
              return onAddChannel(e);
            }}
          >
            Add Channel
          </Button>
        )}
      </div>
      <TableComponent
        editable={false}
        columns={tableColumns}
        data={channelWhitelistList}
        actions={tableActions}
        title=""
      />
      <DialogContent
        title={'Channel'}
        open={isFormOpen}
        fullWidth={true}
        close={onCloseDialogForm}
      >
        <div>
          <ChannelWhitelistForm
            formData={formData}
            errors={formErrors}
            onChangeFormData={onChangeFormData}
          />
          <div className={classes.buttonContainer}>
            <Button
              size="small"
              color="primary"
              onClick={e => {
                return addChannelWhitelist();
              }}
            >
              Add Channel
            </Button>
            <Button
              onClick={e => {
                return onCloseDialogForm(e);
              }}
              size="small"
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
      <AlertDialog
        title={`Delete channel?`}
        message={`Are you sure you want to delete channel '${channelToDeleteData.channel_id}' from the list?`}
        buttonCancelText="Cancel"
        buttonAcceptText="Accept"
        open={isDeleteAlertOpen}
        action={onDialogDeleteAction}
      />
    </div>
  );
};

ChannelWhiteListConfiguration.propTypes = {
  isCreatable: PropTypes.bool.isRequired,
  isDeletable: PropTypes.bool.isRequired,
  channelWhitelistList: PropTypes.array.isRequired,
  addChannelWhitelist: PropTypes.func.isRequired,
  deleteChannelWhitelist: PropTypes.func.isRequired
};

export default ChannelWhiteListConfiguration;
