import axios from 'axios';

import { repliesConstants } from '../../constants/configuration/repliesConstants';
import { setHeaders } from '../../setters/auth';

export const fetchReplyConfig = clientShortName => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/reply_config`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: repliesConstants.FETCH_REPLY_CONFIG,
    payload: promise
  });
};

export const fetchRepliesAccess = clientShortName => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/reply_config/access-list`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: repliesConstants.FETCH_REPLY_ACCESS,
    payload: promise
  });
};

export const putRepliesAccess = (clientShortName, data) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/reply_config/access-list`;

  const promise = axios.put(
    url,
    {
      usernames: data.usernames,
      roles: data.roles
    },
    {
      headers: setHeaders()
    }
  );

  return dispatch({
    type: repliesConstants.PUT_REPLY_ACCESS,
    payload: promise
  });
};

export const saveReplyConfig = (clientShortName, data) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/reply_config`;

  const promise = axios.put(url, data, {
    headers: setHeaders()
  });

  return dispatch({
    type: repliesConstants.SAVE_REPLY_CONFIG,
    payload: promise
  });
};

export const resetReplyConfig = () => dispatch => {
  return dispatch({
    type: repliesConstants.RESET_REPLY_CONFIG
  });
};
