import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import GroupsConfiguration from "./groupsConfiguration";

import {
  fetchGroupList,
  addGroup,
  editGroup,
} from "../../../actions/configuration/groupsAction";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  loading: {
    margin: "auto",
    marginTop: "calc(50vh - 96px)",
    color: theme.palette.orange[300],
  },
  notAllowed: {
    margin: "auto",
    height: "calc(100vh - 440px)",
    marginTop: "calc(50vh - 96px)",
  },
});

const mapStateToProps = (state) => ({
  groups: state.groupsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchGroupList: (clientShortName) =>
    dispatch(fetchGroupList(clientShortName)),
  addGroup: (clientShortName, data) =>
    dispatch(addGroup(clientShortName, data)),
  editGroup: (clientShortName, index, data) =>
    dispatch(editGroup(clientShortName, index, data)),
});

class GroupsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
      clientShortName: props.clientShortName,
      loading: true,
      notAllowed: false,
      creatable: false,
      editable: false,
      editingAction: false,
      groupList: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.groups.groupList !== prevState.groupList) {
      update.groupList = nextProps.groups.groupList;
    }

    if (
      nextProps.groups.fetching !== prevState.editingAction ||
      nextProps.groups.error
    ) {
      update.editingAction = nextProps.groups.fetching;
    }

    return Object.keys(update).length ? update : null;
  }

  addGroup(data) {
    this.props.addGroup(this.state.clientShortName, data);
  }

  editGroup(index, data) {
    this.props.editGroup(this.state.clientShortName, index, data);
  }

  initialize() {
    if (this.props.permissions.includes("admin_list_client_groups")) {
      this.props.fetchGroupList(this.state.clientShortName).then(() => {
        this.setState({
          loading: false,
        });
      });

      if (this.props.permissions.includes("admin_update_client_group")) {
        this.setState({
          editable: true,
        });
      }

      if (this.props.permissions.includes("admin_create_client_group")) {
        this.setState({
          creatable: true,
        });
      }
    } else {
      this.setState({
        notAllowed: true,
        loading: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tabIndex === 3 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true,
        },
        this.initialize
      );
    }
  }

  componentDidMount() {
    if (this.props.tabIndex === 3 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true,
        },
        this.initialize
      );
    }
  }

  render() {
    const { classes } = this.props;
    const {
      loading,
      notAllowed,
      editable,
      creatable,
      groupList,
      editingAction,
      clientShortName,
    } = this.state;

    return (
      <div className={classes.root}>
        {!notAllowed && !loading ? (
          <GroupsConfiguration
            editable={editable}
            creatable={creatable}
            editingAction={editingAction}
            addGroup={this.addGroup.bind(this)}
            editGroup={this.editGroup.bind(this)}
            groupList={groupList}
            clientShortName={clientShortName}
          />
        ) : !notAllowed ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <Typography className={classes.notAllowed} variant="body1">
            Not Allowed
          </Typography>
        )}
      </div>
    );
  }
}

GroupsContainer.propTypes = {
  clientShortName: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(GroupsContainer));
