import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const MAX_GOOGLE_BUSINESS_STARS = 5;

const useStyles = makeStyles(() => ({
  bbStars: {
    display: "flex",
    gap: "2px",
    height: "16px",
    "& img": {
      width: "16px",
      height: "16px",
      filter:
        "brightness(0) saturate(100%) invert(92%) sepia(8%) saturate(294%) hue-rotate(186deg) brightness(97%) contrast(92%)",
    },
  },
  bbStar: {
    "& img": {
      filter:
        "brightness(0) saturate(100%) invert(84%) sepia(31%) saturate(5930%) hue-rotate(1deg) brightness(105%) contrast(97%)",
    },
  },
}));

export const GoogleBusinessStars = ({ numberOfStars }) => {
  const classes = useStyles();
  return (
    <div className={classes.bbStars}>
      {[...Array(MAX_GOOGLE_BUSINESS_STARS)].map((_, index) => {
        const starClass =
          numberOfStars && index < numberOfStars ? "bbStar" : "";
        return (
          <div key={index} className={classes[starClass]}>
            <img
              src={`${process.env.PUBLIC_URL}/images/googleBusinessStar.svg`}
              alt="googleBusinessStar"
            />
          </div>
        );
      })}
    </div>
  );
};
