import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  replyContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  iconEdit: {
    color: theme.palette.white,
    fontSize: 13,
    cursor: 'pointer',
    marginRight: theme.spacing(2)
  },
  iconReply: {
    color: theme.palette.white,
    fontSize: 13,
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(1)
  },
  replyContent: {
    color: theme.palette.white
  }
}));

const ListItemReply = ({ replyMessage, commentId, clientShortName }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.replyContainer}>
        <Icon className={classNames(classes.iconReply, 'fa fa-reply')} />
        <div className={classes.replyContent}>{replyMessage}</div>
      </div>
      <Link
        rel="noopener noreferrer"
        target="_blank"
        color="inherit"
        href={`reply?client_short_name=${clientShortName}&comment_ids=${commentId}`}
      >
        <Icon className={classNames(classes.iconEdit, 'fa fa-pen')} />
      </Link>
    </div>
  );
};

ListItemReply.propTypes = {
  replyMessage: PropTypes.string.isRequired,
  commentId: PropTypes.string.isRequired
};

export default ListItemReply;
