import { replyConstants } from '../constants/replyConstants';

import { extractReplyList } from '../extractors/replyExtractor';

import { extractClientList } from '../extractors/clientsExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  list: [],
  clients: [],
  totalReplyNotifications: [],
  replyConfig: {},
  total: null,
  cursor: null,
  error: null
};

const Reply = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_CLIENTS_REPLY
    case `${replyConstants.FETCH_CLIENTS_REPLY}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${replyConstants.FETCH_CLIENTS_REPLY}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${replyConstants.FETCH_CLIENTS_REPLY}_FULFILLED`:
      let clientList = extractClientList(action.payload.data.items, 'reply');
      return {
        ...state,
        fetching: false,
        fetched: true,
        totalReplyNotifications: action.payload.data.counts,
        clients: clientList
      };

    // FETCH_CLIENT_REPLY_CONFIG
    case `${replyConstants.FETCH_CLIENT_REPLY_CONFIG}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${replyConstants.FETCH_CLIENT_REPLY_CONFIG}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${replyConstants.FETCH_CLIENT_REPLY_CONFIG}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        replyConfig: action.payload.data
      };

    // RESET COMMENT LIST REPLY
    case `${replyConstants.RESET_COMMENT_LIST_REPLY}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${replyConstants.RESET_COMMENT_LIST_REPLY}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${replyConstants.RESET_COMMENT_LIST_REPLY}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetchComment: false,
        list: []
      };

    // GET_COMMENT_LIST_BY_CLIENT_REPLY
    case `${replyConstants.GET_COMMENT_LIST_BY_CLIENT_REPLY}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${replyConstants.GET_COMMENT_LIST_BY_CLIENT_REPLY}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${replyConstants.GET_COMMENT_LIST_BY_CLIENT_REPLY}_FULFILLED`:
      let replyList = extractReplyList(action.payload.data);
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetchComment: true,
        list: state.list.concat(replyList['list']),
        total: action.payload.data.total,
        cursor: action.payload.data.cursor
      };

    //FETCH HISTORY FROM COMMENT
    case `${replyConstants.FETCH_HISTORY_FROM_COMMENT_REPLY}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${replyConstants.FETCH_HISTORY_FROM_COMMENT_REPLY}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${replyConstants.FETCH_HISTORY_FROM_COMMENT_REPLY}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            history: action.payload.data.items.length
              ? action.payload.data.items
              : []
          }
        })
      };
    // UPDATE_CLIENTS_COMMENTS_TRANSLATION
    case `${replyConstants.UPDATE_CLIENTS_COMMENTS_TRANSLATION_REPLY}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${replyConstants.UPDATE_CLIENTS_COMMENTS_TRANSLATION_REPLY}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${replyConstants.UPDATE_CLIENTS_COMMENTS_TRANSLATION_REPLY}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            message: action.payload.data.message,
            messageTranslated: action.payload.data.messageTranslated
          }
        })
      };

    // SELECT_REPLY_TO_COMMENT
    case `${replyConstants.SELECT_REPLY_TO_COMMENT}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${replyConstants.SELECT_REPLY_TO_COMMENT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${replyConstants.SELECT_REPLY_TO_COMMENT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            reply: {
              ...(state.list[action.payload.config.index] &&
              state.list[action.payload.config.index]['reply']
                ? state.list[action.payload.config.index]['reply']
                : []),
              status: 'pending',
              message: action.payload.config.message.message,
              category_name: action.payload.config.message.category_name
            }
          }
        }),
        replyConfig: {
          ...state.replyConfig,
          current_reply_count: action.payload.config.message.count,
          current_percentile_color: action.payload.config.message.pColor
        }
      };

    // APPROVE_REPLY_TO_COMMENT
    case `${replyConstants.APPROVE_REPLY_TO_COMMENT}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${replyConstants.APPROVE_REPLY_TO_COMMENT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${replyConstants.APPROVE_REPLY_TO_COMMENT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            reply: {
              ...(state.list[action.payload.config.index] &&
              state.list[action.payload.config.index]['reply']
                ? state.list[action.payload.config.index]['reply']
                : []),
              status: action.payload.data.status,
              message: action.payload.config.message
            }
          }
        }),
        replyConfig: {
          ...state.replyConfig,
          current_reply_count: state.replyConfig.current_reply_count + 1
        }
      };

    // CANCEL_REPLY_TO_COMMENT
    case `${replyConstants.CANCEL_REPLY_TO_COMMENT}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${replyConstants.CANCEL_REPLY_TO_COMMENT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${replyConstants.CANCEL_REPLY_TO_COMMENT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            reply: {
              ...(state.list[action.payload.config.index] &&
              state.list[action.payload.config.index]['reply']
                ? state.list[action.payload.config.index]['reply']
                : []),
              status: 'cancelled'
            }
          }
        })
      };

    //FETCH CLIENTS POST ADS
    case `${replyConstants.FETCH_CLIENTS_POST_ADS_REPLY}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${replyConstants.FETCH_CLIENTS_POST_ADS_REPLY}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${replyConstants.FETCH_CLIENTS_POST_ADS_REPLY}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            post: {
              ...state.list[action.payload.config.index]['post'],
              ads: action.payload.data
            }
          }
        })
      };

    // DELETE_REPLY_TO_COMMENT
    case `${replyConstants.DELETE_REPLY_TO_COMMENT}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${replyConstants.DELETE_REPLY_TO_COMMENT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${replyConstants.DELETE_REPLY_TO_COMMENT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            reply: {
              ...(state.list[action.payload.config.index] &&
              state.list[action.payload.config.index]['reply']
                ? state.list[action.payload.config.index]['reply']
                : []),
              status: action.payload.data,
              reason: action.payload.config.message
            }
          }
        })
      };

    // WBSOCKET:UPDATES_REPLY_STATS
    case `UPDATES_REPLY_STATS`:
      let updatedClientExtracted = extractClientList(
        action.data.items,
        'reply'
      );
      return {
        ...state,
        fetching: false,
        fetched: true,
        clients: updatedClientExtracted,
        totalReplyNotifications: action.data.counts
      };

    // FETCH_CLIENT_COMNENT_REPLY_CONFIG
    case `${replyConstants.FETCH_CLIENT_COMNENT_REPLY_CONFIG}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${replyConstants.FETCH_CLIENT_COMNENT_REPLY_CONFIG}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${replyConstants.FETCH_CLIENT_COMNENT_REPLY_CONFIG}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        replyConfig: action.payload.data,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            replyConfig: action.payload.data
          }
        })
      };

    default:
      return state;
  }
};

export default Reply;
