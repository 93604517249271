import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

// Redux
import {
  fetchUCMList,
  addUserClient,
  updateUserClient,
  disableUserClient,
  fetchUCMRoles,
  resetPasswordUserClient,
} from "../../../actions/configuration/ucmAction";
import { fetchClientList } from "../../../actions/configuration/clientsAction";

import UCMConfiguration from "./ucmConfiguration";

const mapStateToProps = (state) => ({
  UCMState: state.UCMReducer,
  MeState: state.meReducer,
  ClientsState: state.clientsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUCMList: () => dispatch(fetchUCMList()),
  fetchUCMRoles: () => dispatch(fetchUCMRoles()),
  fetchClientList: () => dispatch(fetchClientList()),
  addUserClient: (data) => dispatch(addUserClient(data)),
  disableUserClient: (id, value) => dispatch(disableUserClient(id, value)),
  updateUserClient: (index, data) => dispatch(updateUserClient(index, data)),
  resetPasswordUserClient: (id, index, username) =>
    dispatch(resetPasswordUserClient(id, index, username)),
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  loading: {
    margin: "auto",
    marginTop: "calc(50vh - 96px)",
    color: theme.palette.orange[300],
  },
  notAllowed: {
    margin: "auto",
    height: "calc(100vh - 473px)",
    marginTop: "calc(50vh - 96px)",
  },
}));

const UCMContainer = (props) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [isInitialized, setInitialized] = useState(false);
  const [isNotAllowed, setNotAllowed] = useState(false);
  const [isCreatable, setCreatable] = useState(false);
  const [isUpdatable, setUpdatable] = useState(false);
  const [isDisable, setDisable] = useState(false);
  const [isPasswordResetable, setPasswordResetable] = useState(false);
  const [UCMList, setUCMList] = useState([]);
  const [UCMClientList, setUCMClientList] = useState([]);
  const [UCMRoles, setUCMRoles] = useState([]);

  const addUserClient = (data) => {
    props.addUserClient(data);
  };

  const updateUserClient = (index, data) => {
    if (props.permissions.includes("admin_enable_client_user")) {
      if (
        props.UCMState.UCMList &&
        props.UCMState.UCMList[index] &&
        props.UCMState.UCMList[index]["status"] !== data.status
      ) {
        props.disableUserClient(data.id, data.status);
      }
    }
    props.updateUserClient(index, data);
  };

  const resetUserPassword = (rowData) => {
    props.resetPasswordUserClient(
      rowData.id,
      rowData.tableData.id,
      props.MeState.me.id
    );
  };

  useEffect(() => {
    if (props.permissions.includes("admin_list_client_users")) {
      if (props.tabIndex === 7 && !isInitialized) {
        setLoading(true);
        setInitialized(true);
        props.fetchUCMRoles().then(() => {
          props.fetchUCMList();
        });
        props.fetchClientList();
      }

      if (props.permissions.includes("admin_create_client_user")) {
        setCreatable(true);
      }
      if (props.permissions.includes("admin_edit_client_user")) {
        setUpdatable(true);
      }
      if (props.permissions.includes("admin_enable_client_user")) {
        setDisable(true);
      }
      if (props.permissions.includes("admin_reset_client_user_password")) {
        setPasswordResetable(true);
      }
    } else {
      setNotAllowed(true);
    }
  }, [props.tabIndex]);

  useEffect(() => {
    if (!props.UCMState.fetchingRole) {
      setUCMRoles(props.UCMState.UCMRoles);
    }

    if (!props.UCMState.fetching && !props.ClientsState.fetching) {
      setUCMList(props.UCMState.UCMList);
      setUCMClientList(
        props.ClientsState.clientList.map((client) => {
          return client.clientShortName;
        })
      );
      setLoading(false);
    }

    if (!props.UCMState.fetchingResetPassword) {
      setUCMList(props.UCMState.UCMList);
    }
  }, [
    props.UCMState.fetching,
    props.UCMState.fetchingRole,
    props.UCMState.fetchingResetPassword,
    props.ClientsState.fetching,
  ]);

  return (
    <div className={classes.root}>
      {!isNotAllowed && !isLoading ? (
        <UCMConfiguration
          UCMList={UCMList}
          UCMClientList={UCMClientList}
          UCMRoles={UCMRoles}
          isCreatable={isCreatable}
          isUpdatable={isUpdatable}
          isDisable={isDisable}
          isPasswordResetable={isPasswordResetable}
          addUserClient={addUserClient}
          updateUserClient={updateUserClient}
          resetUserPassword={resetUserPassword}
        />
      ) : !isNotAllowed ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <Typography className={classes.notAllowed} variant="body1">
          Not Allowed
        </Typography>
      )}
    </div>
  );
};

UCMContainer.propTypes = {
  tabIndex: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UCMContainer);
