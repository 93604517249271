import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import RolesPermissions from './rolesAndPermissionsConfiguration';

import {
  fetchRolesAndPermissionList,
  fetchPermissionGroups,
  onChangePermission,
  fetchRolePermissions,
  createRole,
  editRole,
  deleteRole
} from '../../../actions/configuration/rolesAndPermissionsAction';

const styles = theme => ({
  root: {
    width: '100%'
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 367px)',
    marginTop: 'calc(50vh - 96px)'
  }
});

const mapStateToProps = state => ({
  rolesAndPermissionsReducer: state.rolesAndPermissionsReducer
});

const mapDispatchToProps = dispatch => ({
  fetchRolesAndPermissionList: () => dispatch(fetchRolesAndPermissionList()),
  fetchPermissionGroups: () => dispatch(fetchPermissionGroups()),
  onChangePermission: (value, keyGroup, keyPermission) =>
    dispatch(onChangePermission(value, keyGroup, keyPermission)),
  fetchRolePermissions: roleId => dispatch(fetchRolePermissions(roleId)),
  createRole: (roleId, roleName, permissions) =>
    dispatch(createRole(roleId, roleName, permissions)),
  editRole: (roleId, roleName, permissions) =>
    dispatch(editRole(roleId, roleName, permissions)),
  deleteRole: roleId => dispatch(deleteRole(roleId))
});

class RolesPermissionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
      roleList: [],
      permissionGroups: [],
      loading: true,
      notAllowed: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.rolesAndPermissionsReducer.roleList !== prevState.roleList) {
      update.roleList = nextProps.rolesAndPermissionsReducer.roleList;
    }

    if (
      nextProps.rolesAndPermissionsReducer.permissionGroups !==
      prevState.permissionGroups
    ) {
      update.permissionGroups =
        nextProps.rolesAndPermissionsReducer.permissionGroups;
    }

    return Object.keys(update).length ? update : null;
  }

  deleteRole(roleId) {
    this.props.deleteRole(roleId);
  }

  updateRolesPermissions(mode, roleId, roleName, permissionGroups) {
    const permissions = [];
    permissionGroups.map(group => {
      return group.permissions.map(permission => {
        if (permission.checked === true) permissions.push(permission.name);
        return true;
      });
    });

    if (mode === 'add') {
      this.props.createRole(roleId, roleName, permissions);
    } else {
      this.props.editRole(roleId, roleName, permissions);
    }
  }

  onChangePermission(value, keyGroup, keyPermission) {
    this.props.onChangePermission(value, keyGroup, keyPermission);
  }

  fetchPermissionGroups(roleId) {
    if (roleId) {
      this.props.fetchRolePermissions(roleId);
    } else {
      this.props.fetchPermissionGroups();
    }
  }

  initialize() {
    if (this.props.permissions.includes('admin_list_roles')) {
      this.props.fetchRolesAndPermissionList().then(() => {
        this.setState({
          loading: false
        });
      });
    } else {
      this.setState({
        notAllowed: true,
        loading: false
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tabIndex === 0 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true
        },
        this.initialize
      );
    }
  }

  componentDidMount() {
    if (this.props.tabIndex === 0 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true
        },
        this.initialize
      );
    }
  }

  render() {
    const { classes } = this.props;
    const { roleList, permissionGroups, loading, notAllowed } = this.state;

    return (
      <div className={classes.root}>
        {!notAllowed && !loading ? (
          <RolesPermissions
            list={roleList}
            permissionGroups={permissionGroups}
            fetchPermissionGroups={this.fetchPermissionGroups.bind(this)}
            onChangePermission={this.onChangePermission.bind(this)}
            updateRolesPermissions={this.updateRolesPermissions.bind(this)}
            deleteRole={this.deleteRole.bind(this)}
          />
        ) : !notAllowed ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <Typography className={classes.notAllowed} variant="body1">
            Not Allowed
          </Typography>
        )}
      </div>
    );
  }
}

RolesPermissionsContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  tabIndex: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(RolesPermissionsContainer));
