import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  htmlTooltip: {
    maxWidth: 440,
    fontSize: theme.typography.pxToRem(12)
  }
});

class CustomTooltip extends Component {
  render() {
    const { classes, children, title, hide } = this.props;

    return !hide ? (
      <Tooltip
        classes={{
          tooltip: classes.htmlTooltip
        }}
        title={title}
      >
        {children}
      </Tooltip>
    ) : (
      children
    );
  }
}

CustomTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.element.isRequired,
  hide: PropTypes.bool,
  arrow: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(CustomTooltip);
