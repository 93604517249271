import { setComment } from '../setters/comment';

export const extractCommentList = (data, stream) => {
  const list = [];
  data.map((comment, key) => {
    list.push(setComment(comment, stream, key));
    return true;
  });

  return Object.assign({}, list);
};
