import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import CheckBox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Chip from "@material-ui/core/Chip";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { AlertStatus } from "./AlertStatus";
import ListItemComponent from "../listItem/ListItemComponent";

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  containerItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: 30,
    paddingLeft: 20,
  },
  rootReadOnly: {
    paddingRight: 30,
    paddingLeft: 30,
  },
  selectAll: {
    marginLeft: 20,
  },
  formGroup: {
    width: "100%",
    "& ul": {
      width: "100%",
    },
  },
  statusIconContainer: {
    marginRight: -20,
    marginLeft: 20,
    width: "10%",
  },
  new: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },
  pending: {
    backgroundColor: theme.palette.warning[500],
    color:
      theme.type === "dark" ? theme.palette.primary.main : theme.palette.black,
  },
  successful: {
    backgroundColor: theme.palette.success[500],
    color: theme.palette.white,
  },
  approved: {
    backgroundColor: theme.palette.success[500],
    color: theme.palette.white,
  },
  cancelled: {
    backgroundColor: theme.palette.secondary[500],
    color: theme.palette.white,
  },
  failed: {
    backgroundColor: theme.palette.error[500],
    color: theme.palette.white,
  },
  delete_failed: {
    backgroundColor: theme.palette.error[500],
    color: theme.palette.white,
  },
  deleted: {
    backgroundColor: theme.palette.error[500],
    color: theme.palette.white,
  },
  removed: {
    backgroundColor: theme.palette.error[500],
    color: theme.palette.white,
  },
  undeletable: {
    backgroundColor: theme.palette.error[500],
    color: theme.palette.white,
  },
  unrepliable: {
    backgroundColor: theme.palette.error[500],
    color: theme.palette.white,
  },
  immediate: {
    backgroundColor: theme.palette.error[500],
    color: theme.palette.white,
  },
  volume: {
    backgroundColor: theme.palette.primary[500],
    color: theme.palette.white,
  },
  replyLabel: {
    fontSize: 11,
    padding: 0,
  },
});

class ListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectAll: false,
    };

    this.select.bind(this);
    this.selectAll.bind(this);
  }

  onChangeSelect() {
    const { list } = this.props;
    const itemsSelected = [];

    Object.entries(list).map((item) => {
      if (this.state[item[1].id]) {
        itemsSelected.push(item[1].id);
      }

      return true;
    });
    this.props.onChangeSelect(itemsSelected);
  }

  setAll = async (list, event, force) => {
    return await Promise.all(
      Object.entries(list).map((item) => {
        this.setState({
          [item[1].id]:
            item[1].copyright && item[1].copyright.status === "locked"
              ? false
              : force
              ? force
              : event.target.checked,
        });
        return true;
      })
    );
  };

  async selectAll(event, force) {
    const { list } = this.props;
    this.setState({
      selectAll: force ? force : event.target.checked,
    });
    await this.setAll(list, event, force);
    this.onChangeSelect();
  }

  select(item, event) {
    this.setState(
      {
        selectAll:
          this.state.selectAll && event.target.checked === false
            ? false
            : this.state.selectAll,
        [item[1].id]: event.target.checked,
      },
      () => {
        //check if all checked and set all to true
        const length = Object.entries(this.state).filter((item) => {
          return item[0] !== "selectAll" && item[1] === true;
        }).length;
        if (length === Object.values(this.state).length - 1) {
          this.setState({
            selectAll: true,
          });
        }
        this.onChangeSelect();
      }
    );
  }

  setCheckBoxesNames = async (list) => {
    return await Promise.all(
      Object.entries(list).map((item) => {
        if (this.state[item[1].id] === undefined) {
          this.setState({
            [item[1].id]: this.state.selectAll ? true : false,
          });
        }
        return true;
      })
    );
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      if (this.state.selectAll) {
        this.selectAll(null, true);
      }
    }

    if (
      prevProps.status !== this.props.status &&
      this.props.status === "edit"
    ) {
      this.selectAll(null, true);
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.list.length < this.props.list.length) {
      this.componentDidMount();
      return true;
    }
    return null;
  }

  async componentDidMount() {
    await this.setCheckBoxesNames(this.props.list);
  }

  render() {
    const { selectAll } = this.state;
    const {
      classes,
      list,
      selectedEvent,
      classificationEvent,
      clickItemFromComment,
      updateTranslation,
      translated,
      displayBoth,
      actionsMenu,
      indexList,
      isAddingTag,
      mode,
      status,
    } = this.props;

    return (
      <div
        className={classNames(classes.root, {
          [classes.rootReadOnly]: status === "readOnly",
        })}
      >
        <FormGroup row classes={{ root: classes.formGroup }}>
          {status === "edit" ? (
            <div className={classes.selectAll}>
              <FormControlLabel
                control={
                  <CheckBox
                    checked={selectAll}
                    onChange={(e) => this.selectAll(e)}
                  />
                }
                label="Select All"
              />
            </div>
          ) : (
            ""
          )}
          <List>
            {list
              ? Object.entries(list).map((item) => {
                  return (
                    <div className={classes.containerItem} key={item[1].id}>
                      <div>
                        {status === "edit" &&
                        this.state[item[1].id] !== undefined ? (
                          <FormControlLabel
                            control={
                              <CheckBox
                                disabled={
                                  item[1].copyright &&
                                  item[1].copyright.status === "locked"
                                }
                                checked={this.state[item[1].id]}
                                value={item[1].id}
                                onChange={(e) => this.select(item, e)}
                              />
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <ListItemComponent
                        index={indexList}
                        item={item}
                        isAddingTag={isAddingTag}
                        selectedEvent={selectedEvent ? selectedEvent : null}
                        classificationEvent={
                          classificationEvent ? classificationEvent : null
                        }
                        updateTranslation={
                          updateTranslation ? updateTranslation : null
                        }
                        translated={translated}
                        displayBoth={displayBoth}
                        clickItemFromComment={clickItemFromComment}
                        readOnly={
                          mode === "explorer" ||
                          mode === "reply" ||
                          mode === "copyright"
                            ? true
                            : false
                        }
                        mode={mode}
                        actionsMenu={actionsMenu}
                      />
                      {mode === "reply" || mode === "copyright" ? (
                        <div className={classes.statusIconContainer}>
                          {item[1][mode] && item[1][mode]["status"] ? (
                            <Chip
                              size="small"
                              label={item[1][mode]["status"]}
                              className={classNames(
                                classes.replyLabel,
                                classes[item[1][mode]["status"]]
                              )}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      ) : mode === "alert" ? (
                        <div className={classes.statusIconContainer}>
                          {item[1].alert ? (
                            <AlertStatus
                              alert={item[1].alert}
                              classes={classes}
                            />
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  );
                })
              : ""}
          </List>
        </FormGroup>
      </div>
    );
  }
}

ListComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  indexList: PropTypes.string,
  list: PropTypes.array.isRequired,
  selectedEvent: PropTypes.func,
  displayBoth: PropTypes.bool,
  isAddingTag: PropTypes.bool,
  mode: PropTypes.string,
  actionsMenu: PropTypes.array,
  classificationEvent: PropTypes.func,
  clickItemFromComment: PropTypes.func,
  translated: PropTypes.bool,
  onChangeSelect: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(ListComponent);
