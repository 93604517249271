import axios from "axios";

import { assetManagementConstants } from "../../constants/configuration/assetManagementConstants";
import { setHeaders } from "../../setters/auth";

export const fetchAssetManagementList = () => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/assets`;

  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: assetManagementConstants.FETCH_ASSET_MANAGEMENT_LIST,
    payload: promise,
  });
};

export const fetchActiveServicesList = () => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/assets/services`;

  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: assetManagementConstants.FETCH_ACTIVE_SERVICES_LIST,
    payload: promise,
  });
};

export const fetchAssetManagement = (index, assetId) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/assets/${assetId}`;

  const promise = axios.get(url, {
    headers: setHeaders(),
    index,
  });

  return dispatch({
    type: assetManagementConstants.FETCH_ASSET_MANAGEMENT,
    payload: promise,
  });
};

export const assignClientShortname =
  (index, assetId, clientShortName) => (dispatch) => {
    let url = `${process.env.URL_API_ENDPOINT}/admin/assets/${assetId}/assign/${clientShortName}`;

    const promise = axios.post(
      url,
      {},
      {
        headers: setHeaders(),
        index,
        clientShortName,
      }
    );

    return dispatch({
      type: assetManagementConstants.ASSIGN_CLIENT_SHORTNAME,
      payload: promise,
    });
  };

export const assignClientShortnameBatch =
  (assetIds, clientShortName) => (dispatch) => {
    let url = `${process.env.URL_API_ENDPOINT}/admin/assets/batch-assign/${clientShortName}`;

    const promise = axios.post(
      url,
      { asset_ids: assetIds },
      {
        headers: setHeaders(),
        assetIds,
        clientShortName,
      }
    );

    return dispatch({
      type: assetManagementConstants.ASSIGN_CLIENT_SHORTNAME_BATCH,
      payload: promise,
    });
  };

export const createTasks =
  (index, assetIds, clientShortName, formData) => (dispatch) => {
    let url = `${process.env.URL_API_ENDPOINT}/admin/assets/client/${clientShortName}/tasks/create`;

    const payloadData = {
      asset_ids: assetIds,
      init_time: formData.initTime,
      sla: parseInt(formData.sla),
      alert_sla: parseInt(formData.alertSla),
      query_only_active: formData.queryOnlyActive,
      allow_published_posts: formData.allowPublishedPosts,
      pull_insights: formData.pullInsights,
    };

    if (formData.adPlatforms) {
      let banned_ad_platform = "";
      if (formData.adPlatforms.length === 1) {
        banned_ad_platform =
          formData.adPlatforms[0] === "facebook" ? "instagram" : "facebook";
      }
      payloadData.banned_ad_platform = banned_ad_platform;
    }

    const promise = axios.post(url, payloadData, {
      headers: setHeaders(),
      index,
      clientShortName,
      payloadData,
    });

    return dispatch({
      type: assetManagementConstants.CREATE_TASKS,
      payload: promise,
    });
  };

export const toggleCrawlingDisabled =
  (index, assetIds, isEnable) => (dispatch) => {
    let url = `${process.env.URL_API_ENDPOINT}/admin/assets/${
      isEnable ? "enable-crawling" : "disable-crawling"
    }`;

    const payloadData = {
      asset_ids: assetIds,
    };

    const promise = axios.post(url, payloadData, {
      headers: setHeaders(),
      index,
      isEnable,
    });

    return dispatch({
      type: assetManagementConstants.TOGGLE_CRAWLING_DISABLED,
      payload: promise,
    });
  };

export const toggleCrawlingStartStop =
  (index, assetIds, clientShortName, isStart) => (dispatch) => {
    let url = `${
      process.env.URL_API_ENDPOINT
    }/admin/assets/client/${clientShortName}/tasks/${
      isStart ? "start" : "stop"
    }`;

    const payloadData = {
      asset_ids: assetIds,
    };

    const promise = axios.post(url, payloadData, {
      headers: setHeaders(),
      index,
      isStart,
    });

    return dispatch({
      type: assetManagementConstants.TOGGLE_CRAWLING_START_STOP,
      payload: promise,
    });
  };

export const updateAsset =
  (index, assetId, updateParam, updateValue) => (dispatch) => {
    let url = `${process.env.URL_API_ENDPOINT}/admin/assets/${assetId}`;

    const payloadData = {};
    if (updateParam === "alias") {
      payloadData.asset_name = { alias: updateValue };
    } else if (updateParam === "activeServices") {
      payloadData.active_services = updateValue;
    } else if (updateParam === "webhooks") {
      payloadData.webhook_config = {
        ...updateValue,
        filters: {
          is_published: updateValue.publishedStatus,
        },
      };
      delete payloadData.webhook_config.publishedStatus;
    } else if (updateParam === "assetVisibility") {
      payloadData.asset_visibility = updateValue;
    } else if (updateParam === "autoRehide") {
      payloadData.auto_rehide = updateValue;
    } else if (updateParam === "replierInfo") {
      payloadData.replier_asset_id = updateValue["assets"].find(
        (asset) => asset.selected
      ).id;
    }

    const promise = axios.patch(url, payloadData, {
      headers: setHeaders(),
      index,
      updateParam,
      updateValue,
    });

    return dispatch({
      type: assetManagementConstants.UPDATE_ASSET,
      payload: promise,
    });
  };

export const updateAssetBatch =
  (assetIds, updateParam, updateValue) => (dispatch) => {
    let url = `${process.env.URL_API_ENDPOINT}/admin/assets/batch-update`;

    const payloadData = { asset_ids: assetIds };

    if (updateParam === "alias") {
      payloadData.asset_name = { alias: updateValue };
    } else if (updateParam === "activeServices") {
      payloadData.active_services = updateValue;
    } else if (updateParam === "webhooks") {
      payloadData.webhook_config = {
        ...updateValue,
        filters: {
          is_published: updateValue.publishedStatus,
        },
      };
      delete payloadData.webhook_config.publishedStatus;
    } else if (updateParam === "assetVisibility") {
      payloadData.asset_visibility = updateValue;
    } else if (updateParam === "autoRehide") {
      payloadData.auto_rehide = updateValue;
    } else if (updateParam === "replierInfo") {
      payloadData.replier_asset_id = updateValue["assets"].find(
        (asset) => asset.selected
      ).id;
    }

    const promise = axios.post(url, payloadData, {
      headers: setHeaders(),
      assetIds,
      updateParam,
      updateValue,
    });

    return dispatch({
      type: assetManagementConstants.UPDATE_ASSET_BATCH,
      payload: promise,
    });
  };
