import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import FavoriteIcon from '@material-ui/icons/Favorite';

import TableComponent from '../../commons/table/tableComponent';
import DialogContent from '../../commons/dialog/contentDialogComponent';
import AlertDialog from '../../commons/dialog/alertDialogComponent';

import TaskHistoryForm from './taskHistoryForm';

import { setDateFormatTimeStamp } from '../../../setters/date';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 7
  },
  buttonAdd: {
    marginLeft: theme.spacing(2)
  },
  buttonRefresh: {
    marginLeft: theme.spacing(8),
    marginTop: theme.spacing(1),
    color: theme.palette.black
  },
  pacificTimeText: {
    marginLeft: theme.spacing(8),
    display: 'flex',
    alignItems: 'center'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  chip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  chipLabel: {
    userSelect: 'all'
  },
  favoriteColor: {
    color: '#ff0000'
  },
  filters: {
    display: 'flex',
    marginBottom: theme.spacing(3)
  },
  formControl: {
    marginLeft: theme.spacing(2)
  },
  formControlFilter: {
    minWidth: 185,
    marginLeft: theme.spacing(8)
  }
}));

const TaskHistoryConfiguration = props => {
  const classes = useStyles();
  const theme = useTheme();
  const [isFormOpen, setFormOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState('');
  const [taskToRerunIndex, setTaskToRerunIndex] = useState(null);
  const [isDialogRerunOpen, setDialogRerunOpen] = useState(false);

  const initialFormData = {
    is_favorite: 0,
    params: {
      query: '',
      order: 'relevance'
    }
  };
  const [formData, setFormData] = useState(initialFormData);

  const [formErrors, setFormErrors] = useState({});

  const orderOptions = ['date', 'rating', 'relevance', 'title', 'viewCount'];
  const onAddTask = e => {
    setFormOpen(true);
  };

  const onCloseDialogForm = () => {
    setFormOpen(false);
  };

  const onChangeFormData = (name, value, context = null) => {
    if (context) {
      setFormData({
        ...formData,
        [context]: {
          ...formData[context],
          [name]: value
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const checkForErrors = () => {
    return new Promise((resolve, reject) => {
      const formErrors = {};
      for (let field in formData) {
        let errorMessage = null;
        let contextError = {};
        let context = null;
        switch (field) {
          case 'params':
            if (formData[field]['query'] === '') {
              contextError.query = 'Query is mandatory';
              context = field;
            }
            break;
          default:
            break;
        }
        if (context) {
          formErrors[context] = {
            ...formErrors[context],
            ...contextError
          };
        } else if (errorMessage) {
          formErrors[field] = errorMessage;
        }
      }

      resolve(formErrors);
    });
  };

  const addTask = () => {
    checkForErrors().then(formErrors => {
      if (Object.keys(formErrors).length === 0) {
        formData.status = 'started';
        if (formData.params.publishedBefore) {
          formData.params.publishedBefore = formData.params.publishedBefore.toString();
        }
        if (formData.params.publishedAfter) {
          formData.params.publishedAfter = formData.params.publishedAfter.toString();
        }
        props.addTask(formData);
        onCloseDialogForm();
        setFormData(initialFormData);
      } else {
        setFormErrors(formErrors);
      }
    });
  };

  const onRerunTask = (e, data) => {
    setTaskToRerunIndex(data.tableData.id);
    setDialogRerunOpen(true);
  };

  const onDialogRerunAction = isConfirmed => {
    if (isConfirmed) {
      const data = taskHistoryList[taskToRerunIndex];

      const task = {
        is_favorite: 0,
        status: 'started',
        params: {
          query:
            data.params && data.params.query ? data.params.query : 'no query',
          order:
            data.params && data.params.order ? data.params.order : 'relevance'
        }
      };

      if (data.params && data.params.publishedBefore) {
        task.params.publishedBefore = data.params.publishedBefore.toString();
      }

      if (data.params && data.params.publishedAfter) {
        task.params.publishedAfter = data.params.publishedAfter.toString();
      }

      props.addTask(task);
    }

    setDialogRerunOpen(false);
  };

  const onUpdateFavorite = (e, data) => {
    const index = data.tableData.id;
    const taskId = data.id;
    const isFavorite = data.is_favorite ? 0 : 1;
    props.updateFavorite(index, taskId, isFavorite);
  };

  // Displayers
  const onFilterValueChange = filterType => {
    setActiveFilter(filterType);
    props.fetchByFilter(filterType);
  };

  const displayFilter = () => {
    return (
      <FormGroup className={classes.formGroup}>
        <InputLabel>Filter By</InputLabel>
        <Select
          value={activeFilter}
          onChange={e => {
            onFilterValueChange(e.target.value);
          }}
        >
          <MenuItem value="">None</MenuItem>
          <MenuItem value="favorites">Favorites</MenuItem>
        </Select>
      </FormGroup>
    );
  };

  // Table helpers
  const displayChips = (index, data) => {
    let chips = [];
    let copyData = { ...data };
    for (let key in copyData) {
      let keyName = key;
      if (['publishedBefore', 'publishedAfter'].indexOf(key) !== -1) {
        copyData[key] = setDateFormatTimeStamp(parseInt(copyData[key]));
      }
      if (key === 'crawled_items') {
        keyName = 'Found videos';
      }
      if (key === 'inserted_items') {
        keyName = 'New videos';
      }
      if (key === 'ignored_items') {
        keyName = 'Ignored videos';
      }
      if (key === 'updated_items') {
        keyName = 'Existing videos updated';
      }
      if (key === 'refreshed_items') {
        keyName = 'Existing videos not updated';
      }
      chips.push(
        <Chip
          key={`${key}-${index}`}
          classes={{ root: classes.chip, label: classes.chipLabel }}
          label={`${keyName}: ${copyData[key]}`}
        />
      );
    }
    return chips;
  };

  const { isCreatable, isUpdatable, taskHistoryList } = props;

  // Table schema
  const tableColumns = [
    {
      title: 'Name',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 7,
        width: ''
      },
      field: 'id',
      render: rowData => `Search${rowData.id}`
    },
    {
      title: 'Status',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 7
      },
      field: 'status'
    },
    {
      title: 'Parameters',
      cellStyle: {
        padding: theme.spacing(0, 1, 1, 1),
        paddingLeft: 7,
        width: '420px'
      },
      field: 'params',
      render: rowData => displayChips(rowData.tableData.id, rowData.params)
    },
    {
      title: 'Statistics',
      cellStyle: {
        padding: theme.spacing(0, 1, 1, 1),
        paddingLeft: 7,
        width: '480px'
      },
      field: 'stats',
      render: rowData => displayChips(rowData.tableData.id, rowData.stats)
    },
    {
      title: 'Last Run',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 7
      },
      field: 'last_run'
    },
    {
      title: 'Last Successful Run',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 7
      },
      field: 'last_successful_run'
    }
  ];

  const tableActions = [];
  if (isCreatable) {
    tableActions.push({
      icon: DirectionsRunIcon,
      tooltip: 'Re-run task',
      onClick: (event, rowData) => onRerunTask(event, rowData)
    });
  }
  if (isUpdatable) {
    tableActions.push(rowData => ({
      icon: FavoriteIcon,
      iconProps: {
        classes: { colorPrimary: classes.favoriteColor },
        color: rowData.is_favorite ? 'primary' : 'disabled'
      },
      tooltip: rowData.is_favorite ? 'Cancel favorite' : 'Favorite task',
      onClick: (event, rowData) => onUpdateFavorite(event, rowData)
    }));
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h5">Task History</Typography>
        {isCreatable && (
          <Button
            variant="contained"
            className={classes.buttonAdd}
            size="small"
            color="primary"
            onClick={e => {
              return onAddTask(e);
            }}
          >
            Add Task
          </Button>
        )}
      </div>
      <div className={classes.filters}>
        <FormControl className={classes.formControlFilter}>
          {displayFilter()}
        </FormControl>
        <IconButton
          className={classes.buttonRefresh}
          onClick={e => props.fetchByFilter(activeFilter)}
        >
          <RefreshIcon />
        </IconButton>
        <Typography className={classes.pacificTimeText}>
          Pacific Time:&nbsp;
          {new Date().toLocaleTimeString('en-US', {
            timeZone: 'America/Los_Angeles'
          })}
        </Typography>
      </div>
      <TableComponent
        editable={false}
        columns={tableColumns}
        data={taskHistoryList}
        actions={tableActions}
        title=""
      />
      <DialogContent
        title={'Task'}
        open={isFormOpen}
        fullWidth={true}
        close={onCloseDialogForm}
      >
        <div>
          <TaskHistoryForm
            formData={formData}
            errors={formErrors}
            onChangeFormData={onChangeFormData}
            orderOptions={orderOptions}
          />
          <div className={classes.buttonContainer}>
            <Button
              size="small"
              color="primary"
              onClick={e => {
                return addTask(e);
              }}
            >
              Add Task
            </Button>
            <Button
              onClick={e => {
                return onCloseDialogForm(e);
              }}
              size="small"
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
      <AlertDialog
        title="Re-run task?"
        message={`Are you sure you want to re-run the task with name 'Search${taskHistoryList[
          taskToRerunIndex
        ] && taskHistoryList[taskToRerunIndex].id}'?`}
        buttonCancelText="Cancel"
        buttonAcceptText="Accept"
        open={isDialogRerunOpen}
        action={onDialogRerunAction}
      />
    </div>
  );
};

TaskHistoryConfiguration.propTypes = {
  isCreatable: PropTypes.bool.isRequired,
  isUpdatable: PropTypes.bool.isRequired,
  taskHistoryList: PropTypes.array.isRequired,
  addTask: PropTypes.func.isRequired,
  updateFavorite: PropTypes.func.isRequired,
  fetchByFilter: PropTypes.func.isRequired
};

export default TaskHistoryConfiguration;
