import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Link from "@material-ui/core/Link";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { sendLogs } from "../../../actions/meAction";
import DialogContent from "../../commons/dialog/contentDialogComponent";
import Textarea from "../../commons/textarea/textareaComponent";

const mapStateToProps = (state) => ({
  me: state.meReducer,
});

const mapDispatchToProps = (dispatch) => ({
  sendLogs: (message, requests) => dispatch(sendLogs(message, requests)),
});

const styles = (theme) => ({
  root: {},
  fullList: {
    width: "auto",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  badge: {
    cursor: "pointer",
    height: 16,
    marginLeft: theme.spacing(1),
    fontSize: 11,
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    overflow: "inherit",
  },
  sendLogsButton: {
    position: "absolute",
    bottom: 0,
    margin: "auto",
    width: "100%",
  },
});

class LeftMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      openSendLogsDialog: false,
      me: {},
      message: "",
    };

    this.close.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.me.me !== prevState.me) {
      update.me = nextProps.me.me;
    }
    return Object.keys(update).length ? update : null;
  }

  close = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ open: false });
    this.props.onClose(false);
  };

  openSendLogsDialog() {
    this.setState({
      openSendLogsDialog: true,
    });
  }

  handleChangeMessage(e, id) {
    this.setState({
      [id]: e.target.value,
    });
  }

  closeSendLogsDialog() {
    this.setState({
      openSendLogsDialog: false,
      message: "",
    });
  }

  sendLogs() {
    const data = localStorage.getItem("requests");
    this.props.sendLogs(this.state.message, data);
    this.closeSendLogsDialog();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ open: this.props.open });
    }
  }

  displayButtonsSendLogs() {
    const { classes } = this.props;
    return (
      <div className={classes.buttonContainer}>
        <Button
          size="small"
          color="primary"
          onClick={(e) => {
            return this.sendLogs();
          }}
        >
          {"Send Logs"}
        </Button>
        <Button
          onClick={() => {
            return this.closeSendLogsDialog();
          }}
          size="small"
          color="secondary"
        >
          Cancel
        </Button>
      </div>
    );
  }

  fullList() {
    const { classes } = this.props;
    const { me } = this.state;
    return (
      <div className={classes.fullList} role="presentation">
        <List>
          {[
            {
              text: "Home",
              link: "/",
              icon: "home",
            },
            {
              text: "Moderation",
              link: "/moderation",
              icon: "fas fa-shield-alt",
              iconType: "fontAwsome",
            },
            {
              text: "Alerts",
              link: "/alerts",
              icon: "fas fa-exclamation-triangle",
              iconType: "fontAwsome",
            },
            {
              text: "IP Protection",
              link: "/copyright",
              icon: "fas fa-globe",
              iconType: "fontAwsome",
            },
            {
              text: "Explorer",
              link: "/explorer",
              icon: "search",
            },
            {
              text: "Customer Engagement",
              link: "/reply",
              icon: "far fa-comments",
              iconType: "fontAwsome",
            },
            {
              type: "divider",
            },
            {
              text: "Admin",
              icon: "fas fa-wrench",
              iconType: "fontAwsome",
              link: "/admin",
              hide: me.permissions && !me.permissions.includes("admin_access"),
            },
            {
              text: "Sales",
              icon: "fas fa-user-tie",
              iconType: "fontAwsome",
              link: "/sales",
              hide: me.permissions && !me.permissions.includes("sales_access"),
            },
            {
              type: "divider",
            },
            {
              text: "Documentation",
              link: process.env.DOCUMENTATION_URL,
              icon: "fas fa-book",
              iconType: "fontAwsome",
              target: true,
            },
          ].map((item, key) => {
            if (item.type && item.type === "divider" && !item.hide) {
              return <Divider key={key} variant="middle" />;
            }

            if (!item.hide) {
              return (
                <ListItem className={classes.listItem} button key={key}>
                  {item.iconType === "fontAwsome" ? (
                    <Icon
                      className={classNames(classes.icon, {
                        [item.icon]: true,
                      })}
                    />
                  ) : (
                    <Icon className={classes.icon}>{item.icon}</Icon>
                  )}
                  <Link
                    rel={item.target ? "noopener noreferrer" : ""}
                    target={item.target ? "_blank" : ""}
                    color="inherit"
                    href={item.link}
                  >
                    <Typography>{item.text}</Typography>
                  </Link>
                  {item.badge ? (
                    <Chip
                      className={classes.badge}
                      label={item.badge}
                      color="secondary"
                      size="small"
                    />
                  ) : (
                    ""
                  )}
                </ListItem>
              );
            }
            return <div key={key} />;
          })}
        </List>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { open, message, openSendLogsDialog } = this.state;

    return (
      <div className={classes.root}>
        <Drawer open={open} onClose={this.close(false)}>
          {this.fullList()}
          <Button
            onClick={this.openSendLogsDialog.bind(this)}
            color="primary"
            className={classes.sendLogsButton}
          >
            Send Logs
          </Button>
          <DialogContent
            title={"Send Logs"}
            open={openSendLogsDialog}
            close={this.closeSendLogsDialog.bind(this)}
          >
            <div>
              <Textarea
                id={"message"}
                label={"Message"}
                value={message}
                margin={"dense"}
                onChange={this.handleChangeMessage.bind(this)}
              />
              {this.displayButtonsSendLogs()}
            </div>
          </DialogContent>
        </Drawer>
      </div>
    );
  }
}

LeftMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(LeftMenu));
