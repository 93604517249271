import { SFConstants } from "../../constants/configuration/SFConstants";

import { extractStickyFilterList } from "../../extractors/stickyFiltersExtractor";

const initialState = {
  fetching: false,
  fetched: false,
  stickyFiltersList: [],
  error: null,
};

const stickyFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_STICKY_FILTERS_LIST
    case `${SFConstants.FETCH_STICKY_FILTERS_LIST}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${SFConstants.FETCH_STICKY_FILTERS_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${SFConstants.FETCH_STICKY_FILTERS_LIST}_FULFILLED`:
      const stickyFiltersList = extractStickyFilterList(action.payload.data);
      return {
        ...state,
        fetching: false,
        fetched: true,
        stickyFiltersList,
      };

    // ADD_STICKY_FILTER
    case `${SFConstants.ADD_STICKY_FILTER}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${SFConstants.ADD_STICKY_FILTER}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${SFConstants.ADD_STICKY_FILTER}_FULFILLED`:
      const addedStickyFilter = action.payload.config.addData;
      addedStickyFilter.id = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        stickyFiltersList: [addedStickyFilter, ...state.stickyFiltersList],
      };

    // UPDATE_STICKY_FILTER
    case `${SFConstants.UPDATE_STICKY_FILTER}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${SFConstants.UPDATE_STICKY_FILTER}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${SFConstants.UPDATE_STICKY_FILTER}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        stickyFiltersList: Object.assign([], {
          ...state.stickyFiltersList,
          [action.payload.config.index]: {
            ...state.stickyFiltersList[action.payload.config.index],
            name: action.payload.config.editData.name,
            asset_ids: action.payload.config.editData.asset_ids,
            order_number: action.payload.config.editData.order_number,
          },
        }),
      };
    default:
      return state;
  }
};

export default stickyFiltersReducer;
