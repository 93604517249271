import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBox from '@material-ui/core/Checkbox';

import DatePicker from '../commons/picker/dateTimePickerComponent';
import InputComponent from '../commons/input/inputComponent';
import SelectComponent from '../commons/select/selectComponent';

const styles = theme => ({
  root: {},
  button: {
    marginRight: 20
  },
  formControlContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  formControlContent: {
    marginRight: 20
  },
  container: {
    display: 'flex',
    position: 'relative',
    margin: 3
  },
  containerDivider: {
    display: 'flex',
    marginTop: 20,
    marginBottom: 20,
    width: '100%'
  },
  inputWidth: {
    width: 220
  },
  divider: {
    width: '100%'
  },
  infoIcon: {
    fontSize: 13,
    position: 'absolute',
    zIndex: 3000,
    marginLeft: -15
  }
});

class ReplySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilters: false,
      settedValue: false,
      clients: props.clients,
      userList: props.userList,
      clientSelected: props.defaultValues
        ? props.defaultValues.client_short_name
        : '',
      data: {
        replyStatus:
          props.defaultValues &&
          props.defaultValues.reply_status &&
          props.defaultValues.reply_status.length
            ? props.defaultValues.reply_status.split(',')
            : [],
        startTime: props.defaultValues ? props.defaultValues.start : '',
        endTime: props.defaultValues ? props.defaultValues.end : '',
        createdAtStart: props.defaultValues
          ? props.defaultValues.reply_created_at_start
          : '',
        createdAtEnd: props.defaultValues
          ? props.defaultValues.reply_created_at_end
          : '',
        updatedAtStart: props.defaultValues
          ? props.defaultValues.reply_updated_at_start
          : '',
        updatedAtEnd: props.defaultValues
          ? props.defaultValues.reply_updated_at_end
          : '',
        createdBy: props.defaultValues
          ? props.defaultValues.reply_created_by
          : '',
        updatedBy: props.defaultValues
          ? props.defaultValues.reply_updated_by
          : '',
        message: props.defaultValues ? props.defaultValues.reply_message : '',
        clientShortName: props.defaultValues
          ? props.defaultValues.client_short_name
          : '',
        sort: props.defaultValues ? props.defaultValues.sort : false
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userList !== this.props.userList) {
      this.setState({
        userList: this.props.userList
      });
    }
  }

  handleChange(event, id) {
    if (id === 'clientShortName') {
      this.setState(
        {
          clientSelected: event.target.value
        },
        () => {
          this.clearFilters();
        }
      );
    }

    this.setState({
      data: {
        ...this.state.data,
        [id]:
          event.currentTarget.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      }
    });
  }

  setSelectedDate(name, date) {
    this.setState({
      data: {
        ...this.state.data,
        [name]: date
      },
      settedValue: false
    });
  }

  clearFilters() {
    this.setState({
      settedValue: true,
      data: {
        ...this.state.data,
        replyStatus: ['new', 'pending'],
        message: '',
        createdAtStart: '',
        updatedAtStart: '',
        createdAtEnd: '',
        updatedAtEnd: '',
        sort: false,
        createdBy: '',
        updatedBy: '',
        startTime: '',
        endTime: ''
      }
    });
  }

  search() {
    this.props.getFilterData(this.state.data);
  }

  render() {
    const { classes, hide } = this.props;
    const { settedValue, clients, data, clientSelected, userList } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.formControlContainer}>
          <div className={classes.container}>
            <SelectComponent
              id={'clientShortName'}
              label={'Client name'}
              multiple={false}
              value={data['clientShortName'] ? data['clientShortName'] : ''}
              items={clients}
              attributName={'name'}
              attributId={'clientShortName'}
              onChange={this.handleChange.bind(this)}
            />
          </div>
          <div className={classes.containerDivider}>
            <Divider className={classes.divider} component="div" />
          </div>
          {clientSelected ? (
            <div className={classes.container}>
              <FormControlLabel
                control={
                  <CheckBox
                    checked={void 0 !== data['sort'] ? data['sort'] : false}
                    onChange={e => this.handleChange(e, 'sort')}
                    color="primary"
                  />
                }
                label="Sort By Created Time"
              />
            </div>
          ) : (
            ''
          )}
          <div className={classes.containerDivider}>
            <Divider className={classes.divider} component="div" />
          </div>
          {clientSelected ? (
            <div className={classes.container}>
              <SelectComponent
                id={'replyStatus'}
                label={'Reply status'}
                multiple={true}
                display={2}
                value={data['replyStatus'] ? data['replyStatus'] : []}
                items={[
                  'new',
                  'pending',
                  'cancelled',
                  'successful',
                  'deleted',
                  'undeletable',
                  'unrepliable',
                  'failed',
                  'delete_failed'
                ]}
                onChange={this.handleChange.bind(this)}
              />
            </div>
          ) : (
            ''
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <SelectComponent
                id={'createdBy'}
                label={'Reply created by'}
                multiple={true}
                display={1}
                value={data['createdBy'] ? data['createdBy'] : []}
                items={userList}
                attributName={'username'}
                attributId={'id'}
                onChange={this.handleChange.bind(this)}
              />
              <SelectComponent
                id={'updatedBy'}
                label={'Reply updated by'}
                multiple={true}
                display={1}
                value={data['updatedBy'] ? data['updatedBy'] : []}
                items={userList}
                attributName={'username'}
                attributId={'id'}
                onChange={this.handleChange.bind(this)}
              />
            </div>
          ) : (
            ''
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <InputComponent
                id={'message'}
                label={'Reply message'}
                value={data['message']}
                onChange={this.handleChange.bind(this)}
              />
            </div>
          ) : (
            ''
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <DatePicker
                settedValue={settedValue}
                name={'startTime'}
                label={'Comment created start'}
                value={data['startTime']}
                setSelectedDate={this.setSelectedDate.bind(this)}
              />
            </div>
          ) : (
            ''
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <DatePicker
                settedValue={settedValue}
                name={'endTime'}
                label={'Comment created end'}
                value={data['endTime']}
                setSelectedDate={this.setSelectedDate.bind(this)}
              />
            </div>
          ) : (
            ''
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <DatePicker
                settedValue={settedValue}
                name={'createdAtStart'}
                label={'Selected for reply start'}
                value={data['createdAtStart']}
                setSelectedDate={this.setSelectedDate.bind(this)}
              />
            </div>
          ) : (
            ''
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <DatePicker
                settedValue={settedValue}
                name={'createdAtEnd'}
                label={'Selected for reply end'}
                value={data['createdAtEnd']}
                setSelectedDate={this.setSelectedDate.bind(this)}
              />
            </div>
          ) : (
            ''
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <DatePicker
                settedValue={settedValue}
                name={'updatedAtStart'}
                label={'Last updated reply start'}
                value={data['updatedAtStart']}
                setSelectedDate={this.setSelectedDate.bind(this)}
              />
            </div>
          ) : (
            ''
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <DatePicker
                settedValue={settedValue}
                name={'updatedAtEnd'}
                label={'Last updated reply end'}
                value={data['updatedAtEnd']}
                setSelectedDate={this.setSelectedDate.bind(this)}
              />
            </div>
          ) : (
            ''
          )}
          <div className={classes.container}>
            <Button
              variant="contained"
              color="primary"
              className={classNames(classes.button)}
              onClick={this.search.bind(this)}
            >
              Search
            </Button>
            <Button
              variant="contained"
              className={classNames(classes.button)}
              onClick={this.clearFilters.bind(this)}
            >
              Clear Filters
            </Button>
            {typeof hide === 'function' ? (
              <Button
                variant="contained"
                className={classNames(classes.button)}
                onClick={hide}
              >
                Close
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

ReplySearch.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  defaultValues: PropTypes.object,
  getFilterData: PropTypes.func.isRequired,
  clients: PropTypes.array.isRequired,
  userList: PropTypes.array.isRequired,
  hide: PropTypes.func
};

export default withStyles(styles, { withTheme: true })(ReplySearch);
