import { setAssetManagement } from '../setters/assetManagement';

export const extractAssetManagementList = data => {
  const assets = [];
  data.map(asset => {
    assets.push(setAssetManagement(asset));
    return true;
  });

  return assets;
};
