import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Redirect } from 'react-router-dom';

import AppBar from '../../components/commons/appBar/AppBarComponent';
import LeftMenu from '../../components/commons/leftMenu/leftMenuComponent';
import TabsComponent from '../../components/commons/tabs/tabsComponent';

import { fetchMe } from '../../actions/meAction';
import { fetchAssetManagement } from '../../actions/configuration/assetManagementAction';

import PostKPIsContainer from '../../components/taskKPIs/postKPIs/postKPIsContainer';
import CommentKPIsContainer from '../../components/taskKPIs/commentKPIs/commentKPIsContainer';
import LinkedAccountsContainer from '../../components/taskKPIs/linkedAccounts/linkedAccountsContainer';

const mapDispatchToProps = dispatch => ({
  fetchMe: () => dispatch(fetchMe()),
  fetchAssetManagement: (index, assetId) =>
    dispatch(fetchAssetManagement(index, assetId))
});

const styles = theme => ({
  root: {
    display: 'flex'
  },
  content: {
    width: '100%',
    paddingTop: theme.spacing(5)
  },
  breadcrumbsContainer: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(8)
  },
  tabsContainer: {
    padding: theme.spacing(8),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1)
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.black
  }
});

class TaskKPIsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asset: null,
      assetId:
        props.location.state && props.location.state.assetId
          ? props.location.state.assetId
          : window.location.pathname.replace('/task-kpis/', ''),
      tabIndex: props.location.state ? props.location.state.hasDefaultValue : 0,
      hasDefaultValue: props.location.state
        ? props.location.state.hasDefaultValue
        : 0,
      loading: true,
      permissions: []
    };
  }

  logout() {
    this.props.signOut();
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  redirectConfiguration() {
    this.setState({
      redirectConfiguration: true
    });
  }

  breadcrumbs(assetId) {
    const { classes } = this.props;
    return (
      <Breadcrumbs aria-label="Breadcrumb">
        <Link
          className={classes.link}
          onClick={this.redirectConfiguration.bind(this)}
        >
          admin
        </Link>
        <Typography>{assetId}</Typography>
      </Breadcrumbs>
    );
  }

  onChangeTabIndex = tabIndex => {
    this.setState({
      tabIndex
    });
  };

  componentDidMount() {
    this.props.fetchMe().then(response => {
      this.setState({
        permissions: response.value.data.permissions,
        loading: false
      });
    });
  }

  render() {
    const { classes } = this.props;
    const {
      open,
      loading,
      assetId,
      redirectConfiguration,
      hasDefaultValue,
      permissions,
      tabIndex
    } = this.state;

    if (redirectConfiguration) {
      return (
        <Redirect
          to={{
            pathname: `/admin`,
            state: {
              hasDefaultValue: 6
            }
          }}
        />
      );
    }

    const tabs = ['Post KPIs', 'Comment KPIs', 'Linked Accounts'];

    return (
      <div className={classes.root}>
        <AppBar
          action={this.handleDrawerOpen}
          status={open}
          selected={'admin'}
          logout={this.logout.bind(this)}
        />
        <LeftMenu open={open} onClose={this.handleDrawerClose.bind(this)} />
        <main className={classes.content}>
          <div className={classes.breadcrumbsContainer}>
            {this.breadcrumbs(assetId)}
          </div>
          <div className={classes.tabsContainer}>
            {!loading && (
              <TabsComponent
                hasDefaultValue={hasDefaultValue}
                tabs={tabs}
                onChangeIndex={this.onChangeTabIndex.bind(this)}
              >
                <PostKPIsContainer
                  assetId={assetId}
                  tabIndex={tabIndex}
                  permissions={permissions}
                />
                <CommentKPIsContainer
                  assetId={assetId}
                  tabIndex={tabIndex}
                  permissions={permissions}
                />
                <LinkedAccountsContainer
                  assetId={assetId}
                  tabIndex={tabIndex}
                  permissions={permissions}
                />
              </TabsComponent>
            )}
          </div>
        </main>
      </div>
    );
  }
}

TaskKPIsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TaskKPIsPage));
