import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import InputComponent from "../../commons/input/inputComponent";
import Textarea from "../../commons/textarea/textareaComponent";

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  formControlContainer: {
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
  formGroup: {
    width: "50%",
  },
  formGroupEditor: {
    marginTop: theme.spacing(6),
    width: "50%",
  },
  errorText: {
    color: "#e06666",
  },
});

class GroupsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      checkFilter: props.checkFilter,
      jsonText:
        props.defaultValue && props.defaultValue.filters
          ? JSON.stringify(props.defaultValue.filters, null, 2)
          : "",
      data: props.defaultValue
        ? props.defaultValue
        : {
            id: "",
            label: "",
            description: "",
            filters: [],
          },
      error: props.error
        ? props.error
        : {
            id: "",
            label: "",
            description: "",
            filters: "",
          },
    };
  }

  onChange(e, value) {
    this.setState(
      {
        data: {
          ...this.state.data,
          [value]: e.target.value,
        },
      },
      () => {
        this.props.onChangeGroupForm(this.state.data);
      }
    );
  }

  onChangeEditor(event) {
    const newText = event.target.value;

    try {
      const parsedData = JSON.parse(newText);

      this.setState(
        {
          jsonText: newText,
          data: {
            ...this.state.data,
            filters: parsedData,
          },
          error: {
            ...this.state.error,
            filters: null,
          },
        },
        () => {
          this.props.onChangeGroupForm(this.state.data);
        }
      );
    } catch (parseError) {
      this.setState(
        {
          jsonText: newText,
          data: {
            ...this.state.data,
            filters: null,
          },
          error: {
            ...this.state.error,
            filters: parseError.message,
          },
        },
        () => {
          this.props.onErrorGroupForm();
        }
      );
    }
  }

  displayFilters() {
    const { classes } = this.props;
    const { error } = this.state;

    return (
      <FormGroup className={classes.formGroupEditor}>
        <InputLabel>Filters *</InputLabel>
        <Textarea
          id={"filters"}
          rows={12}
          rowsMax={12}
          value={this.state.jsonText}
          margin={"dense"}
          onChange={(e) => {
            return this.onChangeEditor(e);
          }}
        />
        {error["filters"] ? (
          <FormHelperText className={classes.errorText}>
            {error["filters"]}
          </FormHelperText>
        ) : (
          ""
        )}
      </FormGroup>
    );
  }

  displayTextField(name, label, required, disabled, multiline) {
    const { defaultValue } = this.props;
    const { data, error } = this.state;
    return (
      <InputComponent
        multiline={multiline}
        required={required}
        disabled={disabled}
        defaultValue={
          Object.values(defaultValue).length ? defaultValue[name] : data[name]
        }
        onChange={(e) => {
          return this.onChange(e, name);
        }}
        error={error[name] ? true : false}
        helperText={error[name]}
        label={label}
        margin="dense"
        fullWidth={true}
      />
    );
  }

  displayTextFields() {
    const { classes, mode } = this.props;
    return (
      <FormGroup className={classes.formGroup}>
        {this.displayTextField(
          "id",
          "ID",
          true,
          mode === "edit" ? true : false,
          false
        )}
        {this.displayTextField("label", "Label", true, false, false)}
        {this.displayTextField("description", "Description", true, false, true)}
      </FormGroup>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checkFilter !== this.props.checkFilter) {
      this.setState({
        checkFilter: this.props.checkFilter,
      });
    }

    if (prevProps.error !== this.props.error) {
      this.setState({
        error: this.props.error,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.state;
    return (
      <div className={classes.root}>
        {loading ? (
          <CircularProgress />
        ) : (
          <div className={classes.formControlContainer}>
            <FormControl className={classes.formControl}>
              {this.displayTextFields()}
            </FormControl>
            <FormControl className={classes.formControl}>
              {this.displayFilters()}
            </FormControl>
          </div>
        )}
      </div>
    );
  }
}

GroupsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onChangeGroupForm: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  error: PropTypes.object,
  checkFilter: PropTypes.bool.isRequired,
  onErrorGroupForm: PropTypes.func.isRequired,
  mode: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(GroupsForm);
