import { copyrightConstants } from '../constants/copyrightConstants';

import {
  extractCopyrightList,
  extractCopyrightReportList
} from '../extractors/copyrightExtractor';

import { extractClientList } from '../extractors/clientsExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  list: [],
  clients: [],
  reportList: [],
  total: null,
  cursor: null,
  error: null
};

const Copyright = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_CLIENTS_COPYRIGHT
    case `${copyrightConstants.FETCH_CLIENTS_COPYRIGHT}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${copyrightConstants.FETCH_CLIENTS_COPYRIGHT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${copyrightConstants.FETCH_CLIENTS_COPYRIGHT}_FULFILLED`:
      let clientList = extractClientList(
        action.payload.data.items,
        'copyright'
      );

      return {
        ...state,
        fetching: false,
        fetched: true,
        clients: clientList
      };

    // FETCH_CLIENTS_REPORTS_COPYRIGHT
    case `${copyrightConstants.FETCH_CLIENTS_REPORTS_COPYRIGHT}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${copyrightConstants.FETCH_CLIENTS_REPORTS_COPYRIGHT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${copyrightConstants.FETCH_CLIENTS_REPORTS_COPYRIGHT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        reportList: extractCopyrightReportList(action.payload.data.items)
      };

    // LOCK_REPORT_COPYRIGHT
    case `${copyrightConstants.LOCK_REPORT_COPYRIGHT}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${copyrightConstants.LOCK_REPORT_COPYRIGHT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${copyrightConstants.LOCK_REPORT_COPYRIGHT}_FULFILLED`:
      let selectedReport = state.reportList.filter(
        report => action.payload.config.reportId === report.id
      )[0];

      return {
        ...state,
        fetching: false,
        fetched: true,
        reportList: Object.assign([], {
          ...state.reportList,
          [selectedReport.key]: {
            ...state.reportList[selectedReport.key],
            lock: action.payload.config.value
          }
        })
      };

    // RESET VIDEO LIST COPYRIGHT
    case `${copyrightConstants.RESET_VIDEO_LIST_COPYRIGHT}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${copyrightConstants.RESET_VIDEO_LIST_COPYRIGHT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${copyrightConstants.RESET_VIDEO_LIST_COPYRIGHT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetchComment: false,
        list: []
      };

    // GET_VIDEO_LIST_BY_CLIENT_COPYRIGHT
    case `${copyrightConstants.GET_VIDEO_LIST_BY_CLIENT_COPYRIGHT}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${copyrightConstants.GET_VIDEO_LIST_BY_CLIENT_COPYRIGHT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${copyrightConstants.GET_VIDEO_LIST_BY_CLIENT_COPYRIGHT}_FULFILLED`:
      let copyrightList = extractCopyrightList(action.payload.data);
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetchComment: true,
        list: state.list.concat(copyrightList['list']),
        total: action.payload.data.total,
        cursor: action.payload.data.cursor
      };

    //FETCH HISTORY FROM VIDEO
    case `${copyrightConstants.FETCH_HISTORY_FROM_VIDEO_COPYRIGHT}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${copyrightConstants.FETCH_HISTORY_FROM_VIDEO_COPYRIGHT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${copyrightConstants.FETCH_HISTORY_FROM_VIDEO_COPYRIGHT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            history: action.payload.data.items.length
              ? action.payload.data.items
              : []
          }
        })
      };

    // MOVE_VIDEO_FROM_REPORT
    case `${copyrightConstants.MOVE_VIDEO_FROM_REPORT}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${copyrightConstants.MOVE_VIDEO_FROM_REPORT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${copyrightConstants.MOVE_VIDEO_FROM_REPORT}_FULFILLED`:
      let moveList = JSON.parse(JSON.stringify(state.list));

      action.payload.config.videoListSelected.map(video => {
        moveList.map(item => {
          if (item.id === video) {
            item.copyright = {
              ...item.copyright,
              report_id: action.payload.config.reportSelected
            };
          }
          return true;
        });
        return true;
      });

      return {
        ...state,
        fetching: false,
        fetched: true,
        list: moveList
      };

    // REMOVE_VIDEO_FROM_REPORT
    case `${copyrightConstants.REMOVE_VIDEO_FROM_REPORT}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${copyrightConstants.REMOVE_VIDEO_FROM_REPORT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${copyrightConstants.REMOVE_VIDEO_FROM_REPORT}_FULFILLED`:
      let removeList = JSON.parse(JSON.stringify(state.list));

      action.payload.config.videoListSelected.map(video => {
        removeList.map(item => {
          if (item.id === video) {
            item.copyright = {
              ...item.copyright,
              report_id: ''
            };
          }
          return true;
        });
        return true;
      });

      return {
        ...state,
        fetching: false,
        fetched: true,
        list: removeList
      };

    // UPDATE_CLIENTS_VIDEOS_TRANSLATION
    case `${copyrightConstants.UPDATE_CLIENTS_VIDEOS_TRANSLATION_COPYRIGHT}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${copyrightConstants.UPDATE_CLIENTS_VIDEOS_TRANSLATION_COPYRIGHT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${copyrightConstants.UPDATE_CLIENTS_VIDEOS_TRANSLATION_COPYRIGHT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            message: action.payload.data.message,
            messageTranslated: action.payload.data.messageTranslated
          }
        })
      };

    // PROCESS_COPYRIGHT_TO_VIDEO
    case `${copyrightConstants.PROCESS_COPYRIGHT_TO_VIDEO}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${copyrightConstants.PROCESS_COPYRIGHT_TO_VIDEO}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${copyrightConstants.PROCESS_COPYRIGHT_TO_VIDEO}_FULFILLED`:
      const dataProcess = action.payload.config.videoData;
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            copyright: {
              ...state.list[action.payload.config.index]['copyright'],
              infringement: dataProcess.infringement,
              categories: dataProcess.categories,
              locations: dataProcess.locations,
              status: 'pending'
            }
          }
        })
      };

    // APPROVE_COPYRIGHT_TO_VIDEO
    case `${copyrightConstants.APPROVE_COPYRIGHT_TO_VIDEO}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${copyrightConstants.APPROVE_COPYRIGHT_TO_VIDEO}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${copyrightConstants.APPROVE_COPYRIGHT_TO_VIDEO}_FULFILLED`:
      const dataApprove = action.payload.config.videoData;
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            copyright: {
              ...state.list[action.payload.config.index]['copyright'],
              infringement: dataApprove.infringement,
              categories: dataApprove.categories,
              locations: dataApprove.locations,
              status: 'approved'
            }
          }
        })
      };

    // REMOVE_COPYRIGHT_TO_VIDEO
    case `${copyrightConstants.REMOVE_COPYRIGHT_TO_VIDEO}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${copyrightConstants.REMOVE_COPYRIGHT_TO_VIDEO}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${copyrightConstants.REMOVE_COPYRIGHT_TO_VIDEO}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            copyright: {
              ...(state.list[action.payload.config.index] &&
              state.list[action.payload.config.index]['copyright']
                ? state.list[action.payload.config.index]['copyright']
                : []),
              status: 'removed'
            }
          }
        })
      };

    //FETCH CLIENTS POST ADS
    case `${copyrightConstants.FETCH_CLIENTS_POST_ADS_COPYRIGHT}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${copyrightConstants.FETCH_CLIENTS_POST_ADS_COPYRIGHT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${copyrightConstants.FETCH_CLIENTS_POST_ADS_COPYRIGHT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            post: {
              ...state.list[action.payload.config.index]['post'],
              ads: action.payload.data
            }
          }
        })
      };

    // WBSOCKET:UPDATES_COPYRIGHT_STATS
    case `UPDATES_COPYRIGHT_STATS`:
      let updatedClientExtracted = extractClientList(
        action.data.items,
        'copyright'
      );
      return {
        ...state,
        fetching: false,
        fetched: true,
        clients: updatedClientExtracted
      };

    default:
      return state;
  }
};

export default Copyright;
