import { setDateFormatTimeStamp } from './date';

export const setPostKPI = (postKPI, key = null) => {
  const item = {
    task_id: postKPI.task_id ? postKPI.task_id : '',
    task_type_pretty: postKPI.task_type_pretty ? postKPI.task_type_pretty : '',
    task_duration: postKPI.task_duration ? postKPI.task_duration : '',
    task_end_time: postKPI.task_end_time
      ? setDateFormatTimeStamp(postKPI.task_end_time)
      : '',
    refreshed_items:
      postKPI.stats && postKPI.stats.refreshed_items
        ? postKPI.stats.refreshed_items
        : 0,
    crawled_items:
      postKPI.stats && postKPI.stats.crawled_items
        ? postKPI.stats.crawled_items
        : 0,
    updated_items:
      postKPI.stats && postKPI.stats.updated_items
        ? postKPI.stats.updated_items
        : 0,
    inserted_items:
      postKPI.stats && postKPI.stats.inserted_items
        ? postKPI.stats.inserted_items
        : 0
  };

  return item;
};
