import { combineReducers } from "redux";
import moderationReducer from "./moderationReducer";
import streamReducer from "./streamReducer";
import explorerReducer from "./explorerReducer";
import replyReducer from "./replyReducer";
import copyrightReducer from "./copyrightReducer";
import statisticsReducer from "./statisticsReducer";
import meReducer from "./meReducer";
import usersReducer from "./usersReducer";
import alertsReducer from "./alertsReducer";
import alertStatsReducer from "./alertStatsReducer";
import rolesAndPermissionsReducer from "./configuration/rolesAndPermissionsReducer";
import tagsReducer from "./configuration/tagsReducer";
import clientsReducer from "./configuration/clientsReducer";
import usersConfigurationReducer from "./configuration/usersConfigurationReducer";
import actionsReducer from "./configuration/actionsReducer";
import platformsReducer from "./configuration/platformsReducer";
import assetsReducer from "./configuration/assetsReducer";
import claraReducer from "./configuration/claraReducer";
import classifiersReducer from "./configuration/classifiersReducer";
import streamsConfigurationReducer from "./configuration/streamsConfigurationReducer";
import repliesConfigurationReducer from "./configuration/repliesReducer";
import tagsExampleReducer from "./configuration/tagsExampleReducer";
import groupsReducer from "./configuration/groupsReducer";
import taskHistoryReducer from "./configuration/taskHistoryReducer";
import channelWhitelistReducer from "./configuration/channelWhitelistReducer";
import BMMReducer from "./configuration/bmmReducer";
import UCMReducer from "./configuration/ucmReducer";
import adbastionReportsReducer from "./configuration/adbastionReportsReducer";
import assetManagamentReducer from "./configuration/assetManagamentReducer";
import industriesReducer from "./industriesReducer";
import plansReducer from "./plansReducer";
import salesReducer from "./salesReducer";
import attemptsReducer from "./attemptsReducer";
import postKPIsReducer from "./taskKPIs/postKPIsReducer";
import commentKPIsReducer from "./taskKPIs/commentKPIsReducer";
import linkedAccountsReducer from "./taskKPIs/linkedAccountsReducer";
import trendReducer from "./configuration/trendsReducer";
import stickyFiltersReducer from "./configuration/stickyFiltersReducer";
import guidelineGroupReducer from "./configuration/guidelineGroupReducer";
import moderationGuidelinesReducer from "./configuration/moderationGuidelinesReducer";
import featureFlagsReducer from "./featureFlagsReducer";

export default combineReducers({
  meReducer,
  usersReducer,
  alertsReducer,
  alertStatsReducer,
  moderationReducer,
  statisticsReducer,
  streamReducer,
  explorerReducer,
  replyReducer,
  copyrightReducer,
  rolesAndPermissionsReducer,
  usersConfigurationReducer,
  tagsReducer,
  classifiersReducer,
  clientsReducer,
  actionsReducer,
  platformsReducer,
  assetsReducer,
  claraReducer,
  streamsConfigurationReducer,
  tagsExampleReducer,
  groupsReducer,
  taskHistoryReducer,
  channelWhitelistReducer,
  BMMReducer,
  UCMReducer,
  adbastionReportsReducer,
  assetManagamentReducer,
  repliesConfigurationReducer,
  industriesReducer,
  plansReducer,
  salesReducer,
  attemptsReducer,
  postKPIsReducer,
  commentKPIsReducer,
  linkedAccountsReducer,
  trendReducer,
  stickyFiltersReducer,
  guidelineGroupReducer,
  moderationGuidelinesReducer,
  featureFlagsReducer,
});
