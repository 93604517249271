export const explorerConstants = {
  FETCH_CLIENTS: "FETCH_CLIENTS",
  GET_COMMENT_LIST_BY_CLIENT: "GET_COMMENT_LIST_BY_CLIENT",
  RESET_COMMENT_LIST: "RESET_COMMENT_LIST",
  FETCH_HISTORY_FROM_COMMENT: "FETCH_HISTORY_FROM_COMMENT",
  UPDATE_CLIENTS_COMMENTS_TRANSLATION: "UPDATE_CLIENTS_COMMENTS_TRANSLATION",
  FETCH_EXPORT_FIELDS: "FETCH_EXPORT_FIELDS",
  ON_CHANGE_EXPORT_DATA: "ON_CHANGE_EXPORT_DATA",
  GENERATE_EXPORT_FILE: "GENERATE_EXPORT_FILE",
  FETCH_CLIENTS_POST_ADS_EXPLORER: "FETCH_CLIENTS_POST_ADS_EXPLORER",
  EXPLORER_SEND_ANALYZE: "EXPLORER_SEND_ANALYZE",
  FETCH_ANALYZE_TEMPLATES: "FETCH_ANALYZE_TEMPLATES",
  PUSH_TO_STREAM: "PUSH_TO_STREAM",
  GET_AUTOCOMPLETE: "GET_AUTOCOMPLETE",
};
