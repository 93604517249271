import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  contentDetails: {
    padding: theme.spacing(0, 0, 1, 0)
  },
  rootSummary: {
    minHeight: '40px !important'
  },
  contentSummary: {
    justifyContent: 'space-between'
  },
  contentSummaryNoShadow: {
    backgroundColor: theme.palette.container.light
  },
  expensionRoot: {},
  noShadow: {
    boxShadow: 'none'
  }
});

function Panel(props) {
  const { classes, title, children, expanded, noShadow, seconHeader } = props;
  return (
    <div className={classes.root}>
      <ExpansionPanel
        classes={{
          root: classNames(classes.expensionRoot, {
            [classes.noShadow]: noShadow
          })
        }}
        defaultExpanded={expanded}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            root: classNames(classes.rootSummary, {
              [classes.contentSummaryNoShadow]: noShadow
            }),
            expanded: classes.expandedSummary,
            content: classes.contentSummary
          }}
        >
          <Typography className={classes.heading}>{title || ''}</Typography>
          {seconHeader ? <div>{seconHeader}</div> : ''}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: classes.contentDetails
          }}
        >
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

Panel.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  expanded: PropTypes.bool,
  noShadow: PropTypes.bool,
  children: PropTypes.element,
  seconHeader: PropTypes.element
};

export default withStyles(styles)(Panel);
