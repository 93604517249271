import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBox from '@material-ui/core/Checkbox';

import DatePicker from '../commons/picker/dateTimePickerComponent';
import SelectComponent from '../commons/select/selectComponent';
import InputComponent from '../commons/input/inputComponent';

import { infringementItems, categoryItems } from '../../data/data';

const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    marginRight: 20
  },
  formControlContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  formControlContent: {
    marginRight: 20
  },
  container: {
    display: 'flex',
    position: 'relative',
    margin: 3
  },
  containerDivider: {
    display: 'flex',
    marginTop: 20,
    marginBottom: 20,
    width: '100%'
  },
  inputWidth: {
    width: 220
  },
  divider: {
    width: '100%'
  },
  infoIcon: {
    fontSize: 13,
    position: 'absolute',
    zIndex: 3000,
    marginLeft: -15
  }
}));

const CopyrightSearch = ({
  getFilterData,
  defaultValues,
  clients,
  hide,
  reportList
}) => {
  const classes = useStyles();
  const [defaultValueDatePicker, setdefautValueDatePicker] = useState(false);
  const [clientList, setClientList] = useState(clients);
  const [clientSelected, setClientSelected] = useState(
    defaultValues ? defaultValues.client_short_name : ''
  );

  const [data, setData] = useState({
    copyrightStatus:
      defaultValues &&
      defaultValues.copyright_status &&
      defaultValues.copyright_status.length
        ? defaultValues.copyright_status.split(',')
        : [],
    copyrightCategories:
      defaultValues &&
      defaultValues.copyright_categories &&
      defaultValues.copyright_categories.length
        ? defaultValues.copyright_categories.split(',')
        : [],
    copyrightInfringments:
      defaultValues &&
      defaultValues.copyright_infringments &&
      defaultValues.copyright_infringments.length
        ? defaultValues.copyright_infringments.split(',')
        : [],
    hasReport: defaultValues ? defaultValues.has_report : '',
    copyrightKeyword: defaultValues ? defaultValues.copyright_keyword : '',
    commentIds: defaultValues ? defaultValues.comment_ids : '',
    report: defaultValues ? defaultValues.report : '',
    startTime: defaultValues ? defaultValues.start : '',
    endTime: defaultValues ? defaultValues.end : '',
    clientShortName: defaultValues ? defaultValues.client_short_name : '',
    sort: defaultValues ? defaultValues.sort : false,
    copyrightLegacyVideos: defaultValues
      ? defaultValues.copyright_legacy_videos
      : false
  });

  useEffect(() => {
    if (clients) {
      return setClientList(clients);
    }
  }, [clients]);

  const handleChange = (event, id) => {
    if (id === 'clientShortName') {
      clearFilters();
      setClientSelected(event.target.value);
    }

    setData({
      ...data,
      [id]:
        event.currentTarget.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  };

  const search = () => {
    getFilterData(data);
  };

  const setSelectedDate = (name, date) => {
    setData({
      ...data,
      [name]: date
    });
    setdefautValueDatePicker(false);
  };

  const clearFilters = () => {
    setdefautValueDatePicker(false);
    setData({
      ...data,
      copyrightStatus: ['new'],
      commentIds: '',
      report: '',
      sort: false,
      copyrightLegacyVideos: false,
      copyrightKeyword: '',
      copyrightInfringments: [],
      copyrightCategories: [],
      startTime: '',
      endTime: ''
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.formControlContainer}>
        <div className={classes.container}>
          <SelectComponent
            id={'clientShortName'}
            label={'Client name'}
            multiple={false}
            value={data['clientShortName']}
            items={clientList}
            attributName={'name'}
            attributId={'clientShortName'}
            onChange={(e, id) => handleChange(e, id)}
          />
        </div>
        <div className={classes.containerDivider}>
          <Divider className={classes.divider} component="div" />
        </div>
        {clientSelected ? (
          <div className={classes.container}>
            <FormControlLabel
              control={
                <CheckBox
                  checked={void 0 !== data['sort'] ? data['sort'] : false}
                  onChange={e => handleChange(e, 'sort')}
                  color="primary"
                />
              }
              label="Sort By Created Time"
            />
            <FormControlLabel
              control={
                <CheckBox
                  checked={
                    void 0 !== data['copyrightLegacyVideos']
                      ? data['copyrightLegacyVideos']
                      : false
                  }
                  onChange={e => handleChange(e, 'copyrightLegacyVideos')}
                  color="primary"
                />
              }
              label="Show Legacy Videos"
            />
          </div>
        ) : (
          ''
        )}
        <div className={classes.containerDivider}>
          <Divider className={classes.divider} component="div" />
        </div>
        {clientSelected ? (
          <div className={classes.container}>
            <SelectComponent
              id={'copyrightStatus'}
              label={'Copyright status'}
              multiple={true}
              displayNone={true}
              display={2}
              value={data['copyrightStatus']}
              items={['new', 'pending', 'approved', 'removed', 'locked']}
              onChange={(e, id) => handleChange(e, id)}
            />
            <SelectComponent
              id={'hasReport'}
              label={'Has a Report'}
              displayNone={true}
              attributId={'id'}
              attributName={'name'}
              value={data['hasReport']}
              items={[
                {
                  id: 'included',
                  name: 'Included in a report'
                },
                {
                  id: 'not_included',
                  name: 'Not included in a report'
                }
              ]}
              onChange={(e, id) => handleChange(e, id)}
            />
          </div>
        ) : (
          ''
        )}
        {clientSelected ? (
          <div className={classes.container}>
            <InputComponent
              id={'commentIds'}
              label={'Video IDs'}
              value={data['commentIds']}
              onChange={(e, id) => handleChange(e, id)}
            />
            <InputComponent
              id={'copyrightKeyword'}
              label={'Keyword'}
              value={data['copyrightKeyword']}
              onChange={(e, id) => handleChange(e, id)}
            />
          </div>
        ) : (
          ''
        )}
        {clientSelected ? (
          <div className={classes.container}>
            <SelectComponent
              id={'copyrightCategories'}
              label={'Categories'}
              attributId={'value'}
              multiple={true}
              attributName={'label'}
              value={data['copyrightCategories']}
              items={categoryItems}
              onChange={(e, id) => handleChange(e, id)}
            />
            <SelectComponent
              id={'copyrightInfringments'}
              label={'Infringments'}
              attributId={'value'}
              multiple={true}
              attributName={'label'}
              value={data['copyrightInfringments']}
              items={infringementItems}
              onChange={(e, id) => handleChange(e, id)}
            />
          </div>
        ) : (
          ''
        )}
        {clientSelected ? (
          <div className={classes.container}>
            <SelectComponent
              id={'report'}
              label={'Reports'}
              displayNone={true}
              attributId={'id'}
              attributName={'label'}
              value={data['report']}
              items={reportList}
              onChange={(e, id) => handleChange(e, id)}
            />
          </div>
        ) : (
          ''
        )}
        {clientSelected ? (
          <div className={classes.container}>
            <DatePicker
              settedValue={defaultValueDatePicker}
              name={'startTime'}
              label={'Video created start'}
              value={data['startTime']}
              setSelectedDate={(e, name) => setSelectedDate(e, name)}
            />
          </div>
        ) : (
          ''
        )}
        {clientSelected ? (
          <div className={classes.container}>
            <DatePicker
              settedValue={defaultValueDatePicker}
              name={'endTime'}
              label={'Video created end'}
              value={data['endTime']}
              setSelectedDate={(e, name) => setSelectedDate(e, name)}
            />
          </div>
        ) : (
          ''
        )}
        <div className={classes.container}>
          <Button
            variant="contained"
            color="primary"
            className={classNames(classes.button)}
            onClick={() => search()}
          >
            Search
          </Button>
          <Button
            variant="contained"
            className={classNames(classes.button)}
            onClick={() => clearFilters()}
          >
            Clear Filters
          </Button>
          {typeof hide === 'function' ? (
            <Button
              variant="contained"
              className={classNames(classes.button)}
              onClick={() => hide()}
            >
              Close
            </Button>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

CopyrightSearch.propTypes = {
  defaultValues: PropTypes.object,
  getFilterData: PropTypes.func,
  clients: PropTypes.array,
  userList: PropTypes.array,
  hide: PropTypes.func
};

export default CopyrightSearch;
