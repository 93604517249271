import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { styles } from "./styles";

import { operationalTags } from "../../data/data";

import AppBar from "../../components/commons/appBar/AppBarComponent";
import AlertCancelDialog from "../../components/alerts/AlertCancelDialog";
import AlertDialog from "../../components/commons/dialog/alertDialogComponent";
import LeftMenu from "../../components/commons/leftMenu/leftMenuComponent";
import CommentList from "../../components/commentList/CommentListComponent";
import PostRelated from "../../components/postRelated/postRelatedComponent";
import NotificationMenu from "../../components/commons/notificationMenu/notificationMenuComponent";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  getStats,
  issueAlert,
  cancelAlert,
  getClientAlerts,
  fetchHistoryFromComment,
} from "../../actions/alertStatsAction";

const mapStateToProps = (state) => ({
  alertStats: state.alertStatsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getStats: () => dispatch(getStats()),
  getAlerts: (clientShortName, cursor) =>
    dispatch(getClientAlerts(clientShortName, cursor)),
  issueAlert: (clientShortName, commentId, selectedCommentIndex) =>
    dispatch(issueAlert(clientShortName, commentId, selectedCommentIndex)),
  cancelAlert: (clientShortName, commentId, reason, selectedCommentIndex) =>
    dispatch(
      cancelAlert(clientShortName, commentId, reason, selectedCommentIndex)
    ),
  fetchHistoryFromComment: (
    clientShortName,
    commentId,
    selectedCommentIndex,
    options
  ) =>
    dispatch(
      fetchHistoryFromComment(
        clientShortName,
        commentId,
        selectedCommentIndex,
        options
      )
    ),
});
class AlertsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cancelReason: "",
      clientIndex: "0",
      isCancelDialogOpen: false,
      isIssueDialogOpen: false,
      selectedCommentIndex: "0",
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.getStats();
  }

  handleDrawerOpen = () => this.setState({ open: true });
  handleDrawerClose = () => this.setState({ open: false });

  loadMore = (callback) => {
    const { alertStats } = this.props;
    const { clientIndex } = this.state;
    const client = alertStats.clients[clientIndex];

    if (client) {
      this.props
        .getAlerts(client.clientShortName, alertStats.alerts.cursor)
        .then(({ payload }) => callback(payload.items && payload.items.length));
    }
  };

  setSelectedComment = (index) => {
    this.setState({ selectedCommentIndex: index });
  };

  updateTranslation = () => {};
  onChangeIndexMenu = () => {};
  clickItemFromPost = () => {};

  clickItemFromComment = (actionType, data = {}, callback) => {
    const { selectedCommentIndex } = this.state;
    const { alertStats, fetchHistoryFromComment } = this.props;
    const selectedComment = alertStats.alerts.items[selectedCommentIndex];

    if (actionType === "history") {
      fetchHistoryFromComment(
        selectedComment.client.shortName,
        selectedComment.id,
        selectedCommentIndex,
        {
          index: selectedCommentIndex,
        }
      );
    }

    callback && callback();
  };

  onIssueAlertClick = (value) => {
    const { selectedCommentIndex } = this.state;
    const { alertStats, issueAlert, getStats } = this.props;
    const selectedComment = alertStats.alerts.items[selectedCommentIndex];

    if (selectedComment && value) {
      issueAlert(
        selectedComment.client.shortName,
        selectedComment.objectId,
        selectedCommentIndex
      ).then(() => setTimeout(getStats, 1000));
    }

    this.setState({ isIssueDialogOpen: false });
  };

  onCancelAlertConfirm = (reason) => {
    const { selectedCommentIndex } = this.state;
    const { alertStats, cancelAlert, getStats } = this.props;
    const selectedComment = alertStats.alerts.items[selectedCommentIndex];

    if (selectedComment) {
      cancelAlert(
        selectedComment.client.shortName,
        selectedComment.objectId,
        reason,
        selectedCommentIndex
      ).then(() => {
        setTimeout(getStats, 1000);
        this.setState({ isCancelDialogOpen: false });
      });
    }
  };

  clientSelected = (clientId) => {
    const { clients } = this.props.alertStats;
    const clientIndex = (clients || []).findIndex(({ id }) => id === clientId);

    if (clientIndex > -1) {
      this.setState({
        loading: true,
        clientIndex: clientIndex.toString(),
      });

      this.props
        .getAlerts(clients[clientIndex].clientShortName)
        .then(() => this.setState({ loading: false }))
        .catch(() => this.setState({ loading: false }));
    }
  };

  render() {
    const { classes, signOut, alertStats } = this.props;
    const {
      open,
      loading,
      notAllowed,
      clientIndex,
      fetchComment,
      isCancelDialogOpen,
      isIssueDialogOpen,
      selectedCommentIndex,
    } = this.state;
    const selectedPost =
      (alertStats.alerts.items &&
        alertStats.alerts.items[selectedCommentIndex] &&
        alertStats.alerts.items[selectedCommentIndex]["post"]) ||
      null;

    return (
      <div className={classes.root}>
        <AppBar
          status={open}
          logout={signOut}
          selected="alerts"
          action={this.handleDrawerOpen}
        />
        <LeftMenu open={open} onClose={this.handleDrawerClose} />
        {alertStats.clients.length ? (
          <NotificationMenu
            title="Clients"
            index={clientIndex}
            loaded={fetchComment}
            hasGroupedItems={false}
            items={alertStats.clients}
            selected={this.clientSelected}
          />
        ) : null}

        <main className={classes.content}>
          <div className={classes.toolbar} />
          {!notAllowed && !loading ? (
            <Grid container spacing={2}>
              <Grid item xl={8} lg={7} sm={6} xs={7}>
                <Grid item xs={12}>
                  <CommentList
                    mode="alert"
                    status="readOnly"
                    disableKeyboard={false}
                    loadMore={this.loadMore}
                    actionsMenu={operationalTags}
                    cursor={alertStats.alerts.cursor}
                    list={alertStats.alerts.items || []}
                    getIndexList={this.setSelectedComment}
                    containerClassName={classes.heightList}
                    updateTranslation={this.updateTranslation}
                    clickItemFromComment={this.clickItemFromComment}
                  />
                </Grid>
                {selectedPost && (
                  <Grid item className={classes.alertActions}>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      onClick={() => this.setState({ isIssueDialogOpen: true })}
                    >
                      Issue Alert
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      className={classes.cancelBtn}
                      onClick={() =>
                        this.setState({ isCancelDialogOpen: true })
                      }
                    >
                      Cancel Alert
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                xl={4}
                lg={5}
                sm={6}
                xs={5}
                className={classes.rightSide}
              >
                {selectedPost && (
                  <div className={classes.postRelatedContainer}>
                    <PostRelated
                      statistics={{}}
                      post={selectedPost}
                      clickItemFromPost={this.clickItemFromPost}
                      onChangeIndexMenu={this.onChangeIndexMenu}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          ) : !notAllowed ? (
            <CircularProgress className={classes.loading} />
          ) : (
            <Typography className={classes.notAllowed} variant="body1">
              Not Allowed
            </Typography>
          )}
          <AlertCancelDialog
            isOpen={isCancelDialogOpen}
            onConfirm={this.onCancelAlertConfirm}
            onClose={() => this.setState({ isCancelDialogOpen: false })}
          />
          <AlertDialog
            title="Issue Alert?"
            message="Are you sure you want issue this alert?"
            buttonCancelText="Cancel"
            buttonAcceptText="Accept"
            open={isIssueDialogOpen}
            action={this.onIssueAlertClick}
          />
        </main>
      </div>
    );
  }
}

AlertsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AlertsPage));
