import React, { useState } from "react";
import PropTypes from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";

import DialogContent from "../../commons/dialog/contentDialogComponent";
import TableComponent from "../../commons/table/tableComponent";
import SelectComponent from "../../commons/select/selectComponent";
import UCMForm from "./ucmForm";
import AlertDialog from "../../commons/dialog/alertDialogComponent";

import { validateEmail } from "../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
    "& .MuiInput-root": {
      marginTop: "0 !important",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 7,
  },
  buttonAdd: {
    marginLeft: theme.spacing(2),
  },
  detailContainer: {
    display: "flex",
  },
  detailItem: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.container.light,
  },
  detailLegend: {
    padding: theme.spacing(1),
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
  },
  detailContent: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    overflow: "auto",
  },
  detailCell: {
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const UCMConfiguration = (props) => {
  const classes = useStyles();

  // Init
  const {
    UCMList,
    UCMClientList,
    UCMRoles,
    isUpdatable,
    isCreatable,
    isDisable,
    isPasswordResetable,
  } = props;

  const initialFormData = {
    clientShortName: "",
    availableClients: [],
    email: "",
    firstName: "",
    lastName: "",
    roles: [],
  };

  // Hooks
  const [isFormOpen, setFormOpen] = useState(false);
  const [isDialogResetPasswordOpen, setDialogResetPasswordOpen] = useState(
    false
  );
  const [userToResetPassword, setUserToResetPassword] = useState(null);
  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});

  // Table schema
  const tableColumns = [
    {
      title: "Email",
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14,
      },
      field: "email",
      editComponent: (props) => <p>{props.value}</p>,
    },
    {
      title: "First Name",
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14,
      },
      field: "firstName",
      editComponent: (props) => <p>{props.value}</p>,
    },
    {
      title: "Last Name",
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14,
      },
      field: "lastName",
      editComponent: (props) => <p>{props.value}</p>,
    },
    {
      title: "Client Short Name",
      cellStyle: {
        padding: 2,
        paddingLeft: 14,
      },
      field: "clientShortName",
      editComponent: (fieldProps) => (
        <Input
          value={fieldProps.value}
          onChange={(e) => fieldProps.onChange(e.target.value)}
        />
      ),
    },
    {
      title: "Roles",
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14,
      },
      field: "roles",
      render: (rowData) => (
        <p>
          {rowData.roles
            ? rowData.roles
                .map((i) => UCMRoles.find((e) => e.id === i))
                .map((w) => (w ? w.name : ""))
                .join(", ")
            : ""}
        </p>
      ),
      editComponent: (fieldProps) => {
        return (
          <Select
            multiple={true}
            value={fieldProps.value}
            onChange={(e) => fieldProps.onChange(e.target.value)}
            renderValue={(selected) => {
              return selected
                .map((i) => {
                  return UCMRoles.find((e) => e.id === i);
                })
                .map((w) => (w ? w.name : ""))
                .join(", ");
            }}
          >
            {UCMRoles.map((role) => {
              return (
                <MenuItem
                  key={`${role.id}-${fieldProps.rowData.id}`}
                  value={role.id}
                >
                  {role.name}
                </MenuItem>
              );
            })}
          </Select>
        );
      },
    },
    {
      title: "Available Clients",
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14,
      },
      field: "availableClients",
      render: (rowData) => {
        return (
          <p>
            {rowData.availableClients
              ? rowData.availableClients.join(", ")
              : ""}
          </p>
        );
      },
      editComponent: (fieldProps) => {
        return (
          <SelectComponent
            multiple={true}
            displaySearch={true}
            value={fieldProps.value}
            items={UCMClientList}
            display={1}
            onChange={(e) => fieldProps.onChange(e.target.value)}
          />
        );
      },
    },
    {
      title: "Status",
      cellStyle: {
        padding: 2,
        paddingLeft: 14,
      },
      field: "status",
      filtering: false,
      render: (rowData) => <p>{rowData.status ? "Enabled" : "Disabled"}</p>,
      editComponent: isDisable
        ? (fieldProps) => {
            return (
              <Switch
                checked={fieldProps.value}
                onChange={(e) => fieldProps.onChange(e.target.checked)}
                color="primary"
                name="status"
              />
            );
          }
        : (props) => <p>{props.value ? "Enabled" : "Disabled"}</p>,
    },
    {
      title: "Created At",
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14,
      },
      field: "created_at",
      editComponent: (props) => <p>{props.value}</p>,
    },
    {
      title: "Initial Login",
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14,
      },
      field: "initial_login_time",
      editComponent: (props) => <p>{props.value}</p>,
    },
    {
      title: "Password Reset",
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14,
      },
      field: "reset_info",
      editComponent: (props) => <p>{props.value}</p>,
    },
    {
      title: "Login After Reset",
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14,
      },
      field: "reset_login_time",
      editComponent: (props) => <p>{props.value}</p>,
    },
  ];

  const onChangeFormData = (e, name, type = null) => {
    setFormData({
      ...formData,
      [name]:
        type === "checkbox" ? e.target.checked : e.target ? e.target.value : e,
    });
  };

  const onAddUserClient = (e) => {
    setFormOpen(true);
  };

  const onCloseDialogForm = () => {
    setFormOpen(false);
  };

  const addUerClient = (e) => {
    checkForErrors().then((formErrors) => {
      if (Object.keys(formErrors).length === 0) {
        props.addUserClient(formData);
        onCloseDialogForm();
        setFormData(initialFormData);
      } else {
        setFormErrors(formErrors);
      }
    });
  };

  const updateUserClient = (index, data) => {
    props.updateUserClient(index, data);
  };

  const checkForErrors = () => {
    return new Promise((resolve, reject) => {
      const formErrors = {};
      for (let field in formData) {
        let errorMessage = null;
        switch (field) {
          case "email":
            if (formData[field] === "") {
              errorMessage = "email is mandatory";
            } else if (!validateEmail(formData[field])) {
              errorMessage = "email is not valid";
            }
            break;
          case "firstName":
            if (formData[field] === "") {
              errorMessage = "Firstname is mandatory";
            }
            break;
          case "lastName":
            if (formData[field] === "") {
              errorMessage = "Lastname is mandatory";
            }
            break;
          case "clientShortName":
            if (formData[field] === "") {
              errorMessage = "Client Short Name is mandatory";
            }
            break;
          case "availableClients":
            if (formData[field] === "") {
              errorMessage = "Available Clients is mandatory";
            }
            break;
          default:
            break;
        }
        if (errorMessage) {
          formErrors[field] = errorMessage;
        }
      }

      resolve(formErrors);
    });
  };

  const onClickResetPassword = (rowData) => {
    setUserToResetPassword(rowData);
    setDialogResetPasswordOpen(true);
  };

  const actionDialogResetPassword = (status) => {
    if (status) {
      props.resetUserPassword(userToResetPassword);
    }
    setDialogResetPasswordOpen(false);
  };

  const actions = [];

  if (isPasswordResetable) {
    actions.push({
      icon: "vpn_key",
      tooltip: "Reset user password",
      onClick: (event, rowData) => {
        onClickResetPassword(rowData);
      },
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h5">User Client Management</Typography>
        {isCreatable && (
          <Button
            variant="contained"
            className={classes.buttonAdd}
            size="small"
            color="primary"
            onClick={(e) => {
              return onAddUserClient(e);
            }}
          >
            Add User
          </Button>
        )}
      </div>
      <TableComponent
        editable={isUpdatable}
        filter={true}
        columns={tableColumns}
        data={Object.assign([], UCMList)}
        title=""
        actions={actions}
        options={{
          pageSizeOptions: [5, 10, 20, 50, 100],
          filtering: true,
        }}
        update={(index, data) => updateUserClient(index, data)}
      />
      <DialogContent
        title={"Add User"}
        open={isFormOpen}
        fullWidth={true}
        close={onCloseDialogForm}
      >
        <div>
          <UCMForm
            formData={formData}
            errors={formErrors}
            UCMRoles={UCMRoles}
            onChangeFormData={onChangeFormData}
          />
          <div className={classes.buttonContainer}>
            <Button
              size="small"
              color="primary"
              onClick={(e) => {
                return addUerClient(e);
              }}
            >
              Add User
            </Button>
            <Button
              onClick={(e) => {
                return onCloseDialogForm(e);
              }}
              size="small"
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
      <AlertDialog
        title="Reset User Password?"
        message={`Are you sure you want to reset password of user '${
          userToResetPassword === null ? "" : userToResetPassword.email
        }' ?`}
        buttonCancelText="Cancel"
        buttonAcceptText="Accept"
        open={isDialogResetPasswordOpen}
        action={actionDialogResetPassword}
      />
    </div>
  );
};

UCMConfiguration.propTypes = {
  UCMList: PropTypes.array.isRequired,
  UCMRoles: PropTypes.array.isRequired,
  isCreatable: PropTypes.bool.isRequired,
  isUpdatable: PropTypes.bool.isRequired,
  isDisable: PropTypes.bool.isRequired,
  isPasswordResetable: PropTypes.bool.isRequired,
  resetUserPassword: PropTypes.func.isRequired,
  addUerClient: PropTypes.func,
  updateUserClient: PropTypes.func,
};

export default UCMConfiguration;
