import { trendsConstants } from '../../constants/configuration/trendsConstants';
const initialState = {
  fetching: false,
  fetched: false,
  trendList: [],
  error: null
};

const trendsReducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_GLOBAL_TREND_LIST
    case `${trendsConstants.FETCH_GLOBAL_TREND_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${trendsConstants.FETCH_GLOBAL_TREND_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${trendsConstants.FETCH_GLOBAL_TREND_LIST}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        trendList: action.payload.data.items
      };
    // CREATE_GLOBAL_TREND
    case `${trendsConstants.CREATE_GLOBAL_TREND}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${trendsConstants.CREATE_GLOBAL_TREND}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${trendsConstants.CREATE_GLOBAL_TREND}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        trendList: [
          ...state.trendList,
          {
            ...action.payload.config.trend,
            id: action.payload.data.id
          }
        ]
      };
    // UPDATE_GLOBAL_TREND
    case `${trendsConstants.UPDATE_GLOBAL_TREND}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${trendsConstants.UPDATE_GLOBAL_TREND}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${trendsConstants.UPDATE_GLOBAL_TREND}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        trendList: Object.assign([], {
          ...state.trendList,
          [action.payload.config.index]: action.payload.config.trend
        })
      };
    // DELETE_GLOBAL_TREND
    case `${trendsConstants.DELETE_GLOBAL_TREND}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${trendsConstants.DELETE_GLOBAL_TREND}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${trendsConstants.DELETE_GLOBAL_TREND}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        trendList: state.trendList.filter(
          trend => trend.id !== action.payload.config.id
        )
      };
    default:
      return state;
  }
};

export default trendsReducer;
