import { bmmConstants } from '../../constants/configuration/bmmConstants';

import { extractBMMList } from '../../extractors/bmmExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  BMMList: [],
  error: null
};

const bmmReducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_BMM_LIST
    case `${bmmConstants.FETCH_BMM_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${bmmConstants.FETCH_BMM_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${bmmConstants.FETCH_BMM_LIST}_FULFILLED`:
      const BMMList = extractBMMList(action.payload.data);
      return {
        ...state,
        fetching: false,
        fetched: true,
        BMMList
      };
    default:
      return state;
  }
};

export default bmmReducer;
