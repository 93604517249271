import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { makeStyles, useTheme } from '@material-ui/core/styles';

import TableComponent from '../../commons/table/tableComponent';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  }
}));

const CommentKPIsConfiguration = props => {
  const classes = useStyles();
  const theme = useTheme();

  // Init
  const { commentKPIsList } = props;

  // Table schema
  const tableColumns = [
    {
      title: 'Task ID',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'task_id'
    },
    {
      title: 'Task Type',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'task_type_pretty'
    },
    {
      title: 'Max Total Comments',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'max_total_comments'
    },
    {
      title: 'Max Refreshed Items',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'max_refreshed_items'
    },
    {
      title: 'Max Crawled Items',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'max_crawled_items'
    },
    {
      title: 'Sum Inserted Items',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'sum_inserted_items'
    },
    {
      title: 'Average Duration',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'avg_duration'
    }
  ];

  return (
    <div className={classes.root}>
      <TableComponent
        editable={false}
        columns={tableColumns}
        data={commentKPIsList}
        title=""
      />
    </div>
  );
};

CommentKPIsConfiguration.propTypes = {
  commentKPIsList: PropTypes.array.isRequired
};

export default CommentKPIsConfiguration;
