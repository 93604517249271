import { claraConstants } from '../../constants/configuration/claraConstants';
import { extractClassifierList } from '../../extractors/classifierExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  classifierClara: [],
  error: null
};

const Clara = (state = initialState, action) => {
  switch (action.type) {
    // FETCH CLASSIFIERS
    case `${claraConstants.FETCH_CLASSIFIERS}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${claraConstants.FETCH_CLASSIFIERS}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${claraConstants.FETCH_CLASSIFIERS}_FULFILLED`:
      let classifierClara = extractClassifierList(action.payload.data);
      return {
        ...state,
        fetching: false,
        fetched: true,
        classifierClara
      };
    // UPDATE CLASSIFIER RULES
    case `${claraConstants.UPDATE_CLASSIFIER_RULES}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${claraConstants.UPDATE_CLASSIFIER_RULES}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${claraConstants.UPDATE_CLASSIFIER_RULES}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        classifierClara: Object.assign([], {
          ...state.classifierClara,
          [action.payload.config.index]: {
            ...state.classifierClara[action.payload.config.index],
            rules: action.payload.data.rules,
            version: action.payload.data.version
          }
        })
      };
    // UPDATE STATUS CLASSIFIER
    case `${claraConstants.UPDATE_STATUS_CLASSIFIER}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${claraConstants.UPDATE_STATUS_CLASSIFIER}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${claraConstants.UPDATE_STATUS_CLASSIFIER}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        classifierClara: Object.assign([], {
          ...state.classifierClara,
          [action.payload.config.index]: {
            ...state.classifierClara[action.payload.config.index],
            status: action.payload.data.clara_only ? 0 : 1
          }
        })
      };

    //ADD CLASSIFIER LIST ITEM
    case `${claraConstants.ADD_CLASSIFIER_LIST_ITEM}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${claraConstants.ADD_CLASSIFIER_LIST_ITEM}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${claraConstants.ADD_CLASSIFIER_LIST_ITEM}_FULFILLED`:
      let newClassifier = {
        ...action.payload.data,
        clara_only: true
      };
      newClassifier = extractClassifierList([newClassifier])[0];
      newClassifier.id = state.classifierClara.length;
      return {
        ...state,
        fetching: false,
        fetched: true,
        classifierClara: [...state.classifierClara, newClassifier]
      };

    //DELETE_CLASSIFIER_LIST_ITEM
    case `${claraConstants.DELETE_CLASSIFIER_LIST_ITEM}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${claraConstants.DELETE_CLASSIFIER_LIST_ITEM}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${claraConstants.DELETE_CLASSIFIER_LIST_ITEM}_FULFILLED`:
      let updatedClassifierClara = state.classifierClara.filter(
        classifier => classifier.id !== action.payload.config.id
      );
      updatedClassifierClara = updatedClassifierClara.map(classifier => {
        classifier.clara_only = classifier.status === 1 ? false : true;
        return classifier;
      });
      updatedClassifierClara = extractClassifierList(updatedClassifierClara);
      return {
        ...state,
        fetching: false,
        fetched: true,
        classifierClara: updatedClassifierClara
      };

    // RESET CLASSIFIERS
    case `${claraConstants.RESET_CLASSIFIERS}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${claraConstants.RESET_CLASSIFIERS}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${claraConstants.RESET_CLASSIFIERS}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetchComment: false,
        classifierClara: []
      };
    default:
      return state;
  }
};

export default Clara;
