import { streamConstants } from '../constants/streamConstants';

import {
  extractStreams,
  extractStreamsGrouped,
  extractKeyFromStream
} from '../extractors/streamExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  streams: [],
  streamsGrouped: {},
  total: 0,
  cursor: null,
  streamSelected: null,
  error: null
};

const Streams = (state = initialState, action) => {
  switch (action.type) {
    case `${streamConstants.GET_STREAM_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${streamConstants.GET_STREAM_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${streamConstants.GET_STREAM_LIST}_FULFILLED`:
      const streamsList = extractStreams(action.payload.data.items);

      const streamsGroupedList = extractStreamsGrouped(streamsList);

      return {
        ...state,
        fetching: false,
        fetched: true,
        streams: streamsList,
        streamsGrouped: streamsGroupedList,
        total: action.payload.data.total,
        cursor: action.payload.data.cursor
      };
    // ADD FAVORITE TO STREAM
    case `${streamConstants.ADD_FAVORITE_TO_STREAM}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${streamConstants.ADD_FAVORITE_TO_STREAM}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${streamConstants.ADD_FAVORITE_TO_STREAM}_FULFILLED`:
      const key = extractKeyFromStream(
        state.streams,
        action.payload.config.key
      );

      const streamsFav = Object.assign([], {
        ...state.streams,
        [key]: {
          ...state.streams[key],
          favourite: action.payload.config.type === 'favourite' ? true : false
        }
      });

      const streamsGroupedFav = extractStreamsGrouped(streamsFav);

      return {
        ...state,
        fetching: false,
        fetched: true,
        streams: streamsFav,
        streamsGrouped: streamsGroupedFav
      };
    //STREAM_SELECTED
    case `${streamConstants.STREAM_SELECTED}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${streamConstants.STREAM_SELECTED}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${streamConstants.STREAM_SELECTED}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        streamSelected: action.payload.streamId
      };
    //DELETE_STREAM
    case `${streamConstants.DELETE_STREAM}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${streamConstants.DELETE_STREAM}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${streamConstants.DELETE_STREAM}_FULFILLED`:
      const keyDelete = extractKeyFromStream(
        state.streams,
        action.payload.config.streamId
      );

      const streamsDel = Object.assign([], {
        ...state.streams,
        [keyDelete]: {
          ...state.streams[keyDelete],
          deleting: true
        }
      });

      const streamsGroupedDel = extractStreamsGrouped(streamsDel);

      return {
        ...state,
        fetching: false,
        fetched: true,
        streams: streamsDel,
        streamsGrouped: streamsGroupedDel
      };
    // WBSOCKET:UPDATE STREAM LIST
    case `UPDATES_STREAM_LIST`:
      const streamsWs = extractStreams(action.data.items);
      const streamsGroupedWs = extractStreamsGrouped(streamsWs);

      return {
        ...state,
        fetching: false,
        fetched: true,
        streams: streamsWs,
        streamsGrouped: streamsGroupedWs,
        total: action.data.total,
        cursor: action.data.cursor
      };
    default:
      return state;
  }
};

export default Streams;
