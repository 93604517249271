import { repliesConstants } from '../../constants/configuration/repliesConstants';

const initialState = {
  fetching: false,
  fetched: false,
  replyConfig: {},
  replyAccess: {},
  error: null
};

const Replies = (state = initialState, action) => {
  switch (action.type) {
    // FETCH REPLY CONFIG
    case `${repliesConstants.FETCH_REPLY_CONFIG}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${repliesConstants.FETCH_REPLY_CONFIG}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${repliesConstants.FETCH_REPLY_CONFIG}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        replyConfig: action.payload.data
      };

    // SAVE_REPLY_CONFIG
    case `${repliesConstants.SAVE_REPLY_CONFIG}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${repliesConstants.SAVE_REPLY_CONFIG}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${repliesConstants.SAVE_REPLY_CONFIG}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetchComment: true,
        replyConfig: action.payload.data
      };

    // SAVE_REPLY_ACCESS
    case `${repliesConstants.FETCH_REPLY_ACCESS}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${repliesConstants.FETCH_REPLY_ACCESS}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${repliesConstants.FETCH_REPLY_ACCESS}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetchComment: true,
        replyAccess: action.payload.data
      };

    // PUT_REPLY_ACCESS
    case `${repliesConstants.PUT_REPLY_ACCESS}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${repliesConstants.PUT_REPLY_ACCESS}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${repliesConstants.PUT_REPLY_ACCESS}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetchComment: true,
        replyAccess: action.payload.data
      };

    // RESET_REPLY_CONFIG
    case `${repliesConstants.RESET_REPLY_CONFIG}`:
      return {
        ...state,
        fetchComment: false,
        replyConfig: []
      };
    default:
      return state;
  }
};

export default Replies;
