import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import DoneIcon from "@material-ui/icons/Done";

import FullScreenDialog from "../commons/dialog/fullScreenDialogComponemt";
import DialogContent from "../commons/dialog/contentDialogComponent";
import AlertDialog from "../commons/dialog/alertDialogComponent";
import NestedList from "../commons/nestedList/nestedListComponent";
import Textarea from "../commons/textarea/textareaComponent";
import Input from "../commons/input/inputComponent";
import SelectComponent from "../commons/select/selectComponent";
import CustomizedSnackbar from "../commons/snackBar/snackBarComponent";

import { isContainBracket } from "../../utils/utils";

const predefinedReasonsItems = [
  "Response Not Available",
  "Response Ineligible",
  "Language Unavailable",
  "Comment Deleted",
  "Repeated Comments",
  "Brand Responded",
  "Incorrectly Tagged",
  "reason",
];

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  card: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  messageContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  buttonsContainer: {
    width: "100%",
    minHeight: 50,
  },
  messageitemReply: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 100,
    maxHeight: 100,
    margin: theme.spacing(1),
    textAlign: "left",
  },
  messageInformations: {
    minWidth: 250,
    height: 95,
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.container.light,
  },
  messageText: {
    overflow: "hidden",
    flexGrow: 1,
    marginRight: theme.spacing(2),
    padding: 4,
    height: 95,
    backgroundColor: theme.palette.container.light,
  },
  message: {
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(1, 1, 1, 1),
  },
  buttonSelect: {},
  buttonApprove: {
    color: theme.palette.white,
    background: theme.palette.success[500],
    "&:hover, &:focus, &:active": {
      color: theme.palette.white,
      background: theme.palette.success[600],
    },
  },
  buttonCancel: {
    color: theme.palette.white,
    background: theme.palette.secondary[500],
    "&:hover, &:focus, &:active": {
      color: theme.palette.white,
      background: theme.palette.secondary[600],
    },
  },
  buttonRemove: {},
  dialogContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  buttonsDialogContainer: {
    textAlign: "right",
  },
  selectedIcon: {
    backgroundColor: theme.palette.secondary[200],
    padding: 20,
    borderRadius: 40,
    cursor: "pointer",
    width: 80,
    height: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: theme.palette.shadow.shadowDefault,
  },
  selectedDisabled: {
    opacity: 0.5,
  },
  isPending: {
    color: theme.palette.black,
  },
  breadcrumbs: {
    width: "100%",
    textAlign: "left",
    marginLeft: theme.spacing(2),
  },
  linkLang: {
    padding: 4,
    borderRadius: 4,
    minWidth: 50,
    fontWeight: "bold",
  },
  usageWarning: {
    color: theme.palette.warning[800],
    backgroundColor: "rgba(255,235,59, .3)",
    boxShadow: theme.palette.shadow.shadowDefault,
  },
  usageDanger: {
    color: theme.palette.secondary[500],
    backgroundColor: "rgba(224, 102, 102, .3)",
    boxShadow: theme.palette.shadow.shadowDefault,
  },
  usageOk: {
    color: theme.palette.success[600],
    backgroundColor: "rgba(147,196,125, .3)",
    boxShadow: theme.palette.shadow.shadowDefault,
  },
  emptyReplyWarning: {
    display: "inline-block",
    color: "white",
    fontSize: 12,
    backgroundColor: "#D23031",
    borderRadius: 4,
    padding: "2px 6px",
  },
  emptyReplyWarningIcon: {
    fontSize: 16,
    position: "relative",
    top: 2,
    marginRight: 2,
  },
  replyTitle: {
    display: "flex",
    justifyContent: "center",
  },
});

class ReplyArea extends Component {
  constructor(props) {
    super(props);
    const { classes } = props;

    const colorClass =
      this.props.replyConfig["current_percentile_color"] === "green"
        ? classes.usageOk
        : this.props.replyConfig["current_percentile_color"] === "yellow"
        ? classes.usageWarning
        : this.props.replyConfig["current_percentile_color"] === "red"
        ? classes.usageDanger
        : "";

    const breadcrumbsToken =
      this.props.item && this.props.item["reply"] ? (
        <React.Fragment>
          <span>{this.props.item["reply"]["category_name"]}</span>
          &nbsp;&nbsp;&nbsp;
          {this.props.item["reply"]["category_name"] !== "" && (
            <span className={classes.linkLang + " " + colorClass}>
              Used: {this.props.replyConfig["current_reply_count"]}
            </span>
          )}
        </React.Fragment>
      ) : (
        ""
      );

    this.state = {
      permissions: props.permissions || [],
      item: props.item || {},
      textChanged: false,
      openReasonMessageDialog: false,
      openDialogReply: false,
      openCheckReplySendDialog: false,
      messageSnackbar: "",
      openSnackBar: false,
      selectedCount: 0,
      selectedPColor: "green",
      selectedLanguage:
        props.item["reply"] && props.item["reply"]["language"]
          ? props.item["reply"]["language"]
          : "",
      selectedMessageOriginal:
        props.item["reply"] && props.item["reply"]["original_message"]
          ? props.item["reply"]["original_message"]
          : "",
      isPending: false,
      selectedItem: "",
      reason:
        props.item["reply"] && props.item["reply"]["reason"]
          ? props.item["reply"]["reason"]
          : "",
      itemChoosed:
        props.item && props.item["reply"] && props.item["reply"]["message"]
          ? props.item["reply"]["message"].replace(
              "@user",
              props.item["replyConfig"] &&
                props.item["replyConfig"]["reply_username"] !== ""
                ? props.item["replyConfig"]["reply_username"]
                : "@user"
            )
          : "",
      assetToReplyFrom:
        props.item &&
        props.item["replyConfig"] &&
        props.item["replyConfig"]["replier_info"] &&
        props.item["replyConfig"]["replier_info"]["enabled"] === true &&
        props.item["replyConfig"]["replier_info"]["assets"] &&
        props.item["replyConfig"]["replier_info"]["assets"].length
          ? props.item["replyConfig"]["replier_info"]["assets"].find(
              (asset) => !!asset.selected
            ).id
          : undefined,
      status: props.item["reply"]["status"],
      breadcrumbs: breadcrumbsToken,
    };
    this.onClickButton.bind(this);
  }

  closeReasonDialogMessage(e, reason) {
    this.setState({
      isPending: false,
      openReasonMessageDialog: false,
    });
  }

  onClickButton(e, type) {
    if (this.state.isPending) {
      return false;
    }

    this.setState(
      {
        isPending: true,
      },
      () => {
        if (type === "select") {
          this.setState({
            isPending: false,
            textChanged: true,
          });
        }

        if (type === "save") {
          const data = {
            message: this.state.itemChoosed,
            original_message: this.state.selectedMessageOriginal,
            language: this.state.selectedLanguage,
            category_name: this.state.breadcrumbs,
            count: this.state.selectedCount,
            pColor: this.state.selectedPColor,
          };

          this.props.replyActionToComment(data, "select", () => {
            this.setState({
              isPending: false,
              textChanged: false,
            });
          });
        }

        if (type === "approve") {
          this.props.replyActionToComment(
            this.state.itemChoosed,
            type,
            (data) => {
              if (data.message) {
                this.setState({
                  messageSnackbar: data.message,
                  openSnackBar: true,
                });
              }

              this.setState({
                isPending: false,
                isShowReplyCount: false,
              });
            },
            this.state.assetToReplyFrom
          );
        }

        if (
          type === "delete" ||
          type === "delete_failed" ||
          type === "cancel"
        ) {
          this.setState({
            openReasonMessageDialog: true,
            isPending: false,
          });
        }
      }
    );
  }

  handleCloseReply() {
    this.setState(
      {
        openDialogReply: false,
      },
      () => {
        this.props.selectReplyOpen(false);
      }
    );
  }

  handleOpenReply() {
    this.setState(
      {
        openDialogReply: true,
      },
      () => {
        this.props.selectReplyOpen(true);
      }
    );
  }

  handleChange(e, id) {
    this.setState({
      [id]: e.target.value,
      textChanged: true,
    });
  }

  selectedItem(
    selectedMessage,
    language,
    auto = false,
    path,
    count = 0,
    pColor = "green"
  ) {
    const { item } = this.state;

    let message = selectedMessage
      ? selectedMessage.replace(
          "@user",
          item["replyConfig"] && item["replyConfig"]["reply_username"] !== ""
            ? item["replyConfig"]["reply_username"]
            : "@user"
        )
      : "";

    this.setState(
      {
        selectedItem: message,
        selectedLanguage: language,
        selectedMessageOriginal: selectedMessage,
        breadcrumbs: path ? path : "",
        selectedCount: count,
        selectedPColor: pColor,
      },
      () => {
        if (auto) {
          this.actionReply(true);
        }
      }
    );
  }

  actionReasonReply(e, status) {
    this.closeReasonDialogMessage();
    this.props.replyActionToComment(
      this.state.reason,
      status === "new" || status === "pending" ? "cancel" : "delete"
    );
  }

  checkReply() {
    const { selectedLanguage, item } = this.state;

    if (selectedLanguage !== "" && item.languageDetected !== selectedLanguage) {
      this.setState({
        openCheckReplySendDialog: true,
      });
    } else {
      this.actionReply(true);
    }
  }

  handleBlur(e) {
    this.props.selectReplyOpen(false);
  }

  handleFocus(e) {
    this.props.selectReplyOpen(true);
  }

  actionReply(state) {
    if (state) {
      this.setState(
        {
          openCheckReplySendDialog: false,
          openDialogReply: false,
          itemChoosed: this.state.selectedItem,
        },
        () => {
          if (isContainBracket(this.state.selectedItem)) {
            this.onClickButton(null, "select");
          } else {
            this.onClickButton(null, "save");
          }
        }
      );
    } else {
      this.setState({
        openCheckReplySendDialog: false,
        openDialogReply: false,
      });
    }

    this.props.selectReplyOpen(false);
  }

  onCloseSnackBar() {
    this.setState({
      openSnackBar: false,
    });
  }

  displayReasonButtonDialog(status) {
    const { classes } = this.props;
    const { reason } = this.state;
    return (
      <div className={classes.buttonsDialogContainer}>
        <Button
          disabled={reason === "" ? true : false}
          size="small"
          color="primary"
          className={classNames(classes.button, classes.buttonSelect)}
          onClick={(e) => {
            return this.actionReasonReply(e, status);
          }}
        >
          {`${
            status === "new" || status === "pending" ? "Cancel" : "Delete"
          } reply`}
        </Button>
        <Button
          size="small"
          color="secondary"
          className={classes.button}
          onClick={(e) => {
            return this.closeReasonDialogMessage();
          }}
        >
          Cancel
        </Button>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item !== this.props.item) {
      const { classes } = this.props;

      const colorClass =
        this.props.replyConfig["current_percentile_color"] === "green"
          ? classes.usageOk
          : this.props.replyConfig["current_percentile_color"] === "yellow"
          ? classes.usageWarning
          : this.props.replyConfig["current_percentile_color"] === "red"
          ? classes.usageDanger
          : "";

      const reply =
        this.props.item &&
        this.props.item["reply"] &&
        this.props.item["reply"]["message"]
          ? this.props.item["reply"]["message"]
          : "";
      const replyWithUserHandle = reply
        ? reply.replace(
            "@user",
            this.props.item["replyConfig"] &&
              this.props.item["replyConfig"]["reply_username"] !== ""
              ? this.props.item["replyConfig"]["reply_username"]
              : "@user"
          )
        : "";

      const breadcrumbsToken = (
        <React.Fragment>
          <span>{this.props.item["reply"]["category_name"] || ""}</span>
          &nbsp;&nbsp;&nbsp;
          {this.props.item["reply"]["category_name"] !== "" && (
            <span className={classes.linkLang + " " + colorClass}>
              Used: {this.props.replyConfig["current_reply_count"]}
            </span>
          )}
        </React.Fragment>
      );

      this.setState({
        item: this.props.item,
        reason:
          this.props.item["reply"] && this.props.item["reply"]["reason"]
            ? this.props.item["reply"]["reason"]
            : "",
        itemChoosed: replyWithUserHandle,
        status: this.props.item["reply"]["status"],
        breadcrumbs: breadcrumbsToken,
        assetToReplyFrom:
          this.props.item &&
          this.props.item["replyConfig"] &&
          this.props.item["replyConfig"]["replier_info"] &&
          this.props.item["replyConfig"]["replier_info"]["enabled"] === true &&
          this.props.item["replyConfig"]["replier_info"]["assets"] &&
          this.props.item["replyConfig"]["replier_info"]["assets"].length
            ? this.props.item["replyConfig"]["replier_info"]["assets"].find(
                (asset) => !!asset.selected
              ).id
            : undefined,
      });
    }

    if (prevProps.permissions !== this.props.permissions) {
      this.setState({
        permissions: this.props.permissions,
      });
    }
  }

  getFlattenedAssetsOptions = () => {
    return (
      this.props.item["replyConfig"]["replier_info"] &&
      this.props.item["replyConfig"]["replier_info"]["assets"].map((asset) => {
        return {
          id: asset.id,
          name: asset && asset.asset_name ? asset.asset_name.name : "",
          selected: asset.selected,
        };
      })
    );
  };

  render() {
    const { classes } = this.props;
    const {
      openReasonMessageDialog,
      openCheckReplySendDialog,
      openDialogReply,
      permissions,
      isPending,
      status,
      itemChoosed,
      openSnackBar,
      textChanged,
      messageSnackbar,
      reason,
      breadcrumbs,
      item,
      assetToReplyFrom,
    } = this.state;

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <div className={classes.replyTitle}>
            <Typography variant="h6">Reply</Typography>
            {status === "pending" && !!assetToReplyFrom && (
              <SelectComponent
                displaySearch
                attributId="id"
                attributName="name"
                value={assetToReplyFrom}
                items={this.getFlattenedAssetsOptions()}
                display={1}
                onChange={(e) => {
                  this.setState({
                    assetToReplyFrom: e.target.value,
                  });
                }}
              />
            )}
            <Typography variant="h6">
              {item.post_placement === "STORY" ? "to private message" : ""}
            </Typography>
          </div>
          <div className={classes.container}>
            <Breadcrumbs className={classes.breadcrumbs}>
              <Typography color="textPrimary">{breadcrumbs}</Typography>
            </Breadcrumbs>
            <div className={classes.messageContainer}>
              <div className={classes.messageitemReply}>
                <div className={classes.messageText}>
                  <div className={classes.message}>
                    {status === "new" ||
                    status === "pending" ||
                    status === "failed" ||
                    status === "deleted" ||
                    status === "delete_failed" ||
                    status === "cancelled" ? (
                      <Textarea
                        id={"itemChoosed"}
                        label={"Message"}
                        handleBlur={this.handleBlur.bind(this)}
                        handleFocus={this.handleFocus.bind(this)}
                        value={itemChoosed}
                        margin={"dense"}
                        onChange={this.handleChange.bind(this)}
                      />
                    ) : (
                      itemChoosed
                    )}
                  </div>
                </div>
                {permissions.includes("select_comment_reply") && textChanged ? (
                  <div
                    onClick={(e) => {
                      return itemChoosed && !isContainBracket(itemChoosed)
                        ? this.onClickButton(e, "save")
                        : false;
                    }}
                    className={classNames(classes.selectedIcon, {
                      [classes.selectedDisabled]:
                        itemChoosed === "" || isContainBracket(itemChoosed),
                    })}
                  >
                    {isPending ? (
                      <CircularProgress
                        className={classes.isPending}
                        size={18}
                      />
                    ) : (
                      <DoneIcon />
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className={classes.buttonsContainer}>
              {status === "new" ||
              status === "pending" ||
              status === "failed" ||
              status === "deleted" ||
              status === "cancelled" ? (
                permissions.includes("select_comment_reply") &&
                item["replyConfig"] &&
                item["replyConfig"].categories &&
                item["replyConfig"].categories.length ? (
                  <FullScreenDialog
                    buttonTitle={"Select Reply"}
                    title={"Message List"}
                    open={openDialogReply}
                    action={"Close"}
                    hideCloseIcon={true}
                    handleClose={this.handleCloseReply.bind(this)}
                    handleOpen={this.handleOpenReply.bind(this)}
                    handleAction={this.handleCloseReply.bind(this)}
                  >
                    <AlertDialog
                      title="Choose this reply?"
                      message="are you sure to choose this reply, it might be in a different language than the comment"
                      buttonCancelText="Cancel"
                      buttonAcceptText="Accept"
                      open={openCheckReplySendDialog}
                      action={this.actionReply.bind(this)}
                    />
                    <NestedList
                      data={item["replyConfig"]}
                      item={item}
                      scroll={true}
                      displayItem={true}
                      availableLanguages={
                        item["replyConfig"]
                          ? item["replyConfig"]["available_languages"]
                          : []
                      }
                      defaultLanguage={
                        item["replyConfig"]
                          ? item["replyConfig"]["default_language"]
                          : ""
                      }
                      selectedItem={this.selectedItem.bind(this)}
                    />
                  </FullScreenDialog>
                ) : (
                  <Button disabled>
                    <CircularProgress className={classes.isPending} size={13} />
                    <span style={{ marginLeft: 8 }}>Select reply</span>
                  </Button>
                )
              ) : (
                ""
              )}
              {permissions.includes("approve_comment_reply") &&
              (status === "pending" || status === "failed") ? (
                <Button
                  variant="contained"
                  size="small"
                  className={classNames(classes.button, classes.buttonApprove)}
                  onClick={(e) => {
                    return this.onClickButton(e, "approve");
                  }}
                  disabled={isContainBracket(itemChoosed)}
                >
                  {status === "failed" ? "Retry Approve" : "Approve reply"}
                </Button>
              ) : (
                ""
              )}
              {permissions.includes("cancel_comment_reply") &&
              (status === "new" ||
                status === "pending" ||
                status === "failed") ? (
                <Button
                  variant="contained"
                  size="small"
                  className={classNames(classes.button, classes.buttonCancel)}
                  onClick={(e) => {
                    return this.onClickButton(e, "cancel");
                  }}
                >
                  Cancel reply
                </Button>
              ) : (
                ""
              )}
              {permissions.includes("delete_comment_reply") &&
              (status === "successful" || status === "delete_failed") ? (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  className={classNames(classes.button, classes.buttonRemove)}
                  onClick={(e) => {
                    return this.onClickButton(e, "delete");
                  }}
                >
                  {status === "delete_failed" ? "Retry delete" : "Remove reply"}
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </Card>
        <DialogContent
          title={`${status === "new" ? "Cancel" : "Delete"} reply`}
          open={openReasonMessageDialog}
          close={(e, reason) => {
            this.closeReasonDialogMessage(e, reason);
          }}
        >
          <div>
            <div className={classes.dialogContainer}>
              <div>
                <Typography variant="body2">
                  {`Are you sure to ${
                    status === "new" ? "cancel" : "delete"
                  } this reply ?`}
                </Typography>
              </div>
              {predefinedReasonsItems.length ? (
                <SelectComponent
                  id={"reason"}
                  label={"Predefined Reasons"}
                  multiple={false}
                  fullWidth={true}
                  value={"reason"}
                  hiddenValues={["reason"]}
                  displayEmpty={true}
                  display={1}
                  items={predefinedReasonsItems}
                  onChange={this.handleChange.bind(this)}
                />
              ) : (
                ""
              )}
              <Input
                id={"reason"}
                label={"Reason"}
                value={reason ? reason : ""}
                margin={"dense"}
                onChange={this.handleChange.bind(this)}
              />
            </div>
            {this.displayReasonButtonDialog(status)}
          </div>
        </DialogContent>
        <CustomizedSnackbar
          variant="info"
          onClose={this.onCloseSnackBar.bind(this)}
          message={messageSnackbar}
          open={openSnackBar}
        />
      </div>
    );
  }
}

ReplyArea.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  permissions: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  selectReplyOpen: PropTypes.func.isRequired,
  replyActionToComment: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(ReplyArea);
