import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as fileDownload from "react-file-download";
import queryString from "query-string";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";

import AppBar from "../../components/commons/appBar/AppBarComponent";
import DialogContent from "../../components/commons/dialog/contentDialogComponent";
import LeftMenu from "../../components/commons/leftMenu/leftMenuComponent";
import NotificationMenu from "../../components/commons/notificationMenu/notificationMenuComponent";
import ActionsContent from "../../components/actionsContent/copyright/actionContentComponent";

import CommentList from "../../components/commentList/CommentListComponent";
import PostRelated from "../../components/postRelated/postRelatedComponent";
import CopyrightSearch from "../../components/copyrightSearch/copyrightSearchComponent";
import CopyrightArea from "../../components/copyrightArea/copyrightAreaComponent";

import { operationalTags } from "../../data/data";

import { fetchMe } from "../../actions/meAction";

import { createTemporaryStream } from "../../actions/streamAction";
import {
  fetchClients,
  getCommentListByClient,
  resetCommentList,
  fetchHistoryFromComment,
  updateTranslation,
  processVideo,
  approveVideo,
  removeVideo,
  fetchAdsFromPost,
  fetchReportList,
  generateReport,
  removeVideoFromReport,
  moveVideoFromReport,
  downloadReport,
  lockReport,
} from "../../actions/copyrightAction";
import { getPostStatistics } from "../../actions/statisticsAction";

import { removeEmpty } from "../../utils/utils";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  grid: {
    width: "100%",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    height: 48,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  postRelated: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  postRelatedContainer: {
    marginBottom: "10px",
  },
  rightSide: {
    overflow: "hidden auto",
  },
  heightList: {
    paddingBottom: theme.spacing(2),
    height: "calc(100vh - 325px)",
  },
  actionContent: {
    display: "flex",
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonSuccess: {
    color: theme.palette.white,
    background: theme.palette.success[500],
    "&:hover, &:focus, &:active": {
      color: theme.palette.white,
      background: theme.palette.success[500],
    },
  },
  loading: {
    margin: "auto",
    color: theme.palette.orange[300],
    marginTop: "calc(50vh - 96px)",
  },
  notAllowed: {
    margin: "auto",
    marginTop: "calc(50vh - 96px)",
  },
  totalComments: {
    width: "100%",
    marginBottom: theme.spacing(1),
    textAlign: "right",
    paddingTop: theme.spacing(1),
  },
});

const mapStateToProps = (state) => ({
  me: state.meReducer,
  alerts: state.alertsReducer,
  copyright: state.copyrightReducer,
  statistics: state.statisticsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMe: () => dispatch(fetchMe()),
  getCommentListByClient: (query, option) =>
    dispatch(getCommentListByClient(query, option)),
  getPostStatistics: (clientShortName, postId) =>
    dispatch(getPostStatistics(clientShortName, postId)),
  resetCommentList: () => dispatch(resetCommentList()),
  createTemporaryStream: (data) => dispatch(createTemporaryStream(data)),
  fetchClients: () => dispatch(fetchClients()),
  fetchHistoryFromComment: (clientShortName, commentId, options) =>
    dispatch(fetchHistoryFromComment(clientShortName, commentId, options)),
  updateTranslation: (clientShortName, commentId, options) =>
    dispatch(updateTranslation(clientShortName, commentId, options)),
  fetchAdsFromPost: (clientShortName, postId, options) =>
    dispatch(fetchAdsFromPost(clientShortName, postId, options)),
  processVideo: (clientShortName, commentId, data, options) =>
    dispatch(processVideo(clientShortName, commentId, data, options)),
  approveVideo: (clientShortName, commentId, data, options) =>
    dispatch(approveVideo(clientShortName, commentId, data, options)),
  removeVideo: (clientShortName, commentId, options) =>
    dispatch(removeVideo(clientShortName, commentId, options)),
  fetchReportList: (clientShortName) =>
    dispatch(fetchReportList(clientShortName)),
  generateReport: (query, options) => dispatch(generateReport(query, options)),
  moveVideoFromReport: (clientShortName, videoListSelected, reportSelected) =>
    dispatch(
      moveVideoFromReport(clientShortName, videoListSelected, reportSelected)
    ),
  removeVideoFromReport: (clientShortName, videoListSelected) =>
    dispatch(removeVideoFromReport(clientShortName, videoListSelected)),
  downloadReport: (clientShortName, id) =>
    dispatch(downloadReport(clientShortName, id)),
  lockReport: (clientShortName, id, value) =>
    dispatch(lockReport(clientShortName, id, value)),
});

class CopyrightPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexList: "0",
      clientIndex: "0",
      open: false,
      query: queryString.parse(window.location.search),
      translate: false,
      openFilterDialog: false,
      statisticsSelected: false,
      list: [],
      reportList: [],
      videoListSelected: [],
      me: [],
      total: 0,
      copyrightConfig: [],
      mode: "copyright",
      status: "readOnly",
      statistics: {},
      streamRedirectId: "",
      clients: [],
      fetchComment: false,
      notAllowed: false,
      loading: true,
      disableKeyboard: false,
      notification: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.copyright.list !== prevState.list) {
      update.list = nextProps.copyright.list;
    }

    if (nextProps.copyright.reportList !== prevState.reportList) {
      update.reportList = nextProps.copyright.reportList;
    }

    if (nextProps.copyright.fetchComment !== prevState.fetchComment) {
      update.fetchComment = nextProps.copyright.fetchComment;
    }

    if (nextProps.copyright.total !== prevState.total) {
      update.total = nextProps.copyright.total;
    }

    if (nextProps.copyright.clients !== prevState.clients) {
      update.clients = nextProps.copyright.clients;
    }

    if (nextProps.statistics.statistics !== prevState.statistics) {
      update.statistics = nextProps.statistics.statistics;
    }

    if (nextProps.me.me !== prevState.me) {
      update.me = nextProps.me.me;
    }

    if (nextProps.alerts.notification !== prevState.notification) {
      update.notification = nextProps.alerts.notification;
    }

    return Object.keys(update).length ? update : null;
  }

  updateTranslation(item, callback) {
    this.props
      .updateTranslation(item[1].client.shortName, item[1].id, {
        index: item[1].key,
      })
      .then((response) => {
        callback(response.value.data.messageTranslated);
      });
  }

  clickItemFromPost(string) {
    if (string === "openAds") {
      const post = this.state.list[this.state.indexList]["post"];
      this.props.fetchAdsFromPost(post["client"]["shortName"], post["id"], {
        index: this.state.indexList,
      });
    }
  }

  clickItemFromComment(string, data = {}, callback) {
    const { list, indexList } = this.state;
    const clientShortName = list[indexList]["client"]["shortName"];
    const commentId = list[indexList]["id"];

    if (string === "history") {
      this.props.fetchHistoryFromComment(clientShortName, commentId, {
        index: indexList,
      });
    }

    if (typeof callback === "function") {
      callback();
    }
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  onChangeIndexMenu(index) {
    if (index === 1) {
      this.setState(
        {
          statisticsSelected: true,
        },
        () => {
          const post = this.state.list[this.state.indexList]["post"];
          this.props.getPostStatistics(post["client"]["shortName"], post["id"]);
        }
      );
    } else {
      this.setState({
        statisticsSelected: false,
      });
    }
  }

  getIndexList(index) {
    //get index of the comment list
    this.setState({
      indexList: index,
    });

    if (index && this.state.list && this.state.list[index]) {
      const post = this.state.list[index]["post"];
      if (index && post && post["client"] && this.state.statisticsSelected) {
        this.props.getPostStatistics(post["client"]["shortName"], post["id"]);
      }
    }
  }

  getQueryString(q) {
    const query = queryString.stringify(removeEmpty(q), {
      strict: false,
      encode: false,
      arrayFormat: "comma",
      skipNull: true,
    });
    return query;
  }

  getFilterData(data) {
    const query = {};

    if (data.clientShortName) {
      Object.assign(query, {
        client_short_name: data.clientShortName,
      });
    } else {
      return false;
    }

    if (data.copyrightStatus) {
      Object.assign(query, {
        copyright_status: data.copyrightStatus.join(","),
      });
    }

    if (data.copyrightKeyword) {
      Object.assign(query, {
        copyright_keyword: data.copyrightKeyword,
      });
    }

    if (data.copyrightCategories) {
      Object.assign(query, {
        copyright_categories: data.copyrightCategories.join(","),
      });
    }

    if (data.copyrightInfringments) {
      Object.assign(query, {
        copyright_infringments: data.copyrightInfringments.join(","),
      });
    }

    if (data.sort) {
      Object.assign(query, {
        sort: data.sort,
      });
    }

    if (data.copyrightLegacyVideos) {
      Object.assign(query, {
        copyright_legacy_videos: data.copyrightLegacyVideos,
      });
    }

    if (data.startTime) {
      Object.assign(query, {
        start: data.startTime,
      });
    }

    if (data.endTime) {
      Object.assign(query, {
        end: data.endTime,
      });
    }

    if (data.commentIds) {
      Object.assign(query, {
        comment_ids: data.commentIds,
      });
    }

    if (data.report) {
      Object.assign(query, {
        report: data.report,
      });
    }

    if (data.hasReport) {
      Object.assign(query, {
        has_report: data.hasReport,
      });
    }

    this.setState(
      {
        query: query,
        openFilterDialog: false,
        total: 0,
      },
      () => {
        let client = this.findClientKey(this.state.clients, "clientShortName");
        if (client) {
          this.clientSelected(client[0]);
        }
      }
    );
  }

  updateList(query) {
    this.props.resetCommentList().then(() => {
      setTimeout(() => {
        this.fetchCommentList();
      }, 0);
    });

    localStorage.setItem("query-copyright", JSON.stringify(query));
    window.history.pushState(
      null,
      null,
      `${window.location.origin}/copyright?${this.getQueryString(query)}`
    );
  }

  fetchCommentList(options = {}, callback = null) {
    const { query, indexList } = this.state;
    this.props.getCommentListByClient(query, options).then((response) => {
      if (typeof callback === "function") {
        callback(
          response &&
            response.value &&
            response.value.data &&
            response.value.data.items &&
            response.value.data.items.length
            ? false
            : true
        );
      }
    });
    this.getIndexList(indexList ? indexList : "0");
  }

  logout() {
    this.props.signOut();
  }

  loadMore(callback) {
    this.fetchCommentList(
      {
        cursor: this.props.copyright.cursor,
      },
      callback
    );
  }

  showFilterDialog() {
    this.setState({
      openFilterDialog: true,
    });
  }

  onSelectVideo(videoList) {
    this.setState({
      videoListSelected: videoList,
    });
  }

  editMove() {
    this.setState({
      status: "edit",
    });
  }

  moveVideoFromReport(reportSelected) {
    const { videoListSelected, clients, clientIndex } = this.state;
    this.props
      .moveVideoFromReport(
        clients[clientIndex]["clientShortName"],
        videoListSelected,
        reportSelected
      )
      .then(() => {
        this.setState({
          status: "readOnly",
        });
      });
  }

  removeVideoFromReport() {
    const { videoListSelected, clients, clientIndex } = this.state;
    this.props
      .removeVideoFromReport(
        clients[clientIndex]["clientShortName"],
        videoListSelected
      )
      .then(() => {
        this.setState({
          status: "readOnly",
        });
      });
  }

  cancelMove() {
    this.setState({
      status: "readOnly",
    });
  }

  lockReport(id, value) {
    const { clients, clientIndex } = this.state;

    this.props.lockReport(clients[clientIndex]["clientShortName"], id, value);
  }

  downloadReport(id) {
    const { clients, clientIndex } = this.state;

    this.props
      .downloadReport(clients[clientIndex]["clientShortName"], id)
      .then((response) => {
        fileDownload(
          response.value.data,
          `report-ip-protection-${clients[clientIndex]["clientShortName"]}.csv`
        );
      });
  }

  generateReport() {
    this.props.generateReport(this.state.query, {});
  }

  closeFilterDialog() {
    this.setState({
      openFilterDialog: false,
    });
  }

  selectCopyrightOpen(status) {
    this.setState({
      disableKeyboard: status,
    });
  }

  setCopyrightPage() {
    //if 0 param in the url
    if (
      Object.values(this.state.query).length === 0 &&
      !this.state.query.comment_ids &&
      localStorage.getItem("query-copyright")
    ) {
      window.history.pushState(
        null,
        null,
        `${window.location.origin}/copyright?${this.getQueryString(
          JSON.parse(localStorage.getItem("query-copyright"))
        )}`
      );
      this.setState(
        {
          query: JSON.parse(localStorage.getItem("query-copyright")),
        },
        () => {
          this.getClientCopyrightList(this.state.query.comment_ids);
        }
      );
    } else {
      this.getClientCopyrightList(this.state.query.comment_ids);
    }
  }

  clientSelected(index = "0", firstload = false, comment_ids) {
    const { me, clients } = this.state;
    let client_short_name = clients[index]
      ? clients[index]["clientShortName"]
      : "";

    this.props.fetchReportList(client_short_name).then(() => {
      if (
        me &&
        me.permissions &&
        me.permissions.includes("list_copyright_items")
      ) {
        if (comment_ids) {
          this.setState(
            {
              query: {
                client_short_name: client_short_name,
                comment_ids: comment_ids,
              },
            },
            () => {
              this.updateList(this.state.query);
            }
          );
        } else if (
          index !== this.state.clientIndex ||
          (!Object.values(this.state.query).length && firstload)
        ) {
          this.setState(
            {
              query: {
                client_short_name: client_short_name,
                copyright_status: "new",
              },
            },
            () => {
              this.updateList(this.state.query);
            }
          );
        } else {
          if (!firstload) {
            this.updateList(this.state.query);
          }
        }
      }
    });
    this.setState({
      clientIndex: index,
    });
  }

  findClientKey(clientList, value) {
    const { query } = this.state;
    const clientKeys = Object.keys(clientList);
    return clientKeys.filter((key) => {
      return query.client_short_name === clientList[key][value];
    });
  }

  getClientCopyrightList(comment_ids) {
    const { query } = this.state;
    this.props.fetchClients().then((response) => {
      if (query && query.client_short_name) {
        let client = this.findClientKey(
          response.value.data.items,
          "client_short_name"
        );
        if (client) {
          this.clientSelected(client[0], false, comment_ids);
        }
      }
    });
  }

  actionReport(status, data = {}) {
    const { list, indexList, clients, clientIndex } = this.state;
    const comment_id = list[indexList]["id"];
    const client_short_name = clients[clientIndex]["clientShortName"]
      ? clients[clientIndex]["clientShortName"]
      : null;

    if (status === "process") {
      this.props.processVideo(client_short_name, comment_id, data, {
        index: indexList,
      });
    } else if (status === "approve") {
      this.props.approveVideo(client_short_name, comment_id, data, {
        index: indexList,
      });
    } else {
      this.props.removeVideo(client_short_name, comment_id, {
        index: indexList,
      });
    }
  }

  changeKeyboardSatus(status) {
    this.setState({
      disableKeyboard: status,
    });
  }

  componentDidMount() {
    this.props.fetchMe().then((response) => {
      this.setState({
        loading: false,
      });
      if (
        response.value &&
        response.value.data &&
        response.value.data.permissions &&
        response.value.data.permissions.includes("copyright_stats")
      ) {
        this.setCopyrightPage();
      } else {
        this.setState({
          notAllowed: true,
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { clients, clientIndex } = this.state;
    let client_short_name = clients[clientIndex]
      ? clients[clientIndex]["clientShortName"]
      : "";

    if (
      prevState.notification !== this.state.notification &&
      client_short_name
    ) {
      this.props.fetchReportList(client_short_name);
    }
  }

  render() {
    const { classes } = this.props;
    const {
      list,
      reportList,
      total,
      open,
      indexList,
      clientIndex,
      translate,
      displayBoth,
      statistics,
      mode,
      status,
      query,
      openFilterDialog,
      clients,
      fetchComment,
      streamRedirectId,
      notAllowed,
      loading,
      disableKeyboard,
      videoListSelected,
      me,
    } = this.state;

    if (streamRedirectId) {
      return (
        <Redirect
          to={{
            pathname: "/moderation",
            search: `?stream=${streamRedirectId}`,
          }}
        />
      );
    }

    return (
      <div className={classes.root}>
        <AppBar
          action={this.handleDrawerOpen}
          status={open}
          selected={"copyright"}
          logout={this.logout.bind(this)}
        />
        <LeftMenu open={open} onClose={this.handleDrawerClose.bind(this)} />
        {clients.length ? (
          <NotificationMenu
            index={clientIndex}
            enableFavorite={false}
            selected={this.clientSelected.bind(this)}
            items={clients}
            title={"Clients"}
            moderation={false}
            loaded={fetchComment}
            hasGroupedItems={false}
          />
        ) : (
          ""
        )}

        <main className={classes.content}>
          <div className={classes.toolbar} />
          {!notAllowed && !loading ? (
            <Grid container>
              <Grid container spacing={2}>
                <Grid item xl={8} lg={7} sm={6} xs={7}>
                  <Grid item xs={12}>
                    <CommentList
                      containerClassName={classes.heightList}
                      getIndexList={this.getIndexList.bind(this)}
                      translated={translate}
                      displayBoth={displayBoth}
                      list={list}
                      clickItemFromComment={this.clickItemFromComment.bind(
                        this
                      )}
                      loadMore={this.loadMore.bind(this)}
                      cursor={this.props.copyright.cursor}
                      onChangeSelect={this.onSelectVideo.bind(this)}
                      actionsMenu={operationalTags}
                      mode={mode}
                      status={status}
                      updateTranslation={this.updateTranslation.bind(this)}
                      disableKeyboard={disableKeyboard}
                    />
                  </Grid>
                  <Grid item>
                    <div className={classes.totalComments}>
                      <Chip
                        color="primary"
                        size="small"
                        icon={<DoneIcon />}
                        label={`Total comments: ${total ? total : "0"}`}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  className={classes.rightSide}
                  item
                  xl={4}
                  lg={5}
                  sm={6}
                  xs={5}
                >
                  <div className={classes.postRelatedContainer}>
                    <PostRelated
                      statistics={statistics}
                      clickItemFromPost={this.clickItemFromPost.bind(this)}
                      onChangeIndexMenu={this.onChangeIndexMenu.bind(this)}
                      post={
                        list && list[indexList] ? list[indexList]["post"] : null
                      }
                      isYoutubeVideo={true}
                      viewCount={
                        list[indexList] && list[indexList]["viewCount"]
                          ? list[indexList]["viewCount"]
                          : "0"
                      }
                    />
                  </div>
                  <div className={classes.actionsContainer}>
                    <ActionsContent
                      me={me}
                      showFilterDialog={this.showFilterDialog.bind(this)}
                      edit={this.editMove.bind(this)}
                      move={this.moveVideoFromReport.bind(this)}
                      removeVideo={this.removeVideoFromReport.bind(this)}
                      cancelMove={this.cancelMove.bind(this)}
                      lockReport={this.lockReport.bind(this)}
                      list={list}
                      reportList={reportList}
                      downloadReport={this.downloadReport.bind(this)}
                      generateReport={this.generateReport.bind(this)}
                      videoListSelected={videoListSelected}
                      status={status}
                    />
                    <DialogContent
                      title={"Filters"}
                      open={openFilterDialog}
                      close={this.closeFilterDialog.bind(this)}
                    >
                      {clients && clients.length ? (
                        <CopyrightSearch
                          defaultValues={query}
                          getFilterData={this.getFilterData.bind(this)}
                          clients={clients}
                          reportList={reportList}
                          hide={this.closeFilterDialog.bind(this)}
                        />
                      ) : (
                        <div></div>
                      )}
                    </DialogContent>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {me.permissions && me.permissions.length && list[indexList] ? (
                  <CopyrightArea
                    action={this.actionReport.bind(this)}
                    onFocus={this.changeKeyboardSatus.bind(this)}
                    onBlur={this.changeKeyboardSatus.bind(this)}
                    me={me}
                    data={
                      list && list[indexList]
                        ? list[indexList]["copyright"]
                        : []
                    }
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          ) : !notAllowed ? (
            <CircularProgress className={classes.loading} />
          ) : (
            <Typography className={classes.notAllowed} variant="body1">
              Not Allowed
            </Typography>
          )}
        </main>
      </div>
    );
  }
}

CopyrightPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CopyrightPage));
