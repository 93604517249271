import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

import InputComponent from '../../commons/input/inputComponent';
import SelectComponent from '../../commons/select/selectComponent';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  formControlContainer: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  formGroup: {
    width: '50%'
  }
}));

const GuidelineGroupsForm = props => {
  const classes = useStyles();

  const { formData, errors } = props;

  return (
    <div className={classes.formControlContainer}>
      <FormControl className={classes.formControl}>
        <FormGroup className={classes.formGroup}>
          <InputComponent
            required={true}
            defaultValue={formData['name'] ? formData['name'] : ''}
            onChange={e => {
              props.onChangeFormData(e, 'name');
            }}
            value={formData['name'] ? formData['name'] : ''}
            error={errors['name'] ? true : false}
            helperText={errors['name']}
            label={'Name'}
            margin="dense"
            fullWidth={true}
          />
        </FormGroup>

        <FormGroup>
          <SelectComponent
            id={'asset_ids'}
            label={'Assets'}
            attributName={'name'}
            attributId={'id'}
            multiple
            displaySearch
            value={formData['asset_ids'] ? formData['asset_ids'] : []}
            items={props.assetList.map(asset => {
              return {
                ...asset,
                id: asset.id,
                name: `${asset.name} (${asset.id})`
              };
            })}
            onChange={e => {
              props.onChangeFormData(e, 'asset_ids');
            }}
            error={errors['asset_ids'] ? errors['asset_ids'] : null}
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};

GuidelineGroupsForm.propTypes = {
  formData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChangeFormData: PropTypes.func.isRequired
};

export default GuidelineGroupsForm;
