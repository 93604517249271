import axios from 'axios';

import { statisticsConstants } from '../constants/statisticsConstants';
import { setHeaders } from '../setters/auth';

export const getPostStatistics = (
  clientShortName = '',
  postId = ''
) => dispatch => {
  const url = `${
    process.env.URL_API_ENDPOINT
  }/clients/${clientShortName}/posts/${postId}/comments/statistics`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: statisticsConstants.GET_POST_STATISTICS,
    payload: promise
  });
};
