import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TableComponent from "../../commons/table/tableComponent";
import DialogContent from "../../commons/dialog/contentDialogComponent";

import GroupsForm from "./groupsForm";

import { downloadAsJson } from "../../../utils/utils";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 7,
  },
  buttonAddGroup: {
    marginLeft: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonDownload: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0),
    color: theme.palette.black,
    padding: 0,
  },
  loading: {
    marginRight: 6,
    width: "16px !important",
    height: "16px !important",
    color: "#00000042",
  },
});

class GroupsConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupSelected: 0,
      openDialogGroups: false,
      mode: "add",
      defaultValue: {},
      error: {},
      editable: props.editable,
      creatable: props.creatable,
      onErrorFilter: false,
      checkFilter: false,
      columns: [
        {
          title: "ID",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          field: "id",
        },
        {
          title: "Label",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          field: "label",
        },
        {
          title: "Description",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          field: "description",
          render: (rowData) => this.displayDescription(rowData.description),
        },
        {
          title: "Filters",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          field: "filters",
          render: (rowData) => this.displayFilters(rowData.filters),
        },
      ],
      editingAction: props.editingAction || false,
      data: props.groupList || [],
      actions: this.onCreateActions(props.editable),
      group: {},
    };
  }

  displayFilters(filters) {
    return <div>{filters ? filters.length : "0"}</div>;
  }

  displayDescription(description) {
    if (description.length > 50) {
      return `${description.slice(0, 50)}...`;
    }
    return description;
  }

  onCreateActions(editable) {
    return editable
      ? [
          {
            icon: "edit",
            tooltip: "Edit Group",
            onClick: (event, rowData) => this.actionEditGroup(event, rowData),
          },
        ]
      : [];
  }

  onChangeGroupForm(data) {
    this.setState({
      onErrorFilter: false,
      checkFilter: false,
      group: data,
    });
  }

  onErrorGroupForm() {
    this.setState({
      onErrorFilter: true,
    });
  }

  checkForErrors() {
    const { group, onErrorFilter } = this.state;

    const promise = new Promise((resolve, reject) => {
      this.setState(
        {
          error: {
            id: group.id ? "" : "ID is mandatory",
            label: group.label ? "" : "Label is mandatory",
            description: group.description ? "" : "Description is mandatory",
            filters: !group.filters.length
              ? "Filters is madatory"
              : onErrorFilter
              ? "JSON not valid"
              : "",
          },
        },
        () => {
          resolve();
        }
      );
    });

    return promise;
  }

  updateGroup = () => {
    this.setState(
      {
        checkFilter: true,
      },
      () => {
        setTimeout(() => {
          this.checkForErrors().then(() => {
            if (
              !Object.values(this.state.error).filter((value) => value !== "")
                .length
            ) {
              if (this.state.mode === "add") {
                this.props.addGroup(this.state.group);
              } else {
                this.props.editGroup(
                  this.state.groupSelected,
                  this.state.group
                );
              }
            }
          });
        }, 100);
      }
    );
  };

  openDialogGroups() {
    this.setState({
      openDialogGroups: true,
    });
  }

  closeDialogGroups = () => {
    if (!this.state.editingAction) {
      this.setState({
        openDialogGroups: false,
      });
    }
  };

  actionAddGroup = (e) => {
    const defaultValue = {
      id: "",
      label: "",
      description: "",
      filters: [],
    };

    this.setState(
      {
        mode: "add",
        defaultValue: defaultValue,
        group: defaultValue,
      },
      () => {
        this.openDialogGroups();
      }
    );
  };

  actionEditGroup(e, data) {
    const defaultValue = {
      id: data.id,
      label: data.label,
      description: data.description,
      filters: data.filters,
    };

    this.setState(
      {
        mode: "edit",
        groupSelected: data.tableData.id,
        defaultValue: defaultValue,
        group: defaultValue,
      },
      () => {
        this.openDialogGroups();
      }
    );
  }

  displayButtonsGroup() {
    const { classes } = this.props;
    const { mode, editingAction } = this.state;

    return (
      <div className={classes.buttonContainer}>
        <Button
          disabled={editingAction}
          size="small"
          color="primary"
          onClick={this.updateGroup}
        >
          {editingAction && <CircularProgress className={classes.loading} />}
          {mode === "add" ? "Add group" : "Edit group"}
        </Button>
        <Button
          onClick={this.closeDialogGroups}
          size="small"
          color="secondary"
          disabled={editingAction}
        >
          Cancel
        </Button>
      </div>
    );
  }

  downloadJson = () => {
    downloadAsJson(
      this.state.data,
      this.props.clientShortName +
        "_groups_" +
        moment().format("YYYY-MM-DD") +
        "_" +
        moment().format("hh_mm_A")
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.editingAction !== this.props.editingAction) {
      this.setState({
        editingAction: this.props.editingAction,
        openDialogGroups:
          !!prevProps.editingAction && !this.props.editingAction
            ? false
            : this.state.openDialogGroups,
      });
    }

    if (prevProps.groupList !== this.props.groupList) {
      this.setState({
        data: this.props.groupList,
      });
    }

    if (prevProps.creatable !== this.props.creatable) {
      this.setState({
        creatable: this.props.creatable,
      });
    }

    if (prevProps.editable !== this.props.editable) {
      this.setState({
        editable: this.props.editable,
        actions: this.onCreateActions(this.props.editable),
      });
    }
  }

  render() {
    const { classes, creatable } = this.props;
    const {
      columns,
      data,
      actions,
      openDialogGroups,
      defaultValue,
      checkFilter,
      error,
      mode,
    } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h5">Group list</Typography>
          <IconButton
            className={classes.buttonDownload}
            onClick={this.downloadJson}
          >
            <SystemUpdateAltIcon />
          </IconButton>
          {creatable ? (
            <Button
              variant="contained"
              className={classes.buttonAddGroup}
              size="small"
              color="primary"
              onClick={this.actionAddGroup}
            >
              Add group
            </Button>
          ) : (
            ""
          )}
        </div>
        <TableComponent
          editable={false}
          columns={columns}
          data={data}
          actions={actions}
          title=""
        />
        <DialogContent
          title={"Group"}
          open={openDialogGroups}
          fullWidth={true}
          close={this.closeDialogGroups.bind(this)}
        >
          <div>
            <GroupsForm
              mode={mode}
              defaultValue={defaultValue}
              checkFilter={checkFilter}
              onErrorGroupForm={this.onErrorGroupForm.bind(this)}
              onChangeGroupForm={this.onChangeGroupForm.bind(this)}
              error={error}
            />
            {this.displayButtonsGroup()}
          </div>
        </DialogContent>
      </div>
    );
  }
}

GroupsConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  addGroup: PropTypes.func.isRequired,
  editGroup: PropTypes.func.isRequired,
  editingAction: PropTypes.bool,
  groupList: PropTypes.array,
  editable: PropTypes.bool,
  creatable: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(GroupsConfiguration);
