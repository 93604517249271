import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';

const styles = theme => ({
  root: {
    width: '100%'
  }
});

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: props.columns,
      editable: props.editable,
      data: props.data ? props.data : [],
      pageSize: 5
    };
  }

  onChangeRowsPerPage(page) {
    this.setState({
      pageSize: page
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data
      });
    }

    if (prevProps.editable !== this.props.editable) {
      this.setState({
        editable: this.props.editable
      });
    }
  }

  update(index, newData) {
    this.props.update(index, newData);
  }

  render() {
    const {
      theme,
      title,
      actions,
      detailPanel,
      onSelectionChange
    } = this.props;
    const { editable, data, columns, pageSize } = this.state;
    const options = {
      ...this.props.options,
      emptyRowsWhenPaging: false,
      pageSize: pageSize
    };

    return (
      <MaterialTable
        style={{
          width: '90%',
          margin: 'auto',
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2)
        }}
        actions={actions ? actions : []}
        options={options}
        onSelectionChange={onSelectionChange}
        title={title || ''}
        columns={columns}
        data={data}
        editable={
          editable
            ? {
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    const all = data;
                    const index = all.indexOf(oldData);
                    all[index] = newData;
                    this.setState({ all }, () => {
                      this.update(index, newData);
                      resolve();
                    });
                  })
              }
            : {}
        }
        detailPanel={detailPanel ? detailPanel : []}
        onChangeRowsPerPage={page => this.onChangeRowsPerPage(page)}
        onRowClick={
          detailPanel ? (event, rowData, togglePanel) => togglePanel() : null
        }
      />
    );
  }
}

Table.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  title: PropTypes.string,
  editable: PropTypes.bool,
  action: PropTypes.array,
  update: PropTypes.func,
  detailPanel: PropTypes.array,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  options: PropTypes.object
};

export default withStyles(styles, { withTheme: true })(Table);
