import { tagsExampleConstants } from '../../constants/configuration/tagsExampleConstants';

const initialState = {
  fetching: false,
  fetched: false,
  tagsExampleList: [],
  error: null
};

const TagsExample = (state = initialState, action) => {
  switch (action.type) {
    // FETCH ASSET LIST
    case `${tagsExampleConstants.FETCH_TAG_EXAMPLE_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${tagsExampleConstants.FETCH_TAG_EXAMPLE_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${tagsExampleConstants.FETCH_TAG_EXAMPLE_LIST}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        tagsExampleList: action.payload.data || []
      };
    // EDIT ASSET
    case `${tagsExampleConstants.EDIT_TAG_EXAMPLE}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${tagsExampleConstants.EDIT_TAG_EXAMPLE}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${tagsExampleConstants.EDIT_TAG_EXAMPLE}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        tagsExampleList: Object.assign([], {
          ...state.tagsExampleList,
          [action.payload.config.index]: {
            ...state.tagsExampleList[action.payload.config.index],
            example: action.payload.config.tagsExampleInfo.example,
            excluded_example:
              action.payload.config.tagsExampleInfo.excluded_example
          }
        })
      };
    default:
      return state;
  }
};

export default TagsExample;
