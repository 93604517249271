import { usersConstants } from '../../constants/configuration/usersConstants';

import { extractUserList } from '../../extractors/configurationExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  userList: [],
  error: null
};

const Users = (state = initialState, action) => {
  switch (action.type) {
    // FETCH USER LIST
    case `${usersConstants.FETCH_USER_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${usersConstants.FETCH_USER_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${usersConstants.FETCH_USER_LIST}_FULFILLED`:
      let userList = extractUserList(action.payload.data);
      return {
        ...state,
        fetching: false,
        fetched: true,
        userList: userList
      };

    // UPDATE USER ROLES
    case `${usersConstants.UPDATE_USER_ROLES}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${usersConstants.UPDATE_USER_ROLES}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${usersConstants.UPDATE_USER_ROLES}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        userList: Object.assign([], {
          ...state.userList,
          [action.payload.config.index]: {
            ...state.userList[action.payload.config.index],
            roles: action.payload.config.roles
          }
        })
      };
    default:
      return state;
  }
};

export default Users;
