import axios from 'axios';

import { attemptsConstants } from '../constants/attemptsConstants';
import { setHeaders } from '../setters/auth';

const createQuery = options => {
  const params = [];

  if (options.search) params.push(`term=${options.search}`);

  return params.join('&');
};

export const fetchAttemptsList = (code, options = {}) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/sales/analysis/${code}`;

  if (options && options.search) {
    url = url + `?`;
  }

  url = url + createQuery(options);

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: attemptsConstants.FETCH_ATTEMPTS_LIST,
    payload: promise
  });
};

export const restartAnalysis = id => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/sales/analysis/attempts/${id}/reports`;

  const promise = axios.post(url, null, {
    headers: setHeaders()
  });

  return dispatch({
    type: attemptsConstants.RESTART_ATTEMPT,
    payload: promise
  });
};

export const convertClient = (id, data) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/sales/analysis/attempts/${id}/convert`;

  const promise = axios.post(
    url,
    {
      client_short_name: data.clientShortName,
      name: data.name
    },
    {
      headers: setHeaders(),
      index: id
    }
  );

  return dispatch({
    type: attemptsConstants.CONVERT_ATTEMPT_TO_CLIENT,
    payload: promise
  });
};

export const resetAttemptsList = () => dispatch => {
  return dispatch({
    type: attemptsConstants.RESET_ATTEMPTS_LIST
  });
};
