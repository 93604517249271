import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import IconButton from '@material-ui/core/IconButton';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import PanToolIcon from '@material-ui/icons/PanTool';
import TableComponent from '../../commons/table/tableComponent';
import DialogContent from '../../commons/dialog/contentDialogComponent';

import AssetsForm from './assetsForm';

import { downloadAsJson } from '../../../utils/utils';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 7
  },
  buttonAddAsset: {
    marginLeft: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  platform: {
    marginLeft: 2,
    marginRight: 2
  },
  crawlingAllowed: {
    color: theme.palette.success[500]
  },
  buttonDownload: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0),
    color: theme.palette.black,
    padding: 0
  }
});

class AssetsConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetSelected: 0,
      openDialogAssets: false,
      mode: 'add',
      defaultValue: {},
      error: {},
      editable: props.editable,
      creatable: props.creatable,
      platformList: props.platformList || [],
      columns: [
        {
          title: 'ID',
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14
          },
          field: 'id'
        },
        {
          title: 'Name',
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14
          },
          field: 'name'
        },
        {
          title: 'Crawling Allowed',
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14
          },
          field: 'crawling_allowed',
          render: rowData =>
            rowData.crawling_allowed ? (
              <DoneOutlineIcon className={props.classes.crawlingAllowed} />
            ) : (
              <PanToolIcon color="error" />
            )
        },
        {
          title: 'Platforms',
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14
          },
          field: 'platform_ids',
          render: rowData => this.displayPlatformNames(rowData.platform_ids)
        }
      ],
      data: props.assetList || [],
      actions: this.onCreateActions(props.editable),
      asset: {}
    };
  }

  displayPlatformNames(ids) {
    const { classes } = this.props;
    const { platformList } = this.state;
    return platformList.map((platform, key) => {
      if (ids.includes(key.toString())) {
        return <Chip className={classes.platform} key={key} label={platform} />;
      }
      return '';
    });
  }

  onCreateActions(editable) {
    return editable
      ? [
          {
            icon: 'edit',
            tooltip: 'Edit Asset',
            onClick: (event, rowData) => this.actionEditAsset(event, rowData)
          }
        ]
      : [];
  }

  onChangeAssetForm(data) {
    this.setState({ asset: data });
  }

  checkForErrors() {
    const { asset } = this.state;

    const promise = new Promise((resolve, reject) => {
      this.setState(
        {
          error: {
            id: asset.id ? '' : 'ID is mandatory',
            name: asset.name ? '' : 'Name is mandatory',
            platform_ids: asset.platform_ids.length
              ? ''
              : 'Plaform(s) is mandatory'
          }
        },
        () => {
          resolve();
        }
      );
    });

    return promise;
  }

  updateAsset() {
    this.checkForErrors().then(() => {
      if (
        !Object.values(this.state.error).filter(value => value !== '').length
      ) {
        if (this.state.mode === 'add') {
          this.props.addAsset(this.state.asset);
        } else {
          this.props.editAsset(this.state.assetSelected, this.state.asset);
        }
        this.closeDialogAssets();
      }
    });
  }

  openDialogAssets() {
    this.setState({
      openDialogAssets: true
    });
  }

  closeDialogAssets() {
    this.setState({
      openDialogAssets: false
    });
  }

  actionAddAsset(e) {
    const defaultValue = {
      id: '',
      name: '',
      platform_ids: []
    };

    this.setState(
      {
        mode: 'add',
        defaultValue: defaultValue,
        asset: defaultValue
      },
      () => {
        this.openDialogAssets();
      }
    );
  }

  actionEditAsset(e, data) {
    const defaultValue = {
      id: data.id,
      name: data.name,
      platform_ids: data.platform_ids
    };

    this.setState(
      {
        mode: 'edit',
        assetSelected: data.tableData.id,
        defaultValue: defaultValue,
        asset: defaultValue
      },
      () => {
        this.openDialogAssets();
      }
    );
  }

  displayButtonsAsset() {
    const { classes } = this.props;
    const { mode } = this.state;
    return (
      <div className={classes.buttonContainer}>
        <Button
          size="small"
          color="primary"
          onClick={e => {
            return this.updateAsset();
          }}
        >
          {mode === 'add' ? 'Add asset' : 'Edit asset'}
        </Button>
        <Button
          onClick={e => {
            return this.closeDialogAssets(e);
          }}
          size="small"
          color="secondary"
        >
          Cancel
        </Button>
      </div>
    );
  }

  downloadJson() {
    downloadAsJson(
      this.state.data,
      this.props.clientShortName +
        '_assets_' +
        moment().format('YYYY-MM-DD') +
        '_' +
        moment().format('hh_mm_A')
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assetList !== this.props.assetList) {
      this.setState({
        data: this.props.assetList
      });
    }

    if (prevProps.platformList !== this.props.platformList) {
      this.setState({
        platformList: this.props.platformList
      });
    }

    if (prevProps.creatable !== this.props.creatable) {
      this.setState({
        creatable: this.props.creatable
      });
    }

    if (prevProps.editable !== this.props.editable) {
      this.setState({
        editable: this.props.editable,
        actions: this.onCreateActions(this.props.editable)
      });
    }
  }

  render() {
    const { classes, creatable } = this.props;
    const {
      columns,
      data,
      actions,
      openDialogAssets,
      defaultValue,
      error,
      platformList,
      mode
    } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h5">Asset list</Typography>
          <IconButton
            className={classes.buttonDownload}
            onClick={() => this.downloadJson()}
          >
            <SystemUpdateAltIcon />
          </IconButton>
          {creatable ? (
            <Button
              variant="contained"
              className={classes.buttonAddAsset}
              size="small"
              color="primary"
              onClick={e => {
                return this.actionAddAsset(e);
              }}
            >
              Add asset
            </Button>
          ) : (
            ''
          )}
        </div>
        <TableComponent
          editable={false}
          columns={columns}
          data={data}
          actions={actions}
          title=""
        />
        <DialogContent
          title={'Asset'}
          open={openDialogAssets}
          fullWidth={true}
          close={this.closeDialogAssets.bind(this)}
        >
          <div>
            <AssetsForm
              mode={mode}
              defaultValue={defaultValue}
              platformList={platformList}
              onChangeAssetForm={this.onChangeAssetForm.bind(this)}
              error={error}
            />
            {this.displayButtonsAsset()}
          </div>
        </DialogContent>
      </div>
    );
  }
}

AssetsConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  addAsset: PropTypes.func.isRequired,
  editAsset: PropTypes.func.isRequired,
  assetList: PropTypes.array,
  platformList: PropTypes.array,
  editable: PropTypes.bool,
  creatable: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(AssetsConfiguration);
