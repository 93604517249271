import axios from 'axios';

import { copyrightConstants } from '../constants/copyrightConstants';
import { setHeaders } from '../setters/auth';

const createQuery = (query, options) => {
  const params = [];

  if (query.start) params.push(`startTime=${query.start}`);
  if (query.start) params.push(`startTime=${query.start}`);
  if (query.end) params.push(`endTime=${query.end}`);
  if (query.copyright_status)
    params.push(`copyright_statuses=${query.copyright_status}`);
  if (query.copyright_keyword)
    params.push(
      `copyright_keyword=${encodeURIComponent(query.copyright_keyword)}`
    );
  if (query.copyright_categories)
    params.push(`copyright_categories=${query.copyright_categories}`);
  if (query.copyright_infringments)
    params.push(`copyright_infringments=${query.copyright_infringments}`);
  if (query.comment_ids) params.push(`comment_ids=${query.comment_ids}`);
  if (query.has_report)
    params.push(
      `has_report=${query.has_report === 'included' ? 'true' : 'false'}`
    );
  if (query.report) params.push(`report=${query.report}`);
  if (query.sort) params.push(`sort=created_time:desc`);
  if (query.copyright_legacy_videos)
    params.push(`copyright_legacy_videos=true`);
  if (options.cursor) params.push(`cursor=${options.cursor}`);
  if (options.limit) params.push(`limit=${options.limit}`);

  return params.join('&');
};

export const getCommentListByClient = (query, options = {}) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/copyright/clients/${query.client_short_name}/items`;
  if (
    (options && options.cursor) ||
    (options && options.limit) ||
    (Object.values(query).length > 1 &&
      Object.keys(query).includes('client_short_name'))
  ) {
    url = url + `?`;
  }

  url = url + createQuery(query, options);

  const promise = axios.get(url, {
    headers: setHeaders(),
    options: options
  });

  return dispatch({
    type: copyrightConstants.GET_VIDEO_LIST_BY_CLIENT_COPYRIGHT,
    payload: promise
  });
};

export const generateReport = (query, options = {}) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/copyright/clients/${query.client_short_name}/reports`;
  if (
    (options && options.cursor) ||
    (options && options.limit) ||
    (Object.values(query).length > 1 &&
      Object.keys(query).includes('client_short_name'))
  ) {
    url = url + `?`;
  }

  url = url + createQuery(query, options);

  const promise = axios.post(
    url,
    {},
    {
      headers: setHeaders(),
      options: options
    }
  );

  return dispatch({
    type: copyrightConstants.GENERATE_COPYRIGHT_REPORT_VIDEO,
    payload: promise
  });
};

export const resetCommentList = () => dispatch => {
  const promise = new Promise((resolve, reject) => {
    resolve(); // fulfilled
    reject('failure decrase notification count');
  });

  return dispatch({
    type: copyrightConstants.RESET_VIDEO_LIST_COPYRIGHT,
    payload: promise
  });
};

export const fetchClients = () => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/copyright/stats`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: copyrightConstants.FETCH_CLIENTS_COPYRIGHT,
    payload: promise
  });
};

export const fetchReportList = clientShortName => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/copyright/clients/${clientShortName}/reports`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: copyrightConstants.FETCH_CLIENTS_REPORTS_COPYRIGHT,
    payload: promise
  });
};

export const moveVideoFromReport = (
  clientShortName,
  videoListSelected,
  reportSelected
) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/copyright/clients/${clientShortName}/reports/${reportSelected}/objects`;

  const promise = axios.post(
    url,
    {
      object_ids: videoListSelected
    },
    {
      headers: setHeaders(),
      videoListSelected: videoListSelected,
      reportSelected: reportSelected
    }
  );

  return dispatch({
    type: copyrightConstants.MOVE_VIDEO_FROM_REPORT,
    payload: promise
  });
};

export const removeVideoFromReport = (
  clientShortName,
  videoListSelected
) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/copyright/clients/${clientShortName}/reports/remove-objects`;

  const promise = axios.post(
    url,
    {
      object_ids: videoListSelected
    },
    {
      headers: setHeaders(),
      videoListSelected: videoListSelected
    }
  );

  return dispatch({
    type: copyrightConstants.REMOVE_VIDEO_FROM_REPORT,
    payload: promise
  });
};

export const downloadReport = (clientShortName, reportId) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/copyright/clients/${clientShortName}/reports/${reportId}/export`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: copyrightConstants.DOWNLOAD_REPORT_COPYRIGHT,
    payload: promise
  });
};

export const lockReport = (clientShortName, reportId, value) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/copyright/clients/${clientShortName}/reports/${reportId}/lock`;

  const promise = axios.patch(
    url,
    {},
    {
      headers: setHeaders(),
      reportId: reportId,
      value: value
    }
  );

  return dispatch({
    type: copyrightConstants.LOCK_REPORT_COPYRIGHT,
    payload: promise
  });
};

export const fetchHistoryFromComment = (
  clientShortName,
  commentId,
  options = {}
) => dispatch => {
  const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/comments/${commentId}/history`;
  const promise = axios.get(url, {
    headers: setHeaders(),
    commentId: commentId,
    index: options.index ? options.index : null
  });

  return dispatch({
    type: copyrightConstants.FETCH_HISTORY_FROM_VIDEO_COPYRIGHT,
    payload: promise
  });
};

export const updateTranslation = (
  clientShortName,
  commentId,
  options = {}
) => dispatch => {
  const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/comments/${commentId}/message-translation`;
  const promise = axios.patch(
    url,
    {},
    {
      headers: setHeaders(),
      index: options.index ? options.index : null
    }
  );

  return dispatch({
    type: copyrightConstants.UPDATE_CLIENTS_VIDEOS_TRANSLATION_COPYRIGHT,
    payload: promise
  });
};

export const fetchAdsFromPost = (
  clientShortName,
  postId,
  options = {}
) => dispatch => {
  const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/posts/${postId}/ads`;
  const promise = axios.get(url, {
    headers: setHeaders(),
    index: options.index !== undefined ? options.index : null
  });

  return dispatch({
    type: copyrightConstants.FETCH_CLIENTS_POST_ADS_COPYRIGHT,
    payload: promise
  });
};

export const processVideo = (
  clientShortName,
  commentId,
  data = {},
  options = {}
) => dispatch => {
  const url = `${process.env.URL_API_ENDPOINT}/copyright/clients/${clientShortName}/comments/${commentId}/process`;

  const promise = axios.post(url, data, {
    headers: setHeaders(),
    videoData: data,
    index: options.index !== undefined ? options.index : null
  });

  return dispatch({
    type: copyrightConstants.PROCESS_COPYRIGHT_TO_VIDEO,
    payload: promise
  });
};

export const approveVideo = (
  clientShortName,
  commentId,
  data = {},
  options = {}
) => dispatch => {
  const url = `${process.env.URL_API_ENDPOINT}/copyright/clients/${clientShortName}/comments/${commentId}/approve`;

  const promise = axios.post(url, data, {
    headers: setHeaders(),
    videoData: data,
    index: options.index !== undefined ? options.index : null
  });

  return dispatch({
    type: copyrightConstants.APPROVE_COPYRIGHT_TO_VIDEO,
    payload: promise
  });
};

export const removeVideo = (
  clientShortName,
  commentId,
  options = {}
) => dispatch => {
  const url = `${process.env.URL_API_ENDPOINT}/copyright/clients/${clientShortName}/comments/${commentId}`;
  const promise = axios.delete(url, {
    headers: setHeaders(),
    index: options.index !== undefined ? options.index : null
  });

  return dispatch({
    type: copyrightConstants.REMOVE_COPYRIGHT_TO_VIDEO,
    payload: promise
  });
};

//WEBSOCKET ACTIONS
export const updatesCopyrightStats = data => ({
  type: 'UPDATES_COPYRIGHT_STATS',
  data: data
});
