import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";

import InputComponent from "../../commons/input/inputComponent";
import Textarea from "../../commons/textarea/textareaComponent";

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  formControlContainer: {
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
  formGroup: {
    width: "50%",
  },
  formGroupEditor: {
    marginTop: theme.spacing(6),
    width: "50%",
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
  },
  tag: {
    margin: 2,
  },
  errorText: {
    color: "#e06666",
  },
});

class ActionsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      checkFilter: props.checkFilter,
      clientTags: props.clientTags,
      typeList: props.typeList,
      tagList: props.tagList || [],
      jsonText:
        props.defaultValue && props.defaultValue.filters
          ? JSON.stringify(props.defaultValue.filters, null, 2)
          : "",
      data: props.defaultValue
        ? props.defaultValue
        : {
            id: "",
            type: "",
            filters: [],
            time_interval: "",
            timeout: "",
            tags: [],
            replier_asset_id: "",
          },
      replierAssetsList: props.replierAssetsList,
      error: props.error
        ? props.error
        : {
            id: "",
            type: "",
            filters: "",
            tags: "",
            replier_asset_id: "",
          },
    };
  }

  onChangeEditor(event) {
    const newText = event.target.value;

    try {
      const parsedData = JSON.parse(newText);

      this.setState(
        {
          jsonText: newText,
          data: {
            ...this.state.data,
            filters: parsedData,
          },
          error: {
            ...this.state.error,
            filters: null,
          },
        },
        () => {
          this.props.onChangeActionForm(this.state.data);
        }
      );
    } catch (parseError) {
      this.setState(
        {
          jsonText: newText,
          data: {
            ...this.state.data,
            filters: null,
          },
          error: {
            ...this.state.error,
            filters: parseError.message,
          },
        },
        () => {
          this.props.onErrorActionForm();
        }
      );
    }
  }

  setValue(e, value) {
    this.setState(
      {
        data: {
          ...this.state.data,
          [value]: e.target.value,
        },
      },
      () => {
        this.props.onChangeActionForm(this.state.data);
      }
    );
  }

  resetOptionalValues(e, value) {
    this.setState(
      {
        data: {
          ...this.state.data,
          time_interval: "",
          timeout: "",
          tags: [],
        },
      },
      () => {
        this.setValue(e, value);
      }
    );
  }

  onChange(e, value) {
    if (value === "type" && e.target.value !== "check_alert") {
      this.resetOptionalValues(e, value);
    } else {
      this.setValue(e, value);
    }
  }

  displayTextField(value, label, required = false, isDisabled = false) {
    const { classes, defaultValue, mode } = this.props;
    const { data, error } = this.state;
    return (
      <FormGroup className={classes.formGroup}>
        <InputComponent
          label={label}
          required={required}
          disabled={isDisabled && mode === "edit" ? true : false}
          defaultValue={
            Object.values(defaultValue).length
              ? defaultValue[value]
              : data[value]
          }
          onChange={(e) => {
            return this.onChange(e, value);
          }}
          error={error[value] ? true : false}
          helperText={error[value]}
          margin="dense"
          fullWidth={true}
        />
      </FormGroup>
    );
  }

  displaySelect() {
    const { classes } = this.props;
    const { data, typeList, error } = this.state;
    return (
      <FormGroup className={classes.formGroup}>
        <InputLabel>Type *</InputLabel>
        <Select
          error={error["type"] ? true : false}
          value={data.type}
          onChange={(e) => {
            return this.onChange(e, "type");
          }}
          input={<Input id="type" />}
        >
          {typeList ? (
            typeList.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))
          ) : (
            <MenuItem value={"none"}>{"none"}</MenuItem>
          )}
        </Select>
        {error["type"] ? <FormHelperText>{error["type"]}</FormHelperText> : ""}
      </FormGroup>
    );
  }

  displayTWReplierAssetSelect() {
    const { classes } = this.props;
    const { data, replierAssetsList, error } = this.state;

    return (
      <FormGroup className={classes.formGroup}>
        <InputLabel>TW handle</InputLabel>
        <Select
          error={error["replier_asset_id"] ? true : false}
          value={data.replier_asset_id}
          onChange={(e) => {
            return this.onChange(e, "replier_asset_id");
          }}
          input={<Input id="replier_asset_id" />}
        >
          {replierAssetsList ? (
            replierAssetsList.map((replierAsset) => (
              <MenuItem key={replierAsset.id} value={replierAsset.id}>
                {replierAsset.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem value={"none"}>{"none"}</MenuItem>
          )}
        </Select>
        {error["replier_asset_id"] ? (
          <FormHelperText>{error["replier_asset_id"]}</FormHelperText>
        ) : (
          ""
        )}
      </FormGroup>
    );
  }

  displayFilters() {
    const { classes } = this.props;
    const { error } = this.state;

    return (
      <FormGroup className={classes.formGroupEditor}>
        <InputLabel>Filters</InputLabel>
        <Textarea
          id={"filters"}
          rows={12}
          rowsMax={12}
          value={this.state.jsonText}
          margin={"dense"}
          onChange={(e) => {
            return this.onChangeEditor(e);
          }}
        />
        {error["filters"] ? (
          <FormHelperText className={classes.errorText}>
            {error["filters"]}
          </FormHelperText>
        ) : (
          ""
        )}
      </FormGroup>
    );
  }

  displayMultipleSelect() {
    const { classes } = this.props;
    const { data, tagList, clientTags, error } = this.state;
    return (
      <FormGroup className={classes.formGroup}>
        <InputLabel>Tags *</InputLabel>
        <Select
          multiple
          error={error["tags"] ? true : false}
          value={data.tags}
          onChange={(e) => {
            return this.onChange(e, "tags");
          }}
          input={<Input id="tags" />}
          renderValue={(selected) => (
            <div className={classes.tags}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={
                    tagList.find((tag) => value === tag.id)
                      ? tagList.find((tag) => value === tag.id)["label"]
                      : value
                  }
                  className={classes.tag}
                />
              ))}
            </div>
          )}
        >
          {clientTags.map((tag) => {
            const item = tagList.find((i) => tag === i.id) || tag;
            return (
              <MenuItem
                key={item.id ? item.id : item}
                value={item.id ? item.id : item}
              >
                {item.label ? item.label : item}
              </MenuItem>
            );
          })}
        </Select>
        {error["tags"] ? <FormHelperText>{error["tags"]}</FormHelperText> : ""}
      </FormGroup>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.typeList !== this.props.typeList) {
      this.setState({
        typeList: this.props.typeList,
      });
    }

    if (prevProps.replierAssetsList !== this.props.replierAssetsList) {
      this.setState({
        replierAssetsList: this.props.replierAssetsList,
      });
    }

    if (prevProps.checkFilter !== this.props.checkFilter) {
      this.setState({
        checkFilter: this.props.checkFilter,
      });
    }

    if (prevProps.error !== this.props.error) {
      this.setState({
        error: this.props.error,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { loading, data } = this.state;
    return (
      <div className={classes.root}>
        {loading ? (
          <CircularProgress />
        ) : (
          <div className={classes.formControlContainer}>
            <FormControl className={classes.formControl}>
              {this.displayTextField("id", "ID", true, true)}
            </FormControl>
            <FormControl className={classes.formControl}>
              {this.displaySelect()}
            </FormControl>
            <FormControl className={classes.formControl}>
              {this.displayMultipleSelect()}
            </FormControl>
            {data.type === "reply" && (
              <FormControl className={classes.formControl}>
                {this.displayTWReplierAssetSelect()}
              </FormControl>
            )}
            {data.type === "check_alert" && (
              <FormControl className={classes.formControl}>
                {this.displayTextField(
                  "time_interval",
                  "Time Interval",
                  true,
                  false
                )}
                {this.displayTextField("timeout", "Timeout", true, false)}
              </FormControl>
            )}
            <FormControl className={classes.formControl}>
              {this.displayFilters()}
            </FormControl>
          </div>
        )}
      </div>
    );
  }
}

ActionsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onChangeActionForm: PropTypes.func.isRequired,
  onErrorActionForm: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  checkFilter: PropTypes.bool,
  error: PropTypes.object,
  typeList: PropTypes.array,
  tagList: PropTypes.array,
  replierAssetsList: PropTypes.array,
  mode: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(ActionsForm);
