import { GGConstants } from '../../constants/configuration/GGConstants';

import { extractGuidelineGroupList } from '../../extractors/guidelineGroupsExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  guidelineGroupsList: [],
  error: null
};

const guidelineGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_GUIDELINE_GROUPS_LIST
    case `${GGConstants.FETCH_GUIDELINE_GROUPS_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${GGConstants.FETCH_GUIDELINE_GROUPS_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${GGConstants.FETCH_GUIDELINE_GROUPS_LIST}_FULFILLED`:
      const guidelineGroupsList = extractGuidelineGroupList(
        action.payload.data
      );
      return {
        ...state,
        fetching: false,
        fetched: true,
        guidelineGroupsList
      };

    // ADD_GUIDELINE_GROUP
    case `${GGConstants.ADD_GUIDELINE_GROUP}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${GGConstants.ADD_GUIDELINE_GROUP}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${GGConstants.ADD_GUIDELINE_GROUP}_FULFILLED`:
      const addedGuidelineGroup = action.payload.config.addData;
      addedGuidelineGroup.id = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        guidelineGroupsList: [addedGuidelineGroup, ...state.guidelineGroupsList]
      };

    // UPDATE_GUIDELINE_GROUP
    case `${GGConstants.UPDATE_GUIDELINE_GROUP}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${GGConstants.UPDATE_GUIDELINE_GROUP}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${GGConstants.UPDATE_GUIDELINE_GROUP}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        guidelineGroupsList: Object.assign([], {
          ...state.guidelineGroupsList,
          [action.payload.config.index]: {
            ...state.guidelineGroupsList[action.payload.config.index],
            name: action.payload.config.editData.name,
            asset_ids: action.payload.config.editData.asset_ids
          }
        })
      };
    default:
      return state;
  }
};

export default guidelineGroupsReducer;
