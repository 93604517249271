import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';

import AppBar from '../../components/commons/appBar/AppBarComponent';
import LeftMenu from '../../components/commons/leftMenu/leftMenuComponent';
import SubHeader from '../../components/commons/subHeader/subHeaderComponent';
import AttemptsList from '../../components/attemptsList/attemptsListComponent';
import AlertDialog from '../../components/commons/dialog/alertDialogComponent';
import DialogContent from '../../components/commons/dialog/contentDialogComponent';
import InputComponent from '../../components/commons/input/inputComponent';

import { fetchMe } from '../../actions/meAction';
import {
  fetchAttemptsList,
  resetAttemptsList,
  restartAnalysis,
  convertClient
} from '../../actions/attemptsAction';

const styles = theme => ({
  root: {
    display: 'flex'
  },
  content: {
    width: '100%'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    height: 48
  },
  notAllowed: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)'
  },
  loading: {
    margin: 'auto',
    color: theme.palette.orange[300],
    marginTop: 'calc(50vh - 96px)'
  },
  topControlsContainer: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(8),
    display: 'inline-block'
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.black
  },
  formControl: {
    width: '100%'
  },
  formGroup: {
    width: '80%'
  },
  buttonsContainer: {
    marginTop: theme.spacing(3),
    textAlign: 'right'
  },
  logoConvertClient: {
    marginBottom: theme.spacing(3)
  },
  title: {
    margin: theme.spacing(0, 0, 1, 0)
  },
  formControlFilter: {
    minWidth: 185,
    marginLeft: theme.spacing(8)
  },
  buttonRefresh: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    color: theme.palette.black
  },
  refreshText: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    marginLeft: theme.spacing(8)
  }
});

const mapStateToProps = state => ({
  me: state.meReducer,
  attempts: state.attemptsReducer
});

const mapDispatchToProps = dispatch => ({
  fetchMe: () => dispatch(fetchMe()),
  fetchAttemptsList: (saleId, options) =>
    dispatch(fetchAttemptsList(saleId, options)),
  resetAttemptsList: () => dispatch(resetAttemptsList()),
  restartAnalysis: id => dispatch(restartAnalysis(id)),
  convertClient: (id, data) => dispatch(convertClient(id, data))
});

class AttemptsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      open: false,
      openRestartAnalysisDialog: false,
      openDialogConvertClient: false,
      openDialogGoToClientConfig: false,
      clientConverted: false,
      itemSelected: {},
      convertData: {
        logo: '',
        industry: '',
        clientShortName: '',
        name: ''
      },
      redirectSales: false,
      redirectExplorer: false,
      redirectAnalysis: false,
      redirectAdmin: false,
      saleId:
        props.location.state && props.location.state.id
          ? props.location.state.id
          : window.location.pathname
              .replace('/sales/', '')
              .replace('/attempts', ''),
      notAllowed: false,
      list: [],
      isLoading: true,
      isFiltered: false,
      max_attempts: null,
      used_attempts: null,
      activeStatusFilter: '',
      activeStepFilter: '',
      searchValue: ''
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.attempts.list !== prevState.list && !prevState.isFiltered) {
      const { attempts } = nextProps;
      update.list = attempts.list;
      update.max_attempts = attempts.max_attempts;
      update.used_attempts = attempts.used_attempts;
    } else if (prevState.isFiltered) {
      update.isFiltered = false;
    }

    return Object.keys(update).length ? update : null;
  }

  applyFilters(activeStatusFilter) {
    if (activeStatusFilter !== '' && this.state.activeStepFilter !== '') {
      let newList = [...this.state.list];
      newList = newList.filter(item => {
        const step = item.steps.find(
          step => step.id === this.state.activeStepFilter
        );
        return step.status === activeStatusFilter;
      });
      this.setState({
        list: newList,
        isFiltered: true,
        isLoading: false
      });
    }
  }

  onFilterStepChange(activeStepFilter) {
    this.setState(
      {
        activeStepFilter
      },
      () => {
        if (activeStepFilter !== '') {
          this.applyFilters(this.state.activeStatusFilter);
        }
      }
    );
  }

  onFilterStatusChange(activeStatusFilter) {
    this.setState(
      {
        activeStatusFilter
      },
      () => {
        this.applyFilters(activeStatusFilter);
      }
    );
  }

  refreshAttemptsList() {
    this.setState({
      isLoading: true
    });
    this.props
      .fetchAttemptsList(this.state.saleId, {
        search: this.state.searchValue
      })
      .then(() => {
        this.applyFilters(this.state.activeStatusFilter);
      });
  }

  displayStepFilter() {
    return (
      <FormGroup className={this.props.classes.formGroup}>
        <InputLabel>Filter Step</InputLabel>
        <Select
          value={this.state.activeStepFilter}
          onChange={e => {
            this.onFilterStepChange(e.target.value);
          }}
        >
          <MenuItem value="">None</MenuItem>
          <MenuItem value="wizard_business_profile">
            Wizard (Business Profile)
          </MenuItem>
          <MenuItem value="wizard_grant_access">Wizard (Grant Access)</MenuItem>
          <MenuItem value="wizard_finish">Wizard (Finish)</MenuItem>
          <MenuItem value="crawling">Crawling</MenuItem>
          <MenuItem value="clara">Clara</MenuItem>
          <MenuItem value="report">Report</MenuItem>
        </Select>
      </FormGroup>
    );
  }

  displayStatusFilter() {
    return (
      <FormGroup className={this.props.classes.formGroup}>
        <InputLabel>Filter By Status</InputLabel>
        <Select
          value={this.state.activeStatusFilter}
          onChange={e => {
            this.onFilterStatusChange(e.target.value);
          }}
          disabled={this.state.activeStepFilter === ''}
        >
          <MenuItem value="">None</MenuItem>
          <MenuItem value="started">Started</MenuItem>
          <MenuItem value="finished">Finished</MenuItem>
          <MenuItem value="not_started">Not Started</MenuItem>
          <MenuItem value="failed">Failed</MenuItem>
        </Select>
      </FormGroup>
    );
  }

  logout() {
    this.props.signOut();
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  searchResult(value) {
    this.setState({
      isLoading: true,
      searchValue: value
    });
    this.props
      .fetchAttemptsList(this.state.saleId, {
        search: value
      })
      .then(() => {
        this.applyFilters(this.state.activeStatusFilter);
      });
  }

  actionAttempt(e, item, actionName) {
    if (actionName === 'restart_analysis') {
      this.setState({
        openRestartAnalysisDialog: true,
        itemSelected: item.id
      });
    }

    if (actionName === 'convert_to_client') {
      this.setState({
        openDialogConvertClient: true,
        itemSelected: item.id,
        convertData: {
          logo: item.brand_logo,
          industry: item.industry,
          clientShortName: item.client ? item.client.client_short_name : '',
          name: item.brand_name ? item.brand_name : ''
        }
      });
    }
  }

  onChange(e, id) {
    this.setState({
      convertData: {
        ...this.state.convertData,
        [id]: e.target.value
      }
    });
  }

  restartAnalysis(status) {
    if (status) {
      this.props.restartAnalysis(this.state.itemSelected);
    }

    this.setState({
      openRestartAnalysisDialog: false
    });
  }

  componentDidMount() {
    this.props.fetchMe().then(response => {
      if (response.value.data.permissions.includes('sales_access')) {
        this.props.fetchAttemptsList(this.state.saleId).then(() => {
          this.setState({
            isLoading: false
          });
        });
      } else {
        this.setState({
          notAllowed: true
        });
      }
    });
  }

  redirectSales() {
    this.setState({
      redirectSales: true
    });
  }

  breadcrumbs(saleId) {
    const { classes } = this.props;
    return (
      <Breadcrumbs aria-label="Breadcrumb">
        <Link className={classes.link} onClick={this.redirectSales.bind(this)}>
          sales
        </Link>
        <Typography>{saleId}</Typography>
      </Breadcrumbs>
    );
  }

  componentWillUnmount() {
    this.props.resetAttemptsList();
  }

  closeDialogConvertClient() {
    this.setState({
      openDialogConvertClient: false
    });
  }

  convertToClient() {
    this.props
      .convertClient(this.state.itemSelected, this.state.convertData)
      .then(() => {
        this.setState({
          openDialogGoToClientConfig: true,
          clientConverted: true
        });
      });

    this.setState({
      openDialogConvertClient: false
    });
  }

  redirectCLientConfig(status) {
    if (status) {
      this.setState({
        redirectAdmin: true
      });
    }

    this.setState({
      openDialogConvertClient: false
    });
  }

  formClient(data) {
    const { classes } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <Avatar
          className={classes.logoConvertClient}
          src={data.logo ? data.logo : ''}
        />
        <FormGroup className={classes.formGroup}>
          <InputComponent
            id={'clientShortName'}
            disabled={false}
            defaultValue={data.clientShortName}
            label={'Client Short Name'}
            margin="dense"
            fullWidth={true}
            onChange={(e, id) => this.onChange(e, id)}
          />
          <InputComponent
            id={'industry'}
            disabled={true}
            defaultValue={data.industry}
            label={'Industry'}
            margin="dense"
            fullWidth={true}
          />
          <InputComponent
            id={'name'}
            defaultValue={data.name}
            label={'Name'}
            margin="dense"
            fullWidth={true}
            onChange={(e, id) => this.onChange(e, id)}
          />
        </FormGroup>
      </FormControl>
    );
  }

  displayButtonsConvertClient() {
    const { classes } = this.props;
    const { convertData } = this.state;
    return (
      <div className={classes.buttonsContainer}>
        <Button
          size="small"
          color="primary"
          disabled={!convertData.name || !convertData.clientShortName}
          onClick={() => this.convertToClient()}
        >
          {'Convert to Client'}
        </Button>
        <Button
          onClick={() => this.closeDialogConvertClient()}
          size="small"
          color="secondary"
        >
          Cancel
        </Button>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const {
      open,
      list,
      isLoading,
      used_attempts,
      notAllowed,
      saleId,
      redirectSales,
      clientConverted,
      redirectAdmin,
      convertData,
      openRestartAnalysisDialog,
      openDialogConvertClient,
      openDialogGoToClientConfig
    } = this.state;

    if (redirectAdmin) {
      return (
        <Redirect
          to={{
            pathname: `/admin`,
            state: {
              hasDefaultValue: 4
            }
          }}
        />
      );
    }

    if (redirectSales) {
      return (
        <Redirect
          to={{
            pathname: `/sales`
          }}
        />
      );
    }

    return (
      <div className={classes.root}>
        <AppBar
          action={this.handleDrawerOpen}
          status={open}
          selected={'sales'}
          logout={this.logout.bind(this)}
        />
        <LeftMenu open={open} onClose={this.handleDrawerClose.bind(this)} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {!notAllowed ? (
            <div>
              <SubHeader searchResult={this.searchResult.bind(this)} />
              <Grid className={classes.grid} container>
                <Grid item xs={12} style={{ textAlign: 'left' }}>
                  <div className={classes.topControlsContainer}>
                    {this.breadcrumbs(saleId)}
                  </div>
                  <div className={classes.topControlsContainer}>
                    <div
                      style={{
                        transform: 'translate(0, -6px)'
                      }}
                    >
                      <FormControl className={classes.formControlFilter}>
                        {this.displayStepFilter()}
                      </FormControl>
                      <FormControl className={classes.formControlFilter}>
                        {this.displayStatusFilter()}
                      </FormControl>
                      <p className={classes.refreshText}>Refresh: </p>
                      <IconButton
                        className={classes.buttonRefresh}
                        onClick={e => this.refreshAttemptsList()}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid className={classes.grid} container>
                <Grid item xs={2} />
                <Grid item xs={8}>
                  <div className={classes.title}>
                    <Typography variant="h5">
                      Attempts: {used_attempts}
                      {!isLoading && ', Filtered: ' + list.length}
                    </Typography>
                  </div>
                  <AttemptsList
                    list={list}
                    isLoading={isLoading}
                    clientConverted={clientConverted}
                    action={this.actionAttempt.bind(this)}
                  />
                </Grid>
                <Grid item xs={2} />
              </Grid>
            </div>
          ) : (
            <Typography className={classes.notAllowed} variant="body1">
              Not Allowed
            </Typography>
          )}
        </main>
        <AlertDialog
          title="Restart analysis"
          message={'Do you want to restart this analysis?'}
          buttonAcceptText="Yes"
          buttonCancelText="No"
          action={status => this.restartAnalysis(status)}
          open={openRestartAnalysisDialog}
        />
        <AlertDialog
          title="Continue the configuration"
          message={'Do you want to go to the client configuration?'}
          buttonAcceptText="Yes"
          buttonCancelText="No"
          action={status => this.redirectCLientConfig(status)}
          open={openDialogGoToClientConfig}
        />
        <DialogContent
          title={'Convert to Client'}
          open={openDialogConvertClient}
          close={this.closeDialogConvertClient.bind(this)}
          fullWidth={true}
        >
          <div>
            {this.formClient(convertData)}
            {this.displayButtonsConvertClient()}
          </div>
        </DialogContent>
      </div>
    );
  }
}

AttemptsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AttemptsPage));
