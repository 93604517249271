import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
  root: {
    marginRight: 15,
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
  inputWidth: {
    marginTop: 0,
    width: "100%",
  },
});
class Textarea extends Component {
  handleChange(e, id) {
    this.props.onChange(e, id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  render() {
    const {
      classes,
      value,
      id,
      rows,
      rowsMax,
      label,
      margin,
      handleBlur,
      handleFocus,
    } = this.props;

    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <TextField
            autoComplete="off"
            className={classes.inputWidth}
            id={id}
            multiline
            rows={rows || "3"}
            rowsMax={rowsMax || "3"}
            label={label}
            value={value ? value : ""}
            margin={margin ? margin : "normal"}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(e) => {
              return this.handleChange(e, id);
            }}
          />
        </FormControl>
      </div>
    );
  }
}

Textarea.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  margin: PropTypes.string,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  onChange: PropTypes.func,
};

export default withStyles(styles)(Textarea);
