import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import ClientsConfiguration from "./clientsConfiguration";

import { fetchIndustryList } from "../../../actions/industriesAction";
import { fetchPlanList } from "../../../actions/plansAction";
import { fetchTagsList } from "../../../actions/configuration/tagsAction";
import {
  fetchClientList,
  addClient,
  editClient,
} from "../../../actions/configuration/clientsAction";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  loading: {
    margin: "auto",
    marginTop: "calc(50vh - 96px)",
    color: theme.palette.orange[300],
  },
  notAllowed: {
    margin: "auto",
    height: "calc(100vh - 367px)",
    marginTop: "calc(50vh - 96px)",
  },
});

const mapStateToProps = (state) => ({
  tags: state.tagsReducer,
  industries: state.industriesReducer,
  plans: state.plansReducer,
  clients: state.clientsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchIndustryList: () => dispatch(fetchIndustryList()),
  fetchPlanList: () => dispatch(fetchPlanList()),
  fetchTagsList: () => dispatch(fetchTagsList()),
  fetchClientList: () => dispatch(fetchClientList()),
  addClient: (data) => dispatch(addClient(data)),
  editClient: (index, data) => dispatch(editClient(index, data)),
});

class ClientsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
      loading: true,
      notAllowed: false,
      creatable: false,
      editable: false,
      taskHistoryViewable: false,
      channelWhitelistViewable: false,
      clientList: [],
      tagList: [],
      industryList: [],
      planList: [],
    };

    window.history.replaceState({}, "admin", "/admin");
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.tags.tagList !== prevState.tagList) {
      update.tagList = nextProps.tags.tagList;
    }

    if (nextProps.clients.clientList !== prevState.clientList) {
      update.clientList = nextProps.clients.clientList;
    }

    if (nextProps.industries.industryList !== prevState.industryList) {
      update.industryList = nextProps.industries.industryList;
    }

    if (nextProps.plans.planList !== prevState.planList) {
      update.planList = nextProps.plans.planList;
    }

    return Object.keys(update).length ? update : null;
  }

  addClient(data) {
    this.props.addClient(data);
  }

  editClient(index, data) {
    this.props.editClient(index, data);
  }

  initialize() {
    this.props.fetchIndustryList().then(() => {
      this.props.fetchPlanList().then(() => {
        this.props.fetchTagsList().then(() => {
          if (this.props.permissions.includes("admin_list_clients")) {
            this.props.fetchClientList().then(() => {
              this.setState({
                loading: false,
              });
            });
            if (this.props.permissions.includes("admin_update_client")) {
              this.setState({
                editable: true,
              });
            }
            if (this.props.permissions.includes("admin_create_client")) {
              this.setState({
                creatable: true,
              });
            }
            if (
              this.props.permissions.includes(
                "copyright_admin_channel_whitelist_get"
              )
            ) {
              this.setState({
                channelWhitelistViewable: true,
              });
            }
            if (
              this.props.permissions.includes("copyright_admin_get_history")
            ) {
              this.setState({
                taskHistoryViewable: true,
              });
            }
          } else {
            this.setState({
              notAllowed: true,
              loading: false,
            });
          }
        });
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tabIndex === 4 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true,
        },
        this.initialize
      );
    }
  }

  componentDidMount() {
    if (this.props.tabIndex === 4 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true,
        },
        this.initialize
      );
    }
  }

  render() {
    const { classes } = this.props;
    const {
      loading,
      notAllowed,
      clientList,
      tagList,
      industryList,
      planList,
      editable,
      taskHistoryViewable,
      channelWhitelistViewable,
      creatable,
    } = this.state;

    return (
      <div className={classes.root}>
        {!notAllowed && !loading && tagList.length ? (
          <ClientsConfiguration
            clientList={clientList}
            editable={editable}
            creatable={creatable}
            taskHistoryViewable={taskHistoryViewable}
            channelWhitelistViewable={channelWhitelistViewable}
            tagList={tagList}
            industryList={industryList}
            planList={planList}
            addClient={this.addClient.bind(this)}
            editClient={this.editClient.bind(this)}
          />
        ) : !notAllowed ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <Typography className={classes.notAllowed} variant="body1">
            Not Allowed
          </Typography>
        )}
      </div>
    );
  }
}

ClientsContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ClientsContainer));
