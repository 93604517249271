import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import StreamsConfiguration from './streamsConfiguration';

import {
  fetchStreamList,
  addStream,
  editStream
} from '../../../actions/configuration/streamsAction';
import { fetchTagsList } from '../../../actions/configuration/tagsAction';
import { fetchUserList } from '../../../actions/configuration/usersConfigurationAction';

const styles = theme => ({
  root: {
    width: '100%'
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 440px)',
    marginTop: 'calc(50vh - 96px)'
  }
});

const mapStateToProps = state => ({
  tags: state.tagsReducer,
  rolesAndPermissionsReducer: state.rolesAndPermissionsReducer,
  streams: state.streamsConfigurationReducer,
  users: state.usersConfigurationReducer
});

const mapDispatchToProps = dispatch => ({
  fetchTagsList: () => dispatch(fetchTagsList()),
  fetchStreamList: () => dispatch(fetchStreamList()),
  fetchUserList: () => dispatch(fetchUserList()),
  addStream: data => dispatch(addStream(data)),
  editStream: (index, data) => dispatch(editStream(index, data))
});

class StreamsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
      loading: true,
      notAllowed: false,
      creatable: props.creatable,
      editable: props.editable,
      tagList: [],
      userList: [],
      roleList: [],
      streamList: []
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.tags.tagList !== prevState.tagList) {
      update.tagList = nextProps.tags.tagList;
    }

    if (nextProps.rolesAndPermissionsReducer.roleList !== prevState.roleList) {
      update.roleList = nextProps.rolesAndPermissionsReducer.roleList;
    }

    if (nextProps.streams.streamList !== prevState.streamList) {
      update.streamList = nextProps.streams.streamList;
    }

    if (nextProps.users.userList !== prevState.userList) {
      update.userList = nextProps.users.userList;
    }

    return Object.keys(update).length ? update : null;
  }

  addStream(data) {
    this.props.addStream(data);
  }

  editStream(index, data) {
    this.props.editStream(index, data);
  }

  initialize() {
    if (this.props.permissions.includes('admin_list_tags')) {
      this.props.fetchUserList().then(() => {
        this.props.fetchTagsList().then(() => {
          this.setState({
            loading: false
          });
          if (this.props.permissions.includes('admin_list_streams')) {
            this.props.fetchStreamList();
          }
        });
      });

      if (this.props.permissions.includes('admin_update_stream')) {
        this.setState({
          editable: true
        });
      }
      if (this.props.permissions.includes('admin_create_stream')) {
        this.setState({
          creatable: true
        });
      }
    } else {
      this.setState({
        notAllowed: true,
        loading: false
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tabIndex === 5 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true
        },
        this.initialize
      );
    }
  }

  componentDidMount() {
    if (this.props.tabIndex === 5 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true
        },
        this.initialize
      );
    }
  }

  render() {
    const { classes } = this.props;
    const {
      loading,
      notAllowed,
      editable,
      creatable,
      streamList,
      roleList,
      userList,
      tagList
    } = this.state;

    return (
      <div className={classes.root}>
        {!notAllowed && !loading ? (
          <StreamsConfiguration
            editable={editable}
            creatable={creatable}
            addStream={this.addStream.bind(this)}
            editStream={this.editStream.bind(this)}
            streamList={streamList}
            roleList={roleList}
            tagList={tagList}
            userList={userList}
          />
        ) : !notAllowed ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <Typography className={classes.notAllowed} variant="body1">
            Not Allowed
          </Typography>
        )}
      </div>
    );
  }
}

StreamsContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  tabIndex: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(StreamsContainer));
