export const setModerationGuidelines = (mg, key = null) => {
  let item = {
    tag: {
      id: mg.tag.id,
      value: mg.tag.label
    }
  };

  let data = mg.configs.map(i => {
    let keys = Object.keys(i);
    keys = keys.filter(n => {
      return n !== 'guideline_group';
    });

    let value = keys
      .map(k => {
        let value = k.replace('_action', '');
        value = value.charAt(0).toUpperCase() + value.slice(1);
        return i[k].enabled === true ? value : '';
      })
      .filter(i => i !== '')
      .join(', ');

    let config = {
      id: i.guideline_group.id,
      name: i.guideline_group.name,
      competitor_description: i.competitor_description,
      value: value.length ? value : 'Leave Visible',
      alert: keys
        .map(k => {
          return k === 'alert_action'
            ? {
                emails: i[k].emails ? i[k].emails : []
              }
            : {};
        })
        .find(value => Object.keys(value).length !== 0),
      exceptions: keys
        .map(k => {
          return i[k].enabled === true
            ? {
                name: k,
                value: i[k].exception_tag_ids
              }
            : {};
        })
        .filter(value => Object.keys(value).length !== 0)
    };

    if (i.volume_action) {
      config = {
        ...config,
        volume: keys
          .map(k => {
            return k === 'volume_action'
              ? {
                  enabled: i[k].enabled,
                  threshold: i[k].threshold ? i[k].threshold : 1,
                  time_interval: i[k].time_interval ? i[k].time_interval : 3600,
                  timeout: i[k].timeout ? i[k].timeout : 300,
                  emails: i[k].emails ? i[k].emails : []
                }
              : {};
          })
          .filter(value => Object.keys(value).length !== 0)
      };
    }

    return config;
  });

  let object = data.reduce((obj, i) => {
    let config = {
      [i.id]: {
        guideline_name: i.name,
        alert: i.alert,
        value: i.value,
        competitor_description: i.competitor_description,
        exceptions: i.exceptions
      }
    };

    if (i.volume && i.volume[0]) {
      config = {
        ...config,
        [i.id]: {
          ...config[i.id],
          volume: i.volume[0]
        }
      };
    }
    return Object.assign(obj, config);
  }, {});

  item = {
    ...item,
    ...object
  };

  return item;
};
