import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

class OAuthLoader extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <CircularProgress size={24} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(OAuthLoader);
