import { setPost } from "./post";
import { setTagsListFromComment, setSentimentFromTags } from "./tags";
import { setDateFormat } from "./date";

export const setComment = (comment, stream, key = null) => {
  const item = {
    isLiked: comment && comment.isLiked ? comment.isLiked : false,
    isDeleted: comment ? comment.isDeleted : false,
    isHidden: comment ? comment.isHidden : false,
    isUnsure: comment ? comment.isUnsure : false,
    isVisitorPost: comment ? comment.isVisitorPost : false,
    isOwned: comment ? comment.isOwned : false,
    isFavorite: comment ? comment.isFavorite : false,
    id: comment ? comment.id : "",
    actionOutsideOfCamp:
      comment && comment.actionOutsideOfCamp
        ? comment.actionOutsideOfCamp
        : null,
    client:
      comment && comment.client
        ? comment.client
        : {
            name: "Unknown",
            shortName: "Unknown",
            logo: "",
          },
    objectId: comment ? comment.objectId : "",
    key: key,
    cannotBeLoaded: comment ? comment.cannotBeLoaded : false,
    postId: comment ? comment.postId : "",
    post: comment && comment.post ? setPost(comment.post) : null,
    attachments: comment ? comment.attachments : [],
    createdTime: comment ? comment.createdTime : "",
    createdTimePretty: comment ? comment.createdTimePretty : "",
    date: setDateFormat(
      comment && comment.createdTimePretty
        ? comment.createdTimePretty
        : "0000-00-00T00:00:00"
    ),
    startTime: 0,
    endTime: 0,
    message: comment && comment.message !== "" ? comment.message : "empty",
    messageTranslated:
      comment && comment.messageTranslated !== ""
        ? comment.messageTranslated
        : "empty",
    userName:
      (comment && comment.userName) !== "" ? comment.userName : "unknown",
    parent:
      comment && comment.parent
        ? {
            ...comment.parent,
            alerts:
              comment.parent && comment.parent.actions
                ? comment.parent.actions
                : [],
            date: setDateFormat(
              comment && comment.parent && comment.parent.createdTimePretty
                ? comment.parent.createdTimePretty
                : "0000-00-00T00:00:00"
            ),
          }
        : {},
    platformName: comment ? comment.platformName : "",
    pageId: comment ? comment.pageId : "",
    commentUrl: comment ? comment.commentUrl : "",
    viewCount: comment ? comment.view_count : "0",
    reply_username:
      comment && comment.reply_username ? comment.reply_username : "",
    classified: false,
    isClassifying: false,
    streamId: stream && stream.id ? stream.id : null,
    sentiment:
      comment && comment.tags && comment.tags.length > 0
        ? setSentimentFromTags(comment.tags)
        : "neutral",
    alerts: comment && comment.actions ? comment.actions : [],
    alert: comment && comment.alert ? comment.alert : undefined,
    highlightedTags: stream.highlighted_tags ? stream.highlighted_tags : [],
    tagCategories:
      comment && comment.tagCategories ? comment.tagCategories : [],
    tags:
      comment && comment.tagConfigs
        ? setTagsListFromComment(comment.tagConfigs).sort((a, b) =>
            a.group > b.group ? 1 : -1
          )
        : [],
    history: [],
    title: comment && comment.title ? comment.title : "",
    copyright: comment && comment.copyright ? comment.copyright : [],
    reply: comment && comment.reply ? comment.reply : [],
    replyConfig: comment && comment.replyConfig ? comment.replyConfig : [],
    languageDetected:
      comment && comment.languageDetected ? comment.languageDetected : "",
    postPlacement: comment.post_placement,
    commentPhrase: comment.commentPhrase,
    tp_data: comment.tp_data,
  };
  return item;
};
