import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

const useStylesItem = makeStyles(theme => ({
  subHeader: {
    display: 'flex',
    backgroundColor: theme.palette.white
  },
  subHeaderTextContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginLeft: theme.spacing(2),
    cursor: 'pointer'
  },
  icon: {
    fontSize: 14
  },
  itemColumn: {
    color: theme.palette.primary.main
  }
}));

const SalesItem = ({ data, index, style }) => {
  const classes = useStylesItem();
  const onClickAction = (e, item, action) => {
    data.action(item, index, action);
    e.stopPropagation();
  };

  const onClickItem = (e, index) => {
    data.clickItem(index);
    e.stopPropagation();
  };

  const item = data.list[index];

  return (
    <ListItem
      button
      className={classes.subHeader}
      style={style}
      key={index}
      onClick={e => onClickItem(e, item['id'])}
    >
      <IconButton
        onClick={e => onClickAction(e, item, 'edit')}
        edge="end"
        aria-label="edit"
      >
        <Icon className={classNames(classes.icon, 'fa fa-pen')} />
      </IconButton>
      <IconButton
        onClick={e => onClickAction(e, item, 'delete')}
        edge="end"
        aria-label="delete"
      >
        <Icon className={classNames(classes.icon, 'fa fa-trash')} />
      </IconButton>
      <div className={classes.subHeaderTextContainer}>
        <Typography className={classes.itemColumn}>{`${item.id}`}</Typography>
        <Typography className={classes.itemColumn}>{`${
          item.owner ? item.owner : 'unknown'
        }`}</Typography>
        <Typography
          className={classes.itemColumn}
        >{`${item.used_attempts}/${item.max_attempts}`}</Typography>
      </div>
    </ListItem>
  );
};

SalesItem.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  style: PropTypes.object
};

export default SalesItem;
