import { industriesConstants } from '../constants/industriesConstants';

import { extractIndustryList } from '../extractors/industriesExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  industryList: [],
  error: null
};

const Industries = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_INDUSTRIES
    case `${industriesConstants.FETCH_INDUSTRIES}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${industriesConstants.FETCH_INDUSTRIES}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${industriesConstants.FETCH_INDUSTRIES}_FULFILLED`:
      let industries = extractIndustryList(action.payload.data);
      return {
        ...state,
        fetching: false,
        fetched: true,
        industryList: industries
      };
    default:
      return state;
  }
};

export default Industries;
