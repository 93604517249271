import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';

import InputComponent from '../../commons/input/inputComponent';
import JSONEditor from '../../commons/jsonEditor/jsonEditorComponent';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%'
  },
  ruleContainer: {
    width: '100%'
  },
  ruleContent: {
    width: '100%'
  },
  ruleForms: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  ruleHeader: {
    backgroundColor: theme.palette.container.light,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  ruleFooter: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  ruleHeaderButtonContainer: {
    cursor: 'pointer'
  },
  formControlEditor: {
    marginTop: theme.spacing(-1)
  },
  formControl: {
    marginTop: theme.spacing(2)
  },
  formGroup: {
    width: '50%'
  },
  formGroupEditor: {
    marginTop: theme.spacing(6),
    width: '50%'
  },
  formControlLabelSwitch: {
    marginLeft: 0,
    width: 'fit-content'
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  tag: {
    margin: 2
  }
});

class RuleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      checkFilter: props.checkFilter,
      ruleFiltersKey: null,
      rules: props.rules,
      version_selected: props.version_selected,
      clientTags: props.clientTags,
      error: props.error
        ? props.error
        : {
            filters: ''
          },
      tagList: props.tagList,
      mode: 'edit',
      filterPlaceholder: [
        {
          field: 'version',
          type: 'contains_any',
          values: ['1.0']
        }
      ],
      data: props.rules
    };

    this.deleteRule.bind(this);
  }

  onErrorEditor(e, ruleKey) {
    this.props.onErrorRulesForm(e, ruleKey);
  }

  onChangeEditor(e, value, ruleKey) {
    this.setState(
      {
        data: Object.assign([], {
          ...this.state.data,
          [ruleKey]: {
            ...this.state.data[ruleKey],
            [value]: e
          }
        })
      },
      () => {
        this.props.onChangeRulesForm(this.state.data, ruleKey);
      }
    );
  }

  onChange(e, value, ruleKey, checked) {
    this.setState(
      {
        data: Object.assign([], {
          ...this.state.data,
          [ruleKey]: {
            ...this.state.data[ruleKey],
            [value]: checked ? e.target.checked : e.target.value
          }
        })
      },
      () => {
        this.props.onChangeRulesForm(this.state.data, ruleKey);
      }
    );
  }

  addRule() {
    const newData = this.state.data.concat({
      tags: [],
      autoprocess: false,
      filters: this.state.filterPlaceholder
    });

    this.setState(
      {
        data: newData,
        rules: newData
      },
      () => {
        this.props.onChangeRulesForm(this.state.data, null);
      }
    );
  }

  deleteRule(key) {
    this.setState(
      {
        data: this.state.data.filter((_, i) => i !== key)
      },
      () => {
        this.props.onChangeRulesForm(this.state.data, key);
      }
    );
  }

  displayFilters(ruleKey) {
    const { classes } = this.props;
    const { data, ruleFiltersKey, checkFilter, filterPlaceholder } = this.state;
    return (
      <FormGroup className={classes.formGroupEditor}>
        <InputLabel>Filters *</InputLabel>
        <JSONEditor
          checkFilter={ruleFiltersKey === ruleKey && checkFilter}
          placeholder={data[ruleKey]['filters'] || filterPlaceholder}
          height={'240px'}
          onChange={e => {
            return this.onChangeEditor(e, 'filters', ruleKey);
          }}
          onError={e => {
            return this.onErrorEditor(e, ruleKey);
          }}
        />
        {this.state[`errorFilter-${ruleKey}`] ? (
          <FormHelperText>
            {this.state[`errorFilter-${ruleKey}`]}
          </FormHelperText>
        ) : (
          ''
        )}
      </FormGroup>
    );
  }

  displaySwitch(ruleKey) {
    const { classes } = this.props;
    const { data } = this.state;

    return (
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          classes={{ root: classes.formControlLabelSwitch }}
          labelPlacement={'start'}
          control={
            <Switch
              checked={data[ruleKey]['autoprocess']}
              onChange={e => {
                return this.onChange(e, 'autoprocess', ruleKey, true);
              }}
              value={data[ruleKey]['autoprocess']}
              color="primary"
            />
          }
          label="Autoprocess"
        />
      </FormGroup>
    );
  }

  displayMultipleSelect(ruleKey) {
    const { classes } = this.props;
    const { tagList, clientTags, data } = this.state;
    return (
      <FormGroup className={classes.formGroup}>
        <InputLabel>Tags</InputLabel>
        <Select
          multiple
          value={data[ruleKey]['tags']}
          onChange={e => {
            return this.onChange(e, 'tags', ruleKey, false);
          }}
          input={<Input id={`tags-${ruleKey}`} />}
          renderValue={selected => (
            <div className={classes.tags}>
              {selected.map(value => (
                <Chip
                  key={value}
                  label={
                    tagList.find(tag => value === tag.id)
                      ? tagList.find(tag => value === tag.id)['label']
                      : value
                  }
                  className={classes.tag}
                />
              ))}
            </div>
          )}
        >
          {clientTags.map(tag => {
            const item = tagList.find(i => tag === i.id) || tag;
            return (
              <MenuItem
                key={item.id ? item.id : item}
                value={item.id ? item.id : item}
              >
                {item.label ? item.label : item}
              </MenuItem>
            );
          })}
        </Select>
      </FormGroup>
    );
  }

  displayTextField(value, ruleKey, label, required = false) {
    const { classes } = this.props;
    const { data, error } = this.state;
    return (
      <FormGroup className={classes.formGroup}>
        <InputComponent
          required={required}
          rules={data[ruleKey][value]}
          onChange={e => {
            return this.onChange(e, value, ruleKey, false);
          }}
          error={error[value] && !data[ruleKey][value] ? true : false}
          helperText={error[value]}
          label={label}
          margin="dense"
          fullWidth={true}
        />
      </FormGroup>
    );
  }

  buildRule(key, isDefault) {
    const { classes } = this.props;
    return (
      <div className={classes.ruleContent}>
        <div className={classes.ruleHeader}>
          <Typography className={classes.ruleTitle}>
            {isDefault ? 'Default ' : ''}Rule
          </Typography>
          {key !== 0 ? (
            <div
              onClick={e => {
                return this.deleteRule(key);
              }}
              className={classes.ruleHeaderButtonContainer}
            >
              <DeleteRoundedIcon />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className={classes.ruleForms}>
          <FormControl>{this.displayMultipleSelect(key)}</FormControl>
          <FormControl className={classes.formControl}>
            {this.displaySwitch(key)}
          </FormControl>
          <FormControl className={classes.formControlEditor}>
            {this.displayFilters(key)}
          </FormControl>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checkFilter !== this.props.checkFilter) {
      this.setState({
        checkFilter: this.props.checkFilter,
        ruleFiltersKey: this.props.ruleFiltersKey
      });
    }

    if (prevProps.classifierSelected !== this.props.classifierSelected) {
      this.setState(
        {
          rules: [],
          data: []
        },
        () => {
          this.setState({
            rules: this.props.rules,
            data: this.props.rules
          });
        }
      );
    }

    if (prevProps.error !== this.props.error) {
      this.setState({
        error: this.props.error,
        [`errorFilter-${this.props.ruleFiltersKey}`]: this.props.error.filters
      });
    }
  }

  componentDidMount() {
    this.props.rules.map((rule, key) => {
      this.setState({
        [`errorFilter-${key}`]: ''
      });
      return true;
    });
  }

  render() {
    const { classes } = this.props;
    const { rules, data } = this.state;

    return (
      <div className={classes.root}>
        {rules.map((rule, key) => {
          if (data[key]) {
            return (
              <div className={classes.ruleContainer} key={key}>
                {this.buildRule(key, rule.isDefault)}
              </div>
            );
          }
          return '';
        })}
        <div className={classes.ruleFooter}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={e => {
              return this.addRule();
            }}
          >
            {'Add rule'}
          </Button>
        </div>
      </div>
    );
  }
}

RuleForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  checkFilter: PropTypes.bool,
  ruleFiltersKey: PropTypes.number,
  onChangeRulesForm: PropTypes.func.isRequired,
  onErrorRulesForm: PropTypes.func.isRequired,
  clientTags: PropTypes.array.isRequired,
  rules: PropTypes.array.isRequired,
  tagList: PropTypes.array.isRequired,
  classifierSelected: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(RuleForm);
