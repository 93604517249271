import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CheckBox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import {
  fetchFeatureFlags,
  updateFeatureFlags,
} from "../../../../actions/featureFlagsAction";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  label: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "4px",
  },
});

class FeatureFlags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    const {
      rowData: { clientShortName },
      fetchFeatureFlags,
    } = this.props;
    fetchFeatureFlags(clientShortName).then((data) => {
      if (data && data.value) this.setState({ items: data.value.data });
    });
  }

  onChange = (item, newValue) => {
    this.setState((prevState) => {
      const updatedItems = prevState.items.map((feature) =>
        feature.field === item.field ? { ...feature, value: newValue } : feature
      );
      return { items: updatedItems };
    });
  };

  onSave = () => {
    const { items } = this.state;
    const {
      rowData: { clientShortName },
      updateFeatureFlags,
      onClose,
    } = this.props;
    updateFeatureFlags(clientShortName, items).then(onClose);
  };

  render() {
    const { classes, onClose } = this.props;
    const { items } = this.state;

    return (
      <div className={classes.root}>
        {items && items.length
          ? items.map((feature) => (
              <FormControlLabel
                key={feature.field}
                control={
                  <CheckBox
                    checked={feature.value}
                    value={feature.field}
                    onChange={(_, value) => this.onChange(feature, value)}
                  />
                }
                label={
                  <div className={classes.label}>
                    <Typography>{feature.label}</Typography>
                    <Typography variant="caption">
                      {feature.description}
                    </Typography>
                  </div>
                }
              />
            ))
          : null}
        <div className={classes.actions}>
          <Button size="small" color="primary" onClick={this.onSave}>
            Save
          </Button>
          <Button onClick={onClose} size="small" color="secondary">
            Close
          </Button>
        </div>
      </div>
    );
  }
}

FeatureFlags.propTypes = {
  classes: PropTypes.object.isRequired,
  rowData: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  featureFlags: state.featureFlagsReducer.featureFlags,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFeatureFlags: (name) => dispatch(fetchFeatureFlags(name)),
  updateFeatureFlags: (name, items) =>
    dispatch(updateFeatureFlags(name, items)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(FeatureFlags));
