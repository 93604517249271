import axios from 'axios';

import { salesConstants } from '../constants/salesConstants';
import { setHeaders } from '../setters/auth';

const createQuery = options => {
  const params = [];

  if (options.search) params.push(`term=${options.search}`);

  return params.join('&');
};

export const fetchOwnerList = () => dispatch => {
  const url = `${process.env.URL_API_ENDPOINT}/users`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: salesConstants.FETCH_OWNER_LIST,
    payload: promise
  });
};

export const fetchListAnalysis = (options = {}) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/sales/analysis`;

  if (options && options.search) {
    url = url + `?`;
  }

  url = url + createQuery(options);

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: salesConstants.FETCH_LIST_ANALYSIS,
    payload: promise
  });
};

export const createNewCode = data => dispatch => {
  const url = `${process.env.URL_API_ENDPOINT}/sales/analysis`;

  const promise = axios.post(
    url,
    {
      id: data.id,
      owner: data.owner,
      max_attempts: parseInt(data.max_attempts)
    },
    {
      headers: setHeaders(),
      newCode: data
    }
  );

  return dispatch({
    type: salesConstants.CREATE_NEW_CODE,
    payload: promise
  });
};

export const editCode = (index, data) => dispatch => {
  const url = `${process.env.URL_API_ENDPOINT}/sales/analysis/${data.id}`;

  const promise = axios.put(
    url,
    {
      owner: data.owner,
      max_attempts: parseInt(data.max_attempts)
    },
    {
      headers: setHeaders(),
      index: index,
      code: data
    }
  );

  return dispatch({
    type: salesConstants.EDIT_CODE,
    payload: promise
  });
};

export const deleteCode = (index, data) => dispatch => {
  const url = `${process.env.URL_API_ENDPOINT}/sales/analysis/${data.id}`;

  const promise = axios.delete(url, {
    headers: setHeaders(),
    index: index
  });

  return dispatch({
    type: salesConstants.DELETE_CODE,
    payload: promise
  });
};
