import axios from 'axios';

import { usersConstants } from '../../constants/configuration/usersConstants';
import { setHeaders } from '../../setters/auth';

export const fetchUserList = () => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/users`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: usersConstants.FETCH_USER_LIST,
    payload: promise
  });
};

export const updateUserRoles = (index, userId, roles) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/users/${userId}/roles`;

  const promise = axios.patch(
    url,
    {
      roles: roles
    },
    {
      headers: setHeaders(),
      index: index,
      roles: roles
    }
  );

  return dispatch({
    type: usersConstants.UPDATE_USER_ROLES,
    payload: promise
  });
};
