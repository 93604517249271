import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import { setDateFormat } from '../../../setters/date';

import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(theme => ({
  tagsContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  tag: {
    margin: 5,
    height: 20,
    fontSize: 10
  },
  title: {},
  errorsContainer: {
    marginLeft: theme.spacing(2)
  },
  typo: {
    marginLeft: theme.spacing(1),
    fontSize: 13,
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: theme.palette.primary.main,
    minWidth: 30,
    marginRight: theme.spacing(1)
  }
}));

const HistoryComment = ({ type, step }) => {
  const classes = useStyles();
  return (
    <Step active={true}>
      <StepLabel className={classes.title}>{'Status Changed'}</StepLabel>
      <StepContent>
        <Typography className={classes.typo}>
          <Icon
            className={classNames(classes.icon, 'fas fa-exclamation-circle')}
          />
          {`outside of CAMP: ${step['is_action_outside_camp'] ? 'YES ' : 'NO'}`}
        </Typography>
        <Typography className={classes.typo}>
          <Icon
            className={classNames(classes.icon, 'fas fa-exclamation-circle')}
          />
          {`New Status: ${
            step['new_status'] ? step['new_status'] : 'New status unknown'
          }`}
        </Typography>
        {step.new_tags && step.new_tags.length ? (
          <div className={classes.tagsContainer}>
            <Typography className={classes.typo}>{'New tags: '}</Typography>
            {step.new_tags
              ? step.new_tags.map((tag, key) => {
                  return (
                    <Chip
                      className={classes.tag}
                      key={key}
                      label={tag}
                      component="div"
                    />
                  );
                })
              : ''}
          </div>
        ) : (
          ''
        )}
        <Typography className={classes.typo}>
          <Icon
            className={classNames(classes.icon, 'fas fa-exclamation-circle')}
          />
          {`Old Status: ${
            step['old_status'] ? step['old_status'] : 'Old status unknown'
          }`}
        </Typography>
        {step.new_tags && step.old_tags.length ? (
          <div className={classes.tagsContainer}>
            <Typography className={classes.typo}>{'Old tags: '}</Typography>
            {step.old_tags
              ? step.old_tags.map((tag, key) => {
                  return (
                    <Chip
                      className={classes.tag}
                      key={key}
                      label={tag}
                      component="div"
                    />
                  );
                })
              : ''}
          </div>
        ) : (
          ''
        )}
        <Typography className={classes.typo}>
          <Icon className={classes.icon}>calendar_today</Icon>
          {` ${setDateFormat(step.timestamp_pretty)}`}
        </Typography>
      </StepContent>
    </Step>
  );
};

HistoryComment.propTypes = {
  step: PropTypes.object
};

export default HistoryComment;
