export const setAction = (action, key = null) => {
  const item = {
    id: action && action.action_id ? action.action_id : "",
    key: key,
    tags:
      action && action.volume_config && action.volume_config.tags
        ? action.volume_config.tags
        : action.filters.filter(
            (filter) =>
              filter.field === "tags" && filter.type === "contains_all"
          ).length
        ? action.filters.filter(
            (filter) =>
              filter.field === "tags" && filter.type === "contains_all"
          )[0].values
        : [],
    type: action && action.type ? action.type : "",
    filters:
      action && action.filters
        ? action.filters.filter(
            (filter) =>
              !(filter.field === "tags" && filter.type === "contains_all")
          )
        : "",
    volume_config: action && action.volume_config ? action.volume_config : "",
    replier_asset_id:
      action && action.replier_asset_id ? action.replier_asset_id : "",
  };

  return item;
};
