import { clientsConstants } from "../../constants/configuration/clientsConstants";

import { extractClientList } from "../../extractors/clientsExtractor";

const initialState = {
  fetching: false,
  fetched: false,
  clientList: [],
  error: null,
};

const Clients = (state = initialState, action) => {
  switch (action.type) {
    // FETCH CLIENT LIST
    case `${clientsConstants.FETCH_CLIENT_LIST}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${clientsConstants.FETCH_CLIENT_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${clientsConstants.FETCH_CLIENT_LIST}_FULFILLED`:
      let clientList = extractClientList(action.payload.data, "config");
      return {
        ...state,
        fetching: false,
        fetched: true,
        clientList: clientList,
      };
    // ADD CLIENT
    case `${clientsConstants.ADD_CLIENT}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${clientsConstants.ADD_CLIENT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${clientsConstants.ADD_CLIENT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        clientList: [
          ...state.clientList,
          {
            name: action.payload.config.clientInfo.name,
            clientShortName: action.payload.config.clientInfo.clientShortName,
            abbreviation: action.payload.config.clientInfo.abbreviation,
            logo: action.payload.config.clientInfo.logo,
            tags: action.payload.config.clientInfo.tags,
            adbastion_tags: action.payload.config.clientInfo.adbastion_tags,
            care_tags: action.payload.config.clientInfo.care_tags,
            care_review_tags: action.payload.config.clientInfo.care_review_tags,
            adbastion_moderation_enabled:
              action.payload.config.clientInfo.adbastion_moderation_enabled,
            care_agent_stats_enabled:
              action.payload.config.clientInfo.care_agent_stats_enabled,
            adbastion_publishing_enabled:
              action.payload.config.clientInfo.adbastion_publishing_enabled,
            care_enabled: action.payload.config.clientInfo.care_enabled,
            managed: action.payload.config.clientInfo.managed,
            adbastion_moderation_alerts_enabled:
              action.payload.config.clientInfo
                .adbastion_moderation_alerts_enabled,
            adbastion_auto_crawling:
              action.payload.config.clientInfo.adbastion_auto_crawling,
            adbastion_moderation_init_time:
              action.payload.config.clientInfo.adbastion_moderation_init_time,
            adbastion_moderation_guideline_update_time:
              action.payload.config.clientInfo
                .adbastion_moderation_guideline_update_time,
            adbastion_insights_init_time:
              action.payload.config.clientInfo.adbastion_insights_init_time,
            care_init_time: action.payload.config.clientInfo.care_init_time,
            trial_subscription_init:
              action.payload.config.clientInfo.trial_subscription_init,
            trial_subscription_init_time:
              action.payload.config.clientInfo.trial_subscription_init_time,
            industry: action.payload.config.clientInfo.otherIndustry
              ? action.payload.config.clientInfo.otherIndustry
              : action.payload.config.clientInfo.industry,
            adbastion_plan: action.payload.config.clientInfo.adbastion_plan,
            emailsAlert: action.payload.config.clientInfo.emailsAlert,
            initTime: action.payload.config.clientInfo.initTime,
            sla: action.payload.config.clientInfo.sla,
            alertSla: action.payload.config.clientInfo.alertSla,
            queryOnlyActive: action.payload.config.clientInfo.queryOnlyActive,
            pullInsights: action.payload.config.clientInfo.pullInsights,
            salesAnalysisClient: false,
            allowPublishedPosts:
              action.payload.config.clientInfo.allowPublishedPosts,
            privateRepliesUserTimespan:
              action.payload.config.clientInfo.privateRepliesUserTimespan,
          },
        ],
      };
    // EDIT CLIENT
    case `${clientsConstants.EDIT_CLIENT}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${clientsConstants.EDIT_CLIENT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${clientsConstants.EDIT_CLIENT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        clientList: Object.assign([], {
          ...state.clientList,
          [action.payload.config.index]: {
            ...state.clientList[action.payload.config.index],
            name: action.payload.config.clientInfo.name,
            clientShortName: action.payload.config.clientInfo.clientShortName,
            abbreviation: action.payload.config.clientInfo.abbreviation,
            logo: action.payload.config.clientInfo.logo,
            tags: action.payload.config.clientInfo.tags,
            adbastion_tags: action.payload.config.clientInfo.adbastion_tags,
            care_tags: action.payload.config.clientInfo.care_tags,
            care_review_tags: action.payload.config.clientInfo.care_review_tags,
            adbastion_moderation_enabled:
              action.payload.config.clientInfo.adbastion_moderation_enabled,
            care_agent_stats_enabled:
              action.payload.config.clientInfo.care_agent_stats_enabled,
            adbastion_publishing_enabled:
              action.payload.config.clientInfo.adbastion_publishing_enabled,
            care_enabled: action.payload.config.clientInfo.care_enabled,
            managed: action.payload.config.clientInfo.managed,
            adbastion_moderation_alerts_enabled:
              action.payload.config.clientInfo
                .adbastion_moderation_alerts_enabled,
            adbastion_auto_crawling:
              action.payload.config.clientInfo.adbastion_auto_crawling,
            adbastion_moderation_init_time:
              action.payload.config.clientInfo.adbastion_moderation_init_time,
            adbastion_insights_init_time:
              action.payload.config.clientInfo.adbastion_insights_init_time,
            care_init_time: action.payload.config.clientInfo.care_init_time,
            trial_subscription_init:
              action.payload.config.clientInfo.trial_subscription_init,
            trial_subscription_init_time:
              action.payload.config.clientInfo.trial_subscription_init_time,
            adbastion_moderation_guideline_update_time:
              action.payload.config.clientInfo
                .adbastion_moderation_guideline_update_time,
            industry:
              action.payload.config.clientInfo.industry === "Other"
                ? action.payload.config.clientInfo.otherIndustry
                : action.payload.config.clientInfo.industry,
            adbastion_plan: action.payload.config.clientInfo.adbastion_plan,
            emailsAlert: action.payload.config.clientInfo.emailsAlert,
            initTime: action.payload.config.clientInfo.initTime,
            sla: action.payload.config.clientInfo.sla,
            alertSla: action.payload.config.clientInfo.alertSla,
            queryOnlyActive: action.payload.config.clientInfo.queryOnlyActive,
            pullInsights: action.payload.config.clientInfo.pullInsights,
            allowPublishedPosts:
              action.payload.config.clientInfo.allowPublishedPosts,
            privateRepliesUserTimespan:
              action.payload.config.clientInfo.privateRepliesUserTimespan,
          },
        }),
      };
    default:
      return state;
  }
};

export default Clients;
