import axios from 'axios';

import { classifiersConstants } from '../../constants/configuration/classifiersConstants';
import { setHeaders } from '../../setters/auth';

export const fetchClassifiersList = () => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/classifiers`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: classifiersConstants.FETCH_CLASSIFIER_LIST,
    payload: promise
  });
};

export const addClassifier = data => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/classifiers`;
  let classifier = {
    id: data.classifier_name,
    group: data.group,
    color: data.colorGroup,
    versions: data.versions,
    default_rules: data.default_rules
  };

  const promise = axios.post(url, classifier, {
    headers: setHeaders(),
    classifierInfo: data
  });

  return dispatch({
    type: classifiersConstants.ADD_CLASSIFIER,
    payload: promise
  });
};

export const editClassifier = (index, data) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/classifiers/${data.classifier_name}`;
  let classifier = {
    group: data.group,
    color: data.colorGroup,
    versions: data.versions,
    default_rules: data.default_rules
  };

  const promise = axios.put(url, classifier, {
    headers: setHeaders(),
    index: index,
    classifierInfo: data
  });

  return dispatch({
    type: classifiersConstants.EDIT_CLASSIFIER,
    payload: promise
  });
};
