import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SelectComponent from '../../commons/select/selectComponent';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%'
  },
  versionContainer: {
    margin: theme.spacing(1)
  }
});

class ClassifierForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      formType: props.formType
    };
  }

  onChangeVersion(value) {
    this.props.onChangeVersion(value);
  }

  onChangeClassifier(value) {
    this.props.onChangeClassifier(value);
  }

  componentWillUnmount() {
    this.props.onChangeClassifier('');
  }

  render() {
    const {
      classes,
      versions,
      classifierList,
      classifierSelected,
      version_selected
    } = this.props;
    const { formType } = this.state;

    const isExisting = classifierList.find(
      classifier => classifier.classifier_name === classifierSelected
    );

    return (
      <div className={classes.root}>
        <div className={classes.versionContainer}>
          {formType === 'add' && (
            <SelectComponent
              id={'classifier'}
              label={'Classifier'}
              value={isExisting ? classifierSelected : ''}
              attributId={'classifier_name'}
              attributName={'classifier_name'}
              items={classifierList}
              onChange={(e, id) => this.onChangeClassifier(e.target.value)}
              displaySearch={true}
            />
          )}
          <SelectComponent
            id={'version'}
            label={'Version'}
            value={version_selected}
            items={versions}
            onChange={(e, id) => this.onChangeVersion(e.target.value)}
          />
        </div>
        {this.props.children}
      </div>
    );
  }
}

ClassifierForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onChangeVersion: PropTypes.func.isRequired,
  versions: PropTypes.array.isRequired,
  version_selected: PropTypes.string.isRequired,
  formType: PropTypes.string.isRequired,
  classifierList: PropTypes.array,
  classifierSelected: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(ClassifierForm);
