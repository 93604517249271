import { setDateFormat } from "./date";

const getNormalizedAssetPlatform = (assetPlatform) => {
  switch (assetPlatform) {
    case "facebook":
    case "instagram":
    case "youtube":
      return (
        assetPlatform[0].toUpperCase() + assetPlatform.slice(1) + " Organic"
      );
    case "facebook_ads":
      return "Facebook/Instagram Ads";
    default:
      return assetPlatform[0].toUpperCase() + assetPlatform.slice(1);
  }
};

const getNormalizedTypes = (assetTypes) => {
  const isArray = Array.isArray(assetTypes);
  const types = isArray ? assetTypes : [assetTypes];

  const arr = types.map((at) => {
    switch (at) {
      case "facebook_bm":
        return "Business Manager credentials";
      case "external_user":
      case "fb_external_credentials":
        return "BB Platform credentials";
      default:
        return `BB Platform credentials`;
    }
  });

  return isArray ? arr : arr[0];
};

export const setAssetManagement = (asset) => {
  const item = {
    assetId: asset.id ? asset.id : "",
    assetName:
      asset.asset_name && asset.asset_name.name ? asset.asset_name.name : "",
    assetAlias:
      asset.asset_name && asset.asset_name.alias ? asset.asset_name.alias : "",
    assetUsername:
      asset.asset_name && asset.asset_name.username
        ? asset.asset_name.username
        : "",
    assetPlatform: asset.platform
      ? getNormalizedAssetPlatform(asset.platform)
      : "",
    assetPlatformId: asset.platform ? asset.platform : "",
    assetType: asset.type ? getNormalizedTypes(asset.type) : "",
    isAdAsset: asset.platform && asset.platform === "facebook_ads",
    isFbOrganicAsset: asset.platform && asset.platform === "facebook",
    clientShortName: asset.client_short_name ? asset.client_short_name : "",
    activeServices: asset.active_services ? asset.active_services : [],
    replierInfo: asset.replier_info ? asset.replier_info : { enabled: false },
    crawlingStatus: asset.crawling_status ? asset.crawling_status : "N/A",
    crawlingInfo: asset.crawling_info ? asset.crawling_info : {},
    crawlingHistory: asset.crawling_history ? asset.crawling_history : [],
    credentialTypes: asset.credential_types
      ? getNormalizedTypes(asset.credential_types)
      : [],
    credentialStatus: asset.credential_status ? asset.credential_status : {},
    permissions: asset.permissions ? asset.permissions : {},
    linkedAccounts: asset.linked_accounts ? asset.linked_accounts : {},
    accessFrom: asset.access_from ? setDateFormat(asset.access_from) : "",
    lastRefresh: asset.last_refresh ? setDateFormat(asset.last_refresh) : "",
    assetStatus: asset.asset_status ? asset.asset_status : "",
    pendingRemoval:
      asset.pending_removal && asset.asset_status !== "REMOVED"
        ? asset.pending_removal
        : false,
    removalRequestTime: asset.removal_request_time
      ? asset.removal_request_time
      : 0,
    removalRequestUserId: asset.removal_request_user_id
      ? asset.removal_request_user_id
      : "",
    assetVisibility: asset.asset_visibility ? asset.asset_visibility : {},
    autoRehide: asset.auto_rehide ? asset.auto_rehide : false,
    webhooks:
      asset.webhook_config && asset.webhook_config.filters
        ? {
            ...asset.webhook_config,
            publishedStatus: asset.webhook_config.filters.is_published,
          }
        : {
            configured: false,
            active: false,
            publishedStatus: "all",
          },
    bmOwner: asset.bm_owner ? asset.bm_owner : {},
  };

  return item;
};
