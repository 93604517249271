import { taskHistoryConstants } from '../../constants/configuration/taskHistoryConstants';

import { extractTaskHistoryList } from '../../extractors/taskHistoryExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  taskHistoryList: [],
  error: null
};

const taskHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_TASK_HISTORY_LIST
    case `${taskHistoryConstants.FETCH_TASK_HISTORY_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${taskHistoryConstants.FETCH_TASK_HISTORY_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${taskHistoryConstants.FETCH_TASK_HISTORY_LIST}_FULFILLED`:
      const taskHistoryList = extractTaskHistoryList(action.payload.data);
      return {
        ...state,
        fetching: false,
        fetched: true,
        taskHistoryList
      };

    // ADD_TASK
    case `${taskHistoryConstants.ADD_TASK}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${taskHistoryConstants.ADD_TASK}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${taskHistoryConstants.ADD_TASK}_FULFILLED`:
      let addedTask = extractTaskHistoryList([
        action.payload.config.addData
      ])[0];
      addedTask.id = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        taskHistoryList: [addedTask, ...state.taskHistoryList]
      };

    // UPDATE_TASK_FAVORITE
    case `${taskHistoryConstants.UPDATE_TASK_FAVORITE}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${taskHistoryConstants.UPDATE_TASK_FAVORITE}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${taskHistoryConstants.UPDATE_TASK_FAVORITE}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        taskHistoryList: Object.assign([], {
          ...state.taskHistoryList,
          [action.payload.config.index]: {
            ...state.taskHistoryList[action.payload.config.index],
            is_favorite: action.payload.config.is_favorite
          }
        })
      };
    default:
      return state;
  }
};

export default taskHistoryReducer;
