import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

// Material UI
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import PanToolIcon from "@material-ui/icons/PanTool";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import WifiIcon from "@material-ui/icons/Wifi";
import WifiOffIcon from "@material-ui/icons/WifiOff";

import SimpleMenu from "../../commons/simpleMenu/simpleMenuComponent";
import TableComponent from "../../commons/table/tableComponent";
import DialogContent from "../../commons/dialog/contentDialogComponent";
import AlertDialog from "../../commons/dialog/alertDialogComponent";

import AssetManagementForm from "./assetManagementForm";
import CrawlingForm from "./crawlingForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
    "& .MuiInput-root": {
      marginTop: "0 !important",
    },
    "& .MuiTypography-h6": {
      marginRight: "16px",
    },
    "& .MuiCheckbox-colorPrimary.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.1)",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 7,
  },
  detailContainer: {
    display: "flex",
  },
  detailItem: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.container.light,
  },
  detailLegend: {
    padding: theme.spacing(1),
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
  },
  detailContent: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    overflow: "auto",
  },
  detailCell: {
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 32,
  },
  statusIcon: {
    verticalAlign: "bottom",
  },
  statusIconRight: {
    marginLeft: 16,
  },
  actionsContainer: {
    display: "flex",
  },
  customAction: {
    borderRadius: "50%",
    padding: "0",
    cursor: "pointer",
    width: 48,
    height: 48,
    minWidth: "unset",
    "& .MuiButton-label": {
      width: "auto",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const AssetManagementConfiguration = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const initialEditFormParams = {
    index: null,
    assetIds: [],
    assetName: "",
    clientShortName: "",
    updateParam: "",
    updateValue: null,
  };
  const [editFormParams, setEditFormParams] = useState(initialEditFormParams);

  const [isFormOpen, setFormOpen] = useState(false);
  const [isCrawlingFormOpen, setCrawlingFormOpen] = useState(false);
  const [isFormLoading, setFormLoading] = useState(false);

  const [isEditBatch, setEditBatch] = useState(false);
  const [assetList, setAssetList] = useState([]);
  const [batchAssetIds, setBatchAssetIds] = useState([]);

  const [validBatchClientShortName, setValidBatchClientShortName] =
    useState(null);
  const [validBatchCrawlingStatus, setValidBatchCrawlingStatus] =
    useState(null);
  const [validBatchPlatformAds, setValidBatchPlatformAds] = useState(null);

  const [isUpdateAlertOpen, setUpdateAlertOpen] = useState(false);
  const [isCreateTasksAlertOpen, setCreateTasksAlertOpen] = useState(false);
  const [isCrawlingStatusAlertOpen, setCrawlingStatusAlertOpen] =
    useState(false);

  const [formData, setFormData] = useState({});
  const [isCSNAlreadyAssigned, setCSNAlreadyAssigned] = useState(false);

  const [formErrors, setFormErrors] = useState({});
  const initialFormActionsLoading = {
    clientShortName: false,
    alias: false,
    createTasks: false,
    updateTasks: false,
    enableCrawling: false,
    disableCrawling: false,
    startCrawling: false,
    stopCrawling: false,
    assetVisibility: false,
    autoRehide: false,
    replierInfo: false,
  };
  const [formActionsLoading, setFormActionsLoading] = useState(
    initialFormActionsLoading
  );

  const [redirectTaskKPIs, setRedirectTaskKPIs] = useState(null);
  const [hasDefaultValue, setHasDefaultValue] = useState(0);
  //Init

  const {
    assetManagementList,
    activeServicesList,
    clientList,
    formPermissions,
    crawlingPermissions,
  } = props;

  const formOptions = {
    clientShortName: clientList.map((client) => {
      return client.clientShortName;
    }),
    activeServices: activeServicesList,
  };

  //Funcs

  const updateFormActionLoading = (name, isLoading) => {
    setFormActionsLoading({
      ...formActionsLoading,
      [name]: isLoading,
    });
  };

  const onOpenUpdateAlert = () => {
    setUpdateAlertOpen(true);
  };

  const onOpenCreateTasksAlert = () => {
    setCreateTasksAlertOpen(true);
  };

  const onOpenCrawlingStatusAlert = () => {
    setCrawlingStatusAlertOpen(true);
  };

  const onActionAlert = (isConfirmed) => {
    if (isConfirmed) {
      const { clientShortName, updateParam, index, assetIds, updateValue } =
        editFormParams;
      const assetId = assetIds[0];
      switch (updateParam) {
        case "clientShortName":
          updateFormActionLoading(updateParam, true);
          props.assignClientShortname(
            index,
            assetIds,
            updateValue,
            isEditBatch,
            (isAssignSuccessful) => {
              props.fetchAssetManagement(index, assetId, isEditBatch, () => {
                setCSNAlreadyAssigned(isAssignSuccessful);
                updateFormActionLoading(updateParam, false);
              });
            }
          );
          break;
        case "activeServices":
        case "alias":
        case "webhooks":
        case "assetVisibility":
        case "autoRehide":
          updateFormActionLoading(updateParam, true);
          props.updateAsset(
            index,
            assetIds,
            updateParam,
            updateValue,
            isEditBatch,
            (isUpdateSuccessful) => {
              props.fetchAssetManagement(index, assetId, isEditBatch, () => {
                if (isUpdateSuccessful) {
                  setFormErrors({});
                }
                updateFormActionLoading(updateParam, false);
              });
            }
          );
          break;
        case "replierInfo":
          updateFormActionLoading(updateParam, true);
          props.updateAsset(
            index,
            assetIds,
            updateParam,
            updateValue,
            isEditBatch,
            (isUpdateSuccessful) => {
              props.fetchAssetManagement(index, assetId, isEditBatch, () => {
                if (isUpdateSuccessful) {
                  setFormErrors({});
                }
                updateFormActionLoading(updateParam, false);
              });
            }
          );
          break;
        case "createTasks":
        case "updateTasks":
          updateFormActionLoading(updateParam, true);
          props.createTasks(
            index,
            assetIds,
            clientShortName,
            updateValue,
            (createTasksResult) => {
              props.fetchAssetManagement(
                index,
                assetId,
                isEditBatch,
                (asset) => {
                  if (createTasksResult) {
                    setFormErrors({});
                    setFormData({
                      ...formData,
                      crawlingStatus: asset.crawlingStatus,
                    });
                  }
                  updateFormActionLoading(updateParam, false);
                }
              );
            }
          );
          break;
        case "enableCrawling":
        case "disableCrawling":
          updateFormActionLoading(updateParam, true);
          props.toggleCrawlingDisabled(
            index,
            assetIds,
            updateValue,
            (toggleDisableResult) => {
              props.fetchAssetManagement(
                index,
                assetId,
                isEditBatch,
                (asset) => {
                  let formDataCopy = { ...formData };
                  if (asset.clientShortName !== "") {
                    props.fetchClient(asset.clientShortName, (client) => {
                      if (updateParam === "enableCrawling" && client) {
                        formDataCopy.crawlers = {
                          initTime: client.initTime,
                          sla: client.sla,
                          alertSla: client.alertSla,
                          queryOnlyActive: client.queryOnlyActive,
                          allowPublishedPosts: client.allowPublishedPosts,
                          pullInsights: client.pullInsights,
                          adPlatforms: asset.isAdAsset
                            ? ["facebook", "instagram"]
                            : null,
                        };
                      }
                      formDataCopy.crawlingStatus = asset.crawlingStatus;
                      if (toggleDisableResult) {
                        setFormData(formDataCopy);
                      }
                      updateFormActionLoading(updateParam, false);
                    });
                  } else {
                    formDataCopy.crawlingStatus = asset.crawlingStatus;
                    if (toggleDisableResult) {
                      setFormData(formDataCopy);
                    }
                    updateFormActionLoading(updateParam, false);
                  }
                }
              );
            }
          );
          break;
        case "startCrawling":
        case "stopCrawling":
          updateFormActionLoading(updateParam, true);
          props.toggleCrawlingStartStop(
            index,
            assetIds,
            clientShortName,
            updateValue,
            (toggleStartStopResult) => {
              props.fetchAssetManagement(
                index,
                assetId,
                isEditBatch,
                (asset) => {
                  if (toggleStartStopResult) {
                    setFormData({
                      ...formData,
                      crawlingStatus: asset.crawlingStatus,
                    });
                  }
                  updateFormActionLoading(updateParam, false);
                }
              );
            }
          );
          break;
        default:
          break;
      }
    }

    setUpdateAlertOpen(false);
    setCreateTasksAlertOpen(false);
    setCrawlingStatusAlertOpen(false);
  };

  const onCloseDialogForm = () => {
    setFormOpen(false);
  };

  const onCloseDialogCrawlingForm = () => {
    setCrawlingFormOpen(false);
  };

  const onClickEditAssetBatch = (data) => {
    setFormErrors({});
    setEditBatch(true);
    setFormLoading(true);
    setFormOpen(true);

    const firstAsset = data[0];
    const assetIds = data.map((a) => {
      return a.assetId;
    });
    setBatchAssetIds(assetIds);
    setEditFormParams({
      index: -1,
      assetIds,
      assetName: "Batch",
      clientShortName: firstAsset.clientShortName,
    });

    props.fetchClient(firstAsset.clientShortName, (client) => {
      setFormData({
        clientShortName: firstAsset.clientShortName,
        alias: firstAsset.assetAlias,
        activeServices: [],
        webhooks: firstAsset.webhooks,
        isFbOrganicAsset: firstAsset.isFbOrganicAsset,
        isSalesClient: client.salesAnalysisClient,
        autoRehide: firstAsset.autoRehide,
        replierInfo: firstAsset.replierInfo,
        assetVisibility: { camp: "public", client_camp: "public" },
      });
      setCSNAlreadyAssigned(firstAsset.clientShortName !== "");
      setFormLoading(false);
    });
  };

  const onClickEditAsset = (index, assetId, assetName, clientShortName) => {
    onClickDeselectAll();
    setEditFormParams({
      index,
      assetIds: [assetId],
      assetName,
      clientShortName,
    });
    setFormErrors({});
    setEditBatch(false);
    setFormLoading(true);
    setFormOpen(true);
    props.fetchClient(clientShortName, (client) => {
      props.fetchAssetManagement(index, assetId, false, (asset) => {
        setFormData({
          clientShortName: asset.clientShortName,
          alias: asset.assetAlias,
          activeServices: asset.activeServices,
          webhooks: asset.webhooks,
          isFbOrganicAsset: asset.isFbOrganicAsset,
          isSalesClient: client.salesAnalysisClient,
          assetVisibility: asset.assetVisibility,
          autoRehide: asset.autoRehide,
          replierInfo: asset.replierInfo,
        });
        setCSNAlreadyAssigned(
          assetManagementList[index].clientShortName !== ""
        );
        setFormLoading(false);
      });
    });
  };

  const onClickEditCrawlingBatch = (data) => {
    setFormErrors({});
    setEditBatch(true);
    setFormLoading(true);
    setCrawlingFormOpen(true);

    const firstAsset = data[0];
    const assetIds = data.map((a) => {
      return a.assetId;
    });
    setBatchAssetIds(assetIds);
    setEditFormParams({
      index: -1,
      assetIds,
      assetName: "Batch",
      clientShortName: firstAsset.clientShortName,
    });

    props.fetchClient(firstAsset.clientShortName, (client) => {
      setFormData({
        crawlingStatus: firstAsset.crawlingStatus,
        crawlingInfo: firstAsset.crawlingInfo,
        isAdAsset: firstAsset.isAdAsset,
        canPullInsights:
          ["facebook", "instagram", "facebook_ads"].indexOf(
            firstAsset.assetPlatformId
          ) !== -1,
        crawlers: {
          initTime: client.initTime,
          sla: client.sla,
          alertSla: client.alertSla,
          queryOnlyActive: client.queryOnlyActive,
          allowPublishedPosts: client.allowPublishedPosts,
          adPlatforms: firstAsset.isAdAsset
            ? getAdPlatformsFromAsset(firstAsset)
            : null,
          pullInsights: client.pullInsights,
        },
      });
      setCSNAlreadyAssigned(firstAsset.clientShortName !== "");
      setFormLoading(false);
    });
  };

  const onClickEditCrawling = (index, assetId, assetName, clientShortName) => {
    onClickDeselectAll();
    setEditFormParams({
      index,
      assetIds: [assetId],
      assetName,
      clientShortName,
    });
    setFormErrors({});
    setEditBatch(false);
    setFormLoading(true);
    setCrawlingFormOpen(true);

    props.fetchAssetManagement(index, assetId, false, (asset) => {
      props.fetchClient(asset.clientShortName, (client) => {
        let crawlers = {};
        if (client && asset.crawlingStatus === "N/A") {
          crawlers = {
            initTime: client.initTime,
            sla: client.sla,
            alertSla: client.alertSla,
            queryOnlyActive: client.queryOnlyActive,
            allowPublishedPosts: client.allowPublishedPosts,
            pullInsights: client.pullInsights,
            adPlatforms: asset.isAdAsset
              ? getAdPlatformsFromAsset(asset)
              : null,
          };
        } else if (client && asset.crawlingStatus !== "N/A") {
          crawlers = {
            initTime: asset.crawlingInfo.init_time,
            sla: asset.crawlingInfo.sla,
            alertSla: asset.crawlingInfo.alert_sla,
            queryOnlyActive: asset.crawlingInfo.query_only_active,
            allowPublishedPosts: asset.crawlingInfo.allow_published_posts,
            pullInsights: asset.crawlingInfo.pull_insights,
            adPlatforms: asset.isAdAsset
              ? getAdPlatformsFromAsset(asset)
              : null,
          };
        }
        setFormData({
          crawlingStatus: asset.crawlingStatus,
          crawlingInfo: asset.crawlingInfo,
          isAdAsset: asset.isAdAsset,
          canPullInsights:
            ["facebook", "instagram", "facebook_ads"].indexOf(
              asset.assetPlatformId
            ) !== -1,
          crawlers,
        });
        setCSNAlreadyAssigned(
          assetManagementList[index]
            ? assetManagementList[index].clientShortName !== ""
            : false
        );
        setFormLoading(false);
      });
    });
  };

  const getAdPlatformsFromAsset = (asset) => {
    const { crawlingInfo = {} } = asset;
    const { banned_ad_platform = "" } = crawlingInfo;

    if (banned_ad_platform === "facebook") {
      return ["instagram"];
    }

    if (banned_ad_platform === "instagram") {
      return ["facebook"];
    }

    return ["facebook", "instagram"];
  };

  const onChangeFormData = (name, value, context = null) => {
    if (context) {
      setFormData({
        ...formData,
        [context]: {
          ...formData[context],
          [name]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const checkForErrors = (actionType = null) => {
    return new Promise((resolve, reject) => {
      const formErrors = {};
      for (let field in formData) {
        let errorMessage = null;
        let contextError = {};
        let context = null;
        switch (field) {
          case "crawlers":
            if (actionType === "createTasks" || actionType === "updateTasks") {
              if (formData[field]["initTime"] === "") {
                contextError.initTime = "Init time is mandatory";
                context = field;
              }
              if (
                formData[field]["sla"] === "" ||
                formData[field]["sla"] === null
              ) {
                contextError.sla = "SLA is mandatory";
                context = field;
              }
              if (
                formData[field]["alertSla"] === "" ||
                formData[field]["alertSla"] === null
              ) {
                contextError.alertSla = "Alert SLA is mandatory";
                context = field;
              }
              if (
                formData[field]["adPlatforms"] !== null &&
                formData[field]["adPlatforms"].length === 0
              ) {
                contextError.adPlatforms =
                  "At least one ad platform is mandatory";
                context = field;
              }
            }
            break;
          case "alias":
            if (actionType === "alias") {
              if (formData[field] === "") {
                errorMessage = "Alias is mandatory";
              }
            }
            break;
          default:
            break;
        }
        if (context) {
          formErrors[context] = {
            ...formErrors[context],
            ...contextError,
          };
        } else if (errorMessage) {
          formErrors[field] = errorMessage;
        }
      }

      resolve(formErrors);
    });
  };

  const formAction = (type, value = null) => {
    checkForErrors(type).then((formErrors) => {
      if (Object.keys(formErrors).length === 0) {
        setEditFormParams({
          ...editFormParams,
          updateParam: type,
          updateValue: value,
        });
        switch (type) {
          case "createTasks":
          case "updateTasks":
            onOpenCreateTasksAlert();
            break;
          case "enableCrawling":
          case "disableCrawling":
          case "startCrawling":
          case "stopCrawling":
            onOpenCrawlingStatusAlert();
            break;
          default:
            onOpenUpdateAlert();
            break;
        }
      } else {
        setFormErrors(formErrors);
      }
    });
  };

  // Table schema

  const onCreateActions = (rowData) => {
    const items = [
      {
        action: "post-kpis",
        href: null,
        label: "Post KPIs",
        data: {
          position: 0,
        },
      },
      {
        action: "comment-kpis",
        href: null,
        label: "Comment KPIs",
        data: {
          position: 1,
        },
      },
      {
        action: "linked-accounts",
        href: null,
        label: "Linked Accounts",
        data: {
          position: 2,
        },
      },
    ];

    return (
      <div className={classes.actionsContainer}>
        <Button
          className={classes.customAction}
          key={`action-edit-${rowData.assetId}`}
          onClick={(e) => {
            e.stopPropagation();
            onClickEditAsset(
              rowData.tableData.id,
              rowData.assetId,
              rowData.assetName,
              rowData.clientShortName
            );
          }}
        >
          <EditIcon />
        </Button>
        <Button
          className={classes.customAction}
          key={`action-crawling-${rowData.assetId}`}
          onClick={(e) => {
            e.stopPropagation();
            onClickEditCrawling(
              rowData.tableData.id,
              rowData.assetId,
              rowData.assetName,
              rowData.clientShortName
            );
          }}
        >
          <SettingsIcon />
        </Button>
        <div className={classes.customAction}>
          <SimpleMenu
            clickItemFromComment={(type, data, callback) => {
              clickContextItem(type, data, callback, rowData);
            }}
            items={items}
          />
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      field: "actions",
      Title: "Actions",
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14,
      },
      filtering: false,
      render: (rowData) => onCreateActions(rowData),
    },
    {
      title: "Asset",
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14,
      },
      field: "assetName",
      customFilterAndSearch: (term, rowData) => {
        const str = rowData.assetName + rowData.assetId + rowData.assetUsername;
        return str.toLowerCase().includes(term.toLowerCase());
      },
      render: (rowData) => (
        <React.Fragment>
          <div>{rowData.assetId}</div>
          {rowData.assetAlias !== rowData.assetName && (
            <div>{rowData.assetAlias}</div>
          )}
          <div>{rowData.assetName}</div>
          <div>{rowData.assetUsername}</div>
        </React.Fragment>
      ),
    },
    {
      title: "Platform & Type",
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14,
      },
      field: "assetPlatform",
      customFilterAndSearch: (term, rowData) => {
        const str = rowData.assetPlatform + rowData.assetType;
        return str.toLowerCase().includes(term.toLowerCase());
      },
      filterCellStyle: { color: "red" },
      render: (rowData) => (
        <React.Fragment>
          <div>{rowData.assetPlatform}</div>
          <div>{rowData.assetType}</div>
        </React.Fragment>
      ),
    },
    {
      title: "Client Shortname",
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14,
      },
      field: "clientShortName",
      render: (rowData) => (
        <React.Fragment>
          <div>{rowData.clientShortName}</div>
        </React.Fragment>
      ),
    },
    {
      title: "Active Services",
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14,
      },
      field: "activeServices",
      customFilterAndSearch: (term, rowData) => {
        const str = rowData.activeServices.join();
        return str.toLowerCase().includes(term.toLowerCase());
      },
      render: (rowData) =>
        rowData.activeServices.map((item) => (
          <div key={`active-service-${rowData.assetId}-${item}`}>{item}</div>
        )),
    },
    {
      title: "Crawling & Webhook",
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14,
      },
      field: "crawlingStatus",
      render: (rowData) => {
        let csIcon = null;
        let whsIcon = null;
        if (rowData.crawlingStatus === "disabled") {
          csIcon = (
            <Tooltip title="Crawling is disabled">
              <PanToolIcon color="error" className={classes.statusIcon} />
            </Tooltip>
          );
        } else if (rowData.crawlingStatus === "running") {
          csIcon = (
            <Tooltip title="Crawling is running">
              <PlayCircleFilledIcon className={classes.statusIcon} />
            </Tooltip>
          );
        } else if (rowData.crawlingStatus === "paused") {
          csIcon = (
            <Tooltip title="Crawling is paused">
              <PauseCircleFilledIcon className={classes.statusIcon} />
            </Tooltip>
          );
        } else if (rowData.crawlingStatus === "N/A") {
          csIcon = <span>{rowData.crawlingStatus}</span>;
        }
        if (rowData.webhooks.configured && rowData.webhooks.active) {
          whsIcon = (
            <Tooltip title="Webhooks are configured and active">
              <WifiIcon
                className={classes.statusIcon + " " + classes.statusIconRight}
              />
            </Tooltip>
          );
        } else if (rowData.webhooks.configured || rowData.webhooks.active) {
          whsIcon = (
            <Tooltip
              title={`Webhooks are ${
                rowData.webhooks.configured ? "configured" : "active"
              }`}
            >
              <WifiOffIcon
                className={classes.statusIcon + " " + classes.statusIconRight}
              />
            </Tooltip>
          );
        }
        return (
          <React.Fragment>
            {csIcon}
            {whsIcon}
          </React.Fragment>
        );
      },
    },
    {
      title: "Status",
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14,
      },
      field: "assetStatus",
      customFilterAndSearch: (term, rowData) => {
        const str = rowData.assetStatus + rowData.pendingRemoval;
        return str.toLowerCase().includes(term.toLowerCase());
      },
      render: (rowData) => (
        <React.Fragment>
          <div>{rowData.assetStatus}</div>
          <div style={{ color: "#f66", fontWeight: "bold" }}>
            {rowData.pendingRemoval ? "Removal request" : ""}
          </div>
          <div>
            {rowData.assetVisibility.camp &&
            rowData.assetVisibility.camp !== "public"
              ? `CAMP: ${rowData.assetVisibility.camp}`
              : null}
          </div>
          <div>
            {rowData.assetVisibility.client_camp &&
            rowData.assetVisibility.client_camp !== "public"
              ? `Client CAMP: ${rowData.assetVisibility.client_camp}`
              : null}
          </div>
        </React.Fragment>
      ),
    },
    {
      title: "Last refresh",
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14,
      },
      field: "lastRefresh",
      customFilterAndSearch: (term, rowData) => {
        const str = rowData.lastRefresh + rowData.accessFrom;
        return str.toLowerCase().includes(term.toLowerCase());
      },
      render: (rowData) => (
        <React.Fragment>
          <div>
            Refresh: <i>{rowData.lastRefresh}</i>
          </div>
          <div>
            Since: <i>{rowData.accessFrom}</i>
          </div>
        </React.Fragment>
      ),
    },
  ];

  const showDetails = (rowData) => {
    return (
      <div className={classes.detailContainer}>
        {showDetailsCrawlingInfo(rowData)}
        {showDetailsCredentialsAndOther(rowData)}
        {showDetailsCrawlingHistory(rowData)}
      </div>
    );
  };

  const showDetailsCrawlingInfo = (rowData) => {
    if (Object.keys(rowData.crawlingInfo).length === 0) {
      return (
        <div className={classes.detailItem}>
          <div className={classes.detailLegend}>{"Crawling Info"}</div>
          <div className={classes.detailContent}>Not configured</div>
        </div>
      );
    }

    return (
      <div className={classes.detailItem}>
        <div className={classes.detailLegend}>{"Crawling Info"}</div>
        <div className={classes.detailContent}>
          {rowData.crawlingInfo.init_time ? (
            <div
              className={classes.detailCell}
              key={`ci-${rowData.id}-init_time`}
            >
              {`Init time: ${new Date(
                rowData.crawlingInfo.init_time * 1000
              ).toUTCString()}`}
            </div>
          ) : null}
          {rowData.crawlingInfo.sla ? (
            <div className={classes.detailCell} key={`ci-${rowData.id}-sla`}>
              {`SLA: ${rowData.crawlingInfo.sla}s`}
            </div>
          ) : null}
          {rowData.crawlingInfo.alert_sla ? (
            <div
              className={classes.detailCell}
              key={`ci-${rowData.id}-alert_sla`}
            >
              {`Alert SLA: ${rowData.crawlingInfo.alert_sla}s`}
            </div>
          ) : null}
          {rowData.crawlingInfo.query_only_active &&
          rowData.assetPlatformId === "facebook_ads" ? (
            <div
              className={classes.detailCell}
              key={`ci-${rowData.id}-query_only_active`}
            >
              {`Monitor only active ads: ${
                rowData.crawlingInfo.query_only_active ? "yes" : "no"
              }`}
            </div>
          ) : null}
          {rowData.crawlingInfo.allow_published_posts &&
          rowData.assetPlatformId === "facebook_ads" ? (
            <div
              className={classes.detailCell}
              key={`ci-${rowData.id}-allow_published_posts`}
            >
              {`Allow published posts: ${
                rowData.crawlingInfo.allow_published_posts ? "yes" : "no"
              }`}
            </div>
          ) : null}
          {rowData.crawlingInfo.pull_insights ? (
            <div
              className={classes.detailCell}
              key={`ci-${rowData.id}-pull_insights`}
            >
              {`Pull insights: ${
                rowData.crawlingInfo.pull_insights ? "yes" : "no"
              }`}
            </div>
          ) : null}
        </div>
        {showDetailsWebhookInfo(rowData)}
      </div>
    );
  };

  const showDetailsWebhookInfo = (rowData) => {
    if (!rowData.webhooks || Object.keys(rowData.webhooks).length === 0) {
      return null;
    }

    if (
      (rowData.webhooks.configured || false) === false &&
      (rowData.webhooks.active || false) === false
    ) {
      return null;
    }

    return (
      <div>
        <div className={classes.detailLegend}>{"Webhook Info"}</div>
        <div className={classes.detailContent}>
          <div
            className={classes.detailCell}
            key={`ci-${rowData.id}-configured`}
          >
            Installed on page (configured):{" "}
            <em>{`${rowData.webhooks.configured || false ? `yes` : `no`}`}</em>
          </div>
          <div className={classes.detailCell} key={`ci-${rowData.id}-active`}>
            Streaming data to CAMP (active):{" "}
            <em>{`${rowData.webhooks.active || false ? `yes` : `no`}`}</em>
          </div>
          <div
            className={classes.detailCell}
            key={`ci-${rowData.id}-publishedStatus`}
          >
            Webhook Published Status:{" "}
            <em>{`${rowData.webhooks.publishedStatus}`}</em>
          </div>
        </div>
      </div>
    );
  };

  const showDetailsCredentialsAndOther = (rowData) => {
    return (
      <div className={classes.detailItem}>
        {showDetailsCredentialTypes(rowData)}
        {showDetailsPermissions(rowData)}
        {showDetailsCredentialStatus(rowData)}
        {showDetailsBmOwner(rowData)}
        {showDetailsLinkedAccounts(rowData)}
      </div>
    );
  };

  const showDetailsCredentialTypes = (rowData) => {
    if (rowData.credentialTypes.length === 0) {
      return null;
    }

    return (
      <div className={classes.detailItem}>
        <div className={classes.detailLegend}>{"Credential Types"}</div>
        <div className={classes.detailContent}>
          {rowData.credentialTypes &&
            rowData.credentialTypes.map((ct, index) => {
              return (
                <div
                  className={classes.detailCell}
                  key={`ct-${rowData.id}-${index}`}
                >
                  {ct}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const showDetailsPermissions = (rowData) => {
    if (Object.keys(rowData.permissions).length === 0) {
      return null;
    }

    return (
      <div className={classes.detailItem}>
        <div className={classes.detailLegend}>{"Permissions"}</div>
        <div className={classes.detailContent}>
          {Object.keys(rowData.permissions).length > 0 &&
            Object.keys(rowData.permissions).map((perGroupKey) => {
              return (
                <div
                  className={classes.detailCell}
                  key={`pergrp-${rowData.id}-${perGroupKey}`}
                >
                  {`${perGroupKey}: `}
                  {rowData.permissions[perGroupKey]
                    .toString()
                    .split(",")
                    .join(", ")}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const showDetailsCredentialStatus = (rowData) => {
    if (Object.keys(rowData.credentialStatus).length === 0) {
      return null;
    }

    return (
      <div className={classes.detailItem}>
        <div className={classes.detailLegend}>{"Credential Status"}</div>
        <div className={classes.detailContent}>
          {rowData.assetStatus ? (
            <div
              className={classes.detailCell}
              key={`cs-${rowData.id}-asset_status`}
            >
              {`Status: ${rowData.assetStatus}`}
            </div>
          ) : null}
          {rowData.credentialStatus["facebook_bm"] ? (
            <div
              className={classes.detailCell}
              key={`cs-${rowData.id}-facebook_bm`}
            >
              {`Last found in BM: ${rowData.credentialStatus["facebook_bm"]}`}
            </div>
          ) : null}
          {rowData.credentialStatus["youtube"] ? (
            <div
              className={classes.detailCell}
              key={`cs-${rowData.id}-youtube`}
            >
              {`Youtube credetials since: ${rowData.credentialStatus["youtube"]}`}
            </div>
          ) : null}
          {rowData.credentialStatus["fb_external_credentials"] ? (
            <div
              className={classes.detailCell}
              key={`cs-${rowData.id}-fb_external_credentials`}
            >
              {`Sales credentials since: ${rowData.credentialStatus["fb_external_credentials"]}`}
            </div>
          ) : null}
          {rowData.pendingRemoval ? (
            <div
              className={classes.detailCell}
              key={`cs-${rowData.id}-pending_removal`}
            >
              <i>{`Removal requested by ${
                rowData.removalRequestUserId
              } at ${new Date(
                rowData.removalRequestTime * 1000
              ).toUTCString()}`}</i>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const showDetailsCrawlingHistory = (rowData) => {
    if (rowData.crawlingHistory.length === 0) {
      return null;
    }

    return (
      <div className={classes.detailItem}>
        <div className={classes.detailLegend}>{"Crawling History"}</div>
        <div className={classes.detailContent}>
          <ul>
            {rowData.crawlingHistory &&
              rowData.crawlingHistory.map((ch, index) => {
                return (
                  <li key={`li-${rowData.id}-${index}`}>
                    {ch.type ? (
                      <div
                        className={classes.detailCell}
                        key={`ch-${rowData.id}-${index}-type`}
                      >{`Action type: ${ch.type}`}</div>
                    ) : null}
                    {ch.time ? (
                      <div
                        className={classes.detailCell}
                        key={`ch-${rowData.id}-${index}-time`}
                      >{`Time: ${new Date(ch.time * 1000).toUTCString()}`}</div>
                    ) : null}
                    {ch.user ? (
                      <div
                        className={classes.detailCell}
                        key={`ch-${rowData.id}-${index}-user`}
                      >{`User: ${ch.user}`}</div>
                    ) : null}
                    {ch.crawling_values ? (
                      <div
                        className={classes.detailCell}
                        key={`ch-${rowData.id}-${index}-crawling_values`}
                      >
                        Crawling Values:{" "}
                        <ul>
                          {ch.crawling_values.init_time ? (
                            <li
                              key={`ch-${rowData.id}-${index}-crawling_values-init_time`}
                            >{`Init time: ${new Date(
                              ch.crawling_values.init_time * 1000
                            ).toUTCString()}`}</li>
                          ) : null}
                          {ch.crawling_values.init_time ? (
                            <li
                              key={`ch-${rowData.id}-${index}-crawling_values-sla`}
                            >{`SLA: ${ch.crawling_values.sla}s`}</li>
                          ) : null}
                          {ch.crawling_values.alert_sla ? (
                            <li
                              key={`ch-${rowData.id}-${index}-crawling_values-alert_sla`}
                            >{`Alert SLA: ${ch.crawling_values.alert_sla}s`}</li>
                          ) : null}
                          {ch.crawling_values.allow_published_posts ? (
                            <li
                              key={`ch-${rowData.id}-${index}-crawling_values-allow_published_posts`}
                            >{`Allow published posts: ${ch.crawling_values.allow_published_posts}`}</li>
                          ) : null}
                          {ch.crawling_values.pull_insights ? (
                            <li
                              key={`ch-${rowData.id}-${index}-crawling_values-pull_insights`}
                            >{`Pull insights: ${ch.crawling_values.pull_insights}`}</li>
                          ) : null}
                          {ch.crawling_values.query_only_active ? (
                            <li
                              key={`ch-${rowData.id}-${index}-crawling_values-query_only_active`}
                            >{`Get only active ads: ${ch.crawling_values.query_only_active}`}</li>
                          ) : null}
                        </ul>
                      </div>
                    ) : null}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    );
  };

  const showDetailsLinkedAccounts = (rowData) => {
    if (Object.keys(rowData.linkedAccounts).length === 0) {
      return null;
    }

    return (
      <div className={classes.detailItem}>
        <div className={classes.detailLegend}>{"Linked Accounts"}</div>
        <div className={classes.detailContent}>
          {rowData.linkedAccounts.length > 0 &&
            rowData.linkedAccounts.map((la, index) => {
              return (
                <div
                  className={classes.detailCell}
                  key={`la-${rowData.id}-${index}`}
                >
                  {index + 1}.&nbsp;&nbsp;{la.asset_name} ({`${la.asset_id}`}) -{" "}
                  {`${la.platform}`}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const showDetailsBmOwner = (rowData) => {
    if (Object.keys(rowData.bmOwner).length === 0) {
      return null;
    }

    return (
      <div className={classes.detailItem}>
        <div className={classes.detailLegend}>{"Owner Business Manager"}</div>
        <div className={classes.detailContent}>
          {rowData.bmOwner.id ? (
            <div className={classes.detailCell} key={`ci-${rowData.id}-id`}>
              {`ID: ${rowData.bmOwner.id}`}
            </div>
          ) : null}
          {rowData.bmOwner.name ? (
            <div className={classes.detailCell} key={`ci-${rowData.id}-name`}>
              {`Name: ${rowData.bmOwner.name}`}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const tableDetailPanel = [
    {
      tooltip: "Show Asset Details",
      render: (rowData) => showDetails(rowData),
    },
  ];

  const clickContextItem = (type, data, callback, rowData) => {
    redirectTaskKPIsPage(rowData.assetId, data.position);
    callback();
  };

  const redirectTaskKPIsPage = (assetId, value) => {
    setRedirectTaskKPIs(assetId);
    setHasDefaultValue(value);
  };

  const onChangeBatchSelections = (rows) => {
    setValidBatchClientShortName(rows.length ? rows[0].clientShortName : null);
    setValidBatchCrawlingStatus(rows.length ? rows[0].crawlingStatus : null);
    setValidBatchPlatformAds(rows.length ? rows[0].isAdAsset : null);
  };

  const onClickDeselectAll = () => {
    setValidBatchClientShortName(null);
    setValidBatchCrawlingStatus(null);
    setValidBatchPlatformAds(null);
    setBatchAssetIds([]);
    setAssetList(
      assetManagementList.map((a) => {
        a.tableData = {
          checked: false,
        };
        return a;
      })
    );
  };

  useEffect(() => {
    let firstAsset = null;
    setAssetList(
      assetManagementList.map((a) => {
        const isAssetSelected = batchAssetIds.indexOf(a.assetId) > -1;
        if (!firstAsset && isAssetSelected) {
          firstAsset = a;
        }
        a.tableData = {
          checked: isAssetSelected,
        };
        return a;
      })
    );
    if (firstAsset) {
      setValidBatchCrawlingStatus(firstAsset.crawlingStatus);
    }
  }, [assetManagementList]);

  if (redirectTaskKPIs) {
    return (
      <Redirect
        to={{
          pathname: `/task-kpis/${redirectTaskKPIs}`,
          state: {
            assetId: redirectTaskKPIs,
            hasDefaultValue: hasDefaultValue,
          },
        }}
      />
    );
  } else
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h5">Asset Management</Typography>
        </div>
        <TableComponent
          editable={false}
          columns={tableColumns}
          data={assetList}
          title=""
          detailPanel={tableDetailPanel}
          options={{
            toolbarButtonAlignment: "left",
            showSelectAllCheckbox: false,
            pageSizeOptions: [5, 10, 20, 50, 100],
            filtering: true,
            selection: true,
            selectionProps: (rowData) => ({
              disabled:
                (validBatchClientShortName !== null &&
                  rowData.clientShortName !== validBatchClientShortName) ||
                (validBatchCrawlingStatus !== null &&
                  rowData.crawlingStatus !== validBatchCrawlingStatus) ||
                (validBatchPlatformAds !== null &&
                  rowData.isAdAsset &&
                  !validBatchPlatformAds) ||
                (!rowData.isAdAsset && validBatchPlatformAds),
              color: "primary",
            }),
          }}
          onSelectionChange={onChangeBatchSelections}
          actions={[
            {
              tooltip: "Deselect All",
              icon: "backspace",
              onClick: () => {
                onClickDeselectAll();
              },
            },
            {
              tooltip: "Batch Edit Assets",
              icon: "edit",
              onClick: (evt, data) => {
                onClickEditAssetBatch(data);
              },
            },
            {
              tooltip: "Batch Crawling Configuration",
              icon: "settings",
              onClick: (evt, data) => {
                onClickEditCrawlingBatch(data);
              },
            },
          ]}
        />
        <DialogContent
          title={`Edit Asset: ${
            isEditBatch
              ? "Batch Operation"
              : editFormParams.assetName + " - " + editFormParams.assetIds[0]
          }`}
          open={isFormOpen}
          fullWidth={true}
          close={onCloseDialogForm}
        >
          <div>
            <AssetManagementForm
              formData={formData}
              errors={formErrors}
              onChangeFormData={onChangeFormData}
              isLoading={isFormLoading}
              isCSNAlreadyAssigned={isCSNAlreadyAssigned}
              formActionsLoading={formActionsLoading}
              formAction={formAction}
              formOptions={formOptions}
              formPermissions={formPermissions}
              isEditBatch={isEditBatch}
            />
            <div className={classes.buttonContainer}>
              <Button
                onClick={(e) => {
                  return onCloseDialogForm(e);
                }}
                size="small"
                color="secondary"
              >
                Close
              </Button>
            </div>
          </div>
        </DialogContent>
        <DialogContent
          title={`Asset Crawling Configuration: ${
            isEditBatch
              ? "Batch Operation"
              : editFormParams.assetName + " - " + editFormParams.assetIds[0]
          }`}
          open={isCrawlingFormOpen}
          fullWidth={true}
          close={onCloseDialogCrawlingForm}
        >
          <div>
            <CrawlingForm
              formData={formData}
              errors={formErrors}
              onChangeFormData={onChangeFormData}
              isLoading={isFormLoading}
              isCSNAlreadyAssigned={isCSNAlreadyAssigned}
              isEditBatch={isEditBatch}
              formActionsLoading={formActionsLoading}
              formAction={formAction}
              formOptions={formOptions}
              formPermissions={crawlingPermissions}
            />
            <div className={classes.buttonContainer}>
              <Button
                onClick={(e) => {
                  return onCloseDialogCrawlingForm(e);
                }}
                size="small"
                color="secondary"
              >
                Close
              </Button>
            </div>
          </div>
        </DialogContent>
        <AlertDialog
          title={`Confirm Changes${isEditBatch ? " (Batch)" : ""}`}
          message={`Are you sure you want to update ${
            isEditBatch
              ? "selected assets"
              : "asset '" + editFormParams.assetName + "'"
          } and change '${editFormParams.updateParam}' value to ${
            typeof editFormParams.updateValue === "object" &&
            Array.isArray(editFormParams.updateValue) === false
              ? "configured parameters"
              : "'" + editFormParams.updateValue + "'"
          }?`}
          buttonCancelText="Cancel"
          buttonAcceptText="Accept"
          open={isUpdateAlertOpen}
          action={onActionAlert}
        />
        <AlertDialog
          title={`${
            editFormParams.updateParam === "createTasks" ? "Create" : "Update"
          } Tasks${isEditBatch ? " (Batch)" : ""}`}
          message={`Are you sure you want to ${
            editFormParams.updateParam === "createTasks" ? "create" : "update"
          } tasks for the ${
            isEditBatch
              ? "selected assets"
              : "asset '" + editFormParams.assetName + "'"
          }?`}
          buttonCancelText="Cancel"
          buttonAcceptText="Accept"
          open={isCreateTasksAlertOpen}
          action={onActionAlert}
        />
        <AlertDialog
          title={`Update Crawling Status${isEditBatch ? " (Batch)" : ""}`}
          message={`Are you sure you want to update crawling status for the ${
            isEditBatch
              ? "selected assets"
              : "asset '" + editFormParams.assetName + "'"
          } and ${
            editFormParams.updateParam &&
            editFormParams.updateParam.replace("Crawling", "")
          } crawling?`}
          buttonCancelText="Cancel"
          buttonAcceptText="Accept"
          open={isCrawlingStatusAlertOpen}
          action={onActionAlert}
        />
      </div>
    );
};

AssetManagementConfiguration.propTypes = {
  assetManagementList: PropTypes.array.isRequired,
};

export default AssetManagementConfiguration;
