import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";

import configureStore from "./store/store";

import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import Amplify, { Auth } from "aws-amplify";
import { CookiesProvider } from "react-cookie";

const awsconfig = {
  Auth: {
    //identityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID,
    region: process.env.AWS_COGNITO_REGION,
    identityPoolRegion: process.env.AWS_COGNITO_IDENTITY_POOL_REGION,
    userPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.AWS_OAUTH_DOMAIN,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: process.env.AWS_OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.AWS_OAUTH_REDIRECT_SIGN_OUT,
      responseType: process.env.AWS_OAUTH_RESPONSE_TYPE, // code
    },
    Analytics: {
      disabled: true,
    },
  },
};

// your Cognito Hosted UI configuration
Amplify.configure(awsconfig);

Auth.configure(awsconfig);

if (process.env.SENTRY_URL) {
  Sentry.init({ dsn: process.env.SENTRY_URL });
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
