import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import TagsExampleConfiguration from './tagsExampleConfiguration';

import { fetchTagsList } from '../../../actions/configuration/tagsAction';
import {
  fetchTagsExampleList,
  editTagsExample
} from '../../../actions/configuration/tagsExampleActions';

const styles = theme => ({
  root: {
    width: '100%'
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 440px)',
    marginTop: 'calc(50vh - 96px)'
  }
});

const mapStateToProps = state => ({
  tagsExample: state.tagsExampleReducer,
  tags: state.tagsReducer
});

const mapDispatchToProps = dispatch => ({
  fetchTagsList: () => dispatch(fetchTagsList()),
  fetchTagsExampleList: clientShortName =>
    dispatch(fetchTagsExampleList(clientShortName)),
  editTagsExample: (clientShortName, index, data) =>
    dispatch(editTagsExample(clientShortName, index, data))
});

class TagsExampleContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
      clientShortName: props.clientShortName,
      editable: false,
      loading: true,
      notAllowed: false,
      tagsExampleList: [],
      tagList: []
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.tags.tagList !== prevState.tagList) {
      update.tagList = nextProps.tags.tagList;
    }

    if (nextProps.tagsExample.tagsExampleList !== prevState.tagsExampleList) {
      update.tagsExampleList = nextProps.tagsExample.tagsExampleList;
    }

    return Object.keys(update).length ? update : null;
  }

  addTagsExample(data) {
    this.props.addTagsExample(this.state.clientShortName, data);
  }

  editTagsExample(index, data) {
    this.props.editTagsExample(this.state.clientShortName, index, data);
  }

  initialize() {
    if (this.props.permissions.includes('admin_list_client_examples')) {
      this.props.fetchTagsList().then(() => {
        this.props.fetchTagsExampleList(this.state.clientShortName).then(() => {
          this.setState({
            loading: false
          });
        });
      });

      if (this.props.permissions.includes('admin_update_client_example')) {
        this.setState({
          editable: true
        });
      }
    } else {
      this.setState({
        notAllowed: true,
        loading: false
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tabIndex === 0 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true
        },
        this.initialize
      );
    }
  }

  componentDidMount() {
    if (this.props.tabIndex === 0 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true
        },
        this.initialize
      );
    }
  }

  render() {
    const { classes } = this.props;
    const {
      loading,
      notAllowed,
      editable,
      tagsExampleList,
      tagList,
      clientShortName
    } = this.state;

    return (
      <div className={classes.root}>
        {!notAllowed && !loading ? (
          <TagsExampleConfiguration
            editable={editable}
            addTagsExample={this.addTagsExample.bind(this)}
            editTagsExample={this.editTagsExample.bind(this)}
            tagList={tagList}
            tagsExampleList={tagsExampleList}
            clientShortName={clientShortName}
          />
        ) : !notAllowed ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <Typography className={classes.notAllowed} variant="body1">
            Not Allowed
          </Typography>
        )}
      </div>
    );
  }
}

TagsExampleContainer.propTypes = {
  clientShortName: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  tabIndex: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TagsExampleContainer));
