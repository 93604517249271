import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Typography from "@material-ui/core/Typography";

import IconComponent from "../icon/IconComponent";
import AlertDialog from "../dialog/alertDialogComponent";
import Tooltip from "../tooltip/TooltipComponent";

//TODO: https://github.com/mui-org/material-ui/issues/16135
import styled from "styled-components";
import { compose, css, display, spacing, palette } from "@material-ui/system";

const Box = styled("div")`
  ${display}${css(compose(spacing, palette))}
`;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    borderRight: `1px solid ${theme.palette.divider}`,
    height: "100vh",
    overflow: "hidden",
    minWidth: 140,
    boxShadow: theme.palette.shadow.right,
    backgroundColor: theme.palette.primary.lightDark,
    color: theme.palette.colorStreamList,
  },
  itemListsContainer: {
    overflowY: "auto",
    height: "calc(100vh - 48px)",
  },
  fullWidth: {
    minWidth: 240,
  },
  drawer: {
    width: theme.drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerClose: {
    overflowX: "hidden",
    width: theme.spacing(7 + 1),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  itemContainer: {},
  itemsContainer: {
    minHeight: 170,
  },
  otherContainer: {
    minHeight: 70,
  },
  favouriteContainer: {
    minHeight: 100,
    overflow: "hidden",
    width: "100%",
    "& ul": {
      minHeight: 80,
    },
  },
  listItem: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 2,
    paddingBottom: 2,
  },
  listItemSelected: {
    backgroundColor: `${
      theme.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.primary.dark
    } !important`,
  },
  listItemColumn: {
    flexDirection: "column",
  },
  listItemText: {
    textAlign: "left",
    "& span": {
      fontSize: 12,
      textOverflow: "ellipsis",
      overflow: "hidden",
      width: "fit-content",
      maxWidth: 130,
      whiteSpace: "nowrap",
      display: "block",
    },
  },
  listItemTextMargin: {
    margin: theme.spacing(0, 0, 0, 1),
  },
  listItemIcon: {
    minWidth: 25,
    height: 22,
    justifyContent: "center",
    marginRight: 0,
  },
  title: {
    padding: theme.spacing(1),
    backgroundColor:
      theme.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.primary.dark,
  },
  groupContainer: {},
  groupTitle: {
    textAlign: "left",
    marginLeft: "24px",
    fontWeight: "bold",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  groupItem: {
    marginLeft: "8px",
  },
  groupHideIcon: {
    width: 16,
    height: 16,
    marginLeft: 8,
  },
});

class NotificationMenuComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.items[0]["id"],
      openDialogChange: false,
      openDialogDelete: false,
      enableFavorite: props.enableFavorite,
      preSelected: 0,
      loaded: props.loaded || null,
      index: props.index,
      openDialogCommentProcess: false,
      isClassifying: false,
      isAddingTag: false,
      itemDelete: {
        id: null,
        key: null,
      },
      hiddenGroups: [],
    };

    this.deleteItem.bind(this);
  }

  updateSelected(e, selectedId, firstLoad = false) {
    if (selectedId === this.state.selected && !firstLoad) {
      return false;
    }

    if (e && e.target.innerText === "delete") {
      this.setState({
        preSelected: selectedId,
      });
      return false;
    }

    if (this.props.moderation) {
      if (this.state.isAddingTag || this.state.isClassifying) {
        this.setState({
          openDialogCommentProcess: true,
        });
      } else {
        this.setState({
          openDialogChange: true,
          preSelected: selectedId,
        });
      }
    } else {
      this.setState({ selected: selectedId });
      this.props.selected(selectedId, firstLoad);
    }
  }

  warningDelete(id, key) {
    this.setState({
      openDialogDelete: true,
      itemDelete: {
        id: id,
        key: key.toString(),
      },
    });
  }

  deleteItem(item) {
    if (typeof this.props.deleteItem === "function") {
      this.props.deleteItem(item);
    }
  }

  actionDialogCommentProcess() {
    this.setState({
      openDialogCommentProcess: false,
    });
  }

  actionDialogChange(state) {
    if (state) {
      this.setState({
        selected: this.state.preSelected,
        openDialogChange: false,
      });
      this.props.selected(this.state.preSelected);
    } else {
      this.setState({
        openDialogChange: false,
      });
    }
  }

  actionDialogDelete(state) {
    if (state) {
      this.setState({
        selected: this.state.preSelected,
        openDialogDelete: false,
      });
      this.deleteItem(this.state.itemDelete);
    } else {
      this.setState({
        openDialogDelete: false,
      });
    }
  }

  onDragStart(e, key) {
    e.dataTransfer.setData("id", key);
  }

  onDrop(e, type) {
    if (typeof this.props.addFavourite === "function") {
      this.props.addFavourite(e.dataTransfer.getData("id"), type);
    }
  }

  onDragOver(e) {
    e.preventDefault();
  }

  toggleHideGroup(groupKey) {
    const hgs = this.state.hiddenGroups;
    if (hgs.indexOf(groupKey) !== -1) {
      this.setState({
        hiddenGroups: hgs.filter((key) => key !== groupKey),
      });
    } else {
      this.setState({
        hiddenGroups: [...hgs, groupKey],
      });
    }
  }

  setItemList(status, draggable = false, specific = false) {
    const { items, moderation, classes, hasGroupedItems } = this.props;
    const { selected, loaded, hiddenGroups } = this.state;

    const itemsGrouped = hasGroupedItems
      ? this.props.itemsGrouped
      : {
          "{none}": items,
        };

    let jsx = [];
    for (const groupKey in itemsGrouped) {
      const isGrouped = groupKey !== "{none}";
      const hasFavoriteGrouped = itemsGrouped[groupKey].some(
        (it) => it.favourite
      );
      const hasUnfavoriteGrouped = itemsGrouped[groupKey].some(
        (it) => !it.favourite
      );
      const isGroupHidden = hiddenGroups.indexOf(groupKey) !== -1;

      let totals = 0;
      itemsGrouped[groupKey].map((item) => {
        if (status) {
          totals += item.favourite ? item.notification_count : 0;
        } else {
          totals += !item.favourite ? item.notification_count : 0;
        }
        return true;
      });

      jsx.push(
        <div className={classes.groupContainer} key={groupKey}>
          {isGrouped && (status ? hasFavoriteGrouped : hasUnfavoriteGrouped) && (
            <div
              onClick={() => this.toggleHideGroup(groupKey)}
              className={classes.groupTitle}
            >
              {groupKey}{" "}
              {isGroupHidden ? (
                <ArrowLeftIcon className={classes.groupHideIcon} />
              ) : (
                <ArrowDropDownIcon className={classes.groupHideIcon} />
              )}
              &nbsp;&nbsp;&nbsp;
              <ListItemIcon className={classes.listItemIcon}>
                <IconComponent
                  selected={false}
                  favourite={false}
                  deleteIcon={false}
                  invisible={totals === 0}
                  variant={"standard"}
                  badgeContent={totals}
                  iconName={"fas fa-wave-square"}
                />
              </ListItemIcon>
            </div>
          )}
          {!isGroupHidden &&
            itemsGrouped[groupKey]
              .filter((item) => {
                if (specific) {
                  return item.own === specific;
                }
                return item.favourite === status && !item.own;
              })
              .map((item, key) => {
                return (
                  <div
                    key={key}
                    draggable={draggable ? true : false}
                    className={`draggable${
                      isGrouped ? " " + classes.groupItem : ""
                    }`}
                    onDragStart={(e) => this.onDragStart(e, item.id)}
                  >
                    <Tooltip hidden={!moderation} title={<p>{item.label}</p>}>
                      <ListItem
                        dense
                        onClick={(e) => {
                          return loaded === null || loaded
                            ? this.updateSelected(e, item.id, false)
                            : false;
                        }}
                        selected={selected === item.id}
                        className={classNames(classes.listItem, {
                          [classes.listItemColumn]:
                            typeof item.notification_count !== "number",
                        })}
                        classes={{
                          selected: classes.listItemSelected,
                        }}
                        disabled={loaded !== null ? !loaded : false}
                      >
                        <ListItemText
                          className={classNames(classes.listItemText, {
                            [classes.listItemTextMargin]:
                              typeof item.notification_count === "number",
                          })}
                          primary={item.label}
                        />
                        <ListItemIcon className={classes.listItemIcon}>
                          <IconComponent
                            selected={selected === item.id}
                            favourite={item.favourite}
                            deleteIcon={item.own ? true : false}
                            deleteAction={(e) =>
                              this.warningDelete(item.id, item.key)
                            }
                            invisible={item.notification_count === 0}
                            variant={"standard"}
                            deleting={item.deleting ? item.deleting : false}
                            badgeContent={item.notification_count}
                            iconName={"fas fa-wave-square"}
                          />
                        </ListItemIcon>
                      </ListItem>
                    </Tooltip>
                    <Divider variant="middle" />
                  </div>
                );
              })}
        </div>
      );
    }

    return jsx;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loaded !== this.props.loaded) {
      this.setState({
        loaded: this.props.loaded,
      });
    }

    if (prevProps.isClassifying !== this.props.isClassifying) {
      this.setState({
        isClassifying: this.props.isClassifying,
      });
    }

    if (prevProps.isAddingTag !== this.props.isAddingTag) {
      this.setState({
        isAddingTag: this.props.isAddingTag,
      });
    }

    if (prevProps.index !== this.props.index) {
      this.setState({
        index: this.props.index,
        selected: this.props.items[this.props.index]["id"],
      });
    }
  }

  componentDidMount() {
    const { selected } = this.state;
    this.updateSelected(null, selected, true);
  }

  render() {
    const { classes, theme, action, items, title, fullWidth } = this.props;
    const {
      openDialogChange,
      openDialogDelete,
      openDialogCommentProcess,
      enableFavorite,
    } = this.state;
    return (
      <div
        className={classNames(classes.root, {
          [classes.fullWidth]: fullWidth,
        })}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={action}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <div className={classes.itemListsContainer}>
          {enableFavorite ? (
            <Box
              onDragOver={(e) => this.onDragOver(e)}
              onDrop={(e) => {
                this.onDrop(e, "favourite");
              }}
              className={classNames(classes.favouriteContainer, {
                droppable: true,
              })}
            >
              <Typography className={classes.title} variant="body2">
                Favorite
              </Typography>
              <List>{this.setItemList(true, true)}</List>
            </Box>
          ) : (
            ""
          )}
          <Typography variant="body2" className={classes.title}>
            {title}
          </Typography>
          <Box
            onDragOver={(e) => this.onDragOver(e)}
            onDrop={(e) => {
              this.onDrop(e, "items");
            }}
            className={classNames(classes.itemsContainer, {
              droppable: true,
            })}
          >
            <List className={classes.itemContainer}>
              {this.setItemList(false, true)}
            </List>
          </Box>
          {items.filter((item) => item.own === true).length ? (
            <div>
              <Divider variant="middle" />
              <Typography className={classes.title} variant="body2">
                Other Items
              </Typography>
              <Box
                className={classNames(classes.itemsContainer, {
                  [classes.otherContainer]: true,
                })}
              >
                <List className={classes.itemContainer}>
                  {this.setItemList(false, false, true)}
                </List>
              </Box>
            </div>
          ) : (
            ""
          )}
        </div>
        <AlertDialog
          title="Comments in progress"
          message="Some comments are processing, you cannot change the stream yet."
          open={openDialogCommentProcess}
          buttonCancelText="ok"
          action={this.actionDialogCommentProcess.bind(this)}
        />
        <AlertDialog
          title="Are you sure?"
          message="change the project will stop the current moderation"
          buttonCancelText="Cancel"
          buttonAcceptText="Accept"
          open={openDialogChange}
          action={this.actionDialogChange.bind(this)}
        />
        <AlertDialog
          title="Are you sure?"
          message="It will delete the item permanently."
          buttonCancelText="Cancel"
          buttonAcceptText="Accept"
          open={openDialogDelete}
          action={this.actionDialogDelete.bind(this)}
        />
      </div>
    );
  }
}

NotificationMenuComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isAddingTag: PropTypes.bool,
  isClassifying: PropTypes.bool,
  selected: PropTypes.func.isRequired,
  enableFavorite: PropTypes.bool,
  moderation: PropTypes.bool,
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  addFavourite: PropTypes.func,
  deleteItem: PropTypes.func,
  fullWidth: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  loaded: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(
  NotificationMenuComponent
);
