import { salesConstants } from '../constants/salesConstants';

const initialState = {
  fetching: false,
  fetched: false,
  ownerList: null,
  list: null,
  error: null
};

const Sales = (state = initialState, action) => {
  switch (action.type) {
    // FETCH OWNER LIST
    case `${salesConstants.FETCH_OWNER_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${salesConstants.FETCH_OWNER_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${salesConstants.FETCH_OWNER_LIST}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        ownerList: action.payload.data.items
      };

    // CREATE NEW CODE
    case `${salesConstants.CREATE_NEW_CODE}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${salesConstants.CREATE_NEW_CODE}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${salesConstants.CREATE_NEW_CODE}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [state.list.length]: action.payload.config.newCode
        })
      };

    // EDIT CODE
    case `${salesConstants.EDIT_CODE}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${salesConstants.EDIT_CODE}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${salesConstants.EDIT_CODE}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: action.payload.config.code
        })
      };

    // DELETE CODE
    case `${salesConstants.DELETE_CODE}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${salesConstants.DELETE_CODE}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${salesConstants.DELETE_CODE}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: state.list.filter(
          (item, key) => key !== action.payload.config.index
        )
      };

    // FETCH_LIST_ANALYSIS
    case `${salesConstants.FETCH_LIST_ANALYSIS}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${salesConstants.FETCH_LIST_ANALYSIS}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${salesConstants.FETCH_LIST_ANALYSIS}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.data.items
      };

    default:
      return state;
  }
};

export default Sales;
