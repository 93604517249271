import axios from "axios";

import { actionsConstants } from "../../constants/configuration/actionsConstants";
import { setHeaders } from "../../setters/auth";

export const fetchActionList = (clientShortName) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/actions`;

  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: actionsConstants.FETCH_ACTION_LIST,
    payload: promise,
  });
};

export const fetchActionTypeList = (clientShortName) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/actions/action-types`;

  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: actionsConstants.FETCH_ACTION_TYPE_LIST,
    payload: promise,
  });
};

export const addAction = (clientShortName, data) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/actions`;

  let filters = data.filters.length
    ? data.filters.concat({
        field: "tags",
        type: "contains_all",
        values: data.tags,
      })
    : [
        {
          field: "tags",
          type: "contains_all",
          values: data.tags,
        },
      ];

  let actions = {
    action_id: data.id,
    type: data.type,
    filters: filters,
  };

  if (data.tags && data.timeout && data.time_interval) {
    actions = {
      ...actions,
      volume_config: {
        time_interval: data.time_interval,
        timeout: data.timeout,
        tags: data.tags,
      },
    };
  }

  const promise = axios.post(url, actions, {
    headers: setHeaders(),
    actionInfo: data,
  });

  return dispatch({
    type: actionsConstants.ADD_ACTION,
    payload: promise,
  });
};

export const editAction = (clientShortName, index, data) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/actions/${data.id}`;

  let filters = data.filters.length
    ? data.filters.concat({
        field: "tags",
        type: "contains_all",
        values: data.tags,
      })
    : [
        {
          field: "tags",
          type: "contains_all",
          values: data.tags,
        },
      ];

  let actions = {
    type: data.type,
    filters: filters,
    replier_asset_id: data.replier_asset_id,
  };

  if (data.tags && data.timeout && data.time_interval) {
    actions = {
      ...actions,
      volume_config: {
        time_interval: data.time_interval,
        timeout: data.timeout,
        tags: data.tags,
      },
    };
  }

  const promise = axios.put(url, actions, {
    headers: setHeaders(),
    index: index,
    actionInfo: data,
  });

  return dispatch({
    type: actionsConstants.EDIT_ACTION,
    payload: promise,
  });
};

export const deleteAction = (clientShortName, index) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/actions/${index}`;

  const promise = axios.delete(url, {
    headers: setHeaders(),
    index: index,
  });

  return dispatch({
    type: actionsConstants.DELETE_ACTION,
    payload: promise,
  });
};
