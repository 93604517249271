import axios from 'axios';

import { MGConstants } from '../../constants/configuration/MGConstants';
import { setHeaders } from '../../setters/auth';

export const resetFetch = () => dispatch => {
  const promise = new Promise((resolve, reject) => {
    resolve(); // fulfilled
    reject('failure decrase notification count');
  });

  return dispatch({
    type: MGConstants.RESET_FETCH_MODERATION_GUIDELINES,
    payload: promise
  });
};

export const fetchModerationGuidelinesList = clientShortName => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/moderation-guidelines`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: MGConstants.FETCH_MODERATION_GUIDELINES_LIST,
    payload: promise
  });
};

export const updateModerationGuidelines = (
  clientShortName,
  index,
  data
) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/moderation-guidelines/tags/${data.tag.id}`;

  let configs = data.guidelines.map(guideline => {
    return {
      guideline_group_id: guideline.id,
      hide_action: {
        enabled: guideline.hide_action.enabled,
        exception_tag_ids: guideline.hide_action.exceptions
      },
      volume_action: {
        enabled:
          guideline.volume_action &&
          guideline.volume_action.enabled !== undefined
            ? guideline.volume_action.enabled
            : false,
        threshold:
          guideline.volume_action &&
          guideline.volume_action.threshold &&
          guideline.volume_action.threshold >= 1
            ? guideline.volume_action.threshold
            : 1,
        time_interval:
          guideline.volume_action &&
          guideline.volume_action.time_interval &&
          guideline.volume_action.time_interval >= 3600
            ? guideline.volume_action.time_interval
            : 3600,
        timeout:
          guideline.volume_action &&
          guideline.volume_action.timeout &&
          guideline.volume_action.timeout >= 300
            ? guideline.volume_action.timeout
            : 300,
        emails:
          guideline.volume_action && guideline.volume_action.emails
            ? guideline.volume_action.emails
            : []
      },
      alert_action: {
        enabled:
          guideline.alert_action && guideline.alert_action.enabled !== undefined
            ? guideline.alert_action.enabled
            : false,
        exception_tag_ids:
          guideline.alert_action && guideline.alert_action.exceptions
            ? guideline.alert_action.exceptions
            : [],
        emails:
          guideline.alert_action && guideline.alert_action.emails
            ? guideline.alert_action.emails
            : []
      },
      competitor_description: guideline.competitor_description
    };
  });

  const promise = axios.put(
    url,
    { configs: configs },
    {
      headers: setHeaders(),
      configs: data,
      index
    }
  );

  return dispatch({
    type: MGConstants.UPDATE_MODERATION_GUIDELINES,
    payload: promise
  });
};
