import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    marginTop: "8px",
    width: "100%",
  },
  rootSmallTab: {
    minWidth: "100px !important",
  },
  rootSmall: {
    minHeight: 30,
    "& button": {
      minHeight: 30,
      fontSize: 12,
    },
  },
});

const TabContainer = ({ children, dir }) => {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
};

class FullWidthTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.hasDefaultValue !== undefined ? props.hasDefaultValue : 0,
    };
  }

  handleChange = (event, value) => {
    const condition =
      this.state.value === value && this.props.toggleSelectable ? true : false;

    this.setState({ value: condition ? null : value });
    if (typeof this.props.onChangeIndex === "function") {
      this.props.onChangeIndex(condition === true ? null : value);
    }
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  render() {
    const {
      classes,
      theme,
      tabs,
      children,
      variant,
      style,
      smallTab,
      small,
    } = this.props;
    const { value } = this.state;
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            classes={{
              root: small ? classes.rootSmall : null,
            }}
            value={value === null ? false : value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant={variant ? variant : "standard"}
            scrollButtons="auto"
          >
            {tabs.map((tab, key) => {
              return (
                <Tab
                  key={key}
                  label={tab}
                  classes={{
                    root: smallTab ? classes.rootSmallTab : null,
                  }}
                />
              );
            })}
          </Tabs>
        </AppBar>
        {value !== null ? (
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={this.handleChangeIndex}
            style={style ? style : null}
          >
            {children.length > 1 ? (
              children.map((child, key) => {
                return (
                  <TabContainer key={key} dir={theme.direction}>
                    {child}
                  </TabContainer>
                );
              })
            ) : (
              <TabContainer dir={theme.direction}>{children}</TabContainer>
            )}
          </SwipeableViews>
        ) : (
          ""
        )}
      </div>
    );
  }
}

FullWidthTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  hasDefaultValue: PropTypes.number,
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
  variant: PropTypes.string,
  unSelectable: PropTypes.bool,
  onChangeIndex: PropTypes.func,
  style: PropTypes.object,
  dir: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(FullWidthTabs);
