import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

import ListItemContent from "./ListItemContent";
import ListItemTags from "./ListItemTags";
import ListItemAlerts from "./ListItemAlerts";
import ListItemReply from "./ListItemReply";
import { fromNow } from "../../../setters/date";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.backgroundComment,
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    position: "relative",
    width: "80%",
  },
  positive: {
    boxShadow: theme.palette.shadow.shadowPositive,
  },
  negative: {
    boxShadow: theme.palette.shadow.shadowNegative,
  },
  neutral: {
    // boxShadow: theme.palette.shadow.shadowDefault
  },
  divider: {
    height: "2px",
    marginTop: "5px",
    backgroundColor: theme.palette.container.light,
  },
  comment: {
    padding: theme.spacing(1),
  },
  child: {
    marginLeft: "50px",
    marginTop: "10px",
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
  dividerSelected: {
    backgroundColor: theme.palette.white,
  },
  isHidden: {
    opacity: 0.5,
  },
  fromContainer: {
    top: 0,
    left: 0,
    width: 50,
    height: 50,
    overflow: "hidden",
    position: "absolute",
  },
  adbastionNotification: {
    backgroundColor: theme.palette.black,
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.white,
    padding: theme.spacing(1),
  },
  fromContent: {
    color: theme.palette.primary.main,
    padding: 2,
    fontSize: 10,
    transform: "rotate(322deg) translate(-14px, 0px)",
    boxShadow: theme.palette.shadow.shadowDefault,
    backgroundColor: theme.palette.white,
    width: 73,
    position: "absolute",
    top: -4,
    left: -10,
  },
});

class ListItemComponent extends Component {
  generateTitleComponent(fromNow) {
    return <React.Fragment>{`time zone: ${fromNow}`}</React.Fragment>;
  }

  displayContent(classes, item, index, isParent = false, hasParent = false) {
    const {
      classificationEvent,
      translated,
      displayBoth,
      readOnly,
      actionsMenu,
      clickItemFromComment,
      updateTranslation,
      isAddingTag,
      mode,
    } = this.props;

    const replyExists = () => {
      return (
        typeof item[1]["reply"] === "object" &&
        !Array.isArray(item[1]["reply"]) &&
        Object.keys(item[1]["reply"]).length !== 0
      );
    };

    return (
      <div
        className={classNames(classes.comment, {
          [classes.child]: hasParent && index === item[0],
        })}
      >
        <ListItemContent
          parent={isParent}
          item={isParent ? Object.entries([item])[0] : item}
          index={index}
          isAddingTag={isAddingTag}
          classificationEvent={classificationEvent ? classificationEvent : null}
          translated={translated}
          displayBoth={displayBoth}
          readOnly={readOnly}
          actionsMenu={actionsMenu}
          mode={mode}
          clickItemFromComment={clickItemFromComment}
          updateTranslation={updateTranslation}
        />
        <ListItemTags
          sentiment={isParent ? item.sentiment : item[1].sentiment}
          tagCategories={isParent ? item.tagCategories : item[1].tagCategories}
          tags={
            isParent
              ? item.tags
              : item[1].copyright && item[1].copyright.report_id
              ? [
                  {
                    id: item[1].copyright.report_id,
                    label: `Report #${item[1].copyright.report_id}`,
                    checked: true,
                  },
                ]
              : item[1].tags
          }
          highlightedTags={
            item[1] && item[1].highlightedTags ? item[1].highlightedTags : []
          }
        />
        <ListItemAlerts
          index={index}
          isParent={isParent}
          item={isParent ? Object.entries([item])[0] : item}
        />

        {item[1] && replyExists() && index === item[0] && mode !== "reply" ? (
          <>
            <Divider
              component="div"
              className={classNames(classes.divider, {
                [classes.dividerSelected]: index === item[0] || isParent,
              })}
              variant="middle"
            />
            <ListItemReply
              replyMessage={
                item[1] &&
                item[1]["reply"] &&
                item[1]["reply"]["message"] &&
                item[1]["reply"]["status"] === "successful"
                  ? item[1]["reply"]["message"]
                  : ""
              }
              commentId={item[1]["id"]}
              clientShortName={item[1]["client"]["shortName"]}
            />
          </>
        ) : (
          ""
        )}
      </div>
    );
  }

  displayAdbastionNotification(classes, item, index) {
    const adbastionNotificationName =
      item[1] &&
      item[1].alert &&
      item[1].alert.adbastion_notification &&
      item[1].alert.adbastion_notification.name;

    if (!adbastionNotificationName) {
      return null;
    }

    return (
      <div className={classNames(classes.adbastionNotification)}>
        Notification created on the BrandBastion platform:{" "}
        {adbastionNotificationName}
      </div>
    );
  }

  render() {
    const { classes, item, selectedEvent, index } = this.props;
    return (
      <div
        className={classNames(classes.root, {
          [classes.selected]: index === item[0],
          [classes.neutral]: item[1].sentiment === "neutral",
          [classes.positive]: item[1].sentiment === "positive",
          [classes.negative]: item[1].sentiment === "negative",
        })}
        onClick={(e) => {
          if (typeof selectedEvent === "function" && index !== item[0]) {
            return selectedEvent(item[0]);
          }
          return null;
        }}
      >
        <div className={classes.fromContainer}>
          <div className={classes.fromContent}>
            <span>{fromNow(item[1].createdTime * 1000)}</span>
          </div>
        </div>

        {item[1].parent &&
        Object.keys(item[1].parent).length > 0 &&
        index === item[0]
          ? this.displayContent(classes, item[1].parent, index, true)
          : ""}
        {this.displayContent(
          classes,
          item,
          index,
          false,
          item[1].parent && Object.keys(item[1].parent).length > 0
            ? true
            : false
        )}
        {this.displayAdbastionNotification(classes, item, index)}
      </div>
    );
  }
}

ListItemComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  item: PropTypes.array.isRequired,
  index: PropTypes.string,
  selectedEvent: PropTypes.func,
  classificationEvent: PropTypes.func,
  translated: PropTypes.bool,
  readOnly: PropTypes.bool,
  isAddingTag: PropTypes.bool,
  actionsMenu: PropTypes.array,
  mode: PropTypes.string,
  clickItemFromComment: PropTypes.func,
  displayBoth: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(ListItemComponent);
