import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

//TODO: https://github.com/mui-org/material-ui/issues/16135
import styled from 'styled-components';
import { compose, css, display, spacing, palette } from '@material-ui/system';
const Box = styled('div')`${display}${css(
  compose(
    spacing,
    palette
  )
)}`;

const styles = theme => ({
  comment: {
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
    height: '100%',
    width: '100%',
    background: '#f9f9f9',
    '@media print': {
      columnBreakInside: 'avoid',
      width: '500px',
      marginRight: '16px',
      printColorAdjust: 'exact'
    },
    padding: theme.spacing(1),
    boxShadow: 'none'
  },
  avatar: {
    background: theme.palette.primary.main,
    marginRight: theme.spacing(2)
  },
  icon: {
    color: theme.palette.primary.dark
  },
  center: {
    maxWidth: 'calc(100vh - 75px)'
  },
  iconCloseContainer: {
    position: 'absolute',
    cursor: 'pointer',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  iconClose: {
    color: theme.palette.primary.main
  },
  userName: {
    color: '#3b5998'
  },
  message: {},
  hidden: {
    opacity: 0.5
  },
  collapse: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  replyContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(4)
  },
  iconReply: {
    marginRight: theme.spacing(3),
    color: theme.palette.primary.main
  },
  replyMessage: {
    marginTop: -4
  }
});

class PrintItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: false
    };
  }

  udpateStatus() {
    this.setState({
      hidden: !this.state.hidden
    });
  }

  render() {
    const { classes, item, showUserName, collapse, showReply } = this.props;
    const { hidden } = this.state;

    return (
      <Box
        css={{ width: '48%', margin: '0.5%' }}
        displayPrint={hidden ? 'none' : 'inline-block'}
      >
        <Paper
          className={classNames(classes.comment, {
            [classes.hidden]: hidden
          })}
        >
          <Avatar
            alt="avatar"
            className={classes.avatar}
            src="https://s3-eu-west-1.amazonaws.com/bbappcache/assets/profile_images/no_user_image.png"
          />
          <div
            className={classNames(classes.center, {
              [classes.collapse]: collapse
            })}
          >
            <Typography
              variant="subtitle2"
              className={classes.userName}
              gutterBottom
            >
              {showUserName ? item.userName : 'User'}
            </Typography>
            <div
              variant="body2"
              className={classNames(classes.message, {
                [classes.collapse]: collapse
              })}
            >
              <div>{item.message}</div>
              {showReply && (item.reply && item.reply.message) ? (
                <div className={classes.replyContainer}>
                  <i
                    className={classNames(
                      classes.iconReply,
                      'fas fa-arrow-right'
                    )}
                  ></i>
                  <div className={classes.replyMessage}>
                    {item.reply ? item.reply.message : ''}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div
            onClick={this.udpateStatus.bind(this)}
            className={classes.iconCloseContainer}
          >
            <Box displayPrint={'none'}>
              <Icon
                className={classNames(
                  classes.iconClose,
                  'fas fa-times-circle',
                  {
                    'fas fa-undo': hidden
                  }
                )}
              />
            </Box>
          </div>
        </Paper>
      </Box>
    );
  }
}

PrintItem.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  showUserName: PropTypes.bool.isRequired,
  showReply: PropTypes.bool.isRequired,
  collapse: PropTypes.bool.isRequired
};

export default withStyles(styles, { withTheme: true })(PrintItem);
