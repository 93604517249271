import { platformsConstants } from '../../constants/configuration/platformsConstants';

const initialState = {
  fetching: false,
  fetched: false,
  platformList: [],
  error: null
};

const Platforms = (state = initialState, action) => {
  switch (action.type) {
    // FETCH PLATFORM LIST
    case `${platformsConstants.FETCH_PLATFORM_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${platformsConstants.FETCH_PLATFORM_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${platformsConstants.FETCH_PLATFORM_LIST}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        platformList: Object.assign([], action.payload.data)
      };
    default:
      return state;
  }
};

export default Platforms;
