import axios from 'axios';

import { channelWhitelistConstants } from '../../constants/configuration/channelWhitelistConstants';
import { setHeaders } from '../../setters/auth';

export const fetchChannelWhitelistList = clientShortName => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/copyright/admin/${clientShortName}/channel-whitelist`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: channelWhitelistConstants.FETCH_CHANNEL_WHITELIST_LIST,
    payload: promise
  });
};

export const addChannelWhitelist = (clientShortName, data) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/copyright/admin/${clientShortName}/channel-whitelist`;

  data.client_short_name = clientShortName;

  const promise = axios.post(url, data, {
    headers: setHeaders(),
    addData: data
  });

  return dispatch({
    type: channelWhitelistConstants.ADD_CHANNEL_WHITELIST,
    payload: promise
  });
};

export const deleteChannelWhitelist = (
  clientShortName,
  index,
  channel_id
) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/copyright/admin/${clientShortName}/channel-whitelist/${channel_id}`;

  const promise = axios.delete(url, {
    headers: setHeaders(),
    index
  });

  return dispatch({
    type: channelWhitelistConstants.DELETE_CHANNEL_WHITELIST,
    payload: promise
  });
};
