import { setDateFormat } from "./date";

export const setPost = (post, key = null) => {
  const item = {
    id: post ? post.id : "",
    ads: null,
    objectId: post ? post.objectId : "",
    key: key,
    postUrl: post ? post.postUrl : "",
    client: post
      ? post.client
      : {
          name: "Unknown",
          shortName: "Unknown",
          logo: "",
        },
    picture: post ? post.picture : "",
    createdTime: post ? post.createdTime : "",
    date: setDateFormat(post ? post.createdTimePretty : "0000-00-00T00:00:00"),
    message: post && post.message !== "" ? post.message : "empty",
    messageTranslated: post ? post.messageTranslated : "",
    userName: post && post.userName !== "" ? post.userName : "unknown",
    platformId: post ? post.platformId : "",
    pageId: post ? post.pageId : "",
    pageName: post ? post.pageName : "",
    postType: post ? post.postType : "",
    recommendation_type: post ? post.recommendation_type : "",
    attachments: post ? post.attachments : [],
    targeting: post && post.targeting ? post.targeting : [],
  };

  return item;
};
