import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import FullScreenDialog from '../../commons/dialog/fullScreenDialogComponemt';
import DialogContent from '../../commons/dialog/contentDialogComponent';
import CustomizedSnackbar from '../../commons/snackBar/snackBarComponent';
import PrintCommentList from '../../print/printComponent';
import ExportCommentList from '../../export/exportComponent';
import SelectComponent from '../../commons/select/selectComponent';
import AlertDialog from '../../commons/dialog/alertDialogComponent';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.backgroundDark,
    borderRadius: 5,
    boxShadow: theme.palette.shadow.shadowAround
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  success: {
    backgroundColor: theme.palette.success[500]
  },
  error: {
    color: theme.palette.error[500],
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    marginTop: 0
  },
  buttonSuccess: {
    color: theme.palette.white,
    background: theme.palette.success[500],
    '&:hover, &:focus, &:active': {
      color: theme.palette.white,
      background: theme.palette.success[800]
    }
  },
  progress: {
    color: theme.palette.black
  },
  buttonContainer: {
    marginTop: theme.spacing(2)
  }
}));

const ActionsContent = ({
  me,
  showFilterDialog,
  loadPrintList,
  handleAnalyze,
  printCommentList,
  printList,
  loadExportList,
  exportCommentList,
  exportFields,
  loadingFile,
  loadingStreams,
  loadingPushStream,
  streamList,
  fetchStreams,
  pushToStream,
  isPushStreamMaxCommentsExceeded,
  getExportData,
  select,
  edit,
  cancelEdit,
  list,
  status,
  analyzeTemplates,
  totalComments
}) => {
  const getDefaultSelectedTemplateId = () => {
    return analyzeTemplates.find(at => at.selected).id;
  };

  const classes = useStyles();
  const theme = useTheme();
  const [analyze, setAnalyze] = useState(false);
  const [size, setSize] = useState('300');
  const [templateId, setTemplateId] = useState(getDefaultSelectedTemplateId());
  const [streamId, setStreamId] = useState('none');
  const [streamLabel, setStreamLabel] = useState('');
  const [openDialogStartAnalyze, setOpenDialogStartAnalyze] = useState(false);
  const [openDialogPushToStream, setOpenDialogPushToStream] = useState(false);
  const [
    openDialogConfirmPushToStream,
    setOpenDialogConfirmPushToStream
  ] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarVariant, setSnackBarVariant] = useState('success');
  const [messageSnackBar, setMessageSnackBar] = useState('');

  const onChangeSize = value => {
    setSize(value);
  };

  const onChangeTemplateId = e => {
    setTemplateId(e.target.value);
  };

  const onChangeStreamId = e => {
    const streamId = e.target.value;
    const stream = streamList.find(st => st.id === streamId);
    setStreamLabel(stream ? stream.label : 'Selected Stream');
    setStreamId(e.target.value);
  };

  const onClickAnalyze = () => {
    setOpenDialogStartAnalyze(true);
  };

  const onClickPushToStream = () => {
    if (!isPushStreamMaxCommentsExceeded) {
      fetchStreams();
    }
    setOpenDialogPushToStream(true);
  };

  const startAnalyze = status => {
    if (status) {
      setAnalyze(true);
      handleAnalyze(size, templateId, status => {
        if (status === 'success') {
          setSnackBarVariant('success');
          setMessageSnackBar('analyze started');
          setOpenSnackBar(true);
        }
        setAnalyze(false);
      });
    }
    setOpenDialogStartAnalyze(false);
  };

  const onPushToStream = () => {
    setOpenDialogConfirmPushToStream(true);
  };

  const handleResultPushToStream = (status, message) => {
    setSnackBarVariant(status ? 'success' : 'error');
    setMessageSnackBar(message);
    setOpenSnackBar(true);
    if (status) {
      setOpenDialogPushToStream(false);
    }
  };

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        size="small"
        color="primary"
        className={classes.button}
        onClick={showFilterDialog}
      >
        Filters
      </Button>
      <DialogContent
        title={'Start Analyze'}
        open={openDialogStartAnalyze}
        close={() => setOpenDialogStartAnalyze(false)}
      >
        <div>
          <FormGroup row style={{ marginLeft: 5 }}>
            <SelectComponent
              id={'analyze-template'}
              label={'Template'}
              attributId={'id'}
              attributName={'name'}
              mandatory={true}
              value={templateId}
              display={1}
              fullWidth={true}
              items={analyzeTemplates}
              onChange={e => {
                return onChangeTemplateId(e);
              }}
            />
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={
                <TextField
                  type="number"
                  defaultValue={size}
                  style={{
                    width: 70,
                    marginLeft: theme.spacing(2),
                    marginTop: theme.spacing(1)
                  }}
                  inputProps={{ min: '300', max: '60000', step: '100' }}
                  onInput={e => {
                    if (parseInt(e.target.value) > 60000) {
                      e.target.value = '60000';
                    }
                    if (parseInt(e.target.value) < 0) {
                      e.target.value = '300';
                    }
                  }}
                  onChange={e => {
                    return onChangeSize(e.target.value);
                  }}
                />
              }
              label="Limit (max 60000)"
            />
          </FormGroup>
          <div className={classes.buttonContainer}>
            <Button
              size="small"
              color="primary"
              onClick={() => startAnalyze(true)}
            >
              Start
            </Button>
            <Button
              onClick={() => startAnalyze(false)}
              size="small"
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>

      <DialogContent
        title={'Push To Stream'}
        open={openDialogPushToStream}
        close={() => setOpenDialogPushToStream(false)}
      >
        <div style={{ minWidth: 300 }}>
          {isPushStreamMaxCommentsExceeded ? (
            <div>
              <p className={classes.error}>
                ERROR: You cannot add more than 10K comments into the stream at
                once. Please restrict your search.
              </p>
              <p style={{ margin: 0 }}>
                If necessary, you can filter for multiple batches of max 10K
                comments and assign them separately.
              </p>
            </div>
          ) : loadingStreams ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress className={classes.progress} size={18} />
            </div>
          ) : (
            <FormGroup row style={{ marginLeft: 5 }}>
              <SelectComponent
                id={'select-stream'}
                label={'Stream'}
                attributId={'id'}
                attributName={'label'}
                mandatory={true}
                value={streamId}
                display={2}
                fullWidth={true}
                items={[{ id: 'none', label: 'Select Stream' }].concat(
                  streamList
                )}
                onChange={e => {
                  return onChangeStreamId(e);
                }}
              />
            </FormGroup>
          )}
          <div className={classes.buttonContainer}>
            <Button
              size="small"
              color="primary"
              disabled={
                loadingStreams ||
                isPushStreamMaxCommentsExceeded ||
                streamId === 'none'
              }
              onClick={() => onPushToStream()}
              startIcon={
                loadingPushStream ? (
                  <CircularProgress className={classes.progress} size={18} />
                ) : null
              }
            >
              Push
            </Button>
            <Button
              onClick={() => setOpenDialogPushToStream(false)}
              size="small"
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>

      <AlertDialog
        title="Confirm push to stream"
        message={
          'Are you sure you want to push ' +
          totalComments +
          " comments to the stream '" +
          streamLabel +
          "' ?"
        }
        buttonAcceptText="Yes"
        buttonCancelText="No"
        action={status => {
          if (status) {
            pushToStream(streamId, handleResultPushToStream);
          }
          setOpenDialogConfirmPushToStream(false);
        }}
        open={openDialogConfirmPushToStream}
      />

      <CustomizedSnackbar
        variant={snackBarVariant}
        onClose={() => setOpenSnackBar(false)}
        message={messageSnackBar}
        open={openSnackBar}
      />
      {list &&
      list.length &&
      me &&
      me.permissions &&
      me.permissions.includes('analyze') ? (
        <Button
          variant="contained"
          size="small"
          className={classes.button}
          startIcon={
            analyze ? (
              <CircularProgress className={classes.progress} size={18} />
            ) : null
          }
          onClick={() => onClickAnalyze()}
        >
          Analyze
        </Button>
      ) : (
        ''
      )}
      {list && list.length ? (
        <FullScreenDialog
          buttonTitle={'Print'}
          title={'Print the comment list'}
          action={'Print'}
          handleOpen={loadPrintList}
          handleAction={printCommentList}
        >
          <PrintCommentList data={printList} />
        </FullScreenDialog>
      ) : (
        ''
      )}
      {list && list.length ? (
        <FullScreenDialog
          buttonTitle={'Export'}
          title={'Export the comment to CSV'}
          action={'Export CSV'}
          handleOpen={loadExportList}
          handleAction={exportCommentList}
        >
          <ExportCommentList
            exportFields={exportFields}
            loadingFile={loadingFile}
            getExportData={getExportData}
          />
        </FullScreenDialog>
      ) : (
        ''
      )}
      {list && list.length ? (
        <Button
          variant="contained"
          size="small"
          className={classes.button}
          onClick={onClickPushToStream}
        >
          Stream
        </Button>
      ) : (
        ''
      )}
      {list && list.length && status === 'readOnly' ? (
        <Button
          variant="contained"
          size="small"
          className={classNames(classes.button, classes.buttonSuccess)}
          onClick={select}
        >
          Select
        </Button>
      ) : (
        ''
      )}
      {status === 'edit' ? (
        <div>
          <Button
            variant="contained"
            size="small"
            className={classNames(classes.button, classes.buttonSuccess)}
            onClick={edit}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            className={classes.button}
            onClick={cancelEdit}
          >
            Cancel Edit
          </Button>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

ActionsContent.propTypes = {
  me: PropTypes.object.isRequired,
  showFilterDialog: PropTypes.func.isRequired,
  loadPrintList: PropTypes.func.isRequired,
  printCommentList: PropTypes.func.isRequired,
  printList: PropTypes.array.isRequired,
  loadExportList: PropTypes.func.isRequired,
  exportCommentList: PropTypes.func.isRequired,
  exportFields: PropTypes.object.isRequired,
  loadingFile: PropTypes.bool.isRequired,
  getExportData: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
  handleAnalyze: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  streamList: PropTypes.array.isRequired,
  loadingStreams: PropTypes.bool.isRequired,
  fetchStreams: PropTypes.func.isRequired,
  pushToStream: PropTypes.func.isRequired,
  isPushStreamMaxCommentsExceeded: PropTypes.bool.isRequired,
  totalComments: PropTypes.number.isRequired
};

export default ActionsContent;
