import React from 'react';
import PropTypes from 'prop-types';
import ChipInput from 'material-ui-chip-input';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';

import InputComponent from '../../commons/input/inputComponent';
import DatePicker from '../../commons/picker/dateTimePickerComponent';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  formControlContainer: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  formGroup: {
    width: '80%'
  },
  formGroupDate: {
    width: '50%'
  }
}));

const TrendsForm = props => {
  const classes = useStyles();

  const displayChipInput = (name, label, required = false) => {
    return (
      <div>
        <ChipInput
          required={required}
          label={label}
          error={props.errors[name] ? true : false}
          helperText={props.errors[name]}
          fullWidth={true}
          defaultValue={
            Object.values(props.formData[name]).length
              ? props.formData[name]
              : []
          }
          onChange={e => props.onChangeFormData(e, name)}
        />
        {props.errors[name] ? <div className={classes.errorMargin}></div> : ''}
      </div>
    );
  };

  const displayTextField = (
    name,
    label,
    required,
    disabled,
    multiline,
    fullWidth
  ) => {
    return (
      <InputComponent
        label={label}
        required={required}
        disabled={disabled}
        multiline={multiline}
        fullWidth={fullWidth}
        defaultValue={props.formData[name]}
        onChange={e => {
          return props.onChangeFormData(e, name);
        }}
        error={props.errors[name] ? true : false}
        helperText={props.errors[name]}
        margin="dense"
      />
    );
  };

  const displayMultipleSelect = (
    id,
    label,
    isRequired = false,
    items,
    attributeId,
    attributeName
  ) => {
    const classes = useStyles();

    return (
      <FormGroup className={classes.formGroup}>
        <InputLabel>
          {label} {isRequired ? '*' : ''}
        </InputLabel>
        <Select
          multiple
          value={props.formData[id]}
          onChange={e => {
            return props.onChangeFormData(e, id);
          }}
          input={<Input id={id} />}
          renderValue={selected => (
            <div className={classes.tags}>
              {selected.map((value, key) => (
                <Chip
                  key={key}
                  label={
                    items.find(i => value === i[attributeId])
                      ? items.find(i => value === i[attributeId])[attributeName]
                      : value
                  }
                  className={classes.tag}
                />
              ))}
            </div>
          )}
        >
          {items.map((i, key) => (
            <MenuItem key={key} value={i[attributeId]}>
              {i[attributeName]}
            </MenuItem>
          ))}
        </Select>
      </FormGroup>
    );
  };

  return (
    <div className={classes.formControlContainer}>
      <FormControl className={classes.formControl}>
        <FormGroup className={classes.formGroup}>
          {displayTextField('name', 'Name', true, false, false, true)}
          {displayTextField(
            'description',
            'Description',
            true,
            false,
            false,
            true
          )}
          {displayChipInput('keywords', 'Keywords', true)}
        </FormGroup>
      </FormControl>
      <FormControl className={classes.formControl}>
        <div className={classes.container}>
          <DatePicker
            required={true}
            error={props.errors['end_date']}
            minDate={new Date('2019-01-01')}
            settedValue={false}
            defaultTimeValue="00:00"
            ampm={false}
            name={'end_date'}
            label={'End Date'}
            value={
              props.formData['end_date'] ? props.formData['end_date'] : null
            }
            setSelectedDate={(name, value) =>
              props.onChangeFormData({ target: { value: value } }, name)
            }
          />
        </div>
      </FormControl>
      <FormGroup>
        <FormControl className={classes.formControl}>
          {displayMultipleSelect(
            'included_clients',
            'Included Clients',
            false,
            props.clientList,
            'clientShortName',
            'name'
          )}
        </FormControl>
        <FormControl className={classes.formControl}>
          {displayMultipleSelect(
            'excluded_clients',
            'Excluded Clients',
            false,
            props.clientList,
            'clientShortName',
            'name'
          )}
        </FormControl>
      </FormGroup>
    </div>
  );
};

TrendsForm.propTypes = {
  clientList: PropTypes.array.isRequired,
  formData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChangeFormData: PropTypes.func.isRequired
};

export default TrendsForm;
