export const styles = (theme) => ({
  root: {
    display: "flex",
  },
  grid: {
    width: "100%",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    height: 48,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  actionsContainer: {
    marginBottom: theme.spacing(1),
  },
  heightList: {
    paddingBottom: theme.spacing(2),
    height: "calc(100vh - 150px)",
  },
  alertActions: {
    marginTop: theme.spacing(3),
  },
  cancelBtn: {
    marginLeft: theme.spacing(2),
  },
  rightSide: {
    overflow: "hidden auto",
  },
  loading: {
    margin: "auto",
    color: theme.palette.orange[300],
    marginTop: "calc(50vh - 88px)",
    marginBottom: "50vh",
  },
  notAllowed: {
    margin: "auto",
    marginTop: "calc(50vh - 96px)",
  },
});
