import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import AlertDialog from '../../commons/dialog/alertDialogComponent';
import DialogContent from '../../commons/dialog/contentDialogComponent';
import TableComponent from '../../commons/table/tableComponent';
import TrendsForm from './trendsForm';

import { setDateFormatTimeStamp } from '../../../setters/date';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    '& .MuiInput-root': {
      marginTop: '0 !important'
    }
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 7
  },
  buttonAdd: {
    marginLeft: theme.spacing(2)
  },
  detailContainer: {
    display: 'flex'
  },
  detailItem: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.container.light
  },
  detailLegend: {
    padding: theme.spacing(1),
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center'
  },
  detailContent: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    overflow: 'auto'
  },
  detailCell: {
    marginBottom: theme.spacing(1)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const TrendsConfiguration = props => {
  const classes = useStyles();

  // Init
  const { trendList, isUpdatable, isCreatable, isDeletable } = props;

  const initialFormData = {
    id: '',
    name: '',
    description: '',
    end_date: '',
    keywords: [],
    included_clients: [],
    excluded_clients: []
  };

  // Hooks
  const [isFormOpen, setFormOpen] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [isEditMode, setEditMode] = useState(false);
  const [rowSelected, setRowSelected] = useState(0);
  // Table schema
  const tableColumns = [
    {
      title: 'Id',
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14
      },
      field: 'id'
    },
    {
      title: 'Name',
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14
      },
      field: 'name'
    },
    {
      title: 'Description',
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14
      },
      field: 'description'
    },
    {
      title: 'Keywords',
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14
      },
      field: 'keywords',
      render: rowData => <div>{rowData.keywords.join(', ')}</div>
    },
    {
      title: 'End Date',
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14
      },
      field: 'end_date',
      render: rowData => <div>{setDateFormatTimeStamp(rowData.end_date)}</div>
    },
    {
      title: 'Included Clients',
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14
      },
      field: 'included_clients',
      render: rowData => <div>{rowData.included_clients.join(', ')}</div>
    },
    {
      title: 'Excluded Clients',
      filtering: false,
      cellStyle: {
        padding: 2,
        paddingLeft: 14
      },
      field: 'excluded_clients',
      render: rowData => <div>{rowData.excluded_clients.join(', ')}</div>
    }
  ];

  const onChangeFormData = (e, name, type = null) => {
    setFormData({
      ...formData,
      [name]:
        type === 'checkbox' ? e.target.checked : e.target ? e.target.value : e
    });
  };

  const onCreateGlobalTrend = e => {
    setEditMode(false);
    setFormOpen(true);
  };

  const onCloseDialogForm = () => {
    setFormOpen(false);
  };

  const createGlobalTrend = e => {
    checkForErrors().then(formErrors => {
      if (Object.keys(formErrors).length === 0) {
        props.createGlobalTrend(formData);
        onCloseDialogForm();
        setFormData(initialFormData);
      } else {
        setFormErrors(formErrors);
      }
    });
  };

  const deleteTrend = status => {
    if (status) {
      props.deleteGlobalTrend(rowSelected, formData.id);
    }
    setAlertOpen(false);
  };

  const updateGlobalTrend = e => {
    checkForErrors().then(formErrors => {
      if (Object.keys(formErrors).length === 0) {
        props.updateGlobalTrend(rowSelected, formData);
        onCloseDialogForm();
        setFormData(initialFormData);
      } else {
        setFormErrors(formErrors);
      }
    });
  };

  const onEditTrend = (event, rowData) => {
    setEditMode(true);
    setRowSelected(rowData.tableData.id);
    setFormData({
      id: rowData.id,
      name: rowData.name,
      description: rowData.description,
      keywords: rowData.keywords,
      end_date: rowData.end_date,
      included_clients: rowData.included_clients
        ? rowData.included_clients
        : [],
      excluded_clients: rowData.excluded_clients ? rowData.excluded_clients : []
    });
    setFormOpen(true);
  };

  const onDeleteTrend = (event, rowData) => {
    setRowSelected(rowData.tableData.id);
    setFormData({
      id: rowData.id,
      name: rowData.name,
      description: rowData.description,
      keywords: rowData.keywords,
      end_date: rowData.end_date,
      included_clients: rowData.included_clients
        ? rowData.included_clients
        : [],
      excluded_clients: rowData.excluded_clients ? rowData.excluded_clients : []
    });
    setAlertOpen(true);
  };

  const checkForErrors = () => {
    return new Promise((resolve, reject) => {
      const formErrors = {};
      for (let field in formData) {
        let errorMessage = null;
        switch (field) {
          case 'name':
            if (formData[field] === '') {
              errorMessage = 'Name is mandatory';
            }
            break;
          case 'description':
            if (formData[field] === '') {
              errorMessage = 'Description is mandatory';
            }
            break;
          case 'keywords':
            if (!formData[field].length) {
              errorMessage = 'Keywords is mandatory';
            }
            break;
          case 'end_date':
            if (formData[field] === '') {
              errorMessage = 'End Date is mandatory';
            }
            break;
          default:
            break;
        }
        if (errorMessage) {
          formErrors[field] = errorMessage;
        }
      }

      resolve(formErrors);
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h5">Global Trends</Typography>
        {isCreatable && (
          <Button
            variant="contained"
            className={classes.buttonAdd}
            size="small"
            color="primary"
            onClick={e => {
              return onCreateGlobalTrend(e);
            }}
          >
            Add Global Trend
          </Button>
        )}
      </div>
      <TableComponent
        filter={false}
        columns={tableColumns}
        data={Object.assign([], trendList)}
        title=""
        options={{
          pageSizeOptions: [5, 10, 20, 50, 100],
          filtering: false
        }}
        actions={[
          {
            icon: 'edit',
            disabled: !isUpdatable,
            tooltip: 'Edit Trend',
            onClick: (event, rowData) => onEditTrend(event, rowData)
          },
          {
            icon: 'delete',
            disabled: !isDeletable,
            tooltip: 'Delete Trend',
            onClick: (event, rowData) => onDeleteTrend(event, rowData)
          }
        ]}
      />
      <DialogContent
        title={'Global Trend'}
        open={isFormOpen}
        fullWidth={true}
        close={onCloseDialogForm}
      >
        <div>
          <TrendsForm
            formData={formData}
            errors={formErrors}
            clientList={props.clientList}
            onChangeFormData={onChangeFormData}
          />
          <div className={classes.buttonContainer}>
            <Button
              size="small"
              color="primary"
              onClick={e => {
                return isEditMode ? updateGlobalTrend(e) : createGlobalTrend(e);
              }}
            >
              Save
            </Button>
            <Button
              onClick={e => {
                return onCloseDialogForm(e);
              }}
              size="small"
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
      <AlertDialog
        title="Delete Global Trend"
        message={`Are you sure you want to delete this global trend?`}
        buttonCancelText="No"
        buttonAcceptText="Yes"
        open={isAlertOpen}
        action={deleteTrend}
      />
    </div>
  );
};

TrendsConfiguration.propTypes = {
  trendList: PropTypes.array.isRequired,
  clientList: PropTypes.array.isRequired,
  isCreatable: PropTypes.bool.isRequired,
  isUpdatable: PropTypes.bool.isRequired,
  isDeletable: PropTypes.bool.isRequired,
  createGlobalTrend: PropTypes.func,
  deleteGlobalTrend: PropTypes.func,
  updateGlobalTrend: PropTypes.func
};

export default TrendsConfiguration;
