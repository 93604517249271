import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  formGroup: {
    width: '50%'
  }
});

class RepliesAccessForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userList: props.userList || [],
      roleList: props.roleList || [],
      data: props.replyAccess
        ? props.replyAccess
        : {
            usernames: [],
            roles: []
          }
    };
  }

  onChange(e, value) {
    this.setState(
      {
        data: {
          ...this.state.data,
          [value]: e.target.value
        }
      },
      () => {
        this.props.onChangeRepliesAccessForm(this.state.data);
      }
    );
  }

  displayMultipleSelect(
    id,
    label,
    isRequired = false,
    items,
    attributeId,
    attributeName
  ) {
    const { classes } = this.props;
    const { data } = this.state;
    return (
      <FormGroup className={classes.formGroup}>
        <InputLabel>
          {label} {isRequired ? '*' : ''}
        </InputLabel>
        <Select
          multiple
          value={data[id]}
          onChange={e => {
            return this.onChange(e, id);
          }}
          input={<Input id={id} />}
          renderValue={selected => (
            <div className={classes.tags}>
              {selected.map((value, key) => (
                <Chip
                  key={key}
                  label={
                    items.find(i => value === i[attributeId])
                      ? items.find(i => value === i[attributeId])[attributeName]
                      : value
                  }
                  className={classes.tag}
                />
              ))}
            </div>
          )}
        >
          {items.map((i, key) => (
            <MenuItem key={key} value={i[attributeId]}>
              {i[attributeName]}
            </MenuItem>
          ))}
        </Select>
      </FormGroup>
    );
  }

  displayTextFields() {
    const { classes } = this.props;
    const { userList, roleList } = this.state;
    return (
      <FormGroup className={classes.formGroup}>
        <FormControl className={classes.formControl}>
          {this.displayMultipleSelect(
            'usernames',
            'Access Users',
            false,
            userList,
            'id',
            'username'
          )}
        </FormControl>
        <FormControl className={classes.formControl}>
          {this.displayMultipleSelect(
            'roles',
            'Access Roles',
            false,
            roleList,
            'id',
            'id'
          )}
        </FormControl>
      </FormGroup>
    );
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.state;
    return (
      <div className={classes.root}>
        {loading ? (
          <CircularProgress />
        ) : (
          <FormControl className={classes.formControl}>
            {this.displayTextFields()}
          </FormControl>
        )}
      </div>
    );
  }
}

RepliesAccessForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onChangeRepliesAccessForm: PropTypes.func.isRequired,
  replyAccess: PropTypes.object
};

export default withStyles(styles, { withTheme: true })(RepliesAccessForm);
