import { moderationConstants } from "../constants/moderationConstants";

import { extractCommentList } from "../extractors/commentExtractor";

import { setSentimentFromTags } from "../setters/tags";

const initialState = {
  fetching: false,
  fetchingList: false,
  fetched: false,
  list: [],
  specificComment: {},
  total: 0,
  fetchComment: false,
  cursor: null,
  error: null,
};

const Moderation = (state = initialState, action) => {
  switch (action.type) {
    //GET COMMENT LIST
    case `${moderationConstants.GET_COMMENT_LIST}_PENDING`:
      return {
        ...state,
        fetchingList: true,
      };
    case `${moderationConstants.GET_COMMENT_LIST}_ERROR`:
      return {
        ...state,
        fetchingList: false,
        error: action.payload.data,
      };
    case `${moderationConstants.GET_COMMENT_LIST}_FULFILLED`:
      let list = extractCommentList(
        action.payload.data.items,
        action.payload.config.stream
      );
      return {
        ...state,
        fetchingList: false,
        fetched: true,
        fetchComment: true,
        total: action.payload.data.total,
        cursor: action.payload.data.cursor,
        list: Object.assign([], list),
      };

    //SET TAG TO COMMENT
    case `${moderationConstants.SET_TAG_TO_COMMENT}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${moderationConstants.SET_TAG_TO_COMMENT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${moderationConstants.SET_TAG_TO_COMMENT}_FULFILLED`:
      return {
        ...state,
        fetching: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            tags: Object.assign([], action.payload.config.tags),
            tagCategories: action.payload.config.tagCategories,
            sentiment: setSentimentFromTags(action.payload.config.tags),
            isLiked:
              action.payload.data.items &&
              action.payload.data.items[0] &&
              action.payload.data.items[0]["actions"] &&
              action.payload.data.items[0]["actions"].find(
                (action) => action.objectType === "like"
              )
                ? true
                : state.list[action.payload.config.index].isLiked,
            alerts:
              action.payload.data.items && action.payload.data.items[0]
                ? action.payload.data.items[0].actions
                : [],
          },
        }),
      };

    //COMMENT ADD TIME
    case `${moderationConstants.COMMENT_ADD_TIME}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${moderationConstants.COMMENT_ADD_TIME}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${moderationConstants.COMMENT_ADD_TIME}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.index]: {
            ...state.list[action.payload.index],
            [action.payload.text]: action.payload.time,
          },
        }),
      };

    //Set COMMENT CLASSIFIED
    case `${moderationConstants.MODERATION_END_EVENT}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${moderationConstants.MODERATION_END_EVENT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${moderationConstants.MODERATION_END_EVENT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            classified: true,
          },
        }),
      };

    //Set COMMENT CLASSIFYING
    case `${moderationConstants.IS_CLASSIFYING_EVENT}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${moderationConstants.IS_CLASSIFYING_EVENT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${moderationConstants.IS_CLASSIFYING_EVENT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.index]: {
            ...state.list[action.payload.index],
            isClassifying: action.payload.value,
            classified: action.payload.value === true ? false : true,
          },
        }),
      };

    //Set COMMENT FAVORITE
    case `${moderationConstants.IS_FAVORITE_EVENT}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${moderationConstants.IS_FAVORITE_EVENT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${moderationConstants.IS_FAVORITE_EVENT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.index]: {
            ...state.list[action.payload.index],
            isFavorite: action.payload.value,
          },
        }),
      };

    //FETCH HISTORY FROM COMMENT
    case `${moderationConstants.FETCH_HISTORY_FROM_COMMENT}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${moderationConstants.FETCH_HISTORY_FROM_COMMENT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${moderationConstants.FETCH_HISTORY_FROM_COMMENT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            history: action.payload.data.items.length
              ? action.payload.data.items
              : [],
          },
        }),
      };

    //UPDATE CLIENTS COMMENTS TRANSLATION
    case `${moderationConstants.UPDATE_CLIENTS_COMMENTS_TRANSLATION}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${moderationConstants.UPDATE_CLIENTS_COMMENTS_TRANSLATION}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${moderationConstants.UPDATE_CLIENTS_COMMENTS_TRANSLATION}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            message: action.payload.data.message,
            messageTranslated: action.payload.data.messageTranslated,
          },
        }),
      };

    //FETCH CLIENTS POST ADS
    case `${moderationConstants.FETCH_CLIENTS_POST_ADS_MODERATION}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${moderationConstants.FETCH_CLIENTS_POST_ADS_MODERATION}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${moderationConstants.FETCH_CLIENTS_POST_ADS_MODERATION}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            post: {
              ...state.list[action.payload.config.index]["post"],
              ads: action.payload.data,
            },
          },
        }),
      };

    // UPDATE COMMENT STATUS
    case `${moderationConstants.UPDATE_COMMENT_STATUS}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${moderationConstants.UPDATE_COMMENT_STATUS}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${moderationConstants.UPDATE_COMMENT_STATUS}_FULFILLED`:
      return {
        ...state,
        list: Object.assign([], {
          ...state.list,
          [action.payload.commentId]: {
            ...state.list[action.payload.commentId],
            [action.payload.data.label]: !state.list[action.payload.commentId][
              action.payload.data.label
            ],
          },
        }),
      };

    // UNLIKE_COMMENT
    case `${moderationConstants.UNLIKE_COMMENT}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${moderationConstants.UNLIKE_COMMENT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${moderationConstants.UNLIKE_COMMENT}_FULFILLED`:
      return {
        ...state,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            isLiked: false,
          },
        }),
      };

    // RESET COMMENT LIST
    case `${moderationConstants.RESET_COMMENT_LIST}_FULFILLED`:
      return {
        ...state,
        fetchComment: false,
        list: [],
      };
    default:
      return state;
  }
};

export default Moderation;
