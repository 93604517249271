import { extractReplyList } from "../extractors/replyExtractor";
import { extractClientList } from "../extractors/clientsExtractor";

export const ALERT_TYPES = {
  alert: { id: "immediate", label: "Immediate" },
  check_alert: { id: "volume", label: "Volume based" },
};

const initialState = {
  alerts: {
    cursor: null,
    items: [],
    post: null,
    tags: [],
    total: 0,
  },
  clients: [],
  error: null,
  fetched: false,
  fetching: false,
};

const AlertStats = (state = initialState, action) => {
  let data = null;
  let newItems = null;

  switch (action.type) {
    // GET
    case `GET_STATS`:
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    case `GET_STATS_SUCCESS`:
      const clientList = extractClientList(action.payload.items, "reply");

      return {
        ...state,
        fetched: true,
        fetching: false,
        clients: clientList,
      };
    case `GET_STATS_FAILURE`:
      return {
        ...state,
        fetched: true,
        fetching: false,
        error: action.payload,
      };
    // GET ALERTS
    case `GET_CLIENT_ALERTS`:
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    case `GET_CLIENT_ALERTS_SUCCESS`:
      const replyList = extractReplyList(action.payload);

      return {
        ...state,
        fetched: true,
        fetching: false,
        alerts: {
          ...state.alerts,
          ...action.payload,
          items: replyList["list"],
        },
      };
    case `GET_CLIENT_ALERTS_PAGE_SUCCESS`:
      data = extractReplyList(action.payload);

      return {
        ...state,
        fetched: true,
        fetching: false,
        alerts: {
          ...state.alerts,
          ...action.payload,
          items: [...state.alerts.items, ...data["list"]],
        },
      };
    case `GET_CLIENT_ALERTS_FAILURE`:
      return {
        ...state,
        fetched: true,
        fetching: false,
        error: action.payload,
      };
    case `ISSUE_ALERT_SUCCESS`:
    case "CANCEL_ALERT_SUCCESS":
      newItems = [...state.alerts.items];

      if (action.payload > -1) {
        newItems.splice(action.payload, 1);
      }

      return {
        ...state,
        alerts: {
          ...state.alerts,
          items: newItems,
        },
      };
    case `GET_HISTORY_FROM_COMMENT_SUCCESS`:
      newItems = [...state.alerts.items];
      newItems[action.payload.selectedCommentIndex].history =
        action.payload.data.items;

      return {
        ...state,
        alerts: {
          ...state.alerts,
          items: newItems,
        },
      };
    default:
      return state;
  }
};

export default AlertStats;
