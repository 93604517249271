import axios from 'axios';
import { streamConstants } from '../constants/streamConstants';
import { setHeaders } from '../setters/auth';
import { getUTC } from '../setters/date';

export const getStreamList = () => dispatch => {
  const url = `${process.env.URL_API_ENDPOINT}/streams`;
  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: streamConstants.GET_STREAM_LIST,
    payload: promise
  });
};

export const addFavouriteToStream = (key, type) => dispatch => {
  //TODO FETCH REAL DATA HERE
  const url = `${process.env.URL_API_ENDPOINT}/streams/${key}/favorite`;
  const promise = axios.patch(
    url,
    {
      favorite: type === 'favourite' ? true : false
    },
    {
      headers: setHeaders(),
      type: type,
      key: key
    }
  );
  return dispatch({
    type: streamConstants.ADD_FAVORITE_TO_STREAM,
    payload: promise
  });
};

export const streamSelected = streamId => dispatch => {
  const promise = new Promise((resolve, reject) => {
    resolve({
      streamId: streamId
    }); // fulfilled
    reject('failure decrase notification count');
  });

  return dispatch({
    type: streamConstants.STREAM_SELECTED,
    payload: promise
  });
};

export const deleteStream = streamId => dispatch => {
  const url = `${process.env.URL_API_ENDPOINT}/streams/${streamId}`;
  const promise = axios.delete(url, {
    headers: setHeaders(),
    streamId: streamId
  });
  return dispatch({
    type: streamConstants.DELETE_STREAM,
    payload: promise
  });
};

export const createTemporaryStream = data => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/clients/${data.query.client_short_name}/streams`;

  const promise = axios.post(
    url,
    {
      label: data.label,
      expires_at: Math.round(getUTC() / 1000) + 172800,
      comment_ids: data.commmentIds,
      startTime: data.query.start,
      endTime: data.query.end,
      tags: data.query.tags,
      user: data.query.user,
      post_id: data.query.post_ids,
      platform_id: data.query.platform_ids
    },
    {
      headers: setHeaders()
    }
  );

  return dispatch({
    type: streamConstants.CREATE_TEMPORARY_STREAM,
    payload: promise
  });
};

export const unlockCommentFromStream = streamId => dispatch => {
  const url = `${process.env.URL_API_ENDPOINT}/streams/${streamId}/locks`;
  const promise = axios.delete(url, {
    headers: setHeaders(),
    streamId: streamId
  });
  return dispatch({
    type: streamConstants.DELETE_COMMENTS_FROM_STREAM,
    payload: promise
  });
};

//WEBSOCKET ACTIONS
export const updatesStreamList = data => ({
  type: 'UPDATES_STREAM_LIST',
  data: data
});
