import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '../commons/table/customTableComponent';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const Ads = ({ ads }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  const createColumn = () => {
    return [
      {
        field: 'account',
        content1: 'account_name',
        content2: 'account_id',
        title: 'Account'
      },
      {
        field: 'campaign',
        content1: 'campaign_name',
        content2: 'campaign_id',
        status: 'status',
        statusValue: '0',
        title: 'Ad campaign'
      },
      {
        field: 'adset',
        content1: 'adset_name',
        content2: 'adset_id',
        status: 'status',
        statusValue: '1',
        title: 'Ad set'
      },
      {
        field: 'ad',
        content1: 'ad_name',
        content2: 'ad_id',
        status: 'status',
        statusValue: '2',
        title: 'Ad'
      },
      {
        field: 'adcreative',
        content1: 'adcreative_name',
        content2: 'adcreative_id',
        title: 'Ad creative'
      },
      {
        field: 'targeting',
        subField: 'countries',
        subFieldType: 'array',
        title: 'Targeting'
      },
      {
        field: 'createdtime',
        time: 'created_time',
        timePretty: 'created_time_pretty',
        title: 'Created time'
      }
    ];
  };

  useEffect(() => {
    return setLoading(ads ? false : true);
  }, [ads]);

  return (
    <div className={classes.root}>
      {ads && ads.length ? (
        <Table
          showExport={false}
          showTitle={false}
          title={''}
          showToolbar={false}
          data={ads}
          columns={createColumn()}
          search={false}
        />
      ) : loading ? (
        <CircularProgress />
      ) : (
        <Typography>No Ads</Typography>
      )}
    </div>
  );
};

Ads.propTypes = {
  ads: PropTypes.array
};

export default Ads;
