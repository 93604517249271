import axios from 'axios';

import { groupsTagConstants } from '../../constants/settings/groupsTagConstants';
import { setHeaders } from '../../setters/auth';

export const fetchGroupsTagList = () => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/users/me/group-color-settings`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: groupsTagConstants.FETCH_GROUPS_TAG,
    payload: promise
  });
};

export const updateGroupsTagColor = (index, data) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/users/me/group-color-settings`;
  const promise = axios.patch(
    url,
    {
      group: data.group,
      color: data.color
    },
    {
      headers: setHeaders(),
      index: index,
      color: data.color
    }
  );

  return dispatch({
    type: groupsTagConstants.EDIT_GROUPS_TAG_COLOR,
    payload: promise
  });
};
