import { meConstants } from '../constants/meConstants';
import { groupsTagConstants } from '../constants/settings/groupsTagConstants';

const initialState = {
  fetching: false,
  fetched: false,
  me: {},
  statistics: {},
  groupsTagList: [],
  error: null
};

const Me = (state = initialState, action) => {
  switch (action.type) {
    // FETCH ME
    case `${meConstants.FETCH_ME}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${meConstants.FETCH_ME}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${meConstants.FETCH_ME}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        me: action.payload.data
      };

    // PATCH ME
    case `${meConstants.PATCH_ME}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${meConstants.PATCH_ME}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${meConstants.PATCH_ME}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        me: {
          ...state.me,
          [action.payload.config.item]: action.payload.config.data
        }
      };
    // FETCH_GROUPS_TAG
    case `${groupsTagConstants.FETCH_GROUPS_TAG}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${groupsTagConstants.FETCH_GROUPS_TAG}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${groupsTagConstants.FETCH_GROUPS_TAG}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        groupsTagList: action.payload.data.items
      };

    // EDIT_GROUPS_TAG_COLOR
    case `${groupsTagConstants.EDIT_GROUPS_TAG_COLOR}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${groupsTagConstants.EDIT_GROUPS_TAG_COLOR}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${groupsTagConstants.EDIT_GROUPS_TAG_COLOR}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        groupsTagList: state.groupsTagList
      };

    // FETCH STATISTICS ME
    case `${meConstants.FETCH_STATISTICS_ME}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${meConstants.FETCH_STATISTICS_ME}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${meConstants.FETCH_STATISTICS_ME}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        statistics: action.payload.data
      };
    default:
      return state;
  }
};

export default Me;
