export const setCommentKPI = (commentKPI, key = null) => {
  const item = {
    task_id: commentKPI.task_id ? commentKPI.task_id : '',
    task_type_pretty: commentKPI.task_type_pretty
      ? commentKPI.task_type_pretty
      : '',
    max_total_comments:
      commentKPI && commentKPI.max_total_comments
        ? commentKPI.max_total_comments
        : 0,
    max_crawled_items:
      commentKPI && commentKPI.max_crawled_items
        ? commentKPI.max_crawled_items
        : 0,
    max_refreshed_items:
      commentKPI && commentKPI.max_refreshed_items
        ? commentKPI.max_refreshed_items
        : 0,
    sum_inserted_items:
      commentKPI && commentKPI.sum_inserted_items
        ? commentKPI.sum_inserted_items
        : 0,
    avg_duration:
      commentKPI && commentKPI.avg_duration ? commentKPI.avg_duration : 0
  };

  return item;
};
