import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

import InputComponent from '../../commons/input/inputComponent';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  formControlContainer: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  formGroup: {
    width: '50%'
  }
}));

const ChannelWhitelistForm = props => {
  const classes = useStyles();
  const { channel_id } = props.formData;

  return (
    <div className={classes.formControlContainer}>
      <FormControl className={classes.formControl}>
        <FormGroup className={classes.formGroup}>
          <InputComponent
            label="Channel ID"
            required={true}
            defaultValue={channel_id}
            onChange={e => {
              return props.onChangeFormData(e, 'channel_id');
            }}
            error={props.errors['channel_id'] ? true : false}
            helperText={props.errors['channel_id']}
            margin="dense"
            fullWidth={true}
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};

ChannelWhitelistForm.propTypes = {
  formData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChangeFormData: PropTypes.func.isRequired
};

export default ChannelWhitelistForm;
