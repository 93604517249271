import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import LinkedAccountsConfiguration from './linkedAccountsConfiguration';

// Redux
import { fetchLinkedAccountsList } from '../../../actions/taskKPIs/linkedAccountsAction';

const mapStateToProps = state => ({
  linkedAccountsState: state.linkedAccountsReducer
});

const mapDispatchToProps = dispatch => ({
  fetchLinkedAccountsList: (assetId, fromDate, toDate) =>
    dispatch(fetchLinkedAccountsList(assetId, fromDate, toDate))
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 196px)',
    paddingTop: theme.spacing(1)
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 196px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 367px)',
    marginTop: 'calc(50vh - 96px)'
  },
  title: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const LinkedAccountsContainer = props => {
  const classes = useStyles();
  const [isInitialized, setInitialized] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isNotAllowed, setNotAllowed] = useState(false);
  const [linkedAccountsList, setLinkedAccountsList] = useState([]);

  useEffect(() => {
    if (props.permissions.includes('admin_get_asset_linked_accounts')) {
      if (props.tabIndex === 2 && !isInitialized) {
        setLoading(true);
        setInitialized(true);
        props.fetchLinkedAccountsList(props.assetId);
      }
    } else {
      setNotAllowed(true);
    }
  }, [props.tabIndex]);

  useEffect(() => {
    if (!props.linkedAccountsState.fetching) {
      setLinkedAccountsList(props.linkedAccountsState.linkedAccountsList);
      setLoading(false);
    }
  }, [props.linkedAccountsState.fetching]);

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h5">Linked Accounts</Typography>
      </div>
      {!isNotAllowed && !isLoading ? (
        <LinkedAccountsConfiguration linkedAccountsList={linkedAccountsList} />
      ) : !isNotAllowed ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <Typography className={classes.notAllowed} variant="body1">
          Not Allowed
        </Typography>
      )}
    </div>
  );
};

LinkedAccountsContainer.propTypes = {
  assetId: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkedAccountsContainer);
