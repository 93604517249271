import axios from 'axios';

import { usersConstants } from '../constants/usersConstants';
import { setHeaders } from '../setters/auth';

export const fetchUsers = () => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/users`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: usersConstants.FETCH_USERS,
    payload: promise
  });
};
