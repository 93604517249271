// Packages
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

// Components
import AdbastionReportsConfiguration from "./adbastionReportsConfiguration";

// Redux
import {
  fetchAdbastionReportsList,
  deleteAdbastionReport,
  downloadAdbastionReportUrl,
  getAdbastionReportUploadUrl,
  uploadAdbastionReport,
  uploadAdbastionReportAws,
} from "../../../actions/configuration/adbastionReportsAction";

const mapStateToProps = (state) => ({
  adbastionReportsState: state.adbastionReportsReducer,
  meState: state.meReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAdbastionReportsList: (clientShortName) =>
    dispatch(fetchAdbastionReportsList(clientShortName)),
  deleteAdbastionReport: (id) => dispatch(deleteAdbastionReport(id)),
  downloadAdbastionReportUrl: (id) => dispatch(downloadAdbastionReportUrl(id)),
  getAdbastionReportUploadUrl: () => dispatch(getAdbastionReportUploadUrl()),
  uploadAdbastionReport: (data) => dispatch(uploadAdbastionReport(data)),
  uploadAdbastionReportAws: (url, data) =>
    dispatch(uploadAdbastionReportAws(url, data)),
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  loading: {
    margin: "auto",
    marginTop: "calc(50vh - 96px)",
    color: theme.palette.orange[300],
  },
  notAllowed: {
    margin: "auto",
    height: "calc(100vh - 473px)",
    marginTop: "calc(50vh - 96px)",
  },
}));

const AdbastionReportsContainer = (props) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [isInitialized, setInitialized] = useState(false);
  const [isNotAllowed, setNotAllowed] = useState(false);
  const [isCreatable, setCreatable] = useState(false);
  const [isDeletable, setDeletable] = useState(false);
  const [adbastionReportsList, setAdbastionReportsList] = useState([]);

  const uploadAdbastionReport = (formData) => {
    props.getAdbastionReportUploadUrl().then((response) => {
      var responseData = response.value.data;
      const reader = new FileReader();

      reader.onload = (e) => {
        const binary = e.target.result;
        const url = responseData.url;

        props.uploadAdbastionReportAws(url, binary).then(() => {
          const report = {
            id: responseData.filename,
            client_short_name: props.clientShortName,
            name: formData.name,
            description: formData.description,
          };
          props.uploadAdbastionReport(report);
        });
      };

      reader.readAsArrayBuffer(formData.file);
    });
  };

  const deleteAdbastionReport = (id) => {
    props.deleteAdbastionReport(id);
  };

  const getAdbastionReportUrl = (id) => {
    props.downloadAdbastionReportUrl(id).then((response) => {
      window.open(response.value.data.url, "_blank");
    });
  };

  useEffect(() => {
    if (props.permissions.includes("admin_list_report")) {
      if (props.tabIndex === 9 && !isInitialized) {
        setLoading(true);
        setInitialized(true);
        props.fetchAdbastionReportsList(props.clientShortName);
      }
      if (
        props.permissions.includes("admin_get_report_upload_url") &&
        props.permissions.includes("admin_create_report")
      ) {
        setCreatable(true);
      }
      if (props.permissions.includes("admin_delete_report")) {
        setDeletable(true);
      }
    } else {
      setNotAllowed(true);
    }
  }, [props.tabIndex]);

  useEffect(() => {
    if (!props.adbastionReportsState.fetching) {
      setAdbastionReportsList(props.adbastionReportsState.adbastionReportsList);
      setLoading(false);
    }
  }, [props.adbastionReportsState.fetching]);

  return (
    <div className={classes.root}>
      {!isNotAllowed && !isLoading ? (
        <AdbastionReportsConfiguration
          adbastionReportsList={adbastionReportsList}
          isCreatable={isCreatable}
          isDeletable={isDeletable}
          uploadAdbastionReport={uploadAdbastionReport}
          deleteAdbastionReport={deleteAdbastionReport}
          downloadAdbastionReportUrl={getAdbastionReportUrl}
        />
      ) : !isNotAllowed ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <Typography className={classes.notAllowed} variant="body1">
          Not Allowed
        </Typography>
      )}
    </div>
  );
};

AdbastionReportsContainer.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  clientShortName: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdbastionReportsContainer);
