import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import ClaraConfiguration from './claraConfiguration';

import {
  fetchClassifiers,
  updateRules,
  updateStatusClassifier,
  addClassifier,
  deleteClassifier,
  resetClassifiers
} from '../../../actions/configuration/claraAction';

import { fetchTagsList } from '../../../actions/configuration/tagsAction';
import { fetchClassifiersList } from '../../../actions/configuration/classifiersAction';

const styles = theme => ({
  root: {
    width: '100%'
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 440px)',
    marginTop: 'calc(50vh - 96px)'
  }
});

const mapStateToProps = state => ({
  tags: state.tagsReducer,
  clara: state.claraReducer,
  classifier: state.classifiersReducer
});

const mapDispatchToProps = dispatch => ({
  resetClassifiers: () => dispatch(resetClassifiers()),
  fetchClassifiers: clientShortName =>
    dispatch(fetchClassifiers(clientShortName)),
  fetchClassifiersList: () => dispatch(fetchClassifiersList()),
  fetchTagsList: () => dispatch(fetchTagsList()),
  updateRules: (clientShortName, index, classifier_name, rules, version) =>
    dispatch(
      updateRules(clientShortName, index, classifier_name, rules, version)
    ),
  updateStatusClassifier: (clientShortName, index, classifier_name, status) =>
    dispatch(
      updateStatusClassifier(clientShortName, index, classifier_name, status)
    ),
  addClassifier: (clientShortName, classifier_name, rules, version) =>
    dispatch(addClassifier(clientShortName, classifier_name, rules, version)),
  deleteClassifier: (clientShortName, id, classifier_name) =>
    dispatch(deleteClassifier(clientShortName, id, classifier_name))
});

class ClaraContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
      loading: true,
      notAllowed: false,
      editable: false,
      creatable: false,
      deletable: false,
      classifierList: [],
      classifierClara: [],
      clientShortName: props.clientShortName,
      clientTags: props.clientTags || [],
      tagList: []
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.tags.tagList !== prevState.tagList) {
      update.tagList = nextProps.tags.tagList;
    }

    if (nextProps.clara.classifierClara !== prevState.classifierClara) {
      update.classifierClara = nextProps.clara.classifierClara;
    }

    if (nextProps.classifier.classifierList !== prevState.classifierList) {
      update.classifierList = nextProps.classifier.classifierList;
      update.creatable = true;
    }

    return Object.keys(update).length ? update : null;
  }

  updateRules(index, rules, version, callback) {
    const classifier_name = this.state.classifierClara[index][
      'classifier_name'
    ];
    this.props
      .updateRules(
        this.state.clientShortName,
        index,
        classifier_name,
        rules,
        version
      )
      .then(() => {
        if (typeof callback === 'function') {
          callback();
        }
      });
  }

  updateStatusClassifier(index, status) {
    const classifier_name = this.state.classifierClara[index][
      'classifier_name'
    ];
    this.props.updateStatusClassifier(
      this.state.clientShortName,
      index,
      classifier_name,
      status
    );
  }

  addClassifier(classifier_name, rules, version, callback) {
    this.props
      .addClassifier(
        this.state.clientShortName,
        classifier_name,
        rules,
        version
      )
      .then(() => {
        if (typeof callback === 'function') {
          callback();
        }
      });
  }

  deleteClassifier(id, classifier_name, callback) {
    this.props
      .deleteClassifier(this.state.clientShortName, id, classifier_name)
      .then(() => {
        if (typeof callback === 'function') {
          callback();
        }
      });
  }

  initialize() {
    if (this.props.permissions.includes('admin_list_client_clara')) {
      this.props.fetchTagsList().then(() => {
        this.props.fetchClassifiers(this.state.clientShortName).then(() => {
          this.setState({
            loading: false
          });

          if (this.props.permissions.includes('admin_create_client_clara')) {
            this.props.fetchClassifiersList();
          }

          if (this.props.permissions.includes('admin_delete_client_clara')) {
            this.setState({
              deletable: true
            });
          }

          if (this.props.permissions.includes('admin_update_client_clara')) {
            this.setState({
              editable: true
            });
          }
        });
      });
    } else {
      this.setState({
        notAllowed: true,
        loading: false
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tabIndex === 5 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true
        },
        this.initialize
      );
    }
  }

  componentDidMount() {
    if (this.props.tabIndex === 5 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true
        },
        this.initialize
      );
    }
  }

  componentWillUnmount() {
    this.props.resetClassifiers();
  }

  render() {
    const { classes } = this.props;
    const {
      loading,
      notAllowed,
      classifierClara,
      classifierList,
      tagList,
      editable,
      creatable,
      deletable,
      clientTags,
      clientShortName
    } = this.state;

    return (
      <div className={classes.root}>
        {!notAllowed && !loading && tagList.length ? (
          <ClaraConfiguration
            tagList={tagList}
            clientTags={clientTags}
            classifierClara={classifierClara}
            classifierList={classifierList}
            editable={editable}
            creatable={creatable}
            deletable={deletable}
            updateRules={this.updateRules.bind(this)}
            updateStatusClassifier={this.updateStatusClassifier.bind(this)}
            addClassifier={this.addClassifier.bind(this)}
            deleteClassifier={this.deleteClassifier.bind(this)}
            clientShortName={clientShortName}
          />
        ) : !notAllowed ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <Typography className={classes.notAllowed} variant="body1">
            Not Allowed
          </Typography>
        )}
      </div>
    );
  }
}

ClaraContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  clientShortName: PropTypes.string.isRequired,
  clientTags: PropTypes.array.isRequired,
  tabIndex: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ClaraContainer));
