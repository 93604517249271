// App.js
import React, { Component } from 'react';

import RouteComponent from './components/route/RouteComponent';
import CssBaseline from '@material-ui/core/CssBaseline';
import { themeBrandBastionDefault } from './theme/themeBrandBastion';
import { themeBrandBastionDark } from './theme/themeBrandBastionDark';
import { MuiThemeProvider } from '@material-ui/core/styles';

import OAuthButton from './components/oauth/OAuthButton';
import OAuthLoader from './components/oauth/OAuthLoader';

import { Auth, Hub } from 'aws-amplify';
import { withCookies, Cookies } from 'react-cookie';

import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);
    // let the Hub module listen on Auth events
    Hub.listen('auth', data => {
      switch (data.payload.event) {
        case 'signIn':
          this.setState({ authData: data.payload.data });
          if (
            !data.payload.data.storage[
              `${data.payload.data.keyPrefix}.${data.payload.data.username}.idToken`
            ]
          ) {
            this.setState({ authState: 'loading' });
            this.componentDidMount();
          } else {
            this.setState({ authState: 'signedIn' });
          }
          break;
        case 'signIn_failure':
          this.setState({
            authState: 'signIn',
            authData: null,
            authError: data.payload.data
          });
          break;
        default:
          break;
      }
    });
    this.state = {
      authState: 'loading',
      authData: null,
      authError: null,
      cookieExpires: null
    };
  }

  setCookie(name, value) {
    const cookies = new Cookies();
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.setState({
      cookieExpires: tomorrow
    });
    cookies.set(name, value, {
      path: '/',
      expires: tomorrow,
      domain: process.env.COOKIE_DOMAIN
    });
  }

  removeCookie(name) {
    const cookies = new Cookies();
    cookies.remove(name, {
      path: '/',
      expires: this.state.cookieExpires,
      domain: process.env.COOKIE_DOMAIN
    });
  }

  async componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        const idToken =
          user.storage[`${user.keyPrefix}.${user.username}.idToken`];
        localStorage.setItem('token', idToken);
        this.setState({ authState: 'signedIn' });
        this.setCookie('idToken', idToken);
      })
      .catch(e => {
        console.log(e);
        this.setState({ authState: 'signIn' });
      });
  }

  signOut() {
    Auth.signOut({ global: true })
      .then(() => {
        this.setState({ authState: 'signIn' });
        this.removeCookie('idToken');
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    const { authState } = this.state;
    return (
      <div className="App">
        {authState === 'loading' && <OAuthLoader />}
        {authState === 'signIn' && <OAuthButton />}
        {authState === 'signedIn' && (
          <MuiThemeProvider
            theme={
              JSON.parse(localStorage.getItem('darkTheme'))
                ? themeBrandBastionDark
                : themeBrandBastionDefault
            }
          >
            <div className="App">
              <CssBaseline />
              <RouteComponent signOut={this.signOut.bind(this)} />
            </div>
          </MuiThemeProvider>
        )}
      </div>
    );
  }
}

export default withCookies(App);
