import axios from 'axios';

import { industriesConstants } from '../constants/industriesConstants';
import { setHeaders } from '../setters/auth';

export const fetchIndustryList = () => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/industries`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: industriesConstants.FETCH_INDUSTRIES,
    payload: promise
  });
};
