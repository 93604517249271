import React from "react";
import classNames from "classnames";
import { Chip } from "@material-ui/core";

import { ALERT_TYPES } from "../../../reducers/alertStatsReducer";

export const AlertStatus = ({ alert, classes }) => {
  const type = ALERT_TYPES[alert.type] || {};

  return (
    <Chip
      size="small"
      label={type.label}
      className={classNames(classes.replyLabel, classes[type.id])}
    />
  );
};
