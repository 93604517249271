import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CheckBox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/Info';

import InputComponent from '../../commons/input/inputComponent';
import DatePicker from '../../commons/picker/dateTimePickerComponent';
import SelectComponent from '../../commons/select/selectComponent';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  formControlContainer: {
    width: '100%'
  },
  formControl: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  formGroup: {
    width: '50%'
  },
  formGroupButtons: {
    width: '50%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  formElement: {
    width: '80%'
  },
  loading: {
    margin: '0 auto',
    display: 'block',
    color: theme.palette.orange[300]
  },
  actionButton: {
    width: '48%',
    marginTop: '16px'
  },
  actionLoading: {
    width: '20px !important',
    height: '20px !important'
  },
  infoText: {
    display: 'flex',
    marginBottom: 16
  }
}));

const CrawlingForm = props => {
  const classes = useStyles();

  const {
    formData,
    formPermissions,
    formActionsLoading,
    errors,
    isCSNAlreadyAssigned,
    isEditBatch
  } = props;

  const displayActionButton = (
    action,
    buttonText,
    payload = null,
    keyNumber = 0
  ) => {
    return (
      <Button
        className={classes.actionButton}
        variant="contained"
        size="small"
        color="primary"
        disabled={formPermissions[action] === false}
        key={action}
        onClick={e => {
          return props.formAction(action, payload);
        }}
      >
        {formActionsLoading[action] && (
          <CircularProgress
            className={classes.actionLoading}
            color="secondary"
          />
        )}
        &nbsp;&nbsp;
        {formPermissions[action] === false ? 'Not Allowed' : buttonText}
      </Button>
    );
  };

  const displayStatusButtons = () => {
    const { crawlingStatus } = props.formData;
    const { isCSNAlreadyAssigned } = props;
    const buttons = [];
    if (crawlingStatus === 'paused' && isCSNAlreadyAssigned) {
      buttons.push(
        displayActionButton('startCrawling', 'Start Crawling', true)
      );
    } else if (crawlingStatus === 'running') {
      buttons.push(displayActionButton('stopCrawling', 'Stop Crawling', false));
    }
    if (['N/A', 'paused', 'running'].indexOf(crawlingStatus) !== -1) {
      buttons.push(
        displayActionButton('disableCrawling', 'Disable Crawling', false)
      );
    } else if (crawlingStatus === 'disabled') {
      buttons.push(
        displayActionButton('enableCrawling', 'Enable Crawling', true)
      );
    }

    return buttons;
  };

  const displayTextField = (
    name,
    label,
    required,
    disabled,
    multiline,
    type = 'text',
    context = null
  ) => {
    const dataContext =
      context && formData[context] ? formData[context] : formData;
    const errorContext = context && errors[context] ? errors[context] : errors;

    return (
      <InputComponent
        type={type}
        multiline={multiline}
        required={required}
        disabled={disabled}
        defaultValue={dataContext[name]}
        onChange={e => {
          return props.onChangeFormData(name, e.target.value, context);
        }}
        error={errorContext[name] ? true : false}
        helperText={errorContext[name]}
        label={label}
        margin="dense"
        halfWidth={true}
      />
    );
  };

  return (
    <div className={classes.formControlContainer}>
      {props.isLoading ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <React.Fragment>
          <FormControl className={classes.formControl}>
            {!isCSNAlreadyAssigned ? (
              <Typography className={classes.infoText}>
                <InfoIcon color="primary" />
                &nbsp;&nbsp;No client assigned to asset{isEditBatch ? 's' : ''}.
                To create tasks, assign a client short name to asset
                {isEditBatch ? 's' : ''}.
              </Typography>
            ) : formData.crawlers.sla === null ? (
              <Typography className={classes.infoText}>
                <InfoIcon color="primary" />
                &nbsp;&nbsp;Crawling parameters are not set for client. Creating
                tasks will be disabled. You can configure crawling for the
                client in clients tab.
              </Typography>
            ) : formData.crawlingStatus === 'N/A' ? (
              <Typography className={classes.infoText}>
                <InfoIcon color="primary" />
                &nbsp;&nbsp;Crawling status is not configured. Use below form to
                create tasks.
              </Typography>
            ) : formData.crawlingStatus !== 'disabled' && isEditBatch ? (
              <Typography className={classes.infoText}>
                <InfoIcon color="primary" />
                &nbsp;&nbsp;When Updating tasks for batch assets, initial form
                data when the form opens is default crawling parameters of the
                client.
              </Typography>
            ) : null}
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormGroup className={classes.formGroup}>
              <Typography>
                Crawling Status:{' ' + formData.crawlingStatus}
              </Typography>
            </FormGroup>
            <FormGroup className={classes.formGroupButtons}>
              {displayStatusButtons()}
            </FormGroup>
          </FormControl>
          {formData.crawlers.sla !== null &&
            isCSNAlreadyAssigned &&
            formData.crawlingStatus !== 'disabled' && (
              <React.Fragment>
                <FormControl className={classes.formControl}>
                  <FormGroup className={classes.formGroup}>
                    <div className={classes.container}>
                      <DatePicker
                        required={true}
                        error={
                          errors.crawlers ? errors.crawlers['initTime'] : null
                        }
                        minDate={new Date('2019-01-01')}
                        settedValue={false}
                        defaultTimeValue="00:00"
                        ampm={false}
                        name={'initTime'}
                        label={'Init Time'}
                        value={
                          formData.crawlers && formData.crawlers['initTime']
                            ? formData.crawlers['initTime']
                            : null
                        }
                        setSelectedDate={(name, value) =>
                          props.onChangeFormData(name, value, 'crawlers')
                        }
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className={classes.formGroupButtons}>
                    {formData.crawlingStatus === 'N/A'
                      ? displayActionButton(
                          'createTasks',
                          'Create Tasks',
                          formData['crawlers']
                        )
                      : displayActionButton(
                          'updateTasks',
                          'Update Tasks',
                          formData['crawlers']
                        )}
                  </FormGroup>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <FormGroup className={classes.formGroup}>
                    {displayTextField(
                      'sla',
                      'SLA',
                      true,
                      false,
                      false,
                      'number',
                      'crawlers'
                    )}
                  </FormGroup>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <FormGroup className={classes.formGroup}>
                    {displayTextField(
                      'alertSla',
                      'Alert SLA',
                      true,
                      false,
                      false,
                      'number',
                      'crawlers'
                    )}
                  </FormGroup>
                </FormControl>
                {formData.canPullInsights && (
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <CheckBox
                          name="pullInsights"
                          checked={formData.crawlers['pullInsights']}
                          onChange={(e, value) =>
                            props.onChangeFormData(
                              'pullInsights',
                              value,
                              'crawlers'
                            )
                          }
                        />
                      }
                      label={`Pull insights (click reactions to posts and comments)`}
                    />
                  </FormControl>
                )}
                {formData.isAdAsset && (
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <CheckBox
                          name="queryOnlyActive"
                          checked={formData.crawlers['queryOnlyActive']}
                          onChange={(e, value) =>
                            props.onChangeFormData(
                              'queryOnlyActive',
                              value,
                              'crawlers'
                            )
                          }
                        />
                      }
                      label={`Query only active ads`}
                    />
                  </FormControl>
                )}
                {formData.isAdAsset && (
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <CheckBox
                          name="allowPublishedPosts"
                          checked={formData.crawlers['allowPublishedPosts']}
                          onChange={(e, value) =>
                            props.onChangeFormData(
                              'allowPublishedPosts',
                              value,
                              'crawlers'
                            )
                          }
                        />
                      }
                      label={`Allow published posts`}
                    />
                  </FormControl>
                )}
                {formData.isAdAsset && (
                  <FormControl className={classes.formControl}>
                    <SelectComponent
                      label={'Ad Platforms'}
                      multiple={true}
                      value={formData.crawlers['adPlatforms']}
                      attributId="id"
                      attributName="label"
                      items={[
                        { label: 'Facebook', id: 'facebook' },
                        { label: 'Instagram', id: 'instagram' }
                      ]}
                      error={
                        errors.crawlers ? errors.crawlers['adPlatforms'] : null
                      }
                      mandatory={true}
                      onChange={e => {
                        props.onChangeFormData(
                          'adPlatforms',
                          e.target.value,
                          'crawlers'
                        );
                      }}
                    />
                  </FormControl>
                )}
              </React.Fragment>
            )}
        </React.Fragment>
      )}
    </div>
  );
};

CrawlingForm.propTypes = {
  formData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChangeFormData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isCSNAlreadyAssigned: PropTypes.bool.isRequired,
  formOptions: PropTypes.object.isRequired,
  formPermissions: PropTypes.object.isRequired,
  formActionsLoading: PropTypes.object.isRequired
};

export default CrawlingForm;
