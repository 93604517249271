import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    height: 'calc(100vh - 120px)'
  },
  title: {
    marginBottom: 7
  },
  button: {
    margin: theme.spacing(2)
  },
  progress: {
    color: theme.palette.white
  }
});

class SavedDataSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupsTagConfig: false,
      translateConfig: false,
      requests: false
    };
  }

  clearData(data) {
    this.setState(
      {
        [data]: true
      },
      () => {
        localStorage.removeItem(data);
      }
    );

    setTimeout(() => {
      this.setState({
        [data]: false
      });
    }, 1000);
  }

  render() {
    const { classes } = this.props;
    const { groupsTagConfig, translateConfig, requests } = this.state;
    return (
      <div className={classes.root}>
        <Typography className={classes.title} variant="h5">
          Saved Data
        </Typography>

        <div>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            startIcon={
              groupsTagConfig ? (
                <CircularProgress className={classes.progress} size={18} />
              ) : null
            }
            onClick={() => this.clearData('groupsTagConfig')}
          >
            Clear Group Tags
          </Button>

          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            startIcon={
              translateConfig ? (
                <CircularProgress className={classes.progress} size={18} />
              ) : null
            }
            onClick={() => this.clearData('translateConfig')}
          >
            Clear Translation
          </Button>

          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            startIcon={
              requests ? (
                <CircularProgress className={classes.progress} size={18} />
              ) : null
            }
            onClick={() => this.clearData('requests')}
          >
            Clear Logs
          </Button>
        </div>
      </div>
    );
  }
}

SavedDataSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(SavedDataSettings);
