import { replaceAll } from "../utils/utils";
import moment from "moment";

export const getUTC = () => {
  return moment.utc().valueOf();
};

export const setDateFormat = (date) => {
  let d = replaceAll(date, "-", "/");
  let realDate = replaceAll(d, "T", " at ");

  if (realDate.indexOf("+") > -1) {
    return realDate.substring(0, realDate.indexOf("+"));
  }

  if (realDate.indexOf(".") > -1) {
    return realDate.substring(0, realDate.indexOf("."));
  }

  return realDate;
};

export const setDateFormatTimeStamp = (timestamp) => {
  return moment(timestamp * 1000)
    .utc()
    .format("YYYY/MM/DD [at] HH:mm:ss");
};

export const setDateWithSec = (seconds) => {
  return moment.utc(seconds * 1000).format("HH:mm:ss");
};

export const fromNow = (timeStamp) => {
  let time = moment(timeStamp).fromNow(true);

  let replaceTime = function (string, part, replace) {
    if (string.includes(`${part}s`)) {
      return replaceAll(string, `${part}s`, replace);
    }

    if (string.includes(part)) {
      return replaceAll(string, `${part}`, replace);
    }

    return string;
  };

  time = replaceTime(time, " seconde", "sc");
  time = replaceTime(time, " minute", "min");
  time = replaceTime(time, " hour", "h");
  time = replaceTime(time, " day", "d");
  time = replaceTime(time, " month", "m");
  time = replaceTime(time, " year", "y");
  time = replaceTime(time, "a", "1");
  return time;
};

export const formatDateCustom = (dateString, formatPattern) => {
  return moment(dateString).format(formatPattern);
};
