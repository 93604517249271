export const replaceAll = (string, search, replacement) => {
  var target = string;
  return target.replace(new RegExp(search, 'g'), replacement);
};

export const validateEmail = email => {
  //eslint-disable-next-line no-useless-escape
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const mergeArrays = (...arrays) => {
  let jointArray = [];

  arrays.forEach(array => {
    jointArray = [...jointArray, ...array];
  });
  const uniqueArray = jointArray.filter(
    (item, index) => jointArray.indexOf(item) === index
  );
  return uniqueArray;
};

export const convertTimeToSecond = value => {
  let a = value.split(':');
  return +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
};

export const convertSecondToTime = seconds => {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
};

export const capitalizeUsername = username => {
  return username
    .toLowerCase()
    .split(new RegExp(/[-]|[.]|[\s]/g))
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

export const isContainBracket = string => {
  return string.includes('[') ? true : string.includes(']') ? true : false;
};

export const removeEmpty = obj => {
  Object.keys(obj).forEach(
    key => (obj[key] === '' || obj[key].length === 0) && delete obj[key]
  );
  return obj;
};

export const downloadAsJson = (data, fname) => {
  var dataString =
    'text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data, 0, 4));
  var a = document.createElement('a');
  a.href = 'data:' + dataString;
  a.download = fname + '.json';

  document.body.appendChild(a);
  a.click();
  a.remove();
};
