import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import AppBar from '../../components/commons/appBar/AppBarComponent';
import LeftMenu from '../../components/commons/leftMenu/leftMenuComponent';
import SubHeader from '../../components/commons/subHeader/subHeaderComponent';
import DialogContent from '../../components/commons/dialog/contentDialogComponent';
import AlertDialog from '../../components/commons/dialog/alertDialogComponent';

import InputComponent from '../../components/commons/input/inputComponent';
import SelectComponent from '../../components/commons/select/selectComponent';
import SalesList from '../../components/salesList/salesListComponent';

import { fetchMe } from '../../actions/meAction';
import {
  fetchOwnerList,
  editCode,
  deleteCode,
  createNewCode,
  fetchListAnalysis
} from '../../actions/salesAction';

const styles = theme => ({
  root: {
    display: 'flex'
  },
  content: {
    width: '100%'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    height: 48
  },
  notAllowed: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)'
  },
  loading: {
    margin: 'auto',
    color: theme.palette.orange[300],
    marginTop: 'calc(50vh - 96px)'
  }
});

const mapStateToProps = state => ({
  me: state.meReducer,
  sales: state.salesReducer
});

const mapDispatchToProps = dispatch => ({
  fetchMe: () => dispatch(fetchMe()),
  fetchOwnerList: () => dispatch(fetchOwnerList()),
  createNewCode: data => dispatch(createNewCode(data)),
  editCode: (index, data) => dispatch(editCode(index, data)),
  deleteCode: (index, data) => dispatch(deleteCode(index, data)),
  fetchListAnalysis: options => dispatch(fetchListAnalysis(options))
});

class SalesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      open: false,
      notAllowed: false,
      openDialogCreateCode: false,
      openDialogDeleteCode: false,
      ownerList: null,
      list: null,
      redirectAttemps: null,
      codeSelected: null,
      titleCode: 'Create',
      dataCode: {
        id: '',
        owner: '',
        used_attempts: '0',
        max_attempts: '100'
      }
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.sales.ownerList !== prevState.ownerList) {
      update.ownerList = nextProps.sales.ownerList;
    }

    if (nextProps.sales.list !== prevState.list) {
      update.list = nextProps.sales.list;
    }

    return Object.keys(update).length ? update : null;
  }

  logout() {
    this.props.signOut();
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  searchResult(value) {
    this.props.fetchListAnalysis({
      search: value
    });
  }

  resetDataCode() {
    this.setState({
      dataCode: {
        id: '',
        owner: '',
        used_attempts: '0',
        max_attempts: '100'
      },
      titleCode: 'Create',
      openDialogCreateCode: false
    });
  }

  updateCode(type) {
    switch (type) {
      case 'Create':
        this.props.createNewCode(this.state.dataCode).then(() => {
          this.resetDataCode();
        });
        break;
      case 'Edit':
        this.props
          .editCode(this.state.codeSelected, this.state.dataCode)
          .then(() => {
            this.resetDataCode();
          });
        break;
      case 'Delete':
        this.props
          .deleteCode(this.state.codeSelected, this.state.dataCode)
          .then(() => {
            this.resetDataCode();
          });
        break;
      default:
      // code block
    }
  }

  actionSubHeader(event) {
    this.setState({
      dataCode: {
        id: '',
        owner: '',
        used_attempts: '0',
        max_attempts: '100'
      },
      titleCode: 'Create',
      openDialogCreateCode: true
    });
  }

  onClickSalesItem(value) {
    this.setState({
      redirectAttemps: value
    });
  }

  actionItemHeader(item, index, action) {
    if (action === 'edit') {
      this.setState({
        titleCode: 'Edit',
        openDialogCreateCode: true,
        dataCode: {
          id: item.id,
          owner: item.owner,
          used_attempts: item.used_attempts,
          max_attempts: item.max_attempts
        },
        codeSelected: index
      });
    }

    if (action === 'delete') {
      this.setState({
        openDialogDeleteCode: true,
        codeSelected: index,
        dataCode: {
          id: item.id,
          owner: item.owner,
          used_attempts: item.used_attempts,
          max_attempts: item.max_attempts
        }
      });
    }
  }

  onChangeCode(e, id) {
    this.setState({
      dataCode: {
        ...this.state.dataCode,
        [id]: e.target.value
      }
    });
  }

  onChangeAttempts(e, id) {
    this.setState({
      dataCode: {
        ...this.state.dataCode,
        [id]:
          e.target.value > 1000 ? 1000 : e.target.value < 1 ? 1 : e.target.value
      }
    });
  }

  actionDialogDeleteCode(action) {
    if (action) {
      this.updateCode('Delete');
    }

    this.setState({
      openDialogDeleteCode: false
    });
  }

  componentDidMount() {
    this.props.fetchMe().then(response => {
      if (response.value.data.permissions.includes('sales_access')) {
        this.props.fetchOwnerList().then(() => {
          this.props.fetchListAnalysis({});
        });
      } else {
        this.setState({
          notAllowed: true
        });
      }
    });
  }

  render() {
    const { classes } = this.props;
    const {
      open,
      openDialogCreateCode,
      openDialogDeleteCode,
      dataCode,
      list,
      ownerList,
      notAllowed,
      titleCode,
      redirectAttemps
    } = this.state;

    if (redirectAttemps) {
      return (
        <Redirect
          to={{
            pathname: `/sales/${redirectAttemps}/attempts`,
            state: {
              id: redirectAttemps
            }
          }}
        />
      );
    }

    return (
      <div className={classes.root}>
        <AppBar
          action={this.handleDrawerOpen}
          status={open}
          selected={'sales'}
          logout={this.logout.bind(this)}
        />
        <LeftMenu open={open} onClose={this.handleDrawerClose.bind(this)} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {!notAllowed ? (
            !(ownerList && ownerList.length) ? (
              <CircularProgress className={classes.loading} />
            ) : (
              <div>
                <SubHeader
                  actionDisabled={!ownerList.length}
                  actionName="Create Code"
                  action={this.actionSubHeader.bind(this)}
                  searchResult={this.searchResult.bind(this)}
                />
                <Grid className={classes.grid} container>
                  <Grid item xs={2} />
                  <Grid item xs={8}>
                    <SalesList
                      list={list}
                      actionItemHeader={this.actionItemHeader.bind(this)}
                      onClickSalesItem={this.onClickSalesItem.bind(this)}
                    />
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </div>
            )
          ) : (
            <Typography className={classes.notAllowed} variant="body1">
              Not Allowed
            </Typography>
          )}
        </main>
        <DialogContent
          title={`${titleCode} a code`}
          open={openDialogCreateCode}
          close={() =>
            this.setState({
              openDialogCreateCode: false
            })
          }
        >
          <div>
            <div>
              <InputComponent
                id={'id'}
                label={'Code'}
                disabled={titleCode === 'Edit' ? true : false}
                defaultValue={dataCode['id']}
                onChange={this.onChangeCode.bind(this)}
              />
              <SelectComponent
                id={'owner'}
                label={'Owner'}
                attributId={'id'}
                attributName={'username'}
                value={
                  ownerList &&
                  ownerList.filter(
                    owner => owner.username === dataCode['owner']
                  ) &&
                  ownerList.filter(
                    owner => owner.username === dataCode['owner']
                  ).length
                    ? dataCode['owner']
                    : ''
                }
                items={ownerList}
                onChange={this.onChangeCode.bind(this)}
              />
              <InputComponent
                id={'max_attempts'}
                type="number"
                label={'Attempts'}
                inputProps={{ min: '1', max: '1000', step: '1' }}
                value={dataCode['max_attempts']}
                onChange={this.onChangeAttempts.bind(this)}
              />
            </div>
            <div>
              <Button
                onClick={() => this.updateCode(titleCode)}
                color="primary"
                disabled={
                  !dataCode['id'] || !Object.values(dataCode['owner']).length
                }
                className={classes.createCodeButton}
                size="small"
              >
                {titleCode}
              </Button>
              <Button
                onClick={() =>
                  this.setState({
                    openDialogCreateCode: false
                  })
                }
                color="secondary"
                className={classes.cancelCodeButton}
                size="small"
              >
                {'Cancel'}
              </Button>
            </div>
          </div>
        </DialogContent>
        <AlertDialog
          title="Delete this Code?"
          message={
            'are you sure to delete this code? All the attempts related to this code will be removed'
          }
          buttonAcceptText="Yes"
          buttonCancelText="No"
          action={this.actionDialogDeleteCode.bind(this)}
          open={openDialogDeleteCode}
        />
      </div>
    );
  }
}

SalesPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SalesPage));
