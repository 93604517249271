import axios from "axios";

import { tagsConstants } from "../../constants/configuration/tagsConstants";
import { setHeaders } from "../../setters/auth";

export const fetchTagsList = () => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/tags`;

  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: tagsConstants.FETCH_TAG_LIST,
    payload: promise,
  });
};

export const addTag = (data) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/tags`;
  let tag = {
    id: data.id,
    mod_ui: {
      description: data.description,
      group: data.group,
      label: data.label,
      example: data.example,
      excluded_example: data.excluded_example,
      pim_tone: data.pim_tone,
      pim_weight: data.pim_weight,
    },
    volume_based_alert: data.volume_based_alert,
  };

  if (data.tag_category) {
    tag.tag_category = data.tag_category;
  }

  const promise = axios.post(url, tag, {
    headers: setHeaders(),
    tagInfo: data,
  });

  return dispatch({
    type: tagsConstants.ADD_TAG,
    payload: promise,
  });
};

export const editTag = (index, data) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/tags/${data.id}`;
  let tag = {
    mod_ui: {
      description: data.description,
      group: data.group,
      label: data.label,
      example: data.example,
      excluded_example: data.excluded_example,
      pim_tone: data.pim_tone,
      pim_weight: data.pim_weight,
    },
    volume_based_alert: data.volume_based_alert,
  };

  if (data.tag_category) {
    tag.tag_category = data.tag_category;
  }

  const promise = axios.put(url, tag, {
    headers: setHeaders(),
    index: index,
    tagInfo: data,
  });

  return dispatch({
    type: tagsConstants.EDIT_TAG,
    payload: promise,
  });
};
