import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';

import FolderIcon from '@material-ui/icons/Folder';

import DialogContent from '../../commons/dialog/contentDialogComponent';
import AlertDialog from '../../commons/dialog/alertDialogComponent';
import FullScreenDialog from '../../commons/dialog/fullScreenDialogComponemt';
import SelectComponent from '../../commons/select/selectComponent';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.backgroundDark,
    borderRadius: 5,
    boxShadow: theme.palette.shadow.shadowAround
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  success: {
    backgroundColor: theme.palette.success[500]
  },
  buttonSuccess: {
    color: theme.palette.white,
    background: theme.palette.success[500],
    '&:hover, &:focus, &:active': {
      color: theme.palette.white,
      background: theme.palette.success[800]
    }
  },
  progress: {
    color: theme.palette.black
  },
  buttonContainer: {
    marginTop: theme.spacing(2)
  },
  containerListReport: {
    overflow: 'auto'
  },
  lock: {
    width: 30
  },
  lockWarning: {
    color: theme.palette.warning[800]
  }
}));

const ActionsContent = ({
  me,
  showFilterDialog,
  edit,
  move,
  removeVideo,
  cancelMove,
  list,
  downloadReport,
  lockReport,
  reportList,
  generateReport,
  videoListSelected,
  status
}) => {
  const classes = useStyles();

  const [openDialogMoveVideo, setOpenDialogMoveVideo] = useState(false);
  const [openDialogReportList, setOpenDialogReportList] = useState(false);
  const [openRemoveFromReport, setOpenRemoveFromReport] = useState(false);
  const [openLockReport, setOpenLockReport] = useState(false);
  const [lockReportSelected, setLockReportSelected] = useState(false);

  const [openDialogGenerateReport, setOpenDialogGenerateReport] = useState(
    false
  );

  const [moveReportSelected, setMoveReportSelected] = useState('');

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        size="small"
        color="primary"
        className={classes.button}
        onClick={() => showFilterDialog()}
      >
        Filters
      </Button>
      {list &&
      list.length &&
      status === 'readOnly' &&
      me &&
      me.permissions &&
      me.permissions.includes('add_copyright_report_object') ? (
        <Button
          variant="contained"
          size="small"
          className={classNames(classes.button, classes.buttonSuccess)}
          onClick={() => edit()}
        >
          Edit
        </Button>
      ) : (
        ''
      )}
      {list &&
      list.length &&
      status === 'readOnly' &&
      me &&
      me.permissions &&
      me.permissions.includes('create_copyright_report') ? (
        <Button
          variant="contained"
          size="small"
          className={classes.button}
          onClick={() => setOpenDialogGenerateReport(true)}
        >
          Generate Report
        </Button>
      ) : (
        ''
      )}
      {status === 'readOnly' &&
      me &&
      me.permissions &&
      me.permissions.includes('list_copyright_report') ? (
        <FullScreenDialog
          buttonTitle={'Report list'}
          title={'Report list'}
          open={openDialogReportList}
          action={'Close'}
          hideCloseIcon={true}
          handleClose={() => setOpenDialogReportList(false)}
          handleOpen={() => setOpenDialogReportList(true)}
          handleAction={() => setOpenDialogReportList(false)}
        >
          <div className={classes.containerListReport}>
            <List dense>
              {reportList.map((report, key) => {
                return (
                  <div key={key}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <FolderIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={report.label}
                        secondary={report.id}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          disabled={report.lock}
                          onClick={() => {
                            setLockReportSelected(report.id);
                            setOpenLockReport(true);
                          }}
                          edge="end"
                          aria-label="lock"
                        >
                          <Icon
                            className={classNames(classes.lock, {
                              'fas fa-lock-open': !report.lock,
                              'fas fa-lock': report.lock,
                              [classes.lockWarning]: report.lock
                            })}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => downloadReport(report.id)}
                          edge="end"
                          aria-label="delete"
                        >
                          <Icon className="fas fa-file-download" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider variant="middle" component="li" />
                  </div>
                );
              })}
            </List>
          </div>
        </FullScreenDialog>
      ) : (
        ''
      )}
      {status === 'edit' ? (
        <div>
          <Button
            variant="contained"
            size="small"
            disabled={videoListSelected.length === 0}
            className={classNames(classes.button, classes.buttonSuccess)}
            onClick={() => setOpenDialogMoveVideo(true)}
          >
            Move to
          </Button>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            disabled={videoListSelected.length === 0}
            className={classes.button}
            onClick={() => setOpenRemoveFromReport(true)}
          >
            Remove
          </Button>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            className={classes.button}
            onClick={() => cancelMove()}
          >
            Cancel Edit
          </Button>
        </div>
      ) : (
        ''
      )}
      <DialogContent
        title={'Move Video'}
        open={openDialogMoveVideo}
        close={() => setOpenDialogMoveVideo(false)}
      >
        <div>
          <div>
            <List dense>
              {videoListSelected.map((id, key) => {
                return (
                  <ListItem key={key}>
                    <ListItemText primary={id} />
                  </ListItem>
                );
              })}
            </List>
            <SelectComponent
              id={'moveReportSelected'}
              label={'Reports'}
              attributId={'id'}
              attributName={'label'}
              value={moveReportSelected}
              items={reportList}
              onChange={(e, id) => setMoveReportSelected(e.target.value)}
            />
          </div>
          <div className={classes.buttonContainer}>
            <Button
              size="small"
              disabled={!moveReportSelected}
              color="primary"
              onClick={() => {
                setOpenDialogMoveVideo(false);
                move(moveReportSelected);
              }}
            >
              Move
            </Button>
            <Button
              onClick={() => setOpenDialogMoveVideo(false)}
              size="small"
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
      <AlertDialog
        title="Generate new report?"
        message={
          'Are you sure to generate a new report (this may take a while).'
        }
        buttonAcceptText="Yes"
        buttonCancelText="No"
        action={status => {
          if (status) {
            generateReport();
          }
          setOpenDialogGenerateReport(false);
        }}
        open={openDialogGenerateReport}
      />
      <AlertDialog
        title="Remove from Report?"
        message={'Do you want to remove the selected videos from their report?'}
        buttonAcceptText="Yes"
        buttonCancelText="No"
        action={status => {
          if (status) {
            removeVideo();
          }
          setOpenRemoveFromReport(false);
        }}
        open={openRemoveFromReport}
      />
      <AlertDialog
        title="Lock the report?"
        message={'Do you want to lock all the videos from this report?'}
        buttonAcceptText="Yes"
        buttonCancelText="No"
        action={status => {
          if (status) {
            lockReport(lockReportSelected, true);
          }
          setOpenLockReport(false);
        }}
        open={openLockReport}
      />
    </div>
  );
};

ActionsContent.propTypes = {
  me: PropTypes.object.isRequired,
  showFilterDialog: PropTypes.func.isRequired,
  cancelMove: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  reportList: PropTypes.array.isRequired,
  downloadReport: PropTypes.func.isRequired,
  generateReport: PropTypes.func.isRequired,
  move: PropTypes.func.isRequired,
  lockReport: PropTypes.func.isRequired,
  removeVideo: PropTypes.func.isRequired,
  videoListSelected: PropTypes.array,
  status: PropTypes.string.isRequired
};

export default ActionsContent;
