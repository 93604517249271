import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CheckBox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import InputComponent from '../../commons/input/inputComponent';
import CustomizedSnackbar from '../../commons/snackBar/snackBarComponent';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formControl: {
    display: 'flex',
    width: '100%'
  },
  formGroup: {
    textAlign: 'left'
  },
  permissionGroupContainer: {
    width: '100%',
    display: 'flex'
  },
  permissionGroup: {
    backgroundColor: theme.palette.container.light,
    height: 'min-content',
    margin: theme.spacing(1),
    padding: theme.spacing(2)
  },
  permissionContainer: {},
  legend: {
    display: 'flex',
    alignItems: 'center'
  },
  iconMore: {
    cursor: 'pointer',
    marginLeft: theme.spacing(3)
  }
});

class PermissionsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionGroups: props.permissionGroups,
      roleName: props.roleName,
      roleId: props.roleId,
      error: '',
      loading: true
    };

    this.onChangeRoleName.bind(this);
    this.onChangeRoleId.bind(this);
    this.showPermissionList.bind(this);
  }

  onCloseSnackBar() {
    this.setState({ openSnackBar: false });
  }

  onChangeRoleName(e) {
    this.props.onChangeTextField('roleName', e.target.value);
  }

  onChangeRoleId(e) {
    this.props.onChangeTextField('roleId', e.target.value);
  }

  onChangeAll(e, keyGroup, permissions) {
    permissions.map((permission, KeyPermission) => {
      this.props.onChangePermission(e.target.checked, keyGroup, KeyPermission);
      return true;
    });
  }

  onChange(e, keyGroup, KeyPermission = null) {
    this.props.onChangePermission(e.target.checked, keyGroup, KeyPermission);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.permissionGroups !== this.props.permissionGroups) {
      this.setState({
        permissionGroups: this.props.permissionGroups,
        loading: false
      });
    }

    if (prevProps.errorKey !== this.props.errorKey) {
      this.setState({
        error: this.props.error,
        openSnackBar: true
      });
    }
  }

  showPermissionList(name) {
    this.setState({
      [name]: !this.state[name]
    });
  }

  componentDidMount() {
    if (this.state.permissionGroups.length) {
      this.setState({
        loading: false
      });

      this.props.permissionGroups.map(group => {
        this.setState({
          [group.name]: false
        });
        return true;
      });
    }
  }

  displayPermissionList(permissions, keyGroup) {
    const { classes, onChangePermission } = this.props;
    return (
      <div className={classes.permissionContainer}>
        {permissions
          ? permissions.map((permission, key) => {
              return (
                <div key={key}>
                  <FormControlLabel
                    control={
                      typeof onChangePermission === 'function' ? (
                        <CheckBox
                          checked={permission.checked}
                          onChange={e => this.onChange(e, keyGroup, key)}
                        />
                      ) : (
                        <p></p>
                      )
                    }
                    label={`${permission.label} (${permission.type})`}
                  />
                </div>
              );
            })
          : ''}
      </div>
    );
  }

  displayPermissionGroups(groups) {
    const { classes, onChangePermission } = this.props;
    return (
      <div className={classes.permissionGroupContainer}>
        {groups
          ? groups.map((item, key) => {
              const permissionsChecked = item.permissions.filter(permission => {
                return permission.checked === true;
              }).length;
              return (
                <div className={classes.permissionGroup} key={key}>
                  <FormLabel className={classes.legend} component="legend">
                    {typeof onChangePermission === 'function' ? (
                      <CheckBox
                        indeterminate={
                          permissionsChecked &&
                          permissionsChecked < item.permissions.length
                            ? true
                            : false
                        }
                        checked={
                          permissionsChecked === item.permissions.length
                            ? true
                            : false
                        }
                        onChange={e =>
                          this.onChangeAll(e, key, item.permissions)
                        }
                      />
                    ) : (
                      ''
                    )}
                    {item.name}
                    {typeof onChangePermission === 'function' ? (
                      <Icon
                        className={classes.iconMore}
                        onClick={e => {
                          return this.showPermissionList(item.name);
                        }}
                      >
                        {!this.state[item.name] ? `expand_more` : `expand_less`}
                      </Icon>
                    ) : (
                      ''
                    )}
                  </FormLabel>
                  <FormGroup className={classes.formGroup}>
                    {this.state[item.name] ||
                    typeof onChangePermission !== 'function'
                      ? this.displayPermissionList(item.permissions, key)
                      : ''}
                  </FormGroup>
                </div>
              );
            })
          : ''}
      </div>
    );
  }

  displayTextFields() {
    return (
      <FormGroup>
        <InputComponent
          required
          defaultValue={this.state.roleId}
          onChange={e => {
            return this.onChangeRoleId(e);
          }}
          label="Role ID"
          margin="dense"
          placeholder={'ROLE_'}
          fullWidth={true}
        />
        <InputComponent
          required
          defaultValue={this.state.roleName}
          label="Role Name"
          onChange={e => {
            return this.onChangeRoleName(e);
          }}
          margin="dense"
          fullWidth={true}
        />
      </FormGroup>
    );
  }

  render() {
    const { classes, mode } = this.props;
    const { permissionGroups, loading, error, openSnackBar } = this.state;
    return (
      <div className={classes.root}>
        {loading ? (
          <CircularProgress />
        ) : (
          <FormControl className={classes.formControl}>
            {mode ? this.displayTextFields() : ''}
            {this.displayPermissionGroups(permissionGroups)}
          </FormControl>
        )}
        <CustomizedSnackbar
          variant="error"
          onClose={this.onCloseSnackBar.bind(this)}
          message={error}
          open={openSnackBar}
        />
      </div>
    );
  }
}

PermissionsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  permissionGroups: PropTypes.array,
  roleName: PropTypes.string,
  errorKey: PropTypes.number,
  error: PropTypes.string,
  onChangePermission: PropTypes.func,
  onChangeTextField: PropTypes.func,
  roleId: PropTypes.string,
  mode: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(PermissionsForm);
