import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as fileDownload from "react-file-download";
import queryString from "query-string";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";

import AppBar from "../../components/commons/appBar/AppBarComponent";
import DialogContent from "../../components/commons/dialog/contentDialogComponent";
import LeftMenu from "../../components/commons/leftMenu/leftMenuComponent";
import ActionsContent from "../../components/actionsContent/explorer/actionContentComponent";

import CommentList from "../../components/commentList/CommentListComponent";
import PostRelated from "../../components/postRelated/postRelatedComponent";
import ExplorerSearch from "../../components/explorerSearch/explorerSearchComponent";

import { operationalTags } from "../../data/data";

import { fetchMe } from "../../actions/meAction";
import { fetchUsers } from "../../actions/usersAction";
import { createTemporaryStream } from "../../actions/streamAction";
import {
  fetchClients,
  getCommentListByClient,
  postAutoComplete,
  resetCommentList,
  fetchHistoryFromComment,
  updateTranslation,
  fetchExportFields,
  changeExportData,
  generateExportFile,
  fetchAdsFromPost,
  sendAnalyze,
  fetchAnalyzeTemplates,
  pushToStream,
} from "../../actions/explorerAction";
import { getPostStatistics } from "../../actions/statisticsAction";
import { getStreamList } from "../../actions/streamAction";

import { removeEmpty } from "../../utils/utils";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  grid: {
    width: "100%",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    height: 48,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  postRelated: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  postRelatedContainer: {
    marginBottom: "10px",
  },
  rightSide: {
    overflow: "hidden auto",
  },
  heightList: {
    paddingBottom: theme.spacing(2),
    height: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    minHeight: 268,
  },
  actionContent: {
    display: "flex",
  },
  loading: {
    margin: "auto",
    color: theme.palette.orange[300],
    marginTop: "calc(50vh - 96px)",
  },
  notAllowed: {
    margin: "auto",
    marginTop: "calc(50vh - 96px)",
  },
  totalComments: {
    width: "100%",
    textAlign: "right",
    paddingTop: theme.spacing(1),
  },
});

const mapStateToProps = (state) => ({
  me: state.meReducer,
  explorer: state.explorerReducer,
  users: state.usersReducer,
  statistics: state.statisticsReducer,
  streams: state.streamReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMe: () => dispatch(fetchMe()),
  fetchUsers: () => dispatch(fetchUsers()),
  fetchExportFields: (clientShortName) =>
    dispatch(fetchExportFields(clientShortName)),
  getCommentListByClient: (query, option) =>
    dispatch(getCommentListByClient(query, option)),
  postAutoComplete: (clientShortName, option) =>
    dispatch(postAutoComplete(clientShortName, option)),
  getPostStatistics: (clientShortName, postId) =>
    dispatch(getPostStatistics(clientShortName, postId)),
  resetCommentList: () => dispatch(resetCommentList()),
  createTemporaryStream: (data) => dispatch(createTemporaryStream(data)),
  fetchClients: () => dispatch(fetchClients()),
  getStreamList: () => dispatch(getStreamList()),
  pushToStream: (query, streamId) => dispatch(pushToStream(query, streamId)),
  sendAnalyze: (query, options) => dispatch(sendAnalyze(query, options)),
  fetchHistoryFromComment: (clientShortName, commentId, options) =>
    dispatch(fetchHistoryFromComment(clientShortName, commentId, options)),
  updateTranslation: (clientShortName, commentId, options) =>
    dispatch(updateTranslation(clientShortName, commentId, options)),
  changeExportData: (group, key, value, options) =>
    dispatch(changeExportData(group, key, value, options)),
  fetchAdsFromPost: (clientShortName, postId, options) =>
    dispatch(fetchAdsFromPost(clientShortName, postId, options)),
  generateExportFile: (query, exportFields, exportLimit) =>
    dispatch(generateExportFile(query, exportFields, exportLimit)),
  fetchAnalyzeTemplates: () => dispatch(fetchAnalyzeTemplates()),
});

class ExplorerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexList: "0",
      open: false,
      query: queryString.parse(window.location.search + window.location.hash),
      translate: false,
      openFilterDialog: false,
      statisticsSelected: false,
      loadingFile: false,
      list: [],
      language: [],
      total: 0,
      status: "readOnly",
      mode: "explorer",
      printList: [],
      exportFields: {},
      statistics: {},
      streamRedirectId: "",
      commentListSelected: [],
      clients: [],
      userList: [],
      loadingStreams: false,
      loadingPushStream: false,
      isPushStreamMaxCommentsExceeded: false,
      streamList: [],
      me: {},
      notAllowed: false,
      loading: true,
      analyzeTemplates: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.explorer.clients !== prevState.clients) {
      update.clients = nextProps.explorer.clients;
    }

    if (nextProps.me.me !== prevState.me) {
      update.me = nextProps.me.me;
    }

    if (nextProps.explorer.printList !== prevState.printList) {
      update.printList = nextProps.explorer.printList;
    }

    if (nextProps.explorer.list !== prevState.list) {
      update.list = nextProps.explorer.list;
    }
    if (nextProps.explorer.language !== prevState.language) {
      update.language = nextProps.explorer.language;
    }

    if (nextProps.explorer.total !== prevState.total) {
      update.total = nextProps.explorer.total;
      if (nextProps.explorer.total > 10000) {
        update.isPushStreamMaxCommentsExceeded = true;
      } else {
        update.isPushStreamMaxCommentsExceeded = false;
      }
    }

    if (nextProps.users.userList !== prevState.users) {
      update.userList = nextProps.users.userList;
    }

    if (nextProps.statistics.statistics !== prevState.statistics) {
      update.statistics = nextProps.statistics.statistics;
    }

    if (nextProps.explorer.exportLimit !== prevState.exportLimit) {
      update.exportLimit = nextProps.explorer.exportLimit;
    }

    if (nextProps.explorer.exportFields !== prevState.exportFields) {
      update.exportFields = nextProps.explorer.exportFields;
    }

    if (nextProps.explorer.analyzeTemplates !== prevState.analyzeTemplates) {
      update.analyzeTemplates = nextProps.explorer.analyzeTemplates;
    }

    if (nextProps.streams.streams !== prevState.streamList) {
      update.streamList = nextProps.streams.streams;
      update.loadingStreams = false;
    }

    return Object.keys(update).length ? update : null;
  }

  select() {
    this.setState({
      status: "edit",
    });
  }

  cancelEdit() {
    this.setState({
      status: "readOnly",
    });
  }

  edit() {
    const { query } = this.state;
    this.setState({
      status: "readOnly",
    });

    this.props
      .createTemporaryStream({
        label: "Temprorary",
        query: query,
        commmentIds: this.state.commentListSelected,
      })
      .then((response) => {
        if (response.value && response.value.data && response.value.data.id) {
          this.setState({
            streamRedirectId: response.value.data.id,
          });
        }
      });
  }

  updateTranslation(item, callback) {
    this.props
      .updateTranslation(item[1].client.shortName, item[1].id, {
        index: item[1].key,
      })
      .then((response) => {
        callback(response.value.data.messageTranslated);
      });
  }

  clickItemFromPost(string) {
    if (string === "openAds") {
      const post = this.state.list[this.state.indexList]["post"];
      this.props.fetchAdsFromPost(post["client"]["shortName"], post["id"], {
        index: this.state.indexList,
      });
    }
  }

  clickItemFromComment(string, data = {}, callback) {
    const { list, indexList } = this.state;

    if (typeof callback === "function") {
      callback();
    }

    if (string === "history") {
      const clientShortName = list[indexList]["client"]["shortName"];
      const commentId = list[indexList]["id"];

      this.props.fetchHistoryFromComment(clientShortName, commentId, {
        index: indexList,
      });
    }
  }

  onSelectComment(commentList) {
    this.setState({
      commentListSelected: commentList,
    });
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  onChangeIndexMenu(index) {
    if (index === 1) {
      this.setState(
        {
          statisticsSelected: true,
        },
        () => {
          const post = this.state.list[this.state.indexList]["post"];
          this.props.getPostStatistics(post["client"]["shortName"], post["id"]);
        }
      );
    } else {
      this.setState({
        statisticsSelected: false,
      });
    }
  }

  getIndexList(index) {
    //get index of the comment list
    this.setState({
      indexList: index,
    });

    if (index && this.state.list && this.state.list[index]) {
      const post = this.state.list[index]["post"];
      if (index && post && post["client"] && this.state.statisticsSelected) {
        this.props.getPostStatistics(post["client"]["shortName"], post["id"]);
      }
    }
  }

  getQueryString(q) {
    const query = queryString.stringify(removeEmpty(q), {
      strict: false,
      encode: false,
      arrayFormat: "comma",
      skipNull: true,
    });
    return query;
  }

  getFilterData(data) {
    const query = {};

    if (data.clientShortName) {
      Object.assign(query, {
        client_short_name: data.clientShortName,
      });
    } else {
      return false;
    }

    if (data.noTags) {
      Object.assign(query, {
        noTags: data.noTags,
      });
    }

    if (data.highlightedTags && !data.noTags) {
      Object.assign(query, {
        highlighted_tags: data.highlightedTags.join(","),
      });
    }

    if (data.startTime) {
      Object.assign(query, {
        start: data.startTime,
      });
    }

    if (data.endTime) {
      Object.assign(query, {
        end: data.endTime,
      });
    }

    if (data.tags && !data.noTags) {
      Object.assign(query, {
        tags: data.tags.join(","),
      });
    }

    if (data.user) {
      Object.assign(query, {
        user: data.user,
      });
    }

    if (data.authorName) {
      Object.assign(query, {
        author_name: data.authorName,
      });
    }

    if (data.assetId) {
      Object.assign(query, {
        asset_id: data.assetId.join(","),
      });
    }

    if (data.message) {
      Object.assign(query, {
        message: data.message,
      });
    }

    if (data.postType) {
      Object.assign(query, {
        postType: data.postType,
      });
    }

    if (data.language) {
      Object.assign(query, {
        language: data.language.join(","),
      });
    }

    if (data.commentIds) {
      Object.assign(query, {
        comment_ids: data.commentIds,
      });
    }

    if (data.postIds) {
      Object.assign(query, {
        post_ids: data.postIds,
      });
    }

    if (data.campaign_ids) {
      Object.assign(query, {
        campaign_ids: data.campaign_ids,
      });
    }

    if (data.platforms) {
      Object.assign(query, {
        platform_id: data.platforms.join(","),
      });
    }

    if (data.replyStatus) {
      Object.assign(query, {
        reply_status: data.replyStatus.join(","),
      });
    }

    if (data.commentTypes) {
      Object.assign(query, {
        comment_types: data.commentTypes.join(","),
      });
    }

    if (data.owned) {
      Object.assign(query, {
        owned: data.owned,
      });
    }

    if (data.anyOfObjectGroupIds) {
      Object.assign(query, {
        anyOfObjectGroupIds: data.anyOfObjectGroupIds.join(","),
      });
    }

    if (data.sort) {
      Object.assign(query, {
        sort: data.sort,
      });
    }

    if (data.status) {
      Object.assign(query, {
        status: data.status,
      });
    }

    if (data.actions_outside_camp_status) {
      Object.assign(query, {
        actions_outside_camp_status: data.actions_outside_camp_status,
      });
    }

    this.setState(
      {
        query: query,
        openFilterDialog: false,
        total: 0,
      },
      () => {
        this.props.resetCommentList();
        this.fetchCommentList();
        localStorage.setItem("query-explorer", JSON.stringify(query));
      }
    );

    window.history.pushState(
      null,
      null,
      `${window.location.origin}/explorer?${this.getQueryString(query)}`
    );
  }

  fetchCommentList(options = {}, callback = null) {
    const { query } = this.state;
    this.props.getCommentListByClient(query, options).then(() => {
      if (typeof callback === "function") {
        callback();
      }
    });
    this.getIndexList("0");
  }

  fetchClientLanguage = (client) => {
    if (client) {
      this.props.postAutoComplete(client, {
        field: "language",
      });
    }
  };

  fetchStreams() {
    this.setState(
      {
        loadingStreams: true,
      },
      () => {
        this.props.getStreamList();
      }
    );
  }

  pushToStream(streamId, callback = null) {
    this.setState(
      {
        loadingPushStream: true,
      },
      () => {
        const { query } = this.state;
        this.props
          .pushToStream(query, streamId)
          .then((result) => {
            if (typeof callback === "function") {
              callback(
                result.action.type === "PUSH_TO_STREAM_FULFILLED",
                result.action.payload.data.message
              );
            }
          })
          .catch((result) => {
            if (typeof callback === "function") {
              callback(false, result.action.payload.data.message);
            }
          })
          .finally(() => {
            this.setState({
              loadingPushStream: false,
            });
          });
      }
    );
  }

  logout() {
    this.props.signOut();
  }

  loadMore(callback) {
    this.fetchCommentList(
      {
        cursor: this.props.explorer.cursor,
      },
      callback
    );
  }

  showFilterDialog() {
    this.setState({
      openFilterDialog: true,
    });
  }

  closeFilterDialog() {
    this.setState({
      openFilterDialog: false,
    });
  }

  printCommentList() {
    window.print();
  }

  loadPrintList() {
    this.fetchCommentList({
      limit: 200,
      print: true,
    });
  }

  loadExportList() {
    this.props.fetchExportFields(this.state.query.client_short_name);
  }

  exportCommentList() {
    const { query } = this.state;

    let fields = Object.values(this.props.explorer.exportFields)
      .map((group) => {
        return group
          .filter((item) => item.checked === true)
          .map((item) => item.keyValue);
      })
      .filter((item) => item.length);

    fields = fields.length
      ? fields.reduce((a, b) => a.concat(b)).join(",")
      : "";

    this.setState({
      loadingFile: true,
    });

    this.props
      .generateExportFile(query, fields, this.props.explorer.exportLimit)
      .then((response) => {
        fileDownload(
          response.value.data,
          `export-comment-${query.client_short_name}.csv`
        );
        this.setState({
          loadingFile: false,
        });
      });
  }

  getExportData(group, key, value, options, callback = null) {
    this.props.changeExportData(group, key, value, options).then(() => {
      if (typeof callback === "function") {
        callback();
      }
    });
  }

  handleAnalyze(size, templateId, callback) {
    const { query } = this.state;
    const options = {
      size: size,
      templateId: templateId,
    };
    this.props
      .sendAnalyze(query, options)
      .then(() => {
        callback("success");
      })
      .catch((e) => {
        callback("error");
      });
  }

  setExplorerPage() {
    //if 0 param in the url
    if (
      Object.values(this.state.query).length === 0 &&
      localStorage.getItem("query-explorer")
    ) {
      window.history.pushState(
        null,
        null,
        `${window.location.origin}/explorer?${this.getQueryString(
          JSON.parse(localStorage.getItem("query-explorer"))
        )}`
      );
      this.setState(
        {
          query: JSON.parse(localStorage.getItem("query-explorer")),
        },
        () => {
          this.fetchCommentList();
        }
      );
    } else {
      this.fetchCommentList();
    }

    this.props.fetchUsers().then(() => {
      this.props.fetchClients().then(() => {
        if (
          Object.values(this.state.query).length === 0 &&
          !localStorage.getItem("query-explorer")
        ) {
          this.setState({
            openFilterDialog: true,
          });
        }
      });
    });
  }

  componentDidMount() {
    this.props.fetchMe().then((response) => {
      this.props.fetchAnalyzeTemplates().then(() => {
        this.setState({
          loading: false,
        });
        this.fetchClientLanguage(this.state.query.client_short_name);
      });
      if (response.value.data.permissions.includes("list_client_comments")) {
        this.setExplorerPage();
      } else {
        this.setState({
          notAllowed: true,
          loading: false,
        });
      }
    });
  }

  render() {
    const { classes } = this.props;
    const {
      list,
      total,
      printList,
      open,
      indexList,
      translate,
      displayBoth,
      loadingFile,
      statistics,
      status,
      mode,
      query,
      exportFields,
      openFilterDialog,
      clients,
      language,
      streamList,
      loadingStreams,
      loadingPushStream,
      isPushStreamMaxCommentsExceeded,
      streamRedirectId,
      notAllowed,
      userList,
      loading,
      me,
      analyzeTemplates,
    } = this.state;

    if (streamRedirectId) {
      return (
        <Redirect
          to={{
            pathname: "/moderation",
            search: `?stream=${streamRedirectId}`,
          }}
        />
      );
    }

    return (
      <div className={classes.root}>
        <AppBar
          action={this.handleDrawerOpen}
          status={open}
          selected={"explorer"}
          logout={this.logout.bind(this)}
        />
        <LeftMenu open={open} onClose={this.handleDrawerClose.bind(this)} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {!notAllowed && !loading ? (
            <Grid container spacing={2}>
              <Grid item xl={8} lg={7} sm={6} xs={7}>
                <Grid item xs={12}>
                  <CommentList
                    containerClassName={classes.heightList}
                    getIndexList={this.getIndexList.bind(this)}
                    translated={translate}
                    displayBoth={displayBoth}
                    list={list}
                    clickItemFromComment={this.clickItemFromComment.bind(this)}
                    loadMore={this.loadMore.bind(this)}
                    cursor={this.props.explorer.cursor}
                    actionsMenu={operationalTags}
                    mode={mode}
                    status={status}
                    onChangeSelect={this.onSelectComment.bind(this)}
                    updateTranslation={this.updateTranslation.bind(this)}
                  />
                </Grid>
                <Grid item>
                  <div className={classes.totalComments}>
                    <Chip
                      color="primary"
                      size="small"
                      icon={<DoneIcon />}
                      label={`Total comments: ${total ? total : "0"}`}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid
                className={classes.rightSide}
                item
                xl={4}
                lg={5}
                sm={6}
                xs={5}
              >
                <div className={classes.postRelatedContainer}>
                  <PostRelated
                    statistics={statistics}
                    clickItemFromPost={this.clickItemFromPost.bind(this)}
                    onChangeIndexMenu={this.onChangeIndexMenu.bind(this)}
                    post={
                      list && list[indexList] ? list[indexList]["post"] : null
                    }
                  />
                </div>
                <div className={classes.actionsContainer}>
                  <ActionsContent
                    me={me}
                    showFilterDialog={this.showFilterDialog.bind(this)}
                    loadPrintList={this.loadPrintList.bind(this)}
                    printCommentList={this.printCommentList.bind(this)}
                    printList={printList}
                    loadExportList={this.loadExportList.bind(this)}
                    exportCommentList={this.exportCommentList.bind(this)}
                    exportFields={exportFields}
                    loadingFile={loadingFile}
                    handleAnalyze={this.handleAnalyze.bind(this)}
                    getExportData={this.getExportData.bind(this)}
                    select={this.select.bind(this)}
                    edit={this.edit.bind(this)}
                    cancelEdit={this.cancelEdit.bind(this)}
                    list={list}
                    status={status}
                    analyzeTemplates={analyzeTemplates}
                    fetchStreams={this.fetchStreams.bind(this)}
                    loadingStreams={loadingStreams}
                    loadingPushStream={loadingPushStream}
                    streamList={streamList}
                    pushToStream={this.pushToStream.bind(this)}
                    isPushStreamMaxCommentsExceeded={
                      isPushStreamMaxCommentsExceeded
                    }
                    totalComments={total || 0}
                  />

                  <DialogContent
                    title={"Filters"}
                    open={openFilterDialog}
                    close={this.closeFilterDialog.bind(this)}
                  >
                    {clients && clients.length ? (
                      <ExplorerSearch
                        defaultValues={query}
                        getFilterData={this.getFilterData.bind(this)}
                        getLanguageData={this.fetchClientLanguage.bind(this)}
                        clients={clients}
                        languageList={language}
                        userList={userList}
                        hide={this.closeFilterDialog.bind(this)}
                      />
                    ) : (
                      <div></div>
                    )}
                  </DialogContent>
                </div>
              </Grid>
            </Grid>
          ) : !notAllowed ? (
            <CircularProgress className={classes.loading} />
          ) : (
            <Typography className={classes.notAllowed} variant="body1">
              Not Allowed
            </Typography>
          )}
        </main>
      </div>
    );
  }
}

ExplorerPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ExplorerPage));
