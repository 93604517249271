import { statisticsConstants } from '../constants/statisticsConstants';

const initialState = {
  fetching: false,
  fetched: false,
  statistics: {},
  error: null
};

const Statistics = (state = initialState, action) => {
  switch (action.type) {
    // GET POST STATISTICS
    case `${statisticsConstants.GET_POST_STATISTICS}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${statisticsConstants.GET_POST_STATISTICS}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${statisticsConstants.GET_POST_STATISTICS}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        statistics: action.payload.data
      };
    default:
      return state;
  }
};

export default Statistics;
