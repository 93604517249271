import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";

const styles = (theme) => ({
  root: {
    marginRight: 15,
  },
  inputWidth: {
    width: 220,
  },
  inputFullWidth: {
    width: "80%",
  },
  fullWidth: {
    width: "100%",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: "100%",
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  menuItemGroup: {
    borderRadius: 4,
    fontSize: 12,
    padding: 2,
  },
  menuItemRoot: {
    "&.Mui-focusVisible:not(.Mui-selected)": {
      backgroundColor: "inherit !important",
    },
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  icon: {
    width: 25,
    fontSize: 18,
  },
  colorIcon: {
    marginLeft: theme.spacing(3),
    width: 18,
    height: 18,
    borderRadius: 9,
  },
  transparent: {
    backgroundColor: "transparent !important",
  },
});
class SelectComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      defaultValue: props.defaultValue,
      selectedTransparent: props.selectedTransparent,
      items: props.items,
      display: props.display || 1,
      searchStr: "",
    };

    this.handleChange.bind(this);
  }

  handleChange(e, id) {
    this.props.onChange(e, id);
  }

  setSearchStr(target) {
    this.setState(
      {
        searchStr: target.value,
      },
      () => {
        target && target.focus();
      }
    );
  }

  setIconPlatform(name) {
    if (name === "facebook") {
      return `fab fa-${name}-f`;
    }
    return `fab fa-${name}`;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.setState({
        items: this.props.items,
      });
    }

    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  componentDidMount() {
    const { defaultValue } = this.state;
    const { id } = this.props;

    if (defaultValue) {
      this.setState({
        value: defaultValue,
      });
      this.handleChange(
        {
          target: {
            value: defaultValue,
          },
        },
        id
      );
    }
  }

  render() {
    const {
      classes,
      id,
      label,
      hiddenValues,
      multiple,
      attributName,
      attributId,
      attributColor,
      mandatory,
      error,
      attributeIcon,
      disabled,
      selectedTransparent,
      displayEmpty,
      displayNone,
      fullWidth,
      displaySearch,
    } = this.props;
    const { value, items, display, searchStr } = this.state;

    return (
      <div
        className={classNames(classes.root, {
          [classes.fullWidth]: fullWidth,
        })}
      >
        <FormControl
          className={classNames({
            [classes.fullWidth]: fullWidth,
          })}
          disabled={disabled ? true : false}
        >
          {label && !displayEmpty ? (
            <InputLabel htmlFor={id}>
              {label}
              {mandatory ? " *" : ""}
            </InputLabel>
          ) : (
            ""
          )}
          <Select
            className={classNames(classes.inputWidth, {
              [classes.inputFullWidth]: fullWidth,
            })}
            displayEmpty={displayEmpty ? true : false}
            multiple={multiple ? true : false}
            value={value ? value : typeof value === "string" ? "" : []}
            input={<Input name={label} id={id} />}
            renderValue={(selected) => {
              if (displayEmpty) {
                return label;
              }
              if (selected && items.length > 0) {
                return multiple && selected.length > display
                  ? `${
                      attributId
                        ? items.find((item) => item[attributId] === selected[0])
                          ? items.find(
                              (item) => item[attributId] === selected[0]
                            )[attributName]
                          : ""
                        : items.find((item) => item === selected[0])
                    } +${selected.length - 1}`
                  : multiple
                  ? attributId
                    ? items.find((item) => item[attributId] === selected[0])
                      ? items.find((item) => item[attributId] === selected[0])[
                          attributName
                        ]
                      : ""
                    : selected.join(", ")
                  : attributId
                  ? items.find((item) => item[attributId] === selected)[
                      attributName
                    ]
                  : items.find((item) => item === selected);
              }
              return label;
            }}
            onClose={(e) => {
              return this.setSearchStr("");
            }}
            onChange={(e) => {
              return this.handleChange(e, id);
            }}
          >
            {displaySearch && (
              <div className={classes.search} value={"search"}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    return this.setSearchStr(e.target);
                  }}
                />
              </div>
            )}
            {displayNone ? <MenuItem value="">None</MenuItem> : ""}
            {items.length > 0 &&
              items
                .filter((item) => {
                  if (searchStr && searchStr.length >= 2) {
                    if (item && typeof item === "object") {
                      if (
                        (item[attributName] &&
                          item[attributName]
                            .toLowerCase()
                            .includes(searchStr.toLowerCase())) ||
                        (item["group"] &&
                          item["group"]
                            .toLowerCase()
                            .includes(searchStr.toLowerCase())) ||
                        (item[attributName] &&
                          !multiple &&
                          value === item[attributName]) ||
                        (item[attributName] &&
                          multiple &&
                          value.includes(item[attributName]))
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    } else if (item) {
                      if (
                        item.toLowerCase().includes(searchStr.toLowerCase()) ||
                        (!multiple && value === item) ||
                        (multiple && value.includes(item))
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  }
                  return true;
                })
                .map((item, key) => {
                  if (
                    hiddenValues &&
                    hiddenValues.find((value) => {
                      return attributId
                        ? value === item[attributId]
                        : value === item;
                    })
                  ) {
                    return (
                      <span
                        key={key}
                        value={attributId ? item[attributId] : item}
                      />
                    );
                  }
                  return (
                    <MenuItem
                      key={key}
                      value={attributId ? item[attributId] : item}
                      classes={{
                        root: classes.menuItemRoot,
                        selected: selectedTransparent
                          ? classes.transparent
                          : null,
                      }}
                    >
                      {attributeIcon ? (
                        <Icon
                          className={classNames(
                            classes.icon,
                            attributeIcon
                              ? this.setIconPlatform(item[attributeIcon])
                              : {}
                          )}
                        />
                      ) : (
                        ""
                      )}
                      {multiple ? (
                        <Checkbox
                          checked={
                            value &&
                            value.indexOf(
                              attributId ? item[attributId] : item
                            ) > -1
                              ? true
                              : false
                          }
                        />
                      ) : (
                        ""
                      )}
                      <ListItemText
                        primary={attributName ? item[attributName] : item}
                      />
                      {attributColor ? (
                        <ListItemSecondaryAction>
                          <div
                            className={classes.colorIcon}
                            style={{ backgroundColor: item[attributColor] }}
                          />
                        </ListItemSecondaryAction>
                      ) : (
                        ""
                      )}
                      {item["group"] ? (
                        <div
                          className={classes.menuItemGroup}
                          style={{ backgroundColor: item["colorGroup"] }}
                        >
                          {item["group"]}
                        </div>
                      ) : (
                        ""
                      )}
                    </MenuItem>
                  );
                })}
          </Select>
          {error ? <FormHelperText error={true}>{error}</FormHelperText> : ""}
        </FormControl>
      </div>
    );
  }
}

SelectComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  attributName: PropTypes.string,
  attributId: PropTypes.string,
  attributColor: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  selectedColor: PropTypes.string,
  attributeIcon: PropTypes.string,
  multiple: PropTypes.bool,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  displaySearch: PropTypes.bool,
  mandatory: PropTypes.bool,
  displayNone: PropTypes.bool,
  error: PropTypes.string,
  selectedTransparent: PropTypes.bool,
  display: PropTypes.number,
  hiddenValues: PropTypes.array,
  onChange: PropTypes.func,
};

export default withStyles(styles)(SelectComponent);
