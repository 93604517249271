import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import {
  fetchGroupsTagList,
  updateGroupsTagColor
} from '../../../actions/settings/groupsTagAction';
import { fetchMe } from '../../../actions/meAction';

import GroupsTagSettings from './groupsTagSettings';

const styles = theme => ({
  root: {
    width: '100%'
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 367px)',
    marginTop: 'calc(50vh - 96px)'
  }
});

const mapStateToProps = state => ({
  me: state.meReducer
});

const mapDispatchToProps = dispatch => ({
  fetchMe: () => dispatch(fetchMe()),
  fetchGroupsTagList: () => dispatch(fetchGroupsTagList()),
  updateGroupsTagColor: (index, data) =>
    dispatch(updateGroupsTagColor(index, data))
});

class GroupsTagContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      notAllowed: false,
      editable: true,
      groupsTagList: []
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.me.groupsTagList !== prevState.groupsTagList) {
      update.groupsTagList = nextProps.me.groupsTagList;
    }

    return Object.keys(update).length ? update : null;
  }

  updateGroupsTagColor(index, data) {
    this.props
      .updateGroupsTagColor(index, data)
      .then(() => {})
      .catch(err => {
        this.props.fetchGroupsTagList();
      });
  }

  componentDidMount() {
    this.props.fetchMe().then(response => {
      this.setState({
        loading: false
      });
      this.props.fetchGroupsTagList();
    });
  }

  render() {
    const { classes } = this.props;
    const { loading, notAllowed, editable, groupsTagList } = this.state;

    return (
      <div className={classes.root}>
        {!notAllowed && !loading ? (
          <GroupsTagSettings
            editable={editable}
            updateGroupsTagColor={this.updateGroupsTagColor.bind(this)}
            groupsTagList={groupsTagList}
          />
        ) : !notAllowed ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <Typography className={classes.notAllowed} variant="body1">
            Not Allowed
          </Typography>
        )}
      </div>
    );
  }
}

GroupsTagContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(GroupsTagContainer));
