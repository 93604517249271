import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux
import {
  fetchGlobalTrendList,
  updateGlobalTrend,
  createGlobalTrend,
  deleteGlobalTrend
} from '../../../actions/configuration/trendsAction';
import { fetchClientList } from '../../../actions/configuration/clientsAction';

import TrendsConfiguration from './trendsConfiguration';

const mapStateToProps = state => ({
  trendState: state.trendReducer,
  clientState: state.clientsReducer
});

const mapDispatchToProps = dispatch => ({
  fetchGlobalTrendList: () => dispatch(fetchGlobalTrendList()),
  fetchClientList: () => dispatch(fetchClientList()),
  createGlobalTrend: data => dispatch(createGlobalTrend(data)),
  updateGlobalTrend: (index, data) => dispatch(updateGlobalTrend(index, data)),
  deleteGlobalTrend: (index, id) => dispatch(deleteGlobalTrend(index, id))
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 473px)',
    marginTop: 'calc(50vh - 96px)'
  }
}));

const TrendsContainer = props => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [isInitialized, setInitialized] = useState(false);
  const [isNotAllowed, setNotAllowed] = useState(false);
  const [isCreatable, setCreatable] = useState(false);
  const [isUpdatable, setUpdatable] = useState(false);
  const [isDeletable, setDeletable] = useState(false);
  const [trendList, setTrendList] = useState([]);
  const [clientList, setClientList] = useState([]);

  const createGlobalTrend = data => {
    props.createGlobalTrend(data);
  };

  const updateGlobalTrend = (index, data) => {
    props.updateGlobalTrend(index, data);
  };

  const deleteGlobalTrend = (index, id) => {
    props.deleteGlobalTrend(index, id);
  };

  useEffect(() => {
    if (props.permissions.includes('admin_list_global_trend')) {
      if (props.tabIndex === 8 && !isInitialized) {
        setInitialized(true);
        props.fetchClientList().then(() => {
          props.fetchGlobalTrendList().then(() => {
            setLoading(false);
          });
        });
      }

      if (props.permissions.includes('admin_create_global_trend')) {
        setCreatable(true);
      }
      if (props.permissions.includes('admin_update_global_trend')) {
        setUpdatable(true);
      }
      if (props.permissions.includes('admin_delete_global_trend')) {
        setDeletable(true);
      }
    } else {
      setNotAllowed(true);
    }
  }, [props.tabIndex]);

  useEffect(() => {
    if (!props.trendState.fetching) {
      setTrendList(props.trendState.trendList);
      setLoading(false);
    }
    if (!props.clientState.fetching) {
      setClientList(props.clientState.clientList);
    }
  }, [props.trendState.fetching, props.clientState.fetching]);

  return (
    <div className={classes.root}>
      {!isNotAllowed && !isLoading ? (
        <TrendsConfiguration
          trendList={trendList}
          clientList={clientList}
          isCreatable={isCreatable}
          isUpdatable={isUpdatable}
          isDeletable={isDeletable}
          createGlobalTrend={createGlobalTrend}
          updateGlobalTrend={updateGlobalTrend}
          deleteGlobalTrend={deleteGlobalTrend}
        />
      ) : !isNotAllowed ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <Typography className={classes.notAllowed} variant="body1">
          Not Allowed
        </Typography>
      )}
    </div>
  );
};

TrendsContainer.propTypes = {
  tabIndex: PropTypes.number.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(TrendsContainer);
