// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';

// Components
import BadgeList from '../badgeList/BadgeListComponent';

// Styles
const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  },
  badge: {
    height: 12,
    backgroundColor: theme.palette.primary.main,
    fontSize: 11
  },
  badgeSelected: {
    backgroundColor: theme.palette.success[500]
  },
  iconMain: {
    fontSize: 21
  },
  favourite: {
    color: theme.palette.colorStreamList,
    fontSize: 12,
    marginTop: theme.spacing(1),
    position: 'absolute',
    top: -1,
    left: theme.spacing(1)
  },
  favouriteSelected: {
    color: theme.palette.primary.main
  },
  deleteContainer: {
    position: 'absolute',
    left: 3,
    top: 3
  },
  isDeleteContainer: {
    position: 'absolute',
    left: 0,
    top: 3
  },
  deleteIcon: {
    fontSize: 16,
    cursor: 'pointer',
    color: theme.palette.colorStreamList
  },
  isDeleting: {
    color: theme.palette.colorStreamList
  },
  new: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white
  },
  failed: {
    backgroundColor: theme.palette.error[500],
    color: theme.palette.white
  },
  pending: {
    backgroundColor: theme.palette.warning[500],
    color: theme.palette.black
  },
  badgeListContainer: {
    minWidth: 50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

// Render Function
const IconComponent = props => {
  const [deleting, setDeleting] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    setDeleting(props.deleting);
  }, [props.deleting]);

  const {
    badgeContent,
    variant,
    invisible,
    favourite,
    deleteIcon,
    deleteAction,
    selected
  } = props;

  return (
    <div>
      {favourite ? (
        <Icon
          className={classNames(classes.favourite, {
            [classes.favouriteSelected]: selected
          })}
        >
          star
        </Icon>
      ) : (
        ''
      )}

      <div className={classes.deleteContainer}>
        {deleteIcon && !deleting ? (
          <div onClick={deleteAction} className={classes.deleteContainer}>
            <Icon className={classes.deleteIcon}>delete</Icon>
          </div>
        ) : (
          ''
        )}
        <div className={classes.isDeleteContainer}>
          {deleting ? (
            <CircularProgress className={classes.isDeleting} size={18} />
          ) : (
            ''
          )}
        </div>
      </div>

      {typeof badgeContent === 'number' ? (
        <Badge
          color="secondary"
          max={999999}
          badgeContent={badgeContent}
          invisible={invisible ? invisible : false}
          className={classes.margin}
          variant={variant ? variant : 'standard'}
          classes={{
            badge: classNames(classes.badge, {
              [classes.badgeSelected]: selected
            })
          }}
        >
          <div />
        </Badge>
      ) : (
        <div className={classes.badgeListContainer}>
          <BadgeList {...props} max={2} />
        </div>
      )}
    </div>
  );
};

IconComponent.propTypes = {
  badgeContent: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  variant: PropTypes.string,
  invisible: PropTypes.bool,
  favourite: PropTypes.bool,
  selected: PropTypes.bool,
  deleteIcon: PropTypes.bool,
  deleteAction: PropTypes.func,
  iconName: PropTypes.string.isRequired
};

export default IconComponent;
