import axios from "axios";

import { explorerConstants } from "../constants/explorerConstants";
import { setHeaders } from "../setters/auth";

const createQuery = (query, options) => {
  const params = [];

  if (query.start) params.push(`startTime=${query.start}`);
  if (query.end) params.push(`endTime=${query.end}`);
  if (query.user) params.push(`user=${query.user}`);
  if (query.asset_id) params.push(`asset_id=${query.asset_id}`);
  if (query.post_ids) params.push(`post_ids=${query.post_ids}`);
  if (query.highlighted_tags)
    params.push(`highlighted_tags=${query.highlighted_tags}`);
  if (query.comment_ids) params.push(`comment_ids=${query.comment_ids}`);
  if (query.platform_id) params.push(`platform_id=${query.platform_id}`);
  if (query.language) params.push(`language=${query.language}`);
  if (query.campaign_ids) params.push(`campaign_ids=${query.campaign_ids}`);
  if (query.author_name)
    params.push(`author_name=${encodeURIComponent(query.author_name)}`);
  if (query.regions) params.push(`regions=${query.regions}`);
  if (query.message)
    params.push(`message=${encodeURIComponent(query.message)}`);
  if (query.postType) params.push(`postType=${query.postType}`);
  if (query.noTags) params.push(`noTags=${query.noTags}`);
  if (query.tags) params.push(`tags=${query.tags}`);
  if (query.allOfTags) params.push(`allOfTags=${query.allOfTags}`);
  if (query.owned) params.push(`owned=${query.owned}`);
  if (query.anyOfTags) params.push(`anyOfTags=${query.anyOfTags}`);
  if (query.noneOfTags) params.push(`noneOfTags=${query.noneOfTags}`);
  if (query.anyOfObjectGroupIds)
    params.push(`anyOfObjectGroupIds=${query.anyOfObjectGroupIds}`);
  if (query.reply_status) params.push(`reply_statuses=${query.reply_status}`);
  if (query.comment_types) params.push(`comment_types=${query.comment_types}`);
  if (query.sort) params.push(`sort=created_time:desc`);
  if (query.status) params.push(`status=${query.status}`);
  if (query.actions_outside_camp_status)
    params.push(
      `actions_outside_camp_status=${query.actions_outside_camp_status}`
    );
  if (options.cursor) params.push(`cursor=${options.cursor}`);
  if (options.limit) params.push(`limit=${options.limit}`);
  if (options.size) params.push(`size=${options.size}`);
  if (options.exportFields) params.push(`fields=${options.exportFields}`);
  if (options.templateId) params.push(`analyze_template=${options.templateId}`);

  return params.join("&");
};

export const getCommentListByClient = (query, options = {}) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/clients/${query.client_short_name}/comments`;
  if (
    (options && options.cursor) ||
    (options && options.limit) ||
    (Object.values(query).length > 1 &&
      Object.keys(query).includes("client_short_name"))
  ) {
    url = url + `?`;
  }

  url = url + createQuery(query, options);

  const promise = axios.get(url, {
    headers: setHeaders(),
    options: options,
    highlighted_tags: query.highlighted_tags
      ? typeof query.highlighted_tags === "object"
        ? query.highlighted_tags
        : query.highlighted_tags.split(",")
      : [],
  });

  return dispatch({
    type: explorerConstants.GET_COMMENT_LIST_BY_CLIENT,
    payload: promise,
  });
};

export const pushToStream = (query, streamId) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/clients/${query.client_short_name}/stream-ingestion/${streamId}`;

  url = url + "?" + createQuery(query, {});

  const promise = axios.post(
    url,
    {},
    {
      headers: setHeaders(),
    }
  );

  return dispatch({
    type: explorerConstants.PUSH_TO_STREAM,
    payload: promise,
  });
};

export const postAutoComplete = (client_short_name, { query, field }) => (
  dispatch
) => {
  let url = `${process.env.URL_API_ENDPOINT}/autocomplete/${client_short_name}/searches`;

  const promise = axios.post(
    url,
    {
      query: query,
      field: field,
    },
    {
      headers: setHeaders(),
    }
  );

  return dispatch({
    type: explorerConstants.GET_AUTOCOMPLETE,
    payload: promise,
  });
};

export const sendAnalyze = (query, options = {}) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/analyze/${query.client_short_name}`;
  if (
    (options && options.cursor) ||
    (options && options.limit) ||
    (Object.values(query).length > 1 &&
      Object.keys(query).includes("client_short_name"))
  ) {
    url = url + `?`;
  }

  url = url + createQuery(query, options);

  const promise = axios.get(url, {
    headers: setHeaders(),
    options: options,
    highlighted_tags: query.highlighted_tags
      ? typeof query.highlighted_tags === "object"
        ? query.highlighted_tags
        : query.highlighted_tags.split(",")
      : [],
  });

  return dispatch({
    type: explorerConstants.EXPLORER_SEND_ANALYZE,
    payload: promise,
  });
};

export const resetCommentList = () => (dispatch) => {
  return dispatch({
    type: explorerConstants.RESET_COMMENT_LIST,
  });
};

export const fetchClients = () => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/clients`;

  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: explorerConstants.FETCH_CLIENTS,
    payload: promise,
  });
};

export const fetchHistoryFromComment = (
  clientShortName,
  commentId,
  options = {}
) => (dispatch) => {
  const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/comments/${commentId}/history`;
  const promise = axios.get(url, {
    headers: setHeaders(),
    commentId: commentId,
    index: options.index ? options.index : null,
  });

  return dispatch({
    type: explorerConstants.FETCH_HISTORY_FROM_COMMENT,
    payload: promise,
  });
};

export const updateTranslation = (clientShortName, commentId, options = {}) => (
  dispatch
) => {
  const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/comments/${commentId}/message-translation`;
  const promise = axios.patch(
    url,
    {},
    {
      headers: setHeaders(),
      index: options.index !== undefined ? options.index : null,
    }
  );

  return dispatch({
    type: explorerConstants.UPDATE_CLIENTS_COMMENTS_TRANSLATION,
    payload: promise,
  });
};

export const fetchExportFields = (clientShortName) => (dispatch) => {
  const url = `${process.env.URL_API_ENDPOINT}/export/client/${clientShortName}/fields`;
  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: explorerConstants.FETCH_EXPORT_FIELDS,
    payload: promise,
  });
};

export const changeExportData = (group, key, value, options) => (dispatch) => {
  const promise = new Promise((resolve, reject) => {
    resolve({
      group: group,
      key: key,
      value: value,
      options: options,
    }); // fulfilled
    reject("failure decrase notification count");
  });

  return dispatch({
    type: explorerConstants.ON_CHANGE_EXPORT_DATA,
    payload: promise,
  });
};

export const generateExportFile = (query, exportFields, exportLimit) => (
  dispatch
) => {
  let url = `${process.env.URL_API_ENDPOINT}/export/client/${query.client_short_name}/comments`;
  if (Object.values(query).length >= 1 && "client_short_name" in query) {
    url = url + `?`;
  }

  const params = [];

  url =
    url +
    createQuery(query, {
      limit: exportLimit,
      exportFields: exportFields,
    });

  url = url + params.join("&");

  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: explorerConstants.GENERATE_EXPORT_FILE,
    payload: promise,
  });
};

export const fetchAdsFromPost = (clientShortName, postId, options = {}) => (
  dispatch
) => {
  const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/posts/${postId}/ads`;
  const promise = axios.get(url, {
    headers: setHeaders(),
    index: options.index !== undefined ? options.index : null,
  });

  return dispatch({
    type: explorerConstants.FETCH_CLIENTS_POST_ADS_EXPLORER,
    payload: promise,
  });
};

export const fetchAnalyzeTemplates = () => (dispatch) => {
  const url = `${process.env.URL_API_ENDPOINT}/analyze/templates`;
  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: explorerConstants.FETCH_ANALYZE_TEMPLATES,
    payload: promise,
  });
};
