import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import ChannelWhiteListConfiguration from './channelWhitelistConfiguration';

// Redux
import {
  fetchChannelWhitelistList,
  addChannelWhitelist,
  deleteChannelWhitelist
} from '../../../actions/configuration/channelWhitelistAction';

const mapStateToProps = state => ({
  channelWhitelistState: state.channelWhitelistReducer
});

const mapDispatchToProps = dispatch => ({
  fetchChannelWhitelistList: clientShortName =>
    dispatch(fetchChannelWhitelistList(clientShortName)),
  addChannelWhitelist: (clientShortName, data) =>
    dispatch(addChannelWhitelist(clientShortName, data)),
  deleteChannelWhitelist: (clientShortName, index, channelId) =>
    dispatch(deleteChannelWhitelist(clientShortName, index, channelId))
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 367px)',
    marginTop: 'calc(50vh - 96px)'
  }
}));

const ChannelWhitelistContainer = props => {
  const classes = useStyles();
  const [isInitialized, setInitialized] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isNotAllowed, setNotAllowed] = useState(false);
  const [isCreatable, setCreatable] = useState(false);
  const [isDeletable, setDeletable] = useState(false);
  const [channelWhitelistList, setChannelWhitelistList] = useState([]);

  const addChannelWhitelist = data => {
    props.addChannelWhitelist(props.clientShortName, data);
  };

  const deleteChannelWhitelist = (index, channelId) => {
    props.deleteChannelWhitelist(props.clientShortName, index, channelId);
  };

  useEffect(() => {
    if (props.permissions.includes('copyright_admin_get_history')) {
      if (props.tabIndex === 11 && !isInitialized) {
        setLoading(true);
        setInitialized(true);
        props.fetchChannelWhitelistList(props.clientShortName, false);
        if (props.permissions.includes('copyright_admin_create_task')) {
          setCreatable(true);
        }
        if (props.permissions.includes('copyright_admin_task_update')) {
          setDeletable(true);
        }
      }
    } else {
      setNotAllowed(true);
    }
  }, [props.tabIndex]);

  useEffect(() => {
    if (!props.channelWhitelistState.fetching) {
      setChannelWhitelistList(props.channelWhitelistState.channelWhitelistList);
      setLoading(false);
    }
  }, [props.channelWhitelistState.fetching]);

  return (
    <div className={classes.root}>
      {!isNotAllowed && !isLoading ? (
        <ChannelWhiteListConfiguration
          channelWhitelistList={channelWhitelistList}
          isCreatable={isCreatable}
          isDeletable={isDeletable}
          addChannelWhitelist={addChannelWhitelist}
          deleteChannelWhitelist={deleteChannelWhitelist}
        />
      ) : !isNotAllowed ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <Typography className={classes.notAllowed} variant="body1">
          Not Allowed
        </Typography>
      )}
    </div>
  );
};

ChannelWhitelistContainer.propTypes = {
  clientShortName: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelWhitelistContainer);
