import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import TranslateIcon from "@material-ui/icons/GTranslate";

import SimpleMenu from "../commons/simpleMenu/simpleMenuComponent";
import TabsComponent from "../commons/tabs/tabsComponent";
import PostDetails from "../postDetails/PostdetailsComponent";
import PostStatistics from "../postStatistics/postStatisticsComponent";
import Attachment from "../commons/attachment/attachmentComponent";
import DialogContent from "../commons/dialog/contentDialogComponent";

import AdsContent from "../ads/adsComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    width: "100%",
    borderRadius: 5,
    boxShadow: theme.palette.shadow.shadowAround,
    backgroundColor: theme.palette.backgroundDark,
  },
  content: {
    width: "100%",
  },
  imageContainer: {},
  image: {
    width: "92px",
    height: "92px",
  },
  messsage: {
    color: theme.palette.common.black,
    marginTop: "8px",
    marginRight: "25px",
    textAlign: "left",
    fontSize: 12,
    overflow: "auto",
    maxHeight: "150px",
    wordBreak: "break-word",
    maxWidth: "460px",
  },
  viewCount: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: 5,
  },
  legend: {
    display: "flex",
    width: "100%",
    padding: theme.spacing(1, 1, 0, 1),
    height: 48,
  },
  title: {
    textAlign: "left",
    whiteSpace: "nowrap",
    maxWidth: 300,
    overflow: "hidden",
    fontWeight: "bold",
    textOverflow: "ellipsis",
    padding: theme.spacing(0, 1, 0, 1),
  },
  post: {
    display: "flex",
    padding: theme.spacing(0, 1, 1, 1),
  },
  postCenter: {
    justifyContent: "center",
  },
  avatar: {
    width: "40px",
    height: "40px",
    backgroundColor: theme.palette.white,
    marginBottom: "5px",
  },
  date: {
    color: theme.palette.common.black,
    marginLeft: "10px",
    fontSize: "9px",
    textAlign: "left",
  },
  name: {
    color: theme.palette.common.black,
    fontSize: "14px",
    lineHeight: "initial",
    marginLeft: "10px",
    textAlign: "left",
  },
  menu: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    right: 18,
    top: 18,
  },
  loading: {
    margin: "auto",
    marginTop: 100,
    marginBottom: 100,
    color: theme.palette.orange[300],
  },
  translateIcon: {
    cursor: "pointer",
    color: theme.palette.container.dark,
  },
  translated_hidden: {
    display: "none",
  },
  translated: {
    color: theme.palette.primary.main,
  },
}));

const PostRelated = ({
  clickItemFromPost,
  statistics,
  onChangeIndexMenu,
  post,
  isYoutubeVideo,
  viewCount,
  hideTopMenu,
  hideBottomMenu,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [translated, setTranslated] = useState(false);
  const [openAdsDialog, setOpenAdsDialog] = useState(false);

  useEffect(() => {
    return setLoading(post ? false : true);
  }, [post]);

  const clickItemFromComment = (string, data = {}, callback) => {
    if (string === "openAds") {
      setOpenAdsDialog(true);
      if (typeof clickItemFromPost === "function") {
        clickItemFromPost(string);
      }
    }
    callback();
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <CircularProgress className={classes.loading} />
      ) : post ? (
        <div className={classes.content}>
          <div className={classes.content}>
            {!hideTopMenu ? (
              <div className={classes.legend}>
                <Avatar
                  className={classes.avatar}
                  src={post.client ? post.client.logo : ""}
                />
                <div>
                  <Typography
                    className={classes.name}
                    align="center"
                    variant="subtitle2"
                  >
                    {post.client ? post.client.name : ""}
                  </Typography>
                  <Typography
                    className={classes.date}
                    align="center"
                    variant="subtitle1"
                  >
                    {post.date}
                  </Typography>
                </div>
                <div className={classes.menu}>
                  <div
                    className={classNames(classes.translateContainer, {
                      [classes.translated_hidden]:
                        !post.messageTranslated ||
                        !post.message.localeCompare(post.messageTranslated),
                    })}
                    onClick={(e) => {
                      setTranslated(!translated);
                    }}
                  >
                    <TranslateIcon
                      className={classNames(classes.translateIcon, {
                        [classes.translated]:
                          translated && post.messageTranslated,
                      })}
                    />
                  </div>
                  <SimpleMenu
                    clickItemFromComment={clickItemFromComment.bind(this)}
                    items={[
                      {
                        href: post.postUrl,
                        label: "Go to post",
                      },
                      {
                        href: `/explorer?client_short_name=${
                          post.client ? post.client.shortName : ""
                        }&post_ids=${post.objectId}`,
                        label: "Explore the post",
                      },
                      {
                        action: "openAds",
                        href: null,
                        label: "See ads",
                      },
                    ]}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {post.title ? <div className={classes.title}>{post.title}</div> : ""}
          <div className={classes.post}>
            <div className={classes.imageContainer}>
              {post.attachments && post.attachments.length > 0 ? (
                <Attachment
                  attachments={post.attachments}
                  postId={post.id}
                  isYoutubeVideo={isYoutubeVideo}
                  isSelected={false}
                  imageSize={400}
                  hideLegend={true}
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.messsage}>
              {translated ? post.messageTranslated : post.message}
            </div>
          </div>
          {!hideBottomMenu ? (
            <TabsComponent
              variant={"fullWidth"}
              small
              style={{
                background: theme.palette.primary[700],
                color: theme.palette.white,
              }}
              toggleSelectable={true}
              tabs={["Details", "Statistics"]}
              onChangeIndex={onChangeIndexMenu.bind(this)}
            >
              <PostDetails
                pageId={post.pageId}
                postId={post.objectId}
                pageName={post.pageName}
                postType={post.postType}
                targeting={post.targeting ? post.targeting : []}
                viewCount={isYoutubeVideo && viewCount ? viewCount : null}
              />
              <PostStatistics data={statistics} />
            </TabsComponent>
          ) : (
            ""
          )}
          <DialogContent
            title={"Ads"}
            open={openAdsDialog}
            close={() => setOpenAdsDialog(false)}
          >
            <AdsContent ads={post.ads} />
          </DialogContent>
        </div>
      ) : (
        <div className={classes.content}>
          <Typography
            className={classes.name}
            align="center"
            variant="subtitle2"
          >
            Post not found or unknown
          </Typography>
        </div>
      )}
    </div>
  );
};

PostRelated.propTypes = {
  onChangeIndexMenu: PropTypes.func,
  clickItemFromPost: PropTypes.func,
  statistics: PropTypes.object,
  post: PropTypes.object,
  viewCount: PropTypes.string,
  hideTopMenu: PropTypes.bool,
  hideBottomMenu: PropTypes.bool,
};

export default PostRelated;
