import { setComment } from '../setters/comment';

export const extractExplorerList = (data, highlighted_tags) => {
  const list = [];

  const guidelines = {
    tags: data.tags,
    highlighted_tags: highlighted_tags
  };

  data.items.map((comment, key) => {
    list.push(setComment(comment, guidelines, key));
    return true;
  });

  return {
    list: list
  };
};

export const extractExportFields = items => {
  let general = [];
  let fields = {};

  Object.entries(items).map((data, key) => {
    if (typeof data[1] === 'object') {
      fields = {
        ...fields,
        [data[0]]: Object.entries(data[1]).map((item, key) => {
          return {
            checked: false,
            key: key,
            keyValue: item[0],
            value: item[1]
          };
        })
      };
    } else {
      general.push({
        checked: false,
        key: key,
        keyValue: data[0],
        value: data[1]
      });
    }
    return true;
  });

  return (fields = {
    General: general,
    ...fields
  });
};
