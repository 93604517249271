import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SwatchesPicker } from 'react-color';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import TableComponent from '../../commons/table/tableComponent';
import Tooltip from '../../commons/tooltip/TooltipComponent';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  title: {
    marginBottom: 7
  },
  colorContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  colorSquare: {
    marginLeft: theme.spacing(1),
    width: 15,
    height: 15
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
    top: 175
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  }
});

class GroupsTagSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: props.editable,
      openPicker: false,
      columns: [
        {
          title: 'Group name',
          cellStyle: {
            padding: 2,
            paddingLeft: 14
          },
          field: 'group',
          editComponent: props => <p>{props.value}</p>
        },
        {
          title: 'Tags',
          cellStyle: {
            padding: 2,
            paddingLeft: 14
          },
          field: 'tags',
          editComponent: props => this.renderViewTags(props.value),
          render: rowData => this.renderViewTags(rowData.tags)
        },
        {
          title: 'Color',
          cellStyle: {
            padding: 2,
            paddingLeft: 14
          },
          field: 'color',
          render: rowData => this.renderViewColor(rowData),
          editComponent: props => this.renderEditColor(props)
        }
      ],
      data: props.groupsTagList
    };
  }

  updateGroupsTagColor(index, newData) {
    this.props.updateGroupsTagColor(index, newData);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.groupsTagList !== this.props.groupsTagList) {
      this.setState({
        data: this.props.groupsTagList
      });
    }

    if (prevProps.editable !== this.props.editable) {
      this.setState({
        editable: this.props.editable
      });
    }
  }

  openPickColor() {
    this.setState({
      openPicker: true
    });
  }

  closePickColor() {
    this.setState({
      openPicker: false
    });
  }

  handleChangeColor(e, props) {
    this.closePickColor();
    props.onChange(e.hex);
  }

  generateTitle(tags) {
    const list = [];
    tags.map(tag => {
      list.push(tag.label);
      return true;
    });

    return <p>{list.join(',')}</p>;
  }

  renderViewTags(tags) {
    return (
      <Tooltip title={this.generateTitle(tags)}>
        <div style={{ cursor: 'pointer' }}>{tags.length}</div>
      </Tooltip>
    );
  }

  renderColorPicker(props) {
    const { classes } = this.props;
    return (
      <div className={classes.popover}>
        <div className={classes.cover} onClick={() => this.closePickColor()} />
        <SwatchesPicker
          onChangeComplete={e => this.handleChangeColor(e, props)}
        />
      </div>
    );
  }

  renderEditColor(props) {
    const { classes } = this.props;
    return (
      <div className={classes.colorContainer}>
        <TextField
          autoComplete="off"
          label={'Color'}
          value={props.value ? props.value : '#e0e0e0'}
          margin={'dense'}
          onChange={e => {
            return props.onChange(e.target.value);
          }}
        />
        <div
          onClick={() => this.openPickColor()}
          className={classes.colorSquare}
          style={{
            background: props.value ? props.value : '#e0e0e0',
            cursor: 'pointer'
          }}
        />
        {this.state.openPicker ? this.renderColorPicker(props) : ''}
      </div>
    );
  }

  renderViewColor(rowData) {
    const { classes } = this.props;
    return (
      <div className={classes.colorContainer}>
        <div>{rowData.color ? rowData.color : '#e0e0e0'}</div>
        <div
          className={classes.colorSquare}
          style={{ background: rowData.color ? rowData.color : '#e0e0e0' }}
        />
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { columns, data, editable } = this.state;

    return (
      <div className={classes.root}>
        <Typography className={classes.title} variant="h5">
          Groups Tag List
        </Typography>
        <TableComponent
          editable={editable}
          columns={columns}
          data={data}
          title=""
          update={this.updateGroupsTagColor.bind(this)}
        />
      </div>
    );
  }
}

GroupsTagSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  groupsTagList: PropTypes.array.isRequired,
  updateGroupsTagColor: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(GroupsTagSettings);
