import axios from 'axios';
import { setHeaders } from '../setters/auth';

export const fetchClient = clientShortName => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/clients/${clientShortName}`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return promise;
};
