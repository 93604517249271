import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import { setDateFormat } from '../../../setters/date';

import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(theme => ({
  tag: {
    margin: 5,
    marginTop: 0,
    height: 20,
    fontSize: 10
  },
  title: {},
  username: {
    marginLeft: theme.spacing(1),
    fontSize: 13,
    display: 'flex',
    alignItems: 'center'
  },
  stream: {
    marginLeft: theme.spacing(1),
    fontSize: 13,
    display: 'flex',
    alignItems: 'center'
  },
  date: {
    marginLeft: theme.spacing(1),
    fontSize: 13,
    marginBottom: 5,
    display: 'flex',
    alignItems: 'center'
  },
  alerts: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1)
  },
  iconAlert: {
    width: 30,
    marginLeft: 9,
    marginRight: 0,
    fontSize: 20
  }
}));

const HistoryComment = ({ type, step }) => {
  const classes = useStyles();

  return (
    <Step active={true}>
      <StepLabel className={classes.title}>{'Moderation Event'}</StepLabel>
      <StepContent>
        <Typography className={classes.stream}>
          <Icon className={classNames(classes.icon, 'fas fa-wave-square')} />
          {step.stream_id ? step.stream_id : 'stream unknown'}
        </Typography>
        <Typography className={classes.username}>
          <Icon className={classes.icon}>account_circle</Icon>
          {step.user_name}
        </Typography>
        <Typography className={classes.date}>
          <Icon className={classes.icon}>calendar_today</Icon>
          {`started: ${setDateFormat(step.started_timestamp_pretty)}`}
        </Typography>
        <Typography className={classes.date}>
          <Icon className={classes.icon}>calendar_today</Icon>
          {`ended: ${setDateFormat(step.finished_timestamp_pretty)}`}
        </Typography>
        <div>
          {step.tags
            ? step.tags.map((tag, key) => {
                return (
                  <Chip
                    className={classes.tag}
                    key={key}
                    label={tag}
                    component="div"
                  />
                );
              })
            : ''}
        </div>
        {step.actions.length ? (
          <div className={classes.alerts}>
            <Icon
              className={classNames(
                classes.icon,
                classes.iconAlert,
                'fa fa-exclamation-triangle'
              )}
            />
            {step.action_types.map((action, key) => {
              return (
                <span key={key}>
                  <span>{action}</span>
                  {step.action_types.length - 1 !== key ? (
                    <span>{' / '}</span>
                  ) : (
                    ''
                  )}
                </span>
              );
            })}
          </div>
        ) : (
          ''
        )}
      </StepContent>
    </Step>
  );
};

HistoryComment.propTypes = {
  step: PropTypes.object
};

export default HistoryComment;
