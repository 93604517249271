import { setComment } from '../setters/comment';

export const extractReplyList = data => {
  const list = [];

  const guidelines = {
    tags: data.tags
  };

  if (data && data.items && data.items.length) {
    data.items.map((comment, key) => {
      list.push(setComment(comment, guidelines, key));
      return true;
    });
  }

  return {
    list: list
  };
};
