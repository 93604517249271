import { streamsConstants } from '../../constants/configuration/streamsConstants';

import { extractStreamsConfiguration } from '../../extractors/streamExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  streamList: [],
  error: null
};

const Actions = (state = initialState, action) => {
  switch (action.type) {
    // FETCH STREAM LIST
    case `${streamsConstants.FETCH_STREAM_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${streamsConstants.FETCH_STREAM_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${streamsConstants.FETCH_STREAM_LIST}_FULFILLED`:
      let streams = extractStreamsConfiguration(action.payload.data);
      return {
        ...state,
        fetching: false,
        fetched: true,
        streamList: streams
      };
    // ADD STREAM
    case `${streamsConstants.ADD_STREAM}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${streamsConstants.ADD_STREAM}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${streamsConstants.ADD_STREAM}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        streamList: [
          ...state.streamList,
          {
            id: action.payload.config.streamInfo.id,
            label: action.payload.config.streamInfo.label,
            tags: action.payload.config.streamInfo.tags,
            filters: action.payload.config.streamInfo.filters,
            exclusion_filters:
              action.payload.config.streamInfo.exclusion_filters,
            users: action.payload.config.streamInfo.users,
            roles: action.payload.config.streamInfo.roles
          }
        ]
      };
    // EDIT STREAM
    case `${streamsConstants.EDIT_STREAM}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${streamsConstants.EDIT_STREAM}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${streamsConstants.EDIT_STREAM}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        streamList: Object.assign([], {
          ...state.streamList,
          [action.payload.config.index]: {
            id: action.payload.config.streamInfo.id,
            label: action.payload.config.streamInfo.label,
            tags: action.payload.config.streamInfo.tags,
            filters: action.payload.config.streamInfo.filters,
            exclusion_filters:
              action.payload.config.streamInfo.exclusion_filters,
            users: action.payload.config.streamInfo.users,
            roles: action.payload.config.streamInfo.roles
          }
        })
      };
    default:
      return state;
  }
};

export default Actions;
