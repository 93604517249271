import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import AlertDialog from '../commons/dialog/alertDialogComponent';
import { convertTimeToSecond, convertSecondToTime } from '../../utils/utils';

import TimePicker from '../commons/picker/timePickerComponent';

import {
  infringementItems,
  default_categories,
  default_locations,
  categories_label,
  locations_label
} from '../../data/data';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(1)
  },
  formControl: {},
  card: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around'
  },
  noMarging: {
    padding: 0,
    margin: 0
  },
  containerTime: {
    display: 'flex',
    marginLeft: theme.spacing(-1)
  },
  label: {
    fontSize: 12
  },
  containerActions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%'
  },
  proccessButton: {
    color: theme.palette.white,
    background: theme.palette.success[500],
    '&:hover, &:focus, &:active': {
      color: theme.palette.white,
      background: theme.palette.success[600]
    }
  },
  removeButton: {
    color: theme.palette.white,
    background: theme.palette.secondary[500],
    '&:hover, &:focus, &:active': {
      color: theme.palette.white,
      background: theme.palette.secondary[600]
    }
  },
  hideBlock: {
    visibility: 'hidden'
  }
}));

///////////

const CopyrightArea = ({ action, data, me, onFocus, onBlur }) => {
  //set Locations
  const dataLocations = data.locations
    ? {
        description: data.locations.description,
        entire_video: data.locations.entire_video,
        thumbnail: data.locations.thumbnail,
        timestamp:
          data.locations.timestamp &&
          (data.locations.timestamp.start !== 0 ||
            data.locations.timestamp.end !== 0)
            ? true
            : false
      }
    : default_locations;

  //set Time stamp
  const dataTimeStamp =
    data.locations && data.locations.timestamp
      ? {
          start: convertSecondToTime(data.locations.timestamp.start),
          end: convertSecondToTime(data.locations.timestamp.end)
        }
      : {
          start: '00:00:00',
          end: '00:00:00'
        };

  //set categories
  const includesCategory = category => {
    return data.categories && data.categories.includes(category) ? true : false;
  };

  const dataCategories = data.categories
    ? {
        cash_for_app: includesCategory('cash_for_app'),
        unsure: includesCategory('unsure'),
        unrelated: includesCategory('unrelated'),
        false_positive: includesCategory('false_positive'),
        free_sell_account: includesCategory('free_sell_account')
      }
    : default_categories;

  //set local variables
  const classes = useStyles();
  const [locations, setlocations] = useState(dataLocations);
  const [startTime, setStartTime] = useState(dataTimeStamp.start);
  const [endTime, setEndTime] = useState(dataTimeStamp.end);
  const [categories, setCategories] = useState(dataCategories);
  const [openDialogRemoveVideo, setOpenDialogRemoveVideo] = useState(false);
  const [infringement, setInfringement] = useState(
    data.infringement ? data.infringement : ''
  );

  useEffect(() => {
    setlocations(dataLocations);
    setStartTime(dataTimeStamp.start);
    setEndTime(dataTimeStamp.end);
    setCategories(dataCategories);
    setInfringement(data.infringement ? data.infringement : '');
  }, [data]);

  const isFalse = value => value === false;

  const actionDialogRemoveVideo = status => {
    if (status) {
      action('remove');
    }

    setOpenDialogRemoveVideo(false);
  };

  const actionReport = status => {
    const cat = [];

    Object.entries(categories).map(item => {
      if (item[1]) {
        cat.push(item[0]);
      }
      return true;
    });

    const data = {
      infringement: infringement,
      categories: cat,
      locations: {
        description: locations.description,
        entire_video: locations.entire_video,
        thumbnail: locations.thumbnail,
        timestamp: {
          start: convertTimeToSecond(startTime),
          end: convertTimeToSecond(endTime)
        }
      }
    };

    return action(status, data);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <FormControl
          size={'small'}
          margin={'dense'}
          className={classes.formControl}
        >
          <FormLabel component="legend">Infringement</FormLabel>
          <RadioGroup
            aria-label="infringement"
            name="infringement"
            value={infringement}
            onChange={e => setInfringement(e.target.value)}
          >
            {infringementItems.map((item, key) => {
              return (
                <FormControlLabel
                  key={key}
                  value={item.value}
                  control={
                    <Radio
                      disabled={
                        data.status === 'locked' || data.status === 'approved'
                      }
                      classes={{ root: classes.noMarging }}
                      size="small"
                      onClick={e => {
                        if (infringement === e.target.value) {
                          setlocations(default_locations);
                          setStartTime('00:00:00');
                          setEndTime('00:00:00');
                        }
                        setInfringement(
                          infringement === e.target.value ? '' : e.target.value
                        );
                      }}
                    />
                  }
                  label={
                    <Typography className={classes.label}>
                      {item.label}
                    </Typography>
                  }
                />
              );
            })}
          </RadioGroup>
        </FormControl>
        <FormControl
          size={'small'}
          margin={'dense'}
          className={classNames(classes.formControl, {
            [classes.hideBlock]: !infringement
          })}
        >
          <FormLabel component="legend">locations*</FormLabel>
          <FormGroup>
            {Object.keys(locations).map((item, key) => {
              return (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      disabled={
                        data.status === 'locked' || data.status === 'approved'
                      }
                      checked={locations[item] === true ? true : false}
                      classes={{ root: classes.noMarging }}
                      size="small"
                      onChange={() => {
                        if (item === 'timestamp' && locations[item]) {
                          setStartTime('00:00:00');
                          setEndTime('00:00:00');
                        }
                        setlocations({
                          ...locations,
                          [item]: !locations[item]
                        });
                      }}
                    />
                  }
                  label={
                    <Typography className={classes.label}>
                      {locations_label[key]}
                    </Typography>
                  }
                />
              );
            })}
            <div
              className={classNames(classes.containerTime, {
                [classes.hideBlock]: !locations['timestamp']
              })}
            >
              <TimePicker
                label="Start"
                value={startTime}
                disabled={
                  data.status === 'locked' || data.status === 'approved'
                }
                onFocus={() => onFocus(true)}
                onBlur={() => onBlur(false)}
                onChangeValue={value => setStartTime(value)}
              />
              <TimePicker
                label="End"
                disabled={
                  data.status === 'locked' || data.status === 'approved'
                }
                value={endTime}
                onFocus={() => onFocus(true)}
                onBlur={() => onBlur(false)}
                onChangeValue={value => setEndTime(value)}
              />
            </div>
          </FormGroup>
        </FormControl>
        <FormControl
          size={'small'}
          margin={'dense'}
          className={classes.formControl}
        >
          <FormLabel component="legend">Categories</FormLabel>
          <FormGroup>
            {Object.keys(categories).map((item, key) => {
              return (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      disabled={
                        data.status === 'locked' || data.status === 'approved'
                      }
                      checked={categories[item] === true ? true : false}
                      classes={{ root: classes.noMarging }}
                      size="small"
                      onChange={() =>
                        setCategories({
                          ...categories,
                          [item]: !categories[item]
                        })
                      }
                    />
                  }
                  label={
                    <Typography className={classes.label}>
                      {categories_label[key]}
                    </Typography>
                  }
                />
              );
            })}
          </FormGroup>
        </FormControl>

        {data.status !== 'locked' ? (
          <FormControl
            size={'small'}
            margin={'dense'}
            className={classes.formControl}
          >
            <FormLabel component="legend">Process Actions</FormLabel>
            <div className={classes.containerActions}>
              {(data.status === 'new' || data.status === 'removed') &&
              me &&
              me.permissions &&
              me.permissions.includes('process_copyright_comment') ? (
                <Button
                  onClick={() => actionReport('process')}
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={
                    (Object.values(locations).every(isFalse) && infringement) ||
                    (!Object.values(categories).every(isFalse) &&
                      infringement &&
                      Object.values(locations).every(isFalse))
                      ? true
                      : false
                  }
                >
                  {data.status === 'removed' ? 'Re-' : ''}
                  {infringement ? 'Report' : 'Process'}
                </Button>
              ) : (
                ''
              )}
              {data.status === 'pending' &&
              me &&
              me.permissions &&
              me.permissions.includes('approve_copyright_comment') ? (
                <Button
                  onClick={() => actionReport('approve')}
                  variant="contained"
                  className={classes.proccessButton}
                  size="small"
                >
                  {'Approve'}
                </Button>
              ) : (
                ''
              )}
              {data.status !== 'removed' &&
              data.status !== 'locked' &&
              me &&
              me.permissions &&
              me.permissions.includes('remove_copyright_comment') ? (
                <Button
                  onClick={() => setOpenDialogRemoveVideo(true)}
                  variant="contained"
                  className={classes.removeButton}
                  size="small"
                >
                  {'Remove'}
                </Button>
              ) : (
                ''
              )}
              <AlertDialog
                title="Remove this video ?"
                message="Remove this video from Copyright."
                buttonCancelText="No"
                buttonAcceptText="Yes"
                open={openDialogRemoveVideo}
                action={status => actionDialogRemoveVideo(status)}
              />
            </div>
          </FormControl>
        ) : (
          ''
        )}
      </Card>
    </div>
  );
};

export default CopyrightArea;
