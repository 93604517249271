import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class ImageDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  componentDidMout() {}

  render() {
    const { data, open, imageSize } = this.props;
    return (
      <div>
        {data && data.media && data.media.image ? (
          <Dialog
            open={open}
            onClose={this.props.close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {data ? data.title : ''}
            </DialogTitle>
            <DialogContent>
              {data.type !== 'animated_image_share' ||
              (data.type === 'animated_image_share' &&
                data.media &&
                !data.media.source) ? (
                <img
                  src={data ? data.media.image.src : ''}
                  width={imageSize || 0}
                  height={imageSize || 0}
                  alt={data ? data.title : ''}
                />
              ) : (
                <video
                  controls
                  width={imageSize || 0}
                  height={imageSize || 0}
                  loop
                  muted
                  autoPlay
                  name="media"
                >
                  <source
                    src={data.media ? data.media.source : ''}
                    type="video/mp4"
                  />
                </video>
              )}
            </DialogContent>
          </Dialog>
        ) : (
          ''
        )}
      </div>
    );
  }
}

ImageDialog.propTypes = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  imageSize: PropTypes.number.isRequired
};

export default ImageDialog;
