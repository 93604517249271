import { configurationConstants } from '../../constants/configuration/configurationConstants';

import { extractKeyFromRoles } from '../../extractors/configurationExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  roleList: [],
  permissionGroups: [],
  error: null
};

const rolesAndPermissions = (state = initialState, action) => {
  switch (action.type) {
    // FETCH ROLES AND PERMISSION LIST
    case `${configurationConstants.FETCH_ROLES_AND_PERMISSION_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${configurationConstants.FETCH_ROLES_AND_PERMISSION_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${configurationConstants.FETCH_ROLES_AND_PERMISSION_LIST}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        roleList: action.payload.data
      };
    // FETCH PERMISSION GROUPS
    case `${configurationConstants.FETCH_PERMISSION_GROUPS}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${configurationConstants.FETCH_PERMISSION_GROUPS}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${configurationConstants.FETCH_PERMISSION_GROUPS}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        permissionGroups: action.payload.data
      };
    // ON CHANGE PERMISSION
    case `${configurationConstants.ON_CHANGE_PERMISSION}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${configurationConstants.ON_CHANGE_PERMISSION}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${configurationConstants.ON_CHANGE_PERMISSION}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        permissionGroups: Object.assign([], {
          ...state.permissionGroups,
          [action.payload.keyGroup]: {
            ...state.permissionGroups[action.payload.keyGroup],
            permissions: Object.assign([], {
              ...state.permissionGroups[action.payload.keyGroup]['permissions'],
              [action.payload.keyPermission]: {
                ...state.permissionGroups[action.payload.keyGroup][
                  'permissions'
                ][action.payload.keyPermission],
                checked: action.payload.value
              }
            })
          }
        })
      };
    // FETCH ROLE PERMISSION
    case `${configurationConstants.FETCH_ROLE_PERMISSIONS}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${configurationConstants.FETCH_ROLE_PERMISSIONS}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${configurationConstants.FETCH_ROLE_PERMISSIONS}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        permissionGroups: action.payload.data.permission_groups
      };

    // CREATE ROLE
    case `${configurationConstants.CREATE_ROLE}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${configurationConstants.CREATE_ROLE}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${configurationConstants.CREATE_ROLE}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        roleList: [...state.roleList, action.payload.data]
      };

    // EDIT ROLE
    case `${configurationConstants.EDIT_ROLE}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${configurationConstants.EDIT_ROLE}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${configurationConstants.EDIT_ROLE}_FULFILLED`:
      let roleKey = extractKeyFromRoles(
        state.roleList,
        action.payload.config.roleId
      );
      return {
        ...state,
        fetching: false,
        fetched: true,
        roleList: Object.assign([], {
          ...state.roleList,
          [roleKey]: action.payload.data
        })
      };

    // DELETE ROLE
    case `${configurationConstants.DELETE_ROLE}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${configurationConstants.DELETE_ROLE}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${configurationConstants.DELETE_ROLE}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        roleList: state.roleList.filter(role => {
          return role.id !== action.payload.config.roleId;
        })
      };

    default:
      return state;
  }
};

export default rolesAndPermissions;
