import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import ClassifiersConfiguration from './classifiersConfiguration';

import {
  fetchClassifiersList,
  addClassifier,
  editClassifier
} from '../../../actions/configuration/classifiersAction';

const styles = theme => ({
  root: {
    width: '100%'
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 367px)',
    marginTop: 'calc(50vh - 96px)'
  }
});

const mapStateToProps = state => ({
  classifiers: state.classifiersReducer,
  tags: state.tagsReducer
});

const mapDispatchToProps = dispatch => ({
  fetchClassifiersList: () => dispatch(fetchClassifiersList()),
  addClassifier: data => dispatch(addClassifier(data)),
  editClassifier: (index, data) => dispatch(editClassifier(index, data))
});

class ClassifiersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
      loading: true,
      notAllowed: false,
      editable: false,
      creatable: false,
      classifierList: [],
      tagList: []
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.classifiers.classifierList !== prevState.classifierList) {
      update.classifierList = nextProps.classifiers.classifierList;
    }

    if (nextProps.tags.tagList !== prevState.tagList) {
      update.tagList = nextProps.tags.tagList;
    }

    return Object.keys(update).length ? update : null;
  }

  addClassifier(data) {
    this.props.addClassifier(data);
  }

  editClassifier(index, data) {
    this.props.editClassifier(index, data);
  }

  initialize() {
    if (this.props.permissions.includes('admin_list_classifiers')) {
      this.props.fetchClassifiersList().then(() => {
        this.setState({
          loading: false
        });
      });
      if (this.props.permissions.includes('admin_update_classifier')) {
        this.setState({
          editable: true
        });
      }
      if (this.props.permissions.includes('admin_create_classifier')) {
        this.setState({
          creatable: true
        });
      }
    } else {
      this.setState({
        notAllowed: true,
        loading: false
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tabIndex === 3 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true
        },
        this.initialize
      );
    }
  }

  componentDidMount() {
    if (this.props.tabIndex === 3 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true
        },
        this.initialize
      );
    }
  }

  render() {
    const { classes } = this.props;
    const {
      loading,
      notAllowed,
      classifierList,
      tagList,
      creatable,
      editable
    } = this.state;

    return (
      <div className={classes.root}>
        {!notAllowed && !loading ? (
          <ClassifiersConfiguration
            addClassifier={this.addClassifier.bind(this)}
            editClassifier={this.editClassifier.bind(this)}
            classifierList={classifierList}
            creatable={creatable}
            tagList={tagList}
            editable={editable}
          />
        ) : !notAllowed ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <Typography className={classes.notAllowed} variant="body1">
            Not Allowed
          </Typography>
        )}
      </div>
    );
  }
}

ClassifiersContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  tabIndex: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ClassifiersContainer));
