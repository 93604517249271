import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '../../components/commons/appBar/AppBarComponent';
import LeftMenu from '../../components/commons/leftMenu/leftMenuComponent';
import TabsComponent from '../../components/commons/tabs/tabsComponent';

import ProfileContainer from '../../components/settings/profile/profileContainer';
import GroupsTagContainer from '../../components/settings/groupsTag/groupsTagContainer';
import SavedDataContainer from '../../components/settings/savedData/savedDataContainer';

const styles = theme => ({
  root: {
    display: 'flex'
  },
  content: {
    width: '100%',
    paddingTop: theme.spacing(5)
  }
});

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      hasDefaultValue: 0,
      open: false
    };
  }

  logout() {
    this.props.signOut();
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { open, hasDefaultValue } = this.state;

    return (
      <div className={classes.root}>
        <AppBar
          action={this.handleDrawerOpen}
          status={open}
          selected={'settings'}
          logout={this.logout.bind(this)}
        />
        <LeftMenu open={open} onClose={this.handleDrawerClose.bind(this)} />
        <main className={classes.content}>
          <TabsComponent
            hasDefaultValue={hasDefaultValue}
            tabs={['User Profile', 'Groups Tag', 'Saved Data']}
          >
            <ProfileContainer />
            <GroupsTagContainer />
            <SavedDataContainer />
          </TabsComponent>
        </main>
      </div>
    );
  }
}

SettingsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(SettingsPage);
