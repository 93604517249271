// Packages
import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

// Material
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

// Styles
const ITEM_HEIGHT = 60;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.black,
  },
  iconButton: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  iconButtonWhite: {
    color: theme.palette.white,
  },
  iconButtonWarning: {
    color: theme.palette.warning[500],
  },
  itemContainer: {
    width: "100%",
  },
}));

// Component
const SimpleMenu = ({
  items,
  prefix,
  iconWhite,
  iconWarning,
  clickItemFromComment,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataLabels, setDataLabels] = useState({});
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (action, data, component) => {
    clickItemFromComment(action, data, component, () => {
      if (data && data.label) {
        let changeDataLabel = {
          ...dataLabels,
          [data.label]: !dataLabels[data.label],
        };
        setDataLabels(changeDataLabel);
      }
    });
    handleClose();
  };

  const createLink = (item) => {
    return (
      <a
        rel="noopener noreferrer"
        target="_blank"
        className={classes.link}
        href={item.href}
      >
        {item.label}
      </a>
    );
  };

  const createButton = (item) => {
    return (
      <div
        className={classes.itemContainer}
        onClick={(e) => {
          e.stopPropagation();
          return handleClickItem(item.action, item.data, item.modalComponent);
        }}
      >
        {`${
          item.data && dataLabels[item.data.label] && item.data.prefix
            ? prefix
            : ""
        } ${item.label}`}
      </div>
    );
  };

  return (
    <div>
      <IconButton
        className={classNames(classes.iconButton, {
          [classes.iconButtonWhite]: iconWhite,
          [classes.iconButtonWarning]: iconWarning,
        })}
        aria-label="More"
        aria-owns={open ? "long-menu" : undefined}
        aria-haspopup="true"
        onClick={(e) => handleClick(e)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 5.5,
            width: 200,
          },
        }}
      >
        {items && items.length
          ? items.map((item, key) => (
              <MenuItem key={key}>
                {item.href !== null ? createLink(item) : createButton(item)}
              </MenuItem>
            ))
          : ""}
      </Menu>
    </div>
  );
};

SimpleMenu.propTypes = {
  iconWhite: PropTypes.bool,
  iconWarning: PropTypes.bool,
  items: PropTypes.array,
  prefix: PropTypes.string,
  clickItemFromComment: PropTypes.func,
};

export default SimpleMenu;
