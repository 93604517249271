import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import RepliesConfiguration from './repliesConfiguration';

import {
  fetchReplyConfig,
  saveReplyConfig,
  resetReplyConfig,
  fetchRepliesAccess,
  putRepliesAccess
} from '../../../actions/configuration/repliesAction';

import { fetchRolesAndPermissionList } from '../../../actions/configuration/rolesAndPermissionsAction';

import { fetchUserList } from '../../../actions/configuration/usersConfigurationAction';

const styles = theme => ({
  root: {
    width: '100%'
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 367px)',
    marginTop: 'calc(50vh - 96px)'
  }
});

const mapStateToProps = state => ({
  user: state.usersReducer,
  replies: state.repliesConfigurationReducer,
  rolesAndPermissionsReducer: state.rolesAndPermissionsReducer,
  users: state.usersConfigurationReducer
});

const mapDispatchToProps = dispatch => ({
  fetchUserList: () => dispatch(fetchUserList()),
  fetchRolesAndPermissionList: () => dispatch(fetchRolesAndPermissionList()),
  fetchRepliesAccess: clientShortName =>
    dispatch(fetchRepliesAccess(clientShortName)),
  putRepliesAccess: (clientShortName, data) =>
    dispatch(putRepliesAccess(clientShortName, data)),
  fetchReplyConfig: clientShortName =>
    dispatch(fetchReplyConfig(clientShortName)),
  saveReplyConfig: (clientShortName, data) =>
    dispatch(saveReplyConfig(clientShortName, data)),
  resetReplyConfig: () => dispatch(resetReplyConfig())
});

class RepliesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
      clientShortName: props.clientShortName || '',
      clientTags: props.clientTags || [],
      loading: true,
      replyConfig: {},
      replyAccess: {},
      editable: false,
      editableAccess: false,
      userList: [],
      roleList: [],
      notAllowed: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.replies.replyConfig !== prevState.replyConfig) {
      update.replyConfig = nextProps.replies.replyConfig;
    }

    if (nextProps.replies.replyAccess !== prevState.replyAccess) {
      update.replyAccess = nextProps.replies.replyAccess;
    }

    if (nextProps.rolesAndPermissionsReducer.roleList !== prevState.roleList) {
      update.roleList = nextProps.rolesAndPermissionsReducer.roleList;
    }

    if (nextProps.users.userList !== prevState.userList) {
      update.userList = nextProps.users.userList;
    }

    return Object.keys(update).length ? update : null;
  }

  initialize() {
    const { clientShortName } = this.state;

    if (
      this.props.permissions.includes('admin_list_users') &&
      this.props.permissions.includes('admin_list_roles') &&
      this.props.permissions.includes('admin_update_client_reply')
    ) {
      this.props.fetchUserList();
      this.props.fetchRolesAndPermissionList();
      this.props.fetchRepliesAccess(clientShortName).then(() => {
        this.setState({
          editableAccess: true
        });
      });
    }

    if (this.props.permissions.includes('admin_list_client_reply')) {
      this.props.fetchReplyConfig(clientShortName).then(() => {
        this.setState({
          loading: false
        });
      });

      if (this.props.permissions.includes('admin_update_client_reply')) {
        this.setState({
          editable: true
        });
      }
    } else {
      this.setState({
        notAllowed: true,
        loading: false
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tabIndex === 4 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true
        },
        this.initialize
      );
    }
  }

  componentDidMount() {
    if (this.props.tabIndex === 4 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true
        },
        this.initialize
      );
    }
  }

  saveReplyConfig(data) {
    this.props.saveReplyConfig(this.state.clientShortName, data);
  }

  saveReplyAccess(data) {
    this.props.putRepliesAccess(this.state.clientShortName, data);
  }

  render() {
    const { classes } = this.props;
    const {
      replyConfig,
      loading,
      notAllowed,
      editable,
      roleList,
      userList,
      replyAccess,
      editableAccess,
      clientShortName
    } = this.state;

    return (
      <div className={classes.root}>
        {!notAllowed && !loading ? (
          <RepliesConfiguration
            saveReplyConfig={this.saveReplyConfig.bind(this)}
            saveReplyAccess={this.saveReplyAccess.bind(this)}
            replyConfig={replyConfig}
            editable={editable}
            editableAccess={editableAccess}
            roleList={roleList}
            userList={userList}
            replyAccess={replyAccess}
            clientShortName={clientShortName}
          />
        ) : !notAllowed ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <Typography className={classes.notAllowed} variant="body1">
            Not Allowed
          </Typography>
        )}
      </div>
    );
  }

  componentWillUnmount() {
    this.props.resetReplyConfig();
    this.setState({
      replyConfig: []
    });
  }
}

RepliesContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  clientShortName: PropTypes.string.isRequired,
  clientTags: PropTypes.array.isRequired,
  tabIndex: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(RepliesContainer));
