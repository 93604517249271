import { setBMM } from '../setters/bmm';

export const extractBMMList = data => {
  const bmms = [];
  data.map(bmm => {
    bmms.push(setBMM(bmm));
    return true;
  });

  return bmms;
};
