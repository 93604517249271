import axios from 'axios';

import { meConstants } from '../constants/meConstants';
import { setHeaders } from '../setters/auth';

export const fetchMe = () => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/users/me`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: meConstants.FETCH_ME,
    payload: promise
  });
};

export const patchMe = (item, data) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/users/me`;

  const promise = axios.patch(
    url,
    {
      [item]: data
    },
    {
      headers: setHeaders()
    }
  );

  return dispatch({
    type: meConstants.PATCH_ME,
    item: item,
    data: data,
    payload: promise
  });
};

export const fetchStatisticsMe = () => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/users/me/statistics`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: meConstants.FETCH_STATISTICS_ME,
    payload: promise
  });
};

export const sendLogs = (message, requests) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/logs`;

  const promise = axios.post(
    url,
    {
      message: message,
      requests: requests
    },
    {
      headers: setHeaders()
    }
  );

  return dispatch({
    type: meConstants.SEND_LOGS,
    payload: promise
  });
};
