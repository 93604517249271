import axios from "axios";

import { moderationConstants } from "../constants/moderationConstants";
import { setHeaders } from "../setters/auth";

export const getCommentList = (stream = {}, option = {}) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/streams/${stream.id}/comments?limit=25`;

  const promise = axios.get(url, {
    headers: setHeaders(),
    stream: stream,
    options: option,
  });

  return dispatch({
    type: moderationConstants.GET_COMMENT_LIST,
    payload: promise,
  });
};

export const setTagToComment = (
  filter = {},
  tagCategories = [],
  tags = {},
  index,
  streamId
) => (dispatch) => {
  const url = `${process.env.URL_API_ENDPOINT}/streams/${streamId}/alerts`;

  const promise = axios.post(url, filter, {
    headers: setHeaders(),
    index: index,
    tags: tags,
    tagCategories: tagCategories,
  });

  return dispatch({
    type: moderationConstants.SET_TAG_TO_COMMENT,
    payload: promise,
  });
};

export const resetCommentList = () => (dispatch) => {
  const promise = new Promise((resolve, reject) => {
    resolve(); // fulfilled
    reject("failure update comment status");
  });

  return dispatch({
    type: moderationConstants.RESET_COMMENT_LIST,
    payload: promise,
  });
};

export const updateCommentStatus = (commentId, data) => (dispatch) => {
  const promise = new Promise((resolve, reject) => {
    resolve({
      commentId,
      data,
    }); // fulfilled
    reject("failure update comment status");
  });

  return dispatch({
    type: moderationConstants.UPDATE_COMMENT_STATUS,
    payload: promise,
  });
};

export const setIsClassifying = (index, value) => (dispatch) => {
  const promise = new Promise((resolve, reject) => {
    resolve({
      index: index,
      value: value,
    }); // fulfilled
    reject("failure decrase notification count");
  });

  return dispatch({
    type: moderationConstants.IS_CLASSIFYING_EVENT,
    payload: promise,
  });
};

export const setIsFavorite = (index, value) => (dispatch) => {
  console.log({ index, value });
  const promise = new Promise((resolve, reject) => {
    resolve({ index, value }); // fulfilled
    reject("failure decrase notification count");
  });

  return dispatch({
    type: moderationConstants.IS_FAVORITE_EVENT,
    payload: promise,
  });
};

export const commentAddTime = (index, text, time) => async (dispatch) => {
  const promise = new Promise((resolve, reject) => {
    resolve({
      index: index,
      text: text,
      time: time,
    }); // fulfilled
    reject("failure decrase notification count");
  });

  return dispatch({
    type: moderationConstants.COMMENT_ADD_TIME,
    payload: promise,
  });
};

export const moderationEndEvent = (filter = {}, index, streamId) => (
  dispatch
) => {
  const url = `${process.env.URL_API_ENDPOINT}/streams/${streamId}/moderations`;
  const promise = axios.post(url, filter, {
    headers: setHeaders(),
    index: index,
  });

  return dispatch({
    type: moderationConstants.MODERATION_END_EVENT,
    payload: promise,
  });
};

export const fetchHistoryFromComment = (
  clientShortName,
  commentId,
  options = {}
) => (dispatch) => {
  const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/comments/${commentId}/history`;
  const promise = axios.get(url, {
    headers: setHeaders(),
    commentId: commentId,
    index: options.index ? options.index : null,
  });

  return dispatch({
    type: moderationConstants.FETCH_HISTORY_FROM_COMMENT,
    payload: promise,
  });
};

export const updateTranslation = (clientShortName, commentId, options = {}) => (
  dispatch
) => {
  const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/comments/${commentId}/message-translation`;
  const promise = axios.patch(
    url,
    {},
    {
      headers: setHeaders(),
      index: options.index !== undefined ? options.index : null,
    }
  );

  return dispatch({
    type: moderationConstants.UPDATE_CLIENTS_COMMENTS_TRANSLATION,
    payload: promise,
  });
};

export const fetchAdsFromPost = (clientShortName, postId, options = {}) => (
  dispatch
) => {
  const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/posts/${postId}/ads`;

  const promise = axios.get(url, {
    headers: setHeaders(),
    index: options.index !== undefined ? options.index : null,
  });

  return dispatch({
    type: moderationConstants.FETCH_CLIENTS_POST_ADS_MODERATION,
    payload: promise,
  });
};

export const unlikeComment = (clientShortName, commentId, streamId, index) => (
  dispatch
) => {
  const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/comments/${commentId}/actions/unlike`;

  const promise = axios.post(
    url,
    {
      stream_id: streamId,
    },
    {
      headers: setHeaders(),
      index,
    }
  );

  return dispatch({
    type: moderationConstants.UNLIKE_COMMENT,
    payload: promise,
  });
};
