import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import queryString from "query-string";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";

import AppBar from "../../components/commons/appBar/AppBarComponent";
import DialogContent from "../../components/commons/dialog/contentDialogComponent";
import LeftMenu from "../../components/commons/leftMenu/leftMenuComponent";
import NotificationMenu from "../../components/commons/notificationMenu/notificationMenuComponent";

import CommentList from "../../components/commentList/CommentListComponent";
import PostRelated from "../../components/postRelated/postRelatedComponent";
import ReplySearch from "../../components/replySearch/replySearchComponent";
import ReplyArea from "../../components/replyArea/replyAreaComponent";

import { operationalTags } from "../../data/data";

import { fetchMe } from "../../actions/meAction";
import { fetchUsers } from "../../actions/usersAction";
import { createTemporaryStream } from "../../actions/streamAction";
import {
  fetchClients,
  getCommentListByClient,
  resetCommentList,
  fetchHistoryFromComment,
  updateTranslation,
  replyActionToComment,
  fetchAdsFromPost,
  fetchClientCommentReplyConfig,
} from "../../actions/replyAction";
import { getPostStatistics } from "../../actions/statisticsAction";

import { removeEmpty } from "../../utils/utils";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    height: 48,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  postRelated: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  postRelatedContainer: {
    marginBottom: "10px",
  },
  rightSide: {
    overflow: "hidden auto",
  },
  heightList: {
    paddingBottom: theme.spacing(2),
    height: "calc(100vh - 348px)",
  },
  actionContent: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(1, 0),
    backgroundColor: theme.palette.backgroundDark,
    borderRadius: 5,
    boxShadow: theme.palette.shadow.shadowAround,
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonSuccess: {
    color: theme.palette.white,
    background: theme.palette.success[500],
    "&:hover, &:focus, &:active": {
      color: theme.palette.white,
      background: theme.palette.success[500],
    },
  },
  loading: {
    margin: "auto",
    color: theme.palette.orange[300],
    marginTop: "calc(50vh - 96px)",
  },
  notAllowed: {
    margin: "auto",
    marginTop: "calc(50vh - 96px)",
  },
  totalComments: {
    width: "100%",
    marginBottom: theme.spacing(1),
    textAlign: "right",
    paddingTop: theme.spacing(1),
  },
});

const mapStateToProps = (state) => ({
  me: state.meReducer,
  reply: state.replyReducer,
  users: state.usersReducer,
  statistics: state.statisticsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMe: () => dispatch(fetchMe()),
  fetchUsers: () => dispatch(fetchUsers()),
  getCommentListByClient: (query, option) =>
    dispatch(getCommentListByClient(query, option)),
  getPostStatistics: (clientShortName, postId) =>
    dispatch(getPostStatistics(clientShortName, postId)),
  resetCommentList: () => dispatch(resetCommentList()),
  createTemporaryStream: (data) => dispatch(createTemporaryStream(data)),
  fetchClients: () => dispatch(fetchClients()),
  fetchHistoryFromComment: (clientShortName, commentId, options) =>
    dispatch(fetchHistoryFromComment(clientShortName, commentId, options)),
  updateTranslation: (clientShortName, commentId, options) =>
    dispatch(updateTranslation(clientShortName, commentId, options)),
  fetchAdsFromPost: (clientShortName, postId, options) =>
    dispatch(fetchAdsFromPost(clientShortName, postId, options)),
  replyActionToComment: (
    clientShortName,
    action,
    commentId,
    index,
    message,
    start_time,
    replier_asset_id
  ) =>
    dispatch(
      replyActionToComment(
        clientShortName,
        action,
        commentId,
        index,
        message,
        start_time,
        replier_asset_id
      )
    ),
  fetchClientCommentReplyConfig: (clientShortName, commentId, options) =>
    dispatch(
      fetchClientCommentReplyConfig(clientShortName, commentId, options)
    ),
});

class ReplyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexList: "0",
      clientIndex: "0",
      open: false,
      query: queryString.parse(window.location.search + window.location.hash),
      translate: false,
      openFilterDialog: false,
      statisticsSelected: false,
      list: [],
      userList: [],
      me: [],
      total: 0,
      replyConfig: {},
      mode: "reply",
      status: "readOnly",
      statistics: {},
      streamRedirectId: "",
      clients: [],
      fetchComment: false,
      notAllowed: false,
      loading: true,
      disableKeyboard: false,
      start_time: ((Date.now() / 1000) | 0).toString(),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.reply.list !== prevState.list) {
      update.list = nextProps.reply.list;
    }

    if (nextProps.reply.replyConfig !== prevState.replyConfig) {
      update.replyConfig = nextProps.reply.replyConfig;
    }

    if (nextProps.reply.fetchComment !== prevState.fetchComment) {
      update.fetchComment = nextProps.reply.fetchComment;
    }

    if (nextProps.users.userList !== prevState.users) {
      update.userList = nextProps.users.userList;
    }

    if (nextProps.reply.total !== prevState.total) {
      update.total = nextProps.reply.total;
    }

    if (nextProps.reply.clients !== prevState.clients) {
      update.clients = nextProps.reply.clients;
    }

    if (nextProps.statistics.statistics !== prevState.statistics) {
      update.statistics = nextProps.statistics.statistics;
    }

    if (nextProps.me.me !== prevState.me) {
      update.me = nextProps.me.me;
    }

    return Object.keys(update).length ? update : null;
  }

  updateTranslation(item, callback) {
    this.props
      .updateTranslation(item[1].client.shortName, item[1].id, {
        index: item[1].key,
      })
      .then((response) => {
        callback(response.value.data.messageTranslated);
      });
  }

  clickItemFromPost(string) {
    if (string === "openAds") {
      const post = this.state.list[this.state.indexList]["post"];
      this.props.fetchAdsFromPost(post["client"]["shortName"], post["id"], {
        index: this.state.indexList,
      });
    }
  }

  clickItemFromComment(string, data = {}, callback) {
    const { list, indexList } = this.state;
    const clientShortName = list[indexList]["client"]["shortName"];
    const commentId = list[indexList]["id"];

    if (string === "history") {
      this.props.fetchHistoryFromComment(clientShortName, commentId, {
        index: indexList,
      });
    }

    if (typeof callback === "function") {
      callback();
    }
  }

  onCommentSelect = () => {
    this.setState({ start_time: ((Date.now() / 1000) | 0).toString() });
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  onChangeIndexMenu(index) {
    if (index === 1) {
      this.setState(
        {
          statisticsSelected: true,
        },
        () => {
          const post = this.state.list[this.state.indexList]["post"];
          this.props.getPostStatistics(post["client"]["shortName"], post["id"]);
        }
      );
    } else {
      this.setState({
        statisticsSelected: false,
      });
    }
  }

  getIndexList(index) {
    const { list, statisticsSelected } = this.state;
    //get index of the comment list
    this.setState({
      indexList: index,
    });

    if (index && list && list[index]) {
      const clientShortName = list[index]["client"]
        ? list[index]["client"]["shortName"]
        : "";
      const commentId = list[index]["id"];
      const post = list[index]["post"];
      if (index && post && post["client"] && statisticsSelected) {
        this.props.getPostStatistics(post["client"]["shortName"], post["id"]);
      }

      this.props.fetchClientCommentReplyConfig(clientShortName, commentId, {
        index: index,
      });
    }
  }

  getQueryString(q) {
    const query = queryString.stringify(removeEmpty(q), {
      strict: false,
      encode: false,
      arrayFormat: "comma",
      skipNull: true,
    });
    return query;
  }

  getFilterData(data) {
    const query = {};

    if (data.clientShortName) {
      Object.assign(query, {
        client_short_name: data.clientShortName,
      });
    } else {
      return false;
    }

    if (data.replyStatus) {
      Object.assign(query, {
        reply_status: data.replyStatus.join(","),
      });
    }

    if (data.message) {
      Object.assign(query, {
        reply_message: data.message,
      });
    }

    if (data.createdAtStart) {
      Object.assign(query, {
        reply_created_at_start: data.createdAtStart,
      });
    }

    if (data.createdAtEnd) {
      Object.assign(query, {
        reply_created_at_end: data.createdAtEnd,
      });
    }

    if (data.updatedAtStart) {
      Object.assign(query, {
        reply_updated_at_start: data.updatedAtStart,
      });
    }

    if (data.updatedAtEnd) {
      Object.assign(query, {
        reply_updated_at_end: data.updatedAtEnd,
      });
    }

    if (data.createdBy) {
      Object.assign(query, {
        reply_created_by: data.createdBy,
      });
    }

    if (data.updatedBy) {
      Object.assign(query, {
        reply_updated_by: data.updatedBy,
      });
    }

    if (data.startTime) {
      Object.assign(query, {
        start: data.startTime,
      });
    }

    if (data.endTime) {
      Object.assign(query, {
        end: data.endTime,
      });
    }

    if (data.sort) {
      Object.assign(query, {
        sort: data.sort,
      });
    }

    this.setState(
      {
        query: query,
        openFilterDialog: false,
        total: 0,
      },
      () => {
        let client = this.findClientKey(this.state.clients, "clientShortName");
        if (client) {
          this.clientSelected(client[0]);
        }
      }
    );
  }

  updateList(query) {
    this.props.resetCommentList().then(() => {
      setTimeout(() => {
        this.fetchCommentList();
      }, 0);
    });

    localStorage.setItem("query-reply", JSON.stringify(query));
    window.history.pushState(
      null,
      null,
      `${window.location.origin}/reply?${this.getQueryString(query)}`
    );
  }

  fetchCommentList(options = {}, callback = null) {
    const { query, indexList } = this.state;
    this.props.getCommentListByClient(query, options).then((response) => {
      if (typeof callback === "function") {
        callback(
          response &&
            response.value &&
            response.value.data &&
            response.value.data.items &&
            response.value.data.items.length
            ? false
            : true
        );
      }
    });
    this.getIndexList(indexList ? indexList : "0");
  }

  logout() {
    this.props.signOut();
  }

  loadMore(callback) {
    this.fetchCommentList(
      {
        cursor: this.props.reply.cursor,
      },
      callback
    );
  }

  showFilterDialog() {
    this.setState({
      openFilterDialog: true,
    });
  }

  closeFilterDialog() {
    this.setState({
      openFilterDialog: false,
    });
  }

  selectReplyOpen(status) {
    this.setState({
      disableKeyboard: status,
    });
  }

  setReplyPage() {
    //if 0 param in the url
    if (
      Object.values(this.state.query).length !== 0 &&
      !this.state.query.comment_ids &&
      localStorage.getItem("query-reply")
    ) {
      window.history.pushState(
        null,
        null,
        `${window.location.origin}/reply?${this.getQueryString(
          JSON.parse(localStorage.getItem("query-reply"))
        )}`
      );
      this.setState(
        {
          query: JSON.parse(localStorage.getItem("query-reply")),
        },
        () => {
          this.getClientReplyList(this.state.query.comment_ids);
        }
      );
    } else {
      this.getClientReplyList(this.state.query.comment_ids);
    }
  }

  clientSelected(index = "0", firstload = false, comment_ids) {
    const { me } = this.state;
    if (
      me &&
      me.permissions &&
      me.permissions.includes("list_client_reply_config")
    ) {
      if (comment_ids) {
        this.setState(
          {
            query: {
              client_short_name: this.state.clients[index]["clientShortName"],
              comment_ids: comment_ids,
            },
          },
          () => {
            this.updateList(this.state.query);
          }
        );
      } else if (
        index !== this.state.clientIndex ||
        (!Object.values(this.state.query).length && firstload)
      ) {
        this.setState(
          {
            query: {
              client_short_name: this.state.clients[index]["clientShortName"],
              reply_status: "new,pending",
            },
          },
          () => {
            this.updateList(this.state.query);
          }
        );
      } else {
        if (!firstload) {
          this.updateList(this.state.query);
        }
      }
    }

    this.setState({
      clientIndex: index,
    });
  }

  findClientKey(clientList, value) {
    const { query } = this.state;
    const clientKeys = Object.keys(clientList);
    return clientKeys.filter((key) => {
      return query.client_short_name === clientList[key][value];
    });
  }

  getClientReplyList(comment_ids) {
    const { query } = this.state;
    this.props.fetchClients().then((response) => {
      if (query && query.client_short_name) {
        let client = this.findClientKey(
          response.value.data.items,
          "client_short_name"
        );
        if (client) {
          this.clientSelected(client[0], false, comment_ids);
        }
      }
    });
  }

  replyActionToComment(message, action, callback, replier_asset_id) {
    const { indexList, list, clientIndex, clients, start_time } = this.state;
    this.props
      .replyActionToComment(
        clients[clientIndex]["clientShortName"],
        list[indexList]["id"],
        action,
        indexList,
        message,
        start_time,
        replier_asset_id
      )
      .then((response) => {
        if (callback && typeof callback === "function") {
          callback(
            response && response.value && response.value.data
              ? response.value.data
              : null
          );
        }
      })
      .catch((errorResponse) => {
        if (callback && typeof callback === "function") {
          callback("error");
        }
      });
  }

  componentDidMount() {
    this.props.fetchMe().then((response) => {
      this.setState({
        loading: false,
      });
      if (
        response.value &&
        response.value.data &&
        response.value.data.permissions &&
        response.value.data.permissions.includes("reply_stats")
      ) {
        this.props.fetchUsers().then(() => {
          this.setReplyPage();
        });
      } else {
        this.setState({
          notAllowed: true,
        });
      }
    });
  }

  render() {
    const { classes } = this.props;
    const {
      list,
      total,
      open,
      indexList,
      clientIndex,
      translate,
      displayBoth,
      statistics,
      userList,
      mode,
      status,
      query,
      openFilterDialog,
      clients,
      fetchComment,
      streamRedirectId,
      notAllowed,
      loading,
      disableKeyboard,
      me,
      replyConfig,
    } = this.state;

    if (streamRedirectId) {
      return (
        <Redirect
          to={{
            pathname: "/moderation",
            search: `?stream=${streamRedirectId}`,
          }}
        />
      );
    }

    return (
      <div className={classes.root}>
        <AppBar
          action={this.handleDrawerOpen}
          status={open}
          selected={"reply"}
          logout={this.logout.bind(this)}
        />
        <LeftMenu open={open} onClose={this.handleDrawerClose.bind(this)} />
        {clients.length ? (
          <NotificationMenu
            index={clientIndex}
            enableFavorite={false}
            selected={this.clientSelected.bind(this)}
            items={clients}
            title={"Clients"}
            moderation={false}
            loaded={fetchComment}
            hasGroupedItems={false}
          />
        ) : (
          ""
        )}

        <main className={classes.content}>
          <div className={classes.toolbar} />
          {!notAllowed && !loading ? (
            <Grid container spacing={2}>
              <Grid item xl={8} lg={7} sm={6} xs={7}>
                <Grid item xs={12}>
                  <CommentList
                    containerClassName={classes.heightList}
                    getIndexList={this.getIndexList.bind(this)}
                    translated={translate}
                    displayBoth={displayBoth}
                    list={list}
                    clickItemFromComment={this.clickItemFromComment.bind(this)}
                    loadMore={this.loadMore.bind(this)}
                    cursor={this.props.reply.cursor}
                    actionsMenu={operationalTags}
                    mode={mode}
                    status={status}
                    updateTranslation={this.updateTranslation.bind(this)}
                    disableKeyboard={disableKeyboard}
                    onCommentSelect={this.onCommentSelect.bind(this)}
                  />
                </Grid>
                <Grid item>
                  <div className={classes.totalComments}>
                    <Chip
                      color="primary"
                      size="small"
                      icon={<DoneIcon />}
                      label={`Total comments: ${total ? total : "0"}`}
                    />
                  </div>
                </Grid>
                <Grid item>
                  {me.permissions &&
                  me.permissions.length &&
                  Object.keys(replyConfig).length > 0 &&
                  list[indexList] ? (
                    <ReplyArea
                      item={list[indexList] ? list[indexList] : []}
                      replyActionToComment={this.replyActionToComment.bind(
                        this
                      )}
                      permissions={me.permissions}
                      selectReplyOpen={this.selectReplyOpen.bind(this)}
                      replyConfig={replyConfig}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
              <Grid
                className={classes.rightSide}
                item
                xl={4}
                lg={5}
                sm={6}
                xs={5}
              >
                <div className={classes.postRelatedContainer}>
                  <PostRelated
                    statistics={statistics}
                    clickItemFromPost={this.clickItemFromPost.bind(this)}
                    onChangeIndexMenu={this.onChangeIndexMenu.bind(this)}
                    post={
                      list && list[indexList] ? list[indexList]["post"] : null
                    }
                  />
                </div>
                <div className={classes.actionsContainer}>
                  <div className={classes.actionContent}>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.button}
                      onClick={this.showFilterDialog.bind(this)}
                    >
                      Filters
                    </Button>
                  </div>

                  <DialogContent
                    title={"Filters"}
                    open={openFilterDialog}
                    close={this.closeFilterDialog.bind(this)}
                  >
                    {clients && clients.length ? (
                      <ReplySearch
                        defaultValues={query}
                        getFilterData={this.getFilterData.bind(this)}
                        clients={clients}
                        userList={userList}
                        hide={this.closeFilterDialog.bind(this)}
                      />
                    ) : (
                      <div></div>
                    )}
                  </DialogContent>
                </div>
              </Grid>
            </Grid>
          ) : !notAllowed ? (
            <CircularProgress className={classes.loading} />
          ) : (
            <Typography className={classes.notAllowed} variant="body1">
              Not Allowed
            </Typography>
          )}
        </main>
      </div>
    );
  }
}

ReplyPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ReplyPage));
