import axios from 'axios';

import { streamsConstants } from '../../constants/configuration/streamsConstants';
import { setHeaders } from '../../setters/auth';

export const fetchStreamList = () => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/streams`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: streamsConstants.FETCH_STREAM_LIST,
    payload: promise
  });
};

export const addStream = data => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/streams`;
  let streams = {
    id: data.id,
    label: data.label,
    tags: data.tags,
    filters: data.filters
  };

  let access_filters = [];

  if (data.exclusion_filters) {
    streams = {
      ...streams,
      exclusion_filters: data.exclusion_filters
    };
  }

  if (data.roles && data.roles.length) {
    access_filters.push({
      field: 'roles',
      type: 'contains_any',
      values: data.roles
    });
  }

  if (data.users && data.users.length) {
    access_filters.push({
      field: 'username',
      type: 'contains_any',
      values: data.users
    });
  }

  if (access_filters && access_filters.length) {
    streams = {
      ...streams,
      access_filters: access_filters
    };
  }

  const promise = axios.post(url, streams, {
    headers: setHeaders(),
    streamInfo: data
  });

  return dispatch({
    type: streamsConstants.ADD_STREAM,
    payload: promise
  });
};

export const editStream = (index, data) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/streams/${data.id}`;
  let streams = {
    label: data.label,
    tags: data.tags,
    filters: data.filters
  };

  let access_filters = [];

  if (data.exclusion_filters) {
    streams = {
      ...streams,
      exclusion_filters: data.exclusion_filters
    };
  }

  if (data.roles && data.roles.length) {
    access_filters.push({
      field: 'roles',
      type: 'contains_any',
      values: data.roles
    });
  }

  if (data.users && data.users.length) {
    access_filters.push({
      field: 'username',
      type: 'contains_any',
      values: data.users
    });
  }

  if (access_filters && access_filters.length) {
    streams = {
      ...streams,
      access_filters: access_filters
    };
  }

  const promise = axios.put(url, streams, {
    headers: setHeaders(),
    index: index,
    streamInfo: data
  });

  return dispatch({
    type: streamsConstants.EDIT_STREAM,
    payload: promise
  });
};
