import { setPostKPI } from '../setters/postKPI';

export const extractPostKPIsList = data => {
  const postKPIs = [];

  data.map((postKPI, key) => {
    postKPIs.push(setPostKPI(postKPI, key));
    return true;
  });

  return postKPIs;
};
