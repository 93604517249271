import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  textColor: {
    color: theme.palette.black
  }
}));

const AlertDialog = ({
  open,
  title,
  message,
  buttonAcceptText,
  buttonCancelText,
  action
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.textColor}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.textColor}
          >
            {message ? message : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => action(false)} color="primary">
            {buttonCancelText}
          </Button>
          {buttonAcceptText ? (
            <Button onClick={() => action(true)} color="primary" autoFocus>
              {buttonAcceptText}
            </Button>
          ) : (
            ''
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  action: PropTypes.func,
  buttonAcceptText: PropTypes.string,
  buttonCancelText: PropTypes.string
};

export default AlertDialog;
