// Packages
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// Matrial
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginRight: 15,
  },
  formControl: {
    width: "100%",
  },
  inputWidth: {
    marginTop: 0,
    width: 220,
  },
  inputFullWidth: {
    marginTop: 0,
    width: "100%",
  },
  inputHalfWidth: {
    marginTop: 0,
    width: "50%",
  },
}));

// Component
const InputComponent = ({
  id,
  required,
  disabled,
  defaultValue,
  value,
  error,
  helperText,
  label,
  margin,
  fullWidth,
  halfWidth,
  multiline,
  onChange,
  type,
  inputProps,
  onBlur,
  autofocus = false,
}) => {
  const classes = useStyles();

  // Since TextField does not accept both defaultValue and value,
  // check which one will be used and set `values` accordingly
  const values =
    void 0 !== value
      ? { value }
      : {
          defaultValue:
            defaultValue !== undefined && defaultValue !== null
              ? defaultValue
              : "",
        };

  const handleChange = (e, id) => {
    onChange(e, id);
  };

  return (
    <div>
      <FormControl className={classNames(classes.formControl, classes.root)}>
        <TextField
          autoFocus={autofocus}
          type={type ? type : "text"}
          autoComplete="off"
          multiline={multiline}
          required={required}
          disabled={disabled}
          error={error}
          inputProps={inputProps}
          helperText={helperText}
          onBlur={onBlur}
          className={classNames(classes.inputWidth, {
            [classes.inputFullWidth]: fullWidth,
            [classes.inputHalfWidth]: halfWidth,
          })}
          id={id}
          label={label}
          margin={margin ? margin : "normal"}
          onChange={(e) => {
            return handleChange(e, id);
          }}
          {...values}
        />
      </FormControl>
    </div>
  );
};

// PropTypes
InputComponent.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  halfWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.string,
  onChange: PropTypes.func,
};

// Export
export default InputComponent;
