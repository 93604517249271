import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import DatePicker from '../../commons/picker/dateTimePickerComponent';

import InputComponent from '../../commons/input/inputComponent';
import SelectComponent from '../../commons/select/selectComponent';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  formControlContainer: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  formGroup: {
    width: '50%'
  }
}));

const TaskHistoryForm = props => {
  const classes = useStyles();

  const onChangeDate = (name, value, context = null) => {
    props.onChangeFormData(name, value, context);
  };

  const displayTextField = (
    name,
    label,
    required,
    disabled,
    multiline,
    fullWidth,
    context = null
  ) => {
    const { formData, errors } = props;
    const dataContext =
      context && formData[context] ? formData[context] : formData;
    const errorContext = context && errors[context] ? errors[context] : errors;

    return (
      <InputComponent
        multiline={multiline}
        required={required}
        disabled={disabled}
        defaultValue={dataContext[name]}
        onChange={e => {
          props.onChangeFormData(name, e.target.value, context);
        }}
        value={dataContext[name]}
        error={errorContext[name] ? true : false}
        helperText={errorContext[name]}
        label={label}
        margin="dense"
        fullWidth={fullWidth}
      />
    );
  };

  const { formData } = props;

  return (
    <div className={classes.formControlContainer}>
      <FormControl className={classes.formControl}>
        <FormGroup className={classes.formGroup}>
          {displayTextField(
            'query',
            'Query',
            true,
            false,
            false,
            true,
            'params'
          )}
        </FormGroup>
        <SelectComponent
          label={'Order'}
          value={formData.params['order']}
          items={props.orderOptions}
          onChange={e =>
            props.onChangeFormData('order', e.target.value, 'params')
          }
        />
        <FormGroup className={classes.formGroup}>
          <div className={classes.container}>
            <DatePicker
              settedValue={false}
              name={'publishedBefore'}
              label={'Published Before'}
              value={
                formData.params.publishedBefore
                  ? formData.params.publishedBefore
                  : null
              }
              setSelectedDate={(name, value) =>
                onChangeDate(name, value, 'params')
              }
            />
          </div>
        </FormGroup>
        <FormGroup className={classes.formGroup}>
          <div className={classes.container}>
            <DatePicker
              settedValue={false}
              name={'publishedAfter'}
              label={'Published After'}
              value={
                formData.params.publishedAfter
                  ? formData.params.publishedAfter
                  : null
              }
              setSelectedDate={(name, value) =>
                onChangeDate(name, value, 'params')
              }
            />
          </div>
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.is_favorite === 1 ? true : false}
                onChange={e =>
                  props.onChangeFormData(
                    'is_favorite',
                    e.target.checked ? 1 : 0
                  )
                }
                name="is_favorite"
              />
            }
            label="Recurrent task (Favorite)"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};

TaskHistoryForm.propTypes = {
  formData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChangeFormData: PropTypes.func.isRequired
};

export default TaskHistoryForm;
