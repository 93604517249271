import axios from "axios";

import { featureFlagsConstants } from "../constants/featureFlagsConstants";
import { setHeaders } from "../setters/auth";

const getUrl = (clientShortName) =>
  `${process.env.URL_API_ENDPOINT}/admin/clients/${clientShortName}/feature-flags`;

export const fetchFeatureFlags = (clientShortName) => (dispatch) => {
  const promise = axios.get(getUrl(clientShortName), {
    headers: setHeaders(),
  });

  return dispatch({
    type: featureFlagsConstants.FETCH_FEATURE_FLAGS,
    payload: promise,
  });
};

export const updateFeatureFlags = (clientShortName, items) => (dispatch) => {
  const promise = axios.patch(
    getUrl(clientShortName),
    { items },
    {
      headers: setHeaders(),
    }
  );

  return dispatch({
    type: featureFlagsConstants.UPDATE_FEATURE_FLAGS,
    payload: promise,
  });
};
