import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import Input from "../commons/input/inputComponent";
import DialogContent from "../commons/dialog/contentDialogComponent";

const styles = (theme) => ({
  actionWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
});

const AlertCancelDialog = ({ isOpen, onConfirm, onClose, classes }) => {
  const [reason, setReason] = useState("");

  const onInternalClose = () => {
    setReason("");
    onClose();
  };

  const onInternalConfirm = () => {
    onConfirm(reason);
    setReason("");
  };

  return (
    <DialogContent open={isOpen} title="Cancel Alert" close={onInternalClose}>
      <>
        <Typography variant="body2">
          Please provide a reason for canceling the alert.
        </Typography>
        <Input
          fullWidth
          multiline
          autofocus
          label="Reason"
          margin="dense"
          value={reason}
          id="cancel_reason"
          onChange={(event) => setReason(event.target.value)}
        />

        <div className={classes.actionWrapper}>
          <Button size="small" color="secondary" onClick={onInternalClose}>
            Close
          </Button>
          <Button size="small" color="primary" onClick={onInternalConfirm}>
            Confirm
          </Button>
        </div>
      </>
    </DialogContent>
  );
};

export default withStyles(styles, { withTheme: true })(AlertCancelDialog);
