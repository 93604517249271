export const setGuidelineGroup = (gg, key = null) => {
  const item = {
    name: gg.name ? gg.name : '',
    asset_ids: gg.asset_ids ? gg.asset_ids : [],
    id: gg.id ? gg.id : '',
    client_short_name: gg.client_short_name ? gg.client_short_name : ''
  };

  return item;
};
