import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import { setDateFormatTimeStamp } from '../../../setters/date';

import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(theme => ({
  tag: {
    margin: 5,
    height: 20,
    fontSize: 10
  },
  title: {},
  errorsContainer: {
    marginLeft: theme.spacing(2)
  },
  typo: {
    marginLeft: theme.spacing(1),
    fontSize: 13,
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: theme.palette.primary.main,
    minWidth: 30,
    marginRight: theme.spacing(1)
  },
  iconAlert: {
    width: 30,
    marginRight: 0,
    fontSize: 20
  }
}));

const HistoryComment = ({ type, step }) => {
  const classes = useStyles();
  return (
    <Step active={true}>
      <StepLabel className={classes.title}>{`Action ${
        type === ('action_failed' || 'action_fail') ? 'Failed' : 'Successful'
      }`}</StepLabel>
      <StepContent>
        <Typography className={classes.typo}>
          <Icon
            className={classNames(
              classes.icon,
              classes.iconAlert,
              'fa fa-exclamation-triangle'
            )}
          />
          {step['type'] ? step['type'] : 'action unknown'}
        </Typography>
        <div>
          {step.inputs
            ? step.inputs.map((tag, key) => {
                return (
                  <Chip
                    className={classes.tag}
                    key={key}
                    label={tag}
                    component="div"
                  />
                );
              })
            : ''}
        </div>
        <Typography className={classes.typo}>
          <Icon className={classes.icon}>calendar_today</Icon>
          {` ${setDateFormatTimeStamp(step.timestamp)}`}
        </Typography>
        {step.error ? (
          <Typography className={classes.typo}>
            {`error: ${step.error}`}
          </Typography>
        ) : (
          ''
        )}
      </StepContent>
    </Step>
  );
};

HistoryComment.propTypes = {
  step: PropTypes.object
};

export default HistoryComment;
