import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChipInput from 'material-ui-chip-input';
import { SwatchesPicker } from 'react-color';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import FormGroup from '@material-ui/core/FormGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import InputComponent from '../../commons/input/inputComponent';
import SearchSelectMulti from '../../commons/select/searchSelectMultiComponent';
import JSONEditor from '../../commons/jsonEditor/jsonEditorComponent';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  formControlContainer: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  formGroup: {
    width: '50%'
  },
  formGroupEditor: {
    marginTop: theme.spacing(6),
    width: '50%'
  },
  colorContainer: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  buttonColor: {
    marginLeft: theme.spacing(2)
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
    top: 175
  },
  formControlLabelSwitch: {
    marginLeft: 0,
    width: 'fit-content'
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  }
});

class ClassifiersForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      checkFilter: props.checkFilter,
      tagList: props.tagList
        ? props.tagList.sort((a, b) => (a.label > b.label ? 1 : -1))
        : [],
      openPickColor: false,
      data: props.defaultValue
        ? props.defaultValue
        : {
            classifier_name: '',
            group: '',
            colorGroup: '',
            versions: [],
            default_rules: []
          },
      error: props.error
        ? props.error
        : {
            classifier_name: '',
            group: '',
            colorGroup: '',
            versions: '',
            default_rules: ''
          }
    };
  }

  handleClick(e, value) {
    this.setState({
      openPickColor: value
    });
  }

  handleChangeColor = (color, name) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          [name]: color.hex
        }
      },
      () => {
        this.props.onChangeClassifierForm(this.state.data);
      }
    );
  };

  onChange(e, name) {
    this.setState(
      {
        data: {
          ...this.state.data,
          [name]: e.target ? e.target.value : e
        }
      },
      () => {
        this.props.onChangeClassifierForm(this.state.data);
      }
    );
  }

  onErrorEditor(e) {
    this.props.onErrorClassifierForm(e);
  }

  onChangeRules(e, value, key, checked = false) {
    this.setState(
      {
        data: {
          ...this.state.data,
          default_rules: Object.assign([], {
            ...this.state.data.default_rules,
            [key]: {
              ...this.state.data.default_rules[key],
              [value]: e.target
                ? checked
                  ? e.target.checked
                  : e.target.value
                : e
            }
          })
        }
      },
      () => {
        this.props.onChangeClassifierForm(this.state.data);
      }
    );
  }

  renderChip(list, value, id, item, key) {
    const { classes } = this.props;
    return (
      <Chip
        key={key}
        label={
          list.find(i => value === i[id])
            ? item !== null
              ? list.find(i => value === i[id])[item]
              : value
            : value
        }
        className={classes.item}
      />
    );
  }

  displaySwitch(rule, key) {
    const { classes } = this.props;

    return (
      <FormControlLabel
        classes={{ root: classes.formControlLabelSwitch }}
        labelPlacement={'start'}
        control={
          <Switch
            checked={rule.autoprocess}
            onChange={e => {
              return this.onChangeRules(e, 'autoprocess', key, true);
            }}
            value={rule.autoprocess}
            color="primary"
          />
        }
        label="Autoprocess"
      />
    );
  }

  displayRule(rule, key) {
    const { classes } = this.props;
    const { error, checkFilter, tagList } = this.state;

    return (
      <div key={key}>
        <FormControl className={classes.formControl}>
          {this.displaySwitch(rule, key)}
        </FormControl>
        <FormControl>
          <SearchSelectMulti
            name={'tags'}
            label={'Tags'}
            list={tagList}
            id={'id'}
            attribute={'label'}
            data={rule && rule.tags ? rule.tags : []}
            onChange={(e, name) => this.onChangeRules(e, 'tags', key)}
            renderItem={(i, id, attribute, name, key) =>
              this.renderChip(i, id, attribute, name, key)
            }
            enableCopyPaste={true}
          />
        </FormControl>
        <JSONEditor
          checkFilter={checkFilter}
          placeholder={rule && rule.filters ? rule.filters : []}
          height={'240px'}
          onChange={e => {
            return this.onChangeRules(e, 'filters', key);
          }}
          onError={e => {
            return this.onErrorEditor(e);
          }}
        />
        {error['default_rules'] ? (
          <FormHelperText>{error['default_rules']}</FormHelperText>
        ) : (
          ''
        )}
      </div>
    );
  }

  displayDefaultRules() {
    const { classes } = this.props;
    const { data } = this.state;

    return (
      <FormGroup className={classes.formGroupEditor}>
        <InputLabel>Default Rules *</InputLabel>
        {data.default_rules
          ? data.default_rules.map((rule, key) => {
              return this.displayRule(rule, key);
            })
          : ''}
      </FormGroup>
    );
  }

  displayChipInput(name, label, required = false) {
    const { classes, defaultValue } = this.props;
    const { data, error } = this.state;
    return (
      <div>
        <ChipInput
          required={required}
          label={label}
          error={error[name] ? true : false}
          helperText={error[name]}
          fullWidth={true}
          defaultValue={
            Object.values(defaultValue).length ? defaultValue[name] : data[name]
          }
          onChange={e => this.onChange(e, name)}
        />
        {error[name] ? <div className={classes.errorMargin}></div> : ''}
      </div>
    );
  }

  displayColorPicker(name) {
    const { classes } = this.props;
    return (
      <div className={classes.popover}>
        <div
          className={classes.cover}
          onClick={e => this.handleClick(e, false)}
        />
        <SwatchesPicker
          color={this.state.data[name]}
          onChangeComplete={e => this.handleChangeColor(e, name)}
        />
      </div>
    );
  }

  displayTextField(name, label, required, disabled, multiline, fullWidth) {
    const { defaultValue } = this.props;
    const { data, error } = this.state;
    return (
      <InputComponent
        multiline={multiline}
        required={required}
        disabled={disabled}
        defaultValue={
          Object.values(defaultValue).length ? defaultValue[name] : data[name]
        }
        onChange={e => {
          return this.onChange(e, name);
        }}
        value={data[name]}
        error={error[name] ? true : false}
        helperText={error[name]}
        label={label}
        margin="dense"
        fullWidth={fullWidth}
      />
    );
  }

  displayTextFields() {
    const { classes, mode } = this.props;
    return (
      <FormGroup className={classes.formGroup}>
        {this.displayTextField(
          'classifier_name',
          'Classifier name',
          true,
          mode === 'edit' ? true : false,
          false,
          true
        )}
        {this.displayTextField('group', 'Group name', true, false, true, true)}
        <div className={classes.colorContainer}>
          {this.displayTextField(
            'colorGroup',
            'Group color',
            true,
            false,
            false,
            false
          )}
          <Button
            className={classes.buttonColor}
            size="small"
            onClick={e => this.handleClick(e, true)}
          >
            Pick Color
          </Button>
          {this.state.openPickColor
            ? this.displayColorPicker('colorGroup')
            : ''}
        </div>
        {this.displayChipInput('versions', 'Versions', true)}
      </FormGroup>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checkFilter !== this.props.checkFilter) {
      this.setState({
        checkFilter: this.props.checkFilter
      });
    }

    if (prevProps.error !== this.props.error) {
      this.setState({
        error: this.props.error
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.state;
    return (
      <div className={classes.root}>
        {loading ? (
          <CircularProgress />
        ) : (
          <div className={classes.formControlContainer}>
            <FormControl className={classes.formControl}>
              {this.displayTextFields()}
            </FormControl>
            <FormControl className={classes.formControl}>
              {this.displayDefaultRules()}
            </FormControl>
          </div>
        )}
      </div>
    );
  }
}

ClassifiersForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onChangeClassifierForm: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  error: PropTypes.object,
  checkFilter: PropTypes.bool.isRequired,
  onErrorClassifierForm: PropTypes.func.isRequired,
  mode: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(ClassifiersForm);
