import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import DialogContent from "../../commons/dialog/contentDialogComponent";
import NestedList from "../../commons/nestedList/nestedListComponent";
import SelectComponent from "../../commons/select/selectComponent";

import RepliesAccessForm from "./repliesAccessForm";

import { downloadAsJson } from "../../../utils/utils";

const styles = (theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(1),
  },
  replyConfigContainer: {},
  title: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 7,
  },
  buttonEditReplyConfig: {
    marginLeft: theme.spacing(2),
  },
  replyConfigFilters: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonDownload: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0),
    color: theme.palette.black,
    padding: 0,
  },
  btnLoader: {
    color: theme.palette.white,
  },
});

const AVAILABLE_LANGUAGES = ["en", "es", "de", "fr", "it"];

class RepliesConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      replyConfig: props.replyConfig || {},
      replyAccess: props.replyAccess || {},
      roleList: props.roleList,
      userList: props.userList,
      defaultValue: {
        users: [],
        roles: [],
      },
      creatable: false,
      selectedItem: "",
      deletable: false,
      notAllowed: false,
      editableAccess: false,
      openDialogRepliesAccess: false,
      status: "read",
      editConfig: false,
      available_languages:
        props.replyConfig && props.replyConfig.available_languages
          ? props.replyConfig.available_languages
          : [],
      default_language:
        props.replyConfig && props.replyConfig.default_language
          ? props.replyConfig.default_language
          : "",
    };

    this.availableLanguages =
      [
        ...new Set([
          ...AVAILABLE_LANGUAGES,
          ...props.replyConfig.available_languages,
        ]),
      ] || [];
  }

  handleChange(event, id) {
    this.setState({
      [id]: event.target.value,
    });
  }

  selectedItem(item, lang) {
    this.setState({
      selectedItem: item,
    });
  }

  editReplyConfig(e, status) {
    this.setState({
      editConfig: !this.state.editConfig,
      status: status,
    });
  }

  saveEvent(categories) {
    this.props.saveReplyConfig({
      categories: categories,
      available_languages: this.state.available_languages,
      default_language: this.state.default_language,
    });
  }

  displayButtonsRepliesAccess() {
    const { classes } = this.props;
    return (
      <div className={classes.buttonContainer}>
        <Button
          size="small"
          color="primary"
          onClick={(e) => {
            this.props.saveReplyAccess(this.state.replyAccess);
            this.closeDialogRepliesAccess();
            e.preventDefault();
          }}
        >
          {"Save"}
        </Button>
        <Button
          onClick={(e) => {
            return this.closeDialogRepliesAccess(e);
          }}
          size="small"
          color="secondary"
        >
          Cancel
        </Button>
      </div>
    );
  }

  onChangeRepliesAccessForm(data) {
    this.setState({
      replyAccess: data,
    });
  }

  closeDialogRepliesAccess() {
    this.setState({
      openDialogRepliesAccess: false,
    });
  }

  editRepliesAccess(e) {
    this.setState({
      openDialogRepliesAccess: true,
    });
  }

  downloadJson() {
    downloadAsJson(
      this.state.replyConfig,
      this.props.clientShortName +
        "_replyConfig_" +
        moment().format("YYYY-MM-DD") +
        "_" +
        moment().format("hh_mm_A")
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userList !== this.props.userList) {
      this.setState({
        userList: this.props.userList,
      });
    }

    if (prevProps.roleList !== this.props.roleList) {
      this.setState({
        roleList: this.props.roleList,
      });
    }

    if (prevProps.replyAccess !== this.props.replyAccess) {
      this.setState({
        replyAccess: this.props.replyAccess,
      });
    }

    if (prevProps.replyConfig !== this.props.replyConfig) {
      this.setState({
        replyConfig: this.props.replyConfig,
        available_languages: this.props.replyConfig.available_languages
          ? this.props.replyConfig.available_languages
          : [],
        default_language: this.props.replyConfig.default_language
          ? this.props.replyConfig.default_language
          : [],
        status: "read",
      });
    }
  }

  render() {
    const { classes, editable, editableAccess } = this.props;

    const {
      replyConfig,
      editConfig,
      status,
      default_language,
      available_languages,
      openDialogRepliesAccess,
      userList,
      roleList,
      replyAccess,
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h5">Reply Config</Typography>
          <IconButton
            className={classes.buttonDownload}
            onClick={() => this.downloadJson()}
          >
            <SystemUpdateAltIcon />
          </IconButton>
          {editable ? (
            <Button
              variant="contained"
              className={classes.buttonEditReplyConfig}
              size="small"
              color={editConfig ? "secondary" : "primary"}
              onClick={(e) => {
                return this.editReplyConfig(e, editConfig ? "cancel" : "edit");
              }}
              disabled={status === "save"}
              startIcon={
                status === "save" ? (
                  <CircularProgress size={15} className={classes.btnLoader} />
                ) : null
              }
            >
              {!editConfig ? "Edit Config" : "Cancel Edit"}
            </Button>
          ) : (
            ""
          )}
          {editable && editConfig ? (
            <Button
              variant="contained"
              className={classes.buttonEditReplyConfig}
              size="small"
              color="primary"
              onClick={(e) => {
                return this.editReplyConfig(e, "save");
              }}
            >
              Save Config
            </Button>
          ) : (
            ""
          )}
          {editableAccess ? (
            <Button
              variant="contained"
              className={classes.buttonEditReplyConfig}
              size="small"
              color="primary"
              onClick={(e) => {
                return this.editRepliesAccess(e);
              }}
            >
              {"Edit Access"}
            </Button>
          ) : (
            ""
          )}
        </div>
        <div className={classes.replyConfigContainer}>
          {editConfig ? (
            <div className={classes.replyConfigFilters}>
              <SelectComponent
                id={"available_languages"}
                label={"Available Languages"}
                multiple={true}
                value={available_languages}
                display={3}
                items={this.availableLanguages}
                onChange={this.handleChange.bind(this)}
              />
              <SelectComponent
                id={"default_language"}
                label={"Default Languages"}
                multiple={false}
                value={default_language}
                items={available_languages}
                onChange={this.handleChange.bind(this)}
              />
            </div>
          ) : (
            ""
          )}
          <NestedList
            data={replyConfig}
            width={"full"}
            hideFilterLanguages={true}
            scroll={true}
            availableLanguages={available_languages}
            defaultLanguage={default_language}
            hideSearch={true}
            edit={editConfig}
            status={status}
            saveEvent={this.saveEvent.bind(this)}
            selectedItem={this.selectedItem.bind(this)}
            isAdmin={true}
          />
        </div>
        <DialogContent
          title={"stream"}
          open={openDialogRepliesAccess}
          fullWidth={true}
          close={this.closeDialogRepliesAccess.bind(this)}
        >
          <div>
            <RepliesAccessForm
              replyAccess={replyAccess}
              onChangeRepliesAccessForm={this.onChangeRepliesAccessForm.bind(
                this
              )}
              roleList={roleList}
              userList={userList}
            />
            {this.displayButtonsRepliesAccess()}
          </div>
        </DialogContent>
      </div>
    );
  }

  componentWillUnmount() {
    this.setState({
      replyConfig: [],
    });
  }
}

RepliesConfiguration.propTypes = {
  saveReplyConfig: PropTypes.func,
  saveReplyAccess: PropTypes.func,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  replyConfig: PropTypes.object.isRequired,
  roleList: PropTypes.array.isRequired,
  userList: PropTypes.array.isRequired,
  editable: PropTypes.bool.isRequired,
  editableAccess: PropTypes.bool.isRequired,
};

export default withStyles(styles, { withTheme: true })(RepliesConfiguration);
