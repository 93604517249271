import { setModerationGuidelines } from '../setters/moderationGuidelines';

export const extractModerationGuidelinesList = rawData => {
  const list = {
    columns: [],
    data: []
  };

  if (rawData && rawData.guideline_groups && rawData.guideline_groups.length) {
    list.columns.push({
      title: 'Tag',
      field: 'tag'
    });
    const c = rawData.guideline_groups.map(gg => {
      return {
        title: gg.name,
        field: gg.id
      };
    });
    list.columns = [...list.columns, ...c];
  }

  if (rawData && rawData.items && rawData.items.length) {
    rawData.items.map((mg, key) => {
      list.data.push(setModerationGuidelines(mg, key));
      return true;
    });
  }

  return list;
};
