import React, { Component } from "react";
import PropTypes from "prop-types";
import KeyboardEventHandler from "react-keyboard-event-handler";

import RootRef from "@material-ui/core/RootRef";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";

import AlertDialog from "../commons/dialog/alertDialogComponent";
import ListComponent from "../commons/list/ListComponent";
import CustomizedSnackbar from "../commons/snackBar/snackBarComponent";
import { keyboardEvents } from "../../data/data";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  container: {
    flexGrow: 1,
    overflow: "hidden auto",
    height: "calc(100vh - 90px)",
    borderRadius: 5,
    boxShadow: theme.palette.shadow.shadowAround,
    backgroundColor: theme.palette.backgroundDark,
  },
  submit: {
    marginTop: theme.spacing(3),
    height: 35,
  },
  buttonProgress: {
    color: theme.palette.success[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: -12,
  },
  wrapper: {
    position: "relative",
    width: "80%",
    margin: "auto",
    paddingLeft: 20,
    paddingRight: 30,
  },
  colorPrimary: {
    backgroundColor: theme.palette.orange[50],
  },
  barColorPrimary: {
    backgroundColor: theme.palette.orange[300],
  },
});

class CommentList extends Component {
  constructor(props) {
    super(props);
    this.domRef = React.createRef();
    this.state = {
      index: "0",
      openSnackBar: false,
      openDialogAlertUnhide: false,
      loading: true,
      loadingMore: false,
      disableKeyboard:
        props.disableKeyboard !== undefined ? props.disableKeyboard : false,
    };
  }

  selectedEvent(index) {
    this.setState({
      index: index,
    });
    this.props.onCommentSelect && this.props.onCommentSelect();
    this.sendIndexToParent(index);
  }

  onCloseSnackBar() {
    this.setState({ openSnackBar: false });
  }

  sendIndexToParent(index) {
    if (typeof this.props.getIndexList === "function") {
      this.props.getIndexList(index);
    }
  }

  beforeClassification() {
    const { list } = this.props;
    const { index } = this.state;

    //go down unless last comment
    if (parseInt(index) !== list.length - 1) {
      this.downEvent();
    } else {
      this.selectedEvent(null);
    }
  }

  classificationEvent() {
    const { index } = this.state;
    if (index !== null) {
      //send classification
      if (typeof this.props.classificationEvent === "function") {
        this.props.classificationEvent(
          this.state.index,
          this.beforeClassification.bind(this),
          this.afterClassification.bind(this)
        );
      }
    }
  }

  afterClassification() {
    const { list, totalInStream } = this.props;
    if (
      list &&
      !list.filter((item) => !item.classified).length &&
      totalInStream > 0
    ) {
      this.loadNext();
      return true;
    }
  }

  upEvent() {
    const { index, loading } = this.state;
    const nextIndex = index === "0" ? index : `${parseInt(index) - 1}`;

    //if dom exist
    let dom = this.domRef;

    if (dom && dom.current && index !== nextIndex && !loading) {
      dom = dom.current;

      this.setState({
        index: nextIndex,
      });
      if (
        dom.children[0] &&
        dom.children[0].children[0] &&
        dom.children[0].children[0].children[0] &&
        dom.children[0].children[0].children[0].children[0] &&
        dom.children[0].children[0].children[0].children[0].children[
          nextIndex
        ] &&
        dom.children[0].children[0].children[0].children[0].children[nextIndex]
          .children[0]
      ) {
        dom.children[0].children[0].children[0].children[0].children[
          nextIndex
        ].children[0].scrollIntoView({ block: "center" });
      }
      this.sendIndexToParent(nextIndex);
    }
  }

  downEvent() {
    const { index, loading } = this.state;
    const { list } = this.props;
    const nextIndex =
      list.length === parseInt(index) + 1 ? index : `${parseInt(index) + 1}`;

    //if dom exist
    let dom = this.domRef;

    if (dom && dom.current && index !== nextIndex && !loading) {
      dom = dom.current;

      this.setState({
        index: nextIndex,
      });
      if (
        dom.children[0] &&
        dom.children[0].children[0] &&
        dom.children[0].children[0].children[0] &&
        dom.children[0].children[0].children[0].children[0] &&
        dom.children[0].children[0].children[0].children[0].children[
          nextIndex
        ] &&
        dom.children[0].children[0].children[0].children[0].children[nextIndex]
          .children[0]
      ) {
        dom.children[0].children[0].children[0].children[0].children[
          nextIndex
        ].children[0].scrollIntoView({ block: "center" });
      }
      this.sendIndexToParent(nextIndex);
    }
  }

  removeLoading() {
    this.setState({
      loading: false,
      loadingMore: false,
    });
  }

  componentDidMount() {
    this.removeLoading();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disableKeyboard !== this.props.disableKeyboard) {
      this.setState({
        disableKeyboard:
          this.props.disableKeyboard !== undefined
            ? this.props.disableKeyboard
            : false,
      });
    }

    if (
      (this.props.list.length === 0 ||
        typeof this.props.loadMore === "function") &&
      prevProps.list &&
      this.props.list &&
      prevProps.list !== this.props.list &&
      (prevProps.indexList === "0" || !prevProps.indexList)
    ) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.removeLoading();
        }
      );
    }

    if (
      prevProps.list.length === 0 &&
      this.props.list.length !== 0 &&
      prevProps.list !== this.props.list
    ) {
      this.selectedEvent(this.props.indexList || "0");
    }
  }

  loadNext() {
    this.setState(
      {
        loading: true,
      },
      () => {
        if (typeof this.props.loadNext === "function") {
          // TODO: remove setTimeout
          setTimeout(() => {
            if (this.domRef && this.domRef.current) {
              this.domRef.current.scrollTo(0, 0);
            }
          }, 1000);
          return this.props.loadNext(() => {
            this.removeLoading();
          });
        }
      }
    );
  }

  loadMore() {
    this.setState(
      {
        loadingMore: true,
      },
      () => {
        if (typeof this.props.loadMore === "function") {
          return this.props.loadMore((isEmpty) => {
            if (isEmpty) {
              this.setState({
                openSnackBar: true,
              });
            }
            this.removeLoading();
          });
        }
      }
    );
  }

  enableKeyboardEvents() {
    return keyboardEvents.map((event, key) => {
      return (
        <KeyboardEventHandler
          key={key}
          isDisabled={this.state.loading}
          handleFocusableElements={true}
          handleKeys={event.handleKeys}
          onKeyEvent={() => this.props.tagsEvent(event.name)}
        />
      );
    });
  }

  clickItemFromComment(string, data, callback) {
    const { list } = this.props;
    const { index } = this.state;
    if (
      string === "unhide" &&
      list[index]["alerts"] &&
      list[index]["alerts"].find(
        (alert) =>
          alert.objectType === "hide" &&
          alert.inputNames &&
          !alert.inputNames.includes("hide")
      )
    ) {
      this.setState({
        openDialogAlertUnhide: true,
      });
      return false;
    } else {
      this.props.clickItemFromComment(string, data, callback);
    }
  }

  actionDialogAlertUnhide() {
    this.setState({
      openDialogAlertUnhide: false,
    });
  }

  render() {
    const {
      classes,
      list,
      style,
      translated,
      displayBoth,
      openSnackBar,
      containerClassName,
      actionsMenu,
      loadMore,
      isAddingTag,
      disableKeyboard,
      cursor,
      onChangeSelect,
      updateTranslation,
      mode,
      status,
    } = this.props;
    const { index, loading, loadingMore, openDialogAlertUnhide } = this.state;
    return (
      <div className={classes.root}>
        <RootRef rootRef={this.domRef}>
          <div
            style={style}
            className={`${classes.container} ${containerClassName || ""}`}
          >
            {loading ? (
              <div>
                <LinearProgress
                  classes={{
                    colorPrimary: classes.colorPrimary,
                    barColorPrimary: classes.barColorPrimary,
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <div>
              {list && list.length > 0 ? (
                <ListComponent
                  indexList={index}
                  list={list}
                  isAddingTag={isAddingTag}
                  selectedEvent={this.selectedEvent.bind(this)}
                  classificationEvent={this.classificationEvent.bind(this)}
                  translated={translated}
                  displayBoth={displayBoth}
                  mode={mode}
                  status={status}
                  actionsMenu={actionsMenu}
                  clickItemFromComment={(string, data, callback) =>
                    this.clickItemFromComment(string, data, callback)
                  }
                  onChangeSelect={onChangeSelect}
                  updateTranslation={updateTranslation}
                />
              ) : (
                ""
              )}
              {cursor &&
              loadMore &&
              typeof loadMore === "function" &&
              list.length > 0 ? (
                <div className={classes.wrapper}>
                  <Button
                    onClick={this.loadMore.bind(this)}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loadingMore}
                    className={classes.submit}
                  >
                    {!loadingMore ? "LoadMore" : " "}
                  </Button>
                  {loadingMore && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              ) : (
                ""
              )}

              {!mode && !disableKeyboard && this.enableKeyboardEvents()}

              {!disableKeyboard && (
                <KeyboardEventHandler
                  isDisabled={loading}
                  handleFocusableElements={true}
                  handleKeys={["up"]}
                  onKeyEvent={this.upEvent.bind(this)}
                />
              )}
              {!disableKeyboard && (
                <KeyboardEventHandler
                  isDisabled={loading}
                  handleFocusableElements={true}
                  handleKeys={["down"]}
                  onKeyEvent={this.downEvent.bind(this)}
                />
              )}
              {!disableKeyboard && (
                <KeyboardEventHandler
                  isDisabled={loading}
                  handleFocusableElements={true}
                  handleKeys={["enter", "return"]}
                  onKeyEvent={this.classificationEvent.bind(this)}
                />
              )}
            </div>
          </div>
        </RootRef>
        <AlertDialog
          title="Hide Action found"
          message={
            "You have to remove the tags which are generating a hiding action before unhide this comment."
          }
          buttonCancelText="ok"
          action={this.actionDialogAlertUnhide.bind(this)}
          open={openDialogAlertUnhide}
        />
        <CustomizedSnackbar
          variant="info"
          onClose={this.onCloseSnackBar.bind(this)}
          message={"no other comments loadable"}
          open={openSnackBar}
        />
      </div>
    );
  }
}

CommentList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  loadNext: PropTypes.func,
  cursor: PropTypes.string,
  loadMore: PropTypes.func,
  classificationEvent: PropTypes.func,
  translated: PropTypes.bool,
  displayBoth: PropTypes.bool,
  isAddingTag: PropTypes.bool,
  containerClassName: PropTypes.string,
  style: PropTypes.object,
  getIndexList: PropTypes.func,
  mode: PropTypes.string,
  disableKeyboard: PropTypes.bool,
  actionsMenu: PropTypes.array,
  indexList: PropTypes.string,
  clickItemFromComment: PropTypes.func,
  tagsEvent: PropTypes.func,
  onChangeSelect: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(CommentList);
