// Packages
import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Icon from "@material-ui/core/Icon";

// Components
import InputComponent from "../../commons/input/inputComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  formControlContainer: {
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
  formGroup: {
    width: "50%",
  },
  fileContainer: {
    fontFamily: "sans-serif",
    textAlign: "center",
    display: "flex",
    height: "60px",
    padding: "14px 0",
  },
  fileInput: {
    display: "none",
  },
  customFileUpload: {
    display: "inline-block",
    padding: "6px 12px",
    cursor: "pointer",
    color: "#fff",
    backgroundColor: "#4d88e5",
    fontFamily: "Muli,'Apple Color Emoji',sans-serif",
    fontWeight: "500",
    borderRadius: "4px",
    textTransform: "uppercase",
    height: "35px",
    lineHeight: "23px",
  },
  filePreview: {
    margin: "0 10px",
    display: "flex",
    alignItems: "center",
  },
  fileError: {
    fontFamily: "Muli,'Apple Color Emoji',sans-serif",
    color: "#e06666",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fontSize: 14,
    marginRight: "8px",
  },
}));

const AdbastionReportsForm = (props) => {
  const classes = useStyles();
  const [file, setFile] = useState();

  const displayTextField = (
    name,
    label,
    required,
    disabled,
    multiline,
    fullWidth
  ) => {
    return (
      <InputComponent
        label={label}
        required={required}
        disabled={disabled}
        multiline={multiline}
        fullWidth={fullWidth}
        defaultValue={props.formData[name]}
        onChange={(e) => {
          return props.onChangeFormData(e.target.value, name);
        }}
        error={props.errors[name] ? true : false}
        helperText={props.errors[name]}
        margin="dense"
      />
    );
  };

  const saveFile = (e) => {
    let selectedFile = e.target.files[0];
    setFile(selectedFile);
    props.onChangeFormData(selectedFile, "file");
  };

  return (
    <div className={classes.formControlContainer}>
      <FormControl className={classes.formControl}>
        <FormGroup className={classes.formGroup}>
          {displayTextField("name", "Name", true, false, false, true)}
          {displayTextField(
            "description",
            "Description",
            true,
            false,
            false,
            true
          )}

          <div className={classes.fileContainer}>
            <label className={classes.customFileUpload}>
              <input
                type="file"
                onChange={saveFile}
                className={classes.fileInput}
                accept=".pdf"
              />
              <Icon className={classNames(classes.icon, "fa fa-upload")} />
              Upload file
            </label>
            <div className={classes.filePreview}>
              {file && file.name ? file.name : ""}
            </div>
            <div className={classes.fileError}>{props.errors["file"]}</div>
          </div>
        </FormGroup>
      </FormControl>
    </div>
  );
};

AdbastionReportsForm.propTypes = {
  formData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChangeFormData: PropTypes.func.isRequired,
};

export default AdbastionReportsForm;
