import axios from "axios";

import { SFConstants } from "../../constants/configuration/SFConstants";
import { setHeaders } from "../../setters/auth";

export const fetchStickyFiltersList = (clientShortName) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/sticky-filters`;

  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: SFConstants.FETCH_STICKY_FILTERS_LIST,
    payload: promise,
  });
};

export const addStickyFilter = (clientShortName, data) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/sticky-filters`;

  const promise = axios.post(url, data, {
    headers: setHeaders(),
    addData: data,
  });

  return dispatch({
    type: SFConstants.ADD_STICKY_FILTER,
    payload: promise,
  });
};

export const updateStickyFilter = (clientShortName, index, data) => (
  dispatch
) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/sticky-filters/${data.id}`;

  delete data.index;

  const promise = axios.put(
    url,
    {
      ...data,
      order_number: parseInt(data.order_number),
    },
    {
      headers: setHeaders(),
      editData: data,
      index,
    }
  );

  return dispatch({
    type: SFConstants.UPDATE_STICKY_FILTER,
    payload: promise,
  });
};
