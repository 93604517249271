import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

//import SelectComponent from '../../commons/select/selectComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(0.5),
    backgroundColor: theme.palette.white,
  },
  translateButton: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: 12,
  },
  exampleButton: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: 12,
  },
  stopModerationButton: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: 12,
  },
  selectContainer: {
    width: "100%",
  },
  buttonsContainer: {
    marginTop: theme.spacing(1),
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  loader: {
    color: theme.palette.white,
  },
}));

const ActionsContent = ({
  handleExample,
  handleTranslate,
  handleDisplayBoth,
  handleGroupsTag,
  handleStop,
  translate,
  disableStartModeration,
  displayBoth,
  example,
  itemsGroupsTab,
  groupsTagValues,
  isAddingTag,
  isClassifying,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <div className={classes.selectContainer}>
        <SelectComponent
          id={'groupsTag'}
          label={'Filter Groups Tag Color'}
          attributName={'group'}
          attributId={'group'}
          attributColor={'group_color'}
          multiple={true}
          displayEmpty={true}
          display={2}
          selectedTransparent={true}
          value={groupsTagValues}
          items={itemsGroupsTab}
          onChange={handleGroupsTag}
        />
      </div> */}
      <div className={classes.buttonsContainer}>
        <Button
          onClick={handleExample}
          className={classes.exampleButton}
          variant="contained"
          color="primary"
          size="small"
        >
          {example ? "Hide Examples" : "Display Examples"}
        </Button>
        <Button
          onClick={handleTranslate}
          variant="contained"
          className={classes.translateButton}
          size="small"
        >
          {translate ? "Original translate" : "Translate All"}
        </Button>
        {translate ? (
          <Button
            onClick={handleDisplayBoth}
            className={classes.translateButton}
            size="small"
          >
            {}
            {displayBoth ? "Display One text" : "Display both text"}
          </Button>
        ) : (
          ""
        )}
        <Button
          onClick={handleStop}
          className={classes.stopModerationButton}
          variant="contained"
          color="secondary"
          size="small"
          disabled={isAddingTag || isClassifying}
          startIcon={
            disableStartModeration ? (
              <CircularProgress size={15} className={classes.loader} />
            ) : null
          }
        >
          Stop Moderation
        </Button>
      </div>
    </div>
  );
};

ActionsContent.propTypes = {
  isAddingTag: PropTypes.bool.isRequired,
  isClassifying: PropTypes.bool.isRequired,
  handleExample: PropTypes.func.isRequired,
  handleTranslate: PropTypes.func.isRequired,
  handleDisplayBoth: PropTypes.func.isRequired,
  handleGroupsTag: PropTypes.func.isRequired,
  handleStop: PropTypes.func.isRequired,
  disableStartModeration: PropTypes.bool.isRequired,
  translate: PropTypes.bool.isRequired,
  displayBoth: PropTypes.bool.isRequired,
  itemsGroupsTab: PropTypes.array.isRequired,
  groupsTagValues: PropTypes.array.isRequired,
};

export default ActionsContent;
