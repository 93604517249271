import axios from 'axios';

import { groupsConstants } from '../../constants/configuration/groupsConstants';
import { setHeaders } from '../../setters/auth';

export const fetchGroupList = clientShortName => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/groups`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: groupsConstants.FETCH_GROUP_LIST,
    payload: promise
  });
};

export const addGroup = (clientShortName, data) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/groups`;

  const promise = axios.post(url, data, {
    headers: setHeaders(),
    groupInfo: data
  });

  return dispatch({
    type: groupsConstants.ADD_GROUP,
    payload: promise
  });
};

export const editGroup = (clientShortName, index, data) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/groups/${data.id}`;

  const promise = axios.put(url, data, {
    headers: setHeaders(),
    index: index,
    groupInfo: data
  });

  return dispatch({
    type: groupsConstants.EDIT_GROUP,
    payload: promise
  });
};
