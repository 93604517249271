import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import CopyToClipboard from "../commons/copyToClipboard/CopyToClipboardComponent";
import Tooltip from "../commons/tooltip/TooltipComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    marginRight: 10,
    maxWidth: 300,
    fontSize: 12,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  title: {
    minWidth: 85,
    fontSize: 12,
    textAlign: "left",
  },
}));

const PostDetails = ({
  pageId,
  postId,
  postType,
  pageName,
  targeting,
  viewCount,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="subtitle2" className={classes.title}>
          {"Post ID:"}
        </Typography>
        <div className={classes.content}>
          <Tooltip title={<Typography>{postId}</Typography>}>
            <Typography className={classes.text}>{postId}</Typography>
          </Tooltip>

          <CopyToClipboard text={postId} />
        </div>
      </div>
      {postType ? (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.title}>
            {"Post Type: "}
          </Typography>
          <div className={classes.content}>
            <Tooltip title={<Typography>{postType}</Typography>}>
              <Typography className={classes.text}>{postType}</Typography>
            </Tooltip>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={classes.container}>
        <Typography variant="subtitle2" className={classes.title}>
          {"Asset Name:"}
        </Typography>
        <div className={classes.content}>
          <Typography className={classes.text}>{pageName}</Typography>
        </div>
      </div>
      <div className={classes.container}>
        <Typography variant="subtitle2" className={classes.title}>
          {"Asset ID:"}
        </Typography>
        <div className={classes.content}>
          <Typography className={classes.text}>{pageId}</Typography>
        </div>
      </div>
      {viewCount && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.title}>
            {"View Count:"}
          </Typography>
          <div className={classes.content}>
            <Typography className={classes.text}>{viewCount}</Typography>
          </div>
        </div>
      )}
      {targeting && targeting.countries && targeting.countries.length ? (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.title}>
            {"Targeting:"}
          </Typography>
          <div className={classes.content}>
            <Typography className={classes.text}>
              {targeting && targeting.countries
                ? targeting.countries.toString()
                : ""}
            </Typography>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

PostDetails.propTypes = {
  postId: PropTypes.string.isRequired,
  postType: PropTypes.string,
  pageName: PropTypes.string.isRequired,
  targeting: PropTypes.array.isRequired,
};

export default PostDetails;
