import { explorerConstants } from "../constants/explorerConstants";

import {
  extractExplorerList,
  extractExportFields,
} from "../extractors/explorerExtractor";

import { extractClientList } from "../extractors/clientsExtractor";

const initialState = {
  fetching: false,
  fetched: false,
  list: [],
  language: [],
  printList: [],
  clients: [],
  exportLimit: 1000,
  exportFields: {},
  tags: [],
  analyzeTemplates: [],
  total: null,
  cursor: null,
  error: null,
};

const Explorer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_CLIENTS
    case `${explorerConstants.FETCH_CLIENTS}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${explorerConstants.FETCH_CLIENTS}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${explorerConstants.FETCH_CLIENTS}_FULFILLED`:
      let clientList = extractClientList(action.payload.data.items, "explorer");
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetchComment: true,
        clients: clientList,
      };

    // GET_COMMENT_LIST_BY_CLIENT
    case `${explorerConstants.GET_COMMENT_LIST_BY_CLIENT}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${explorerConstants.GET_COMMENT_LIST_BY_CLIENT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${explorerConstants.GET_COMMENT_LIST_BY_CLIENT}_FULFILLED`:
      let explorerList = extractExplorerList(
        action.payload.data,
        action.payload.config.highlighted_tags
      );
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetchComment: true,
        list: action.payload.config.options.print
          ? state.list
          : state.list.concat(explorerList["list"]),
        printList: action.payload.config.options.print ? explorerList.list : [],
        tags: action.payload.data.tags,
        total: action.payload.data.total,
        cursor: action.payload.data.cursor,
      };

    //FETCH HISTORY FROM COMMENT
    case `${explorerConstants.FETCH_HISTORY_FROM_COMMENT}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${explorerConstants.FETCH_HISTORY_FROM_COMMENT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${explorerConstants.FETCH_HISTORY_FROM_COMMENT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            history: action.payload.data.items.length
              ? action.payload.data.items
              : [],
          },
        }),
      };
    // FETCH AUTO COMPLETE LIST
    case `${explorerConstants.GET_AUTOCOMPLETE}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${explorerConstants.GET_AUTOCOMPLETE}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${explorerConstants.GET_AUTOCOMPLETE}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        language: action.payload.data.items,
      };
    // RESET COMMENT LIST
    case `${explorerConstants.RESET_COMMENT_LIST}`:
      return {
        ...state,
        fetchComment: false,
        list: [],
        post: {},
      };
    // UPDATE_CLIENTS_COMMENTS_TRANSLATION
    case `${explorerConstants.UPDATE_CLIENTS_COMMENTS_TRANSLATION}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${explorerConstants.UPDATE_CLIENTS_COMMENTS_TRANSLATION}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${explorerConstants.UPDATE_CLIENTS_COMMENTS_TRANSLATION}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            message: action.payload.data.message,
            messageTranslated: action.payload.data.messageTranslated,
          },
        }),
      };
    // FETCH_EXPORT_FIELDS
    case `${explorerConstants.FETCH_EXPORT_FIELDS}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${explorerConstants.FETCH_EXPORT_FIELDS}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${explorerConstants.FETCH_EXPORT_FIELDS}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        exportFields: extractExportFields(action.payload.data),
      };

    //FETCH CLIENTS POST ADS
    case `${explorerConstants.FETCH_CLIENTS_POST_ADS_EXPLORER}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${explorerConstants.FETCH_CLIENTS_POST_ADS_EXPLORER}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${explorerConstants.FETCH_CLIENTS_POST_ADS_EXPLORER}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            post: {
              ...state.list[action.payload.config.index]["post"],
              ads: action.payload.data,
            },
          },
        }),
      };

    // ON_CHANGE_EXPORT_DATA
    case `${explorerConstants.ON_CHANGE_EXPORT_DATA}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${explorerConstants.ON_CHANGE_EXPORT_DATA}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${explorerConstants.ON_CHANGE_EXPORT_DATA}_FULFILLED`:
      if (action.payload.key !== null) {
        return {
          ...state,
          fetching: false,
          fetched: true,
          exportFields: {
            ...state.exportFields,
            [action.payload.group[0]]: Object.assign([], {
              ...state.exportFields[action.payload.group[0]],
              [action.payload.key]: {
                ...state.exportFields[action.payload.group[0]][
                  action.payload.key
                ],
                checked: !state.exportFields[action.payload.group[0]][
                  action.payload.key
                ]["checked"],
              },
            }),
          },
        };
      } else {
        if (action.payload.group !== null) {
          return {
            ...state,
            fetching: false,
            fetched: true,
            exportFields: {
              ...state.exportFields,
              [action.payload.group[0]]: state.exportFields[
                action.payload.group[0]
              ].map((item) => {
                return {
                  ...item,
                  checked: action.payload.value,
                };
              }),
            },
          };
        } else {
          return {
            ...state,
            fetching: false,
            fetched: true,
            exportLimit:
              action.payload.options && action.payload.options.limit
                ? action.payload.options.limit
                : state.exportLimit,
          };
        }
      }

    // FETCH_ANALYZE_TEMPLATES
    case `${explorerConstants.FETCH_ANALYZE_TEMPLATES}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${explorerConstants.FETCH_ANALYZE_TEMPLATES}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${explorerConstants.FETCH_ANALYZE_TEMPLATES}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        analyzeTemplates: action.payload.data.items,
      };

    default:
      return state;
  }
};

export default Explorer;
