//WEBSOCKET ALERT
export const alert = data => ({
  type: 'ALERT_APP_BAR',
  data: data
});

//WEBSOCKET NOTIFICATION
export const notification = data => ({
  type: 'NOTIFICATION_BE',
  data: data
});
