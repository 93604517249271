export const wsConnect = host => dispatch => {
  return dispatch({
    type: 'WS_CONNECT',
    host
  });
};

export const wsConnecting = host => dispatch => {
  return dispatch({
    type: 'WS_CONNECTING',
    host
  });
};

export const wsConnected = host => dispatch => {
  return dispatch({
    type: 'WS_CONNECTED',
    host
  });
};

export const wsDisconnect = host => dispatch => {
  return dispatch({
    type: 'WS_DISCONNECT',
    host
  });
};

export const wsDisconnected = host => dispatch => {
  return dispatch({
    type: 'WS_DISCONNECTED',
    host
  });
};
