import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import TableComponent from "../../commons/table/tableComponent";
import DialogContent from "../../commons/dialog/contentDialogComponent";

import TagsForm from "./tagsForm";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 7,
  },
  buttonAddTag: {
    marginLeft: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  detailContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  detailItem: {
    width: "33%",
    margin: theme.spacing(1),
    backgroundColor: theme.palette.container.light,
  },
  detailLegend: {
    padding: theme.spacing(1),
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
  },
  detailContent: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    height: 100,
    overflow: "auto",
  },
});

class TagsConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagSelected: 0,
      openDialogTags: false,
      mode: "add",
      defaultValue: {},
      error: {},
      editable: props.editable,
      creatable: props.creatable,
      columns: [
        {
          title: "ID",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          field: "id",
        },
        {
          title: "Label",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          field: "label",
        },
        {
          title: "Group",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          field: "group",
        },
        {
          title: "Category",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          field: "tag_category_name",
        },
      ],
      data: props.tagList || [],
      actions: this.onCreateActions(props.editable),
      tag: {},
      detailPanel: [
        {
          tooltip: "Show Tag Details",
          render: (rowData) => this.showTagDetails(rowData),
        },
      ],
    };
  }

  showTagDetails(rowData) {
    const { classes } = this.props;
    return (
      <div className={classes.detailContainer}>
        <div className={classes.detailItem}>
          <div className={classes.detailLegend}>{"Description"}</div>
          <div className={classes.detailContent}>{rowData.description}</div>
        </div>
        <div className={classes.detailItem}>
          <div className={classes.detailLegend}>{"Example"}</div>
          <div className={classes.detailContent}>{rowData.example}</div>
        </div>
        <div className={classes.detailItem}>
          <div className={classes.detailLegend}>{"Excluded Example"}</div>
          <div className={classes.detailContent}>
            {rowData.excluded_example}
          </div>
        </div>
      </div>
    );
  }

  onCreateActions(editable) {
    return editable
      ? [
          {
            icon: "edit",
            tooltip: "Edit Tag",
            onClick: (event, rowData) => this.actionEditTag(event, rowData),
          },
        ]
      : [];
  }

  onChangeTagForm(data) {
    this.setState({ tag: data });
  }

  checkForErrors() {
    const { tag } = this.state;

    const promise = new Promise((resolve, reject) => {
      this.setState(
        {
          error: {
            id: tag.id ? "" : "ID is mandatory",
            label: tag.label ? "" : "Label is mandatory",
            description: tag.description ? "" : "Description is mandatory",
            group: tag.group ? "" : "Group is mandatory",
          },
        },
        () => {
          resolve();
        }
      );
    });

    return promise;
  }

  updateTag() {
    this.checkForErrors().then(() => {
      if (
        !Object.values(this.state.error).filter((value) => value !== "").length
      ) {
        if (this.state.mode === "add") {
          this.props.addTag(this.state.tag);
        } else {
          this.props.editTag(this.state.tagSelected, this.state.tag);
        }
        this.closeDialogTags();
      }
    });
  }

  openDialogTags() {
    this.setState({
      openDialogTags: true,
    });
  }

  closeDialogTags() {
    this.setState({
      openDialogTags: false,
    });
  }

  actionAddTag(e) {
    const defaultValue = {
      id: "",
      label: "",
      description: "-",
      group: "-",
      example: "-",
      excluded_example: "-",
      tag_category: "non_harmful",
      volume_based_alert: {
        enabled: false,
        threshold: null,
        time_interval: null,
        timeout: null,
      },
      pim_tone: "",
      pim_weight: null,
    };

    this.setState(
      {
        mode: "add",
        defaultValue: defaultValue,
        tag: defaultValue,
      },
      () => {
        this.openDialogTags();
      }
    );
  }

  actionEditTag(e, data) {
    const defaultValue = {
      id: data.id,
      label: data.label,
      description: data.description,
      group: data.group,
      example: data.example,
      excluded_example: data.excluded_example,
      tag_category: data.tag_category ? data.tag_category : "",
      volume_based_alert: data.volume_based_alert || {
        enabled: false,
        threshold: null,
        time_interval: null,
        timeout: null,
      },
      pim_tone: data.pim_tone,
      pim_weight: data.pim_weight,
    };

    this.setState(
      {
        mode: "edit",
        tagSelected: data.tableData.id,
        defaultValue: defaultValue,
        tag: defaultValue,
      },
      () => {
        this.openDialogTags();
      }
    );
  }

  displayButtonsTag() {
    const { classes } = this.props;
    const { mode } = this.state;
    return (
      <div className={classes.buttonContainer}>
        <Button
          size="small"
          color="primary"
          onClick={(e) => {
            return this.updateTag();
          }}
        >
          {mode === "add" ? "Add tag" : "Edit tag"}
        </Button>
        <Button
          onClick={(e) => {
            return this.closeDialogTags(e);
          }}
          size="small"
          color="secondary"
        >
          Cancel
        </Button>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tagList !== this.props.tagList) {
      this.setState({
        data: this.props.tagList,
      });
    }

    if (prevProps.creatable !== this.props.creatable) {
      this.setState({
        creatable: this.props.creatable,
      });
    }

    if (prevProps.editable !== this.props.editable) {
      this.setState({
        editable: this.props.editable,
        actions: this.onCreateActions(this.props.editable),
      });
    }
  }

  render() {
    const { classes, creatable } = this.props;
    const {
      columns,
      data,
      actions,
      detailPanel,
      openDialogTags,
      defaultValue,
      error,
      mode,
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h5">Tag list</Typography>
          {creatable ? (
            <Button
              variant="contained"
              className={classes.buttonAddTag}
              size="small"
              color="primary"
              onClick={(e) => {
                return this.actionAddTag(e);
              }}
            >
              Add tag
            </Button>
          ) : (
            ""
          )}
        </div>
        <TableComponent
          editable={false}
          columns={columns}
          data={data}
          actions={actions}
          title=""
          detailPanel={detailPanel}
        />
        <DialogContent
          title={"Tag"}
          open={openDialogTags}
          fullWidth={true}
          close={this.closeDialogTags.bind(this)}
        >
          <div>
            <TagsForm
              mode={mode}
              defaultValue={defaultValue}
              onChangeTagForm={this.onChangeTagForm.bind(this)}
              error={error}
            />
            {this.displayButtonsTag()}
          </div>
        </DialogContent>
      </div>
    );
  }
}

TagsConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  addTag: PropTypes.func.isRequired,
  editTag: PropTypes.func.isRequired,
  tagList: PropTypes.array,
  editable: PropTypes.bool,
  creatable: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(TagsConfiguration);
