export const moderationConstants = {
  GET_COMMENT_LIST: 'GET_COMMENT_LIST',
  SET_TAG_TO_COMMENT: 'SET_TAG_TO_COMMENT',
  UPDATE_COMMENT_STATUS: 'UPDATE_COMMENT_STATUS',
  RESET_COMMENT_LIST: 'RESET_COMMENT_LIST',
  COMMENT_ADD_TIME: 'COMMENT_ADD_TIME',
  MODERATION_END_EVENT: 'MODERATION_END_EVENT',
  CLASSIFICATION_EVENT: 'CLASSIFICATION_EVENT',
  IS_CLASSIFYING_EVENT: 'IS_CLASSIFYING_EVENT',
  UPDATE_CLIENTS_COMMENTS_TRANSLATION: 'UPDATE_CLIENTS_COMMENTS_TRANSLATION',
  FETCH_HISTORY_FROM_COMMENT: 'FETCH_HISTORY_FROM_COMMENT',
  FETCH_CLIENTS_POST_ADS_MODERATION: 'FETCH_CLIENTS_POST_ADS_MODERATION',
  UNLIKE_COMMENT: 'UNLIKE_COMMENT',
  IS_FAVORITE_EVENT: 'IS_FAVORITE_EVENT'
};
