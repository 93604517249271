import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { makeStyles, useTheme } from '@material-ui/core/styles';

import TableComponent from '../../commons/table/tableComponent';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  }
}));

const LinkedAccountsConfiguration = props => {
  const classes = useStyles();
  const theme = useTheme();

  // Init
  const { linkedAccountsList } = props;

  // Table schema
  const tableColumns = [
    {
      title: 'Parent ID',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'parent_id'
    },
    {
      title: 'Asset ID',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'asset_id'
    },
    {
      title: 'Asset Name',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'asset_name'
    },
    {
      title: 'Asset Type',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'asset_type'
    },
    {
      title: 'Asset Status',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'asset_status'
    },
    {
      title: 'Created Time',
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      field: 'created_time'
    }
  ];

  return (
    <div className={classes.root}>
      <TableComponent
        editable={false}
        columns={tableColumns}
        data={linkedAccountsList}
        title=""
      />
    </div>
  );
};

LinkedAccountsConfiguration.propTypes = {
  linkedAccountsList: PropTypes.array.isRequired
};

export default LinkedAccountsConfiguration;
