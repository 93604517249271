import { assetManagementConstants } from '../../constants/configuration/assetManagementConstants';

import { extractAssetManagementList } from '../../extractors/assetManagementExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  assetManagementList: [],
  error: null
};

const assetManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_ASSET_MANAGEMENT_LIST
    case `${assetManagementConstants.FETCH_ASSET_MANAGEMENT_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${assetManagementConstants.FETCH_ASSET_MANAGEMENT_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${assetManagementConstants.FETCH_ASSET_MANAGEMENT_LIST}_FULFILLED`:
      const assetManagementList = extractAssetManagementList(
        action.payload.data
      );
      return {
        ...state,
        fetching: false,
        fetched: true,
        assetManagementList
      };

    // FETCH_ASSET_MANAGEMENT
    case `${assetManagementConstants.FETCH_ASSET_MANAGEMENT}_PENDING`:
      return {
        ...state,
        fetching: true,
        detailFetching: true
      };
    case `${assetManagementConstants.FETCH_ASSET_MANAGEMENT}_ERROR`:
      return {
        ...state,
        fetching: false,
        detailFetching: false,
        error: action.payload.data
      };
    case `${assetManagementConstants.FETCH_ASSET_MANAGEMENT}_FULFILLED`:
      const asset = extractAssetManagementList([action.payload.data])[0];
      return {
        ...state,
        fetching: false,
        detailFetching: false,
        fetched: true,
        assetManagementList: Object.assign([], {
          ...state.assetManagementList,
          [action.payload.config.index]: asset
        })
      };

    // ASSIGN_CLIENT_SHORTNAME
    case `${assetManagementConstants.ASSIGN_CLIENT_SHORTNAME}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${assetManagementConstants.ASSIGN_CLIENT_SHORTNAME}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${assetManagementConstants.ASSIGN_CLIENT_SHORTNAME}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        assetManagementList: Object.assign([], {
          ...state.assetManagementList,
          [action.payload.config.index]: {
            ...state.assetManagementList[action.payload.config.index],
            clientShortName: action.payload.config.clientShortName
          }
        })
      };

    default:
      return state;
  }
};

export default assetManagementReducer;
