import { setDateFormatTimeStamp } from './date';

export const setLinkedAccount = (la, key = null) => {
  const item = {
    parent_id: la.parent_id ? la.parent_id : '',
    asset_id: la.asset_id ? la.asset_id : '',
    asset_name: la.asset_name ? la.asset_name : '',
    asset_type: la.asset_type ? la.asset_type : '',
    asset_status: la.asset_status ? la.asset_status : '',
    created_time: la.created_time ? setDateFormatTimeStamp(la.created_time) : ''
  };

  return item;
};
