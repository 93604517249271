import axios from "axios";

import { setHeaders } from "../setters/auth";

// GET CLIENT LIST
export const getStatsSuccess = (data) => ({
  type: "GET_STATS_SUCCESS",
  payload: data,
});
export const getStatsFailure = (error) => ({
  type: "GET_STATS_FAILURE",
  payload: error,
});

export const getStats = () => (dispatch) => {
  const url = `${process.env.URL_API_ENDPOINT}/alert/stats`;

  dispatch({ type: "GET_STATS" });

  return axios
    .get(url, {
      headers: setHeaders(),
    })
    .then((response) => dispatch(getStatsSuccess(response.data)))
    .catch((error) => dispatch(getStatsFailure(error)));
};

// GET ALERT LIST
export const getClientAlertsSuccess = (data) => ({
  type: "GET_CLIENT_ALERTS_SUCCESS",
  payload: data,
});
export const getClientAlertsPageSuccess = (data) => ({
  type: "GET_CLIENT_ALERTS_PAGE_SUCCESS",
  payload: data,
});
export const getClientAlertsFailure = (error) => ({
  type: "GET_CLIENT_ALERTS_FAILURE",
  payload: error,
});

export const getClientAlerts = (clientShortName, cursor = null) => (
  dispatch
) => {
  const params = cursor ? `?cursor=${cursor}` : "";
  const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/alerts${params}`;

  dispatch({ type: "GET_CLIENT_ALERTS" });

  return axios
    .get(url, {
      headers: setHeaders(),
    })
    .then((response) => {
      if (cursor) {
        return dispatch(getClientAlertsPageSuccess(response.data));
      }

      return dispatch(getClientAlertsSuccess(response.data));
    })
    .catch((error) => dispatch(getClientAlertsFailure(error)));
};

// ISSUE ALERT
const issueAlertSuccess = (selectedCommentIndex) => ({
  type: "ISSUE_ALERT_SUCCESS",
  payload: selectedCommentIndex,
});
const issueAlertFailure = (error) => ({
  type: "ISSUE_ALERT_FAILURE",
  payload: error,
});

export const issueAlert = (
  clientShortName,
  commentId,
  selectedCommentIndex
) => (dispatch) => {
  const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/comments/${commentId}/alert/mark-sent`;

  dispatch({ type: "ISSUE_ALERT" });

  return axios
    .patch(url, null, {
      headers: setHeaders(),
    })
    .then(() => dispatch(issueAlertSuccess(selectedCommentIndex)))
    .catch((error) => dispatch(issueAlertFailure(error)));
};

// CANCEL ALERT
const cancelAlertSuccess = (selectedCommentIndex) => ({
  type: "CANCEL_ALERT_SUCCESS",
  payload: selectedCommentIndex,
});
const cancelAlertFailure = (error) => ({
  type: "CANCEL_ALERT_FAILURE",
  payload: error,
});

export const cancelAlert = (
  clientShortName,
  commentId,
  reason,
  selectedCommentIndex
) => (dispatch) => {
  const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/comments/${commentId}/alert/cancel`;

  dispatch({ type: "CANCEL_ALERT" });

  return axios
    .patch(
      url,
      { reason },
      {
        headers: setHeaders(),
      }
    )
    .then(() => dispatch(cancelAlertSuccess(selectedCommentIndex)))
    .catch((error) => dispatch(cancelAlertFailure(error)));
};

// HISTORY
const getCommentHistorySuccess = (data, selectedCommentIndex) => ({
  type: "GET_HISTORY_FROM_COMMENT_SUCCESS",
  payload: { data, selectedCommentIndex },
});

const getCommentHistoryFailure = (error) => ({
  type: "GET_HISTORY_FROM_COMMENT_FAILURE",
  payload: error,
});

export const fetchHistoryFromComment = (
  clientShortName,
  commentId,
  selectedCommentIndex,
  options = {}
) => (dispatch) => {
  const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/comments/${commentId}/history`;

  return axios
    .get(url, {
      headers: setHeaders(),
      commentId: commentId,
      index: options.index ? options.index : null,
    })
    .then((response) =>
      dispatch(getCommentHistorySuccess(response.data, selectedCommentIndex))
    )
    .catch((error) => dispatch(getCommentHistoryFailure(error)));
};
