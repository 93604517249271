import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
import Icon from "@material-ui/core/Icon";

import AppBar from "../../components/commons/appBar/AppBarComponent";
import LeftMenu from "../../components/commons/leftMenu/leftMenuComponent";

import { fetchMe } from "../../actions/meAction";

import { capitalizeUsername } from "../../utils/utils";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  gridRoot: {
    flexGrow: 1,
  },
  content: {
    width: "100%",
    justifyContent: "center",
    paddingTop: 48,
  },
  header: {
    background: theme.palette.backgroundDark,
    boxShadow: theme.palette.shadow.bottom,
  },
  legend: {
    height: 240,
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    textAlign: "left",
  },
  itemContainer: {
    marginTop: theme.spacing(6),
  },
  card: {
    margin: theme.spacing(4),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  cardIcon: {
    fontSize: 100,
    minWidth: 130,
    minHeight: 115,
    padding: 3,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  cardTitle: {
    color: theme.palette.container.dark,
    fontSize: 12,
  },
  loading: {
    margin: "auto",
    marginTop: "calc(50vh - 96px)",
    color: theme.palette.orange[300],
  },
  legendTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(10),
  },
  welcomeContainer: {
    display: "flex",
  },
  welcomeName: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    fontWeight: "bold",
    fontStyle: "italic",
  },
});

const mapStateToProps = (state) => ({
  me: state.meReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMe: () => dispatch(fetchMe()),
});

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      me: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.me.me !== prevState.me) {
      return {
        me: nextProps.me.me,
      };
    }

    return null;
  }

  logout() {
    this.props.signOut();
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    this.props.fetchMe();
  }

  generateCard(data) {
    const { classes } = this.props;
    return (
      <Link underline="none" component={RouterLink} to={data.href}>
        <Card className={classes.card}>
          <CardActionArea>
            <CardContent className={classes.cardContent}>
              {data.iconType === "fontAwsome" ? (
                <Icon
                  className={classNames(classes.cardIcon, {
                    [data.icon]: true,
                  })}
                />
              ) : (
                <Icon className={classes.cardIcon}>{data.icon}</Icon>
              )}
              <Typography className={classes.cardTitle} gutterBottom>
                {data.title}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    );
  }

  render() {
    const { classes } = this.props;
    const { open, me } = this.state;
    return (
      <div className={classes.root}>
        <AppBar
          action={this.handleDrawerOpen}
          status={open}
          selected={"home"}
          logout={this.logout.bind(this)}
        />
        <LeftMenu open={open} onClose={this.handleDrawerClose.bind(this)} />
        <main className={classes.content}>
          {me.username ? (
            <Grid className={classes.gridRoot} container>
              <Grid item xs={12} className={classes.header}>
                <div className={classes.legend}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className={classes.legendTitle}>
                        <Typography variant="h5" gutterBottom>
                          <b>C</b>LASSIFICATION <b>A</b>ND <b>M</b>ODERATION{" "}
                          <b>P</b>LATFORM
                        </Typography>
                        <div className={classes.welcomeContainer}>
                          <Typography>Welcome</Typography>
                          <Typography className={classes.welcomeName}>
                            {capitalizeUsername(me.username)}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center">
                  <Grid item xs={12}>
                    <div className={classes.itemContainer}>
                      <Grid container justify="center">
                        <Grid item>
                          {this.generateCard({
                            icon: "fas fa-shield-alt",
                            iconType: "fontAwsome",
                            title: "MODERATION",
                            href: "/moderation",
                          })}
                        </Grid>
                        <Grid item>
                          {this.generateCard({
                            icon: "fas fa-exclamation-triangle",
                            iconType: "fontAwsome",
                            title: "ALERTS",
                            href: "/alerts",
                          })}
                        </Grid>
                        <Grid item>
                          {this.generateCard({
                            icon: "fas fa-globe",
                            iconType: "fontAwsome",
                            title: "IP PROTECTION",
                            href: "/copyright",
                          })}
                        </Grid>
                        <Grid item>
                          {this.generateCard({
                            icon: "search",
                            title: "EXPLORER",
                            href: "/explorer",
                          })}
                        </Grid>
                        <Grid item>
                          {this.generateCard({
                            icon: "far fa-comments",
                            iconType: "fontAwsome",
                            title: "CUSTOMER ENGAGEMENT",
                            href: "/reply",
                          })}
                        </Grid>
                        {me.permissions &&
                        me.permissions.includes("admin_access") ? (
                          <Grid item>
                            {this.generateCard({
                              icon: "fas fa-wrench",
                              iconType: "fontAwsome",
                              title: "ADMIN",
                              href: "/admin",
                            })}
                          </Grid>
                        ) : (
                          ""
                        )}
                        {me.permissions &&
                        me.permissions.includes("sales_access") ? (
                          <Grid item>
                            {this.generateCard({
                              icon: "fas fa-user-tie",
                              iconType: "fontAwsome",
                              title: "SALES",
                              href: "/sales",
                            })}
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <CircularProgress className={classes.loading} />
          )}
        </main>
      </div>
    );
  }
}

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(HomePage));
