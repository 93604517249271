import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import CircularProgress from '@material-ui/core/CircularProgress';

import InputComponent from '../../commons/input/inputComponent';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  formControlContainer: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  formGroup: {
    width: '50%'
  },
  platforms: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  platform: {
    margin: 2
  }
});

class TagsExampleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: props.defaultValue
        ? props.defaultValue
        : {
            tag: '',
            example: '-',
            excluded_example: '-'
          },
      error: props.error
        ? props.error
        : {
            tag: '',
            example: '',
            excluded_example: ''
          }
    };
  }

  onChange(e, value) {
    this.setState(
      {
        data: {
          ...this.state.data,
          [value]: e.target.value
        }
      },
      () => {
        this.props.onChangeTagsExampleForm(this.state.data);
      }
    );
  }

  displayTextField(
    value,
    label,
    required = false,
    isDisabled = false,
    multiline
  ) {
    const { classes, defaultValue, mode } = this.props;
    const { data, error } = this.state;
    return (
      <FormGroup className={classes.formGroup}>
        <InputComponent
          multiline={multiline}
          required={required}
          disabled={isDisabled && mode === 'edit' ? true : false}
          defaultValue={
            Object.values(defaultValue).length
              ? defaultValue[value]
              : data[value]
          }
          onChange={e => {
            return this.onChange(e, value);
          }}
          error={error[value] ? true : false}
          helperText={error[value]}
          label={label}
          margin="dense"
          fullWidth={true}
        />
      </FormGroup>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setState({
        error: this.props.error
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.state;
    return (
      <div className={classes.root}>
        {loading ? (
          <CircularProgress />
        ) : (
          <div className={classes.formControlContainer}>
            <FormControl className={classes.formControl}>
              {this.displayTextField('tag', 'Tag ID', true, true, false)}
              {this.displayTextField('example', 'Example', true, false, true)}
              {this.displayTextField(
                'excluded_example',
                'Excluded Example',
                false,
                false,
                true
              )}
            </FormControl>
          </div>
        )}
      </div>
    );
  }
}

TagsExampleForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onChangeTagsExampleForm: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  error: PropTypes.object,
  mode: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(TagsExampleForm);
