import { setAdbastionReport } from "../setters/adbastionReport";

export const extractAdbastionReportsList = (data) => {
  const adbastionReports = [];
  data.map((adbastionReport) => {
    adbastionReports.push(setAdbastionReport(adbastionReport));
    return true;
  });

  return adbastionReports;
};
