import { setStream, setStreamConfiguration } from "../setters/stream";

export const extractStreams = (data) => {
  const streams = [];

  data &&
    data.map((stream, key) => {
      streams.push(setStream(stream, key));
      return true;
    });

  // Sort streams alphabetically
  streams.sort((a, b) =>
    a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
  );

  return Object.assign([], streams);
};

export const extractStreamsGrouped = (data) => {
  const streamsGrouped = {};

  data &&
    data.sort((a, b) =>
      a.streamGroup !== "{none}"
        ? a.streamGroup.toLowerCase() > b.streamGroup.toLowerCase()
          ? 1
          : -1
        : false
    );
  data &&
    data.map((stream) => {
      const gn = stream.streamGroup;
      streamsGrouped[gn] = streamsGrouped[gn] ? streamsGrouped[gn] : [];
      streamsGrouped[gn].push(stream);

      return true;
    });

  return streamsGrouped;
};

export const extractStreamsConfiguration = (data) => {
  const streams = [];
  data &&
    data.map((stream, key) => {
      streams.push(setStreamConfiguration(stream, key));
      return true;
    });

  return Object.assign([], streams);
};

export const extractKeyFromStream = (streams, streamId) => {
  return (
    streams &&
    streams
      .map((stream, key) => {
        return stream.id === streamId ? key : "";
      })
      .filter((key) => {
        return key !== "";
      })[0]
  );
};
