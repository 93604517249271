import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

import Chip from "@material-ui/core/Chip";
import { operationalTags } from "../../../data/data";

const styles = (theme) => ({
  tagList: {
    flexFlow: "wrap",
    display: "flex",
    marginLeft: "100px",
    alignItems: "center",
  },
  chip: {
    marginLeft: "5px",
    marginRight: "5px",
  },
  tag: {
    margin: "5px",
    marginTop: 0,
    height: "20px",
    fontSize: "10px",
  },
  highlightedTags: {
    backgroundColor: theme.palette.warning[800],
    color: theme.palette.white,
    boxShadow: theme.palette.shadow.shadowDefault,
    height: 25,
    fontSize: 12,
  },
});

function ListItemTags(props) {
  const { classes, tags, highlightedTags } = props;
  return (
    <div className={classes.tagList}>
      {tags
        ? Object.values(tags).map((tag, key) => {
          if (
            tag &&
            !operationalTags.filter((operationalTag) => {
              return operationalTag.action === tag.id;
            }).length &&
            tag.checked
          ) {
            return (
              <Chip
                key={key}
                label={tag.label}
                className={classNames(classes.tag, {
                  [classes[tag.name]]: true,
                  [classes.highlightedTags]: highlightedTags.filter(
                    (highlightedTag) => {
                      return highlightedTag === tag.id;
                    }
                  ).length,
                })}
                component="div"
              />
            );
          }
          return true;
        })
        : ""}
    </div>
  );
}

ListItemTags.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  highlightedTags: PropTypes.array,
  tags: PropTypes.array,
  sentiment: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(ListItemTags);
