import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux
import {
  fetchTaskHistoryList,
  addTask,
  updateFavorite
} from '../../../actions/configuration/taskHistoryAction';

import TaskHistoryConfiguration from './taskHistoryConfiguration';

const mapStateToProps = state => ({
  taskHistoryState: state.taskHistoryReducer
});

const mapDispatchToProps = dispatch => ({
  fetchTaskHistoryList: (clientShortName, isFilterFavorite) =>
    dispatch(fetchTaskHistoryList(clientShortName, isFilterFavorite)),
  addTask: (clientShortName, data) => dispatch(addTask(clientShortName, data)),
  updateFavorite: (clientShortName, index, taskId, isFavorite) =>
    dispatch(updateFavorite(clientShortName, index, taskId, isFavorite))
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 367px)',
    marginTop: 'calc(50vh - 96px)'
  }
}));

const TaskHistoryContainer = props => {
  const classes = useStyles();
  const [isInitialized, setInitialized] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isNotAllowed, setNotAllowed] = useState(false);
  const [isCreatable, setCreatable] = useState(false);
  const [isUpdatable, setUpdatable] = useState(false);
  const [taskHistoryList, setTaskHistoryList] = useState([]);

  const addTask = data => {
    props.addTask(props.clientShortName, data);
  };

  const updateFavorite = (index, taskId, isFavorite) => {
    props.updateFavorite(props.clientShortName, index, taskId, isFavorite);
  };

  const fetchByFilter = filterType => {
    const isFavorite = filterType === 'favorites';
    props.fetchTaskHistoryList(props.clientShortName, isFavorite);
  };

  useEffect(() => {
    if (props.permissions.includes('copyright_admin_get_history')) {
      if (props.tabIndex === 10 && !isInitialized) {
        setLoading(true);
        setInitialized(true);
        props.fetchTaskHistoryList(props.clientShortName, false);
        if (props.permissions.includes('copyright_admin_create_task')) {
          setCreatable(true);
        }
        if (props.permissions.includes('copyright_admin_task_update')) {
          setUpdatable(true);
        }
      }
    } else {
      setNotAllowed(true);
    }
  }, [props.tabIndex]);

  useEffect(() => {
    if (!props.taskHistoryState.fetching) {
      setTaskHistoryList(props.taskHistoryState.taskHistoryList);
      setLoading(false);
    }
  }, [props.taskHistoryState.fetching]);

  return (
    <div className={classes.root}>
      {!isNotAllowed && !isLoading ? (
        <TaskHistoryConfiguration
          isCreatable={isCreatable}
          isUpdatable={isUpdatable}
          taskHistoryList={taskHistoryList}
          addTask={addTask}
          updateFavorite={updateFavorite}
          fetchByFilter={fetchByFilter}
        />
      ) : !isNotAllowed ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <Typography className={classes.notAllowed} variant="body1">
          Not Allowed
        </Typography>
      )}
    </div>
  );
};

TaskHistoryContainer.propTypes = {
  clientShortName: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskHistoryContainer);
