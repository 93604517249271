import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import AssetsConfiguration from './assetsConfiguration';

import { fetchPlatformList } from '../../../actions/configuration/platformsAction';
import {
  fetchAssetList,
  addAsset,
  editAsset
} from '../../../actions/configuration/assetsActions';

const styles = theme => ({
  root: {
    width: '100%'
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 440px)',
    marginTop: 'calc(50vh - 96px)'
  }
});

const mapStateToProps = state => ({
  platforms: state.platformsReducer,
  assets: state.assetsReducer
});

const mapDispatchToProps = dispatch => ({
  fetchPlatformList: () => dispatch(fetchPlatformList()),
  fetchAssetList: clientShortName => dispatch(fetchAssetList(clientShortName)),
  addAsset: (clientShortName, data) =>
    dispatch(addAsset(clientShortName, data)),
  editAsset: (clientShortName, index, data) =>
    dispatch(editAsset(clientShortName, index, data))
});

class AssetsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
      clientShortName: props.clientShortName,
      loading: true,
      notAllowed: false,
      creatable: false,
      editable: false,
      platformList: [],
      assetList: []
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.platforms.platformList !== prevState.platformList) {
      update.platformList = nextProps.platforms.platformList;
    }

    if (nextProps.assets.assetList !== prevState.assetList) {
      update.assetList = nextProps.assets.assetList;
    }

    return Object.keys(update).length ? update : null;
  }

  addAsset(data) {
    this.props.addAsset(this.state.clientShortName, data);
  }

  editAsset(index, data) {
    this.props.editAsset(this.state.clientShortName, index, data);
  }

  initialize() {
    if (this.props.permissions.includes('admin_list_client_assets')) {
      this.props.fetchPlatformList().then(() => {
        this.props.fetchAssetList(this.state.clientShortName).then(() => {
          this.setState({
            loading: false
          });
        });
      });

      if (this.props.permissions.includes('admin_update_client_asset')) {
        this.setState({
          editable: true
        });
      }

      if (this.props.permissions.includes('admin_create_client_asset')) {
        this.setState({
          creatable: true
        });
      }
    } else {
      this.setState({
        notAllowed: true,
        loading: false
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tabIndex === 2 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true
        },
        this.initialize
      );
    }
  }

  componentDidMount() {
    if (this.props.tabIndex === 2 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true
        },
        this.initialize
      );
    }
  }

  render() {
    const { classes } = this.props;
    const {
      loading,
      notAllowed,
      editable,
      creatable,
      assetList,
      platformList,
      clientShortName
    } = this.state;

    return (
      <div className={classes.root}>
        {!notAllowed && !loading ? (
          <AssetsConfiguration
            editable={editable}
            creatable={creatable}
            addAsset={this.addAsset.bind(this)}
            editAsset={this.editAsset.bind(this)}
            assetList={assetList}
            platformList={platformList}
            clientShortName={clientShortName}
          />
        ) : !notAllowed ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <Typography className={classes.notAllowed} variant="body1">
            Not Allowed
          </Typography>
        )}
      </div>
    );
  }
}

AssetsContainer.propTypes = {
  clientShortName: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  tabIndex: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AssetsContainer));
