import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TableComponent from '../../commons/table/tableComponent';
import DialogContent from '../../commons/dialog/contentDialogComponent';

import TagsExampleForm from './tagsExampleForm';

import { downloadAsJson } from '../../../utils/utils';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 7
  },
  buttonAddTagsExample: {
    marginLeft: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  platform: {
    marginLeft: 2,
    marginRight: 2
  },
  filters: {
    display: 'flex',
    marginBottom: theme.spacing(3)
  },
  buttonDownload: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0),
    color: theme.palette.black,
    padding: 0
  }
});

class TagsExampleConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagsExampleSelected: 0,
      openDialogTagsExample: false,
      mode: 'add',
      defaultValue: {},
      error: {},
      editable: props.editable,
      tagList: props.tagList || [],
      columns: [
        {
          title: 'Tag',
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14
          },
          field: 'tag',
          render: rowData => this.displayTags(rowData.tag)
        },
        {
          title: 'Example',
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14
          },
          field: 'example',
          render: rowData => this.displayExampleText(rowData.example)
        },
        {
          title: 'Excluded example',
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14
          },
          field: 'excluded_example',
          render: rowData => this.displayExampleText(rowData.excluded_example)
        }
      ],
      data: props.tagsExampleList || [],
      actions: this.onCreateActions(props.editable),
      tagsExample: {}
    };
  }

  displayExampleText(text) {
    return text && text.length > 50 ? `${text.slice(0, 30)}...` : text;
  }

  displayTags(tagId) {
    const { classes } = this.props;
    const { tagList } = this.state;

    const tag = tagList
      ? tagList.find(tag => {
          return tagId === tag.id;
        })
      : '';

    return (
      <Chip className={classes.tag} label={tag && tag.label ? tag.label : ''} />
    );
  }

  onCreateActions(editable) {
    return editable
      ? [
          {
            icon: 'edit',
            tooltip: 'Edit Example',
            onClick: (event, rowData) =>
              this.actionEditTagsExample(event, rowData)
          }
        ]
      : [];
  }

  onChangeTagsExampleForm(data) {
    this.setState({ tagsExample: data });
  }

  checkForErrors() {
    const { tagsExample } = this.state;

    const promise = new Promise((resolve, reject) => {
      this.setState(
        {
          error: {
            tag: tagsExample.tag ? '' : 'Tag is mandatory',
            example: tagsExample.example ? '' : 'Example is mandatory'
          }
        },
        () => {
          resolve();
        }
      );
    });

    return promise;
  }

  updateTagsExample() {
    this.checkForErrors().then(() => {
      if (
        !Object.values(this.state.error).filter(value => value !== '').length
      ) {
        if (this.state.mode === 'add') {
          this.props.addTagsExample(this.state.tagsExample);
        } else {
          this.props.editTagsExample(
            this.state.tagsExampleSelected,
            this.state.tagsExample
          );
        }
        this.closeDialogTagsExample();
      }
    });
  }

  openDialogTagsExample() {
    this.setState({
      openDialogTagsExample: true
    });
  }

  closeDialogTagsExample() {
    this.setState({
      openDialogTagsExample: false
    });
  }

  actionAddTagsExample(e) {
    const defaultValue = {
      tag: '',
      example: '-',
      excluded_example: '-'
    };

    this.setState(
      {
        mode: 'add',
        defaultValue: defaultValue,
        tagsExample: defaultValue
      },
      () => {
        this.openDialogTagsExample();
      }
    );
  }

  actionEditTagsExample(e, data) {
    const defaultValue = {
      tag: data.tag,
      example: data.example,
      excluded_example: data.excluded_example
    };

    this.setState(
      {
        mode: 'edit',
        tagsExampleSelected: data.tableData.id,
        defaultValue: defaultValue,
        tagsExample: defaultValue
      },
      () => {
        this.openDialogTagsExample();
      }
    );
  }

  displayButtonsTagsExample() {
    const { classes } = this.props;
    const { mode } = this.state;
    return (
      <div className={classes.buttonContainer}>
        <Button
          size="small"
          color="primary"
          onClick={e => {
            return this.updateTagsExample();
          }}
        >
          {mode === 'add' ? 'Add example' : 'Edit example'}
        </Button>
        <Button
          onClick={e => {
            return this.closeDialogTagsExample(e);
          }}
          size="small"
          color="secondary"
        >
          Cancel
        </Button>
      </div>
    );
  }

  downloadJson() {
    downloadAsJson(
      this.state.data,
      this.props.clientShortName +
        '_tags_example_' +
        moment().format('YYYY-MM-DD') +
        '_' +
        moment().format('hh_mm_A')
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tagsExampleList !== this.props.tagsExampleList) {
      this.setState({
        data: this.props.tagsExampleList
      });
    }

    if (prevProps.tagList !== this.props.tagList) {
      this.setState({
        tagList: this.props.tagList
      });
    }

    if (prevProps.editable !== this.props.editable) {
      this.setState({
        editable: this.props.editable,
        actions: this.onCreateActions(this.props.editable)
      });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      columns,
      data,
      actions,
      openDialogTagsExample,
      defaultValue,
      error,
      mode
    } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h5">Tags Example list</Typography>
          <IconButton
            className={classes.buttonDownload}
            onClick={() => this.downloadJson()}
          >
            <SystemUpdateAltIcon />
          </IconButton>
        </div>
        <TableComponent
          editable={false}
          columns={columns}
          data={data}
          actions={actions}
          title=""
        />
        <DialogContent
          title={'TagsExample'}
          open={openDialogTagsExample}
          fullWidth={true}
          close={this.closeDialogTagsExample.bind(this)}
        >
          <div>
            <TagsExampleForm
              mode={mode}
              defaultValue={defaultValue}
              onChangeTagsExampleForm={this.onChangeTagsExampleForm.bind(this)}
              error={error}
            />
            {this.displayButtonsTagsExample()}
          </div>
        </DialogContent>
      </div>
    );
  }
}

TagsExampleConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  addTagsExample: PropTypes.func.isRequired,
  editTagsExample: PropTypes.func.isRequired,
  tagsExampleList: PropTypes.array,
  tagList: PropTypes.array,
  editable: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(
  TagsExampleConfiguration
);
