import { setIndustry } from '../setters/industry';

export const extractIndustryList = data => {
  const industries = [];

  data.map((industry, key) => {
    industries.push(setIndustry(industry, key));
    return true;
  });

  industries.push({
    name: 'Other'
  });

  return industries;
};
