import { setTaskHistory } from '../setters/taskHistory';

export const extractTaskHistoryList = data => {
  const taskHistoryList = [];
  data.map((action, key) => {
    taskHistoryList.push(setTaskHistory(action, key));
    return true;
  });

  return taskHistoryList;
};
