import { setUCM } from '../setters/ucm';

export const extractUCMList = data => {
  const ucms = [];
  data.map(ucm => {
    ucms.push(setUCM(ucm));
    return true;
  });

  return ucms;
};
