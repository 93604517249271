import React, { useState } from "react";
import PropTypes from "prop-types";

// Material UI
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import TableComponent from "../../commons/table/tableComponent";
import DialogContent from "../../commons/dialog/contentDialogComponent";
import StickyFiltersForm from "./stickyFiltersForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 7,
  },
  detailContainer: {
    display: "flex",
  },
  detailItem: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.container.light,
  },
  detailLegend: {
    padding: theme.spacing(1),
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
  },
  detailContent: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    overflow: "auto",
  },
  detailCell: {
    marginBottom: theme.spacing(1),
  },
  buttonAdd: {
    marginLeft: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  actionsContainer: {
    display: "flex",
  },
  customAction: {
    borderRadius: "50%",
    padding: "0",
    cursor: "pointer",
    width: 48,
    height: 48,
    minWidth: "unset",
    "& .MuiButton-label": {
      width: "auto",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const StickyFiltersConfiguration = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isFormOpen, setFormOpen] = useState(false);
  const [mode, setMode] = useState("add");

  const initialFormData = {
    name: "",
    asset_ids: [],
    order_number: 0,
  };
  const [formData, setFormData] = useState(initialFormData);

  const [formErrors, setFormErrors] = useState({});

  const onAddStickyFilter = (e) => {
    setFormErrors({});
    setFormData(initialFormData);
    setMode("add");
    setFormOpen(true);
  };

  const onEditStickyFilter = (e, rowData) => {
    setFormErrors({});
    setFormData({
      name: rowData.name,
      asset_ids: rowData.asset_ids,
      index: rowData.tableData.id,
      order_number: rowData.order_number,
      id: rowData.id,
    });
    setMode("edit");
    setFormOpen(true);
  };

  const onCloseDialogForm = () => {
    setFormOpen(false);
  };

  const onChangeFormData = (e, name) => {
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };

  const checkForErrors = () => {
    return new Promise((resolve, reject) => {
      const formErrors = {};
      for (let field in formData) {
        let errorMessage = null;
        switch (field) {
          case "name":
            if (formData[field] === "") {
              errorMessage = "Name is mandatory";
            }
            break;
          case "asset_ids":
            if (formData[field].length === 0) {
              errorMessage = "Assets are mandatory";
            }
            break;
          case "order_number":
            if (formData[field].length === 0) {
              errorMessage = "Order is mandatory";
            }
            break;
          default:
            break;
        }
        if (errorMessage) {
          formErrors[field] = errorMessage;
        }
      }

      resolve(formErrors);
    });
  };

  const addStickyFilter = () => {
    checkForErrors().then((formErrors) => {
      if (Object.keys(formErrors).length === 0) {
        props.addStickyFilter(formData);
        onCloseDialogForm();
        setFormData(initialFormData);
      } else {
        setFormErrors(formErrors);
      }
    });
  };

  const updateStickyFilter = () => {
    checkForErrors().then((formErrors) => {
      if (Object.keys(formErrors).length === 0) {
        props.updateStickyFilter(formData.index, formData);
        onCloseDialogForm();
      } else {
        setFormErrors(formErrors);
      }
    });
  };

  const { isCreatable, isEditable, stickyFiltersList } = props;

  // Table schema
  const tableColumns = [
    {
      title: "Name",
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14,
      },
      field: "name",
    },
    {
      title: "ID",
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14,
      },
      field: "id",
    },
    {
      title: "Asset Count",
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14,
      },
      field: "asset_ids",
      render: (rowData) => {
        return rowData.asset_ids.length > 0 ? rowData.asset_ids.length : 0;
      },
    },
    {
      title: "Order",
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14,
      },
      field: "order_number",
      render: (rowData) => {
        return rowData.order_number !== undefined
          ? rowData.order_number.toString()
          : "0";
      },
    },
  ];

  const tableActions = isEditable
    ? [
        {
          icon: "edit",
          tooltip: "Edit Sticky Filter",
          onClick: (event, rowData) => onEditStickyFilter(event, rowData),
        },
      ]
    : [];

  const tableDetailPanel = [
    {
      tooltip: "Show Sticky Filter Details",
      render: (rowData) => {
        if (rowData.asset_ids.length > 0) {
          return (
            <div className={classes.detailContainer}>
              <div className={classes.detailItem}>
                <div className={classes.detailLegend}>{"Asset Names"}</div>
                {rowData.asset_ids.map((ass_id) => {
                  const name = props.assetList.find((ass) => ass.id === ass_id)
                    .name;
                  return (
                    <div className={classes.detailContent}>
                      {name ? name : "NO DATA"}
                    </div>
                  );
                })}
              </div>
              <div className={classes.detailItem}>
                <div className={classes.detailLegend}>{"Asset IDs"}</div>
                {rowData.asset_ids.map((ass_id) => {
                  return <div className={classes.detailContent}>{ass_id}</div>;
                })}
              </div>
            </div>
          );
        } else {
          return (
            <div className={classes.detailContainer}>
              <div className={classes.detailItem}>
                <div className={classes.detailLegend}>{"Asset Names"}</div>
                <div className={classes.detailContent}>NONE</div>
              </div>
              <div className={classes.detailItem}>
                <div className={classes.detailLegend}>{"Asset IDs"}</div>
                <div className={classes.detailContent}>NONE</div>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h5">Adbastion Sticky Filters</Typography>
        {isCreatable && (
          <Button
            variant="contained"
            className={classes.buttonAdd}
            size="small"
            color="primary"
            onClick={(e) => {
              return onAddStickyFilter(e);
            }}
          >
            Add Sticky Filter
          </Button>
        )}
      </div>
      <TableComponent
        editable={false}
        columns={tableColumns}
        detailPanel={tableDetailPanel}
        data={stickyFiltersList}
        actions={tableActions}
        title=""
      />
      <DialogContent
        title={
          mode === "edit"
            ? "Edit Adbastion Sticky Filter"
            : "Add Adbastion Sticky Filter"
        }
        open={isFormOpen}
        fullWidth={true}
        close={onCloseDialogForm}
      >
        <div>
          <StickyFiltersForm
            formData={formData}
            assetList={props.assetList}
            errors={formErrors}
            onChangeFormData={onChangeFormData}
          />
          <div className={classes.buttonContainer}>
            {mode === "edit" ? (
              <Button
                size="small"
                color="primary"
                onClick={(e) => {
                  return updateStickyFilter();
                }}
              >
                Edit Sticky Filter
              </Button>
            ) : (
              <Button
                size="small"
                color="primary"
                onClick={(e) => {
                  return addStickyFilter();
                }}
              >
                Add Sticky Filter
              </Button>
            )}
            <Button
              onClick={(e) => {
                return onCloseDialogForm(e);
              }}
              size="small"
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </div>
  );
};

StickyFiltersConfiguration.propTypes = {
  isCreatable: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  stickyFiltersList: PropTypes.array.isRequired,
  assetList: PropTypes.array.isRequired,
  addStickyFilter: PropTypes.func.isRequired,
  updateStickyFilter: PropTypes.func.isRequired,
};

export default StickyFiltersConfiguration;
