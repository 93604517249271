import React, { Component } from "react";
import PropTypes from "prop-types";
import ChipInput from "material-ui-chip-input";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckBox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogTitle from "@material-ui/core/DialogTitle";

import InputComponent from "../../commons/input/inputComponent";
import SearchSelectMulti from "../../commons/select/searchSelectMultiComponent";
import SelectComponent from "../../commons/select/selectComponent";
import DatePicker from "../../commons/picker/dateTimePickerComponent";

import Tooltip from "../../commons/tooltip/TooltipComponent";
import Icon from "@material-ui/core/Icon";

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  formControlContainer: {
    width: "100%",
  },
  formControl: {
    width: "100%",
    marginTop: 10,
  },
  formGroup: {
    width: "80%",
  },
  formGroupDate: {
    width: "50%",
  },
  item: {
    margin: 2,
  },
  errorMargin: {
    marginTop: 10,
  },
  dialogSubtitle: {
    padding: "8px 0 0 0",
  },
  infoIcon: {
    fontSize: 16,
    marginLeft: theme.spacing(1),
  },
  moderationGroup: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  formItemMiddle: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    minHeight: "57px",
  },
  planSelect: {
    width: "305px",
  },
});

class ClientsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      industryList: props.industryList,
      planList: props.planList,
      tagList: props.tagList
        ? props.tagList.sort((a, b) => (a.label > b.label ? 1 : -1))
        : [],
      data: props.defaultValue
        ? props.defaultValue
        : {
            name: "",
            clientShortName: "",
            logo: "",
            emailsAlert: "",
            tags: [],
            industry: "",
            adbastion_plan: "",
            otherIndustry: "",
          },
      error: props.error
        ? props.error
        : {
            name: "",
            industry: "",
            adbastion_plan: "",
            clientShortName: "",
            emailsAlert: "",
            tags: "",
            logo: "",
          },
    };
  }

  onChange(e, name) {
    let value = e.target ? e.target.value : e;

    switch (name) {
      case "tags":
      case "care_tags":
      case "care_review_tags":
      case "adbastion_tags":
        value = value.filter((i) => i !== "search");
        break;
      case "sla":
      case "alertSla":
        value = parseInt(value);
        break;
      default:
        break;
    }

    this.setState(
      {
        data: {
          ...this.state.data,
          [name]: value,
        },
      },
      () => {
        this.props.onChangeClientForm(this.state.data);
      }
    );
  }

  calculateTime(value) {
    const { data } = this.state;

    return data[value]
      ? Math.round(new Date(data[value]).getTime() / 1000)
      : null;
  }

  displayTextField(name, label, required, disabled, multiline, type = "text") {
    const { defaultValue } = this.props;
    const { data, error } = this.state;
    return (
      <InputComponent
        type={type}
        multiline={multiline}
        required={required}
        disabled={disabled}
        defaultValue={
          Object.values(defaultValue).length ? defaultValue[name] : data[name]
        }
        onChange={(e) => {
          return this.onChange(e, name);
        }}
        error={error[name] ? true : false}
        helperText={error[name]}
        label={label}
        margin="dense"
        fullWidth={true}
      />
    );
  }

  displayChipInput(name, label, required = false) {
    const { classes, defaultValue } = this.props;
    const { data, error } = this.state;
    return (
      <div>
        <ChipInput
          required={required}
          label={label}
          error={error[name] ? true : false}
          helperText={error[name]}
          fullWidth={true}
          defaultValue={
            Object.values(defaultValue).length ? defaultValue[name] : data[name]
          }
          blurBehavior="add"
          onChange={(e) => this.onChange(e, name)}
        />
        {error[name] ? <div className={classes.errorMargin}></div> : ""}
      </div>
    );
  }

  displayTextFields() {
    const { classes, mode } = this.props;
    return (
      <FormGroup className={classes.formGroup}>
        {this.displayTextField("name", "Name", true, false, false)}
        {this.displayTextField(
          "clientShortName",
          "Short Name",
          true,
          mode === "edit" ? true : false,
          false
        )}
        {this.displayTextField(
          "abbreviation",
          "Abbreviation",
          false,
          false,
          false
        )}
        {this.displayChipInput("emailsAlert", "Email(s) Alert", true)}
        {this.displayTextField("logo", "Logo", false, false, false)}
      </FormGroup>
    );
  }

  renderChip(list, value, id, item, key) {
    const { classes } = this.props;
    return (
      <Chip
        key={key}
        label={
          list.find((i) => value === i[id])
            ? item !== null
              ? list.find((i) => value === i[id])[item]
              : value
            : value
        }
        className={classes.item}
      />
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setState({
        error: this.props.error,
      });
    }

    if (prevProps.tagList !== this.props.tagList) {
      this.setState({
        tagList: this.props.tagList,
      });
    }

    if (prevProps.industryList !== this.props.industryList) {
      this.setState({
        industryList: this.props.industryList,
      });
    }

    if (prevProps.planList !== this.props.planList) {
      this.setState({
        planList: this.props.planList,
      });
    }
  }

  render() {
    const { classes, mode } = this.props;
    const {
      loading,
      tagList,
      data,
      industryList,
      error,
      planList,
    } = this.state;

    // dateTimePickerComponent has timestamp "formatting"/rounding, which is why we need to "serialize" moderation/insights dates
    const formattedModerationInitTimestamp = this.calculateTime(
      "adbastion_moderation_init_time"
    );
    const formattedSubscriptionInitTimestamp = this.calculateTime(
      "trial_subscription_init_time"
    );
    const formattedInsightsInitTimestamp = this.calculateTime(
      "adbastion_insights_init_time"
    );
    const formattedCareInitTimestamp = this.calculateTime("care_init_time");
    const formattedModerationGuidelineLastUpdate = this.calculateTime(
      "adbastion_moderation_guideline_update_time"
    );

    return (
      <div className={classes.root}>
        {loading ? (
          <CircularProgress />
        ) : (
          <div className={classes.formControlContainer}>
            <FormControl className={classes.formControl}>
              {this.displayTextFields()}
            </FormControl>
            <SelectComponent
              id={"industry"}
              label={"Industry"}
              attributId={"name"}
              attributName={"name"}
              mandatory={true}
              error={error["industry"]}
              value={data["industry"]}
              display={1}
              fullWidth={true}
              items={industryList}
              onChange={this.onChange.bind(this)}
            />
            {data["industry"] === "Other" ? (
              <FormControl className={classes.formControl}>
                <FormGroup className={classes.formGroup}>
                  {this.displayTextField(
                    "otherIndustry",
                    "Other Industry",
                    false,
                    false,
                    false
                  )}
                </FormGroup>
              </FormControl>
            ) : null}
            <FormControl className={classes.formControl}>
              <SearchSelectMulti
                name={"tags"}
                label={"Tags"}
                list={tagList}
                id={"id"}
                mandatory={true}
                error={error["tags"]}
                attribute={"label"}
                data={data["tags"]}
                onChange={(e, name) => this.onChange(e, name)}
                renderItem={(i, id, attribute, name, key) =>
                  this.renderChip(i, id, attribute, name, key)
                }
                enableCopyPaste={true}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormGroup className={classes.formGroup}>
                {this.displayTextField(
                  "privateRepliesUserTimespan",
                  "Private reply timeframe",
                  false,
                  false,
                  false,
                  "number"
                )}
              </FormGroup>
            </FormControl>
            {mode === "add" && (
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  control={
                    <CheckBox
                      name="useNewIds"
                      checked={data["useNewIds"]}
                      onChange={(e, value) =>
                        this.onChange({ target: { value } }, "useNewIds")
                      }
                    />
                  }
                  label={`Use new IDs`}
                />
              </FormControl>
            )}
            <DialogTitle className={classes.dialogSubtitle}>
              Platform configuration
            </DialogTitle>
            <FormControl className={classes.formControl}>
              <div className={classes.container}>
                <DatePicker
                  required={true}
                  error={error["adbastion_moderation_init_time"]}
                  settedValue={false}
                  defaultTimeValue="00:00"
                  ampm={false}
                  hideTime
                  name={"adbastion_moderation_init_time"}
                  label={"Moderation Init Time"}
                  value={formattedModerationInitTimestamp}
                  setSelectedDate={(name, value) =>
                    // Reversing datepicker timestamp formatting
                    this.onChange({ target: { value: value * 1000 } }, name)
                  }
                />
              </div>
              <div className={classes.container}>
                <DatePicker
                  required={true}
                  error={error["adbastion_moderation_guideline_update_time"]}
                  settedValue={false}
                  defaultTimeValue="00:00"
                  ampm={false}
                  hideTime
                  name={"adbastion_moderation_guideline_update_time"}
                  label={"Guideline Last Update"}
                  value={formattedModerationGuidelineLastUpdate}
                  setSelectedDate={(name, value) =>
                    // Reversing datepicker timestamp formatting
                    this.onChange({ target: { value: value * 1000 } }, name)
                  }
                />
              </div>
              <div className={classes.container}>
                <DatePicker
                  required={true}
                  error={error["adbastion_insights_init_time"]}
                  settedValue={false}
                  defaultTimeValue="00:00"
                  ampm={false}
                  hideTime
                  name={"adbastion_insights_init_time"}
                  label={"Insights Init Time"}
                  value={formattedInsightsInitTimestamp}
                  setSelectedDate={(name, value) =>
                    // Reversing datepicker timestamp formatting
                    this.onChange({ target: { value: value * 1000 } }, name)
                  }
                />
              </div>
            </FormControl>
            <FormControl className={classes.formControl}>
              <SearchSelectMulti
                id={"id"}
                list={tagList}
                mandatory={true}
                attribute={"label"}
                label={"Safety Tags"}
                enableCopyPaste={true}
                name={"adbastion_tags"}
                data={data["adbastion_tags"]}
                error={error["adbastion_tags"]}
                onChange={(e, name) => this.onChange(e, name)}
                renderItem={(i, id, attribute, name, key) =>
                  this.renderChip(i, id, attribute, name, key)
                }
                postFixComponent={
                  <Tooltip
                    title={
                      <p>
                        By default, all the tags assigned for the client are
                        visible on Adbastion. You can use this configuration to
                        differentiate CAMP and Adbation tags. Only the tags
                        selected in this box will be visible to the client on
                        Adbastion
                      </p>
                    }
                  >
                    <Icon className={classes.infoIcon}>info</Icon>
                  </Tooltip>
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <SearchSelectMulti
                id={"id"}
                list={tagList}
                mandatory={true}
                name={"care_tags"}
                label={"Care Tags"}
                attribute={"label"}
                enableCopyPaste={true}
                data={data["care_tags"]}
                error={error["care_tags"]}
                onChange={(e, name) => this.onChange(e, name)}
                renderItem={(i, id, attribute, name, key) =>
                  this.renderChip(i, id, attribute, name, key)
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <SearchSelectMulti
                id={"id"}
                list={tagList}
                mandatory={true}
                attribute={"label"}
                enableCopyPaste={true}
                name={"care_review_tags"}
                label={"Care Review Tags"}
                data={data["care_review_tags"]}
                error={error["care_review_tags"]}
                onChange={(e, name) => this.onChange(e, name)}
                renderItem={(i, id, attribute, name, key) =>
                  this.renderChip(i, id, attribute, name, key)
                }
              />
            </FormControl>
            <div className={classes.container}>
              <DatePicker
                hideTime
                ampm={false}
                required={true}
                settedValue={false}
                name={"care_init_time"}
                label={"Care Init Time"}
                defaultTimeValue="00:00"
                error={error["care_init_time"]}
                value={formattedCareInitTimestamp}
                setSelectedDate={(name, value) =>
                  this.onChange({ target: { value: value * 1000 } }, name)
                }
              />
            </div>
            <div className={classes.moderationGroup}>
              <FormControl>
                <FormControlLabel
                  control={
                    <CheckBox
                      name="adbastion_moderation_enabled"
                      checked={data["adbastion_moderation_enabled"]}
                      onChange={(e, value) => {
                        // Reset alerts enabled state
                        this.setState(
                          {
                            data: {
                              ...this.state.data,
                              adbastion_moderation_alerts_enabled: false,
                            },
                          },
                          // Set Moderation enabled state
                          () => {
                            this.onChange(
                              { target: { value } },
                              "adbastion_moderation_enabled"
                            );
                          }
                        );
                      }}
                    />
                  }
                  label="Platform Safety Fully Managed enabled"
                />
              </FormControl>
              {data["adbastion_moderation_enabled"] ? (
                <FormControl>
                  <FormControlLabel
                    control={
                      <CheckBox
                        name="adbastion_moderation_alerts_enabled"
                        checked={data["adbastion_moderation_alerts_enabled"]}
                        onChange={(e, value) =>
                          this.onChange(
                            { target: { value } },
                            "adbastion_moderation_alerts_enabled"
                          )
                        }
                      />
                    }
                    label={`Adbastion Alerts enabled`}
                  />
                </FormControl>
              ) : null}

              <FormControl>
                <FormControlLabel
                  control={
                    <CheckBox
                      name="adbastion_auto_crawling"
                      checked={data["adbastion_auto_crawling"]}
                      onChange={(e, value) => {
                        this.setState(
                          {
                            data: {
                              ...this.state.data,
                              adbastion_auto_crawling: false,
                            },
                          },
                          () => {
                            this.onChange(
                              { target: { value } },
                              "adbastion_auto_crawling"
                            );
                          }
                        );
                      }}
                    />
                  }
                  label="Adbastion Automatic Crawling"
                />
              </FormControl>
            </div>

            <div className={classes.moderationGroup}>
              <FormControl>
                <FormControlLabel
                  control={
                    <CheckBox
                      name="care_enabled"
                      checked={data["care_enabled"]}
                      onChange={(e, value) => {
                        this.setState(() => {
                          this.onChange({ target: { value } }, "care_enabled");
                        });
                      }}
                    />
                  }
                  label="Platform Care Fully Managed enabled"
                />
              </FormControl>

              {data["care_enabled"] ? (
                <FormControl>
                  <FormControlLabel
                    control={
                      <CheckBox
                        name="care_agent_stats_enabled"
                        checked={data["care_agent_stats_enabled"]}
                        onChange={(e, value) => {
                          this.setState(() => {
                            this.onChange(
                              { target: { value } },
                              "care_agent_stats_enabled"
                            );
                          });
                        }}
                      />
                    }
                    label="Agent Performance stats enabled"
                  />
                </FormControl>
              ) : null}

              <FormControl>
                <FormControlLabel
                  control={
                    <CheckBox
                      name="adbastion_publishing_enabled"
                      checked={data["adbastion_publishing_enabled"]}
                      onChange={(e, value) => {
                        this.setState(() => {
                          this.onChange(
                            { target: { value } },
                            "adbastion_publishing_enabled"
                          );
                        });
                      }}
                    />
                  }
                  label="Publish enabled"
                />
              </FormControl>
            </div>

            <div className={classes.moderationGroup}>
              <FormControl>
                <FormControlLabel
                  control={
                    <CheckBox
                      name="managed"
                      checked={data["managed"]}
                      onChange={(e, value) => {
                        this.setState(() => {
                          this.onChange({ target: { value } }, "managed");
                        });
                      }}
                    />
                  }
                  label="Managed Clients"
                />
              </FormControl>
            </div>
            <FormControl
              className={classNames(classes.formControl, classes.planSelect)}
            >
              <SelectComponent
                id={"adbastion_plan"}
                label={"Plan"}
                attributId={"id"}
                attributName={"label"}
                mandatory={true}
                error={error["adbastion_plan"]}
                value={data["adbastion_plan"]}
                items={planList}
                fullWidth={true}
                onChange={this.onChange.bind(this)}
              />
            </FormControl>
            <div className={classes.formItemMiddle}>
              <FormControl>
                <FormControlLabel
                  control={
                    <CheckBox
                      name="trial_subscription_init"
                      checked={data["trial_subscription_init"]}
                      onChange={(e, value) => {
                        this.setState(
                          {
                            data: {
                              ...this.state.data,
                              trial_subscription_init: value,
                            },
                          },
                          () => {
                            this.onChange(
                              { target: { value } },
                              "trial_subscription_init"
                            );
                          }
                        );
                      }}
                    />
                  }
                  label="Enable a 30-day trial in the Intelligence Platform"
                />
              </FormControl>

              {this.state.data.trial_subscription_init ? (
                <DatePicker
                  hideTime
                  ampm={false}
                  settedValue={false}
                  label={"Init Time"}
                  defaultTimeValue="00:00"
                  name={"trial_subscription_init_time"}
                  value={formattedSubscriptionInitTimestamp}
                  error={error["trial_subscription_init_time"]}
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                  setSelectedDate={(name, value) =>
                    this.onChange({ target: { value: value * 1000 } }, name)
                  }
                />
              ) : null}
            </div>

            <DialogTitle className={classes.dialogSubtitle}>
              Default crawling configuration
            </DialogTitle>
            <FormControl className={classes.formControl}>
              <FormGroup className={classes.formGroupDate}>
                <div className={classes.container}>
                  <DatePicker
                    required={true}
                    error={error["initTime"]}
                    minDate={new Date("2019-01-01")}
                    settedValue={false}
                    defaultTimeValue="00:00"
                    ampm={false}
                    name={"initTime"}
                    label={"Init Time"}
                    value={data["initTime"] ? data["initTime"] : null}
                    setSelectedDate={(name, value) =>
                      this.onChange({ target: { value: value } }, name)
                    }
                  />
                </div>
              </FormGroup>
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormGroup className={classes.formGroup}>
                {this.displayTextField(
                  "sla",
                  "SLA",
                  true,
                  false,
                  false,
                  "number"
                )}
              </FormGroup>
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormGroup className={classes.formGroup}>
                {this.displayTextField(
                  "alertSla",
                  "Alert SLA",
                  true,
                  false,
                  false,
                  "number"
                )}
              </FormGroup>
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <CheckBox
                    name="pullInsights"
                    checked={data["pullInsights"]}
                    onChange={(e, value) =>
                      this.onChange({ target: { value } }, "pullInsights")
                    }
                  />
                }
                label={`Pull insights (click reactions to posts and comments)`}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <CheckBox
                    name="queryOnlyActive"
                    checked={data["queryOnlyActive"]}
                    onChange={(e, value) =>
                      this.onChange({ target: { value } }, "queryOnlyActive")
                    }
                  />
                }
                label={`Query only active ads`}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <CheckBox
                    name="allowPublishedPosts"
                    checked={data["allowPublishedPosts"]}
                    onChange={(e, value) =>
                      this.onChange(
                        { target: { value } },
                        "allowPublishedPosts"
                      )
                    }
                  />
                }
                label={`Allow published posts`}
              />
            </FormControl>
          </div>
        )}
      </div>
    );
  }
}

ClientsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onChangeClientForm: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  error: PropTypes.object,
  mode: PropTypes.string,
  tagList: PropTypes.array,
  industryList: PropTypes.array,
  planList: PropTypes.array,
};

export default withStyles(styles, { withTheme: true })(ClientsForm);
