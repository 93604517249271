import { MGConstants } from '../../constants/configuration/MGConstants';

import { extractModerationGuidelinesList } from '../../extractors/moderationGuidelinesExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  moderationGuidelinesList: [],
  moderationGuidelinesColumns: [],
  error: null
};

const moderationGuidelinesReducer = (state = initialState, action) => {
  switch (action.type) {
    //RESET_FETCH_MODERATION_GUIDELINES
    case `${MGConstants.RESET_FETCH_MODERATION_GUIDELINES}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: false
      };

    // FETCH_MODERATION_GUIDELINES_LIST
    case `${MGConstants.FETCH_MODERATION_GUIDELINES_LIST}_PENDING`:
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    case `${MGConstants.FETCH_MODERATION_GUIDELINES_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload.data
      };
    case `${MGConstants.FETCH_MODERATION_GUIDELINES_LIST}_FULFILLED`:
      const moderationGuidelines = extractModerationGuidelinesList(
        action.payload.data
      );
      return {
        ...state,
        fetching: false,
        fetched: true,
        moderationGuidelinesList: moderationGuidelines.data,
        moderationGuidelinesColumns: moderationGuidelines.columns
      };

    // UPDATE_MODERATION_GUIDELINES
    case `${MGConstants.UPDATE_MODERATION_GUIDELINES}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${MGConstants.UPDATE_MODERATION_GUIDELINES}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${MGConstants.UPDATE_MODERATION_GUIDELINES}_FULFILLED`:
      let newItems = action.payload.config.configs.guidelines.map(guideline => {
        let list = Object.entries(guideline)
          .filter(g => g[0].includes('_action'))
          .filter(l => l[1].enabled === true);

        let vol = list.find(g => g[0] === 'volume_action');
        let alt = list.find(g => g[0] === 'alert_action');
        return {
          [guideline.id]: {
            guideline_name: guideline.name,
            competitor_description: guideline.competitor_description,
            volume: vol ? vol[1] : {},
            alert: alt
              ? {
                  emails: alt[1]['emails']
                }
              : {},
            exceptions: list.map(g => {
              return {
                name: g[0],
                value: g[1].exceptions
              };
            }),
            value: list.length
              ? list
                  .map(g => {
                    let value = g[0].replace('_action', '');
                    value = value.charAt(0).toUpperCase() + value.slice(1);
                    return value;
                  })
                  .join(', ')
              : 'Leave Visible'
          }
        };
      });

      newItems = newItems.reduce(function(acc, x) {
        for (var key in x) acc[key] = x[key];
        return acc;
      }, {});

      return {
        ...state,
        fetching: false,
        fetched: true,
        moderationGuidelinesList: Object.assign([], {
          ...state.moderationGuidelinesList,
          [action.payload.config.index]: {
            ...state.moderationGuidelinesList[action.payload.config.index],
            ...newItems
          }
        })
      };
    default:
      return state;
  }
};

export default moderationGuidelinesReducer;
