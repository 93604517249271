import {
  setClientConfig,
  setClientExplorer,
  setClientStats
} from '../setters/client';

export const extractClientList = (data, type) => {
  const clients = [];

  data.map((client, key) => {
    if (type === 'config') {
      clients.push(setClientConfig(client, key));
    }
    if (type === 'explorer') {
      clients.push(setClientExplorer(client, key));
    }
    if (type === 'reply' || type === 'copyright') {
      clients.push(setClientStats(client, key));
    }
    return true;
  });

  return clients;
};
