import { plansConstants } from "../constants/plansConstants";

const initialState = {
  fetching: false,
  fetched: false,
  planList: [],
  error: null,
};

const Plans = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_PLANS
    case `${plansConstants.FETCH_PLANS}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${plansConstants.FETCH_PLANS}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${plansConstants.FETCH_PLANS}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        planList: action.payload.data,
      };
    default:
      return state;
  }
};

export default Plans;
