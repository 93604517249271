import { setClassifier } from '../setters/classifier';

export const extractClassifierList = data => {
  const classifiers = [];
  data.map((classifier, key) => {
    classifiers.push(setClassifier(classifier, key));
    return true;
  });

  return classifiers;
};
