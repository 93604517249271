import { commentKPIsConstants } from '../../constants/taskKPIs/commentKPIsConstants';

import { extractCommentKPIsList } from '../../extractors/commentKPIsExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  commentKPIsList: [],
  error: null
};

const commentKPIsReducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_COMMENT_KPIS_LIST
    case `${commentKPIsConstants.FETCH_COMMENT_KPIS_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${commentKPIsConstants.FETCH_COMMENT_KPIS_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${commentKPIsConstants.FETCH_COMMENT_KPIS_LIST}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        commentKPIsList: extractCommentKPIsList(
          action.payload.data.comment_tasks_kpis
        )
      };
    default:
      return state;
  }
};

export default commentKPIsReducer;
