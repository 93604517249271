import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const FullScreenDialog = ({
  buttonTitle,
  title,
  open,
  action,
  handleClose,
  handleOpen,
  handleAction,
  hideCloseIcon,
  children
}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(
    open !== undefined ? open : false
  );

  useEffect(() => {
    if (open !== undefined) {
      return setOpenDialog(open);
    }
  }, [open]);

  const clickOpen = () => {
    if (open === undefined) {
      setOpenDialog(true);
    }

    if (typeof handleOpen === 'function') {
      return handleOpen();
    }
  };

  const clickClose = () => {
    if (open === undefined) {
      setOpenDialog(false);
    }

    if (typeof handleClose === 'function') {
      return handleClose();
    }
  };

  const clickAction = () => {
    if (typeof handleAction === 'function') {
      return handleAction();
    }
  };

  return (
    <span>
      <Button
        className={classes.button}
        variant="contained"
        size="small"
        onClick={() => clickOpen()}
      >
        {buttonTitle}
      </Button>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={() => clickClose()}
        TransitionComponent={Transition}
      >
        <Box displayPrint={'none'}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              {!hideCloseIcon ? (
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => clickClose()}
                  aria-label="Close"
                >
                  <CloseIcon />
                </IconButton>
              ) : (
                ''
              )}
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
              <Button color="inherit" onClick={() => clickAction()}>
                {action}
              </Button>
            </Toolbar>
          </AppBar>
        </Box>
        {children}
      </Dialog>
    </span>
  );
};

FullScreenDialog.propTypes = {
  buttonTitle: PropTypes.string,
  title: PropTypes.string,
  open: PropTypes.bool,
  action: PropTypes.string,
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,
  hideCloseIcon: PropTypes.bool,
  handleAction: PropTypes.func,
  children: PropTypes.node
};

export default FullScreenDialog;
