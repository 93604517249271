import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ImageDialog from "../dialog/imageDialogComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
    display: "flex",
  },
  rootWithoutLegend: {
    maxWidth: "250px",
  },
  attachmentItem: {
    display: "flex",
    marginTop: 8,
    marginRight: 8,
  },
  attachmentLegend: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 10,
  },
  attachmentImg: {
    cursor: "pointer",
  },
  attachmentImgSelected: {},
  isSelected: {
    color: theme.palette.white,
  },
  attachmentDescription: {
    textAlign: "left",
    fontSize: "12px",
    textOverflow: "ellipsis",
    overflow: "auto",
    maxHeight: 55,
  },
  attachmentUrl: {
    textAlign: "left",
    fontSize: "12px",
    wordBreak: "break-all",
  },
  item: {
    margin: "5px 5px 0px 5px",
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    fontSize: 12,
    width: 20,
    marginRight: 2,
  },
}));

const ListItemAttachment = ({
  attachments,
  isSelected,
  imageSize,
  hideLegend,
  postId,
  isYoutubeVideo,
}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [attachmentOpened, setAttachmentOpened] = useState(0);

  const handleOpenDialog = (key) => {
    setAttachmentOpened(key);
    setOpenDialog(true);
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = `${process.env.PUBLIC_URL}/images/image-regular.svg`;
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootWithoutLegend]: hideLegend,
      })}
    >
      {attachments.slice(0, 4).map((attachment, key) => (
        <div className={classes.attachmentItem} key={key}>
          {!isYoutubeVideo &&
          (attachment.type !== "animated_image_share" ||
            (attachment.type === "animated_image_share" &&
              attachment.media &&
              !attachment.media.source)) ? (
            <img
              onError={addDefaultSrc}
              onClick={() => {
                return handleOpenDialog(key);
              }}
              className={classNames(classes.attachmentImg, {
                [classes.attachmentImgSelected]: isSelected,
              })}
              width="92"
              height="92"
              src={
                attachment.media && attachment.media.image
                  ? attachment.media.image.src
                  : ""
              }
              alt="post related"
            />
          ) : isYoutubeVideo ? (
            <div className={classes.youtubeContainer}>
              <iframe
                allowFullScreen="allowfullscreen"
                frameBorder="0"
                width="240"
                height="160"
                title="youtube_video"
                src={`https://www.youtube.com/embed/${postId}`}
              ></iframe>
            </div>
          ) : (
            <video
              onClick={() => {
                return handleOpenDialog(key);
              }}
              className={classNames(classes.attachmentImg, {
                [classes.attachmentImgSelected]: isSelected,
              })}
              width="92"
              height="92"
              loop
              muted
              autoPlay
              name="media"
            >
              <source
                src={attachment.media ? attachment.media.source : ""}
                type="video/mp4"
              />
            </video>
          )}
          {!hideLegend ? (
            <div className={classes.attachmentLegend}>
              {attachment.type === "video_share_youtube" ||
              attachment.type === "share" ? (
                <Typography
                  className={classNames(classes.attachmentTitle, {
                    [classes.isSelected]: isSelected,
                  })}
                >
                  {attachment.title}
                </Typography>
              ) : (
                ""
              )}
              {attachment.type === "video_share_youtube" ||
              attachment.type === "share" ? (
                attachment.media.source ? (
                  <a
                    href={attachment.media.source}
                    rel="noopener noreferrer"
                    target="_blank"
                    className={classNames(classes.attachmentUrl, {
                      [classes.isSelected]: isSelected,
                    })}
                  >
                    {`Go to ${
                      attachment.type === "video_share_youtube"
                        ? "youtube video"
                        : "attachment"
                    }`}
                  </a>
                ) : (
                  <Typography
                    variant="body2"
                    className={classNames({
                      [classes.isSelected]: isSelected,
                    })}
                  >
                    Source not available
                  </Typography>
                )
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ))}
      <ImageDialog
        open={openDialog}
        close={() => setOpenDialog(false)}
        data={attachments[attachmentOpened]}
        imageSize={imageSize}
      />
    </div>
  );
};

ListItemAttachment.propTypes = {
  attachments: PropTypes.array,
  imageSize: PropTypes.number,
  hideLegend: PropTypes.bool,
};

export default ListItemAttachment;
