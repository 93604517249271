import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';

import { setDateFormatTimeStamp } from '../../../setters/date';

import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(theme => ({
  tag: {
    margin: 5,
    marginTop: 0,
    height: 20,
    fontSize: 10
  },
  title: {},
  errorsContainer: {
    marginLeft: theme.spacing(2)
  },
  typo: {
    marginLeft: theme.spacing(1),
    fontSize: 13,
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: theme.palette.primary.main,
    minWidth: 30,
    marginRight: theme.spacing(1)
  }
}));

const HistoryComment = ({ type, step }) => {
  const classes = useStyles();

  return (
    <Step active={true}>
      <StepLabel className={classes.title}>{'Copyright Event'}</StepLabel>
      <StepContent>
        <Typography className={classes.typo}>
          <Icon className={classNames(classes.icon, 'far fa-check-circle')} />
          {step.status ? step.status : 'new'}
        </Typography>
        {step.message ? (
          <Typography className={classes.typo}>
            <Icon className={classNames(classes.icon, 'far fa-comment-dots')} />
            {step.message ? step.message : ''}
          </Typography>
        ) : (
          ''
        )}
        <Typography className={classes.typo}>
          <Icon className={classes.icon}>calendar_today</Icon>
          {`At: ${setDateFormatTimeStamp(step.updated_at)}`}
        </Typography>
        <Typography className={classes.typo}>
          <Icon className={classes.icon}>account_circle</Icon>
          {step.updated_by}
        </Typography>
      </StepContent>
    </Step>
  );
};

HistoryComment.propTypes = {
  step: PropTypes.object
};

export default HistoryComment;
