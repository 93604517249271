import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0.5)
  },
  disabled: {
    opacity: 0.7
  }
}));

const TimePicker = ({
  label,
  value,
  onChangeValue,
  onBlur,
  onFocus,
  disabled
}) => {
  const classes = useStyles();
  const [hour, setHour] = useState(value ? value.split(':')[0] : 0);
  const [min, setMin] = useState(value ? value.split(':')[1] : 0);
  const [sec, setSec] = useState(value ? value.split(':')[2] : 0);

  useEffect(() => {
    setHour(value ? value.split(':')[0] : 0);
    setMin(value ? value.split(':')[1] : 0);
    setSec(value ? value.split(':')[2] : 0);
  }, [value]);

  const leadingZeros = value => {
    if (!isNaN(value) && value.length === 1) {
      value = '0' + value;
    }

    return value;
  };

  const onChange = (e, type) => {
    e.preventDefault();
    if (type === 'hour') {
      setHour(leadingZeros(e.target.value));
      onChangeValue(`${e.target.value}:${min}:${sec}`);
    }
    if (type === 'min') {
      let newMin = e.target.value > 59 ? 59 : leadingZeros(e.target.value);
      setMin(value);
      onChangeValue(`${hour}:${newMin}:${sec}`);
    }
    if (type === 'sec') {
      let newSec = e.target.value > 59 ? 59 : leadingZeros(e.target.value);
      setSec(newSec);
      onChangeValue(`${hour}:${min}:${newSec}`);
    }
  };

  return (
    <div className={classes.root}>
      <div>
        <Typography
          className={classNames({
            [classes.disabled]: disabled
          })}
        >
          {label}
        </Typography>
      </div>
      <input
        value={hour}
        className={classNames({
          [classes.disabled]: disabled
        })}
        disabled={disabled}
        type="number"
        id="hour"
        name="hour"
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={e => onChange(e, 'hour')}
        min="0"
        max="99"
      />
      <input
        value={min}
        className={classNames({
          [classes.disabled]: disabled
        })}
        disabled={disabled}
        type="number"
        id="min"
        name="min"
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={e => onChange(e, 'min')}
        min="0"
        max="59"
      />
      <input
        value={sec}
        className={classNames({
          [classes.disabled]: disabled
        })}
        disabled={disabled}
        type="number"
        id="sec"
        name="sec"
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={e => onChange(e, 'sec')}
        min="0"
        max="59"
      />
    </div>
  );
};

TimePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  onChangeValue: PropTypes.func.isRequired
};

export default TimePicker;
