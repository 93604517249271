import axios from 'axios';

import { tagsExampleConstants } from '../../constants/configuration/tagsExampleConstants';
import { setHeaders } from '../../setters/auth';

export const fetchTagsExampleList = clientShortName => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/examples`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: tagsExampleConstants.FETCH_TAG_EXAMPLE_LIST,
    payload: promise
  });
};

export const editTagsExample = (clientShortName, index, data) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/examples/${data.tag}`;
  let tagsExamples = {
    example: data.example,
    excluded_example: data.excluded_example
  };

  const promise = axios.put(url, tagsExamples, {
    headers: setHeaders(),
    index: index,
    tagsExampleInfo: data
  });

  return dispatch({
    type: tagsExampleConstants.EDIT_TAG_EXAMPLE,
    payload: promise
  });
};
