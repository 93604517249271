import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

//TODO: https://github.com/mui-org/material-ui/issues/16135
import styled from 'styled-components';
import { compose, css, display, spacing, palette } from '@material-ui/system';

const Box = styled('div')`${display}${css(
  compose(
    spacing,
    palette
  )
)}`;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    minHeight: 'calc(100vh - 302px)'
  },
  boxContainer: {
    backgroundColor: theme.palette.container.light,
    height: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 400,
    position: 'relative'
  },
  droppableContainer: {
    height: 'calc(100vh - 333px)',
    overflow: 'auto'
  },
  contentContainer: {
    width: '95%',
    margin: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  onDragOver: {
    border: `3px dashed ${theme.palette.primary.main}`
  }
}));

const ColumnDragAndDrop = ({
  columns,
  list,
  onDrop,
  filters,
  filterBy,
  renderItem
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [onDragOver, setOnDragOver] = useState('');

  const onDragStart = (e, key) => {
    setOnDragOver('');
    e.dataTransfer.setData('id', key);
  };

  const handleOnDrop = (e, columnId) => {
    setOnDragOver('');
    onDrop(e.dataTransfer.getData('id'), columnId);
  };

  const handleOnDragOver = (e, title) => {
    setOnDragOver(title);
    e.preventDefault();
  };

  const setListByColumn = list => {
    return list.map((item, key) => {
      return (
        <div
          key={key}
          draggable={true}
          className={classNames('draggable', {
            [classes.contentContainer]: true
          })}
          onDragStart={e => onDragStart(e, item.id)}
        >
          {renderItem(item)}
        </div>
      );
    });
  };

  useEffect(() => {
    return setLoading(list ? false : true);
  }, [list]);

  return (
    <div className={classes.root}>
      {columns.map((column, key) => {
        return (
          <div key={key} className={classes.boxContainer}>
            <Typography variant="overline">{column.label}</Typography>
            <Box
              onDragOver={e => handleOnDragOver(e, column.title)}
              onDrop={e => {
                handleOnDrop(e, column.id);
              }}
              className={classNames(classes.droppableContainer, {
                droppable: column.droppable,
                [classes.onDragOver]: onDragOver === column.title
              })}
            >
              {!loading &&
                setListByColumn(
                  list.filter(
                    item =>
                      filters !== undefined && filterBy !== undefined
                        ? item.status === column.id &&
                          filters.indexOf(item[filterBy]) > -1
                        : item.status === column.id
                    //  item.status === column.id
                  )
                )}
            </Box>
          </div>
        );
      })}
    </div>
  );
};

ColumnDragAndDrop.propTypes = {
  columns: PropTypes.array.isRequired,
  list: PropTypes.array.isRequired,
  filters: PropTypes.array,
  filterBy: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  renderItem: PropTypes.func
};

export default ColumnDragAndDrop;
