import { channelWhitelistConstants } from '../../constants/configuration/channelWhitelistConstants';

const initialState = {
  fetching: false,
  fetched: false,
  channelWhitelistList: [],
  error: null
};

const channelWhitelistReducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_CHANNEL_WHITELIST_LIST
    case `${channelWhitelistConstants.FETCH_CHANNEL_WHITELIST_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${channelWhitelistConstants.FETCH_CHANNEL_WHITELIST_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${channelWhitelistConstants.FETCH_CHANNEL_WHITELIST_LIST}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        channelWhitelistList: action.payload.data
      };

    // ADD_CHANNEL_WHITELIST
    case `${channelWhitelistConstants.ADD_CHANNEL_WHITELIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${channelWhitelistConstants.ADD_CHANNEL_WHITELIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${channelWhitelistConstants.ADD_CHANNEL_WHITELIST}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        channelWhitelistList: [
          ...state.channelWhitelistList,
          action.payload.config.addData
        ]
      };

    // DELETE_CHANNEL_WHITELIST
    case `${channelWhitelistConstants.DELETE_CHANNEL_WHITELIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${channelWhitelistConstants.DELETE_CHANNEL_WHITELIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${channelWhitelistConstants.DELETE_CHANNEL_WHITELIST}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        channelWhitelistList: state.channelWhitelistList.filter(
          (cwl, i) => i !== action.payload.config.index
        )
      };
    default:
      return state;
  }
};

export default channelWhitelistReducer;
