import axios from 'axios';

import { linkedAccountsConstants } from '../../constants/taskKPIs/linkedAccountsConstants';
import { setHeaders } from '../../setters/auth';

export const fetchLinkedAccountsList = assetId => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/assets/${assetId}/linked-accounts`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: linkedAccountsConstants.FETCH_LINKED_ACCOUNTS_LIST,
    payload: promise
  });
};
