import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";

import TableComponent from "../../commons/table/tableComponent";
import DialogContent from "../../commons/dialog/contentDialogComponent";
import AlertDialog from "../../commons/dialog/alertDialogComponent";
import ActionsForm from "./actionsForm";

import { downloadAsJson } from "../../../utils/utils";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 7,
  },
  buttonAddAction: {
    marginLeft: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  tag: {
    marginLeft: 2,
    marginRight: 2,
  },
  buttonDownload: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0),
    color: theme.palette.black,
    padding: 0,
  },
  loading: {
    marginRight: 6,
    width: "16px !important",
    height: "16px !important",
    color: "#00000042",
  },
});

class ActionsConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagSelected: 0,
      openDialogActions: false,
      checkFilter: false,
      actionDeleting: "",
      openDialogDeleteAction: false,
      mode: "add",
      defaultValue: {},
      error: {},
      editable: props.editable,
      creatable: props.creatable,
      deletable: props.deletable,
      tagList: props.tagList,
      clientTags: props.clientTags,
      typeList: props.typeList,
      onErrorFilter: false,
      columns: [
        {
          title: "Tags",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          field: "tags",
          render: (rowData) => this.displayTags(rowData.tags),
        },
        {
          title: "Type",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          field: "type",
        },
        {
          title: "Filters",
          cellStyle: {
            padding: props.theme.spacing(1),
            paddingLeft: 14,
          },
          render: (rowData) => this.displayFilters(rowData.filters),
          field: "filters",
        },
      ],
      editingAction: props.editingAction || false,
      data: props.actionList || [],
      replierAssetsList: props.replierAssetsList || [],
      actions: this.onCreateActions(props.editable),
      action: {},
    };
  }

  displayTags(tags) {
    const { classes } = this.props;
    const { tagList } = this.state;
    return tagList.map((tag, key) => {
      if (tags.includes(tag.id)) {
        return <Chip className={classes.tag} key={key} label={tag.label} />;
      }
      return "";
    });
  }

  displayFilters(filters) {
    return <div>{filters.length}</div>;
  }

  onCreateActions(editable, deletable) {
    let actions = [];
    if (editable) {
      actions.push({
        icon: "edit",
        tooltip: "Edit action",
        onClick: (event, rowData) => this.actionEditAction(event, rowData),
      });
    }

    if (deletable) {
      actions.push({
        icon: "delete",
        tooltip: "Delete action",
        onClick: (event, rowData) => this.actionDeleteAction(event, rowData),
      });
    }
    return actions;
  }

  onChangeActionForm(data) {
    this.setState({
      onErrorFilter: false,
      checkFilter: false,
      action: {
        id: data.id,
        type: data.type,
        filters: data.filters,
        time_interval: data.time_interval,
        timeout: data.timeout,
        tags: data.tags,
        replier_asset_id: data.replier_asset_id,
      },
    });
  }

  onErrorActionForm() {
    this.setState({
      onErrorFilter: true,
    });
  }

  checkForErrors() {
    const { action, onErrorFilter } = this.state;
    const promise = new Promise((resolve, reject) => {
      this.setState(
        {
          error: {
            id: action.id ? "" : "ID is mandatory",
            type: action.type ? "" : "Type is mandatory",
            tags: action.tags ? "" : "Tags is mandatory",
            time_interval:
              action.type === "check_alert" && !action.time_interval
                ? "Time interval is mandatory"
                : "",
            timeout:
              action.type === "check_alert" && !action.time_interval
                ? "Timeout is mandatory"
                : "",
            filters: onErrorFilter ? "JSON not valid" : "",
          },
        },
        () => {
          resolve();
        }
      );
    });

    return promise;
  }

  updateAction = () => {
    this.setState(
      {
        checkFilter: true,
      },
      () => {
        setTimeout(() => {
          this.checkForErrors().then(() => {
            if (
              !Object.values(this.state.error).filter((value) => value !== "")
                .length
            ) {
              if (this.state.mode === "add") {
                this.props.addAction(this.state.action);
              } else {
                this.props.editAction(
                  this.state.tagSelected,
                  this.state.action
                );
              }
            }
          });
        }, 100);
      }
    );
  };

  openDialogActions() {
    this.setState({
      openDialogActions: true,
    });
  }

  closeDialogActions = () => {
    if (!this.state.editingAction) {
      this.setState({
        openDialogActions: false,
      });
    }
  };

  actionAddAction = (e) => {
    const defaultValue = {
      id: "",
      type: "",
      filters: [],
      time_interval: "",
      timeout: "",
      tags: [],
      replier_asset_id: "",
    };

    this.setState(
      {
        mode: "add",
        defaultValue: defaultValue,
        action: defaultValue,
      },
      () => {
        this.openDialogActions();
      }
    );
  };

  actionDeleteAction(e, data) {
    this.setState({
      openDialogDeleteAction: true,
      actionDeleting: data.id,
    });
  }

  actionDialogDeleteAction(state) {
    if (state) {
      this.props.deleteAction(this.state.actionDeleting);
    }

    this.setState({
      openDialogDeleteAction: false,
    });
  }

  actionEditAction(e, data) {
    const defaultValue = {
      id: data.id,
      type: data.type,
      filters: data.filters,
      tags: data.tags,
      replier_asset_id: data.replier_asset_id,
      time_interval:
        data.volume_config && data.volume_config.time_interval
          ? data.volume_config.time_interval
          : "",
      timeout:
        data.volume_config && data.volume_config.timeout
          ? data.volume_config.timeout
          : "",
    };

    this.setState(
      {
        mode: "edit",
        tagSelected: data.tableData.id,
        defaultValue: defaultValue,
        action: defaultValue,
      },
      () => {
        this.openDialogActions();
      }
    );
  }

  displayButtonsAction() {
    const { classes } = this.props;
    const { mode, editingAction } = this.state;

    return (
      <div className={classes.buttonContainer}>
        <Button
          disabled={editingAction}
          size="small"
          color="primary"
          onClick={this.updateAction}
        >
          {editingAction && <CircularProgress className={classes.loading} />}
          {mode === "add" ? "Add action" : "Edit action"}
        </Button>
        <Button
          onClick={this.closeDialogActions}
          size="small"
          color="secondary"
          disabled={editingAction}
        >
          Cancel
        </Button>
      </div>
    );
  }

  downloadJson = () => {
    downloadAsJson(
      this.state.data,
      this.props.clientShortName +
        "_actions_" +
        moment().format("YYYY-MM-DD") +
        "_" +
        moment().format("hh_mm_A")
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.actionList !== this.props.actionList) {
      this.setState({
        data: this.props.actionList,
      });
    }

    if (prevProps.editingAction !== this.props.editingAction) {
      this.setState({
        editingAction: this.props.editingAction,
        openDialogActions:
          !!prevProps.editingAction && !this.props.editingAction
            ? false
            : this.state.openDialogActions,
      });
    }

    if (prevProps.replierAssetsList !== this.props.replierAssetsList) {
      this.setState({
        replierAssetsList: this.props.replierAssetsList,
      });
    }

    if (prevProps.typeList !== this.props.typeList) {
      this.setState({
        typeList: this.props.typeList,
      });
    }

    if (prevProps.tagList !== this.props.tagList) {
      this.setState({
        tagList: this.props.tagList,
      });
    }

    if (prevProps.creatable !== this.props.creatable) {
      this.setState({
        creatable: this.props.creatable,
      });
    }

    if (prevProps.editable !== this.props.editable) {
      this.setState({
        editable: this.props.editable,
        actions: this.onCreateActions(
          this.props.editable,
          this.props.deletable
        ),
      });
    }

    if (prevProps.deletable !== this.props.deletable) {
      this.setState({
        deletable: this.props.deletable,
        actions: this.onCreateActions(
          this.props.editable,
          this.props.deletable
        ),
      });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      columns,
      data,
      actions,
      openDialogActions,
      defaultValue,
      error,
      typeList,
      creatable,
      openDialogDeleteAction,
      tagList,
      clientTags,
      checkFilter,
      mode,
      replierAssetsList,
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h5">action list</Typography>
          <IconButton
            className={classes.buttonDownload}
            onClick={this.downloadJson}
          >
            <SystemUpdateAltIcon />
          </IconButton>
          {creatable ? (
            <Button
              variant="contained"
              className={classes.buttonAddAction}
              size="small"
              color="primary"
              onClick={this.actionAddAction}
            >
              Add action
            </Button>
          ) : (
            ""
          )}
        </div>
        <TableComponent
          editable={false}
          columns={columns}
          data={data}
          actions={actions}
          title=""
        />
        <DialogContent
          title={"action"}
          open={openDialogActions}
          fullWidth={true}
          close={this.closeDialogActions.bind(this)}
        >
          <div>
            <ActionsForm
              mode={mode}
              defaultValue={defaultValue}
              onChangeActionForm={this.onChangeActionForm.bind(this)}
              onErrorActionForm={this.onErrorActionForm.bind(this)}
              error={error}
              checkFilter={checkFilter}
              tagList={tagList}
              typeList={typeList}
              clientTags={clientTags}
              replierAssetsList={replierAssetsList}
            />
            {this.displayButtonsAction()}
          </div>
        </DialogContent>
        <AlertDialog
          title="Delete Action"
          message="Do you want to delete this action?"
          buttonCancelText="no"
          buttonAcceptText="yes"
          open={openDialogDeleteAction}
          action={this.actionDialogDeleteAction.bind(this)}
        />
      </div>
    );
  }
}

ActionsConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  addAction: PropTypes.func.isRequired,
  editAction: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  clientTags: PropTypes.array.isRequired,
  actionList: PropTypes.array,
  replierAssetsList: PropTypes.array,
  editingAction: PropTypes.bool,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  creatable: PropTypes.bool,
  typeList: PropTypes.array,
  tagList: PropTypes.array,
};

export default withStyles(styles, { withTheme: true })(ActionsConfiguration);
