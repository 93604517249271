import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, fade } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  secondHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.grey[200],
    minHeight: 50
  },
  searchButton: {
    position: 'absolute',
    height: 35,
    marginLeft: 6
  },
  createCodeContainer: {
    paddingLeft: theme.spacing(1)
  },
  createCodeButton: {
    height: 35
  },
  search: {
    position: 'absolute',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.55),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    top: 58,
    right: 0,
    marginRight: 85,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cancelIcon: {
    cursor: 'pointer',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 6,
    right: 6
  },
  inputRoot: {
    color: 'inherit',
    paddingRight: 30
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200
      }
    }
  }
}));

const SubHeaderComponent = ({
  actionName,
  action,
  searchResult,
  actionDisabled
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  return (
    <div className={classes.secondHeader}>
      <div className={classes.createCodeContainer}>
        {typeof action === 'function' ? (
          <Button
            size="small"
            color="primary"
            disabled={actionDisabled}
            className={classes.createCodeButton}
            variant="contained"
            onClick={e => action(e)}
          >
            {actionName}
          </Button>
        ) : (
          ''
        )}
      </div>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
          inputProps={{ 'aria-label': 'search' }}
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        {search ? (
          <div onClick={() => setSearch('')} className={classes.cancelIcon}>
            <CancelIcon />
          </div>
        ) : (
          ''
        )}
        <Button
          size="small"
          color="primary"
          className={classes.searchButton}
          variant="contained"
          onClick={() => searchResult(search)}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

SubHeaderComponent.propTypes = {
  actionName: PropTypes.string,
  action: PropTypes.func,
  searchResult: PropTypes.func.isRequired
};

export default SubHeaderComponent;
