import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

import InputComponent from '../../commons/input/inputComponent';
import SelectComponent from '../../commons/select/selectComponent';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  formControlContainer: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  formGroup: {
    width: '50%'
  }
}));

const UCMForm = props => {
  const classes = useStyles();

  const displayTextField = (
    name,
    label,
    required,
    disabled,
    multiline,
    fullWidth
  ) => {
    return (
      <InputComponent
        label={label}
        required={required}
        disabled={disabled}
        multiline={multiline}
        fullWidth={fullWidth}
        defaultValue={props.formData[name]}
        onChange={e => {
          return props.onChangeFormData(e, name);
        }}
        error={props.errors[name] ? true : false}
        helperText={props.errors[name]}
        margin="dense"
      />
    );
  };

  return (
    <div className={classes.formControlContainer}>
      <FormControl className={classes.formControl}>
        <FormGroup className={classes.formGroup}>
          {displayTextField('email', 'Email', true, false, false, true)}
          {displayTextField(
            'firstName',
            'First name',
            true,
            false,
            false,
            true
          )}
          {displayTextField('lastName', 'Last name', true, false, false, true)}
          {displayTextField(
            'clientShortName',
            'Client Short Name',
            true,
            false,
            false,
            true
          )}
          <SelectComponent
            id={'roles'}
            label={'Roles'}
            multiple={true}
            value={
              props.formData && props.formData['roles']
                ? props.formData['roles']
                : []
            }
            items={props.UCMRoles}
            attributId={'id'}
            attributName={'name'}
            onChange={(e, id) => props.onChangeFormData(e.target.value, id)}
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};

UCMForm.propTypes = {
  formData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChangeFormData: PropTypes.func.isRequired
};

export default UCMForm;
