import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import PostRelated from "../../postRelated/postRelatedComponent";

const styles = (theme) => ({
  legendContainer: {
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(1),
    height: 140,
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.container.light,
  },
  messageContainer: {
    width: "50%",
    height: 125,
  },
  message: {
    height: 120,
    display: "flex",
    justifyContent: "space-around",
    overflow: "auto",
  },
  messageTranslated: {
    fontStyle: "italic",
  },
  messageCol: {
    display: "flex",
    flexFlow: "column",
    "& span": {
      fontStyle: "normal",
      fontWeight: "bold",
      marginBottom: 10,
    },
  },
  postContainer: {
    width: "50%",
  },
});

class Legend extends Component {
  render() {
    const { classes, item } = this.props;
    return (
      <div className={classes.legendContainer}>
        <div className={classes.messageContainer}>
          <div className={classes.message}>
            <div className={classes.messageCol}>
              <span>Original message</span>
              {item.message}
            </div>
            <div
              className={`${classes.messageTranslated} ${classes.messageCol}`}
            >
              <span>Translated message</span>
              {item.messageTranslated}
            </div>
          </div>
        </div>
        <div className={classes.postContainer}>
          <PostRelated
            statistics={{}}
            hideTopMenu={true}
            hideBottomMenu={true}
            post={item["post"] ? item["post"] : null}
          />
        </div>
      </div>
    );
  }
}

Legend.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Legend);
