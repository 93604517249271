import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import DoneIcon from "@material-ui/icons/Done";
import TranslateIcon from "@material-ui/icons/GTranslate";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";

import Attachment from "../attachment/attachmentComponent";
import SimpleMenu from "../simpleMenu/simpleMenuComponent";
import Tooltip from "../tooltip/TooltipComponent";
import DialogContent from "../dialog/contentDialogComponent";
import DialogActions from "@material-ui/core/DialogActions";
import Steppers from "../steppers/steppersComponent";
import ModerationHistory from "../history/historyModerationComponent";
import ReplyHistory from "../history/historyReplyComponent";
import InitialStatusHistory from "../history/historyStatusInitialComponent";
import ActionHistory from "../history/historyActionComponent";
import StatusChangedHistory from "../history/historyStatusChangeComponent";
import CopyrightHistory from "../history/historyCopyrightComponent";
import { TrustpilotStars } from "../trustpilotStars/TrustpilotStarsComponent";
import { GoogleBusinessStars } from "../googleBusnessStars/GoogleBusnessStars";

import { formatDateCustom } from "../../../setters/date";

const starMapping = {
  ONE_STAR: 1,
  TWO_STAR: 2,
  THREE_STAR: 3,
  FOUR_STAR: 4,
  FIVE_STAR: 5,
};

export const getStarNumber = (starType) => {
  return starType ? starMapping[starType] : null;
};

const styles = (theme) => ({
  content: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "100px",
  },
  center: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
    paddingLeft: "5px",
  },
  centerValidated: {
    width: "calc(100% - 200px)",
  },
  centerWithTag: {
    maxHeight: "90px",
  },
  rightSide: {
    minWidth: "100px",
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    marginRight: 8,
    marginLeft: 15,
  },
  avatar: {
    borderRadius: 0,
    width: "30px",
    height: "30px",
    padding: "3px",
    backgroundColor: "#fff",
  },
  date: {
    paddingTop: theme.spacing(1),
    fontSize: "9px",
  },
  name: {
    fontSize: "10px",
  },
  divider: {
    width: "100%",
    height: "1px",
    margin: "10px 0px",
    backgroundColor: theme.palette.container.light,
  },
  commentHalfWidth: {
    width: "50%",
  },
  comment: {
    fontSize: "14px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  reviewHeader: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
  },
  reviewTitle: {
    fontSize: "13px",
    fontWeight: 500,
    "& span": {
      fontWeight: 700,
    },
  },
  reviewLabel: {
    height: 20,
    fontSize: 11,
    fontWeight: 700,
    borderRadius: "4px",
  },
  reviewLabelNegative: {
    color: "#4E0B0C",
    backgroundColor: "#F9D8D8",
  },
  reviewLabelPositive: {
    color: "#002D14",
    backgroundColor: "#DEFBE7",
  },
  starIcon: {
    fontSize: 18,
  },
  commentTranslated: {
    fontStyle: "italic",
  },
  textSelected: {
    color: theme.palette.white,
  },
  labelSelected: {
    color: theme.palette.white,
    borderColor: theme.palette.white,
  },
  selectedIcon: {
    backgroundColor: theme.palette.secondary[200],
    padding: 14,
    top: 16,
    borderRadius: 26,
    position: "absolute",
    right: -40,
    cursor: "pointer",
    width: "52px !important",
    height: 52,
    display: "inline !important",
    boxShadow: theme.palette.shadow.shadowDefault,

    "&:hover": {
      backgroundColor: theme.palette.secondary[400],
    },
  },
  hiddenIcon: {
    display: "none",
  },
  disabledIcon: {
    opacity: 0.5,
  },
  visibleIcon: {
    display: "inline !important",
    width: 25,
  },
  doneParent: {
    color: theme.palette.white,
  },
  parentError: {
    color: theme.palette.white,
    margin: "5px",
  },
  translate: {
    color: theme.palette.white,
  },
  translateIcon: {
    cursor: "pointer",
    fontSize: 18,
  },
  translated: {
    color: theme.palette.container.dark,
  },
  icon: {
    margin: 0,
    color: "white",
    cursor: "pointer",
    fontSize: 18,
    width: 25,
  },
  emptyIcon: {
    color: theme.palette.primary.dark,
  },
  iconBlack: {
    color: theme.palette.black,
  },
  iconYellow: {
    color: theme.palette.warning[500],
  },
  iconRed: {
    color: `${theme.palette.error[500]} !important`,
  },
  iconList: {
    display: "flex",
    alignItems: "flex-start",
    marginRight: theme.spacing(2),
  },
  isTranslating: {
    color: theme.palette.white,
  },
  isModerating: {
    color: "black",
  },
  isLabel: {
    margin: theme.spacing(1),
    marginBottom: 0,
    height: 20,
    fontSize: 11,
  },
  postTitle: {
    fontSize: 16,
    fontWeight: 700,
    marginLeft: "10px",
  },
  experiencedAt: {
    fontSize: 13,
    marginLeft: "10px",
  },
  hasExperiencedDate: {
    "& p": {
      marginBottom: 4,
    },
  },
  messageType: {
    padding: 4,
    fontSize: 11,
    marginTop: 6,
    maxWidth: 110,
    marginBottom: 6,
    borderRadius: 10,
    color: theme.palette.white,
    backgroundColor: "#00215F",
  },
  attachmentContainer: {
    marginLeft: 10,
    marginBottom: 8,
  },
  messageContainer: {
    display: "flex",
    maxHeight: "150px",
    overflow: "auto",
    wordBreak: "break-word",
  },
  doneIcon: {
    marginTop: -4,
  },
  youtubeRedContainer: {
    backgroundColor: theme.palette.white,
    height: 12,
    display: "block",
    width: 16,
    marginLeft: 3,
    marginTop: 3,
  },
  youtubeRed: {
    color: "#F40E01",
    marginTop: -3,
    marginLeft: -6,
  },
  tiktokIcon: {
    color: "white",
    position: "relative",
    top: -3,
  },
  trustpilotIcon: {
    position: "relative",
    top: -3,
  },
  googleBusinessIconWrapper: {
    position: "relative",
    width: 19,
    height: 19,
    top: -1,
    backgroundColor: "white",
    borderRadius: 19,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  googleBusinessIcon: {
    width: 13,
    height: 13,
  },
  modalText: {
    fontSize: 18,
  },
  modalConfirmButton: {
    color: "white",
    backgroundColor: "#D23031",
    width: 163,
    height: 40,
    marginTop: 30,
    textTransform: "none",
    fontSize: 16,

    "&:hover": {
      backgroundColor: "#D23031",
    },
  },
  modalCancelButton: {
    color: "#006DD9",
    backgroundColor: "white",
    textTransform: "none",
    width: 87,
    height: 40,
    marginTop: 30,
    fontSize: 16,

    "&:hover": {
      backgroundColor: "white",
    },
  },
});

class ListItemContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      translated: props.translated,
      displayBoth: props.displayBoth,
      actionsMenu: props.actionsMenu,
      isFavourite: props.item[1] ? props.item[1]["isFavorite"] : false,
      openDialog: false,
      openDeleteConfirmationModal: false,
      deleteConfirmationModalData: {},
      isTranslating: false,
    };

    this.translateItem.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translated !== this.props.translated) {
      this.setState({
        translated: this.props.translated,
      });
    }

    if (prevProps.displayBoth !== this.props.displayBoth) {
      this.setState({
        displayBoth: this.props.displayBoth,
      });
    }

    if (
      prevProps.item[1].classified !== this.props.item[1].classified &&
      this.props.item[1].classified
    ) {
      this.setState(
        {
          actionsMenu: this.props.actionsMenu,
        },
        () => {
          this.setActions();
        }
      );
    }

    if (prevProps.item[1].isFavorite !== this.props.item[1].isFavorite) {
      this.setState({
        isFavourite: this.props.item[1].isFavorite,
      });
    }
  }

  closeDialog() {
    this.setState({
      openDialog: false,
    });
  }

  closeDeleteConfirmationModal() {
    this.setState({
      openDeleteConfirmationModal: false,
      deleteConfirmationModalData: {
        ...this.state.deleteConfirmationModalData,
        data: {
          ...this.state.deleteConfirmationModalData.data,
          value: false,
        },
      },
    });
  }

  resetTranslationIcon(messageTranslated) {
    const isNew = messageTranslated
      ? messageTranslated.localeCompare(this.state.messageTranslated)
      : false;
    this.setState({
      translated: isNew ? !this.state.translated : this.state.translated,
      isTranslating: false,
      messageTranslated: isNew
        ? messageTranslated
        : this.state.messageTranslated,
    });
  }

  translateItem(item, translationAvailable) {
    if (translationAvailable) {
      this.setState({
        translated: !this.state.translated,
      });
    } else {
      this.setState({
        isTranslating: true,
      });
      this.props.updateTranslation(item, this.resetTranslationIcon.bind(this));
    }
  }

  setActions() {
    const actions = Object.assign([], this.state.actionsMenu);
    const { item, readOnly, parent } = this.props;
    const postPlacement = item[1] && item[1]["postPlacement"];
    const platformName = item[1] && item[1]["platformName"];
    const shortName =
      item[1] && item[1]["client"] ? item[1]["client"]["shortName"] : "";

    if (
      !actions.filter((action) => {
        return action.name === "explorer";
      }).length
    ) {
      const href =
        postPlacement === "DM"
          ? `/explorer?client_short_name=${shortName}&post_ids=${item[1]["postId"]}`
          : `/explorer?client_short_name=${shortName}&comment_ids=${item[1]["id"]}`;

      actions.push({
        href,
        label: "Explore the comment",
        name: "explorer",
      });
    }

    if (
      platformName === "linkedin" &&
      item[1] &&
      item[1]["isDeleted"] === false
    ) {
      actions.unshift({
        action: "delete",
        href: null,
        label: "Delete",
        data: {
          label: "isDeleted",
          value: false,
          prefix: false,
        },
      });
    }

    if (
      window.location.pathname.includes("/alerts") &&
      item[1] &&
      item[1].alert &&
      item[1].alert.adbastion_notification &&
      item[1].alert.adbastion_notification.link_comments
    ) {
      actions.push({
        href: item[1].alert.adbastion_notification.link_comments,
        label: "Explore Alert comments",
        name: "explore_alert_comments",
      });
    }

    this.setState({
      actionsMenu: actions.filter((i) => {
        return (
          i.href ||
          (i.action === "history" && !parent) ||
          (((item[1]["tags"] &&
            item[1]["tags"].find((tag) => tag.id === "hide") &&
            i.action === "hide" &&
            platformName !== "linkedin" &&
            i.data.value === item[1].isHidden &&
            !readOnly &&
            !parent) ||
            (item[1]["tags"] &&
              item[1]["tags"].find((tag) => tag.id === "unhide") &&
              i.action === "unhide" &&
              i.data.value === item[1].isHidden &&
              !readOnly &&
              !parent) ||
            (i.action === "unlike" &&
              i.data.value === item[1].isLiked &&
              !readOnly &&
              !parent) ||
            (item[1]["tags"] &&
              item[1]["tags"].find((tag) => tag.id === "unsure") &&
              i.action === "unsure" &&
              i.data.value === item[1].isUnsure &&
              !readOnly &&
              !parent) ||
            (item[1]["tags"] &&
              item[1]["tags"].find((tag) => tag.id === "delete") &&
              i.action === "delete" &&
              i.data.value === item[1].isDeleted &&
              !readOnly &&
              !parent)) &&
            i.action !== "favorite")
        );
      }),
    });
  }

  clickClassificationEvent() {
    this.props.classificationEvent();
  }

  getPlatformIcon(name) {
    if (name === "facebook") {
      return `fab fa-${name}-f`;
    }
    return `fab fa-${name}`;
  }

  addToFavourite() {
    const { actionsMenu, readOnly } = this.props;
    const data = actionsMenu.find((item) => {
      return item.action === "favorite";
    });
    if (!readOnly) {
      this.setState({
        isFavourite: !this.state.isFavourite,
      });
      this.clickItemFromComment("favorite", data);
    }
  }

  clickItemFromComment(action, data, callback) {
    if (action === "delete") {
      this.setState({
        openDeleteConfirmationModal: true,
        deleteConfirmationModalData: {
          data: {
            label: "isDeleted",
            prefix: false,
            value: true,
          },
          callback,
        },
      });
      return;
    }

    if (typeof this.props.clickItemFromComment === "function") {
      this.props.clickItemFromComment(action, data, callback);
    }

    if (action === "history") {
      this.setState({
        openDialog: true,
      });
    }
  }

  onConfirmDeleteComment() {
    if (typeof this.props.clickItemFromComment === "function") {
      this.props.clickItemFromComment(
        "delete",
        this.state.deleteConfirmationModalData.data,
        this.state.deleteConfirmationModalData.callback
      );
      this.closeDeleteConfirmationModal();
    }
  }

  componentDidMount() {
    const { item } = this.props;
    this.setActions();

    this.setState({
      message: item && item[1] ? item[1].message : "",
      messageTranslated: item && item[1] ? item[1].messageTranslated : "",
    });
  }

  render() {
    const {
      classes,
      item,
      index,
      parent,
      classificationEvent,
      displayBoth,
      isAddingTag,
      readOnly,
    } = this.props;
    const {
      translated,
      actionsMenu,
      isFavourite,
      openDialog,
      openDeleteConfirmationModal,
      isTranslating,
      message,
      messageTranslated,
    } = this.state;
    const translationAvailable =
      (message !== "" || messageTranslated !== "") &&
      message &&
      message.localeCompare(messageTranslated);

    const isFacebookReview =
      item[1].post &&
      (item[1].post.recommendation_type === "POSITIVE" ||
        item[1].post.recommendation_type === "NEGATIVE");

    const isGoogleBusinessReview =
      item[1].post &&
      item[1]["platformName"] &&
      item[1]["platformName"].includes("google business") &&
      Object.keys(starMapping).includes(item[1].post.recommendation_type);

    const isTrustpilotReview =
      item[1].post &&
      item[1]["platformName"] &&
      item[1]["platformName"].includes("trustpilot") &&
      Object.keys(starMapping).includes(item[1].post.recommendation_type);

    const title = (item[1].tp_data && item[1].title) || null;
    const trustpilotStars = isTrustpilotReview
      ? getStarNumber(item[1].post.recommendation_type)
      : 0;
    const googleBusinessStars = isGoogleBusinessReview
      ? getStarNumber(item[1].post.recommendation_type)
      : 0;

    const getHideTooltip = () => {
      let tooltip = "";

      if (item[1].isHidden) {
        if (item[1].actionOutsideOfCamp === "hidden") {
          tooltip = "Comment hidden outside of CAMP";
        } else {
          tooltip = "Comment hidden by CAMP";
        }
      } else {
        if (item[1].actionOutsideOfCamp === "visible") {
          tooltip = "Comment visible outside of CAMP";
        } else {
          tooltip = "Comment visible by CAMP";
        }
      }

      return tooltip;
    };

    const getDeleteTooltip = () => {
      let tooltip = "";

      if (item[1].isDeleted) {
        if (item[1].actionOutsideOfCamp === "deleted") {
          tooltip = "Comment deleted outside of CAMP";
        } else {
          tooltip = "Comment deleted by CAMP";
        }
      }

      return tooltip;
    };

    return (
      <div className={classes.content}>
        <div className={classes.leftSide}>
          <Avatar
            className={classes.avatar}
            src={item[1] && item[1].client ? item[1].client.logo : ""}
          />
          <Typography
            className={classNames(classes.date, {
              [classes.textSelected]: index === item[0] || parent,
            })}
            align="center"
            variant="subtitle1"
          >
            {(item.length && item[1].date) || "0000-00-00T00:00:00"}
          </Typography>
          <Typography
            className={classNames(classes.name, {
              [classes.textSelected]: index === item[0] || parent,
            })}
            align="center"
            variant="subtitle2"
          >
            {item[1] && item[1].client ? item[1].client.name : ""}
          </Typography>
          <Typography
            className={classNames(classes.name, {
              [classes.textSelected]: index === item[0] || parent,
            })}
            align="center"
            variant="subtitle2"
          >
            {item[1].userName}
          </Typography>
          {item[1].isVisitorPost ? (
            <Chip
              className={classNames(classes.isLabel, {
                [classes.labelSelected]: index === item[0] || parent,
              })}
              variant="outlined"
              size="small"
              label="Visitor Post"
            />
          ) : null}
          {item[1].isOwned ? (
            <Chip
              className={classNames(classes.isLabel, {
                [classes.labelSelected]: index === item[0] || parent,
              })}
              variant="outlined"
              size="small"
              label="Brand Comment"
            />
          ) : null}
          {item[1].commentPhrase ? (
            <div className={classes.messageType}>{item[1].commentPhrase}</div>
          ) : null}
        </div>
        <div
          className={classNames(classes.center, {
            [classes.centerValidated]:
              item[1].classified ||
              index === item[0] ||
              parent ||
              item[1].isClassifying,
          })}
        >
          {item[1].postPlacement === "REVIEW" && (
            <>
              <div className={classes.reviewHeader}>
                {isFacebookReview && (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/${
                      item[1].post.recommendation_type === "POSITIVE"
                        ? "facebookRecommends"
                        : "facebookDoesntRecommend"
                    }.svg`}
                    alt={item[1].post.recommendation_type}
                  />
                )}
                {item[1].post && (
                  <Typography
                    className={classNames(classes.reviewTitle, {
                      [classes.textSelected]: index === item[0] || parent,
                    })}
                  >
                    Reviewed <span>{item[1].post.pageName}</span>:
                  </Typography>
                )}
                {!!trustpilotStars && (
                  <TrustpilotStars numberOfStars={trustpilotStars} />
                )}
                {!!googleBusinessStars && (
                  <GoogleBusinessStars numberOfStars={googleBusinessStars} />
                )}
                {item[1].post &&
                  item[1].post.recommendation_type &&
                  isFacebookReview && (
                    <Chip
                      className={classNames(classes.reviewLabel, {
                        [classes.reviewLabelNegative]:
                          item[1].post.recommendation_type === "NEGATIVE",
                        [classes.reviewLabelPositive]:
                          item[1].post.recommendation_type === "POSITIVE",
                      })}
                      size="small"
                      label={
                        item[1].post.recommendation_type === "POSITIVE"
                          ? "Recommends"
                          : "Doesn't recommend"
                      }
                    />
                  )}
              </div>
              <Divider className={classes.divider} />
            </>
          )}
          {!!title && (
            <Typography
              className={classNames(classes.postTitle, {
                [classes.textSelected]: index === item[0] || parent,
              })}
            >
              {title}
            </Typography>
          )}
          <div
            className={classNames(classes.messageContainer, {
              [classes.centerWithTag]:
                item.length &&
                item[1].tags &&
                Object.values(item[1].tags).length > 0,
              [classes.hasExperiencedDate]: !!(
                item[1].tp_data && item[1].tp_data.experienced_at
              ),
            })}
          >
            <Typography
              className={classNames(classes.comment, {
                [classes.textSelected]: index === item[0] || parent,
                [classes.commentHalfWidth]:
                  displayBoth &&
                  translated &&
                  message &&
                  message.localeCompare(messageTranslated),
              })}
              align="left"
              paragraph
            >
              {translated && messageTranslated && !displayBoth
                ? messageTranslated
                : message}
            </Typography>
            {displayBoth &&
            translated &&
            messageTranslated &&
            message &&
            message.localeCompare(messageTranslated) ? (
              <Typography
                className={classNames(classes.comment, {
                  [classes.commentTranslated]: true,
                  [classes.commentHalfWidth]: displayBoth,
                  [classes.textSelected]: index === item[0] || parent,
                })}
                align="left"
                paragraph
              >
                {messageTranslated}
              </Typography>
            ) : (
              ""
            )}
          </div>
          {!!item[1].tp_data && (
            <Typography
              className={classNames(classes.experiencedAt, {
                [classes.textSelected]: index === item[0] || parent,
              })}
            >
              {`Date of experience: ${formatDateCustom(
                item[1].tp_data.experienced_at,
                "DD MMM, YYYY"
              )}`}
            </Typography>
          )}
          <div className={classes.attachmentContainer}>
            {item[1] && item[1].attachments && item[1].attachments.length ? (
              <Attachment
                attachments={item[1].attachments}
                isSelected={parent || index === item[0]}
                imageSize={400}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={classes.rightSide}>
          <div className={classes.iconList}>
            <div
              className={classNames(classes.hiddenIcon, {
                [classes.visibleIcon]:
                  index === item[0] &&
                  !parent &&
                  item[1]["tags"] &&
                  item[1]["tags"].find((tag) => tag.id === "favorite"),
              })}
              onClick={this.addToFavourite.bind(this)}
            >
              <Icon
                className={classNames(classes.icon, classes.emptyIcon, {
                  [classes.iconYellow]: isFavourite,
                })}
              >
                star
              </Icon>
            </div>

            <div
              className={classNames(classes.hiddenIcon, {
                [classes.visibleIcon]: index === item[0] || parent,
              })}
            >
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={item[1].commentUrl}
                className={classNames({
                  [classes.youtubeRedContainer]:
                    item &&
                    item[1] &&
                    item[1]["platformName"] &&
                    item[1]["platformName"].includes("youtube"),
                })}
              >
                {item &&
                item[1] &&
                item[1]["platformName"] &&
                item[1]["platformName"].includes("tiktok") ? (
                  <span
                    className={classNames(classes.tiktokIcon, "material-icons")}
                  >
                    tiktok
                  </span>
                ) : item && !!item[1].tp_data ? (
                  <div className={classes.trustpilotIcon}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/trustpilotStar.svg`}
                      alt="trustpilot"
                    />
                  </div>
                ) : item &&
                  item[1] &&
                  item[1]["platformName"].includes("google business") ? (
                  <div className={classes.googleBusinessIconWrapper}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/googleBusiness.svg`}
                      alt="google_business"
                      className={classes.googleBusinessIcon}
                    />
                  </div>
                ) : (
                  <Icon
                    className={classNames(
                      classes.icon,
                      this.getPlatformIcon(item[1]["platformName"]),
                      {
                        [classes.youtubeRed]:
                          item &&
                          item[1] &&
                          item[1]["platformName"] &&
                          item[1]["platformName"].includes("youtube"),
                      }
                    )}
                  />
                )}
              </a>
            </div>
            <div
              className={classNames(classes.hiddenIcon, {
                [classes.disabledIcon]:
                  message === "" ||
                  (message && !message.localeCompare(messageTranslated)),
                [classes.visibleIcon]: index === item[0] && message,
                [classes.translate]: true,
                [classes.translated]: translated && messageTranslated,
              })}
              onClick={(e) => this.translateItem(item, translationAvailable)}
            >
              <Tooltip
                title={
                  index === item[0] && translationAvailable ? (
                    <p>translate</p>
                  ) : (
                    <p>translation not available (click to retry)</p>
                  )
                }
              >
                <div>
                  {!isTranslating ? (
                    <TranslateIcon className={classes.translateIcon} />
                  ) : (
                    ""
                  )}
                  {isTranslating ? (
                    <CircularProgress
                      className={classes.isTranslating}
                      size={18}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </Tooltip>
            </div>
            <div
              className={classNames(classes.hiddenIcon, {
                [classes.visibleIcon]: item[1].isHidden,
              })}
            >
              <Tooltip title={<p>{getHideTooltip()}</p>}>
                <Icon
                  className={classNames(classes.icon, classes.iconBlack, {
                    [classes.iconYellow]: index === item[0],
                    "fas fa-eye-slash": item[1].isHidden,
                    "fas fa-eye": !item[1].isHidden,
                    [classes.iconRed]: !!item[1].actionOutsideOfCamp,
                  })}
                />
              </Tooltip>
            </div>
            <div
              className={classNames(classes.hiddenIcon, {
                [classes.visibleIcon]:
                  index === item[0] && item[1].cannotBeLoaded,
              })}
            >
              <Tooltip title={<p>Comment might be removed outside of CAMP</p>}>
                <Icon
                  className={classNames(
                    classes.icon,
                    classes.iconBlack,
                    "fas fa-exclamation",
                    {
                      [classes.iconYellow]: index === item[0],
                    }
                  )}
                />
              </Tooltip>
            </div>
            <div
              className={classNames(classes.hiddenIcon, {
                [classes.visibleIcon]: item[1].isUnsure,
              })}
            >
              <Tooltip title={<p>Comment unsure</p>}>
                <Icon
                  className={classNames(
                    classes.icon,
                    classes.iconBlack,
                    "fab fa-firstdraft",
                    {
                      [classes.iconYellow]: index === item[0],
                    }
                  )}
                />
              </Tooltip>
            </div>
            <div
              className={classNames(classes.hiddenIcon, {
                [classes.visibleIcon]: item[1].isDeleted,
              })}
            >
              <Tooltip title={<p>{getDeleteTooltip()}</p>}>
                <Icon
                  className={classNames(classes.icon, "far fa-trash-alt", {
                    [classes.iconRed]: !!item[1].actionOutsideOfCamp,
                  })}
                />
              </Tooltip>
            </div>
            <div
              className={classNames(classes.hiddenIcon, {
                [classes.visibleIcon]: item[1].isLiked && !readOnly,
              })}
            >
              <Tooltip title={<p>Comment is liked</p>}>
                <Icon
                  className={classNames(
                    classes.icon,
                    classes.emptyIcon,
                    "far fa-thumbs-up"
                  )}
                />
              </Tooltip>
            </div>
            <div
              className={classNames(classes.hiddenIcon, {
                [classes.visibleIcon]: index === item[0] || parent,
              })}
            >
              {
                <SimpleMenu
                  iconWhite={true}
                  clickItemFromComment={(string, data, callback) => {
                    return this.clickItemFromComment(string, data, callback);
                  }}
                  prefix={"Remove"}
                  items={actionsMenu}
                />
              }
            </div>
          </div>
          <div
            className={classNames(classes.hiddenIcon, {
              [classes.visibleIcon]:
                item[1].isClassifying &&
                !item[1].classified &&
                index !== item[0],
            })}
          >
            <CircularProgress className={classes.isModerating} size={18} />
          </div>
          <div
            className={classNames(classes.hiddenIcon, {
              [classes.selectedIcon]: index === item[0] && !parent && !readOnly,
              [classes.visibleIcon]: item[1].classified,
              [classes.doneParent]: parent,
            })}
            onClick={() => {
              if (
                typeof classificationEvent === "function" &&
                !item[1].isClassifying
              ) {
                return this.clickClassificationEvent();
              }
              return false;
            }}
          >
            {(isAddingTag || item[1].isClassifying) &&
            index === item[0] &&
            !parent &&
            !readOnly ? (
              <CircularProgress className={classes.isModerating} size={20} />
            ) : (
              <DoneIcon
                className={classNames({
                  [classes.doneIcon]: index !== item[0],
                })}
              />
            )}
          </div>
        </div>
        <DialogContent
          title={"History"}
          open={openDialog}
          close={this.closeDialog.bind(this)}
        >
          <Steppers steps={item[1].history}>
            <div>
              <InitialStatusHistory />
              <ModerationHistory />
              <ReplyHistory />
              <ActionHistory />
              <StatusChangedHistory />
              <CopyrightHistory />
            </div>
          </Steppers>
        </DialogContent>
        <DialogContent
          open={openDeleteConfirmationModal}
          close={this.closeDeleteConfirmationModal.bind(this)}
          title={"Are you sure you want to delete this comment?"}
        >
          <div className={classes.modalText}>
            <strong>This action cannot be undone.</strong>
          </div>
          <div className={classes.modalText}>
            All likes and replies on this comment will also be removed.
          </div>

          <DialogActions>
            <Button
              color="secondary"
              className={classes.modalCancelButton}
              onClick={this.closeDeleteConfirmationModal.bind(this)}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              color="secondary"
              onClick={this.onConfirmDeleteComment.bind(this)}
              className={classes.modalConfirmButton}
            >
              Delete comment
            </Button>
          </DialogActions>
        </DialogContent>
      </div>
    );
  }
}

ListItemContent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  item: PropTypes.array,
  index: PropTypes.string,
  parent: PropTypes.bool,
  classificationEvent: PropTypes.func,
  translated: PropTypes.bool,
  displayBoth: PropTypes.bool,
  isAddingTag: PropTypes.bool,
  mode: PropTypes.string,
  readOnly: PropTypes.bool,
  actionsMenu: PropTypes.array,
  clickItemFromComment: PropTypes.func,
  updateTranslation: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(ListItemContent);
