export const setCopyrightReport = (report, key = null) => {
  const item = {
    id: report && report.id ? report.id : '',
    key: key,
    lock: report && report.status && report.status === 'locked' ? true : false,
    client_short_name:
      report && report.client_short_name ? report.client_short_name : '',
    object_ids: report && report.object_ids ? report.object_ids : [],
    label: report && report.label ? report.label : '',
    status: report && report.status ? report.status : '',
    created_at: report && report.created_at ? report.created_at : '',
    created_by: report && report.created_by ? report.created_by : '',
    updated_at: report && report.updated_at ? report.updated_at : ''
  };

  return item;
};
