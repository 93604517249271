import axios from 'axios';

import { GGConstants } from '../../constants/configuration/GGConstants';
import { setHeaders } from '../../setters/auth';

export const fetchGuidelineGroupsList = clientShortName => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/guideline-groups`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: GGConstants.FETCH_GUIDELINE_GROUPS_LIST,
    payload: promise
  });
};

export const addGuidelineGroup = (clientShortName, data) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/guideline-groups`;

  const promise = axios.post(url, data, {
    headers: setHeaders(),
    addData: data
  });

  return dispatch({
    type: GGConstants.ADD_GUIDELINE_GROUP,
    payload: promise
  });
};

export const updateGuidelineGroup = (
  clientShortName,
  index,
  data
) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/guideline-groups/${data.id}`;

  delete data.index;

  const promise = axios.put(url, data, {
    headers: setHeaders(),
    editData: data,
    index
  });

  return dispatch({
    type: GGConstants.UPDATE_GUIDELINE_GROUP,
    payload: promise
  });
};
