import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import RootRef from '@material-ui/core/RootRef';
import { JsonEditor as Editor } from 'jsoneditor-react';

import 'jsoneditor-react/es/editor.min.css';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const JSONEditor = ({ placeholder, onChange, onError, checkFilter }) => {
  const classes = useStyles();
  const editorRef = createRef();

  const onChangeHandler = e => {
    onChange(e === null ? [] : e);
  };

  useEffect(() => {
    if (checkFilter) {
      editorRef.current.children[0].children[0].children[1].children[0].click();
    }
  }, [checkFilter]);

  return (
    <RootRef rootRef={editorRef}>
      <div className={classes.root}>
        <Editor
          mode={'text'}
          allowedModes={['tree', 'text']}
          value={placeholder}
          onChange={onChangeHandler}
          onError={onError}
        />
      </div>
    </RootRef>
  );
};

JSONEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  placeholder: PropTypes.array
};

export default JSONEditor;
