import { postKPIsConstants } from '../../constants/taskKPIs/postKPIsConstants';

import { extractPostKPIsList } from '../../extractors/postKPIsExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  postKPIsList: [],
  error: null
};

const postKPIsReducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_POST_KPIS_LIST
    case `${postKPIsConstants.FETCH_POST_KPIS_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${postKPIsConstants.FETCH_POST_KPIS_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${postKPIsConstants.FETCH_POST_KPIS_LIST}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        postKPIsList: extractPostKPIsList(action.payload.data.post_task_logs)
      };
    default:
      return state;
  }
};

export default postKPIsReducer;
