import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import ModerationGuidelinesConfiguration from './moderationGuidelinesConfiguration';

// Redux
import {
  fetchModerationGuidelinesList,
  resetFetch,
  updateModerationGuidelines
} from '../../../actions/configuration/moderationGuidelinesAction';
import { fetchTagsList } from '../../../actions/configuration/tagsAction';

const mapStateToProps = state => ({
  moderationGuidelinesState: state.moderationGuidelinesReducer,
  tagsState: state.tagsReducer
});

const mapDispatchToProps = dispatch => ({
  resetFetch: () => dispatch(resetFetch()),
  fetchTagsList: () => dispatch(fetchTagsList()),
  fetchModerationGuidelinesList: clientShortName =>
    dispatch(fetchModerationGuidelinesList(clientShortName)),
  updateModerationGuidelines: (clientShortName, index, channelId) =>
    dispatch(updateModerationGuidelines(clientShortName, index, channelId))
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: 500
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 367px)',
    marginTop: 'calc(50vh - 96px)'
  }
}));

const ModerationGuidelinesContainer = props => {
  const classes = useStyles();
  const [isInitialized, setInitialized] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isNotAllowed, setNotAllowed] = useState(false);
  const [isEditable, setEditable] = useState(false);
  const [moderationGuidelinesList, setModerationGuidelinesList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [
    moderationGuidelinesColumns,
    setModerationGuidelinesColumns
  ] = useState([]);

  const updateModerationGuidelines = (index, data) => {
    props.updateModerationGuidelines(props.clientShortName, index, data);
  };

  useEffect(() => {
    if (props.permissions.includes('admin_list_tags')) {
      if (props.tabIndex === 8 && !isInitialized) {
        setLoading(true);
        setInitialized(true);
        props.fetchTagsList().then(() => {
          if (
            props.permissions.includes(
              'admin_list_client_moderation_guidelines'
            )
          ) {
            props.fetchModerationGuidelinesList(props.clientShortName);
            if (
              props.permissions.includes(
                'admin_edit_client_moderation_guidelines'
              )
            ) {
              setEditable(true);
            }
          } else {
            setNotAllowed(true);
          }
        });
      }
    }
  }, [props.tabIndex]);

  useEffect(() => {
    if (!props.moderationGuidelinesState.fetching) {
      setModerationGuidelinesList(
        props.moderationGuidelinesState.moderationGuidelinesList
      );
      setModerationGuidelinesColumns(
        props.moderationGuidelinesState.moderationGuidelinesColumns
      );
    }
  }, [props.moderationGuidelinesState.fetching]);

  useEffect(() => {
    if (!props.tagsState.fetching) {
      setTagList(props.tagsState.tagList);
    }
  }, [props.tagsState.fetching]);

  useEffect(() => {
    if (props.moderationGuidelinesState.fetched) {
      setLoading(false);
      props.resetFetch();
    }
  }, [props.moderationGuidelinesState.fetched]);

  return (
    <div className={classes.root}>
      {!isNotAllowed && !isLoading ? (
        <ModerationGuidelinesConfiguration
          moderationGuidelinesList={moderationGuidelinesList}
          moderationGuidelinesColumns={moderationGuidelinesColumns}
          isEditable={isEditable}
          tagList={tagList ? tagList : []}
          clientTags={props.clientTags}
          updateModerationGuidelines={updateModerationGuidelines}
        />
      ) : !isNotAllowed ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <Typography className={classes.notAllowed} variant="body1">
          Not Allowed
        </Typography>
      )}
    </div>
  );
};

ModerationGuidelinesContainer.propTypes = {
  clientShortName: PropTypes.string.isRequired,
  clientTags: PropTypes.array.isRequired,
  tabIndex: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModerationGuidelinesContainer);
