export const setClassifier = (classifier, key = null) => {
  const item = {
    id: key ? key : 0,
    group: classifier && classifier.group ? classifier.group : 'none',
    colorGroup:
      classifier && classifier.colorGroup
        ? classifier.colorGroup
        : classifier && classifier.color
        ? classifier.color
        : '#F5f5f5',
    classifier_name:
      classifier && classifier.classifier_name
        ? classifier.classifier_name
        : classifier && classifier.id
        ? classifier.id
        : '',
    versions:
      classifier && classifier.versions_available
        ? classifier.versions_available
        : classifier.versions
        ? classifier.versions
        : ['1.0'],
    version: classifier && classifier.version ? classifier.version : '',
    rules: classifier && classifier.rules ? classifier.rules : [],
    default_rules:
      classifier && classifier.default_rules
        ? classifier.default_rules
        : [
            {
              autoprocess: false,
              tags: [],
              filters: [
                {
                  field: 'version',
                  type: 'contains_any',
                  values: ['1.0']
                }
              ]
            }
          ],
    status: classifier && classifier.clara_only ? 0 : 1
  };

  return item;
};
