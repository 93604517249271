import axios from 'axios';

import { postKPIsConstants } from '../../constants/taskKPIs/postKPIsConstants';
import { setHeaders } from '../../setters/auth';

export const fetchPostKPIsList = (assetId, fromDate, toDate) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/assets/${assetId}/kpis/post-tasks`;

  url = url + '?from=' + fromDate + '&to=' + toDate;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: postKPIsConstants.FETCH_POST_KPIS_LIST,
    payload: promise
  });
};
