import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Rnd } from "react-rnd";
import ReactResizeDetector from "react-resize-detector";

import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoneIcon from "@material-ui/icons/Done";
import styled from "styled-components";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import { operationalTags, keyboardEvents } from "../../data/data";

import Tooltip from "../../components/commons/tooltip/TooltipComponent";
import DialogContent from "../../components/commons/dialog/contentDialogComponent";

const MIN_HEIGHT = 300;

const ChipCustom = styled(
  ({ background, colorText, hideTooltip, titleTooltip, ...other }) => {
    return (
      <Tooltip hide={hideTooltip} title={titleTooltip}>
        <Chip classes={{ label: "label", icon: "icon" }} {...other} />
      </Tooltip>
    );
  }
)`
  background: linear-gradient(
    0deg,
    ${(props) => props.background} 0%,
    ${(props) => props.background} 100%
  );

  & .label,
  & .icon {
    color: ${(props) => props.colorText};
  }
`;

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 20,
    "&$expanded": {
      minHeight: 20,
    },
  },
  content: {
    margin: "0 0",
    "&$expanded": {
      margin: "0 0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 4,
  },
}))(MuiExpansionPanelDetails);

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
    minHeight: MIN_HEIGHT,
  },
  container: {
    display: "flex",
    flexFlow: "wrap",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "100%",
    textAlign: "left",
    position: "fixed",
    bottom: MIN_HEIGHT,
  },
  containerChips: {
    overflow: "auto",
    background: theme.palette.white,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  showExample: {
    marginLeft: "auto",
    color: theme.palette.primary.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
  loading: {
    margin: "auto",
    color: theme.palette.orange[300],
  },
  chipChecked: {
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.70)",
  },
  inconHelperContainer: {
    position: "fixed",
    bottom: 0,
    right: 17,
  },
  iconHelper: {
    color: theme.palette.orange[300],
    fontSize: 13,
    cursor: "pointer",
  },
  keyboardEventItem: {
    margin: theme.spacing(1),
  },
  keyboardEventshortCuts: {
    color: theme.palette.orange[300],
  },
});

class TagList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialogKeyBoardHelper: false,
      tagsList: [],
      tagCategories: [],
      groupsTagList: [],
      showExample: false,
      groupsTagValues: props.groupsTagValues,
      width: "calc(100% - 240px)",
      height: MIN_HEIGHT,
    };
    this.handleChange.bind(this);
  }

  handleTriggerEvents(prevEvents, events) {
    const { tagCategories } = this.state;

    Object.entries(events).map((event) => {
      if (prevEvents[event[0]] !== event[1]) {
        let categorie = tagCategories.find((cat) =>
          cat.items.find((tag) => tag.id === event[0])
        );

        if (categorie) {
          let tag = categorie.items.find((tag) => tag.id === event[0]);
          if (tag) {
            let currentValue = tag.checked;
            this.handleChange(tag.id, null, !currentValue);
          }
        }
      }
      return true;
    });
  }

  handleChange = (tagId, event, value = null) => {
    const { tagCategories } = this.state;

    let categories = tagCategories.map((cat) => {
      return {
        ...cat,
        items: cat.items
          .map((tag) =>
            tag.id === tagId
              ? {
                  ...tag,
                  checked: value !== null ? value : event.target.checked,
                }
              : tag
          )
          .sort((tag1, tag2) => tag1.label.localeCompare(tag2.label)),
      };
    });

    let tags = categories.map((cat) => cat.items);
    let tagsMerged = [].concat.apply([], tags);

    this.setState(
      {
        tagCategories: categories,
      },
      () => {
        this.props.getTagList(tagsMerged, categories);
      }
    );
  };

  colorTextGenerator(color) {
    const rgb = color.slice(4).slice(0, -1).split(",");
    // Get YIQ ratio
    return parseInt(rgb[0]) * 0.299 +
      parseInt(rgb[1]) * 0.587 +
      parseInt(rgb[2]) * 0.114 >
      186
      ? "black"
      : "white";
  }

  componentDidUpdate(prevProps) {
    if (this.props.tagCategories && this.props.tagCategories.length) {
      if (prevProps.tagCategories !== this.props.tagCategories) {
        const sortedTagCategories = this.props.tagCategories.map((category) => {
          return {
            ...category,
            items: category.items.sort((tag1, tag2) =>
              tag1.label.localeCompare(tag2.label)
            ),
          };
        });

        this.setState({
          tagCategories: sortedTagCategories,
          height: MIN_HEIGHT,
        });
      }

      //handle shortcut events
      if (
        JSON.stringify(prevProps.triggerEvents) !==
        JSON.stringify(this.props.triggerEvents)
      ) {
        this.handleTriggerEvents(
          prevProps.triggerEvents,
          this.props.triggerEvents
        );
      }

      if (prevProps.showExample !== this.props.showExample) {
        this.setState({
          showExample: this.props.showExample,
        });
      }
    }
  }

  closeDialogKeyBoardHelper() {
    this.setState({
      openDialogKeyBoardHelper: false,
    });
  }

  openDialogKeyBoardHelper() {
    this.setState({
      openDialogKeyBoardHelper: true,
    });
  }

  renderExamples(example, excluded_example, description) {
    return (
      <div>
        <div>
          <Typography>Description: </Typography>
          <Typography>{description}</Typography>
        </div>
        <div>
          <Typography>Example: </Typography>
          <Typography>{example}</Typography>
        </div>
        <div>
          <Typography>Excluded Example: </Typography>
          <Typography>{excluded_example}</Typography>
        </div>
      </div>
    );
  }

  buildTagList(catId, tags, color) {
    const { classes } = this.props;
    const { showExample } = this.state;

    return tags.map((tag, i) => {
      if (
        !operationalTags.filter((operationalTag) => {
          return tag && operationalTag.action === tag.id;
        }).length
      ) {
        return (
          <ChipCustom
            background={color}
            colorText={this.colorTextGenerator(color)}
            className={classNames(classes.chip, {
              [classes.chipChecked]: tag.checked,
            })}
            clickable
            onClick={(event) => {
              /*
               * Apparently, `Chip` click catches any key events (including our global shortcuts).
               * That's why we need to validate that `handleChange()` is triggered only on
               * mouse click (tag click)
               */
              if (event.type !== "click") return false;
              this.handleChange(tag.id, null, !tag.checked);
            }}
            key={`${catId}-${i}`}
            size="small"
            icon={tag.checked ? <DoneIcon /> : null}
            label={tag.label}
            hideTooltip={!showExample}
            titleTooltip={this.renderExamples(
              tag.example,
              tag.excluded_example,
              tag.text
            )}
          />
        );
      }
      return true;
    });
  }

  buildCategorie(cat) {
    return (
      <Accordion key={`cat-${cat.id}`} square expanded>
        <AccordionSummary aria-controls="panel1d-content" id={cat.id}>
          <Typography>{cat.label}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>{this.buildTagList(cat.id, cat.items, cat.color)}</div>
        </AccordionDetails>
      </Accordion>
    );
  }

  render() {
    const { classes } = this.props;
    const { tagCategories, openDialogKeyBoardHelper, height } = this.state;

    return (
      <ReactResizeDetector handleHeight handleWidth>
        {(props) => {
          return (
            <div className={classes.root}>
              {tagCategories.length ? (
                <div className={classes.container}>
                  <Rnd
                    enableResizing={{
                      top: true,
                      right: false,
                      bottom: false,
                      left: false,
                      topRight: false,
                      bottomRight: false,
                      bottomLeft: false,
                      topLeft: false,
                    }}
                    disableDragging
                    minHeight={MIN_HEIGHT}
                    size={{ width: props.width, height: height }}
                    onResizeStop={(e, direction, ref, delta, position) => {
                      this.setState({
                        width: ref.style.width,
                        height: ref.style.height,
                      });
                      this.props.onResizeStop(
                        (
                          parseInt(ref.style.height.replace("px", "")) + 48
                        ).toString() + "px"
                      );
                    }}
                  >
                    <div
                      style={{ height: height }}
                      className={classes.containerChips}
                    >
                      {tagCategories.map((cat) => {
                        return this.buildCategorie(cat);
                      })}
                    </div>
                  </Rnd>
                  <div className={classes.inconHelperContainer}>
                    <Icon
                      onClick={this.openDialogKeyBoardHelper.bind(this)}
                      className={classNames(
                        classes.iconHelper,
                        "fas fa-info-circle"
                      )}
                    />
                  </div>
                </div>
              ) : (
                <CircularProgress className={classes.loading} />
              )}
              <DialogContent
                title={"KeyBoard Helpers"}
                open={openDialogKeyBoardHelper}
                close={this.closeDialogKeyBoardHelper.bind(this)}
              >
                <div>
                  {keyboardEvents.map((event, key) => {
                    let categorie = tagCategories.find((cat) =>
                      cat.items.find((tag) => tag.id === event.name)
                    );
                    if (categorie) {
                      let item = categorie.items.find(
                        (tag) => tag.id === event.name
                      );
                      if (item) {
                        return (
                          <div key={key} className={classes.keyboardEventItem}>
                            {" "}
                            <span
                              className={classes.keyboardEventshortCuts}
                            >{`[${event.handleKeys.join(",")}]`}</span>{" "}
                            - {event.label}
                          </div>
                        );
                      }
                    }
                    return true;
                  })}
                </div>
              </DialogContent>
            </div>
          );
        }}
      </ReactResizeDetector>
    );
  }
}

TagList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  tagCategories: PropTypes.array.isRequired,
  showExample: PropTypes.bool.isRequired,
  getTagList: PropTypes.func.isRequired,
  triggerEvents: PropTypes.object.isRequired,
  onResizeStop: PropTypes.func.isRequired,
  groupsTagValues: PropTypes.array.isRequired,
};

export default withStyles(styles, { withTheme: true })(TagList);
