import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2)
  },
  positiveContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center'
  },
  positiveContent: {
    width: '100%',
    marginLeft: 10
  },
  negativeContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center'
  },
  negativeContent: {
    width: '100%',
    marginLeft: 10
  },
  repliedContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center'
  },
  repliedContent: {
    width: '100%',
    marginLeft: 10
  },
  text: {
    width: '280px',
    textAlign: 'left'
  }
}));

const PostStatistics = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.positiveContainer}>
        <Typography className={classes.text} variant="subtitle2">
          {`Positive sentiment (${data.positive || 0}%):`}
        </Typography>
        <div className={classes.positiveContent}>
          <LinearProgress variant="determinate" value={data.positive || 0} />
        </div>
      </div>

      <div className={classes.negativeContainer}>
        <Typography className={classes.text} variant="subtitle2">
          {`Negative sentiment (${data.negative || 0}%):`}
        </Typography>
        <div className={classes.negativeContent}>
          <LinearProgress
            color="secondary"
            variant="determinate"
            value={data.negative || 0}
          />
        </div>
      </div>

      <div className={classes.repliedContainer}>
        <Typography className={classes.text} variant="subtitle2">
          {`Replies:`}
        </Typography>
        <div className={classes.repliedContent}>
          <Typography className={classes.text} variant="subtitle2">
            {data.replied || 0}
          </Typography>
        </div>
      </div>
    </div>
  );
};

PostStatistics.propTypes = {
  data: PropTypes.object.isRequired
};

export default PostStatistics;
