import { usersConstants } from '../constants/usersConstants';

const initialState = {
  fetching: false,
  fetched: false,
  userList: [],
  error: null
};

const Me = (state = initialState, action) => {
  switch (action.type) {
    // FETCH USERS
    case `${usersConstants.FETCH_USERS}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${usersConstants.FETCH_USERS}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${usersConstants.FETCH_USERS}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        userList: action.payload.data.items
      };
    default:
      return state;
  }
};

export default Me;
