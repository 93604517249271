import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import GuidelineGroupsConfiguration from './guidelineGroupsConfiguration';

// Redux
import {
  fetchGuidelineGroupsList,
  addGuidelineGroup,
  updateGuidelineGroup
} from '../../../actions/configuration/guidelineGroupsAction';

import { fetchAssetList } from '../../../actions/configuration/assetsActions';

const mapStateToProps = state => ({
  guidelineGroupsState: state.guidelineGroupReducer,
  assets: state.assetsReducer
});

const mapDispatchToProps = dispatch => ({
  fetchGuidelineGroupsList: clientShortName =>
    dispatch(fetchGuidelineGroupsList(clientShortName)),
  fetchAssetList: clientShortName => dispatch(fetchAssetList(clientShortName)),
  addGuidelineGroup: (clientShortName, data) =>
    dispatch(addGuidelineGroup(clientShortName, data)),
  updateGuidelineGroup: (clientShortName, index, channelId) =>
    dispatch(updateGuidelineGroup(clientShortName, index, channelId))
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: 500
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 367px)',
    marginTop: 'calc(50vh - 96px)'
  }
}));

const GuidelineGroupsContainer = props => {
  const classes = useStyles();
  const [isInitialized, setInitialized] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isNotAllowed, setNotAllowed] = useState(false);
  const [isCreatable, setCreatable] = useState(false);
  const [isEditable, setEditable] = useState(false);
  const [guidelineGroupsList, setGuidelineGroupsList] = useState([]);
  const [assetList, setAssetList] = useState([]);

  const addGuidelineGroup = data => {
    props.addGuidelineGroup(props.clientShortName, data);
  };

  const updateGuidelineGroup = (index, data) => {
    props.updateGuidelineGroup(props.clientShortName, index, data);
  };

  useEffect(() => {
    if (props.permissions.includes('admin_list_client_guideline_groups')) {
      if (props.tabIndex === 7 && !isInitialized) {
        setLoading(true);
        setInitialized(true);
        props.fetchAssetList(props.clientShortName).then(response => {
          props.fetchGuidelineGroupsList(props.clientShortName);
          if (
            props.permissions.includes('admin_create_client_guideline_group')
          ) {
            setCreatable(true);
          }
          if (
            props.permissions.includes('admin_update_client_guideline_group')
          ) {
            setEditable(true);
          }
        });
      }
    } else {
      setNotAllowed(true);
    }
  }, [props.tabIndex]);

  useEffect(() => {
    if (!props.guidelineGroupsState.fetching) {
      setGuidelineGroupsList(props.guidelineGroupsState.guidelineGroupsList);
    }
  }, [props.guidelineGroupsState.fetching]);

  useEffect(() => {
    if (props.guidelineGroupsState.fetched) {
      setLoading(false);
    }
  }, [props.guidelineGroupsState.fetched]);

  useEffect(() => {
    if (!props.assets.fetching) {
      setAssetList(props.assets.assetList);
    }
  }, [props.assets.fetching]);

  return (
    <div className={classes.root}>
      {!isNotAllowed && !isLoading ? (
        <GuidelineGroupsConfiguration
          guidelineGroupsList={guidelineGroupsList}
          assetList={assetList}
          isCreatable={isCreatable}
          isEditable={isEditable}
          addGuidelineGroup={addGuidelineGroup}
          updateGuidelineGroup={updateGuidelineGroup}
        />
      ) : !isNotAllowed ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <Typography className={classes.notAllowed} variant="body1">
          Not Allowed
        </Typography>
      )}
    </div>
  );
};

GuidelineGroupsContainer.propTypes = {
  clientShortName: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuidelineGroupsContainer);
