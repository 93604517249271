import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import StickyFiltersConfiguration from './stickyFiltersConfiguration';

// Redux
import {
  fetchStickyFiltersList,
  addStickyFilter,
  updateStickyFilter
} from '../../../actions/configuration/stickyFiltersAction';

import { fetchAssetList } from '../../../actions/configuration/assetsActions';

const mapStateToProps = state => ({
  stickyFiltersState: state.stickyFiltersReducer,
  assets: state.assetsReducer
});

const mapDispatchToProps = dispatch => ({
  fetchStickyFiltersList: clientShortName =>
    dispatch(fetchStickyFiltersList(clientShortName)),
  fetchAssetList: clientShortName => dispatch(fetchAssetList(clientShortName)),
  addStickyFilter: (clientShortName, data) =>
    dispatch(addStickyFilter(clientShortName, data)),
  updateStickyFilter: (clientShortName, index, channelId) =>
    dispatch(updateStickyFilter(clientShortName, index, channelId))
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: 500
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 367px)',
    marginTop: 'calc(50vh - 96px)'
  }
}));

const StickyFiltersContainer = props => {
  const classes = useStyles();
  const [isInitialized, setInitialized] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isNotAllowed, setNotAllowed] = useState(false);
  const [isCreatable, setCreatable] = useState(false);
  const [isEditable, setEditable] = useState(false);
  const [stickyFiltersList, setStickyFiltersList] = useState([]);
  const [assetList, setAssetList] = useState([]);

  const addStickyFilter = data => {
    props.addStickyFilter(props.clientShortName, data);
  };

  const updateStickyFilter = (index, data) => {
    props.updateStickyFilter(props.clientShortName, index, data);
  };

  useEffect(() => {
    if (props.permissions.includes('admin_list_client_sticky_filters')) {
      if (props.tabIndex === 6 && !isInitialized) {
        setLoading(true);
        setInitialized(true);
        props.fetchAssetList(props.clientShortName).then(response => {
          props.fetchStickyFiltersList(props.clientShortName);
          if (props.permissions.includes('admin_create_client_sticky_filter')) {
            setCreatable(true);
          }
          if (props.permissions.includes('admin_update_client_sticky_filter')) {
            setEditable(true);
          }
        });
      }
    } else {
      setNotAllowed(true);
    }
  }, [props.tabIndex]);

  useEffect(() => {
    if (!props.stickyFiltersState.fetching) {
      setStickyFiltersList(props.stickyFiltersState.stickyFiltersList);
    }
  }, [props.stickyFiltersState.fetching]);

  useEffect(() => {
    if (props.stickyFiltersState.fetched) {
      setLoading(false);
    }
  }, [props.stickyFiltersState.fetched]);

  useEffect(() => {
    if (!props.assets.fetching) {
      setAssetList(props.assets.assetList);
    }
  }, [props.assets.fetching]);

  return (
    <div className={classes.root}>
      {!isNotAllowed && !isLoading ? (
        <StickyFiltersConfiguration
          stickyFiltersList={stickyFiltersList}
          assetList={assetList}
          isCreatable={isCreatable}
          isEditable={isEditable}
          addStickyFilter={addStickyFilter}
          updateStickyFilter={updateStickyFilter}
        />
      ) : !isNotAllowed ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <Typography className={classes.notAllowed} variant="body1">
          Not Allowed
        </Typography>
      )}
    </div>
  );
};

StickyFiltersContainer.propTypes = {
  clientShortName: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StickyFiltersContainer);
