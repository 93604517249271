import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import RootRef from '@material-ui/core/RootRef';
import moment from 'moment';

import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

import ColumnDragAndDrop from '../../commons/columnDragAndDrop/columnDragAndDropComponent';
import DialogContent from '../../commons/dialog/contentDialogComponent';
import RulesForm from './rulesForm';
import ClassifierForm from './classifierForm';
import RulesList from './rulesList';
import Tooltip from '../../commons/tooltip/TooltipComponent';
import AlertDialog from '../../commons/dialog/alertDialogComponent';

import { downloadAsJson } from '../../../utils/utils';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 7
  },
  buttonAddClassifier: {
    marginLeft: theme.spacing(2)
  },
  filters: {
    display: 'flex',
    marginBottom: theme.spacing(3)
  },
  rootSmallItem: {
    backgroundColor: theme.palette.error[400],
    color: theme.palette.white,
    display: 'flex',
    margin: 2,
    alignItems: 'center',
    width: '100%'
  },
  groupSmallItem: {
    height: 15,
    fontSize: 9,
    marginLeft: theme.spacing(1),
    borderRadius: 4
  },
  titleSmallItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  rootItem: {
    backgroundColor: theme.palette.error[400],
    color: theme.palette.white,
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
  },
  groupItem: {
    height: 18,
    fontSize: 11,
    borderRadius: 4
  },
  titleItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  tagsItemContainer: {
    textAlign: 'left',
    marginTop: theme.spacing(3)
  },
  tagItem: {},
  tagSmallItem: {
    height: 18,
    fontSize: 11
  },
  buttonsContainer: {
    textAlign: 'right'
  },
  formControl: {
    marginLeft: theme.spacing(2)
  },
  formControlFilter: {
    minWidth: 185,
    marginLeft: theme.spacing(8)
  },
  ruleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1)
  },
  activeColor: {
    backgroundColor: theme.palette.warning[500],
    color: theme.palette.black
  },
  activeTextColor: {
    color:
      theme.type === 'dark' ? theme.palette.primary.main : theme.palette.black
  },
  deleteColor: {
    backgroundColor: theme.palette.error[50],
    color: theme.palette.error.main
  },
  autoProcessedColor: {
    backgroundColor: theme.palette.success[300],
    color: theme.palette.black
  },
  classifierSelected: {
    boxShadow: theme.palette.shadow.shadowPrimary
  },
  columns: {
    display: 'flex'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttonDownload: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0),
    color: theme.palette.black,
    padding: 0
  }
});

class ClaraConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classifierSelected: 0,
      addClassifierSelected: '',
      addClassifiersAvailable: [],
      versionsAvailable: [],
      openDialogDeleteClassifier: false,
      openDialogNoVersionSelected: false,
      classifierToDeleteName: '',
      classifierToDeleteId: null,
      minimize: true,
      editable: props.editable,
      groups: [],
      filters: [],
      ruleFiltersKey: null,
      onErrorFilter: false,
      checkFilter: false,
      tagList: props.tagList,
      clientTags: props.clientTags,
      openDialogClassifierForm: false,
      classifierFormType: null,
      columns: [
        {
          id: 0,
          title: 'clara_only',
          label: 'Clara only',
          droppable: true
        },
        {
          id: 1,
          title: 'active',
          label: 'Active',
          droppable: true
        }
      ],
      list: props.classifierClara,
      newRules: [],
      newVersion: ''
    };

    this.domRef = React.createRef();

    this.getClassifierByName = this.getClassifierByName.bind(this);
  }

  onChange(e, data) {
    this.setState({
      [data]: e.target.value
    });
  }

  findGroups(list) {
    let group = [];
    list.map((item, key) => {
      if (group.indexOf(item.group) === -1) {
        group.push(item.group);
      }
      return true;
    });

    return group;
  }

  selectClassifier(id) {
    this.setState({
      classifierSelected: id
    });
  }

  getAvailableClassifierList() {
    const addedClassifierIds = this.props.classifierClara.map(
      addedClassifier => addedClassifier.classifier_name
    );
    return this.props.classifierList.filter(classifier => {
      return addedClassifierIds.includes(classifier.classifier_name) === false;
    });
  }

  getClassifierByName(classifierName) {
    return this.props.classifierList.find(
      classifier => classifier.classifier_name === classifierName
    );
  }

  onChangeMode(e) {
    this.setState({
      minimize: e.target.checked
    });
  }

  onDrop(index, status) {
    this.props.updateStatusClassifier(index, status);
  }

  onEditClassifier() {
    const classifier = this.state.list[this.state.classifierSelected];
    this.setState({
      openDialogClassifierForm: true,
      classifierFormType: 'edit',
      versionsAvailable: classifier ? classifier.versions : [],
      newVersion: classifier ? classifier.version : '',
      newRules: classifier ? classifier.rules : []
    });
  }

  onAddClassifier() {
    this.setState({
      openDialogClassifierForm: true,
      classifierFormType: 'add',
      versionsAvailable: [],
      newVersion: ''
    });
  }

  closeDialogClassifierForm() {
    this.setState({
      openDialogClassifierForm: false
    });
  }

  onDeleteClassifier(e, classifierToDeleteId) {
    this.setState({
      classifierToDeleteId,
      classifierToDeleteName: this.state.list[classifierToDeleteId]
        .classifier_name,
      openDialogDeleteClassifier: true
    });
  }

  actionDialogDeleteClassifier(state) {
    if (state) {
      this.props.deleteClassifier(
        this.state.classifierToDeleteId,
        this.state.classifierToDeleteName
      );
      this.setState({
        openDialogDeleteClassifier: false
      });
    } else {
      this.setState({
        openDialogDeleteClassifier: false
      });
    }
  }

  onChangeVersion(value) {
    this.setState({
      newVersion: value
    });
  }

  onChangeAddClassifier(value) {
    const classifier = this.getClassifierByName(value);
    let newRules = [];
    if (classifier && classifier.default_rules) {
      newRules = classifier.default_rules.map(rule => {
        rule.isDefault = true;
        return rule;
      });
    }

    let newVersion = classifier
      ? Math.max
          .apply(
            Math,
            classifier.versions.map(ver => parseFloat(ver))
          )
          .toString()
      : '';
    if (newVersion.length === 1) newVersion += '.0';

    this.setState({
      addClassifierSelected: value,
      versionsAvailable: classifier ? classifier.versions : [],
      newVersion,
      newRules
    });
  }

  onChangeRulesForm(data, ruleKey) {
    if (data[ruleKey]) {
      this.setState({
        onErrorFilter: data[ruleKey]['filters'] ? false : true
      });
    }

    this.setState({
      newRules: data,
      ruleFiltersKey: ruleKey
    });
  }

  onErrorRulesForm(e, ruleKey) {
    this.setState({
      onErrorFilter: true,
      ruleFiltersKey: ruleKey
    });
  }

  checkForErrors() {
    const { onErrorFilter } = this.state;
    const promise = new Promise((resolve, reject) => {
      this.setState(
        {
          error: {
            filters: onErrorFilter ? 'JSON not valid' : ''
          }
        },
        () => {
          resolve();
        }
      );
    });

    return promise;
  }

  updateRules() {
    this.setState(
      {
        checkFilter: true
      },
      () => {
        setTimeout(() => {
          this.checkForErrors().then(() => {
            if (
              !Object.values(this.state.error).filter(value => value !== '')
                .length
            ) {
              this.props.updateRules(
                this.state.classifierSelected,
                this.state.newRules && this.state.newRules.length
                  ? this.state.newRules
                  : this.state.list[this.state.classifierSelected]['rules'],
                this.state.newVersion && this.state.newVersion.length
                  ? this.state.newVersion
                  : this.state.list[this.state.classifierSelected]['version'],
                () => {
                  this.setState({
                    newRules: [],
                    newVersion: ''
                  });
                  this.closeDialogClassifierForm();
                }
              );
            }
          });
        }, 100);
      }
    );
  }

  addClassifier() {
    this.setState(
      {
        checkFilter: true
      },
      () => {
        setTimeout(() => {
          this.checkForErrors().then(() => {
            if (
              !Object.values(this.state.error).filter(value => value !== '')
                .length
            ) {
              if (this.state.newVersion !== '') {
                this.props.addClassifier(
                  this.state.addClassifierSelected,
                  this.state.newRules,
                  this.state.newVersion,
                  () => {
                    this.setState(
                      {
                        versionsAvailable: [],
                        newVersion: '',
                        newRules: []
                      },
                      () => {
                        this.closeDialogClassifierForm();
                      }
                    );
                  }
                );
              } else {
                this.setState({
                  openDialogNoVersionSelected: true
                });
              }
            }
          });
        }, 100);
      }
    );
  }

  upEvent(e) {
    let previousIndex =
      this.state.classifierSelected === 0
        ? 0
        : this.state.classifierSelected - 1;
    let column = this.state.list[this.state.classifierSelected]['status'];
    let reverse =
      this.state.list[this.state.classifierSelected]['status'] === 1 ? 0 : 1;
    let length = this.domRef.current.children[reverse].children[1].children
      .length;

    this.setState({
      classifierSelected: previousIndex
    });

    if (
      this.domRef.current.children[column].children[1].children[
        previousIndex - length
      ]
    ) {
      this.domRef.current.children[column].children[1].children[
        previousIndex - length
      ].scrollIntoView({ block: 'center' });
    }
  }

  downEvent(e) {
    let nextIndex =
      this.state.classifierSelected === this.state.list.length - 1
        ? this.state.list.length - 1
        : this.state.classifierSelected + 1;
    let column = this.state.list[this.state.classifierSelected]['status'];
    let reverse =
      this.state.list[this.state.classifierSelected]['status'] === 1 ? 0 : 1;
    let length = this.domRef.current.children[reverse].children[1].children
      .length;

    this.setState({
      classifierSelected: nextIndex
    });

    if (
      this.domRef.current.children[column].children[1].children[
        nextIndex + length
      ]
    ) {
      this.domRef.current.children[column].children[1].children[
        nextIndex + length
      ].scrollIntoView({ block: 'center' });
    }
  }

  displaySelect() {
    const { classes } = this.props;
    const { groups, filters } = this.state;
    return (
      <FormGroup className={classes.formGroup}>
        <InputLabel>Filter Groups</InputLabel>
        <Select
          multiple
          value={filters}
          onChange={e => {
            return this.onChange(e, 'filters');
          }}
          renderValue={selected =>
            selected.length > 2
              ? `${selected.slice(0, 2).join(', ')} + ${selected.length - 2}`
              : selected.join(', ')
          }
        >
          {groups.map((group, key) => (
            <MenuItem key={key} value={group}>
              {group}
            </MenuItem>
          ))}
        </Select>
      </FormGroup>
    );
  }

  generateTitleComponent(tags) {
    const { tagList } = this.props;
    return (
      <React.Fragment>
        {tags.map((tag, key) => {
          return (
            <div key={key}>
              {tagList.length && tagList.find(i => tag === i.id)
                ? tagList.find(i => tag === i.id).label
                : tag}
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  renderSmallItem(item) {
    const { classes, deletable } = this.props;
    let tags = item.rules.map(rule => {
      return rule.tags.map(tag => {
        return tag;
      })[0];
    });
    return (
      <Card
        className={classNames(classes.rootSmallItem, {
          [classes.activeColor]: item.status === 1,
          [classes.autoProcessedColor]: item.rules.filter(rule => {
            return rule.autoprocess === true;
          }).length,
          [classes.classifierSelected]:
            item.id === this.state.classifierSelected
        })}
        onClick={e => this.selectClassifier(item.id)}
      >
        <div className={classes.titleSmallItem}>
          {deletable && (
            <IconButton
              size="small"
              className={classes.deleteColor}
              onClick={e => {
                e.stopPropagation();
                this.onDeleteClassifier(e, item.id);
              }}
            >
              <CancelIcon style={{ fontSize: '1rem' }} />
            </IconButton>
          )}
          <Typography
            variant="body2"
            className={item.status === 1 ? classes.activeTextColor : ''}
          >
            {item.classifier_name}
          </Typography>
          <div>
            <Tooltip title={this.generateTitleComponent(tags)}>
              <Chip
                size="small"
                label={tags[0] !== undefined ? tags.length : 0}
                className={classes.tagSmallItem}
              />
            </Tooltip>

            <Chip
              size="small"
              label={item.group}
              style={{ backgroundColor: item.colorGroup }}
              className={classes.groupSmallItem}
            />
          </div>
        </div>
      </Card>
    );
  }

  renderItem(item) {
    const { classes, tagList, deletable } = this.props;
    return (
      <Card
        className={classNames(classes.rootItem, {
          [classes.activeColor]: item.status === 1,
          [classes.autoProcessedColor]: item.rules.filter(rule => {
            return rule.autoprocess === true;
          }).length,
          [classes.classifierSelected]:
            item.id === this.state.classifierSelected
        })}
        onClick={e => this.selectClassifier(item.id)}
      >
        <div className={classes.titleItem}>
          {deletable && (
            <IconButton
              size="small"
              className={classes.deleteColor}
              onClick={e => {
                e.stopPropagation();
                this.onDeleteClassifier(e, item.id);
              }}
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          )}
          <Typography variant="body2">{item.classifier_name}</Typography>
          <Chip
            size="small"
            label={item.group}
            style={{ backgroundColor: item.colorGroup }}
            className={classes.groupItem}
          />
        </div>

        <div className={classes.tagsItemContainer}>
          {item.rules.map((rule, keyRule) => {
            return (
              <div key={keyRule} className={classes.ruleContainer}>
                {rule.tags.map((tag, keyTag) => {
                  return (
                    <Chip
                      key={keyTag}
                      size="small"
                      label={
                        tagList.length && tagList.find(i => tag === i.id)
                          ? tagList.find(i => tag === i.id).label
                          : tag
                      }
                      className={classes.tagItem}
                    />
                  );
                })}
                <Typography>{rule.autoprocess ? 'ON' : 'OFF'}</Typography>
              </div>
            );
          })}
        </div>
      </Card>
    );
  }

  displayButtonsDialog(dialogType) {
    const { classes } = this.props;
    return (
      <div className={classes.buttonContainer}>
        <Button
          size="small"
          color="primary"
          onClick={e => {
            return dialogType === 'edit'
              ? this.updateRules(e)
              : this.addClassifier(e);
          }}
        >
          {'Save classifier'}
        </Button>
        <Button
          onClick={e => {
            this.closeDialogClassifierForm(e);
          }}
          size="small"
          color="secondary"
        >
          Cancel
        </Button>
      </div>
    );
  }

  setGroups() {
    this.setState({
      groups: this.findGroups(this.props.classifierClara),
      filters: this.findGroups(this.props.classifierClara)
    });
  }

  closeDialogNoVersionSelected() {
    this.setState({
      openDialogNoVersionSelected: false
    });
  }

  downloadJson() {
    downloadAsJson(
      this.state.list,
      this.props.clientShortName +
        '_clara_' +
        moment().format('YYYY-MM-DD') +
        '_' +
        moment().format('hh_mm_A')
    );
  }

  componentDidMount() {
    if (this.props.classifierClara.length) {
      this.setGroups();
    }
    if (this.props.classifierList.length) {
      const addClassifiersAvailable = this.getAvailableClassifierList();
      this.setState({
        addClassifiersAvailable
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editable !== this.props.editable) {
      this.setState({
        editable: this.props.editable
      });
    }

    if (
      prevProps.classifierClara.length === 0 &&
      prevProps.classifierClara !== this.props.classifierClara
    ) {
      this.setGroups();
    }

    if (prevProps.classifierClara !== this.props.classifierClara) {
      const addClassifiersAvailable = this.getAvailableClassifierList();
      this.setState({
        list: this.props.classifierClara,
        addClassifiersAvailable
      });
    }

    if (prevProps.classifierList !== this.props.classifierList) {
      const addClassifiersAvailable = this.getAvailableClassifierList();
      this.setState({
        addClassifiersAvailable
      });
    }

    if (prevProps.tagList !== this.props.tagList) {
      this.setState({
        tagList: this.props.tagList
      });
    }
  }

  render() {
    const { classes, creatable } = this.props;
    const {
      columns,
      list,
      groups,
      filters,
      openDialogClassifierForm,
      classifierFormType,
      openDialogDeleteClassifier,
      openDialogNoVersionSelected,
      classifierToDeleteName,
      ruleFiltersKey,
      checkFilter,
      tagList,
      clientTags,
      editable,
      classifierSelected,
      addClassifierSelected,
      addClassifiersAvailable,
      versionsAvailable,
      newRules,
      newVersion,
      error,
      minimize
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h5">Clara list</Typography>
          <IconButton
            className={classes.buttonDownload}
            onClick={() => this.downloadJson()}
          >
            <SystemUpdateAltIcon />
          </IconButton>
          {creatable && this.props.classifierList.length ? (
            <Button
              variant="contained"
              className={classes.buttonAddClassifier}
              size="small"
              color="primary"
              disabled={addClassifiersAvailable.length === 0}
              onClick={e => {
                return this.onAddClassifier(e);
              }}
            >
              Add classifier
              {addClassifiersAvailable.length === 0 && ' (ALL ADDED)'}
            </Button>
          ) : (
            ''
          )}
        </div>

        <div className={classes.filters}>
          {groups.length ? (
            <FormControl className={classes.formControlFilter}>
              {this.displaySelect()}
            </FormControl>
          ) : (
            ''
          )}
          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={
                <CheckBox
                  checked={minimize}
                  onChange={e => this.onChangeMode(e)}
                />
              }
              label={`Minimize`}
            />
          </FormControl>
        </div>

        {tagList.length ? (
          <div className={classes.columns}>
            <RootRef rootRef={this.domRef}>
              <ColumnDragAndDrop
                columns={columns}
                list={list}
                renderItem={item => {
                  return minimize
                    ? this.renderSmallItem(item)
                    : this.renderItem(item);
                }}
                onDrop={this.onDrop.bind(this)}
                filters={filters}
                filterBy={'group'}
              />
            </RootRef>
            <RulesList
              version_selected={
                list[classifierSelected] ? list[classifierSelected].version : ''
              }
              rules={
                list[classifierSelected] ? list[classifierSelected].rules : []
              }
              tagList={tagList}
              editable={editable}
              onEdit={this.onEditClassifier.bind(this)}
            />
          </div>
        ) : (
          ''
        )}

        {tagList.length && classifierFormType ? (
          <React.Fragment>
            <DialogContent
              title={
                classifierFormType === 'edit'
                  ? `Edit classifier: ${
                      list[classifierSelected]
                        ? list[classifierSelected]['classifier_name']
                        : ''
                    }`
                  : 'Add classifier'
              }
              open={openDialogClassifierForm}
              close={this.closeDialogClassifierForm.bind(this)}
              fullWidth={true}
            >
              <div>
                <ClassifierForm
                  formType={classifierFormType}
                  classifierList={addClassifiersAvailable}
                  classifierSelected={addClassifierSelected}
                  versions={versionsAvailable}
                  version_selected={newVersion}
                  onChangeVersion={this.onChangeVersion.bind(this)}
                  onChangeClassifier={this.onChangeAddClassifier.bind(this)}
                />
                <RulesForm
                  rules={newRules}
                  tagList={tagList}
                  clientTags={clientTags}
                  ruleFiltersKey={ruleFiltersKey}
                  checkFilter={checkFilter}
                  classifierSelected={addClassifierSelected}
                  onChangeRulesForm={this.onChangeRulesForm.bind(this)}
                  onErrorRulesForm={this.onErrorRulesForm.bind(this)}
                  error={error}
                />
                {this.displayButtonsDialog(classifierFormType)}
              </div>
            </DialogContent>
          </React.Fragment>
        ) : (
          ''
        )}

        {openDialogClassifierForm ? (
          <React.Fragment>
            <KeyboardEventHandler
              handleFocusableElements={true}
              handleKeys={['up']}
              onKeyEvent={this.upEvent.bind(this)}
            />
            <KeyboardEventHandler
              handleFocusableElements={true}
              handleKeys={['down']}
              onKeyEvent={this.downEvent.bind(this)}
            />
          </React.Fragment>
        ) : (
          ''
        )}

        <AlertDialog
          title="Delete classifier?"
          message={`Are you sure you want to delete classifier '${classifierToDeleteName}' from the list?`}
          buttonCancelText="Cancel"
          buttonAcceptText="Accept"
          open={openDialogDeleteClassifier}
          action={this.actionDialogDeleteClassifier.bind(this)}
        />
        <AlertDialog
          title="Select Version"
          message="You must select a version to save this classifier."
          buttonAcceptText="OK"
          open={openDialogNoVersionSelected}
          action={this.closeDialogNoVersionSelected.bind(this)}
        />
      </div>
    );
  }
}

ClaraConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  tagList: PropTypes.array.isRequired,
  clientTags: PropTypes.array.isRequired,
  classifierClara: PropTypes.array.isRequired,
  updateRules: PropTypes.func.isRequired,
  updateStatusClassifier: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(ClaraConfiguration);
