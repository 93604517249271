import React from "react";
import PropTypes from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import CheckBox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import CircularProgress from "@material-ui/core/CircularProgress";

import SelectComponent from "../../commons/select/selectComponent";
import InputComponent from "../../commons/input/inputComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  formControlContainer: {
    width: "100",
  },
  formControl: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  formGroup: {
    width: "50%",
  },
  loading: {
    margin: "0 auto",
    display: "block",
    color: theme.palette.orange[300],
  },
  actionButton: {
    width: "50%",
    marginTop: "16px",
  },
  actionLoading: {
    width: "20px !important",
    height: "20px !important",
  },
}));

const AssetManagementForm = (props) => {
  const classes = useStyles();

  const {
    errors,
    formData,
    formOptions,
    formPermissions,
    formActionsLoading,
    isCSNAlreadyAssigned,
    isEditBatch,
  } = props;

  const displayActionButton = (
    action,
    buttonText,
    payload = null,
    keyNumber = 0
  ) => {
    return (
      <Button
        className={classes.actionButton}
        variant="contained"
        size="small"
        color="primary"
        disabled={formPermissions[action] === false}
        key={action}
        onClick={(e) => {
          return props.formAction(action, payload);
        }}
      >
        {formActionsLoading[action] && (
          <CircularProgress
            className={classes.actionLoading}
            color="secondary"
          />
        )}
        &nbsp;&nbsp;
        {formPermissions[action] === false ? "Not Allowed" : buttonText}
      </Button>
    );
  };

  const displayTextField = (
    name,
    label,
    required,
    disabled,
    multiline,
    type = "text",
    context = null
  ) => {
    const dataContext =
      context && formData[context] ? formData[context] : formData;
    const errorContext = context && errors[context] ? errors[context] : errors;

    return (
      <InputComponent
        type={type}
        multiline={multiline}
        required={required}
        disabled={disabled}
        defaultValue={dataContext[name]}
        onChange={(e) => {
          return props.onChangeFormData(name, e.target.value, context);
        }}
        error={errorContext[name] ? true : false}
        helperText={errorContext[name]}
        label={label}
        margin="dense"
        halfWidth={true}
      />
    );
  };

  const getFlattenedAssetsOptions = () => {
    return formData["replierInfo"]["assets"].map((asset) => {
      return {
        id: asset.id,
        name: asset && asset.asset_name ? asset.asset_name.name : "",
        selected: asset.selected,
      };
    });
  };

  return (
    <div className={classes.formControlContainer}>
      {props.isLoading ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <React.Fragment>
          <FormControl className={classes.formControl}>
            <FormGroup className={classes.formGroup}>
              <SelectComponent
                displaySearch={true}
                label="Client ShortName"
                error={errors["clientShortName"]}
                value={formData["clientShortName"]}
                items={formOptions["clientShortName"]}
                fullWidth={true}
                display={1}
                onChange={(e) => {
                  return props.onChangeFormData(
                    "clientShortName",
                    e.target.value
                  );
                }}
              />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <Button
                className={classes.actionButton}
                variant="contained"
                size="small"
                color="primary"
                disabled={
                  formData["clientShortName"] === "" ||
                  (isCSNAlreadyAssigned && !formData.isSalesClient)
                }
                onClick={(e) => {
                  return props.formAction(
                    "clientShortName",
                    formData["clientShortName"]
                  );
                }}
              >
                {formActionsLoading["clientShortName"] && (
                  <CircularProgress
                    className={classes.actionLoading}
                    color="secondary"
                  />
                )}
                &nbsp;&nbsp;
                {isCSNAlreadyAssigned && !formData.isSalesClient
                  ? "Already Assigned"
                  : formPermissions["clientShortName"] === false
                  ? "Not Allowed"
                  : "Assign Shortname"}
              </Button>
            </FormGroup>
          </FormControl>
          {!isEditBatch && (
            <FormControl className={classes.formControl}>
              <FormGroup className={classes.formGroup}>
                {displayTextField("alias", "Alias", true, false, false)}
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                {displayActionButton(
                  "alias",
                  "Update Alias",
                  formData["alias"]
                )}
              </FormGroup>
            </FormControl>
          )}
          <FormControl className={classes.formControl}>
            <FormGroup className={classes.formGroup}>
              <SelectComponent
                multiple={true}
                displaySearch={true}
                attributId="id"
                attributName="name"
                label="Active Services"
                error={errors["activeServices"]}
                value={formData["activeServices"]}
                items={formOptions["activeServices"]}
                fullWidth={true}
                display={1}
                onChange={(e) => {
                  return props.onChangeFormData(
                    "activeServices",
                    e.target.value
                  );
                }}
              />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              {displayActionButton(
                "activeServices",
                "Update Active Services",
                formData["activeServices"]
              )}
            </FormGroup>
          </FormControl>
          {!isEditBatch &&
            formData.isFbOrganicAsset &&
            formData["clientShortName"] !== "" && (
              <FormControl className={classes.formControl}>
                <FormGroup className={classes.formGroup}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        name="configured"
                        checked={formData.webhooks["configured"]}
                        onChange={(e, value) =>
                          props.onChangeFormData(
                            "configured",
                            value,
                            "webhooks"
                          )
                        }
                      />
                    }
                    label={`Webhook Configured`}
                  />
                </FormGroup>
                <FormGroup className={classes.formGroup}>
                  {displayActionButton(
                    "webhooks",
                    "Update Webhooks",
                    formData["webhooks"]
                  )}
                </FormGroup>
              </FormControl>
            )}
          {!isEditBatch &&
            formData.isFbOrganicAsset &&
            formData["clientShortName"] !== "" && (
              <FormControl className={classes.formControl}>
                <FormGroup className={classes.formGroup}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        name="active"
                        checked={formData.webhooks["active"]}
                        onChange={(e, value) =>
                          props.onChangeFormData("active", value, "webhooks")
                        }
                      />
                    }
                    label={`Webhook Active`}
                  />
                </FormGroup>
              </FormControl>
            )}
          {!isEditBatch &&
            formData.isFbOrganicAsset &&
            formData["clientShortName"] !== "" && (
              <FormControl className={classes.formControl}>
                <FormGroup className={classes.formGroup}>
                  <SelectComponent
                    label="Webhook Published Status"
                    value={formData["webhooks"].publishedStatus}
                    items={["all", "published", "unpublished"]}
                    fullWidth={true}
                    display={1}
                    onChange={(e) => {
                      return props.onChangeFormData(
                        "publishedStatus",
                        e.target.value,
                        "webhooks"
                      );
                    }}
                  />
                </FormGroup>
              </FormControl>
            )}
          <FormControl className={classes.formControl}>
            <FormGroup className={classes.formGroup}>
              <FormControlLabel
                control={
                  <CheckBox
                    name="assetVisibility"
                    checked={formData.assetVisibility["camp"] === "public"}
                    onChange={(e, value) =>
                      props.onChangeFormData(
                        "camp",
                        value ? "public" : "private",
                        "assetVisibility"
                      )
                    }
                  />
                }
                label={`Visible on CAMP`}
              />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              {displayActionButton(
                "assetVisibility",
                "Update Visibility",
                formData["assetVisibility"]
              )}
            </FormGroup>
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormGroup className={classes.formGroup}>
              <FormControlLabel
                control={
                  <CheckBox
                    name="assetVisibility"
                    checked={
                      formData.assetVisibility["client_camp"] === "public"
                    }
                    onChange={(e, value) =>
                      props.onChangeFormData(
                        "client_camp",
                        value ? "public" : "private",
                        "assetVisibility"
                      )
                    }
                  />
                }
                label={`Visible on Adbastion`}
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormGroup className={classes.formGroup}>
              <FormControlLabel
                control={
                  <CheckBox
                    name="autoRehide"
                    checked={formData.autoRehide}
                    onChange={(e, value) => {
                      props.onChangeFormData("autoRehide", value);
                    }}
                  />
                }
                label={`Automatically rehide comments`}
              />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              {displayActionButton(
                "autoRehide",
                "Update automatic rehiding",
                formData["autoRehide"]
              )}
            </FormGroup>
          </FormControl>
          {formData["replierInfo"] &&
            formData["replierInfo"]["enabled"] === true &&
            formData["replierInfo"]["assets"] &&
            formData["replierInfo"]["assets"].length && (
              <FormControl className={classes.formControl}>
                <FormGroup className={classes.formGroup}>
                  <SelectComponent
                    displaySearch
                    attributId="id"
                    attributName="name"
                    label="Preferred reply profile"
                    error={errors["replierInfo"]}
                    value={
                      formData["replierInfo"]["assets"].find(
                        (asset) => asset.selected
                      ).id
                    }
                    items={getFlattenedAssetsOptions()}
                    fullWidth
                    display={1}
                    onChange={(e) => {
                      return props.onChangeFormData("replierInfo", {
                        ...formData["replierInfo"],
                        assets: formData["replierInfo"]["assets"].map(
                          (asset) => {
                            return {
                              ...asset,
                              selected: asset.id === e.target.value,
                            };
                          }
                        ),
                      });
                    }}
                  />
                </FormGroup>
                <FormGroup className={classes.formGroup}>
                  {displayActionButton(
                    "replierInfo",
                    "Update reply profile",
                    formData["replierInfo"]
                  )}
                </FormGroup>
              </FormControl>
            )}
        </React.Fragment>
      )}
    </div>
  );
};

AssetManagementForm.propTypes = {
  formData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChangeFormData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isCSNAlreadyAssigned: PropTypes.bool.isRequired,
  formOptions: PropTypes.object.isRequired,
  formPermissions: PropTypes.object.isRequired,
  formActionsLoading: PropTypes.object.isRequired,
};

export default AssetManagementForm;
