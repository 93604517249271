import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux
import {
  fetchAssetManagementList,
  fetchActiveServicesList,
  fetchAssetManagement,
  assignClientShortname,
  assignClientShortnameBatch,
  createTasks,
  toggleCrawlingDisabled,
  toggleCrawlingStartStop,
  updateAsset,
  updateAssetBatch
} from '../../../actions/configuration/assetManagementAction';

import { fetchClientList } from '../../../actions/configuration/clientsAction';
import { fetchClient } from '../../../actions/clientSingleAction';

import AssetManagementConfiguration from './assetManagementConfiguration';
import { extractAssetManagementList } from '../../../extractors/assetManagementExtractor';
import { extractClientList } from '../../../extractors/clientsExtractor';

const mapStateToProps = state => ({
  assetManagementState: state.assetManagamentReducer,
  clientState: state.clientsReducer
});

const mapDispatchToProps = dispatch => ({
  fetchAssetManagementList: () => dispatch(fetchAssetManagementList()),
  fetchActiveServicesList: () => dispatch(fetchActiveServicesList()),
  fetchClientList: () => dispatch(fetchClientList()),
  fetchClient: clientShortName => dispatch(fetchClient(clientShortName)),
  fetchAssetManagement: (index, assetId) =>
    dispatch(fetchAssetManagement(index, assetId)),
  assignClientShortname: (index, assetId, clientShortName) =>
    dispatch(assignClientShortname(index, assetId, clientShortName)),
  assignClientShortnameBatch: (assetIds, clientShortName) =>
    dispatch(assignClientShortnameBatch(assetIds, clientShortName)),
  createTasks: (index, assetIds, clientShortName, formData) =>
    dispatch(createTasks(index, assetIds, clientShortName, formData)),
  toggleCrawlingDisabled: (index, assetIds, isEnable) =>
    dispatch(toggleCrawlingDisabled(index, assetIds, isEnable)),
  toggleCrawlingStartStop: (index, assetIds, clientShortName, isStart) =>
    dispatch(
      toggleCrawlingStartStop(index, assetIds, clientShortName, isStart)
    ),
  updateAsset: (index, assetId, updateParam, updateValue) =>
    dispatch(updateAsset(index, assetId, updateParam, updateValue)),
  updateAssetBatch: (assetIds, updateParam, updateValue) =>
    dispatch(updateAssetBatch(assetIds, updateParam, updateValue))
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  loading: {
    margin: 'auto',
    marginTop: 'calc(50vh - 96px)',
    color: theme.palette.orange[300]
  },
  notAllowed: {
    margin: 'auto',
    height: 'calc(100vh - 367px)',
    marginTop: 'calc(50vh - 96px)'
  }
}));

const AssetManagementContainer = props => {
  const classes = useStyles();

  const [isInitialized, setInitialized] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isNotAllowed, setNotAllowed] = useState(false);

  const initialFormPermissions = {
    clientShortName: false,
    clientShortNameBatch: false,
    alias: false,
    activeServices: false,
    activeServicesBatch: false
  };
  const [formPermissions, setFormPermissions] = useState(
    initialFormPermissions
  );

  const initialCrawlingPermissions = {
    createTasks: false,
    startTasks: false,
    stopTasks: false
  };
  const [crawlingPermissions, setCrawlingPermissions] = useState(
    initialCrawlingPermissions
  );

  const [assetManagementList, setAssetManagementList] = useState([]);

  const [activeServicesList, setActiveServicesList] = useState([]);
  const [clientList, setClientList] = useState([]);

  const fetchAssetManagement = (index, assetId, isEditBatch, callback) => {
    if (isEditBatch) {
      props.fetchAssetManagementList().then(response => {
        let asset = response.value.data.find(a => a.id === assetId);
        asset = asset ? extractAssetManagementList([asset])[0] : null;
        callback(asset);
      });
    } else {
      props.fetchAssetManagement(index, assetId).then(response => {
        const asset = extractAssetManagementList([response.value.data])[0];
        if (asset) {
          callback(asset);
        } else {
          callback(null);
        }
      });
    }
  };

  const fetchClient = (clientShortName, callback) => {
    props
      .fetchClient(clientShortName)
      .then(response => {
        const client = extractClientList([response.data], 'config')[0];
        if (client) {
          callback(extractClientList([response.data], 'config')[0]);
        } else {
          callback(null);
        }
      })
      .catch(() => {
        callback(null);
      });
  };

  const assignClientShortname = (
    index,
    assetIds,
    clientShortName,
    isEditBatch,
    callback
  ) => {
    if (isEditBatch) {
      props
        .assignClientShortnameBatch(assetIds, clientShortName)
        .then(() => {
          callback(true);
        })
        .catch(() => {
          callback(false);
        });
    } else {
      props
        .assignClientShortname(index, assetIds[0], clientShortName)
        .then(() => {
          callback(true);
        })
        .catch(() => {
          callback(false);
        });
    }
  };

  const createTasks = (
    index,
    assetIds,
    clientShortName,
    formData,
    callback
  ) => {
    props
      .createTasks(index, assetIds, clientShortName, formData)
      .then(() => {
        callback(true);
      })
      .catch(() => {
        callback(false);
      });
  };

  const toggleCrawlingDisabled = (index, assetIds, isEnable, callback) => {
    props
      .toggleCrawlingDisabled(index, assetIds, isEnable)
      .then(() => {
        callback(true);
      })
      .catch(() => {
        callback(false);
      });
  };

  const toggleCrawlingStartStop = (
    index,
    assetIds,
    clientShortName,
    isStart,
    callback
  ) => {
    props
      .toggleCrawlingStartStop(index, assetIds, clientShortName, isStart)
      .then(() => {
        callback(true);
      })
      .catch(() => {
        callback(false);
      });
  };

  const updateAsset = (
    index,
    assetIds,
    updateParam,
    updateValue,
    isEditBatch,
    callback
  ) => {
    if (isEditBatch) {
      props
        .updateAssetBatch(assetIds, updateParam, updateValue)
        .then(() => {
          callback(true);
        })
        .catch(() => {
          callback(false);
        });
    } else {
      props
        .updateAsset(index, assetIds[0], updateParam, updateValue)
        .then(() => {
          callback(true);
        })
        .catch(() => {
          callback(false);
        });
    }
  };

  useEffect(() => {
    if (
      props.permissions.includes('admin_list_assets') &&
      props.permissions.includes('admin_get_asset_details')
    ) {
      if (props.tabIndex === 6 && !isInitialized) {
        setLoading(true);
        setInitialized(true);
        props.fetchClientList().then(() => {
          props.fetchActiveServicesList().then(response => {
            setActiveServicesList(response.value.data);
            props.fetchAssetManagementList();
          });
        });
      }
      //TODO enable all update permissions based on their availability
      setFormPermissions({
        clientShortName: props.permissions.includes(
          'admin_asset_client_assign'
        ),
        clientShortNameBatch: props.permissions.includes(
          'admin_asset_client_batch_assign'
        ),
        alias: props.permissions.includes('admin_update_asset'),
        activeServices: props.permissions.includes('admin_update_asset'),
        activeServicesBatch: props.permissions.includes(
          'admin_asset_batch_update'
        )
      });

      setCrawlingPermissions({
        createTasks: props.permissions.includes('admin_assets_create_tasks'),
        startTasks: props.permissions.includes('admin_assets_start_tasks'),
        stopTasks: props.permissions.includes('admin_assets_stop_tasks'),
        disableCrawling: props.permissions.includes(
          'admin_assets_disable_crawling'
        ),
        enableCrawling: props.permissions.includes(
          'admin_assets_enable_crawling'
        )
      });
    } else {
      setNotAllowed(true);
    }
  }, [props.tabIndex]);

  useEffect(() => {
    if (!props.clientState.fetching) {
      setClientList(props.clientState.clientList);
    }
  }, [props.clientState.fetching]);

  useEffect(() => {
    if (!props.assetManagementState.fetching) {
      setAssetManagementList(props.assetManagementState.assetManagementList);
      setLoading(false);
    }
  }, [props.assetManagementState.fetching]);

  return (
    <div className={classes.root}>
      {!isNotAllowed && !isLoading ? (
        <AssetManagementConfiguration
          assetManagementList={assetManagementList}
          activeServicesList={activeServicesList}
          clientList={clientList}
          formPermissions={formPermissions}
          crawlingPermissions={crawlingPermissions}
          fetchAssetManagement={fetchAssetManagement}
          assignClientShortname={assignClientShortname}
          createTasks={createTasks}
          toggleCrawlingDisabled={toggleCrawlingDisabled}
          toggleCrawlingStartStop={toggleCrawlingStartStop}
          updateAsset={updateAsset}
          fetchClient={fetchClient}
        />
      ) : !isNotAllowed ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <Typography className={classes.notAllowed} variant="body1">
          Not Allowed
        </Typography>
      )}
    </div>
  );
};

AssetManagementContainer.propTypes = {
  tabIndex: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetManagementContainer);
