import { setUser } from "../setters/user";

export const extractKeyFromRoles = (roles, roleId) => {
  return roles
    .map((role, key) => {
      return role.id === roleId ? key : "";
    })
    .filter((key) => {
      return key !== "";
    })[0];
};

export const extractUserList = (data) => {
  const userList = [];
  data &&
    data.items &&
    data.items.map((user, key) => {
      userList.push(setUser(user, key));
      return true;
    });

  return userList;
};
