import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import TableComponent from '../../commons/table/tableComponent';
import DialogContent from '../../commons/dialog/contentDialogComponent';

import ClassifiersForm from './classifiersForm';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 7
  },
  buttonAddClassifier: {
    marginLeft: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  detailContainer: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  detailItem: {
    width: '33%',
    margin: theme.spacing(1),
    backgroundColor: theme.palette.container.light
  },
  detailLegend: {
    padding: theme.spacing(1),
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center'
  },
  detailContent: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    height: 100,
    overflow: 'auto'
  },
  colorGroupRowContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  colorGroupPreview: {
    width: 15,
    height: 15,
    marginLeft: theme.spacing(1)
  }
});

class ClassifiersConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classifierSelected: 0,
      openDialogClassifiers: false,
      checkFilter: false,
      onErrorFilter: false,
      mode: 'add',
      defaultValue: {},
      error: {},
      editable: props.editable,
      creatable: props.creatable,
      tagList: props.tagList || [],
      columns: [
        {
          title: 'Classifier name',
          cellStyle: {
            padding: 2,
            paddingLeft: 14
          },
          field: 'classifier_name'
        },
        {
          title: 'Group name',
          cellStyle: {
            padding: 2,
            paddingLeft: 14
          },
          field: 'group'
        },
        {
          title: 'Group Color',
          cellStyle: {
            padding: 2,
            paddingLeft: 14
          },
          render: rowData => {
            return this.displayRowColorGroup(rowData.colorGroup);
          },
          field: 'colorGroup'
        },
        {
          title: 'Versions',
          cellStyle: {
            padding: 2,
            paddingLeft: 14
          },
          render: rowData => {
            return (
              <p>
                {typeof rowData.versions === 'string'
                  ? rowData.versions
                  : rowData.versions.join(', ')}
              </p>
            );
          },
          field: 'versions'
        }
      ],
      data: props.classifierList || [],
      actions: this.onCreateActions(props.editable),
      classifier: {}
    };
  }

  displayRowColorGroup(color) {
    const { classes } = this.props;

    return (
      <div className={classes.colorGroupRowContainer}>
        <div className={classes.colorGroupText}>{color}</div>
        <div
          className={classes.colorGroupPreview}
          style={{ backgroundColor: color }}
        ></div>
      </div>
    );
  }

  showClassifierDetails(rowData) {
    const { classes } = this.props;
    return (
      <div className={classes.detailContainer}>
        <div className={classes.detailItem}>
          <div className={classes.detailLegend}>{'Description'}</div>
          <div className={classes.detailContent}>{rowData.description}</div>
        </div>
        <div className={classes.detailItem}>
          <div className={classes.detailLegend}>{'Example'}</div>
          <div className={classes.detailContent}>{rowData.example}</div>
        </div>
        <div className={classes.detailItem}>
          <div className={classes.detailLegend}>{'Excluded Example'}</div>
          <div className={classes.detailContent}>
            {rowData.excluded_example}
          </div>
        </div>
      </div>
    );
  }

  onCreateActions(editable) {
    return editable
      ? [
          {
            icon: 'edit',
            tooltip: 'Edit Classifier',
            onClick: (event, rowData) =>
              this.actionEditClassifier(event, rowData)
          }
        ]
      : [];
  }

  onErrorClassifierForm(e) {
    this.setState({
      onErrorFilter: true
    });
  }

  onChangeClassifierForm(data) {
    this.setState({
      onErrorFilter: false,
      checkFilter: false,
      classifier: data
    });
  }

  checkForErrors() {
    const { classifier, onErrorFilter } = this.state;

    const promise = new Promise((resolve, reject) => {
      this.setState(
        {
          error: {
            classifier_name: classifier.classifier_name
              ? ''
              : 'Name is mandatory',
            group: classifier.group ? '' : 'Group name is mandatory',
            colorGroup: classifier.colorGroup ? '' : 'Group color is mandatory',
            versions: classifier.versions.length ? '' : 'Versions is mandatory',
            default_rules: onErrorFilter
              ? 'JSON not valid'
              : classifier.default_rules.length
              ? ''
              : 'need at least 1 rule'
          }
        },
        () => {
          resolve();
        }
      );
    });

    return promise;
  }

  updateClassifier() {
    this.setState(
      {
        checkFilter: true
      },
      () => {
        setTimeout(() => {
          this.checkForErrors().then(() => {
            if (
              !Object.values(this.state.error).filter(value => value !== '')
                .length
            ) {
              if (this.state.mode === 'add') {
                this.props.addClassifier(this.state.classifier);
              } else {
                this.props.editClassifier(
                  this.state.classifierSelected,
                  this.state.classifier
                );
              }
              this.closeDialogClassifiers();
            }
          });
        }, 100);
      }
    );
  }

  openDialogClassifiers() {
    this.setState({
      openDialogClassifiers: true
    });
  }

  closeDialogClassifiers() {
    this.setState({
      openDialogClassifiers: false
    });
  }

  actionAddClassifier(e) {
    const defaultValue = {
      classifier_name: '',
      group: '',
      colorGroup: '',
      versions: [],
      default_rules: [
        {
          autoprocess: false,
          tags: [],
          filters: []
        }
      ]
    };

    this.setState(
      {
        mode: 'add',
        defaultValue: defaultValue,
        classifier: defaultValue
      },
      () => {
        this.openDialogClassifiers();
      }
    );
  }

  actionEditClassifier(e, data) {
    const defaultValue = {
      classifier_name: data.classifier_name,
      group: data.group,
      colorGroup: data.colorGroup,
      versions: data.versions,
      default_rules: data.default_rules
    };

    this.setState(
      {
        mode: 'edit',
        classifierSelected: data.tableData.id,
        defaultValue: defaultValue,
        classifier: defaultValue
      },
      () => {
        this.openDialogClassifiers();
      }
    );
  }

  displayButtonsClassifier() {
    const { classes } = this.props;
    const { mode } = this.state;
    return (
      <div className={classes.buttonContainer}>
        <Button
          size="small"
          color="primary"
          onClick={e => {
            return this.updateClassifier();
          }}
        >
          {mode === 'add' ? 'Add classifier' : 'Edit classifier'}
        </Button>
        <Button
          onClick={e => {
            return this.closeDialogClassifiers(e);
          }}
          size="small"
          color="secondary"
        >
          Cancel
        </Button>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.classifierList !== this.props.classifierList) {
      this.setState({
        data: this.props.classifierList
      });
    }

    if (prevProps.creatable !== this.props.creatable) {
      this.setState({
        creatable: this.props.creatable
      });
    }

    if (prevProps.editable !== this.props.editable) {
      this.setState({
        editable: this.props.editable,
        actions: this.onCreateActions(this.props.editable)
      });
    }

    if (prevProps.tagList !== this.props.tagList) {
      this.setState({
        tagList: this.props.tagList
      });
    }
  }

  render() {
    const { classes, creatable } = this.props;
    const {
      columns,
      data,
      actions,
      detailPanel,
      openDialogClassifiers,
      defaultValue,
      checkFilter,
      error,
      tagList,
      mode
    } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h5">Classifier list</Typography>
          {creatable ? (
            <Button
              variant="contained"
              className={classes.buttonAddClassifier}
              size="small"
              color="primary"
              onClick={e => {
                return this.actionAddClassifier(e);
              }}
            >
              Add classifier
            </Button>
          ) : (
            ''
          )}
        </div>
        <TableComponent
          editable={false}
          columns={columns}
          data={data}
          actions={actions}
          title=""
          detailPanel={detailPanel}
        />
        <DialogContent
          title={'Classifier'}
          open={openDialogClassifiers}
          fullWidth={true}
          close={this.closeDialogClassifiers.bind(this)}
        >
          <div>
            <ClassifiersForm
              mode={mode}
              defaultValue={defaultValue}
              checkFilter={checkFilter}
              tagList={tagList}
              onErrorClassifierForm={this.onErrorClassifierForm.bind(this)}
              onChangeClassifierForm={this.onChangeClassifierForm.bind(this)}
              error={error}
            />
            {this.displayButtonsClassifier()}
          </div>
        </DialogContent>
      </div>
    );
  }
}

ClassifiersConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  addClassifier: PropTypes.func.isRequired,
  editClassifier: PropTypes.func.isRequired,
  classifierList: PropTypes.array,
  editable: PropTypes.bool,
  creatable: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(
  ClassifiersConfiguration
);
