import { setLinkedAccount } from '../setters/linkedAccount';

export const extractLinkedAccounts = data => {
  const linkedAccounts = [];

  data.map((la, key) => {
    linkedAccounts.push(setLinkedAccount(la, key));
    return true;
  });

  return linkedAccounts;
};
