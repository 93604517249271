import axios from 'axios';

import { trendsConstants } from '../../constants/configuration/trendsConstants';
import { setHeaders } from '../../setters/auth';

export const fetchGlobalTrendList = () => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/cfa/global-trends`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: trendsConstants.FETCH_GLOBAL_TREND_LIST,
    payload: promise
  });
};

export const createGlobalTrend = trend => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/cfa/global-trends`;

  const promise = axios.post(
    url,
    {
      name: trend.name,
      description: trend.description,
      keywords: trend.keywords,
      end_date: trend.end_date,
      included_clients: trend.included_clients,
      excluded_clients: trend.excluded_clients
    },
    {
      headers: setHeaders(),
      trend: trend
    }
  );

  return dispatch({
    type: trendsConstants.CREATE_GLOBAL_TREND,
    payload: promise
  });
};

export const updateGlobalTrend = (index, trend) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/cfa/global-trends/${trend.id}`;

  const promise = axios.put(
    url,
    {
      name: trend.name,
      description: trend.description,
      keywords: trend.keywords,
      end_date: trend.end_date,
      included_clients: trend.included_clients,
      excluded_clients: trend.excluded_clients
    },
    {
      headers: setHeaders(),
      index: index,
      trend: trend
    }
  );

  return dispatch({
    type: trendsConstants.UPDATE_GLOBAL_TREND,
    payload: promise
  });
};

export const deleteGlobalTrend = (index, id) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/cfa/global-trends/${id}`;

  const promise = axios.delete(url, {
    headers: setHeaders(),
    id: id,
    index: index
  });

  return dispatch({
    type: trendsConstants.DELETE_GLOBAL_TREND,
    payload: promise
  });
};
