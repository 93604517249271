import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "query-string";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

import AppBar from "../../components/commons/appBar/AppBarComponent";
import NotificationMenu from "../../components/commons/notificationMenu/notificationMenuComponent";
import LeftMenu from "../../components/commons/leftMenu/leftMenuComponent";
import ActionsContent from "../../components/actionsContent/moderation/actionContentComponent";

import CommentList from "../../components/commentList/CommentListComponent";
import PostRelated from "../../components/postRelated/postRelatedComponent";
import TagList from "../../components/tagList/tagListComponent";

import { operationalTags } from "../../data/data";
import { getUTC } from "../../setters/date";

import { fetchMe } from "../../actions/meAction";
import { getPostStatistics } from "../../actions/statisticsAction";
import {
  getCommentList,
  setTagToComment,
  resetCommentList,
  updateCommentStatus,
  setIsClassifying,
  commentAddTime,
  moderationEndEvent,
  fetchHistoryFromComment,
  updateTranslation,
  fetchAdsFromPost,
  unlikeComment,
  setIsFavorite,
} from "../../actions/moderationAction";
import {
  getStreamList,
  addFavouriteToStream,
  streamSelected,
  deleteStream,
  unlockCommentFromStream,
} from "../../actions/streamAction";

import {
  setFilterModerationEvent,
  setFilterAlertEvent,
} from "../../setters/events";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    height: 48,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: "hidden",
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  postRelated: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  tagList: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  postRelatedContainer: {
    marginBottom: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(1),
  },
  actionsContent: {
    width: "100%",
    textAlign: "left",
    marginLeft: theme.spacing(1),
  },
  tagListContainer: {
    marginBottom: theme.spacing(1),
  },
  leftSide: {
    display: "flex",
  },
  rightSide: {
    overflow: "hidden auto",
  },
  finishModerationButton: {
    marginTop: 10,
    backgroundColor: theme.palette.orange[300],
    color: theme.palette.white,

    "&:hover": {
      backgroundColor: theme.palette.orange[600],
    },
  },
  counterContainer: {
    width: "100%",
    textAlign: "left",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.white,
  },
  loading: {
    margin: "auto",
    color: theme.palette.orange[300],
    marginTop: "calc(50vh - 88px)",
    marginBottom: "50vh",
  },
  moderationButton: {
    backgroundColor: theme.palette.orange[300],
    color: theme.palette.white,
    marginTop: "calc(50vh - 96px)",
    marginLeft: -90,

    "&:hover": {
      backgroundColor: theme.palette.orange[600],
    },
  },
  notAllowed: {
    margin: "auto",
    marginTop: "calc(50vh - 96px)",
  },
  expander: {
    width: theme.spacing(3),
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 380px)",
    borderRadius: "0px 5px 5px 0px",
    marginLeft: theme.spacing(1),
  },
  hideRightSide: {
    display: "none",
  },
  cardTagList: {
    padding: 0,
    backgroundColor: theme.palette.white,
    boxShadow: theme.palette.shadow.top,
  },
});

const mapStateToProps = (state) => ({
  me: state.meReducer,
  comment: state.moderationReducer,
  statistics: state.statisticsReducer,
  stream: state.streamReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMe: () => dispatch(fetchMe()),
  getCommentList: (stream, option) => dispatch(getCommentList(stream, option)),
  setIsClassifying: (index, value) => dispatch(setIsClassifying(index, value)),
  setIsFavorite: (index, value) => dispatch(setIsFavorite(index, value)),
  commentAddTime: (index, text, time) =>
    dispatch(commentAddTime(index, text, time)),
  moderationEndEvent: (filter, index, streamId) =>
    dispatch(moderationEndEvent(filter, index, streamId)),
  setTagToComment: (filter, tagCategories, tags, index, streamId) =>
    dispatch(setTagToComment(filter, tagCategories, tags, index, streamId)),
  updateCommentStatus: (commentId, data) =>
    dispatch(updateCommentStatus(commentId, data)),
  resetCommentList: () => dispatch(resetCommentList()),
  getStreamList: () => dispatch(getStreamList()),
  addFavouriteToStream: (key, type) =>
    dispatch(addFavouriteToStream(key, type)),
  getPostStatistics: (clientShortName, postId) =>
    dispatch(getPostStatistics(clientShortName, postId)),
  streamSelected: (streamId) => dispatch(streamSelected(streamId)),
  deleteStream: (streamId) => dispatch(deleteStream(streamId)),
  unlockCommentFromStream: (streamId) =>
    dispatch(unlockCommentFromStream(streamId)),
  fetchHistoryFromComment: (clientShortName, commentId, options) =>
    dispatch(fetchHistoryFromComment(clientShortName, commentId, options)),
  fetchAdsFromPost: (clientShortName, postId, options) =>
    dispatch(fetchAdsFromPost(clientShortName, postId, options)),
  updateTranslation: (clientShortName, commentId, options) =>
    dispatch(updateTranslation(clientShortName, commentId, options)),
  unlikeComment: (clientShortName, commentId, streamId, index) =>
    dispatch(unlikeComment(clientShortName, commentId, streamId, index)),
});

class ModerationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      streamIndex: "0",
      indexList: "0",
      moderation: false,
      mode: "moderation",
      translate: false,
      streams: [],
      streamsGrouped: {},
      list: [],
      groupsTagList: [],
      heightList: "360px",
      groupsTagValues: [],
      counter: 0,
      disableStartModeration: false,
      showExample: false,
      counterTotal: 0,
      displayBoth: false,
      isAddingTag: false,
      isClassifying: false,
      statisticsSelected: false,
      triggerEvents: {},
      statistics: {},
      query: queryString.parse(window.location.search),
      notAllowed: false,
      expander: false,
      size: {
        xl: 8,
        lg: 7,
        sm: 6,
        xs: 7,
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.comment.list !== prevState.list) {
      update.list = nextProps.comment.list;
      update.heightList = "380px";
    }

    if (nextProps.statistics.statistics !== prevState.statistics) {
      update.statistics = nextProps.statistics.statistics;
    }

    if (nextProps.stream.streams !== prevState.streams) {
      update.streams = nextProps.stream.streams;
      update.streamsGrouped = nextProps.stream.streamsGrouped;
      //patch when the stream list change, re-select the good stream
      const newStreamIndex = nextProps.stream.streams
        .map((stream, key) => {
          return stream.id === nextProps.stream.streamSelected ? key : "";
        })
        .filter((key) => {
          return key !== "";
        });

      if (newStreamIndex && newStreamIndex[0]) {
        update.streamIndex = newStreamIndex[0].toString();
      }
    }

    return Object.keys(update).length ? update : null;
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  resetLocation() {
    window.history.pushState(
      null,
      null,
      `${window.location.origin}/moderation`
    );
  }

  streamSelected = (index, firstLoad, callback = null) => {
    const streamsFiltered = this.state.streams
      .map((stream, key) => {
        return stream.id === index ? key : "";
      })
      .filter((key) => {
        return key !== "";
      });

    const streamIndex =
      streamsFiltered.length > 0 ? streamsFiltered[0].toString() : null;

    if (streamIndex) {
      this.setState(
        {
          streamIndex: streamIndex,
        },
        () => {
          this.props.streamSelected(index).then(() => {
            if (this.state.moderation && !firstLoad) {
              this.resetLocation();
              this.stop();
            }

            if (typeof callback === "function") {
              callback();
            }
          });
        }
      );
    }
  };

  actionDeleteStream(id) {
    if (this.state.query && id === this.state.query.stream) {
      this.resetLocation();
    }
    this.props.deleteStream(id);
  }

  deleteStream(streamDelete) {
    const { streamIndex, streams } = this.state;
    if (streamDelete.key.toString() !== streamIndex) {
      this.actionDeleteStream(streamDelete.id);
    } else {
      let newStreamSelected = "";
      if (
        streamDelete.key.toString() === "0" &&
        streamDelete.key.toString() === streamIndex
      ) {
        newStreamSelected = streams[1]["id"];
      } else if (streamDelete.key.toString() === streamIndex) {
        newStreamSelected = streams[0]["id"];
      }
      this.streamSelected(newStreamSelected, false, () => {
        this.actionDeleteStream(streamDelete.id);
      });
    }
  }

  finishModeration() {
    const { streams, streamIndex } = this.state;

    if (streams[streamIndex] && streams[streamIndex]["own"]) {
      this.deleteStream(streams[streamIndex]);
    }
    this.stop();
  }

  logout() {
    this.props.signOut();
  }

  setupTranslation(data) {
    const { translate, displayBoth } = this.state;
    this.setState({
      translate:
        data && data.translate !== undefined ? data.translate : translate,
      displayBoth:
        data && data.displayBoth !== undefined ? data.displayBoth : displayBoth,
    });
  }

  setupGroupsTag(data) {
    const { streams, streamIndex } = this.state;
    const groupsTag =
      streams && streams[streamIndex] && streams[streamIndex]["groupsTag"]
        ? streams[streamIndex]["groupsTag"]
        : [];
    const values = groupsTag.map((i) => i.group);

    this.setState({
      groupsTagValues: data === undefined || data === null ? values : data,
      groupsTagList: groupsTag,
    });
  }

  start() {
    const { streams, streamIndex } = this.state;
    const translation = JSON.parse(localStorage.getItem("translateConfig"));
    const groupsTagConfig = JSON.parse(localStorage.getItem("groupsTagConfig"));
    const id =
      streams[streamIndex] && streams[streamIndex]["id"]
        ? streams[streamIndex]["id"]
        : "none";
    this.setupTranslation(translation ? translation[id] : undefined);
    this.setupGroupsTag(groupsTagConfig ? groupsTagConfig[id] : undefined);
    this.fetchCommentList();
    this.setState({
      moderation: true,
    });
  }

  stop() {
    const { streams, streamIndex } = this.state;

    if (
      streams &&
      streams.length &&
      streams[streamIndex] &&
      streams[streamIndex]["id"]
    ) {
      const streamId = streams[streamIndex]["id"];
      this.setState(
        {
          disableStartModeration: true,
        },
        () => {
          this.props.unlockCommentFromStream(streamId).then(() => {
            this.props.resetCommentList().then(() => {
              this.setState(
                {
                  counter: 0,
                  counterTotal: 0,
                  indexList: "0",
                  translate: false,
                  showExample: false,
                  displayBoth: false,
                  statisticsSelected: false,
                },
                () => {
                  this.setState({
                    disableStartModeration: false,
                    moderation: false,
                  });
                }
              );
            });
          });
        }
      );
    }
  }

  onChangeExpander() {
    this.setState(
      {
        expander: !this.state.expander,
      },
      () => {
        this.setState({
          size: {
            xl: this.state.expander ? 12 : 8,
            lg: this.state.expander ? 12 : 7,
            sm: this.state.expander ? 12 : 6,
            xs: this.state.expander ? 12 : 7,
          },
        });
      }
    );
  }

  updateTranslation(item, callback) {
    this.props
      .updateTranslation(item[1].client.shortName, item[1].id, {
        index: item[1].key,
      })
      .then((response) => {
        callback(response.value.data.messageTranslated);
      });
  }

  setLocalStorage(key, subKey, data) {
    let config = JSON.parse(localStorage.getItem(key));
    config = {
      ...config,
      [subKey]: data,
    };
    localStorage.setItem(key, JSON.stringify(config));
  }

  handleGroupsTag(e, id) {
    const { streams, streamIndex } = this.state;
    this.setLocalStorage(
      "groupsTagConfig",
      streams[streamIndex]["id"],
      e.target.value
    );
    this.setState({
      groupsTagValues: e.target.value,
    });
  }

  translate() {
    const { streams, streamIndex, translate, displayBoth } = this.state;
    this.setLocalStorage("translateConfig", streams[streamIndex]["id"], {
      translate: !translate,
      displayBoth: displayBoth,
    });
    this.setState({
      translate: !translate,
    });
  }

  displayBoth() {
    const { streams, streamIndex, translate, displayBoth } = this.state;
    this.setLocalStorage("translateConfig", streams[streamIndex]["id"], {
      translate: translate,
      displayBoth: !displayBoth,
    });
    this.setState({
      displayBoth: !displayBoth,
    });
  }

  onChangeIndexMenu(index) {
    if (index === 1) {
      this.setState(
        {
          statisticsSelected: true,
        },
        () => {
          const post = this.state.list[this.state.indexList]["post"];
          this.props.getPostStatistics(post["client"]["shortName"], post["id"]);
        }
      );
    } else {
      this.setState({
        statisticsSelected: false,
      });
    }
  }

  getIndexList(index) {
    //get index of the comment list
    this.setState(
      {
        indexList: index,
        disableKeyboard: index ? true : false,
      },
      () => {
        if (index) {
          this.commentAddTime("startTime", index, () => {
            this.setState({
              disableKeyboard: false,
            });
          });
        }
      }
    );
    if (index && this.state.list && this.state.list[index]) {
      const post = this.state.list[index]["post"];
      if (post && post["client"] && this.state.statisticsSelected) {
        this.props.getPostStatistics(post["client"]["shortName"], post["id"]);
      }
    }
  }

  clickItemFromPost(string) {
    if (string === "openAds") {
      const post = this.state.list[this.state.indexList]["post"];
      this.props.fetchAdsFromPost(post["client"]["shortName"], post["id"], {
        index: this.state.indexList,
      });
    }
  }

  clickItemFromComment(string, data, callback) {
    const { list, indexList, streams, streamIndex } = this.state;

    if (typeof callback === "function") {
      callback();
    }

    if (string === "unlike") {
      this.props.setIsClassifying(indexList, true);

      if (
        streams &&
        streams.length &&
        streams[streamIndex] &&
        list &&
        list.length &&
        list[indexList] &&
        list[indexList]["client"]
      ) {
        const clientShortName = list[indexList]["client"]["shortName"];
        const commentId = list[indexList]["id"];
        const streamId = streams[streamIndex]["id"];

        this.props
          .unlikeComment(clientShortName, commentId, streamId, indexList)
          .finally(() => {
            this.props.setIsClassifying(indexList, false);
          });
      }
    } else if (string !== "history") {
      const tags = Object.assign([], list[indexList]["tags"]);
      const newTags = [];

      tags.map((tag) => {
        if (string === "unhide") {
          if (tag.id === "unhide") {
            newTags.push(
              Object.assign({}, tag, {
                checked: list[indexList][data.label],
              })
            );
          } else if (tag.id === "hide") {
            newTags.push(
              Object.assign({}, tag, {
                checked: false,
              })
            );
          } else {
            newTags.push(
              Object.assign({}, tag, {
                checked: tag.checked,
              })
            );
          }
        } else if (string === "hide") {
          if (tag.id === "hide") {
            newTags.push(
              Object.assign({}, tag, {
                checked: !list[indexList][data.label],
              })
            );
          } else if (tag.id === "unhide") {
            newTags.push(
              Object.assign({}, tag, {
                checked: false,
              })
            );
          } else {
            newTags.push(
              Object.assign({}, tag, {
                checked: tag.checked,
              })
            );
          }
        } else if (string === "delete") {
          if (tag.id === "delete") {
            newTags.push(
              Object.assign({}, tag, {
                checked: !list[indexList][data.label],
              })
            );
          } else {
            newTags.push(
              Object.assign({}, tag, {
                checked: tag.checked,
              })
            );
          }
        } else if (string === "favorite") {
          if (tag.id === string) {
            newTags.push(
              Object.assign({}, tag, {
                checked: !list[indexList][data.data.label],
              })
            );
          } else {
            newTags.push(
              Object.assign({}, tag, {
                checked: tag.checked,
              })
            );
          }
        } else if (string === "unsure") {
          if (tag.id === string) {
            newTags.push(
              Object.assign({}, tag, {
                checked: !list[indexList][data.label],
              })
            );
          } else {
            newTags.push(
              Object.assign({}, tag, {
                checked: tag.checked,
              })
            );
          }
        }
        return true;
      });

      this.getTagList(newTags);
      this.props.updateCommentStatus(indexList, data);
    } else {
      if (list && list.length && list[indexList] && list[indexList]["client"]) {
        const clientShortName = list[indexList]["client"]["shortName"];
        const commentId = list[indexList]["id"];

        this.props.fetchHistoryFromComment(clientShortName, commentId, {
          index: indexList,
        });
      }
    }
  }

  tagsEvent(e) {
    const { list, indexList } = this.state;

    if (e === "favorite" && list[indexList]) {
      this.props.setIsFavorite(indexList, !list[indexList]["isFavorite"]);
    }

    this.setState({
      triggerEvents: {
        ...this.state.triggerEvents,
        [e]: !this.state.triggerEvents[e],
      },
    });
  }

  getTagList(tags, tagCategories) {
    const { list, indexList, streams, streamIndex } = this.state;
    const comment = list[indexList];
    const stream = streams[streamIndex];

    if (comment && comment.id && stream && stream.id) {
      this.setState({
        isAddingTag: true,
      });

      this.props
        .setTagToComment(
          setFilterAlertEvent(comment.id, tags),
          tagCategories,
          tags,
          indexList,
          stream.id
        )
        .then((response) => {
          this.setState({
            isAddingTag: false,
          });

          //Patch remove unhide tag if a hide action is here
          if (
            response.value.data.items &&
            response.value.data.items[0] &&
            response.value.data.items[0]["actions"] &&
            response.value.data.items[0]["actions"].find(
              (action) => action.objectType === "unhide"
            ) &&
            response.value.data.items[0]["actions"].find(
              (action) => action.objectType === "hide"
            )
          ) {
            const tags = Object.assign([], comment["tags"]);
            const newTags = [];
            tags.map((tag) => {
              if (tag.id === "unhide") {
                newTags.push(
                  Object.assign({}, tag, {
                    checked: false,
                  })
                );
              } else {
                newTags.push(
                  Object.assign({}, tag, {
                    checked: tag.checked,
                  })
                );
              }
              return true;
            });
            this.getTagList(newTags, tagCategories);
          }
        })
        .catch((e) => {
          this.setState({
            isAddingTag: false,
          });
        });
    }
  }

  classificationEvent(index, beforeClassification, afterClassification) {
    let comment = this.state.list[index];
    let stream = this.state.streams[this.state.streamIndex];

    if (index !== null && (!this.state.isAddingTag || !comment.isClassifying)) {
      beforeClassification();
      this.setState({
        isAddingTag: false,
        isClassifying: true,
      });
      this.props.setIsClassifying(index, true).then(() => {
        if (stream) {
          this.props
            .moderationEndEvent(
              setFilterModerationEvent([comment], Math.round(getUTC() / 1000)),
              index,
              stream.id
            )
            .then(() => {
              this.setState(
                {
                  counter: comment.classified
                    ? this.state.counter
                    : this.state.counter + 1,
                },
                () => {
                  afterClassification();
                  this.props.setIsClassifying(index, false).then(() => {
                    this.setState({
                      isClassifying: false,
                    });
                  });
                }
              );
            })
            .catch((e) => {
              this.props.setIsClassifying(index, false).then(() => {
                this.setState({
                  isClassifying: false,
                });
              });
            });
        }
      });
    }
  }

  commentAddTime(text, index = "0", callback = null) {
    let time = Math.round(getUTC() / 1000);
    this.props.commentAddTime(index, text, time).then(() => {
      if (typeof callback === "function") {
        return callback();
      }
    });
  }

  fetchCommentList(option = {}, callback = null) {
    // fetch 25 comments for the project selected
    // fetch parent comments for each comments
    const stream = this.state.streams[this.state.streamIndex];

    //Fetch parent comment
    this.props.getCommentList(stream, option).then((response) => {
      this.setState({
        counterTotal:
          this.state.counterTotal + response.value.data.items.length,
      });
      if (typeof callback === "function") {
        callback();
      }
    });
  }

  loadNext(callback) {
    // Load 25 comments
    this.props.resetCommentList();
    this.fetchCommentList({}, callback);
  }

  addFavouriteStream(key, type) {
    this.props.addFavouriteToStream(key, type);
  }

  getStreamList() {
    const { query } = this.state;
    this.props.getStreamList().then((response) => {
      if (query && query.stream) {
        if (
          response.value.data.items.find((stream) => query.stream === stream.id)
        ) {
          this.streamSelected(query.stream, true);
          this.start();
        }
      }
    });
  }

  handleExample = () => {
    this.setState({
      showExample: !this.state.showExample,
    });
  };

  componentDidMount() {
    if (this.state.query && this.state.query.stream) {
      this.setState({
        disableStartModeration: true,
      });
    }

    this.props.fetchMe().then((response) => {
      if (response.value.data.permissions.includes("list_streams")) {
        this.getStreamList();
      } else {
        this.setState({
          notAllowed: true,
        });
      }
    });
  }

  render() {
    const { classes } = this.props;
    const {
      open,
      streams,
      streamsGrouped,
      streamIndex,
      indexList,
      moderation,
      disableStartModeration,
      list,
      translate,
      displayBoth,
      groupsTagList,
      isAddingTag,
      isClassifying,
      groupsTagValues,
      counter,
      statistics,
      triggerEvents,
      notAllowed,
      showExample,
      size,
      expander,
      heightList,
    } = this.state;

    return (
      <div className={classes.root}>
        <AppBar
          action={this.handleDrawerOpen}
          status={open}
          logout={this.logout.bind(this)}
          selected={"moderation"}
        />
        <LeftMenu open={open} onClose={this.handleDrawerClose.bind(this)} />
        {streams.length ? (
          <NotificationMenu
            isClassifying={isClassifying}
            isAddingTag={isAddingTag}
            index={streamIndex}
            enableFavorite={true}
            fullWidth={true}
            selected={this.streamSelected.bind(this)}
            items={streams}
            itemsGrouped={streamsGrouped}
            title={"Streams"}
            moderation={moderation}
            addFavourite={this.addFavouriteStream.bind(this)}
            deleteItem={this.deleteStream.bind(this)}
            hasGroupedItems={true}
          />
        ) : (
          ""
        )}

        <main className={classes.content}>
          <div className={classes.toolbar} />
          {streams.length > 0 && !notAllowed ? (
            <Grid container spacing={2}>
              {!moderation ? (
                <Grid item xs={12}>
                  <Button
                    onClick={this.start.bind(this)}
                    variant="contained"
                    disabled={disableStartModeration}
                    className={classes.moderationButton}
                  >
                    Start Moderation
                  </Button>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={size.xl}
                    lg={size.lg}
                    sm={size.sm}
                    xs={size.xs}
                  >
                    <Grid item xs={12}>
                      <div className={classes.leftSide}>
                        <CommentList
                          style={{
                            height: `calc(100vh - ${heightList})`,
                          }}
                          getIndexList={this.getIndexList.bind(this)}
                          isAddingTag={isAddingTag}
                          disableKeyboard={isAddingTag}
                          indexList={indexList}
                          loadNext={this.loadNext.bind(this)}
                          classificationEvent={this.classificationEvent.bind(
                            this
                          )}
                          translated={translate}
                          displayBoth={displayBoth}
                          list={list}
                          clickItemFromComment={this.clickItemFromComment.bind(
                            this
                          )}
                          totalInStream={
                            streams &&
                            streams[streamIndex] &&
                            streams[streamIndex]["notification_count"]
                              ? streams[streamIndex]["notification_count"]
                              : 0
                          }
                          actionsMenu={operationalTags}
                          tagsEvent={this.tagsEvent.bind(this)}
                          updateTranslation={this.updateTranslation.bind(this)}
                        />
                        {list.length ? (
                          <div
                            onClick={this.onChangeExpander.bind(this)}
                            className={classes.expander}
                          >
                            <Icon>
                              {expander
                                ? `keyboard_arrow_left`
                                : `keyboard_arrow_right`}
                            </Icon>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    className={classNames(classes.rightSide, {
                      [classes.hideRightSide]: expander,
                    })}
                    item
                    xl={4}
                    lg={5}
                    sm={6}
                    xs={5}
                  >
                    <div className={classes.postRelatedContainer}>
                      <PostRelated
                        clickItemFromPost={this.clickItemFromPost.bind(this)}
                        statistics={statistics}
                        onChangeIndexMenu={this.onChangeIndexMenu.bind(this)}
                        post={
                          list && list[indexList]
                            ? list[indexList]["post"]
                            : null
                        }
                      />
                    </div>
                    <div className={classes.actionsContainer}>
                      {list.length ? (
                        <ActionsContent
                          isAddingTag={isAddingTag}
                          isClassifying={isClassifying}
                          itemsGroupsTab={groupsTagList}
                          groupsTagValues={groupsTagValues}
                          disableStartModeration={disableStartModeration}
                          handleExample={this.handleExample.bind(this)}
                          handleTranslate={this.translate.bind(this)}
                          handleDisplayBoth={this.displayBoth.bind(this)}
                          handleStop={this.stop.bind(this)}
                          handleGroupsTag={this.handleGroupsTag.bind(this)}
                          displayBoth={displayBoth}
                          example={showExample}
                          translate={translate}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {list.length ? (
                      <div className={classes.counterContainer}>
                        {`Comments moderated: ${counter}`}
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {streams &&
                    streams[streamIndex] &&
                    streams[streamIndex]["notification_count"] !== 0 ? (
                      <div className={classes.cardTagList}>
                        <TagList
                          groupsTagValues={groupsTagValues}
                          groupsTagList={groupsTagValues}
                          showExample={showExample}
                          getTagList={this.getTagList.bind(this)}
                          triggerEvents={triggerEvents}
                          tagCategories={
                            list && list[indexList]
                              ? list[indexList].tagCategories
                              : []
                          }
                          onResizeStop={(height) => {
                            const heightNum = Number(height.split("px")[0]);
                            this.setState({
                              heightList:
                                heightNum < 230
                                  ? "380px"
                                  : `${heightNum + 30}px`,
                            });
                          }}
                        />
                      </div>
                    ) : (
                      <Button
                        onClick={this.finishModeration.bind(this)}
                        variant="contained"
                        color="primary"
                        className={classes.finishModerationButton}
                      >
                        Finish moderation
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : !notAllowed ? (
            <CircularProgress className={classes.loading} />
          ) : (
            <Typography className={classes.notAllowed} variant="body1">
              Not Allowed
            </Typography>
          )}
        </main>
      </div>
    );
  }
}

ModerationPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ModerationPage));
