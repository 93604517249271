import { createMuiTheme } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import blueGrey from "@material-ui/core/colors/blueGrey";
import grey from "@material-ui/core/colors/grey";
import orange from "@material-ui/core/colors/orange";
import yellow from "@material-ui/core/colors/yellow";

export const themeBrandBastionDark = createMuiTheme({
  type: "dark",
  typography: {
    useNextVariants: true,
  },
  palette: {
    typography: {
      logo: {
        fontFamily: ["Century Gothic", "sans-serif"].join(","),
      },
    },
    background: {
      paper: "#546c78",
      comment: grey[800],
      default: "#263238",
    },
    primary: {
      ...grey,
      main: "#252930",
      dark: "#000000",
      lightDark: "#0a0a0a",
    },
    secondary: blueGrey,
    ternary: indigo,
    orange: orange,
    error: {
      ...red,
      500: "#e06666",
    },
    warning: yellow,
    divider: "rgba(0, 0, 0, 0.12)",
    success: {
      ...green,
      500: "#93c47d",
    },
    contrastThreshold: 3,
    backgroundComment: grey[800],
    backgroundLight: grey[600],
    backgroundDark: "#8fa0bd",
    white: "#e0e0e0",
    black: "#fefefe",
    container: {
      light: grey[800],
      dark: grey[300],
    },
    backgroundStreamList: "#000000",
    colorStreamList: "#ffffff",
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    grey: grey,
    gradient: {
      blue: {
        main: "linear-gradient(90deg, #000000 20%, #252930 100%)",
      },
      grey: {
        light:
          "linear-gradient(90deg, rgba(224,224,224,1) 20%, rgba(250,250,250,1) 100%)",
      },
    },
    shadow: {
      shadowDefault:
        "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
      shadowAround:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      shadowNegative: "0px 0px 15px 4px rgba(213,0,0,1)",
      shadowPositive: "0px 0px 15px 4px rgba(76,175,79,1)",
      shadowPositiveSmall: "0px 0px 4px 2px rgba(76,175,79,1)",
      shadowPrimary: "0px 0px 4px 2px rgba(33,150,243,1)",
      insetDefault: "inset 0px 0px 5px -1px rgba(0,0,0,0.75)",
      bottom: "-20px -23px 7px 20px rgba(0,0,0,0.75)",
      right: "-20px -20px 7px 20px rgba(0,0,0,0.75)",
    },
  },
  drawerWidth: 190,
  overrides: {
    MuiFormLabel: {
      root: {
        color: "#ffffff",
      },
    },
    MuiInputBase: {
      input: {
        color: "#ffffff",
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: "#ffffff",
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: grey[500],
        color: "#ffffff",
      },
      root: {
        "box-shadow": "none",
      },
    },
    MuiList: {
      root: {
        color: "#ffffff",
      },
    },
    MuiPaper: {
      root: {
        color: "#ffffff",
      },
    },
    MuiSelect: {
      root: {
        color: "#ffffff",
      },
      icon: {
        color: "#ffffff",
      },
    },
    MuiTypography: {
      root: {
        color: "#fefefe",
      },
    },
    MuiTableCell: {
      body: {
        color: "#ffffff",
      },
    },
  },
});
