import "date-fns";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Tooltip from "../tooltip/TooltipComponent";

const useStyles = makeStyles((theme) => ({
  grid: {
    width: "100%",
  },
  margin: {
    marginRight: 15,
  },
  infoIcon: {
    fontSize: 16,
    marginTop: 32,
    marginLeft: theme.spacing(1),
  },
}));

const MaterialUIPickers = ({
  label,
  name,
  value,
  formatDate,
  settedValue,
  onChangeRaw,
  defaultTimeValue = null,
  setSelectedDate,
  ampm = true,
  minDate = new Date("1900-01-01"),
  maxDate = new Date("2100-01-01"),
  required = false,
  error = "",
  hideTime = false,
}) => {
  const classes = useStyles();
  const [date, setDate] = useState(null);
  const [isDateSet, setDateSet] = useState(false);

  const convertTimestampToDate = (timestamp) => {
    let dateFormatted = moment
      .utc(timestamp * 1000)
      .format("MM DD YYYY HH:mm:ss");
    return moment(dateFormatted).local();
  };

  const handleChange = (newDate) => {
    if (!isDateSet && defaultTimeValue) {
      const timeUnits = defaultTimeValue.split(":");
      newDate.setHours(timeUnits[0], timeUnits[1], 0, 0);
    }
    let dateFormatted = moment(newDate).format("MM DD YYYY HH:mm:ss");
    const timestamp = moment(`${dateFormatted} GMT+0000`).valueOf();
    setDate(newDate);
    setSelectedDate(name, Math.round(timestamp / 1000));
    setDateSet(true);
  };

  useEffect(() => {
    if (value) {
      setDateSet(true);
    }
    return setDate(
      settedValue || !value ? null : convertTimestampToDate(value)
    );
  }, [settedValue, value]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        value={date}
        format={formatDate ? formatDate : 'yyyy MMM dd'}
        onChange={e => handleChange(e)}
        margin="dense"
        minDate={minDate}
        maxDate={maxDate}
        label={`${label}`}
        helperText={error}
        required={required}
        onChangeRaw={onChangeRaw}
        className={classes.margin}
        error={error ? true : false}
      />
      {!hideTime ? (
        <>
          <KeyboardTimePicker
            ampm={ampm}
            value={date}
            margin="dense"
            label={`${label}`}
            required={required}
            onChange={(e) => handleChange(e)}
            keyboardIcon={<Icon>watch_later</Icon>}
          />
          <Tooltip title={<p>UTC time</p>}>
            <Icon className={classes.infoIcon}>info</Icon>
          </Tooltip>
        </>
      ) : null}
    </MuiPickersUtilsProvider>
  );
};

MaterialUIPickers.propTypes = {
  label: PropTypes.string.isRequired,
  formatDate: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  settedValue: PropTypes.bool.isRequired,
  setSelectedDate: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  required: PropTypes.bool,
  error: PropTypes.string,
  hideTime: PropTypes.bool,
};

export default MaterialUIPickers;
