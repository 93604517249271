import { setDateFormat } from "./date";

export const setUCM = (ucm) => {
  let reset_info = ucm.reset_by ? "by " + ucm.reset_by : "";
  reset_info += ucm.reset_at ? " " + setDateFormat(ucm.reset_at) : "";

  const item = {
    id: ucm.id ? ucm.id : "",
    username: ucm.username ? ucm.username : "",
    email: ucm.id ? ucm.email : "",
    clientShortName: ucm.client_short_name ? ucm.client_short_name : "",
    availableClients: ucm.available_clients ? ucm.available_clients : [],
    firstName: ucm.first_name ? ucm.first_name : "",
    lastName: ucm.last_name ? ucm.last_name : "",
    avatar: ucm.avatar ? ucm.avatar : "",
    roles: ucm.roles ? ucm.roles : [],
    is_disabled: ucm.is_disabled,
    status: ucm.is_disabled ? false : true,
    created_at: ucm.created_at ? setDateFormat(ucm.created_at) : "",
    initial_login_time: ucm.initial_login_time
      ? setDateFormat(ucm.initial_login_time)
      : "",
    reset_info,
    reset_login_time: ucm.reset_login_time
      ? setDateFormat(ucm.reset_login_time)
      : "",
  };

  return item;
};
