import { setCommentKPI } from '../setters/commentKPI';

export const extractCommentKPIsList = data => {
  const commentKPIs = [];

  data.map((commentKPI, key) => {
    commentKPIs.push(setCommentKPI(commentKPI, key));
    return true;
  });

  return commentKPIs;
};
