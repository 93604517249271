import { adbastionReportsConstants } from "../../constants/configuration/adbastionReportsConstants";
import { extractAdbastionReportsList } from "../../extractors/adbastionReportsExtractor";

const initialState = {
  fetching: false,
  fetched: false,
  adbastionReportsList: [],
  error: null,
};

const adbastionReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_ADBASTION_REPORTS_LIST
    case `${adbastionReportsConstants.FETCH_ADBASTION_REPORTS_LIST}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${adbastionReportsConstants.FETCH_ADBASTION_REPORTS_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${adbastionReportsConstants.FETCH_ADBASTION_REPORTS_LIST}_FULFILLED`:
      const adbastionReportsList = extractAdbastionReportsList(
        action.payload.data.items
      );
      return {
        ...state,
        fetching: false,
        fetched: true,
        adbastionReportsList,
      };
    // DELETE_ADBASTION_REPORT
    case `${adbastionReportsConstants.DELETE_ADBASTION_REPORT}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${adbastionReportsConstants.DELETE_ADBASTION_REPORT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${adbastionReportsConstants.DELETE_ADBASTION_REPORT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        adbastionReportsList: state.adbastionReportsList.filter(
          (r) => r.id !== action.payload.config.id
        ),
      };
    // GET_ADBASTION_REPORT_DOWNLOAD_URL
    case `${adbastionReportsConstants.GET_ADBASTION_REPORT_DOWNLOAD_URL}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${adbastionReportsConstants.GET_ADBASTION_REPORT_DOWNLOAD_URL}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${adbastionReportsConstants.GET_ADBASTION_REPORT_DOWNLOAD_URL}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    // DOWNLOAD_ADBASTION_REPORT
    case `${adbastionReportsConstants.DOWNLOAD_ADBASTION_REPORT}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${adbastionReportsConstants.DOWNLOAD_ADBASTION_REPORT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${adbastionReportsConstants.DOWNLOAD_ADBASTION_REPORT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    // GET_ADBASTION_REPORT_UPLOAD_URL
    case `${adbastionReportsConstants.GET_ADBASTION_REPORT_UPLOAD_URL}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${adbastionReportsConstants.GET_ADBASTION_REPORT_UPLOAD_URL}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${adbastionReportsConstants.GET_ADBASTION_REPORT_UPLOAD_URL}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    // UPLOAD_ADBASTION_REPORT
    case `${adbastionReportsConstants.UPLOAD_ADBASTION_REPORT}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${adbastionReportsConstants.UPLOAD_ADBASTION_REPORT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${adbastionReportsConstants.UPLOAD_ADBASTION_REPORT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        adbastionReportsList: [
          action.payload.data,
          ...state.adbastionReportsList,
        ],
      };
    // UPLOAD_ADBASTION_REPORT_AWS
    case `${adbastionReportsConstants.UPLOAD_ADBASTION_REPORT_AWS}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${adbastionReportsConstants.UPLOAD_ADBASTION_REPORT_AWS}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${adbastionReportsConstants.UPLOAD_ADBASTION_REPORT_AWS}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    default:
      return state;
  }
};

export default adbastionReportsReducer;
