import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  badge: {
    height: 12,
    backgroundColor: fade(theme.palette.backgroundStreamList, 0.1),
    fontSize: 11,
  },
  new: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },
  failed: {
    backgroundColor: theme.palette.error[500],
    color: theme.palette.white,
  },
  pending: {
    backgroundColor: theme.palette.warning[500],
    color:
      theme.type === "dark" ? theme.palette.primary.main : theme.palette.black,
  },
  processed: {
    backgroundColor: theme.palette.success[500],
    color: theme.palette.white,
  },
  immediate: {
    backgroundColor: theme.palette.error[500],
    color: theme.palette.red,
  },
  volume_based: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },
}));

const BadgeListComponent = ({ badgeContent, variant, invisible, max }) => {
  const classes = useStyles();

  return badgeContent.slice(0, max).map((item, key) => {
    return (
      <Badge
        key={key}
        max={999999}
        badgeContent={item.count}
        invisible={invisible ? invisible : false}
        className={classes.margin}
        variant={variant ? variant : "standard"}
        classes={{
          badge: classNames(classes[item.type], classes.badge),
        }}
      >
        <div />
      </Badge>
    );
  });
};

BadgeListComponent.propTypes = {
  badgeContent: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  variant: PropTypes.string,
  invisible: PropTypes.bool,
  favourite: PropTypes.bool,
  selected: PropTypes.bool,
  deleteIcon: PropTypes.bool,
  deleteAction: PropTypes.func,
  iconName: PropTypes.string.isRequired,
};

export default BadgeListComponent;
