import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  tag: {
    margin: 5,
    marginTop: 0,
    height: 20,
    fontSize: 10
  },
  title: {},
  username: {
    marginLeft: theme.spacing(1),
    fontSize: 13,
    display: 'flex',
    alignItems: 'center'
  },
  stream: {
    marginLeft: theme.spacing(1),
    fontSize: 13,
    display: 'flex',
    alignItems: 'center'
  },
  date: {
    marginLeft: theme.spacing(1),
    fontSize: 13,
    marginBottom: 5,
    display: 'flex',
    alignItems: 'center'
  },
  alerts: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1)
  },
  iconAlert: {
    width: 30,
    marginLeft: 9,
    marginRight: 0,
    fontSize: 20
  }
}));

const stepTypes = {
  status_initial: 0,
  moderation: 1,
  reply: 2,
  action_success: 3,
  action_failed: 3,
  status_change: 4,
  copyright: 5
};

const Steppers = ({ children, steps }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [stepsList, setStepsList] = useState(steps);

  useEffect(() => {
    if (steps.length) {
      setStepsList(steps);
      setLoading(false);
    }
  }, [steps]);

  return (
    <div className={classes.root}>
      {loading ? <CircularProgress /> : ''}

      {!loading && stepsList.length === 0 ? (
        <Typography>No history available</Typography>
      ) : (
        ''
      )}

      {!loading && stepsList.length ? (
        <Stepper orientation="vertical">
          {stepsList.map((step, index) => {
            if (stepTypes[step.type] === undefined) return true;
            return (
              <React.Fragment key={index}>
                {React.cloneElement(
                  children.props.children[stepTypes[step.type]],
                  {
                    type: step.type,
                    step: step && Object.values(step.object) ? step.object : {}
                  }
                )}
              </React.Fragment>
            );
          })}
        </Stepper>
      ) : (
        ''
      )}
    </div>
  );
};

Steppers.propTypes = {
  children: PropTypes.node.isRequired
};

export default Steppers;
