import { attemptsConstants } from '../constants/attemptsConstants';

const initialState = {
  fetching: false,
  fetched: false,
  list: [],
  max_attempts: null,
  used_attempts: null,
  error: null
};

const Attempts = (state = initialState, action) => {
  switch (action.type) {
    // FETCH ATTEMPTS LIST
    case `${attemptsConstants.FETCH_ATTEMPTS_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${attemptsConstants.FETCH_ATTEMPTS_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${attemptsConstants.FETCH_ATTEMPTS_LIST}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        max_attempts: action.payload.data.max_attempts,
        used_attempts: action.payload.data.used_attempts,
        list: action.payload.data.attempts
      };

    // CONVERT ATTEMPTS TO CLIENT
    case `${attemptsConstants.CONVERT_ATTEMPT_TO_CLIENT}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${attemptsConstants.CONVERT_ATTEMPT_TO_CLIENT}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${attemptsConstants.CONVERT_ATTEMPT_TO_CLIENT}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: Object.assign([], {
          ...state.list,
          [action.payload.config.index]: {
            ...state.list[action.payload.config.index],
            converted_client: true
          }
        })
      };

    // RESET_ATTEMPTS_LIST
    case `${attemptsConstants.RESET_ATTEMPTS_LIST}`:
      return {
        ...state,
        fetchComment: false,
        list: [],
        max_attempts: null,
        used_attempts: null
      };

    default:
      return state;
  }
};

export default Attempts;
