export const setClientConfig = (client, key = null) => {
  const item = {
    id: client && client.id ? client.id : "",
    key: key,
    platforms: client && client.platforms ? client.platforms : "",
    platformNames: client && client.platformNames ? client.platformNames : "",
    clientShortName: client && client.shortName ? client.shortName : "",
    abbreviation: client && client.abbreviation,
    name: client && client.name ? client.name : "",
    logo: client && client.logo ? client.logo : "",
    salesAnalysisClient:
      client && client.sale_analysis_client
        ? client.sale_analysis_client
        : false,
    assets: client && client.assets ? client.assets : [],
    tags: client && client.tags ? client.tags : [],
    adbastion_tags:
      client && client.adbastion_tags ? client.adbastion_tags : [],
    care_tags: client && client.care_tags ? client.care_tags : [],
    care_review_tags:
      client && client.care_review_tags ? client.care_review_tags : [],
    adbastion_moderation_enabled:
      client && client.adbastion_moderation_enabled
        ? client.adbastion_moderation_enabled
        : false,
    care_agent_stats_enabled:
      client && client.care_agent_stats_enabled
        ? client.care_agent_stats_enabled
        : false,
    adbastion_publishing_enabled:
      client && client.adbastion_publishing_enabled
        ? client.adbastion_publishing_enabled
        : false,
    care_enabled: client && client.care_enabled ? client.care_enabled : false,
    adbastion_moderation_alerts_enabled:
      client && client.adbastion_moderation_alerts_enabled
        ? client.adbastion_moderation_alerts_enabled
        : false,
    adbastion_auto_crawling:
      client && client.adbastion_auto_crawling
        ? client.adbastion_auto_crawling
        : false,
    adbastion_moderation_init_time:
      client && client.adbastion_moderation_init_time,
    adbastion_insights_init_time: client && client.adbastion_insights_init_time,
    care_init_time: client && client.care_init_time,
    trial_subscription_init: client && client.trial_subscription_init,
    trial_subscription_init_time: client && client.trial_subscription_init_time,
    managed: client && client.managed ? client.managed : false,
    adbastion_moderation_guideline_update_time:
      client && client.adbastion_moderation_guideline_update_time,
    industry: client && client.industry ? client.industry : "",
    adbastion_plan:
      client && client.adbastion_plan ? client.adbastion_plan : "",
    clara_config: client && client.clara_config ? client.clara_config : [],
    copyrightClient:
      client && client.copyright_client ? client.copyright_client : false,
    classifiers:
      client && client.clara_config
        ? client.clara_config.map((classifier) => {
            return classifier.classifier_name;
          })
        : [],
    emailsAlert:
      client && client.email_config && client.email_config.alert_to
        ? client.email_config.alert_to
        : [],
    initTime:
      client && client.crawlers && client.crawlers.init_time
        ? client.crawlers.init_time
        : "",
    sla:
      client && client.crawlers && client.crawlers.sla
        ? client.crawlers.sla
        : null,
    alertSla:
      client && client.crawlers && client.crawlers.alert_sla
        ? client.crawlers.alert_sla
        : null,
    queryOnlyActive:
      client &&
      client.crawlers &&
      typeof client.crawlers.query_only_active !== "undefined"
        ? client.crawlers.query_only_active
        : false,
    allowPublishedPosts:
      client &&
      client.crawlers &&
      typeof client.crawlers.allow_published_posts !== "undefined"
        ? client.crawlers.allow_published_posts
        : true,
    pullInsights:
      client &&
      client.crawlers &&
      typeof client.crawlers.pull_insights !== "undefined"
        ? client.crawlers.pull_insights
        : false,
    privateRepliesUserTimespan:
      client && client.private_replies_user_timespan
        ? client.private_replies_user_timespan
        : "",
  };

  return item;
};

export const setClientExplorer = (client, key = null) => {
  const item = {
    id: client && client.id ? client.id : "",
    key: key,
    sale_analysis_client:
      client && client.sale_analysis_client
        ? client.sale_analysis_client
        : false,
    platforms: client && client.platforms ? client.platforms : "",
    platformNames: client && client.platformNames ? client.platformNames : "",
    clientShortName: client && client.shortName ? client.shortName : "",
    name: client && client.name ? client.name : "",
    assets: client && client.assets ? client.assets : [],
    groups: client && client.groups ? client.groups : [],
    tags: client && client.tags ? client.tags : [],
  };

  return item;
};

export const setClientStats = (client, key = null) => {
  const item = {
    id: client && client.id ? client.id : key.toString(),
    key: key,
    clientShortName:
      client && client.client_short_name ? client.client_short_name : "",
    name: client && client.client_name ? client.client_name : "",
    label: client && client.client_name ? client.client_name : "",
    favourite: false,
    notification_count: client && client.items ? client.items : [],
  };

  return item;
};
