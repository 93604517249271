export const copyrightConstants = {
  FETCH_CLIENTS_COPYRIGHT: 'FETCH_CLIENTS_COPYRIGHT',
  GET_VIDEO_LIST_BY_CLIENT_COPYRIGHT: 'GET_VIDEO_LIST_BY_CLIENT_COPYRIGHT',
  RESET_VIDEO_LIST_COPYRIGHT: 'RESET_VIDEO_LIST_COPYRIGHT',
  FETCH_HISTORY_FROM_VIDEO_COPYRIGHT: 'FETCH_HISTORY_FROM_VIDEO_COPYRIGHT',
  UPDATE_CLIENTS_VIDEOS_TRANSLATION_COPYRIGHT:
    'UPDATE_CLIENTS_VIDEOS_TRANSLATION_COPYRIGHT',
  PROCESS_COPYRIGHT_TO_VIDEO: 'PROCESS_COPYRIGHT_TO_VIDEO',
  APPROVE_COPYRIGHT_TO_VIDEO: 'APPROVE_COPYRIGHT_TO_VIDEO',
  REMOVE_COPYRIGHT_TO_VIDEO: 'REMOVE_COPYRIGHT_TO_VIDEO',
  FETCH_COPYRIGHT_HISTORY_FROM_VIDEO_COPYRIGHT:
    'FETCH_COPYRIGHT_HISTORY_FROM_VIDEO_COPYRIGHT',
  FETCH_CLIENTS_POST_ADS_COPYRIGHT: 'FETCH_CLIENTS_POST_ADS_COPYRIGHT',
  UPDATES_COPYRIGHT_STATS: 'UPDATES_COPYRIGHT_STATS',
  FETCH_CLIENTS_REPORTS_COPYRIGHT: 'FETCH_CLIENTS_REPORTS_COPYRIGHT',
  GENERATE_COPYRIGHT_REPORT_VIDEO: 'GENERATE_COPYRIGHT_REPORT_VIDEO',
  MOVE_VIDEO_FROM_REPORT: 'MOVE_VIDEO_FROM_REPORT',
  REMOVE_VIDEO_FROM_REPORT: 'REMOVE_VIDEO_FROM_REPORT',
  DOWNLOAD_REPORT_COPYRIGHT: 'DOWNLOAD_REPORT_COPYRIGHT',
  LOCK_REPORT_COPYRIGHT: 'LOCK_REPORT_COPYRIGHT'
};
