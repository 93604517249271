export const replyConstants = {
  FETCH_CLIENTS_REPLY: 'FETCH_CLIENTS_REPLY',
  GET_COMMENT_LIST_BY_CLIENT_REPLY: 'GET_COMMENT_LIST_BY_CLIENT_REPLY',
  RESET_COMMENT_LIST_REPLY: 'RESET_COMMENT_LIST_REPLY',
  FETCH_HISTORY_FROM_COMMENT_REPLY: 'FETCH_HISTORY_FROM_COMMENT_REPLY',
  UPDATE_CLIENTS_COMMENTS_TRANSLATION_REPLY:
    'UPDATE_CLIENTS_COMMENTS_TRANSLATION_REPLY',
  SELECT_REPLY_TO_COMMENT: 'SELECT_REPLY_TO_COMMENT',
  APPROVE_REPLY_TO_COMMENT: 'APPROVE_REPLY_TO_COMMENT',
  CANCEL_REPLY_TO_COMMENT: 'CANCEL_REPLY_TO_COMMENT',
  DELETE_REPLY_TO_COMMENT: 'DELETE_REPLY_TO_COMMENT',
  FETCH_REPLY_HISTORY_FROM_COMMENT_REPLY:
    'FETCH_REPLY_HISTORY_FROM_COMMENT_REPLY',
  FETCH_CLIENTS_POST_ADS_REPLY: 'FETCH_CLIENTS_POST_ADS_REPLY',
  UPDATES_REPLY_STATS: 'UPDATES_REPLY_STATS',
  FETCH_CLIENT_COMNENT_REPLY_CONFIG: 'FETCH_CLIENT_COMNENT_REPLY_CONFIG'
};
