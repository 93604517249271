import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import CheckBox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Icon from "@material-ui/core/Icon";

import SelectComponent from "../commons/select/selectComponent";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  filters: {
    width: "100%",
    position: "fixed",
    backgroundColor: theme.palette.white,
    padding: theme.spacing(1),
    marginTop: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: 3,
  },
  fieldsContainer: {
    marginTop: 100,
    width: "100%",
    display: "flex",
    overflow: "auto",
  },
  exportGroupContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  formControl: {
    width: "90%",
    margin: "auto",
  },
  exportGroupContent: {
    backgroundColor: theme.palette.container.light,
    height: "fit-content",
    padding: theme.spacing(1),
  },
  hidden: {
    display: "none",
  },
  legend: {
    display: "flex",
    minWidth: 250,
    justifyContent: "space-between",
    alignItems: "center",
  },
  checkBox: {
    padding: 0,
    marginLeft: -2,
  },
  iconMore: {
    cursor: "pointer",
  },
  loading: {
    margin: "auto",
    marginTop: 70,
    color: theme.palette.orange[300],
  },
  linearLoading: {
    marginTop: 49,
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  limitField: {
    width: 70,
    marginLeft: theme.spacing(1),
  },
  limitFieldInput: {
    color: theme.palette.primary.dark,
  },
});

class ExportComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: 1000,
      exportFields: {},
      "General-display": true,
      loadingFile: false,
      aggregateBy: "",
    };

    this.onChangeAll.bind(this);
    this.onChange.bind(this);
    this.onChangeLimit.bind(this);
    this.handleChange.bind(this);
  }

  onChangeLimit = (e) => {
    this.setState(
      {
        limit: e.target.value,
      },
      () => {
        this.props.getExportData(null, null, null, {
          limit: this.state.limit,
        });
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.exportFields !== this.props.exportFields) {
      this.setState({ exportFields: this.props.exportFields });
    }

    if (prevProps.loadingFile !== this.props.loadingFile) {
      this.setState({ loadingFile: this.props.loadingFile });
    }
  }

  showExportList(group) {
    this.setState({
      [`${group}-display`]: !this.state[`${group}-display`],
    });
  }

  onChange(e, key, group) {
    this.props.getExportData(group, key, null);
  }

  onChangeAll(e, group) {
    this.setState(
      {
        [group[0]]: !this.state[group[0]],
      },
      () => {
        this.props.getExportData(group, null, this.state[group[0]]);
      }
    );
  }

  resetColumn(name, group, callback = null) {
    this.setState(
      {
        [name]: false,
      },
      () => {
        this.props.getExportData(group, null, false, {}, callback);
      }
    );
  }

  handleChange(e, id, group) {
    const { exportFields } = this.state;

    //reset general column each changes
    this.resetColumn(
      "General",
      Object.entries(exportFields).find((field) => field[0] === "General")
    );

    //check value aggregated by dropdown
    if (e.target && e.target.value) {
      this.setState(
        {
          [id]: e.target.value,
        },
        () => {
          this.resetColumn("Aggregations", group, () => {
            return this.props.getExportData(
              group,
              group[1].find((item) => item.keyValue === e.target.value)["key"],
              null
            );
          });
        }
      );
    } else {
      this.setState(
        {
          [id]: "",
        },
        () => {
          this.resetColumn("Aggregations", group);
        }
      );
    }
  }

  displayExportList(group) {
    const { exportFields } = this.state;
    return (
      group &&
      Object.entries(group[1]).map((item, key) => {
        return (
          <div key={item[0]}>
            <FormControlLabel
              control={
                <CheckBox
                  checked={
                    exportFields[group[0]][key]["checked"] !== undefined
                      ? exportFields[group[0]][key]["checked"]
                      : false
                  }
                  onChange={(e) => this.onChange(e, key, group)}
                />
              }
              label={item[1]["value"]}
            />
          </div>
        );
      })
    );
  }

  createExportGroup(group, key = 0) {
    const { classes } = this.props;
    const { aggregateBy } = this.state;
    return (
      <div
        key={key}
        className={classNames(classes.exportGroupContent, {
          [classes.hidden]:
            group[0] === "Aggregations" ||
            (group[0] === "General" && aggregateBy !== ""),
        })}
      >
        <FormLabel className={classes.legend} component="legend">
          <CheckBox
            indeterminate={
              this.state.exportFields[group[0]] &&
              this.state.exportFields[group[0]].find(
                (item) => item.checked === true
              ) &&
              this.state.exportFields[group[0]].find(
                (item) => item.checked === false
              )
                ? true
                : false
            }
            checked={
              this.state[group[0]] !== undefined ? this.state[group[0]] : false
            }
            onChange={(e) => this.onChangeAll(e, group)}
            classes={{
              root: classes.checkBox,
            }}
          />
          {group[0]}
          <Icon
            className={classes.iconMore}
            onClick={(e) => {
              return this.showExportList(group[0]);
            }}
          >
            {!this.state[`${group[0]}-display`] ? `expand_more` : `expand_less`}
          </Icon>
        </FormLabel>
        <FormGroup className={classes.formGroup}>
          {this.state[`${group[0]}-display`]
            ? this.displayExportList(group)
            : ""}
        </FormGroup>
      </div>
    );
  }

  displayExportGroups(groups) {
    const { classes } = this.props;
    return (
      <div className={classes.exportGroupContainer}>
        {groups &&
          Object.entries(groups).map((group, key) => {
            return this.createExportGroup(group, key);
          })}
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { limit, exportFields, loadingFile, aggregateBy } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.filters}>
          <FormGroup row>
            <FormControlLabel
              classes={{
                root: classes.marginTop,
              }}
              control={
                <TextField
                  type="number"
                  defaultValue={limit}
                  onChange={this.onChangeLimit}
                  classes={{ root: classes.limitField }}
                  inputProps={{
                    min: "0",
                    max: "10000",
                    step: "1000",
                    className: classes.limitFieldInput,
                  }}
                />
              }
              label={
                <Typography classes={{ root: classes.limitFieldInput }}>
                  Limit (max 10000)
                </Typography>
              }
            />
            <SelectComponent
              id={"aggregateBy"}
              label={
                <Typography classes={{ root: classes.limitFieldInput }}>
                  Aggregate by
                </Typography>
              }
              multiple={false}
              displayNone={true}
              value={aggregateBy}
              items={
                exportFields && exportFields.Aggregations
                  ? exportFields.Aggregations
                  : []
              }
              attributName={"value"}
              attributId={"keyValue"}
              onChange={(e, id) =>
                this.handleChange(
                  e,
                  id,
                  Object.entries(exportFields).find(
                    (field) => field[0] === "Aggregations"
                  )
                )
              }
            />
          </FormGroup>
        </div>
        {loadingFile ? (
          <div>
            <LinearProgress className={classes.linearLoading} color="primary" />
          </div>
        ) : (
          ""
        )}
        {exportFields && Object.values(exportFields).length ? (
          <div className={classes.fieldsContainer}>
            <FormControl className={classes.formControl}>
              {this.displayExportGroups(exportFields)}
            </FormControl>
          </div>
        ) : (
          <CircularProgress className={classes.loading} />
        )}
      </div>
    );
  }
}

ExportComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  getExportData: PropTypes.func.isRequired,
  loadingFile: PropTypes.bool.isRequired,
  exportFields: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ExportComponent);
