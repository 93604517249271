import { actionsConstants } from "../../constants/configuration/actionsConstants";
import { extractActionList } from "../../extractors/actionsExtractor";

const initialState = {
  fetching: false,
  fetched: false,
  typeList: [],
  actionList: [],
  error: null,
};

const Actions = (state = initialState, action) => {
  switch (action.type) {
    // FETCH ACTION LIST
    case `${actionsConstants.FETCH_ACTION_LIST}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${actionsConstants.FETCH_ACTION_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${actionsConstants.FETCH_ACTION_LIST}_FULFILLED`:
      let actionList = extractActionList(action.payload.data.actions || []);

      return {
        ...state,
        fetching: false,
        fetched: true,
        actionList: actionList,
        replierAssetsList: action.payload.data.replier_assets,
      };
    // FETCH ACTION TYPE LIST
    case `${actionsConstants.FETCH_ACTION_TYPE_LIST}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${actionsConstants.FETCH_ACTION_TYPE_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${actionsConstants.FETCH_ACTION_TYPE_LIST}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        typeList: action.payload.data,
      };
    // ADD ACTION
    case `${actionsConstants.ADD_ACTION}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${actionsConstants.ADD_ACTION}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${actionsConstants.ADD_ACTION}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        actionList: [
          ...state.actionList,
          {
            id: action.payload.config.actionInfo.id,
            type: action.payload.config.actionInfo.type,
            filters: action.payload.config.actionInfo.filters,
            tags: action.payload.config.actionInfo.tags,
            volume_config: {
              time_interval: action.payload.config.actionInfo.time_interval,
              timeout: action.payload.config.actionInfo.timeout,
              tags: action.payload.config.actionInfo.tags,
            },
          },
        ],
      };
    // EDIT ACTION
    case `${actionsConstants.EDIT_ACTION}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${actionsConstants.EDIT_ACTION}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${actionsConstants.EDIT_ACTION}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        actionList: Object.assign([], {
          ...state.actionList,
          [action.payload.config.index]: {
            id: action.payload.config.actionInfo.id,
            type: action.payload.config.actionInfo.type,
            filters: action.payload.config.actionInfo.filters,
            tags: action.payload.config.actionInfo.tags,
            volume_config: {
              time_interval: action.payload.config.actionInfo.time_interval,
              timeout: action.payload.config.actionInfo.timeout,
              tags: action.payload.config.actionInfo.tags,
            },
          },
        }),
      };
    // DELETE ACTION
    case `${actionsConstants.DELETE_ACTION}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${actionsConstants.DELETE_ACTION}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${actionsConstants.DELETE_ACTION}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        actionList: state.actionList.filter(
          (i) => i.id !== action.payload.config.index
        ),
      };
    default:
      return state;
  }
};

export default Actions;
