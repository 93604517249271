import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList } from 'react-window';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import AttemptsItem from './attemptsListItem';

const useStylesList = makeStyles(theme => ({
  root: {
    borderRadius: 5,
    flexGrow: 1,
    height: 'calc(100vh - 220px)',
    overflow: 'hidden auto'
  },
  loading: {
    margin: 'auto',
    color: theme.palette.orange[300],
    marginTop: 'calc(50vh - 180px)'
  }
}));

const useClientRect = () => {
  const [rect, setRect] = useState(null);
  const ref = useCallback(node => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect, ref];
};

const AttemptsList = ({ list, isLoading, action, clientConverted }) => {
  const classes = useStylesList();
  const [rect, ref] = useClientRect();

  return (
    <div className={classes.root} ref={ref}>
      {isLoading ? (
        <CircularProgress className={classes.loading} />
      ) : rect !== null && list.length > 0 ? (
        <FixedSizeList
          itemData={{
            list: list,
            action: action,
            clientConverted: clientConverted
          }}
          height={rect.height}
          itemSize={366}
          itemCount={list.length}
        >
          {AttemptsItem}
        </FixedSizeList>
      ) : (
        <Typography>{'No Items found'}</Typography>
      )}
    </div>
  );
};

AttemptsList.propTypes = {
  list: PropTypes.array,
  action: PropTypes.func,
  clientConverted: PropTypes.bool
};

export default AttemptsList;
