import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material UI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

import TableComponent from '../../commons/table/tableComponent';
import DialogContent from '../../commons/dialog/contentDialogComponent';
import ModerationGuidelinesForm from './moderationGuidelinesForm';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 7
  },
  detailContainer: {
    display: 'flex'
  },
  detailItem: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.container.light
  },
  detailLegend: {
    padding: theme.spacing(1),
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center'
  },
  detailContent: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    overflow: 'auto'
  },
  detailCell: {
    marginBottom: theme.spacing(1)
  },
  buttonAdd: {
    marginLeft: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actionsContainer: {
    display: 'flex'
  },
  customAction: {
    borderRadius: '50%',
    padding: '0',
    cursor: 'pointer',
    width: 48,
    height: 48,
    minWidth: 'unset',
    '& .MuiButton-label': {
      width: 'auto'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  exceptionsTooltip: {
    fontSize: 15,
    padding: 8
  },
  exceptionsTooltipTitle: {
    fontWeight: 'bold',
    fontSize: 17
  },
  exceptionsIcon: {
    color: '#FFD700',
    fontSize: 14,
    marginRight: 8,
    cursor: 'pointer'
  }
}));

const ModerationGuidelinesConfiguration = props => {
  const initialFormData = {
    index: 0,
    configs: []
  };

  const [isFormOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [selectedTag, setSelectedTag] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [columns, setColumns] = useState(props.moderationGuidelinesColumns);
  const [list, setList] = useState(props.moderationGuidelinesList);
  const [isEditable, setIsEditable] = useState(props.isEditable);

  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (props.moderationGuidelinesColumns !== columns) {
      setColumns(props.moderationGuidelinesColumns);
    }
  }, [props.moderationGuidelinesColumns]);

  useEffect(() => {
    if (props.moderationGuidelinesList !== list) {
      setList(props.moderationGuidelinesList);
    }
  }, [props.moderationGuidelinesList]);

  useEffect(() => {
    if (props.isEditable !== isEditable) {
      setIsEditable(props.isEditable);
    }
  }, [props.isEditable]);

  const onEditModerationGuidelines = (e, rowData) => {
    let guidelines = Object.entries(rowData).filter(
      row => row[0] !== 'tag' && row[0] !== 'tableData'
    );

    guidelines = guidelines.map(guideline => {
      let hide_action_exceptions = guideline[1].exceptions.find(
        exception => exception.name === 'hide_action'
      );
      let alert_action_exceptions = guideline[1].exceptions.find(
        exception => exception.name === 'alert_action'
      );

      let config = {
        id: guideline[0],
        name: guideline[1].guideline_name,
        hide_action: {
          enabled: guideline[1].value.includes('Hide'),
          exceptions: hide_action_exceptions ? hide_action_exceptions.value : []
        },
        alert_action: {
          enabled: guideline[1].value.includes('Alert'),
          exceptions: alert_action_exceptions
            ? alert_action_exceptions.value
            : [],
          emails: guideline[1].alert ? guideline[1].alert.emails : []
        },
        competitor_description: guideline[1].competitor_description
      };

      if (guideline[1].volume) {
        config = {
          ...config,
          volume_action: {
            enabled: guideline[1].volume.enabled,
            threshold: guideline[1].volume.threshold,
            time_interval: guideline[1].volume.time_interval,
            timeout: guideline[1].volume.timeout,
            emails: guideline[1].volume.emails
          }
        };
      }

      return config;
    });

    setFormErrors({});
    setSelectedTag(rowData.tag.value);
    setFormData({
      tag: rowData.tag,
      guidelines: guidelines,
      index: rowData.tableData.id
    });
    setFormOpen(true);
  };

  const onCloseDialogForm = () => {
    setFormOpen(false);
  };

  const onChangeFormData = (parent, key, sub, item, e, type = 'string') => {
    setFormData({
      ...formData,
      [parent]: Object.assign([], {
        ...formData[parent],
        [key]: {
          ...formData[parent][key],
          [sub]:
            item === null
              ? e.target.value
              : {
                  ...formData[parent][key][sub],
                  [item]:
                    e.target.checked !== undefined && type !== 'number'
                      ? e.target.checked
                      : type === 'number'
                      ? parseInt(e.target.value)
                      : e.target.value
                }
        }
      })
    });
  };

  const checkForErrors = () => {
    return new Promise((resolve, reject) => {
      const formErrors = {};
      resolve(formErrors);
    });
  };

  const updateModerationGuidelines = () => {
    checkForErrors().then(formErrors => {
      if (Object.keys(formErrors).length === 0) {
        props.updateModerationGuidelines(formData.index, formData);
        onCloseDialogForm();
      } else {
        setFormErrors(formErrors);
      }
    });
  };

  // Table schema
  const c = columns.map(mg => {
    return {
      ...mg,
      cellStyle: {
        padding: theme.spacing(1),
        paddingLeft: 14
      },
      customFilterAndSearch: (term, rowData) => {
        const str = rowData['tag'].id;
        return str.toLowerCase().includes(term.toLowerCase());
      },
      render: rowData => {
        return (
          <div>
            {rowData &&
            rowData[mg.field] &&
            rowData[mg.field].exceptions &&
            rowData[mg.field].exceptions.filter(
              exception =>
                exception && exception.value && exception.value.length
            ) &&
            rowData[mg.field].exceptions.filter(
              exception =>
                exception && exception.value && exception.value.length
            ).length ? (
              <Tooltip
                title={
                  <div className={classes.exceptionsTooltip}>
                    <div className={classes.exceptionsTooltipTitle}>
                      Exceptions
                    </div>
                    {rowData[mg.field].exceptions
                      .filter(ex => ex.name !== 'volume_action')
                      .map((exception, key) => {
                        return (
                          <div key={key}>
                            <p>
                              {exception.name}:{' '}
                              {exception.value
                                ? exception.value.toString()
                                : ''}
                            </p>
                          </div>
                        );
                      })}
                    {rowData[mg.field] &&
                    rowData[mg.field].volume &&
                    rowData[mg.field].volume.enabled ? (
                      <React.Fragment>
                        <div className={classes.exceptionsTooltipTitle}>
                          Volume Base Alert
                        </div>
                        <p>
                          {'Threshold'}: {rowData[mg.field].volume.threshold}
                        </p>
                        <p>
                          {'Time Interval'}:{' '}
                          {rowData[mg.field].volume.time_interval}
                        </p>
                        <p>
                          {'Timeout'}: {rowData[mg.field].volume.timeout}
                        </p>
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                  </div>
                }
              >
                <Icon
                  className={classNames(classes.exceptionsIcon, {
                    'fas fa-exclamation-triangle': true
                  })}
                />
              </Tooltip>
            ) : null}
            <span>
              {rowData && rowData[mg.field] && rowData[mg.field].value
                ? rowData[mg.field].value
                : ''}
            </span>
          </div>
        );
      }
    };
  });

  const tableActions = isEditable
    ? [
        {
          icon: 'edit',
          tooltip: 'Edit Mideration Guidelines',
          onClick: (event, rowData) =>
            onEditModerationGuidelines(event, rowData)
        }
      ]
    : [];

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h5">Adbastion Moderation Guidelines</Typography>
      </div>
      <TableComponent columns={c} data={list} actions={tableActions} title="" />
      <DialogContent
        title={`Edit Adbastion Moderation Guidelines: ${selectedTag}`}
        open={isFormOpen}
        fullWidth={true}
        close={onCloseDialogForm}
      >
        <div>
          <ModerationGuidelinesForm
            formData={formData}
            tagList={props.tagList}
            clientTags={props.clientTags}
            errors={formErrors}
            onChangeFormData={onChangeFormData}
          />
          <div className={classes.buttonContainer}>
            <Button
              size="small"
              color="primary"
              disabled={
                formData &&
                formData.guidelines &&
                (formData.guidelines.find(
                  g =>
                    g.volume_action &&
                    g.volume_action.emails &&
                    g.volume_action.emails.length === 0 &&
                    g.volume_action.enabled
                ) ||
                  formData.guidelines.find(
                    g =>
                      g.alert_action &&
                      g.alert_action.emails &&
                      g.alert_action.emails.length === 0 &&
                      g.alert_action.enabled
                  ))
              }
              onClick={e => {
                return updateModerationGuidelines();
              }}
            >
              Edit Moderation Guidelines
            </Button>
            <Button
              onClick={e => {
                return onCloseDialogForm(e);
              }}
              size="small"
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </div>
  );
};

ModerationGuidelinesConfiguration.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  moderationGuidelinesList: PropTypes.array.isRequired,
  moderationGuidelinesColumns: PropTypes.array.isRequired,
  updateModerationGuidelines: PropTypes.func.isRequired,
  clientTags: PropTypes.array.isRequired,
  tagList: PropTypes.array.isRequired
};

export default ModerationGuidelinesConfiguration;
