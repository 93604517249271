import axios from 'axios';

import { commentKPIsConstants } from '../../constants/taskKPIs/commentKPIsConstants';
import { setHeaders } from '../../setters/auth';

export const fetchCommentKPIsList = (assetId, fromDate, toDate) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/assets/${assetId}/kpis/post-tasks`;

  url = url + '?from=' + fromDate + '&to=' + toDate;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: commentKPIsConstants.FETCH_COMMENT_KPIS_LIST,
    payload: promise
  });
};
