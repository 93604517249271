import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import CheckBox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import InputComponent from "../../commons/input/inputComponent";
import SelectComponent from "../../commons/select/selectComponent";

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  formControl: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: 25,
    width: "100%",
  },
  formGroup: {
    // width: '50%'
  },
});

class TagsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: props.defaultValue
        ? props.defaultValue
        : {
            id: "",
            label: "",
            description: "-",
            group: "-",
            example: "-",
            excluded_example: "-",
            volume_based_alert: {
              enabled: false,
              threshold: null,
              time_interval: null,
              timeout: null,
            },
            pim_tone: "",
            pim_weight: null,
          },
      error: props.error
        ? props.error
        : {
            id: "",
            label: "",
            description: "",
            group: "",
          },
    };
  }

  onChange(e, value) {
    this.setState(
      {
        data: {
          ...this.state.data,
          [value]: e.target.value,
        },
      },
      () => {
        this.props.onChangeTagForm(this.state.data);
      }
    );
  }

  onVolumeBasedAlertChange(event, name) {
    const value =
      typeof event.target.value === "string"
        ? Number(event.target.value)
        : event.target.value;

    this.setState(
      {
        data: {
          ...this.state.data,
          volume_based_alert: {
            ...this.state.data.volume_based_alert,
            [name]: value,
          },
        },
      },
      () => {
        this.props.onChangeTagForm(this.state.data);
      }
    );
  }

  displayTextField(
    name,
    label,
    required,
    disabled,
    multiline,
    inputType = "text"
  ) {
    const { defaultValue } = this.props;
    const { data, error } = this.state;

    return (
      <InputComponent
        multiline={multiline}
        required={required}
        disabled={disabled}
        defaultValue={
          Object.values(defaultValue).length ? defaultValue[name] : data[name]
        }
        onChange={(e) => this.onChange(e, name)}
        error={error[name] ? true : false}
        helperText={error[name]}
        label={label}
        margin="dense"
        fullWidth={true}
        type={inputType}
      />
    );
  }

  displayTextFields() {
    const { classes, mode } = this.props;
    const { data } = this.state;

    return (
      <FormGroup className={classes.formGroup}>
        {this.displayTextField(
          "id",
          "ID",
          true,
          mode === "edit" ? true : false,
          false
        )}
        {this.displayTextField("label", "Label", true, false, false)}
        {this.displayTextField("description", "Description", true, false, true)}
        {this.displayTextField("group", "Tag group", true, false, false)}
        {this.displayTextField("example", "Example", false, false, true)}
        {this.displayTextField(
          "excluded_example",
          "Excluded Example",
          false,
          false,
          true
        )}
        <SelectComponent
          id={"tag_category"}
          label={"Category"}
          value={data["tag_category"]}
          attributId={"value"}
          attributName={"label"}
          items={[
            {
              value: "sensitive",
              label: "Sensitive",
            },
            {
              value: "universally_harmful",
              label: "Universally Harmful",
            },
            {
              value: "non_harmful",
              label: "Non Harmful",
            },
            {
              value: "customer_engagement",
              label: "Customer Engagement",
            },
            {
              value: "alerts",
              label: "Alerts",
            },
          ]}
          onChange={(e) => this.onChange(e, "tag_category")}
        />
        <SelectComponent
          id="pim_tone"
          label="Pim tone"
          value={data.pim_tone}
          attributId="value"
          attributName="label"
          items={[
            {
              value: "positive",
              label: "Positive",
            },
            {
              value: "negative",
              label: "Negative",
            },
            {
              value: "neutral",
              label: "Neutral",
            },
          ]}
          onChange={(e) => this.onChange(e, "pim_tone")}
        />
        {this.displayTextField(
          "pim_weight",
          "PIM weight",
          false,
          false,
          false,
          "number"
        )}
      </FormGroup>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setState({
        error: this.props.error,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      loading,
      data: {
        volume_based_alert: { enabled, threshold, time_interval, timeout },
      },
    } = this.state;

    return (
      <div className={classes.root}>
        {loading ? (
          <CircularProgress />
        ) : (
          <FormControl className={classes.formControl}>
            {this.displayTextFields()}
            <FormGroup className={classes.formGroup}>
              <FormControlLabel
                control={
                  <CheckBox
                    name="volume_based_alert.enabled"
                    checked={enabled}
                    onChange={(e) =>
                      this.onVolumeBasedAlertChange(
                        { target: { value: e.target.checked } },
                        "enabled"
                      )
                    }
                  />
                }
                label="Volume based alerts enabled"
              />
              <InputComponent
                required={enabled}
                disabled={!enabled}
                defaultValue={threshold}
                onChange={(e) => this.onVolumeBasedAlertChange(e, "threshold")}
                error={this.state.error["threshold"] ? true : false}
                helperText={this.state.error["threshold"]}
                label="Threshold"
                margin="dense"
                fullWidth={true}
                type="number"
              />
              <InputComponent
                required={enabled}
                disabled={!enabled}
                defaultValue={time_interval}
                onChange={(e) =>
                  this.onVolumeBasedAlertChange(e, "time_interval")
                }
                error={this.state.error["time_interval"] ? true : false}
                helperText={this.state.error["time_interval"]}
                label="Time interval"
                margin="dense"
                fullWidth={true}
                type="number"
              />
              <InputComponent
                required={enabled}
                disabled={!enabled}
                defaultValue={timeout}
                onChange={(e) => this.onVolumeBasedAlertChange(e, "timeout")}
                error={this.state.error["timeout"] ? true : false}
                helperText={this.state.error["timeout"]}
                label="Timeout"
                margin="dense"
                fullWidth={true}
                type="number"
              />
            </FormGroup>
          </FormControl>
        )}
      </div>
    );
  }
}

TagsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onChangeTagForm: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  error: PropTypes.object,
  mode: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(TagsForm);
