import axios from 'axios';

import { assetsConstants } from '../../constants/configuration/assetsConstants';
import { setHeaders } from '../../setters/auth';

export const fetchAssetList = clientShortName => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/assets`;

  const promise = axios.get(url, {
    headers: setHeaders()
  });

  return dispatch({
    type: assetsConstants.FETCH_ASSET_LIST,
    payload: promise
  });
};

export const addAsset = (clientShortName, data) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/assets`;
  let assets = {
    id: data.id,
    name: data.name,
    platform_ids: data.platform_ids
  };

  const promise = axios.post(url, assets, {
    headers: setHeaders(),
    assetInfo: data
  });

  return dispatch({
    type: assetsConstants.ADD_ASSET,
    payload: promise
  });
};

export const editAsset = (clientShortName, index, data) => dispatch => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/client/${clientShortName}/assets/${data.id}`;
  let assets = {
    name: data.name,
    platform_ids: data.platform_ids
  };

  const promise = axios.put(url, assets, {
    headers: setHeaders(),
    index: index,
    assetInfo: data
  });

  return dispatch({
    type: assetsConstants.EDIT_ASSET,
    payload: promise
  });
};
