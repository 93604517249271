import { linkedAccountsConstants } from '../../constants/taskKPIs/linkedAccountsConstants';

import { extractLinkedAccounts } from '../../extractors/linkedAccountsExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  linkedAccountsList: [],
  error: null
};

const linkedAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH_LINKED_ACCOUNTS_LIST
    case `${linkedAccountsConstants.FETCH_LINKED_ACCOUNTS_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${linkedAccountsConstants.FETCH_LINKED_ACCOUNTS_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${linkedAccountsConstants.FETCH_LINKED_ACCOUNTS_LIST}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        linkedAccountsList: extractLinkedAccounts(action.payload.data)
      };
    default:
      return state;
  }
};

export default linkedAccountsReducer;
