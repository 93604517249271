import { classifiersConstants } from '../../constants/configuration/classifiersConstants';

import { extractClassifierList } from '../../extractors/classifierExtractor';

const initialState = {
  fetching: false,
  fetched: false,
  classifierList: [],
  error: null
};

const Classifiers = (state = initialState, action) => {
  switch (action.type) {
    // FETCH CLASSIFIER LIST
    case `${classifiersConstants.FETCH_CLASSIFIER_LIST}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${classifiersConstants.FETCH_CLASSIFIER_LIST}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${classifiersConstants.FETCH_CLASSIFIER_LIST}_FULFILLED`:
      let classifiers = extractClassifierList(action.payload.data);
      return {
        ...state,
        fetching: false,
        fetched: true,
        classifierList: classifiers
      };
    // ADD CLASSIFIER
    case `${classifiersConstants.ADD_CLASSIFIER}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${classifiersConstants.ADD_CLASSIFIER}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${classifiersConstants.ADD_CLASSIFIER}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        classifierList: [
          ...state.classifierList,
          {
            classifier_name:
              action.payload.config.classifierInfo.classifier_name,
            group: action.payload.config.classifierInfo.group,
            colorGroup: action.payload.config.classifierInfo.colorGroup,
            versions: action.payload.config.classifierInfo.versions,
            default_rules: action.payload.config.classifierInfo.default_rules
          }
        ]
      };
    // EDIT CLASSIFIER
    case `${classifiersConstants.EDIT_CLASSIFIER}_PENDING`:
      return {
        ...state,
        fetching: true
      };
    case `${classifiersConstants.EDIT_CLASSIFIER}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data
      };
    case `${classifiersConstants.EDIT_CLASSIFIER}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        classifierList: Object.assign([], {
          ...state.classifierList,
          [action.payload.config.index]: {
            classifier_name:
              action.payload.config.classifierInfo.classifier_name,
            group: action.payload.config.classifierInfo.group,
            colorGroup: action.payload.config.classifierInfo.colorGroup,
            versions: action.payload.config.classifierInfo.versions,
            default_rules: action.payload.config.classifierInfo.default_rules
          }
        })
      };
    default:
      return state;
  }
};

export default Classifiers;
