import axios from "axios";

import { plansConstants } from "../constants/plansConstants";
import { setHeaders } from "../setters/auth";

export const fetchPlanList = () => (dispatch) => {
  const url = `${process.env.URL_API_ENDPOINT}/client-plan`;

  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: plansConstants.FETCH_PLANS,
    payload: promise,
  });
};
