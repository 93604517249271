import axios from "axios";

import { replyConstants } from "../constants/replyConstants";
import { setHeaders } from "../setters/auth";

export const getCommentListByClient =
  (query, options = {}) =>
  (dispatch) => {
    let url = `${process.env.URL_API_ENDPOINT}/clients/${query.client_short_name}/replies`;
    if (
      (options && options.cursor) ||
      (options && options.limit) ||
      (Object.values(query).length > 1 &&
        Object.keys(query).includes("client_short_name"))
    ) {
      url = url + `?`;
    }

    const params = [];

    if (query.start) params.push(`startTime=${query.start}`);
    if (query.comment_ids) params.push(`comment_ids=${query.comment_ids}`);
    if (query.end) params.push(`endTime=${query.end}`);
    if (query.reply_status) params.push(`reply_statuses=${query.reply_status}`);
    if (query.reply_created_at_start)
      params.push(`reply_created_at_start=${query.reply_created_at_start}`);
    if (query.reply_created_at_end)
      params.push(`reply_created_at_end=${query.reply_created_at_end}`);
    if (query.reply_updated_at_start)
      params.push(`reply_updated_at_start=${query.reply_updated_at_start}`);
    if (query.reply_updated_at_end)
      params.push(`reply_updated_at_end=${query.reply_updated_at_end}`);
    if (query.reply_created_by)
      params.push(`reply_created_by=${query.reply_created_by}`);
    if (query.reply_updated_by)
      params.push(`reply_updated_by=${query.reply_updated_by}`);
    if (query.reply_message)
      params.push(`reply_message=${encodeURIComponent(query.reply_message)}`);
    if (query.sort) params.push(`sort=created_time:desc`);

    if (options.cursor) params.push(`cursor=${options.cursor}`);
    if (options.limit) params.push(`limit=${options.limit}`);

    // Adds param to not fethc the reply_config for each comments
    params.push(`reply_config=0`);

    url = url + params.join("&");

    const promise = axios.get(url, {
      headers: setHeaders(),
      options: options,
    });

    return dispatch({
      type: replyConstants.GET_COMMENT_LIST_BY_CLIENT_REPLY,
      payload: promise,
    });
  };

export const resetCommentList = () => (dispatch) => {
  const promise = new Promise((resolve, reject) => {
    resolve(); // fulfilled
    reject("failure decrase notification count");
  });

  return dispatch({
    type: replyConstants.RESET_COMMENT_LIST_REPLY,
    payload: promise,
  });
};

export const replyActionToComment =
  (
    clientShortName,
    commentId,
    action,
    index,
    message,
    start_time,
    replier_asset_id
  ) =>
  (dispatch) => {
    let url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/comments/${commentId}/reply/${action}`;
    let body = {};
    body =
      action === "select"
        ? Object.assign(
            {},
            {
              message: message.message,
              original_message: message.original_message
                ? message.original_message
                : message.message,
              language: message.language ? message.language : "en",
              start_time: start_time,
            }
          )
        : action === "delete" || action === "cancel"
        ? Object.assign(
            {},
            {
              reason: message,
              start_time: start_time,
            }
          )
        : action === "approve"
        ? Object.assign(
            {},
            {
              start_time: start_time,
              replier_asset_id: replier_asset_id,
            }
          )
        : {};

    const promise = axios.post(url, body, {
      headers: setHeaders(),
      index: index,
      message: message,
    });

    return dispatch({
      type: replyConstants[`${action.toUpperCase()}_REPLY_TO_COMMENT`],
      payload: promise,
    });
  };

export const fetchClients = () => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/reply/stats`;

  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: replyConstants.FETCH_CLIENTS_REPLY,
    payload: promise,
  });
};

export const fetchHistoryFromComment =
  (clientShortName, commentId, options = {}) =>
  (dispatch) => {
    const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/comments/${commentId}/history`;
    const promise = axios.get(url, {
      headers: setHeaders(),
      commentId: commentId,
      index: options.index ? options.index : null,
    });

    return dispatch({
      type: replyConstants.FETCH_HISTORY_FROM_COMMENT_REPLY,
      payload: promise,
    });
  };

export const updateTranslation =
  (clientShortName, commentId, options = {}) =>
  (dispatch) => {
    const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/comments/${commentId}/message-translation`;
    const promise = axios.patch(
      url,
      {},
      {
        headers: setHeaders(),
        index: options.index ? options.index : null,
      }
    );

    return dispatch({
      type: replyConstants.UPDATE_CLIENTS_COMMENTS_TRANSLATION_REPLY,
      payload: promise,
    });
  };

export const fetchAdsFromPost =
  (clientShortName, postId, options = {}) =>
  (dispatch) => {
    const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/posts/${postId}/ads`;
    const promise = axios.get(url, {
      headers: setHeaders(),
      index: options.index !== undefined ? options.index : null,
    });

    return dispatch({
      type: replyConstants.FETCH_CLIENTS_POST_ADS_REPLY,
      payload: promise,
    });
  };

//WEBSOCKET ACTIONS
export const updatesReplyStats = (data) => ({
  type: "UPDATES_REPLY_STATS",
  data: data,
});

const CancelToken = axios.CancelToken;
var cancel;

function fetchClientCommentReplyConfigCreator(
  clientShortName,
  commentId,
  options = {}
) {
  const url = `${process.env.URL_API_ENDPOINT}/clients/${clientShortName}/comments/${commentId}/reply/config`;

  return () => {
    if (cancel !== undefined) {
      cancel();
    }

    return axios.get(url, {
      headers: setHeaders(),
      index: options.index !== undefined ? options.index : null,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  };
}

export const fetchClientCommentReplyConfig =
  (clientShortName, commentId, options = {}) =>
  (dispatch) => {
    const promise = fetchClientCommentReplyConfigCreator(
      clientShortName,
      commentId,
      options
    )();

    return dispatch({
      type: replyConstants.FETCH_CLIENT_COMNENT_REPLY_CONFIG,
      payload: promise,
    });
  };
