import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';

import InputComponent from '../../commons/input/inputComponent';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  formControlContainer: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  formGroup: {
    width: '50%'
  },
  platforms: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  platform: {
    margin: 2
  }
});

class TagsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      platformList: props.platformList || [],
      data: props.defaultValue
        ? props.defaultValue
        : {
            id: '',
            name: '',
            platform_ids: []
          },
      error: props.error
        ? props.error
        : {
            id: '',
            name: '',
            platform_ids: ''
          }
    };
  }

  onChange(e, value) {
    this.setState(
      {
        data: {
          ...this.state.data,
          [value]: e.target.value
        }
      },
      () => {
        this.props.onChangeAssetForm(this.state.data);
      }
    );
  }

  displaySelect() {
    const { classes } = this.props;
    const { data, platformList, error } = this.state;
    return (
      <FormGroup className={classes.formGroup}>
        <InputLabel>Platforms *</InputLabel>
        <Select
          multiple
          value={data.platform_ids}
          onChange={e => {
            return this.onChange(e, 'platform_ids');
          }}
          input={<Input id="tags" />}
          renderValue={selected => (
            <div className={classes.platforms}>
              {selected.map(value => (
                <Chip
                  key={value}
                  label={
                    platformList.find(
                      (platform, key) => value === key.toString()
                    )
                      ? platformList.find(
                          (platform, key) => value === key.toString()
                        )
                      : value
                  }
                  className={classes.platform}
                />
              ))}
            </div>
          )}
        >
          {platformList.map((platform, key) => (
            <MenuItem key={platform} value={key.toString()}>
              {platform}
            </MenuItem>
          ))}
        </Select>
        {error['platform_ids'] ? (
          <FormHelperText>{error['platform_ids']}</FormHelperText>
        ) : (
          ''
        )}
      </FormGroup>
    );
  }

  displayTextFields() {
    const { classes, defaultValue, mode } = this.props;
    const { data, error } = this.state;
    return (
      <FormGroup className={classes.formGroup}>
        <InputComponent
          required
          disabled={mode === 'edit' ? true : false}
          defaultValue={
            Object.values(defaultValue).length ? defaultValue.id : data.id
          }
          onChange={e => {
            return this.onChange(e, 'id');
          }}
          error={error.id ? true : false}
          helperText={error.id}
          label="ID"
          margin="dense"
          fullWidth={true}
        />
        <InputComponent
          required
          defaultValue={
            Object.values(defaultValue).length ? defaultValue.name : data.name
          }
          error={error.name ? true : false}
          helperText={error.name}
          onChange={e => {
            return this.onChange(e, 'name');
          }}
          label="Name"
          margin="dense"
          fullWidth={true}
        />
      </FormGroup>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setState({
        error: this.props.error
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.state;
    return (
      <div className={classes.root}>
        {loading ? (
          <CircularProgress />
        ) : (
          <div className={classes.formControlContainer}>
            <FormControl className={classes.formControl}>
              {this.displayTextFields()}
            </FormControl>
            <FormControl className={classes.formControl}>
              {this.displaySelect()}
            </FormControl>
          </div>
        )}
      </div>
    );
  }
}

TagsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onChangeAssetForm: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  error: PropTypes.object,
  platformList: PropTypes.array,
  mode: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(TagsForm);
