import { featureFlagsConstants } from "../constants/featureFlagsConstants";

const initialState = {
  fetching: false,
  fetched: false,
  featureFlags: [],
  error: null,
};

const FeatureFlags = (state = initialState, action) => {
  switch (action.type) {
    case `${featureFlagsConstants.FETCH_FEATURE_FLAGS}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${featureFlagsConstants.FETCH_FEATURE_FLAGS}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${featureFlagsConstants.FETCH_FEATURE_FLAGS}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        featureFlags: action.payload.data,
      };
    case `${featureFlagsConstants.UPDATE_FEATURE_FLAGS}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${featureFlagsConstants.UPDATE_FEATURE_FLAGS}_ERROR`:
      return {
        ...state,
        fetching: false,
        error: action.payload.data,
      };
    case `${featureFlagsConstants.UPDATE_FEATURE_FLAGS}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        featureFlags: action.payload.data,
      };
    default:
      return state;
  }
};

export default FeatureFlags;
