import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBox from "@material-ui/core/Checkbox";

import DatePicker from "../commons/picker/dateTimePickerComponent";
import InputComponent from "../commons/input/inputComponent";
import SelectComponent from "../commons/select/selectComponent";
import Tooltip from "../commons/tooltip/TooltipComponent";

const styles = (theme) => ({
  root: {},
  button: {
    marginRight: 20,
  },
  formControlContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  formControlContent: {
    marginRight: 20,
  },
  container: {
    display: "flex",
    position: "relative",
    margin: 3,
  },
  containerDivider: {
    display: "flex",
    marginTop: 20,
    marginBottom: 20,
    width: "100%",
  },
  inputWidth: {
    width: 220,
  },
  divider: {
    width: "100%",
  },
  infoIcon: {
    fontSize: 13,
    position: "absolute",
    zIndex: 3000,
    marginLeft: -15,
  },
});

const hiddenTags = ["hide", "unhide", "unsure", "sure"];

class ExplorerSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilters: false,
      settedValue: false,
      clients: props.clients,
      userList: props.userList,
      languageList: props.languageList || [],
      clientSelected: props.defaultValues
        ? props.defaultValues.client_short_name
        : "",
      data: {
        hideSalesAnalysis: false,
        noTags: props.defaultValues ? props.defaultValues.noTags : false,
        sort: props.defaultValues ? props.defaultValues.sort : false,
        user: props.defaultValues ? props.defaultValues.user : "",
        authorName: props.defaultValues ? props.defaultValues.author_name : "",
        message: props.defaultValues ? props.defaultValues.message : "",
        postType: props.defaultValues ? props.defaultValues.postType : "",
        commentIds: props.defaultValues ? props.defaultValues.comment_ids : "",
        language:
          props.defaultValues &&
          props.defaultValues.language &&
          props.defaultValues.language.length
            ? props.defaultValues.language.split(",")
            : [],
        campaign_ids: props.defaultValues
          ? props.defaultValues.campaign_ids
          : "",
        postIds: props.defaultValues ? props.defaultValues.post_ids : "",
        startTime: props.defaultValues ? props.defaultValues.start : "",
        endTime: props.defaultValues ? props.defaultValues.end : "",
        owned: props.defaultValues ? props.defaultValues.owned : "",
        status: props.defaultValues ? props.defaultValues.status : "",
        actions_outside_camp_status: props.defaultValues
          ? props.defaultValues.actions_outside_camp_status
          : "",
        anyOfObjectGroupIds:
          props.defaultValues &&
          props.defaultValues.anyOfObjectGroupIds &&
          props.defaultValues.anyOfObjectGroupIds.length
            ? props.defaultValues.anyOfObjectGroupIds.split(",")
            : [],
        replyStatus:
          props.defaultValues &&
          props.defaultValues.reply_status &&
          props.defaultValues.reply_status.length
            ? props.defaultValues.reply_status.split(",")
            : [],
        commentTypes:
          props.defaultValues &&
          props.defaultValues.comment_types &&
          props.defaultValues.comment_types.length
            ? props.defaultValues.comment_types.split(",")
            : [],
        platforms:
          props.defaultValues &&
          props.defaultValues.platform_id &&
          props.defaultValues.platform_id.length
            ? props.defaultValues.platform_id.split(",")
            : [],
        assetId:
          props.defaultValues &&
          props.defaultValues.asset_id &&
          props.defaultValues.asset_id.length
            ? props.defaultValues.asset_id.split(",")
            : [],
        highlightedTags:
          props.defaultValues &&
          props.defaultValues.highlighted_tags &&
          props.defaultValues.highlighted_tags.length
            ? props.defaultValues.highlighted_tags.split(",")
            : [],
        tags:
          props.defaultValues &&
          props.defaultValues.tags &&
          props.defaultValues.tags.length
            ? props.defaultValues.tags.split(",")
            : [],
        clientShortName: props.defaultValues
          ? props.defaultValues.client_short_name
          : "",
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clients !== this.props.clients) {
      this.setState({
        clients: this.props.clients,
      });
    }

    if (prevProps.languageList !== this.props.languageList) {
      this.setState({
        languageList: this.props.languageList,
      });
    }

    if (prevProps.userList !== this.props.userList) {
      this.setState({
        userList: this.props.userList,
      });
    }
  }

  componentDidMount() {
    if (this.state.clientSelected) {
      this.setPlatforms();
    }
  }

  setPlatforms() {
    this.setState({
      data: {
        ...this.state.data,
        platforms:
          this.props.clients && this.props.clients.length
            ? this.props.clients
                .find(
                  (client) =>
                    this.state.clientSelected === client.clientShortName
                )
                .platforms.filter((platform) => {
                  if (this.state.data["platforms"].length) {
                    return this.state.data["platforms"].includes(platform.id);
                  }
                  return platform;
                })
                .map((platform) => platform.id)
            : [],
      },
    });
  }

  handleChange(event, id) {
    if (id === "clientShortName") {
      this.setState(
        {
          clientSelected: event.target.value,
        },
        () => {
          this.props.getLanguageData(this.state.clientSelected);
          this.clearFilters(true);
        }
      );
    }

    this.setState({
      data: {
        ...this.state.data,
        [id]:
          event.currentTarget.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
    });
  }

  setSelectedDate(name, date) {
    this.setState({
      data: {
        ...this.state.data,
        [name]: date,
      },
      settedValue: false,
    });
  }

  clearFilters(setPlatforms = false) {
    this.setState(
      {
        settedValue: true,
        data: {
          ...this.state.data,
          status: "",
          actions_outside_camp_status: "",
          user: "",
          authorName: "",
          message: "",
          postType: "",
          language: [],
          postIds: "",
          noTags: false,
          sort: false,
          commentIds: "",
          tags: [],
          platforms: [],
          highlightedTags: [],
          replyStatus: [],
          commentTypes: [],
          anyOfObjectGroupIds: [],
          owned: "",
          startTime: "",
          endTime: "",
          assetId: [],
        },
      },
      () => {
        if (setPlatforms) {
          this.setPlatforms();
        }
      }
    );
  }

  search() {
    this.props.getFilterData(this.state.data);
  }

  render() {
    const { classes, hide } = this.props;
    const {
      settedValue,
      clients,
      data,
      clientSelected,
      languageList,
      userList,
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.formControlContainer}>
          {clients && clients.length ? (
            <div className={classes.container}>
              <SelectComponent
                id={"clientShortName"}
                label={"Client name"}
                multiple={false}
                value={
                  data && data["clientShortName"] ? data["clientShortName"] : ""
                }
                items={
                  data["hideSalesAnalysis"]
                    ? clients
                        .filter((client) => !client.sale_analysis_client)
                        .sort((a, b) => {
                          if (a.name.toLowerCase() > b.name.toLowerCase())
                            return 1;
                          return -1;
                        })
                    : clients.sort((a, b) => {
                        if (a.name.toLowerCase() > b.name.toLowerCase())
                          return 1;
                        return -1;
                      })
                }
                attributName={"name"}
                attributId={"clientShortName"}
                onChange={this.handleChange.bind(this)}
              />
            </div>
          ) : (
            ""
          )}
          {clients && clients.length ? (
            <div className={classes.container}>
              <FormControlLabel
                control={
                  <CheckBox
                    disabled={
                      clients.find(
                        (client) => clientSelected === client.clientShortName
                      )
                        ? clients.find(
                            (client) =>
                              clientSelected === client.clientShortName
                          ).sale_analysis_client
                        : false
                    }
                    checked={
                      void 0 !== data["hideSalesAnalysis"]
                        ? data["hideSalesAnalysis"]
                        : false
                    }
                    onChange={(e) => this.handleChange(e, "hideSalesAnalysis")}
                    color="secondary"
                  />
                }
                label="Hide sales analysis"
              />
            </div>
          ) : (
            ""
          )}
          <div className={classes.containerDivider}>
            <Divider className={classes.divider} component="div" />
          </div>
          {clientSelected ? (
            <div className={classes.container}>
              <FormControlLabel
                control={
                  <CheckBox
                    checked={void 0 !== data["noTags"] ? data["noTags"] : false}
                    onChange={(e) => this.handleChange(e, "noTags")}
                    color="primary"
                  />
                }
                label="No Tags"
              />
              <FormControlLabel
                control={
                  <CheckBox
                    checked={void 0 !== data["sort"] ? data["sort"] : false}
                    onChange={(e) => this.handleChange(e, "sort")}
                    color="primary"
                  />
                }
                label="Sort By Created Time"
              />
            </div>
          ) : (
            ""
          )}
          <div className={classes.containerDivider}>
            <Divider className={classes.divider} component="div" />
          </div>
          {clientSelected ? (
            <div className={classes.container}>
              <SelectComponent
                id={"platforms"}
                label={"Platforms"}
                attributName={"name"}
                attributId={"id"}
                multiple={true}
                display={2}
                value={data["platforms"]}
                items={
                  clients && clients.length
                    ? typeof clients.find(
                        (client) => clientSelected === client.clientShortName
                      ) === "undefined"
                      ? []
                      : clients.find(
                          (client) => clientSelected === client.clientShortName
                        ).platforms
                    : []
                }
                onChange={this.handleChange.bind(this)}
              />
              <Tooltip title={<p>Also filter the assets dropdown</p>}>
                <Icon className={classes.infoIcon}>info</Icon>
              </Tooltip>
              <SelectComponent
                id={"assetId"}
                label={"Assets"}
                multiple={true}
                value={data["assetId"]}
                attributName={"name"}
                attributId={"id"}
                attributeIcon={"platformName"}
                items={
                  clients && clients.length
                    ? clients
                        .find(
                          (client) => clientSelected === client.clientShortName
                        )
                        .assets.filter((asset) =>
                          clients
                            .find(
                              (client) =>
                                clientSelected === client.clientShortName
                            )
                            .platforms.filter((platform) => {
                              if (this.state.data["platforms"].length) {
                                return this.state.data["platforms"].includes(
                                  platform.id
                                );
                              }
                              return platform;
                            })
                            .map((platform) => platform.name)
                            .includes(asset.platformName)
                        )
                    : []
                }
                onChange={this.handleChange.bind(this)}
              />
            </div>
          ) : (
            ""
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <SelectComponent
                id={"anyOfObjectGroupIds"}
                label={"Groups"}
                multiple={true}
                value={
                  data && data["anyOfObjectGroupIds"]
                    ? data["anyOfObjectGroupIds"]
                    : []
                }
                attributName={"label"}
                attributId={"id"}
                items={
                  clients && clients.length
                    ? clients.find(
                        (client) => clientSelected === client.clientShortName
                      ).groups
                    : []
                }
                onChange={this.handleChange.bind(this)}
              />
            </div>
          ) : (
            ""
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <InputComponent
                id={"postIds"}
                label={"Post ID(s)"}
                value={data["postIds"]}
                onChange={this.handleChange.bind(this)}
              />
              <InputComponent
                id={"commentIds"}
                label={"Comment ID(s)"}
                value={data["commentIds"]}
                onChange={this.handleChange.bind(this)}
              />
            </div>
          ) : (
            ""
          )}
          <div className={classes.container}>
            <InputComponent
              id={"campaign_ids"}
              label={"Campaign ids"}
              value={data["campaign_ids"]}
              onChange={this.handleChange.bind(this)}
            />
          </div>
          {clientSelected ? (
            <div className={classes.container}>
              <SelectComponent
                id={"language"}
                multiple={true}
                label={"Language"}
                value={data && data["language"] ? data["language"] : []}
                attributName={"label"}
                displaySearch={true}
                attributId={"id"}
                items={languageList && languageList.length ? languageList : []}
                onChange={this.handleChange.bind(this)}
              />
            </div>
          ) : (
            ""
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <InputComponent
                id={"message"}
                label={"Message"}
                value={data["message"]}
                onChange={this.handleChange.bind(this)}
              />
              <SelectComponent
                id={"postType"}
                label={"PostType"}
                value={data && data["postType"] ? data["postType"] : ""}
                attributName={"label"}
                attributId={"id"}
                items={[
                  {
                    id: "published_page_post",
                    label: "Published Page Post",
                  },
                  {
                    id: "published_page_post_with_ad",
                    label: "Published Page Post Used in an Ad",
                  },
                  {
                    id: "unpublished_page_post_with_ad_without_ad_info",
                    label:
                      "Unpublished Page Post Used in an Ad Without Ad Information",
                  },
                  {
                    id: "unpublished_page_post_with_ad_with_ad_info",
                    label:
                      "Unpublished Page Post Used in an Ad With Ad Information",
                  },
                ]}
                onChange={this.handleChange.bind(this)}
              />
            </div>
          ) : (
            ""
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <SelectComponent
                id={"replyStatus"}
                label={"Reply status"}
                multiple={true}
                display={2}
                value={data && data["replyStatus"] ? data["replyStatus"] : []}
                items={[
                  "new",
                  "pending",
                  "cancelled",
                  "successful",
                  "deleted",
                  "undeletable",
                  "unrepliable",
                  "failed",
                  "delete_failed",
                ]}
                onChange={this.handleChange.bind(this)}
              />
              <SelectComponent
                multiple
                display={2}
                id="commentTypes"
                attributId="value"
                label="Comment Types"
                attributName="label"
                value={data && data["commentTypes"] ? data["commentTypes"] : []}
                items={[
                  {
                    label: "Public conversations",
                    value: "public_conversations",
                  },
                  {
                    label: "Instagram Story Replies",
                    value: "instagram_story_replies",
                  },
                ]}
                onChange={this.handleChange.bind(this)}
              />
            </div>
          ) : (
            ""
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <SelectComponent
                id={"owned"}
                label={"Owned"}
                attributName={"label"}
                attributId={"value"}
                value={data && data["owned"] ? data["owned"] : ""}
                items={[
                  {
                    label: "None",
                    value: "",
                  },
                  {
                    label: "Owned",
                    value: "true",
                  },
                  {
                    label: "Not owned",
                    value: "false",
                  },
                ]}
                onChange={this.handleChange.bind(this)}
              />
            </div>
          ) : (
            ""
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <SelectComponent
                id={"status"}
                label={"Comment Status"}
                multiple={false}
                value={data && data["status"] ? data["status"] : ""}
                items={["visible", "hidden", "deleted"]}
                onChange={this.handleChange.bind(this)}
              />
              <SelectComponent
                id={"actions_outside_camp_status"}
                label={"Status outside of CAMP"}
                multiple={false}
                value={
                  data && data["actions_outside_camp_status"]
                    ? data["actions_outside_camp_status"]
                    : ""
                }
                items={["visible", "hidden"]}
                onChange={this.handleChange.bind(this)}
              />
            </div>
          ) : (
            ""
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <SelectComponent
                id={"tags"}
                label={"Tags"}
                multiple={true}
                value={data && data["tags"] ? data["tags"] : []}
                hiddenValues={hiddenTags}
                attributName={"label"}
                attributId={"id"}
                disabled={data["noTags"]}
                items={
                  clients && clients.length
                    ? clients.find(
                        (client) => clientSelected === client.clientShortName
                      ).tags
                    : []
                }
                onChange={this.handleChange.bind(this)}
              />
              <SelectComponent
                id={"highlightedTags"}
                label={"Highlighted Tags"}
                multiple={true}
                disabled={data["noTags"]}
                value={
                  data && data["highlightedTags"] ? data["highlightedTags"] : []
                }
                hiddenValues={hiddenTags}
                attributName={"label"}
                attributId={"id"}
                items={
                  clients && clients.length
                    ? clients.find(
                        (client) => clientSelected === client.clientShortName
                      ).tags
                    : []
                }
                onChange={this.handleChange.bind(this)}
              />
            </div>
          ) : (
            ""
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <SelectComponent
                id={"user"}
                label={"CAMP User Name"}
                multiple={true}
                display={1}
                value={data && data["user"] ? data["user"] : []}
                items={userList}
                attributName={"username"}
                attributId={"id"}
                onChange={this.handleChange.bind(this)}
              />
              <InputComponent
                id={"authorName"}
                label={"Author name"}
                value={data["authorName"]}
                onChange={this.handleChange.bind(this)}
              />
            </div>
          ) : (
            ""
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <DatePicker
                settedValue={settedValue}
                name={"startTime"}
                label={"Comment created start"}
                value={data["startTime"]}
                setSelectedDate={this.setSelectedDate.bind(this)}
              />
            </div>
          ) : (
            ""
          )}
          {clientSelected ? (
            <div className={classes.container}>
              <DatePicker
                settedValue={settedValue}
                name={"endTime"}
                label={"Comment created end"}
                value={data["endTime"]}
                setSelectedDate={this.setSelectedDate.bind(this)}
              />
            </div>
          ) : (
            ""
          )}
          <div className={classes.container}>
            <Button
              variant="contained"
              color="primary"
              className={classNames(classes.button)}
              onClick={this.search.bind(this)}
            >
              Search
            </Button>
            <Button
              variant="contained"
              className={classNames(classes.button)}
              onClick={this.clearFilters.bind(this)}
            >
              Clear Filters
            </Button>
            {typeof hide === "function" ? (
              <Button
                variant="contained"
                className={classNames(classes.button)}
                onClick={hide}
              >
                Close
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

ExplorerSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  defaultValues: PropTypes.object,
  getFilterData: PropTypes.func.isRequired,
  getLanguageData: PropTypes.func.isRequired,
  languageList: PropTypes.array,
  clients: PropTypes.array.isRequired,
  userList: PropTypes.array.isRequired,
  hide: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(ExplorerSearch);
