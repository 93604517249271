import axios from "axios";
import moment from "moment";

import { clientsConstants } from "../../constants/configuration/clientsConstants";
import { setHeaders } from "../../setters/auth";

export const fetchClientList = () => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/clients`;

  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: clientsConstants.FETCH_CLIENT_LIST,
    payload: promise,
  });
};

export const addClient = (data) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/clients`;

  let client = {
    email_config: {
      alert_to: data.emailsAlert ? data.emailsAlert : [],
    },
    logo: data.logo,
    name: data.name,
    shortName: data.clientShortName,
    abbreviation: data.abbreviation,
    tags: data.tags,
    adbastion_tags: data.adbastion_tags,
    care_tags: data.care_tags,
    care_review_tags: data.care_review_tags,
    adbastion_auto_crawling: data.adbastion_auto_crawling,
    adbastion_moderation_enabled: data.adbastion_moderation_enabled,
    care_agent_stats_enabled: data.care_agent_stats_enabled,
    adbastion_publishing_enabled: data.adbastion_publishing_enabled,
    care_enabled: data.care_enabled,

    adbastion_moderation_alerts_enabled:
      data.adbastion_moderation_alerts_enabled,
    adbastion_moderation_init_time:
      data.adbastion_moderation_init_time &&
      moment(data.adbastion_moderation_init_time).format("YYYY-MM-DDTHH:mm:ss"),
    adbastion_insights_init_time:
      data.adbastion_insights_init_time &&
      moment(data.adbastion_insights_init_time).format("YYYY-MM-DDTHH:mm:ss"),
    care_init_time:
      data.care_init_time &&
      moment(data.care_init_time).format("YYYY-MM-DDTHH:mm:ss"),
    trial_subscription_init: data.trial_subscription_init,
    trial_subscription_init_time:
      data.trial_subscription_init_time &&
      moment(data.trial_subscription_init_time).format("YYYY-MM-DDTHH:mm:ss"),
    adbastion_moderation_guideline_update_time:
      data.adbastion_moderation_guideline_update_time &&
      moment(data.adbastion_moderation_guideline_update_time).format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
    managed: data.managed,
    industry: data.otherIndustry ? data.otherIndustry : data.industry,
    adbastion_plan: data.adbastion_plan,
    crawlers: {
      init_time: data.initTime,
      sla: data.sla,
      alert_sla: data.alertSla,
      query_only_active: data.queryOnlyActive,
      pull_insights: data.pullInsights,
      allow_published_posts: data.allowPublishedPosts,
    },
    use_new_ids: data.useNewIds,
    private_replies_user_timespan: parseInt(data.privateRepliesUserTimespan),
  };

  const promise = axios.post(url, client, {
    headers: setHeaders(),
    clientInfo: data,
  });

  return dispatch({
    type: clientsConstants.ADD_CLIENT,
    payload: promise,
  });
};

export const editClient = (index, data) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/clients/${data.clientShortName}`;

  let client = {
    email_config: {
      alert_to: data.emailsAlert ? data.emailsAlert : [],
    },
    logo: data.logo,
    name: data.name,
    tags: data.tags,
    abbreviation: data.abbreviation,
    adbastion_tags: data.adbastion_tags,
    care_tags: data.care_tags,
    care_review_tags: data.care_review_tags,
    adbastion_auto_crawling: data.adbastion_auto_crawling,
    adbastion_moderation_enabled: data.adbastion_moderation_enabled,
    care_agent_stats_enabled: data.care_agent_stats_enabled,
    adbastion_publishing_enabled: data.adbastion_publishing_enabled,
    care_enabled: data.care_enabled,
    adbastion_moderation_alerts_enabled:
      data.adbastion_moderation_alerts_enabled,
    adbastion_moderation_init_time:
      data.adbastion_moderation_init_time &&
      moment(data.adbastion_moderation_init_time).format("YYYY-MM-DDTHH:mm:ss"),
    adbastion_moderation_guideline_update_time:
      data.adbastion_moderation_guideline_update_time &&
      moment(data.adbastion_moderation_guideline_update_time).format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
    adbastion_insights_init_time:
      data.adbastion_insights_init_time &&
      moment(data.adbastion_insights_init_time).format("YYYY-MM-DDTHH:mm:ss"),
    care_init_time:
      data.care_init_time &&
      moment(data.care_init_time).format("YYYY-MM-DDTHH:mm:ss"),
    trial_subscription_init: data.trial_subscription_init,
    trial_subscription_init_time:
      data.trial_subscription_init_time &&
      moment(data.trial_subscription_init_time).format("YYYY-MM-DDTHH:mm:ss"),
    managed: data.managed,
    industry: data.industry === "Other" ? data.otherIndustry : data.industry,
    adbastion_plan: data.adbastion_plan,
    crawlers: {
      init_time: data.initTime,
      sla: data.sla,
      alert_sla: data.alertSla,
      query_only_active: data.queryOnlyActive,
      pull_insights: data.pullInsights,
      allow_published_posts: data.allowPublishedPosts,
    },
    private_replies_user_timespan: parseInt(data.privateRepliesUserTimespan),
  };

  const promise = axios.put(url, client, {
    headers: setHeaders(),
    index: index,
    clientInfo: data,
  });

  return dispatch({
    type: clientsConstants.EDIT_CLIENT,
    payload: promise,
  });
};
