import axios from "axios";

import { ucmConstants } from "../../constants/configuration/ucmConstants";
import { setHeaders } from "../../setters/auth";

export const fetchUCMRoles = () => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/cfa/users/roles`;

  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: ucmConstants.FETCH_UCM_ROLES,
    payload: promise,
  });
};

export const fetchUCMList = () => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/cfa/users`;

  const promise = axios.get(url, {
    headers: setHeaders(),
  });

  return dispatch({
    type: ucmConstants.FETCH_UCM_LIST,
    payload: promise,
  });
};

export const addUserClient = (data) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/cfa/users`;

  const promise = axios.post(
    url,
    {
      id: data.id,
      client_short_name: data.clientShortName,
      available_clients: data.availableClients,
      is_admin: data.isAdmin,
      first_name: data.firstName,
      last_name: data.lastName,
      roles: data.roles,
      email: data.email,
    },
    {
      headers: setHeaders(),
      addData: {
        ...data,
        status: true,
      },
    }
  );

  return dispatch({
    type: ucmConstants.ADD_UCM_LIST,
    payload: promise,
  });
};

export const updateUserClient = (index, data) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/cfa/users/${data.id}`;

  if (data.tableData) {
    delete data.tableData;
  }

  const promise = axios.put(
    url,
    {
      roles: data.roles,
      client_short_name: data.clientShortName,
      available_clients: data.availableClients,
    },
    {
      headers: setHeaders(),
      index: index,
      updateData: data,
    }
  );

  return dispatch({
    type: ucmConstants.UPDATE_UCM_LIST,
    payload: promise,
  });
};

export const resetPasswordUserClient = (id, index, username) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/cfa/users/${id}/reset-password`;

  const promise = axios.patch(
    url,
    {},
    {
      headers: setHeaders(),
      username,
      index,
    }
  );

  return dispatch({
    type: ucmConstants.RESET_PASSWORD_UCM_USER,
    payload: promise,
  });
};

export const disableUserClient = (id, value) => (dispatch) => {
  let url = `${process.env.URL_API_ENDPOINT}/admin/cfa/users/${id}`;

  const promise = axios.patch(
    url,
    {
      is_disabled: !value,
    },
    {
      headers: setHeaders(),
    }
  );

  return dispatch({
    type: ucmConstants.DISABLE_UCM_USER,
    payload: promise,
  });
};
