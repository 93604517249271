export const assetManagementConstants = {
  FETCH_ASSET_MANAGEMENT_LIST: 'FETCH_ASSET_MANAGEMENT_LIST',
  FETCH_ACTIVE_SERVICES_LIST: 'FETCH_ACTIVE_SERVICES_LIST',
  FETCH_ASSET_MANAGEMENT: 'FETCH_ASSET_MANAGEMENT',
  ASSIGN_CLIENT_SHORTNAME: 'ASSIGN_CLIENT_SHORTNAME',
  ASSIGN_CLIENT_SHORTNAME_BATCH: 'ASSIGN_CLIENT_SHORTNAME_BATCH',
  CREATE_TASKS: 'CREATE_TASKS',
  TOGGLE_CRAWLING_DISABLED: 'TOGGLE_CRAWLING_DISABLED',
  TOGGLE_CRAWLING_START_STOP: 'TOGGLE_CRAWLING_START_STOP',
  UPDATE_ASSET: 'UPDATE_ASSET',
  UPDATE_ASSET_BATCH: 'UPDATE_ASSET_BATCH'
};
