import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import ActionsConfiguration from "./actionsConfiguration";

import {
  fetchActionList,
  fetchActionTypeList,
  addAction,
  editAction,
  deleteAction,
} from "../../../actions/configuration/actionsAction";
import { fetchTagsList } from "../../../actions/configuration/tagsAction";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  loading: {
    margin: "auto",
    marginTop: "calc(50vh - 96px)",
    color: theme.palette.orange[300],
  },
  notAllowed: {
    margin: "auto",
    height: "calc(100vh - 440px)",
    marginTop: "calc(50vh - 96px)",
  },
});

const mapStateToProps = (state) => ({
  tags: state.tagsReducer,
  actions: state.actionsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTagsList: () => dispatch(fetchTagsList()),
  fetchActionList: (clientShortName) =>
    dispatch(fetchActionList(clientShortName)),
  fetchActionTypeList: (clientShortName) =>
    dispatch(fetchActionTypeList(clientShortName)),
  addAction: (clientShortName, data) =>
    dispatch(addAction(clientShortName, data)),
  editAction: (clientShortName, index, data) =>
    dispatch(editAction(clientShortName, index, data)),
  deleteAction: (clientShortName, index) =>
    dispatch(deleteAction(clientShortName, index)),
});

class ActionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
      clientShortName: props.clientShortName || "",
      clientTags: props.clientTags || [],
      loading: true,
      notAllowed: false,
      creatable: false,
      editable: false,
      deletable: false,
      tagList: [],
      typeList: [],
      actionList: [],
      replierAssetsList: [],
      editingAction: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};

    if (nextProps.tags.tagList !== prevState.tagList) {
      update.tagList = nextProps.tags.tagList;
    }

    if (nextProps.actions.typeList !== prevState.typeList) {
      update.typeList = nextProps.actions.typeList;
    }

    if (nextProps.actions.actionList !== prevState.actionList) {
      update.actionList = nextProps.actions.actionList;
    }

    if (nextProps.actions.replierAssetsList !== prevState.replierAssetsList) {
      update.replierAssetsList = nextProps.actions.replierAssetsList;
    }

    if (nextProps.actions.fetching !== prevState.editingAction) {
      update.editingAction = nextProps.actions.fetching;
    }

    return Object.keys(update).length ? update : null;
  }

  addAction(data) {
    this.props.addAction(this.state.clientShortName, data);
  }

  editAction(index, data) {
    this.props.editAction(this.state.clientShortName, index, data);
  }

  deleteAction(index) {
    this.props.deleteAction(this.state.clientShortName, index);
  }

  initialize() {
    const { clientShortName } = this.state;
    if (this.props.permissions.includes("admin_list_tags")) {
      this.props.fetchTagsList().then(() => {
        if (this.props.permissions.includes("admin_list_client_actions")) {
          this.props.fetchActionList(clientShortName).then(() => {
            this.setState({
              loading: false,
            });

            if (this.props.permissions.includes("admin_update_client_action")) {
              this.setState({
                editable: true,
              });
            }
            if (this.props.permissions.includes("admin_create_client_action")) {
              this.setState({
                creatable: true,
              });
            }
            if (this.props.permissions.includes("admin_delete_client_action")) {
              this.setState({
                deletable: true,
              });
            }
          });
          if (
            this.props.permissions.includes("admin_list_client_action_types")
          ) {
            this.props.fetchActionTypeList(clientShortName);
          }
        }
      });
    } else {
      this.setState({
        notAllowed: true,
        loading: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tabIndex === 1 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true,
        },
        this.initialize
      );
    }
  }

  componentDidMount() {
    if (this.props.tabIndex === 1 && this.state.isInitialized === false) {
      this.setState(
        {
          isInitialized: true,
        },
        this.initialize
      );
    }
  }

  render() {
    const { classes } = this.props;
    const {
      loading,
      notAllowed,
      editable,
      creatable,
      deletable,
      actionList,
      typeList,
      tagList,
      clientTags,
      clientShortName,
      replierAssetsList,
      editingAction,
    } = this.state;

    return (
      <div className={classes.root}>
        {!notAllowed && !loading ? (
          <ActionsConfiguration
            editable={editable}
            creatable={creatable}
            deletable={deletable}
            addAction={this.addAction.bind(this)}
            editAction={this.editAction.bind(this)}
            deleteAction={this.deleteAction.bind(this)}
            actionList={actionList}
            replierAssetsList={replierAssetsList}
            editingAction={editingAction}
            typeList={typeList}
            tagList={tagList}
            clientTags={clientTags}
            clientShortName={clientShortName}
          />
        ) : !notAllowed ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <Typography className={classes.notAllowed} variant="body1">
            Not Allowed
          </Typography>
        )}
      </div>
    );
  }
}

ActionsContainer.propTypes = {
  clientShortName: PropTypes.string.isRequired,
  clientTags: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ActionsContainer));
