import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import TableComponent from "../../commons/table/tableComponent";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  title: {
    marginBottom: 7,
  },
});

class UsersConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: props.editable,
      columns: [
        {
          title: "ID",
          cellStyle: {
            padding: 2,
            paddingLeft: 14,
          },
          field: "id",
          editComponent: (props) => <p>{props.value}</p>,
        },
        {
          title: "Username",
          cellStyle: {
            padding: 2,
            paddingLeft: 14,
          },
          field: "username",
          editComponent: (props) => <p>{props.value}</p>,
        },
        {
          title: "Roles",
          cellStyle: {
            padding: 2,
            paddingLeft: 14,
          },
          field: "roles",
          render: (rowData) => <p>{rowData.roles.join(", ")}</p>,
          editComponent: (fieldProps) => {
            return (
              <Select
                autoWidth
                multiple={true}
                value={fieldProps.value}
                onChange={(e) => fieldProps.onChange(e.target.value)}
                renderValue={(selected) => selected.join(", ")}
              >
                {this.props.roleList.map((role) => (
                  <MenuItem
                    key={`${role.id}-${fieldProps.rowData.id}`}
                    value={role.id}
                  >
                    {role.id}
                  </MenuItem>
                ))}
              </Select>
            );
          },
        },
      ],
      data: props.userList,
    };
  }

  updateUserRoles(index, newData) {
    this.props.updateUserRoles(index, newData.id, newData.roles);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userList !== this.props.userList) {
      this.setState({
        data: this.props.userList,
      });
    }

    if (prevProps.editable !== this.props.editable) {
      this.setState({
        editable: this.props.editable,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { columns, data, editable } = this.state;

    return (
      <div className={classes.root}>
        <Typography className={classes.title} variant="h5">
          User List
        </Typography>
        <TableComponent
          editable={editable}
          columns={columns}
          data={data}
          title=""
          update={this.updateUserRoles.bind(this)}
        />
      </div>
    );
  }
}

UsersConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  roleList: PropTypes.array.isRequired,
  editable: PropTypes.bool.isRequired,
  userList: PropTypes.array.isRequired,
  updateUserRoles: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(UsersConfiguration);
