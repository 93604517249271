import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  bounceIn: {
    animationDelay: '0ms',
    animationDuration: '1000ms',
    pointerEvents: 'all',
    animationName: 'bounceIn',
    animationFillMode: 'both'
  },
  iconCopy: {
    color: theme.palette.white,
    cursor: 'pointer'
  }
}));

const CopyToClipboardComponent = ({ text }) => {
  const classes = useStyles();
  const [animate, setAnimate] = useState(true);

  const onCopy = () => {
    setAnimate(true);

    setTimeout(() => {
      setAnimate(false);
    }, 1000);
  };

  return (
    <div className={classes.root}>
      <CopyToClipboard text={text} onCopy={() => onCopy()}>
        <span
          className={classNames(classes.iconCopy, {
            [classes.bounceIn]: animate
          })}
        >
          <Icon className={classNames(classes.icon, 'far fa-copy')} />
        </span>
      </CopyToClipboard>
    </div>
  );
};

CopyToClipboardComponent.propTypes = {
  text: PropTypes.string.isRequired
};

export default CopyToClipboardComponent;
