const initialState = {
  alert: {},
  error: null,
  fetched: false,
  fetching: false,
  notification: {},
};

const Alerts = (state = initialState, action) => {
  switch (action.type) {
    // WBSOCKET:ALERT_APP_BAR
    case `ALERT_APP_BAR`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        alert: action.data,
      };

    // WBSOCKET:NOTIFICATION_BE
    case `NOTIFICATION_BE`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        notification: action.data,
      };
    default:
      return state;
  }
};

export default Alerts;
