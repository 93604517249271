export const setStickFilter = (sf, key = null) => {
  const item = {
    name: sf.name ? sf.name : "",
    asset_ids: sf.asset_ids ? sf.asset_ids : [],
    id: sf.id ? sf.id : "",
    client_short_name: sf.client_short_name ? sf.client_short_name : "",
    order_number: sf.order_number ? sf.order_number : 0,
  };

  return item;
};
