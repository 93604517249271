import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const ContentDialog = ({ open, children, title, close, fullWidth }) => {
  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth="lg"
        open={open}
        onClose={(e, reason) => {
          return close(e, reason);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title ? title : ''}</DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </div>
  );
};

ContentDialog.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  open: PropTypes.bool,
  close: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool
};

export default ContentDialog;
